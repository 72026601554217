import React from 'react';
import PropTypes from 'prop-types';

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableTotal({ totals }) {

    const {
        cobro
    } = totals;

    return (
        
            <tr className='table-head'>
                <th colSpan={3} className="text-danger f-bold text-right">Totales</th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(cobro) }
                </th>
                
            </tr>
        
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object
};