import React from 'react';
import * as URL from "helpers/url_helper";
import moment from "moment";

import { REPORT_TYPE, REPORTS } from "./actionsTypes";

const nf = new Intl.NumberFormat('es-MX');
const nfAmount = amount => new Intl.NumberFormat('es-MX',{ style: 'currency', currency: 'MXN' }).format(amount).replace('$', '');

export const dataReports = {
    [REPORTS.CLIENTS] : {
        id: '0',
        title: 'Ventas',
        title_singular: 'Venta',
        type: REPORT_TYPE.CLIENT_PROVIDERS ,
        url_file: URL.UPLOAD_FILE_MOV_CLIENTS,
        url_data: URL.GET_MOV_CLIENTS,
        url_delete: URL.DELETE_MOV_CLIENT,
        url_history: URL.GET_MOV_CLIENTS_HISTORY,
        url_format: 'formatos/clientes.xlsx',
        report_config_name: 'clients-providers_ventas',
        column_names: [
            {
                text: 'Cliente',
                dataField: 'client_account.name',
                order: true,
                style: { minWidth: '50px' }              
            },
            {
                text: 'Fecha',
                dataField: 'mov_date',
                order: true,
                style: { minWidth: '50px' },
                classes: 'column-grey'
            },
            {
                text: 'Día',
                dataField: 'mov_date_day',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Folio Cliente',
                dataField: 'folio',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Folio Proveedor',
                dataField: 'mov_provider.folio',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Ruta',
                dataField: 'mov_provider.origin',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Proveedor',
                dataField: 'provider_account.name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Producto',
                dataField: 'product',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Pzs',
                dataField: 'pieces',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { pieces, deleted_at }) => deleted_at ? '0.0' : (pieces !== '0.00' ? nf.format(pieces) : '')
            },
            {
                text: 'KGS',
                dataField: 'kgs',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { kgs, deleted_at }) => deleted_at ? '0.0' : (kgs !== '0.000000' ? nf.format(kgs) : '')
            },
            {
                text: 'Prom',
                dataField: 'prom',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { prom, deleted_at }) => deleted_at ? '0.0' : (prom !== '0.000000' ? nf.format(prom) : '')
            },
            {
                text: 'PV',
                dataField: 'pv',
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { pv, deleted_at }) => deleted_at ? '0.0' : (pv !== '0.00' ? nf.format(pv) : '')
            },
            {
                text: 'Total vta',
                dataField: 'total_vta',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                classes: 'column-grey',
                formatter: (cell, { total_vta, deleted_at }) => deleted_at ? '0.0' : (total_vta !== '0.00' ? nfAmount(total_vta) : '')
            },
            {
                text: 'Merma',
                dataField: 'decrease',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { decrease, deleted_at }) => deleted_at ? '0.0' : (decrease !== '0.00' ? nfAmount(decrease) : '')
            },
            {
                text: 'Mortalidad',
                dataField: 'mortalidad_descuento',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { mortalidad_descuento, deleted_at }) => deleted_at ? '0.0' : ((mortalidad_descuento !== '0.00') ? nfAmount(mortalidad_descuento) : '')
            },
            {
                text: 'Otros Descuentos',
                dataField: 'nota_credito',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { nota_credito, deleted_at }) => deleted_at ? '0.0' : (nota_credito !== '0.00' ? nfAmount(nota_credito) : '')
            },
            {
                text: 'Otros Cargos',
                dataField: 'nota_cargo',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { nota_cargo, deleted_at }) => deleted_at ? '0.0' : (nota_cargo !== '0.00' ? nfAmount(nota_cargo) : '')
            },
            {
                text: 'Venta Neta',
                dataField: 'venta_neta',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                classes: 'column-grey',
                formatter: (cell, { venta_neta, deleted_at }) => deleted_at ? '0.0' : (venta_neta !== '0.00' ? nfAmount(venta_neta) : '')
            },
            {
                text: 'IVA',
                dataField: 'iva',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { iva, deleted_at }) => deleted_at ? '0.0' : (Number(iva) > 0 ? nfAmount(iva) : '')
            },
            {
                text: 'Retenciones',
                dataField: 'retencion',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { retencion, deleted_at }) => deleted_at ? '0.0' : (Number(retencion) > 0 ? nfAmount(retencion) : '')
            },
            {
                text: 'Total',
                dataField: 'total',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                classes: 'column-grey',
                formatter: (cell, { total, deleted_at }) => deleted_at ? '0.0' : (total !== '0.00' ? nfAmount(total) : '')
            },
            {
                text: 'Cobro',
                dataField: 'cobro',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { cobro, deleted_at }) => deleted_at ? '0.0' : (cobro !== '0.00' ? nfAmount(cobro) : '')
            },
            {
                text: 'Saldo',
                dataField: 'saldo',
                order: true,
                style: { textAlign: 'right', minWidth: '90px' },
            },
            {
                text: 'Banco',
                dataField: 'payment_account.name',
                order: true,
                style: { minWidth: '50px' }
            }, 
            {
                text: 'Forma de Pago',
                dataField: 'payment_type',
                order: true,
                style: { minWidth: '50px' }
            },
            
            {
                text: 'Banco folio',
                dataField: 'banco_folio',

                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Descripción bancaria',
                dataField: 'folio2',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Observaciones',
                dataField: 'observations',
                order: true,
                style: { minWidth: '50px' }
            }
        ],
        columns_history: [
            {
                text: 'Fecha de cambio',
                dataField: 'cancelled_at',
                formatter: (cell, { cancelled_at }) => cancelled_at && moment(cancelled_at).format('YYYY-MM-DD')
            },            
            {
                text: 'Usuario',
                dataField: 'cancelled_by_name'
            },
            {
                text: 'Cliente',
                dataField: 'c_client_name',
                formatter: (cell, { client_account, c_client_name }) => client_account?.name ? client_account.name : c_client_name
            },
            {
                text: 'Fecha',
                dataField: 'mov_date',
                order: true,
                style: { minWidth: '100px' },
                classes: 'column-grey'
            },
            {
                text: 'Folio',
                dataField: 'folio',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Rem/Prov',
                dataField: 'rem',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Proveedor',
                dataField: 'c_provider_name',
                style: { minWidth: '50px' },
                formatter: (cell, { provider_account, c_provider_name }) => provider_account?.name ? provider_account.name : c_provider_name
            },
            {
                text: 'Producto',
                dataField: 'product',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Pzs',
                dataField: 'pieces',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { pieces }) => pieces ? nf.format(pieces) : '0.00'
            },
            {
                text: 'KGS',
                dataField: 'kgs',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { kgs }) => kgs ? nf.format(kgs) : '0.00'
            },
            {
                text: 'Prom',
                dataField: 'prom',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { prom }) => prom ? nf.format(prom) : '0.00'
            },
            {
                text: 'PV',
                dataField: 'pv',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { pv }) => pv ? nf.format(pv) : '0.00'
            },
            {
                text: 'Total vta',
                dataField: 'total_vta',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                classes: 'column-grey',
                formatter: (cell, { total_vta }) => total_vta ? nf.format(total_vta) : '0.00'
            },
            {
                text: 'Merma Mortalidad',
                dataField: 'decrease',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { decrease }) => decrease !== '0.00' ? nfAmount(decrease) : ''
            },
            {
                text: 'Nota Cargo',
                dataField: 'nota_cargo',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { nota_cargo }) => nota_cargo ? nf.format(nota_cargo) : '0.00'
            },
            {
                text: 'Nota Crédito',
                dataField: 'nota_credito',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { nota_credito }) => nota_credito ? nf.format(nota_credito) : '0.00'
            },
            {
                text: 'Venta Neta',
                dataField: 'venta_neta',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                classes: 'column-grey',
                formatter: (cell, { venta_neta }) => venta_neta ? nf.format(venta_neta) : '0.00'
            },
            {
                text: 'IVA',
                dataField: 'iva',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { iva }) => Number(iva) > 0 ? nfAmount(iva) : ''
            },
            {
                text: 'Retenciones',
                dataField: 'retencion',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { retencion }) => Number(retencion) > 0 ? nfAmount(retencion) : ''
            },
            {
                text: 'Total',
                dataField: 'total',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                classes: 'column-grey',
                formatter: (cell, { total }) => total ? nf.format(total) : '0.00'
            },
            {
                text: 'Forma de Pago',
                dataField: 'c_payment_name',
                order: true,
                style: { minWidth: '50px' },
                formatter: (cell, { payment_account, c_payment_name }) => payment_account?.name ? payment_account.name : c_payment_name
            },
            {
                text: 'Cobro',
                dataField: 'cobro',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { cobro }) => cobro ? nf.format(cobro) : '0.00'
            },
            {
                text: 'Saldo',
                dataField: 'saldo',
                order: true,
                style: { textAlign: 'right' }
            },
            {
                text: 'Merma',
                dataField: 'merma',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Referencia',
                dataField: 'reference',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Folio 2',
                dataField: 'folio2',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Banco',
                dataField: 'banco',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Banco folio',
                dataField: 'banco_folio',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Observaciones',
                dataField: 'observations',
                order: true,
                style: { minWidth: '50px' }
            }
        ]
    },
    [REPORTS.PROVIDERS] : {
        id: '1',
        title: 'Compras',
        title_singular: 'Compra',
        type: REPORT_TYPE.CLIENT_PROVIDERS ,
        url_file: URL.UPLOAD_FILE_MOV_PROVIDERS,
        url_data: URL.GET_MOV_PROVIDERS,
        url_delete: URL.DELETE_MOV_PROVIDER,
        url_history: URL.GET_MOV_PROVIDERS_HISTORY,
        url_format: 'formatos/proveedores.xlsx',
        report_config_name: 'clients-providers_compras',
        saldos: {
            ini: null,
            fin: null,
        },
        column_names: [
            {
                text: 'Proveedor',
                dataField: 'provider_account.name',
                order: true, 
                style: { minWidth: '50px' }
            },
            {
                text: 'Fecha',
                dataField: 'mov_date',
                order: true,
                style: { minWidth: '50px' },
                classes: 'column-grey'
            },
            {
                text: 'Día',
                dataField: 'mov_date_day',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Folio',
                dataField: 'folio',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Factura',
                dataField: 'invoice',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Remision',
                dataField: 'rem',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Ruta',
                dataField: 'origin',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Producto',
                dataField: 'product',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'No pzas',
                dataField: 'pieces',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { pieces, deleted_at }) => deleted_at ? '0.0' : (pieces !== '0.00' ? nf.format(pieces) : '')
            },
            {
                text: 'KG',
                dataField: 'kgs',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { kgs, deleted_at }) => deleted_at ? '0.0' : (kgs !== '0.00' ? nf.format(kgs) : '')
            },
            {
                text: 'Promedio',
                dataField: 'prom',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { prom, deleted_at }) => deleted_at ? '0.0' : (prom !== '0.00' ? nf.format(prom) : '')
            },
            {
                text: 'Precio compra',
                dataField: 'cost',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { cost, deleted_at }) => deleted_at ? '0.0' : (cost !== '0.00' ? nfAmount(cost) : '')
            },
            {
                text: 'Total Compra',
                dataField: 'total',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                classes: 'column-grey',
                formatter: (cell, { total, deleted_at }) => deleted_at ? '0.0' : (total !== '0.00' ? nfAmount(total) : '')
            },
            {
                text: 'Merma',
                dataField: 'decrease',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { decrease, deleted_at }) => deleted_at ? '0.0' : (decrease !== '0.00' ? nfAmount(decrease) : '')
            },
            {
                text: 'Otros descuentos',
                dataField: 'discount',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { discount, deleted_at }) => deleted_at ? '0.0' : (discount !== '0.00' ? nfAmount(discount) : '')
            },
            {
                text: 'Otros cargos',
                dataField: 'other_charges',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { other_charges, deleted_at }) => deleted_at ? '0.0' : (Number(other_charges) > 0 ? nfAmount(other_charges) : '')
            },
            {
                text: 'Compras netas',
                dataField: 'net',
                order: true,
                classes: 'column-grey',
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { net, deleted_at }) => deleted_at ? '0.0' : (net !== '0.00' ? nfAmount(net) : '')
            },  
            {
                text: 'IVA',
                dataField: 'iva',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { iva, deleted_at }) => deleted_at ? '0.0' : (Number(iva) > 0 ? nfAmount(iva) : '')
            },
            {
                text: 'Retenciones',
                dataField: 'retencion',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { retencion, deleted_at }) => deleted_at ? '0.0' : (Number(retencion) > 0 ? nfAmount(retencion) : '')
            },
            {
                text: 'Total',
                dataField: 'total_total',
                order: true,
                classes: 'column-grey',
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { total_total, deleted_at }) => deleted_at ? '0.0' : (total_total !== '0.00' ? nfAmount(total_total) : '')
            },         
            {
                text: 'Total Pagos',
                dataField: 'payment',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { payment, deleted_at }) => deleted_at ? '0.0' : (payment !== '0.00' ? nfAmount(payment) : '')
            },
            {
                text: 'Saldo',
                dataField: 'saldo',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' }
            },
            {
                text: 'Descripción',
                dataField: 'payment_account.name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Forma de pago',
                dataField: 'payment_type',
                order: true,
                style: { minWidth: '70px' }
            },
            {
                text: 'Descripción bancaria',
                dataField: 'desc_bank',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Observaciones',
                dataField: 'observations',
                order: true,
                style: { minWidth: '50px' }
            }
        ],
        columns_history: [
            {
                text: 'Fecha de cambio',
                dataField: 'cancelled_at',
                formatter: (cell, { cancelled_at }) => cancelled_at && moment(cancelled_at).format('YYYY-MM-DD')
            },            
            {
                text: 'Usuario',
                dataField: 'cancelled_by_name'
            },
            {
                text: 'Proveedor',
                dataField: 'c_provider_name',
                formatter: (cell, { provider_account, c_provider_name }) => provider_account?.name ? provider_account.name : c_provider_name
            },
            {
                text: 'Fecha',
                dataField: 'mov_date',
                order: true,
                style: { minWidth: '50px' },
                classes: 'column-grey'
            },
            {
                text: 'Factura',
                dataField: 'invoice',
                order: true
            },
            {
                text: 'Remision',
                dataField: 'rem',
                order: true
            },
            {
                text: 'Sucursal',
                dataField: 'origin',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Producto',
                dataField: 'product',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'No pzas',
                dataField: 'pieces',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { pieces }) => pieces ? nf.format(pieces) : '0.00'
            },
            {
                text: 'KG',
                dataField: 'kgs',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { kgs }) => kgs ? nf.format(kgs) : '0.00'
            },
            {
                text: 'Promedio',
                dataField: 'prom',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { prom }) => prom ? nf.format(prom) : '0.00'
            },
            {
                text: 'Precio compra',
                dataField: 'cost',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { cost }) => cost ? nf.format(cost) : '0.00'
            },
            {
                text: 'Compra total',
                dataField: 'total',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                classes: 'column-grey',
                formatter: (cell, { total }) => total ? nf.format(total) : '0.00'
            },
            {
                text: 'Merma',
                dataField: 'decrease',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { decrease }) => decrease ? nf.format(decrease) : '0.00'
            },
            {
                text: 'Otros Cargos',
                dataField: 'other_charges',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { other_charges }) => Number(other_charges) > 0 ? nfAmount(other_charges) : ''
            },
            {
                text: 'Compras netas',
                dataField: 'net',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { net }) => net ? nf.format(net) : '0.00'
            },
            {
                text: 'IVA',
                dataField: 'iva',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { iva }) => Number(iva) > 0 ? nfAmount(iva) : ''
            },
            {
                text: 'Retenciones',
                dataField: 'retencion',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { retencion }) => Number(retencion) > 0 ? nfAmount(retencion) : ''
            },
            {
                text: 'Total',
                dataField: 'total_total',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { total_total }) => total_total !== '0.00' ? nfAmount(total_total) : ''
            },
            {
                text: 'Descripción',
                dataField: 'c_payment_name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Monto',
                dataField: 'payment',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { payment }) => payment ? nf.format(payment) : '0.00'
            },
            {
                text: 'Saldo',
                dataField: 'saldo',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                formatter: (cell, { saldo }) => saldo ? nf.format(saldo) : '0.00'
            },
            {
                text: 'Observaciones',
                dataField: 'observations',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Folio',
                dataField: 'folio',
                order: true
            }
        ]
    },
    [REPORTS.WAREHOUSE_CHECK] : {
        id: '2',
        title: 'Almacén',
        title_singular: 'Comprobación almacén',
        type: REPORT_TYPE.CLIENT_PROVIDERS ,
        url_file: '',
        url_data: URL.GET_WAREHOUSE,
        url_history: '',
        url_format: '',
        saldos: {
            ini: null,
            fin: null,
        },
        column_names: [
            {
                text: 'Fecha',
                dataField: 'date',
                order: true,
                classes: 'column-grey',
                headerStyle: () => ({minWidth:'80px'}),
                isSortable: true
            },
            {
                text: 'Folio',
                dataField: 'folio',
                order: false,
                classes: 'column-grey',
                headerStyle: () => ({minWidth:'80px'}),
                isSortable: true
            },
            {
                text: 'Proveedor ',
                dataField: 'provider',
                formatter: (cell, row) => row?.provider ? row.provider : 'Todos',
                headerStyle: () => ({minWidth:'50px'}),
                isSortable: true
            },     
            {
                text: 'Producto',
                dataField: 'product',
                order: false,
                classes: 'column-grey',
                headerStyle: () => ({minWidth:'80px'}),
                isSortable: true
            },       
            {
                text: 'Entradas (Piezas)',
                dataField: 'provider_pieces',
                headerStyle: () => ({minWidth:'50px'}),
                isSortable: true,
                formatter: (cell, { provider_pieces }) => provider_pieces !== '0.00' ? nf.format(provider_pieces) : '0'
            },
            {
                text: 'Salidas',
                dataField: 'client_pieces',
                headerStyle: () => ({minWidth:'50px'}),
                isSortable: true,
                formatter: (cell, { client_pieces }) => client_pieces !== '0.00' ? nf.format(client_pieces) : '0'
            },
            {
                text: 'Ajustes',
                dataField: 'adjust_pieces',
                headerStyle: () => ({minWidth:'50px'}),
                isSortable: true,
                formatter: (cell, { adjust_pieces }) => adjust_pieces !== '0.00' ? nf.format(adjust_pieces) : '0'
            },
            {
                text: 'Diferencias',
                dataField: 'diff_pieces',
                headerStyle: () => ({minWidth:'50px'}),
                isSortable: true,
                formatter: (cell, { diff_pieces }) => diff_pieces !== '0.00' ? nf.format(diff_pieces) : '0'
            },
            {
                text: 'Entradas (Kgs)',
                dataField: 'provider_kgs',
                headerStyle: () => ({minWidth:'50px'}),
                isSortable: true,
                formatter: (cell, { provider_kgs }) => provider_kgs !== '0.00' ? nf.format(provider_kgs) : '0'
            },
            {
                text: 'Salidas',
                dataField: 'client_kgs',
                headerStyle: () => ({minWidth:'50px'}),
                isSortable: true,
                formatter: (cell, { client_kgs }) => client_kgs !== '0.00' ? nf.format(client_kgs) : '0'
            },
            {
                text: 'Ajustes',
                dataField: 'adjust_kgs',
                headerStyle: () => ({minWidth:'50px'}),
                isSortable: true,
                formatter: (cell, { adjust_kgs }) => adjust_kgs !== '0.00' ? nf.format(adjust_kgs) : '0'
            },
            {
                text: 'Diferencias',
                dataField: 'diff_kgs',
                headerStyle: () => ({minWidth:'50px'}),
                isSortable: true,
                formatter: (cell, { diff_kgs }) => diff_kgs !== '0.00' ? nf.format(diff_kgs) : '0'
            },
            {
                text: 'Observaciones',
                dataField: 'adjust_comment',
                isSortable: false
            },
        ],
        columns_history: []
    },    
    [REPORTS.BANKS]: {
        id: '3',
        title: 'Bancos',
        title_singular: 'Banco',
        type: REPORT_TYPE.BANKS,
        url_file: URL.UPLOAD_FILE_MOV_BANKS,
        url_data: URL.GET_MOV_BANKS,
        url_delete: URL.DELETE_MOV_BANK,
        url_history: URL.GET_MOV_BANKS_HISTORY,
        url_format: 'formatos/bancos.xlsx',
        saldos: {
            ini: null,
            fin: null,
        },
        column_names: [
            {
                text: 'Banco',
                dataField: 'bank_account.name',
                order: true
            },
            {
                text: 'Fecha',
                dataField: 'mov_date',
                order: true
            },
            {
                text: 'Día',
                dataField: 'mov_date_day',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Folio',
                dataField: 'folio',
                order: true
            },
            {
                text: 'Cliente/Proveedor',
                dataField: 'client_account.name',
                order: true,
            },
            {
                text: 'Depósito',
                dataField: 'deposit',
                order: true,
                formatter: (cell, { deposit }) => deposit !== '0.00' ? nf.format(deposit) : '',
                classes: 'text-end'
            },
            {
                text: 'Pago',
                dataField: 'payment',
                order: true,
                formatter: (cell, { payment }) => payment !== '0.00' ? nf.format(payment) : '',
                classes: 'text-end'
            },
            {
                text: 'Descripción',
                dataField: 'description',
                order: true
            },
            {
                text: 'Observaciones',
                dataField: 'observation',
                order: true
            },
            {
                text: 'Conciliado',
                dataField: 'conciliado',
                order: true,
                formatter: (cell, { mov_clients }) => mov_clients?.length ? 'Sí' : 'No',
            },
        ],
        columns_history: [
            {
                text: 'Fecha de cambio',
                dataField: 'cancelled_at',
                formatter: (cell, { cancelled_at }) => cancelled_at && moment(cancelled_at).format('YYYY-MM-DD')
            },            
            {
                text: 'Usuario',
                dataField: 'cancelled_by_name'
            },
            {
                text: 'Banco',
                dataField: 'c_bank_name',
                formatter: (cell, { bank_account, c_bank_name }) => bank_account?.name ? bank_account.name : c_bank_name
            },
            {
                text: 'Fecha',
                dataField: 'mov_date',
                order: true
            },
            {
                text: 'Folio',
                dataField: 'folio',
                order: true
            },
            {
                text: 'Cliente/Proveedor',
                dataField: 'c_client_name',
                formatter: (cell, { client_account, c_client_name }) => client_account?.name ? client_account.name : c_client_name
            },
            {
                text: 'Depósito',
                dataField: 'deposit',
                order: true,
                formatter: (cell, { deposit }) => deposit ? nf.format(deposit) : '0.00'
            },
            {
                text: 'Pago',
                dataField: 'payment',
                order: true,
                formatter: (cell, { payment }) => payment !== '0.00' ? nf.format(payment) : '',
                classes: 'text-end'
            },
            {
                text: 'Descripción',
                dataField: 'description',
                order: true
            },
            {
                text: 'Observaciones',
                dataField: 'observation',
                order: true
            }
        ]
    },
    [REPORTS.CHARGES]: {
        id: '4',
        title: 'Cargos',
        title_singular: 'Cargo',
        type: null,
        url_file: '',
        url_data: URL.GET_CHARGES,
        url_delete: '',
        url_history: '',
        url_format: '',
        column_names: [
            {
                text: 'Proveedor ',
                dataField: 'provider',
                formatter: (cell, row) => row?.provider ? row.provider : 'Todos',
                order: true
            },
            {
                text: 'Fecha',
                dataField: 'date',
                order: true,
                style: { minWidth: '50px' },
                classes: 'column-grey'
            },
            {
                text: 'Proveedor',
                dataField: 'provider_charge',
                order: true                
            },  
            {
                text: 'Cliente',
                dataField: 'client_charge',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Diferencia',
                dataField: 'charges_diff',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Observaciones',
                dataField: 'observations',
                order: true
            }
        ],
        columns_history: [
            {
                text: 'Fecha de cambio',
                dataField: 'cancelled_at',
                formatter: (cell, row) => moment(row.cancelled_at).format('YYYY-MM-DD')
            },            
            {
                text: 'Usuario',
                dataField: 'cancelled_by_name'
            },
            {
                text: 'Cliente',
                dataField: 'c_client_name',
                order: true                
            },
            {
                text: 'Fecha',
                dataField: 'mov_date',
                order: true,
                style: { minWidth: '50px' },
                classes: 'column-grey'
            },
            {
                text: 'Folio',
                dataField: 'folio',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'REM',
                dataField: 'rem',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Proveedor',
                dataField: 'c_provider_name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Producto',
                dataField: 'product',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Pzs',
                dataField: 'pieces',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
            },
            {
                text: 'KGS',
                dataField: 'kgs',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' }
            },
            {
                text: 'Prom',
                dataField: 'prom',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' }
            },
            {
                text: 'PV',
                dataField: 'pv',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' }
            },
            {
                text: 'Total vta',
                dataField: 'total_vta',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' },
                classes: 'column-grey'
            },
            {
                text: 'Forma de Pago',
                dataField: 'c_payment_name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Cobro',
                dataField: 'cobro',
                order: true,
                style: { minWidth: '50px', textAlign: 'right' }
            },
            {
                text: 'Saldo',
                dataField: 'saldo',
                order: true,
                style: { textAlign: 'right' }
            },
            {
                text: 'Referencia',
                dataField: 'reference',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Folio 2',
                dataField: 'folio2',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Banco',
                dataField: 'banco',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Banco folio',
                dataField: 'banco_folio',
                order: true,
                style: { minWidth: '50px' }
            }
        ]
    },
    [REPORTS.BANKS_CHARGES]: {
        id: '5',
        title: 'Diferencias',
        title_singular: 'Diferencias',
        type: REPORT_TYPE.BANKS ,
        url_file: '',
        url_data: URL.GET_BANKS_CHARGES,
        url_delete: '',
        url_history: '',
        url_format: '',
        column_names: [
            /* {
                text: 'Proveedor ',
                dataField: 'provider',
                formatter: (cell, row) => row?.provider ? row.provider : 'Todos',
                order: true
            }, */
            {
                text: 'Nombre del banco',
                dataField: 'bank_name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Fecha',
                dataField: 'date',
                order: true,
                style: { minWidth: '50px' },
                classes: 'column-grey'
            },
            {
                text: 'Banco',
                dataField: 'bank_charge',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },  
            {
                text: 'Cliente',
                dataField: 'client_charge',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Diferencia',
                dataField: 'charges_diff',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Observaciones',
                dataField: 'observations',
                order: true
            }
        ],
        columns_history: []
    },
    [REPORTS.COSTS]: {
        id: '5',
        title: 'Gastos',
        title_singular: 'Diferencias',
        type: REPORT_TYPE.COSTS ,
        url_file: '',
        url_data: URL.GET_COSTS_MOV,
        url_delete: URL.DELETE_COST,
        url_history: URL.GET_COST_HISTORY,
        url_format: '',
        column_names: [
            {
                text: 'Consecutivo',
                dataField: 'folio',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Fecha',
                dataField: 'date',
                order: true,
                style: { minWidth: '50px' },
                classes: 'column-grey'
            },
            {
                text: 'Folio factura',
                dataField: 'invoice',
                order: true,
                style: { minWidth: '80px', textAlign: 'right' }
            },
            {
                text: 'Proveedor',
                dataField: 'provider.name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Cuenta contable',
                dataField: 'account.name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Importe',
                dataField: 'amount',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Iva',
                dataField: 'iva',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Total',
                dataField: 'total',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Banco',
                dataField: 'bank.name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Pago',
                dataField: 'payment',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Forma de pago',
                dataField: 'payment_type',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },            
            {
                text: 'Banco folio',
                dataField: 'bank_folio',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Observaciones',
                dataField: 'observations',
                order: true
            }
        ],
        columns_history: [
            {
                text: 'Fecha de cambio',
                dataField: 'cancelled_at',
                formatter: (cell, { cancelled_at }) => cancelled_at && moment(cancelled_at).format('YYYY-MM-DD')
            },            
            {
                text: 'Usuario',
                dataField: 'cancelled_by_name'
            },
            {
                text: 'Cuenta contable',
                dataField: 'account.name',
                order: true,
                style: { minWidth: '50px' }
            },
            {
                text: 'Fecha',
                dataField: 'date',
                order: true,
                style: { minWidth: '50px' },
                classes: 'column-grey'
            },
            {
                text: 'Concepto',
                dataField: 'name',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },  
            {
                text: 'Importe',
                dataField: 'amount',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Iva',
                dataField: 'iva',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Total',
                dataField: 'total',
                order: true,
                style: { minWidth: '70px', textAlign: 'right' }
            },
            {
                text: 'Observaciones',
                dataField: 'observations',
                order: true
            }
        ]
    }
};