import PageBase from "components/Common/PageBase"
import React from "react"
import { Card, CardBody, Nav, NavItem, Row } from "reactstrap"
import ConfigForm from "./components/ConfigForm"
import Tabs, { tabName } from "./components/Tabs"
import useBillingConfiguration from "./useBillingConfiguration"
import { cardBodyStyles } from "./static"
import TaxDataTab from "./components/TaxDataTab"
import CsdCatalogsTab from "./components/CsdCatalogsTab"
import DefaultConfigTab from "./components/DefaultConfigTab"
import ConfigFoliosTab from "./components/ConfigFoliosTab"

const breadcrumbList = [
  { id: "2", title: "Configuración de facturación", link: "", active: true },
]

const BillingConfiguration = () => {
  const { state, events } = useBillingConfiguration()

  const renderTabContent = () => {
    switch (state.tabActive) {
      case tabName.otherCharges:
      case tabName.otherDiscounts:
        return <ConfigForm tabActive={state.tabActive} />
      case tabName.taxData:
        return <TaxDataTab enterprise={state.enterprise} />
      case tabName.csdsCatalogs:
        return <CsdCatalogsTab />
      case tabName.defaultConcepts:
        return <DefaultConfigTab />
      case tabName.configurationFolios:
        return <ConfigFoliosTab />
      default:
        return null
        break
    }
  }

  return (
    <>
      {/* {isLoading ? <FullSpinner /> : ""} */}
      <PageBase
        titleMeta="Configuración de facturación"
        titleBread="Configuración de facturación"
        itemsBread={breadcrumbList}
      >
        <Tabs tabActive={state.tabActive} setTabActive={events.setTabActive} />
        <Card>
          <CardBody style={cardBodyStyles}>{renderTabContent()}</CardBody>
        </Card>
      </PageBase>
    </>
  )
}

export default BillingConfiguration
