import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getOrder } from '../../store/orders/actions';

import {
    Row,
    Col,
    Card,
    CardBody,
    Spinner
} from 'reactstrap';

import PageBase from 'components/Common/PageBase';
import OrderHeader from './OrderHeader';
import OrderSummary from './OrderSummary';
import Comments from './Comments';
import PageNotFound from 'pages/Utility/PageNotFound';

import 'flatpickr/dist/themes/material_red.css';
import OrderRating from './OrderRating';


const breadcrumbList = [
    { id: '1', title: 'Pedidos', link:'/distributor/orders', active: false },
    { id: '2', title: 'Detalle del pedido', link: '#', active: true }
];

export default function OrderContainer() {   
    const dispatch    = useDispatch();
    const { orderId } = useParams(); 

    const { orderActive, ui } = useSelector(state => state.Orders);

    const { isLoading, isError } = ui;
    const { id, provider, client, items, order_date, comments, rating, status, status_string } = orderActive;
    
    useEffect(() => {
        dispatch(
            getOrder(orderId)
        );
    }, []);

    if(isError) return <PageNotFound />

    return (
        <>  
            <PageBase
                titleMeta="Detalle del pedido"
                titleBread="Pedido"
                itemsBread={ breadcrumbList }
            >
                {
                    isLoading ? <Spinner /> : null
                }          
                { !isLoading && !isEmpty(orderActive) && (
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>                                    
                                    <OrderHeader 
                                        id={ id }
                                        provider={ provider }
                                        client={ client }
                                        status={ status } 
                                        status_string={ status_string}
                                        order_date={ order_date }
                                    />
                                    <OrderSummary 
                                        items={ items }
                                    />
                                    <Comments
                                        comments={ comments }
                                    />
                                    {
                                        status === 'completed' ? (
                                            <OrderRating 
                                                rating={ rating }
                                                orderStatus={ status } 
                                            />
                                        ) : null
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </PageBase>
        </>
    )
}