import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TypeForm } from "./RightForms/TypeForm"
import "./custom.scss"
import FullSpinner from "components/Common/FullSpinner"
import PageBase from "components/Common/PageBase"
import Filters from "./Filters"
import { Card, CardBody } from "reactstrap"
import OffCanvas from "../common/OffCanvas"
import { getCostData } from "helpers/backend_helper"
import { getAccountingAcounts } from "../../../store/accounting-accounts/actions"
import * as moment from "moment"
import DataTable from "../common/DataTable"
import {
  setActiveTabAdminiData,
  setTypeAdminiData,
} from "store/catalogs/administrative-data/actions"
import { REPORTS } from "store/catalogs/administrative-data/actionsTypes"
import { REPORT_TYPE } from "../../../store/catalogs/administrative-data/actionsTypes"
import ModalDelete from "../common/ModalDelete"
import ModalHistory from "../common/ModalHistory"
const breadcrumbList = [
  { id: "1", title: "Administrativo", link: "", active: false },
  { id: "2", title: "Gastos", link: "", active: true },
]

const defaultFormValues = {
  /* name: '',
    amount: 0,
    iva: 0,
    total: 0,
    payment:0,
    saldo:0,
    observations: '',
    date: moment().format('YYYY-MM-DD'),
    account_id: { id: '', name: '' } */
}

export default function CostsContainer() {
  const defaultSelect = { id: "", name: "" }
  const dispatch = useDispatch()
  const [isNew, setIsNew] = useState(false)
  const [isRight, setIsRight] = useState(false)
  const [dataRow, setDataRow] = useState(defaultFormValues)
  const [data, setData] = useState([])
  const [modalDelete, setModalDelete] = useState(false)
  const [modalHistory, setModalHistory] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    activeTab,
    dataReports,
    ui: { isLoading },
  } = useSelector(state => state.AdministrativeData)

  const { costAccounts } = useSelector(state => state.AccountingAccounts)

  const { title } = dataReports[activeTab]
  const [columnOrder, setColumnOrder] = useState({
    column: "",
    order: "",
  })
  const { column, order } = columnOrder

  const handleEdit = row => {
    const parsedRow = {
      ...row,
      movs_costs_sales: row?.movs_costs_sales?.map(mov => ({
        id: mov.id,
        name: mov.date + " - f-" + (mov.folio ?? "") + " - $" + mov.total,
        import: Number(mov.pivot.import),
        folio: mov.folio,
        pendingToPayment: mov.pendingToPayment,
      })),
    }

    setIsNew(false)
    setDataRow(parsedRow)
    setIsRight(true)
  }

  const handleDelete = row => {
    setModalDelete(prev => !prev)
    setDataRow(row)
  }

  const handleHistory = row => {
    setModalHistory(true)
    setDataRow(row)
  }

  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  useEffect(() => {
    dispatch(setActiveTabAdminiData(REPORTS.COSTS))
    dispatch(setTypeAdminiData(REPORT_TYPE.COSTS))
  }, [])

  useEffect(() => {
    dispatch(getAccountingAcounts())
  }, [])

  return (
    <>
      {isLoading ? <FullSpinner /> : ""}
      <PageBase
        titleMeta="Gastos"
        titleBread="Gastos"
        itemsBread={breadcrumbList}
      >
        <Card>
          <CardBody>
            {activeTab == "costos" && (
              <Filters
                setIsNew={setIsNew}
                toggleRightCanvas={toggleRightCanvas}
                setData={setData}
                accounts={costAccounts}
              />
            )}
            <br />
            {activeTab == "costos" && (
              <DataTable
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleHistory={handleHistory}
              />
            )}

            <OffCanvas
              isNew={isNew}
              isRight={isRight}
              toggleRightCanvas={toggleRightCanvas}
              reportTitle={title}
            >
              <TypeForm
                isNew={isNew}
                activeTab={activeTab}
                dataRow={dataRow}
                toggleRightCanvas={toggleRightCanvas}
                accounts={costAccounts}
                setDataRow={setDataRow}
                setData={setData}
                data={data}
              />
            </OffCanvas>
            <ModalDelete
              isOpen={modalDelete}
              setOpenModal={setModalDelete}
              row={dataRow}
            />
            {modalHistory && (
              <ModalHistory
                isOpen={modalHistory}
                setOpenModal={setModalHistory}
                row={dataRow}
              />
            )}
          </CardBody>
        </Card>
      </PageBase>
    </>
  )
}
