// MyGoogleMaps.js
import React, { Component } from "react"

import GoogleMapReact from "google-map-react"

import styled from "styled-components"

import AutoComplete from "./Autocomplete"
import Marker from "./Marker"
import { PropTypes } from "prop-types"

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`

class MyGoogleMap extends Component {
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: this.props.startPosition
      ? [this.props.startPosition?.lat, this.props.startPosition?.lng]
      : [19.04451, -98.198576],
    zoom: 16,
    address: "",
    draggable: true,
    lat: this.props.startPosition?.lat ?? 19.04451,
    lng: this.props.startPosition?.lng ?? -98.198576,
  }

  UNSAFE_componentWillMount() {
    //this.setCurrentLocation();
  }

  onMarkerInteraction = (childKey, childProps, mouse) => {
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    })
  }
  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true })
    this._generateAddress()
  }

  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    })
    // not
  }

  _onClick = value => {
    this.setState({
      lat: value.lat,
      lng: value.lng,
    })
    this._generateAddress()
    this.props.setPosition({ lat: value.lat, lng: value.lng })
  }

  onChildClick = (evt, a) => {
    console.log("child click", evt, a)
    this._generateAddress()
    this.props.setPosition({ lat: a.lat, lng: a.lng })
  }

  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    })

    this._generateAddress()
  }

  addPlace = place => {
    this.setState({
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    })
    this._generateAddress()
    console.log("place", place)
    this.props.setPosition({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    })
  }

  _generateAddress() {
    const { mapApi } = this.state

    const geocoder = new mapApi.Geocoder()

    geocoder.geocode(
      { location: { lat: this.state.lat, lng: this.state.lng } },
      (results, status) => {
        console.log(results)
        console.log(status)
        if (status === "OK") {
          if (results[0]) {
            this.setState({ address: results[0].formatted_address })
            this.props.setAddress(results[0].formatted_address)
          } else {
            this.setState({ address: '' })
            this.props.setAddress('')
            //window.alert("No results found")
          }
        } else {
          this.setState({ address: '' })
          this.props.setAddress('')
          //window.alert("Geocoder failed due to: " + status)
        }
      }
    )
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          center: [position.coords.latitude, position.coords.longitude],
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      })
    }
  }

  render() {
    const { places, mapApiLoaded, mapInstance, mapApi } = this.state
    console.log('this.props.startPosition', this.props.startPosition)

    return (
      <>
        {mapApiLoaded && (
          <div>
            <AutoComplete
              map={mapInstance}
              mapApi={mapApi}
              addplace={this.addPlace}
            />
          </div>
        )}

        <Wrapper style={{ minHeight: "300px", height: '300px' }}>
          <GoogleMapReact
            center={this.state.center}
            zoom={this.state.zoom}
            draggable={this.state.draggable}
            onChange={this._onChange}
            onChildMouseDown={this.onMarkerInteraction}
            onChildMouseUp={this.onMarkerInteractionMouseUp}
            onChildMouseMove={this.onMarkerInteraction}
            onChildClick={this.onChildClick}
            onClick={this._onClick}
            bootstrapURLKeys={{
              key: "AIzaSyAQcgn4C-bn2LZC0z1INHc_H-Zt9_3hXMc",
              libraries: ["places", "geometry"],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
          >
            <Marker
              text={this.state.address}
              lat={this.state.lat}
              lng={this.state.lng}
            />
          </GoogleMapReact>

          <div>
            <div className="map-details d-none">
              Latitude: <span>{this.state.lat}</span>, Longitude:{" "}
              <span>{this.state.lng}</span>
            </div>
            <div className="map-details d-none">
              Zoom: <span>{this.state.zoom}</span>
            </div>
            <div className="">
              Dirección: <strong>{this.state.address}</strong>
            </div>
          </div>
        </Wrapper>
      </>
    )
  }
}

MyGoogleMap.propTypes = {
  setPosition: PropTypes.func,
  setAddress: PropTypes.func,
  startPosition: PropTypes.object,
}

export default MyGoogleMap
