import { GET_ACCOUNTING_ACCOUNTS, GET_ACCOUNTING_ACCOUNTS_FAIL, GET_ACCOUNTING_ACCOUNTS_SUCCESS } from "./actionTypes";

export const getAccountingAcounts = () => ({
    type: GET_ACCOUNTING_ACCOUNTS
});

export const getAccountingAcountsSuccess = data => ({
    type: GET_ACCOUNTING_ACCOUNTS_SUCCESS,
    payload: data
});

export const getAccountingAcountsFail = errors => ({
    type: GET_ACCOUNTING_ACCOUNTS_FAIL,
    payload: errors
});