import React, { useCallback } from "react"
import { Row, Col, Button, Modal, Form, Alert } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ClipLoader from "react-spinners/ClipLoader"

//Import Breadcrumb
import "../../../../pages/Catalogos/datatables.scss"
import { useState } from "react"
import { useEffect } from "react"
import {
  getEnterpriseCsds,
  deleteEnterpriseCsd,
  postEnterpriseCsd,
} from "helpers/backend_helper"
import usePage from "hooks/usePage"
import useRequest from "hooks/useRequest"
import { csdValidationSchema, initCsdValues } from "./static"
import { useFormik } from "formik"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"

const CsdCatalogsTab = () => {
  const { page, sizePerPage, setPage } = usePage()

  const [row, setRow] = useState(null)
  const [modal_standard, setmodal_standard] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)

  const storeRequest = useRequest()
  const deleteRequest = useRequest()

  async function handleDelete(evt, row) {
    deleteRequest.req(deleteEnterpriseCsd(row.id)).then(() => {
      getData()
      tog_standard()
    })
  }

  function buttonActions(cell, row, rIdx) {
    return (
      <span>
        <Button
          type="button"
          className="btn btn-sm btn-danger ms-1"
          onClick={evt => {
            tog_standard()
            setRow(row)
          }}
          title="Eliminar"
        >
          <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: "name",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "serial",
      text: "Serial",
      sort: true,
    },
    {
      dataField: "expiration_date",
      text: "Fecha de expiración",
      sort: true,
    },
    {
      dataField: "id",
      text: "Acciones",
      formatter: buttonActions,
    },
  ]

  // Table Data
  let [color, setColor] = useState("#f46a6a")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [totalSize, setTotalSize] = useState(0)

  const getData = useCallback(async () => {
    setLoading(true)
    const response = await getEnterpriseCsds()
    setLoading(false)
    setData(response)
    setTotalSize(response.length)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage,
    totalSize,
    custom: true,
    page,
    onPageChange: setPage,
    hideSizePerPage: true,
  }

  const { SearchBar } = Search

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  const afterSearch = results => {
    setTotalSize(results.length)
  }

  const onSubmit = formValues => {
    const formData = new FormData()

    for (const key in formValues) {
      formData.append(key, formValues[key])
    }

    storeRequest.req(postEnterpriseCsd(formData)).then(() => {
      getData()
      validation.resetForm()
      setOpenModalCreate(false)
    })
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initCsdValues,
    validationSchema: csdValidationSchema,
    onSubmit,
  })

  return (
    <>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={columns}
        data={data}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={data}
            search={{ afterSearch }}
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <Col md="4">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                          onSearch={data => {
                            setPage(1)
                            toolkitProps.searchProps.onSearch(data)
                          }}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                  <Col md="8" className="text-end">
                    <Button
                      onClick={() => setOpenModalCreate(true)}
                      className="btn btn-danger btn-sm"
                    >
                      <i className="fas fa-plus"></i> Crear
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        noDataIndication={
                          loading ? (
                            <ClipLoader
                              color={color}
                              loading={loading}
                              size={30}
                            />
                          ) : (
                            "Sin datos"
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Borrar CSD
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>¿Está seguro de borrar el elemento?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            onClick={evt => handleDelete(evt, row)}
            type="button"
            className="btn btn-danger"
            disabled={deleteRequest.isLoading}
          >
            <i className="fas fa-check"></i> Borrar
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={openModalCreate}
        toggle={() => {
          setOpenModalCreate(false)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Agregar CSD
          </h5>
          <button
            type="button"
            onClick={() => {
              setOpenModalCreate(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
            id="create-form"
          >
            {storeRequest.isError ? (
              <Alert className="alert alert-danger">
                {storeRequest.msgError}
              </Alert>
            ) : (
              ""
            )}
            {storeRequest.isSubmitted ? (
              <Alert className="alert alert-success">{"Registro creado"}</Alert>
            ) : (
              ""
            )}
            <Row>
              <NibbleBaseInput
                fieldType="text"
                fieldName="name"
                label="RFC"
                validation={validation}
                md={6}
              />
              <NibbleBaseInput
                fieldType="password"
                fieldName="pass"
                label="Contraseña"
                validation={validation}
                md={6}
              />
              <NibbleBaseInput
                fieldType="file"
                fieldName="cert"
                label="Archivo .cert"
                validation={validation}
                md={6}
              />
              <NibbleBaseInput
                fieldType="file"
                fieldName="key"
                label="Archivo .key"
                validation={validation}
                md={6}
              />
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setOpenModalCreate(false)
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            type="submit"
            className="btn btn-danger"
            disabled={storeRequest.isLoading}
            form="create-form"
          >
            <i className="fas fa-check"></i> Guardar
          </button>
        </div>
      </Modal>
    </>
  )
}

export default CsdCatalogsTab
