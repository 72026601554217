import React from 'react';
import PropTypes from 'prop-types';

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableTotal({ totals }) {

    const {
     pieces,
     kgs,
     total,
     discount,
     cobro,
     net,
     pagado,
     pagos,
     other_charges,
     decrease
 } = totals;

    return (
        
            <tr className='table-head'>
                <th colSpan={5} className="text-danger f-bold text-right">Totales</th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(pieces) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(kgs) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(total) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(decrease) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(discount) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(other_charges) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(net) }
                </th>
                <th className="text-danger f-bold text-right">
                </th>
                <th className="text-danger f-bold text-right">
                </th>
            </tr>
        
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object
};