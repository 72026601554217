import { GET_ORDER_PROVIDER_SUCCESS, ADD_COMMENT_ORDER_PROVIDER, GET_ORDER_PROVIDER, GET_ORDER_PROVIDER_FAILED } from "./actionTypes";

const INIT_STATE = {
    dailys: [],
    ui:{
        isError: null,
        isLoading: false
    }
};

const ordersProviderReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_ORDER_PROVIDER: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isLoading: true
                }
            }
        }
        case GET_ORDER_PROVIDER_SUCCESS:
            return {
                ...state,
                ui: {
                    isLoading: false,
                    isError: null
                },
                dailys: action.payload
            }

        case GET_ORDER_PROVIDER_FAILED:
            return {
                ...state,
                ui: {
                    isLoading: false,
                    isError: action.payload
                }
        }

        case ADD_COMMENT_ORDER_PROVIDER: {
            return {
                ...state,
                dailys: [
                    ...state.dailys,
                ]
            }
        }
    
        default:
            return state;
    }
    
}

export default ordersProviderReducer;