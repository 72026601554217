import { getTaxesCalculated } from "helpers/sat"
import { tax_configuration } from "common/sat/Taxes"
import { NumberFixed } from "helpers/numbers"

export const fillCfdiGeneralData = movClient => {
  const relatedUuid = movClient?.RelatedUuid ? movClient.RelatedUuid : ""

  const creditNoteFields = {
    NameId: "2",
    CfdiType: "E",
    Relations: { Type: "01", Cfdis: [{ Uuid: relatedUuid }] },
  }

  const debitNoteFields = {
    NameId: "",
    CfdiType: "I",
    Relations: { Type: "02", Cfdis: [{ Uuid: relatedUuid }] },
  }

  return {
    mov_client_id: movClient.id,
    Folio: "",
    IsCreditNote: movClient?.is_credit_note ? true : "",
    IsDebitNote: movClient?.is_debit_note ? true : "",
    ...(movClient?.is_credit_note ? creditNoteFields : {}),
    ...(movClient?.is_debit_note ? debitNoteFields : {}),
  }
}

export const fillCfdiIssuer = (enterprise, validation) => {
  if (!enterprise) return

  validation.setFieldValue("ExpeditionPlace", enterprise?.invoice_address?.cp)
  validation.setFieldValue("Issuer.FiscalRegime", enterprise?.tax_regime)
  validation.setFieldValue("Issuer.Rfc", enterprise?.rfc)
  validation.setFieldValue("Issuer.Name", enterprise?.company_name)
}

export const fillCfdiReceiver = (enterprise, validation) => {
  if (!enterprise) return

  validation.setFieldValue("Receiver.Name", enterprise?.company_name)
  validation.setFieldValue("Receiver.Rfc", enterprise?.rfc)
  validation.setFieldValue("Receiver.CfdiUse", enterprise?.use_cfdi)
  validation.setFieldValue("Receiver.FiscalRegime", enterprise?.tax_regime)
  validation.setFieldValue(
    "Receiver.TaxZipCode",
    enterprise?.invoice_address?.cp
  )
}

export const buildProductToCfdi = (product, movClient) => {
  const Discount =
    Number(movClient.decrease) +
    Number(movClient.mortalidad_descuento) +
    Number(movClient.nota_credito)

  const Item = {
    Id: new Date().getTime(),
    ProductCode: product?.codigo_sat || "",
    Description: product?.name || "",
    UnitCode: product?.unidad_sat || "",
    Quantity: product?.unit_id == 3 ? NumberFixed(movClient?.kgs, 4) : NumberFixed(movClient?.pieces, 4) || "",
    UnitPrice: NumberFixed(movClient?.pv) || "",
    Discount: NumberFixed(Discount),
    Subtotal: NumberFixed(movClient?.total_vta) || 0,
    TaxObject: product?.objeto_imp_sat || "",
    Taxes: getMovClientProductTaxes(movClient),
    Total: NumberFixed(movClient?.total) || 0,
    tax_configuration: product?.tax_configuration
      ? product.tax_configuration
      : tax_configuration,
    IsEditable: true,
  }

  return Item
}

export const buildMovClientToCfdi = movClient => {
  const Discount =
    Number(movClient.decrease) +
    Number(movClient.mortalidad_descuento) +
    Number(movClient.nota_credito)

  const Item = {
    Id: new Date().getTime(),
    ProductCode: "",
    Description: movClient?.product || "",
    UnitCode: "",
    Quantity: NumberFixed(movClient?.kgs) || "",
    UnitPrice: NumberFixed(movClient?.pv) || "",
    Discount: NumberFixed(Discount),
    Subtotal: NumberFixed(movClient?.total_vta) || 0,
    TaxObject: "",
    Taxes: [],
    Total: NumberFixed(movClient?.total) || 0,
    tax_configuration,
    IsEditable: true,
  }

  return Item
}

export const buildConfigProductToCfdi = (config, unitPrice) => {
  if (!config?.Id) return null

  const Item = {
    Id: new Date().getTime(),
    ProductCode: config.ProductCode,
    Description: config.Description,
    UnitCode: config.UnitCode,
    Quantity: 1,
    UnitPrice: Number(unitPrice),
    Discount: 0,
    Subtotal: Number(unitPrice),
    TaxObject: config.TaxObject,
    Taxes: [],
    Total: 0,
    tax_configuration: config.tax_configuration,
    IsEditable: true,
  }

  const { Total, Taxes } = getTaxesCalculated(
    Item.Subtotal,
    Item.Discount,
    Item.tax_configuration
  )

  Item.Total = Total
  Item.Taxes = Taxes

  return Item
}

const getMovClientProductTaxes = dataRow => {
  let Taxes = []

  if (dataRow.type_iva != "0") {
    const iva = {
      Total: Number(dataRow.iva),
      Name: "IVA",
      Base: NumberFixed(dataRow.venta_neta, 4),
      Rate: Number(dataRow.type_iva),
      IsRetention: false,
    }

    Taxes = [iva]
  }

  if (dataRow.type_retencion != "0") {
    const ivaRet = {
      Total: Number(dataRow.retencion),
      Name: "IVA RET",
      Base: NumberFixed(dataRow.venta_neta, 4),
      Rate: Number(dataRow.type_retencion),
      IsRetention: false,
    }

    Taxes = [...Taxes, ivaRet]
  }

  return Taxes
}
