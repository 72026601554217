import React from "react"
import PropTypes from "prop-types"
import CurrencyFormat from "common/nibble/CurrencyFormat"

const nf = new Intl.NumberFormat("es-MX")

export default function TableMovementItem({ item, index }) {
  const { bank, date, total, folio, account, provider, pendingToPayment } = item

  return (
    <>
      <tr>
        <td>{folio}</td>
        {/* <td>{date}</td> */}
        <td className="text-center">{date}</td>
        <td>{index}</td>
        <td>{provider?.name}</td>
        <td>{account?.name}</td>
        <td>{bank?.name}</td>
        <td className="text-right">
          <CurrencyFormat value={total} />
        </td>
        <td className="text-right">
          <CurrencyFormat value={total - pendingToPayment} />
        </td>
        <td className="text-right">
          <CurrencyFormat value={pendingToPayment} />
        </td>
      </tr>
    </>
  )
}

TableMovementItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number
}
