export const InvoiceStatus = {
  Active: "active",
  Canceled: "canceled",
}

export const InvoiceType = {
  Invoice: "Factura",
  DebitNote: "Nota de débito",
  CreditNote: "Nota de crédito",
  CancelacionCfdi: "Cancelación CFDI",
}
