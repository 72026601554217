import React, { useEffect } from "react"
import { useFormik } from "formik"
import { Form, Row } from "reactstrap"
import PropTypes from "prop-types"
import * as Yup from "yup"
import * as _ from "lodash"

import { getConfigFindMany, postConfigUpsertMany } from "helpers/backend_helper"
import useRequest from "hooks/useRequest"
import FullSpinner from "components/Common/FullSpinner"
import getStorageUser from "helpers/api_helper"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"

const config = {
  facturas_serie: "BILLING_CONFIGURATION_INVOICE_SERIE",
  facturas_folio: "BILLING_CONFIGURATION_INVOICE_FOLIO",
  notas_debito_serie: "BILLING_CONFIGURATION_DEBIT_NOTE_SERIE",
  notas_debito_folio: "BILLING_CONFIGURATION_DEBIT_NOTE_FOLIO",
  notas_credito_serie: "BILLING_CONFIGURATION_CREDIT_NOTE_SERIE",
  notas_credito_folio: "BILLING_CONFIGURATION_CREDIT_NOTE_FOLIO",
  comprobante_pago_serie: "BILLING_CONFIGURATION_PAYMENT_RECEIPTS_SERIE",
  comprobante_pago_folio: "BILLING_CONFIGURATION_PAYMENT_RECEIPTS_FOLIO",
}

const configNames = Object.values(config)

const ConfigFoliosTab = ({ tabActive, formId = "create-form-product" }) => {
  const user = getStorageUser()

  const getConfigsRequest = useRequest()
  const saveConfigsRequest = useRequest()

  const onSubmit = formValues => {
    const configurations = Object.entries(formValues).map(([name, value]) => ({
      name,
      value,
      enterprise_id: user?.enterprise_id,
    }))

    saveConfigsRequest.req(postConfigUpsertMany({ configurations }))
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      [config.facturas_serie]: "",
      [config.facturas_folio]: "",
      [config.notas_debito_serie]: "",
      [config.notas_debito_folio]: "",
      [config.notas_credito_serie]: "",
      [config.notas_credito_folio]: "",
      [config.comprobante_pago_serie]: "",
      [config.comprobante_pago_folio]: "",
    },
    validationSchema: Yup.object({
      [config.facturas_serie]: Yup.string().required().label("Serie"),
      [config.facturas_folio]: Yup.number().min(0).required().label("Folio"),
      [config.notas_debito_serie]: Yup.string().required().label("Serie"),
      [config.notas_debito_folio]: Yup.number()
        .min(0)
        .required()
        .label("Folio"),
      [config.notas_credito_serie]: Yup.string().required().label("Serie"),
      [config.notas_credito_folio]: Yup.number()
        .min(0)
        .required()
        .label("Folio"),
      [config.comprobante_pago_serie]: Yup.string().required().label("Serie"),
      [config.comprobante_pago_folio]: Yup.number()
        .min(0)
        .required()
        .label("Folio"),
    }),
    onSubmit,
  })

  const fetchConfigs = () => {
    const configFetch = {
      params: {
        names: configNames.join(","),
        enterprise_id: user?.enterprise_id,
      },
    }

    getConfigsRequest.req(getConfigFindMany(configFetch)).then(resp => {
      if (resp) {
        const facturasConfigSerie = _.find(resp, {
          name: config.facturas_serie,
        })
        const facturasConfigFolio = _.find(resp, {
          name: config.facturas_folio,
        })
        const notasCreditoConfigSerie = _.find(resp, {
          name: config.notas_credito_serie,
        })
        const notasCreditoConfigFolio = _.find(resp, {
          name: config.notas_credito_folio,
        })
        const notasDebitoConfigSerie = _.find(resp, {
          name: config.notas_debito_serie,
        })
        const notasDebitoConfigFolio = _.find(resp, {
          name: config.notas_debito_folio,
        })
        const comprobantePagoConfigSerie = _.find(resp, {
          name: config.comprobante_pago_serie,
        })
        const comprobantePagoConfigFolio = _.find(resp, {
          name: config.comprobante_pago_folio,
        })

        validation.setValues({
          [config.facturas_serie]: facturasConfigSerie?.value ?? "",
          [config.facturas_folio]: facturasConfigFolio?.value ?? "",
          [config.notas_debito_serie]: notasDebitoConfigSerie?.value ?? "",
          [config.notas_debito_folio]: notasDebitoConfigFolio?.value ?? "",
          [config.notas_credito_serie]: notasCreditoConfigSerie?.value ?? "",
          [config.notas_credito_folio]: notasCreditoConfigFolio?.value ?? "",
          [config.comprobante_pago_serie]:
            comprobantePagoConfigSerie?.value ?? "",
          [config.comprobante_pago_folio]:
            comprobantePagoConfigFolio?.value ?? "",
        })
      }
    })
  }

  useEffect(fetchConfigs, [tabActive])

  return (
    <div>
      {getConfigsRequest.isLoading ? <FullSpinner /> : ""}
      <p className="text-muted">
        Configurar las series para cada tipo de comprobante
      </p>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
        id={formId}
      >
        <h5>Facturas</h5>
        <Row>
          <NibbleBaseInput
            fieldType="text"
            fieldName={config.facturas_serie}
            label="Serie"
            validation={validation}
            md={4}
          />
          <NibbleBaseInput
            fieldType="text"
            fieldName={config.facturas_folio}
            label="Folio"
            validation={validation}
            md={4}
          />
        </Row>
        <h5>Notas de crédito</h5>
        <Row>
          <NibbleBaseInput
            fieldType="text"
            fieldName={config.notas_credito_serie}
            label="Serie"
            validation={validation}
            md={4}
          />
          <NibbleBaseInput
            fieldType="text"
            fieldName={config.notas_credito_folio}
            label="Folio"
            validation={validation}
            md={4}
          />
        </Row>
        <h5>Notas de débito</h5>
        <Row>
          <NibbleBaseInput
            fieldType="text"
            fieldName={config.notas_debito_serie}
            label="Serie"
            validation={validation}
            md={4}
          />
          <NibbleBaseInput
            fieldType="text"
            fieldName={config.notas_debito_folio}
            label="Folio"
            validation={validation}
            md={4}
          />
        </Row>
        <h5>Comprobantes de pago</h5>
        <Row>
          <NibbleBaseInput
            fieldType="text"
            fieldName={config.comprobante_pago_serie}
            label="Serie"
            validation={validation}
            md={4}
          />
          <NibbleBaseInput
            fieldType="text"
            fieldName={config.comprobante_pago_folio}
            label="Folio"
            validation={validation}
            md={4}
          />
        </Row>
        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-danger"
            form={formId}
            disabled={saveConfigsRequest.isLoading}
          >
            <i className="fas fa-check"></i> Guardar
          </button>
        </div>
      </Form>
    </div>
  )
}

ConfigFoliosTab.propTypes = {
  tabActive: PropTypes.string,
  formId: PropTypes.any,
  setTabActive: PropTypes.func,
}

export default ConfigFoliosTab
