import React from 'react';
import PropTypes from 'prop-types';

import {
    Table
} from 'reactstrap';

import TableMovementItem from './TableMovementItem';
import Header from './TableHeader';
import TableTotal from './TableTotal';

export default function TableReport({ data, totals, setColumnOrder, columnOrder }) {

    return (
        <div className="table-responsive" style={{position: 'relative', maxHeight: '500px'}}>
            <Table className="table mb-0 report-table table-bordered table-sm">
                <thead className='table-light'>
                    <TableTotal totals={totals} />
                    <Header setColumnOrder={ setColumnOrder } columnOrder={columnOrder}/>
                </thead>
                <tbody>
                    
                    { data.map(item => (
                        <TableMovementItem key={ item.client_id } item={ item }/>
                    )) }
                </tbody>
            </Table>
        </div>
    );
}

TableReport.propTypes = {
    data: PropTypes.any,
    totals: PropTypes.object,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};