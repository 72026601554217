import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Modal, Button, Alert, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { uploadExcel } from 'helpers/backend_helper';
import * as moment from 'moment';
import { yearData } from 'common/data';

const classBtnPeriods = "btn btn-outline-secondary btn-sm mb-1 me-1 mt-1"

function MonthButton({ month, monthString, handler, year }) {
    const curDay = moment()
    //const year = month > curDay.month() ? curDay.year() - 1 : null

    const handleSelect = () => {
        const curDay = moment()
        const start = moment().month(month).year(year).startOf('month')
        const end = moment().month(month).year(year).endOf('month')
        const parseDates = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
        handler(parseDates)
    }

    return (
        <button
            style={{padding:'0.25em 6px'}}
            onClick = { handleSelect }
            className={classBtnPeriods}
        >
            {monthString}
        </button>
    )
}

MonthButton.propTypes = {
    month: PropTypes.number,
    year: PropTypes.number,
    monthString: PropTypes.string,
    handler: PropTypes.func
};

export default MonthButton;