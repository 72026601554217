import React from "react"
import { Card, CardBody } from "reactstrap"

import PageBase from "components/Common/PageBase"
import Tabs, { tabName } from "./components/Tabs"
import useEnterpriseDetail from "./useEnterpriseDetail"
import GeneralDataTab from "./components/GeneralDataTab"
import TaxDataTab from "./components/TaxDataTab"
import CsdCatalogsTab from "./components/CsdCatalogsTab"
import FullSpinner from "components/Common/FullSpinner"
import { cardBodyStyles } from "./components/static"

const breadcrumbList = [
  { id: "1", title: "Usuario", active: false },
  { id: "2", title: "Perfil", active: true },
]

const EnterpriseDetailContainer = () => {
  const { state, events } = useEnterpriseDetail()

  const renderTabContent = () => {
    switch (state.tabActive) {
      case tabName.generalData:
        return <GeneralDataTab enterprise={state.enterprise} />
      case tabName.taxData:
        return <TaxDataTab enterprise={state.enterprise} />
      case tabName.csdsCatalogs:
        return <CsdCatalogsTab />
      default:
        break
    }
  }

  return (
    <>
      {state.isLoading ? <FullSpinner /> : ""}
      <PageBase
        titleMeta="Datos Fiscales"
        titleBread="Datos Fiscales"
        itemsBread={breadcrumbList}
      >
        <Tabs tabActive={state.tabActive} setTabActive={events.setTabActive} />
        <Card>
          <CardBody style={cardBodyStyles}>{renderTabContent()}</CardBody>
        </Card>
      </PageBase>
    </>
  )
}

export default EnterpriseDetailContainer
