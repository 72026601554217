import React from 'react';
import PropTypes from 'prop-types';

import '../common/arrows-order.scss';

export default function TableHeader({ setColumnOrder, columnOrder, configs,options }) {
    
    return (
        <>
            <tr className='table-head'>
                <th className="text-center" style={{minWidth: '60px'}}>Código<br /><br /></th>
                <th className="text-center" style={{minWidth: '100px'}}>
                    Nombre
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="client_name"
                    />
                </th>
                { configs.map(col => (
                    <th key={col.value}>
                        <>
                        {col.name=='PIL_MONTANO' ? 'PIL MONTAÑO':col.name}
                        <ArrowsOrder 
                            sUIetColumnOrder={ setColumnOrder }
                            columnOrder={ columnOrder }
                            column={col.value}
                        />
                        </>
                    </th>
                ))
                }
                
                <th className="text-center" style={{minWidth: '80px'}}>
                    OTROS
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="otros"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    TOTAL
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="total_row"
                    />
                </th>
            </tr>
        </>
    );
}

TableHeader.propTypes = {
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object,
    options: PropTypes.array,
    configs: PropTypes.array
};

const ArrowsOrder = ({ setColumnOrder, column, columnOrder }) => {
    
    const isArrowDownActive = (columnOrder.column === column && columnOrder.order === 'desc');
    const isArrowUpActive = (columnOrder.column === column && columnOrder.order === 'asc');

    return (
        <div className="order arrows-order text-center">
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'desc'
                })}
                className={`p-1 dropdown arrows-order__asc ${ isArrowDownActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'asc'
                })}
                className={`p-1 dropup arrows-order__desc ${isArrowUpActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
        </div>
    )
}

ArrowsOrder.propTypes = {
    column: PropTypes.string,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};