import { GET_ORDER_SUCCESS, ADD_COMMENT_ORDER, GET_ORDER, GET_ORDER_FAILED } from "./actionTypes";

const INIT_STATE = {
    orders: [],
    orderActive: {
        provider: {},
        client: {
            address: {}
        }, 
        order_date: '', 
        items: [],
        comments: [],
        status: '',
        rating: null
    },
    ui:{
        isError: null,
        isLoading: false
    }
};

const ordersReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_ORDER: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isLoading: true
                }
            }
        }
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                ui: {
                    isLoading: false,
                    isError: null
                },
                orderActive: action.payload
            }

        case GET_ORDER_FAILED:
            return {
                ...state,
                ui: {
                    isLoading: false,
                    isError: action.payload
                }
        }

        case ADD_COMMENT_ORDER: {
            return {
                ...state,
                orderActive: {
                    ...state.orderActive,
                    comments: [...state.orderActive.comments, action.payload]
                }
            }
        }
    
        default:
            return state;
    }
    
}

export default ordersReducer;