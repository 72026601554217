import React from 'react';
import PropTypes from 'prop-types';

const nf = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
});

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableMovementItem({ item }) {

    const {
        mov_date,
        folio,
        client_account,
        provider_account,
        product,
        prom,
        pieces,
        kgs,
        price_cost, 
        cost_mn,
        pv: precio_venta,
        total_vta: venta_mn,
        decrease,
        mortalidad_descuento,
        nota_credito,
        nota_cargo,
        venta_neta,
        utilidad,
        porcentaje,
        diferencia,
        iva,
        mortalidad,
        mortalidad_percentaje
    } = item;

    return (
        <>
            <tr>
                <td className="text-center">
                    { mov_date }
                </td>
                <td className="text-center">
                    { folio }
                </td>
                <td>
                    { client_account?.related?.skey }
                </td>
                <td>
                    { client_account ? client_account : '' }
                </td>
                <td>
                    { provider_account ? provider_account : '' }
                </td>
                <td className="text-center">
                    { product }
                </td>
                <td className="text-right">
                    { nf.format(prom) }
                </td>
                <td className="text-right">
                    { parseInt(pieces) }
                </td>
                <td className="text-right">
                    { nf2.format(kgs) }
                </td>
                <td className="text-right">
                    { nf2.format(price_cost) }
                </td>
                <td className="text-right">
                    { nf2.format(precio_venta) }
                </td>
                <td className="text-right">
                    { nf2.format(diferencia ? diferencia:'') }
                </td>
                <td className="text-right">
                    { nf2.format(cost_mn) }
                </td>
                <td className="text-right">
                    { nf2.format(venta_mn) }
                </td>
                <td className="text-right">
                    { nf2.format(decrease) }
                </td>
                <td className="text-right">
                    { nf2.format(mortalidad_descuento) }
                </td>
                <td className="text-right">
                    { nf2.format(nota_credito) }
                </td>
                <td className="text-right">
                    { nf2.format(nota_cargo) }
                </td>
                <td className="text-right">
                    { nf2.format(venta_neta) }
                </td>
                <td className="text-right">
                    { nf2.format(utilidad) }
                </td>
                <td className="text-right">
                    { nf2.format(porcentaje) }
                </td>
                <td className="text-right">
                    { nf2.format(mortalidad) }
                </td>
                <td className="text-right">
                    { nf2.format(mortalidad_percentaje) }%
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object
};