import { useState } from 'react';
import { useSelector } from 'react-redux';
import { postRating } from 'helpers/backend_helper';

const today = new Date();
const currentTime = today.getHours() + ":" + today.getMinutes();

const INIT_FORM = {
    atencion: '0',
    calidad: '0',
    tiempo: '0',
    arrival_time: currentTime,
    departure_time: currentTime,
    start_discharge: currentTime,
    end_discharge: currentTime,
    dead: 0,
    injured: 0, 
    beets: 0,
    observations: ''
}

const INIT_UI = {
    isLoading: false,
    isError: '',
    isSend: false
};

export const useOrderRating = (rating) => {

    const [form, setForm] = useState(rating || INIT_FORM);
    const [formUi, setFormUi] = useState(INIT_UI);

    const { type_string } = useSelector(state => state.Login.user);
    const { id: order_id } = useSelector(state => state.Orders.orderActive);

    const handleChange = ({ name, value }) => {
        setForm(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleInputChange = ({ target }) => {
        setForm(prev => ({
            ...prev,
            [target.name]: target.value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setFormUi({...INIT_UI, isLoading: true});
            await postRating({...form, order_id});

            setFormUi({...INIT_UI, isSend: true});
        } catch (error) {
            setFormUi({...INIT_UI, isError: error});
        }
    }

    return {
        handleInputChange,
        handleChange,
        handleSubmit,
        type_string,
        formUi,
        form  
    }
}
