import React from "react"
import PropTypes from "prop-types"
import CurrencyFormat from "common/nibble/CurrencyFormat"

export default function TableMovementItem({ item, noDoc }) {
  const {
    mov_date,
    client_account,
    bank_account,
    provider_account,
    cost_account,
    observation,
    description,
    deposit,
    payment,
    humanType,
  } = item

  return (
    <>
      <tr>
        <td className="text-center">{mov_date}</td>
        <td className="text-center">{noDoc}</td>
        <td>{humanType}</td>
        <td>{bank_account?.name}</td>
        <td>
          {client_account?.name || provider_account?.name || cost_account?.name}
        </td>
        <td>{observation}</td>
        <td>{description}</td>

        <td className="text-right">
          <CurrencyFormat value={deposit} />
        </td>
        <td className="text-right">
          <CurrencyFormat value={payment} />
        </td>
        <td className="text-right">
          <CurrencyFormat value={bank_account?.balance_initial} />
        </td>
      </tr>
    </>
  )
}

TableMovementItem.propTypes = {
  item: PropTypes.object,
  noDoc: PropTypes.number,
}
