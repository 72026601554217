import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_INIT,
} from "./actionTypes"

const initialState = {
  isLoading: false,
  forgetSuccessMsg: '',
  forgetError: '',
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_INIT: {
      state = {
        ...initialState
      }
      break;
    }
    case FORGET_PASSWORD:
      state = {
        ...state,
        ...initialState,
        isLoading: true
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        forgetSuccessMsg: action.payload,
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = { 
        ...state, 
        isLoading: false,
        forgetError: action.payload
      }
      break
    default:
      state = { 
        ...state 
      }
      break
  }
  return state
}

export default forgetPassword
