import { dataReports } from './data'
import { ADD_ADMINISTRATIVE_DATA, GET_ADMINISTRATIVE_DATA, GET_ADMINISTRATIVE_DATA_FAIL, GET_ADMINISTRATIVE_DATA_SUCESS, SET_ACTIVE_ADMINISTRATIVE_DATA, SET_ADMINISTRATIVE_FILTERS, SET_COLUMNS_VIEW, SET_TYPE_ADMINISTRATIVE_DATA, UPDATE_ADMINISTRATIVE_DATA } from './actionsTypes';
import * as moment from 'moment';

const INIT_STATE = {
    activeTab: 'clientes',
    typeReport: 'ClientesProveedores',
    dataReports,
    pagination: {
        data: [],
        current_page: 1,
        per_page: 0,
        total: 0            
    },
    ui: {
        isLoading: false,
        isError: false,
        msgError: ''
    },
    saldos: {
        ini: null,
        fin: null,
    }, 
    totales: null,
    filters: {
        client: { id: '', name: '' },
        provider: { id: '', name: 'Todos' },
        provider_client: { id: '', name: 'Todos' },
        cost_account: { id: '', name: '' },
        dates: '',
        search: '',
        search_qty: '',
        page: 1,
        bank: { id: '', name: 'Todos' },
        payment: { id: '', name: '' },
        mostrar: { id: 1, name: 'Todo' },
        product: { id: '', name: 'Todos' },
        sucursal: { id: '', name: 'Todas' },
        account_id: { id: '', name: 'Todas' },
        payment_type: { id: '', name: 'Todos' },
        column: '',
        order: '',
        banco:{ name: '' },
        year: { id: moment().year(), name: moment().year() }
    },
    selects: {
        products: [],
        sucursales: [],
        payment_type: [],
    },
    columnsView: [],
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ADMINISTRATIVE_DATA: return {
            ...state,
            ui: {
                ...state.ui,
                isLoading: true
            }
        }
        case GET_ADMINISTRATIVE_DATA_SUCESS: return {
            ...state,
            ui: {
                ...state.ui,
                isLoading: false
            },
            pagination: action.payload.data,
            totales: action.payload.totales,
            saldos: action.payload.saldos,
            selects: {
                ...state.selects,
                ...action.payload?.selects
            },
            columnsView: action.payload?.columnsView || [],
        }
        case GET_ADMINISTRATIVE_DATA_FAIL: return {
            ...state,
            pagination: INIT_STATE.pagination,
            ui: {                
                isLoading: false,
                isError: true,
                msgError: action.payload
            }
        }
        case SET_ACTIVE_ADMINISTRATIVE_DATA: return {
            ...state,
            activeTab: action.payload
        }
        case SET_TYPE_ADMINISTRATIVE_DATA: return {
            ...state,
            typeReport: action.payload
        }
        case ADD_ADMINISTRATIVE_DATA: return {
            ...state,
            pagination: {
                ...state.pagination,
                data: [ action.payload, ...state.pagination.data ]
            }
        }
        case UPDATE_ADMINISTRATIVE_DATA: return {
            ...state,
            pagination: {
                ...state.pagination,
                data: state.pagination.data.map(item => 
                    item.id === action.payload.id ? action.payload : item
                )
            }
        }
        /* case SET_FILTERS_ADMINISTRATIVE_DATA: return {
            ...state,
            filters: action.payload
        }
        case SET_FILTER_CLIENT : return {
            ...state,
            filters: {
                ...state.filters,
                ...action.payload
            }
        } */
        case SET_ADMINISTRATIVE_FILTERS: return {
            ...state,
            filters: {
                ...state.filters,
                ...action.payload
            }
        }

        case SET_COLUMNS_VIEW: return {
            ...state,
            columnsView: action.payload,
        }

        default: return state;
    }
}

export default reducer;