import React from "react"
import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../datatables.scss"
import { useState } from "react";
import { useEffect } from "react";
import { getProducts, getProviderOrders } from "helpers/backend_helper";
import { getDistribuitorOrders } from '../../../helpers/backend_helper';
import { getClassNameOrderStatus } from "helpers/order_helper";
import BreadcrumbNav from "components/Common/BreadcrumbNav";

const breadcrumbItems = [
  { id:'1', title: 'Distribuidor', link: '#', active: false },
  { id:'2', title: 'Pedidos Clientes', link: '#', active: true }
];

const DistributorOrders = () => {

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Link className="btn btn-sm btn-danger" to={{ pathname: "/order/" + row.id, state: row }} title="Ver detalle">
          <i className="fas fa-receipt"></i>
        </Link> <Link className="btn btn-sm btn-danger" to={{ pathname: "/order/reconcile/" + row.id, state: row }} title="Conciliar">
          <i className="fas fa-tasks"></i>
        </Link>
      </span>
    )
  }

  function statusColumnFormatter(cell, row, rIdx) {
    const clase = getClassNameOrderStatus(row.status);
    return (    
      <span className={"badge rounded-pill bg-"+clase}>
        {row.status_string}
      </span>
    )
  }

  const columns = [{
    dataField: 'order_date',
    text: 'Fecha',
    sort: true,
  }, {
    dataField: 'client.company_name',
    text: 'Cliente',
    sort: true
  }, {
    dataField: 'provider.company_name',
    text: 'Proveedor',
    sort: true
  }, {
    dataField: 'status',
    text: 'Status',
    formatter: statusColumnFormatter
  }, {
    dataField: 'id',
    text: 'Acciones',
    formatter: buttonActions
  }];
  
  // Table Data
  const [data, setData] = useState ([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getData()  {
      setLoading(true);
      const response = await getDistribuitorOrders();
      setLoading(false);
      console.log(response);
      setData(response);
    }

    getData();
  }, []);


  const defaultSorted = [{
    dataField: 'order_date',
    order: 'desc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: data.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (data).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pedidos</title>
        </MetaTags>
        <div className="container-fluid">
          <BreadcrumbNav title="Pedidos Clientes"  items={breadcrumbItems}/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={data}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder='Buscar'
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={loading ? <Spinner animation="border" variant="danger" style={{color:"#f46a6a"}} />:'Sin datos'}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DistributorOrders
