import { call, put, takeEvery } from "redux-saga/effects";

import { getClientOrder } from '../../helpers/backend_helper'
import { getOrderSuccess, getOrderFailed } from "./actions";
import { GET_ORDER } from './actionTypes';

function* getOrder({ payload: id }){
    try {
        const response = yield call(getClientOrder, id)
        yield put(getOrderSuccess(response));
    }catch (error) {
        yield put(
            getOrderFailed('Sorry! the page you are looking for could not be found')
        );
    }
}

function* ordersSaga(){
    yield takeEvery(GET_ORDER, getOrder);
}

export default ordersSaga;