import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { userForgetPassword } from '../../../store/actions';
import { useForm } from 'hooks/useForm';

import { 
    Row, 
    Col, 
    Alert,  
    Spinner 
} from 'reactstrap';

const INIT_FORM = {
    email: ''
};

const ForgetForm = ({ history }) => {

    const dispatch = useDispatch();

    const { form, handleInputChange } = useForm(INIT_FORM);
    const { forgetError, forgetSuccessMsg, isLoading } = useSelector(state => state.ForgetPassword);

    const handleValidSubmit = e => {
        e.preventDefault();
        
        dispatch(
            userForgetPassword(form, history)
        );
    }

    return (
        <div className="p-2">
            {
                forgetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                        { forgetError }
                    </Alert>
                ) : null
            }
            {
                forgetSuccessMsg ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                        { forgetSuccessMsg }
                    </Alert>
                ) : 
                    (<form
                        onSubmit={ handleValidSubmit }
                        className="form-horizontal"                
                    >
                        <div className="mb-3">
                            <label>Correo electrónico</label>
                            <input
                                onChange={ handleInputChange }
                                value={ form.email }
                                name="email"
                                className="form-control"
                                placeholder="Por favor ingresa tu correo electrónico"
                                type="email"
                                required
                            />
                        </div>
                        <Row className="mb-3">
                            <Col className="text-end">
                                {
                                    !isLoading ? (
                                    <button
                                        className="btn btn-warning w-md "
                                        type="submit"
                                    >
                                        Restaurar contraseña
                                    </button>
                                    ) : <Spinner />
                                }
                            </Col>
                        </Row>
                    </form>)
            }
        </div>
    )
}

ForgetForm.propTypes = {
    history: PropTypes.any
};

export default withRouter(ForgetForm);