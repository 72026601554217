import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"

import { Col, Row, Button, Form, Input, Alert, Table } from "reactstrap"

import useRequest from "hooks/useRequest"

import "flatpickr/dist/themes/material_blue.css"
import { putReportConfigOptions } from "helpers/backend_helper"
import { getAdministrativeData } from "store/catalogs/administrative-data/actions"
import { dataReports } from "store/catalogs/administrative-data/data"

const ConfigurationsForm = ({ isNew, toggleRightCanvas }) => {
  const dispatch = useDispatch()

  const { isError, isLoading, isSubmitted, msgError, req } = useRequest()
  const [columnsViewCopy, setColumnsViewCopy] = useState([])

  const activeTab = useSelector(state => state.AdministrativeData.activeTab)
  const columnsView = useSelector(state => state.AdministrativeData.columnsView)

  const { report_config_name } = dataReports[activeTab]

  const save = async () => {
    const data = {
      options: columnsViewCopy,
    }

    req(putReportConfigOptions(data, report_config_name)).then(() => {
      dispatch(getAdministrativeData())
      toggleRightCanvas()
    })
  }

  const handleOnChange = (columnName, show) => {
    const newColumnsView = columnsViewCopy.map(col =>
      col.label === columnName ? { ...col, show } : col
    )

    setColumnsViewCopy(newColumnsView)
  }

  useEffect(() => {
    setColumnsViewCopy(columnsView)
  }, [columnsView])

  return (
    <>
      <Form onSubmit={save}>
        {isError ? (
          <Alert className="alert alert-danger">{msgError}</Alert>
        ) : (
          ""
        )}
        {isSubmitted ? (
          <Alert className="alert alert-success">
            {isNew ? "Registros guardados" : "Registros actualizados"}
          </Alert>
        ) : (
          ""
        )}
        {columnsView?.length ? (
          <div>
            <Row>
              <Col>
              <Table className="table table-sm">
                <thead style={{ top: -16 }}>
                  <tr className="bg-light">
                    <th style={{width:'70px'}}>Mostrar</th>
                    <th>Columna</th>
                  </tr>
                </thead>
                <tbody>
                  {columnsViewCopy?.map(option => (
                    <tr key={option.label}>
                      <td scope="row">
                        <Input
                          checked={option.show}
                          onChange={e => {
                            handleOnChange(option.label, e.target.checked)
                          }}
                          name="pieces_provider"
                          type="checkbox"
                          className="form-control"
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td scope="row">{option.label}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  onClick={toggleRightCanvas}
                  color="secondary"
                  className="btn btn-secondary btn"
                  style={{ marginRight: "10px" }}
                  disabled={isLoading}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  className="btn btn-danger btn"
                  disabled={isLoading}
                  onClick={e => {
                    save()
                  }}
                >
                  <i
                    className={`${
                      isLoading ? "" : "d-none"
                    } bx bx-loader bx-spin font-size-16 align-middle me-2`}
                  ></i>
                  {isLoading ? "Guardando" : "Guardar"}
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <div>No se encontro la configuración de las columnas</div>
        )}
      </Form>
    </>
  )
}

ConfigurationsForm.propTypes = {
  toggleRightCanvas: PropTypes.func,
  isNew: PropTypes.bool,
}

export default ConfigurationsForm
