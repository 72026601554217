import React from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { useSelector } from "react-redux"

import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import EnterpriseModal from "./EnterpriseModal"

const Client = ({
  validation,
  disableName = true,
  disableRfc = true,
  disableTaxZipCode = true,
  disableFiscalRegime = true,
  disableUseCfdi = false,
  title = null,
}) => {
  const { tax_regime, use_cfdi } = useSelector(state => state.Sat)

  const showRelatedUuidField =
    validation.values.IsDebitNote || validation.values.IsCreditNote
  const isComplementInvoice = validation.values.CfdiType === "P"

  return (
    <>
      <div className="pb-4">
        {title ? title : <h5>Cliente</h5>}
        <Row>
          <NibbleBaseInput
            fieldType="text"
            fieldName="Receiver.Name"
            label="Razón social"
            validation={validation}
            md={4}
            disabled={disableName}
          />
          <NibbleBaseInput
            fieldType="text"
            fieldName="Receiver.Rfc"
            label="RFC"
            validation={validation}
            md={4}
            disabled={disableRfc}
          />
          <NibbleBaseInput
            fieldType="text"
            fieldName="Receiver.TaxZipCode"
            label="C.P."
            validation={validation}
            md={4}
            disabled={disableTaxZipCode}
          />
          <NibbleBaseInput
            fieldType="select"
            fieldName="Receiver.FiscalRegime"
            label="Régimen fiscal"
            selectItems={tax_regime?.data?.map(item => ({
              ...item,
              id: item.code,
            }))}
            validation={validation}
            selectOptionValue={option => option.id}
            selectOptionLabel={option =>
              `${option.code} - ${option.description}`
            }
            md={6}
            disabled={disableFiscalRegime}
            placeHolder="Seleccionar"
            isLoading={tax_regime.isLoading}
          />
          {isComplementInvoice ? null : (
            <NibbleBaseInput
              fieldType="select"
              fieldName="Receiver.CfdiUse"
              label="Uso de CFDI"
              selectItems={use_cfdi?.data?.map(item => ({
                ...item,
                id: item.code,
              }))}
              validation={validation}
              selectOptionValue={option => option.code}
              selectOptionLabel={option =>
                `${option.code} - ${option.description}`
              }
              md={6}
              placeHolder="Seleccionar"
              isLoading={use_cfdi.isLoading}
              disabled={disableUseCfdi}
            />
          )}

          {showRelatedUuidField ? (
            <NibbleBaseInput
              fieldType="text"
              fieldName="Relations.Cfdis[0].Uuid"
              label="UUID Cfdi relacionado"
              validation={validation}
              md={12}
            />
          ) : null}
        </Row>
      </div>
    </>
  )
}

Client.propTypes = {
  validation: PropTypes.object,
  toggleReceiverModal: PropTypes.func,
  disableName: PropTypes.bool,
  disableRfc: PropTypes.bool,
  disableTaxZipCode: PropTypes.bool,
  disableFiscalRegime: PropTypes.bool,
  disableUseCfdi: PropTypes.bool,
  showEditClient: PropTypes.bool,
  showSearchClient: PropTypes.bool,
  enterpriseId: PropTypes.any,
  title: PropTypes.any,
}

export default Client
