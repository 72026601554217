import React, { useState } from "react"
import classNames from "classnames"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/light.css"
import PropTypes from "prop-types"
import Select from "react-select"
import { Col, FormFeedback, Input, Label } from "reactstrap"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import moment from "moment"
import { get } from "lodash"

const NibbleBaseInput = ({
  md = 6,
  label = "",
  fieldName = "field_name",
  validation,
  fieldType = "text",
  placeHolder = "",
  selectItems = [],
  selectIsClearable = true,
  selectOptionValue = option => option.id,
  selectOptionLabel = option => option.name,
  selectIsSearchable = true,
  maxLength = null,
  readOnly = false,
  getOptionValue = option => option.id,
  disabled = false,
  onChange = null,
  onInputChange = null,
  isLoading = false,
  onKeyDown = null,
}) => {
  const fieldValue = get(validation.values, fieldName)
  const objFieldError = get(validation.errors, fieldName)
  const fieldError = Array.isArray(objFieldError) ? "" : objFieldError
  const isInvalidField =
    get(validation.touched, fieldName) && get(validation.errors, fieldName)

  if (fieldType === "date") {
    return (
      <Col md={md} className="mb-3">
        {label && <Label>{label}</Label>}
        <Flatpickr
          value={validation.values[fieldName]}
          className={classNames({
            "form-control": true,
            "is-invalid": isInvalidField ? true : false,
          })}
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            locale: Spanish,
          }}
          onChange={dates => {
            const parseDates = dates
              .map(date => moment(date).format("YYYY-MM-DD"))
              .join("/")

            validation.setFieldValue(fieldName, parseDates, true)
          }}
        />
        <FormFeedback type="invalid">{fieldError}</FormFeedback>
      </Col>
    )
  }

  if (fieldType === "datetime") {
    return (
      <Col md={md} className="mb-3">
        {label && <Label>{label}</Label>}
        <Flatpickr
          value={validation.values[fieldName]}
          className={classNames({
            "form-control": true,
            "is-invalid": isInvalidField ? true : false,
          })}
          placeholder="dd M,yyyy"
          options={{
            altInput: true,
            altFormat: "Y-m-d H:i",
            dateFormat: "Y-m-d H:i",
            locale: Spanish,
            enableTime: true,
          }}
          onChange={dates => {
            if (!dates?.length) return

            const [date] = dates
            const formattedDate = moment(date).format("YYYY-MM-DD HH:mm")

            validation.setFieldValue(fieldName, formattedDate, true)
          }}
        />
        <FormFeedback type="invalid">{fieldError}</FormFeedback>
      </Col>
    )
  }

  if (fieldType === "date-range") {
    return (
      <Col md={md} className="mb-3">
        {label && <Label>{label}</Label>}
        <Flatpickr
          value={validation.values[fieldName]?.split("/")}
          className={classNames({
            "form-control": true,
            "is-invalid": isInvalidField ? true : false,
          })}
          placeholder="Y-m-d"
          options={{
            /* altInput: true,
            altFormat: "Y-m-d",
            locale: Spanish, */
            //dateFormat: "Y-m-d a Y-m-d",
            mode: "range",
          }}
          onChange={dates => {
            if (dates.length != 2) return
            const parseDates = dates
              .map(date => moment(date).format("YYYY-MM-DD"))
              .join("/")

            validation.setFieldValue(fieldName, parseDates, true)
          }}
        />
        <FormFeedback type="invalid">{fieldError}</FormFeedback>
      </Col>
    )
  }

  if (fieldType === "select") {
    return (
      <Col className="mb-3" md={md}>
        {label && typeof label === "string" && (
          <Label className="form-label">{label}</Label>
        )}
        {label && typeof label !== "string" && label}
        <Select
          placeholder={placeHolder}
          className={classNames({
            "select-control": true,
            "is-invalid": isInvalidField ? true : false,
          })}
          value={
            selectItems.filter(e => {
              return selectOptionValue(e) === fieldValue
            })[0] ?? null
          }
          isClearable={selectIsClearable}
          isSearchable={selectIsSearchable}
          getOptionLabel={selectOptionLabel}
          getOptionValue={selectOptionValue}
          name={fieldName}
          options={selectItems}
          onChange={
            onChange
              ? onChange
              : (option, meta) => {
                  validation.setFieldValue(fieldName, option?.id ?? null, true)
                }
          }
          onInputChange={onInputChange}
          isDisabled={disabled}
          isLoading={isLoading}
        />
        <FormFeedback type="invalid">{fieldError}</FormFeedback>
      </Col>
    )
  }

  if (fieldType === "multi-select") {
    return (
      <Col className="mb-3" md={md}>
        {label && (
          <>
            <Label className="form-label">{label}</Label>
          </>
        )}
        <Select
          placeholder={placeHolder}
          className={classNames({
            "select-control": true,
            "is-invalid": isInvalidField ? true : false,
          })}
          value={validation.values[fieldName] ?? []}
          isClearable={selectIsClearable}
          isSearchable={selectIsSearchable}
          getOptionLabel={selectOptionLabel}
          getOptionValue={selectOptionValue}
          name={fieldName}
          options={selectItems}
          onChange={(option, meta) => {
            validation.setFieldValue(fieldName, option, true)
          }}
          isMulti
          isDisabled={disabled}
        />
        <FormFeedback type="invalid">{fieldError}</FormFeedback>
      </Col>
    )
  }

  if (fieldType === "file") {
    return (
      <Col className="mb-3" md={md}>
        {label && (
          <>
            <Label className="form-label">{label}</Label>
          </>
        )}
        <Input
          className="form-control"
          placeholder={placeHolder}
          name={fieldName}
          type="file"
          onChange={e => {
            const files = e.target.files
            if (!files.length) return

            const file = files[0]

            validation.setFieldValue(fieldName, file, true)
          }}
          onBlur={validation.handleBlur}
          /* value={fieldValue ?? ""} */
          key={fieldValue ?? ""}
          invalid={isInvalidField ? true : false}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled}
        />
        <FormFeedback type="invalid">{fieldError}</FormFeedback>
      </Col>
    )
  }

  return (
    <Col className="mb-3" md={md}>
      {label && typeof label === "string" && (
        <Label className="form-label">{label}</Label>
      )}
      {label && typeof label !== "string" && label}
      <Input
        className="form-control"
        placeholder={placeHolder}
        name={fieldName}
        type={fieldType}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={fieldValue ?? ""}
        invalid={isInvalidField ? true : false}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
      <FormFeedback type="invalid">{fieldError}</FormFeedback>
    </Col>
  )
}

NibbleBaseInput.propTypes = {
  md: PropTypes.number,
  label: PropTypes.any,
  fieldName: PropTypes.string,
  validation: PropTypes.object,
  fieldType: PropTypes.string,
  selectItems: PropTypes.array,
  selectIsClearable: PropTypes.bool,
  selectOptionValue: PropTypes.func,
  selectOptionLabel: PropTypes.func,
  selectIsSearchable: PropTypes.bool,
  selectStringify: PropTypes.func,
  postFileFunction: PropTypes.func,
  itemFile: PropTypes.any,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  getOptionValue: PropTypes.func,
  placeHolder: PropTypes.string,
  onChange: PropTypes.any,
  onInputChange: PropTypes.any,
  onKeyDown: PropTypes.any,
}

export default NibbleBaseInput
