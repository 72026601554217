import React, { Fragment, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Label } from "reactstrap"
import { AvField } from "availity-reactstrap-validation"

import AdrressForm from './AddressForm';

const ProviderForm = props => {
  const initState = {
    name: null,
    local_delivery: false,
    region_id: null,
    regions: null,
    selectedRegions: {}
  }
  
  const [provider, setProvider] = useState({...initState, ...props.provider})

  console.log(props.provider)
  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <div className="mb-3">
            <AvField
                name="company_name"
                label="Nombre Comercial o Razón Social"
                value={provider.company_name}
                type="text"
                required
                errorMessage="Campo requerido"
                placeholder=""
              />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <AvField
                name="skey"
                label="Clave"
                value={provider.skey}
                type="text"
                errorMessage="Campo requerido"
                placeholder=""
              />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="mb-3">
            <AvField
                name="contact_name"
                label="Nombre del Contacto"
                value={provider.contact_name}
                type="text"
                required
                errorMessage="Campo requerido"
                placeholder=""
              />
          </div>
        </Col>
      </Row>
    
    <Row>
      <Col md={6}>
        <div className="mb-3">
          <AvField
              name="phone"
              label="Telefono"
              value={provider.phone}
              type="text"
              required
              errorMessage="Campo requerido"
              placeholder=""
            />
        </div>
      </Col>
      <Col md={6}>
        <div className="mb-3">
          <AvField
              name="email"
              label="Correo"
              value={provider.email}
              type="email"
              required
              errorMessage="Campo requerido"
              placeholder=""
            />
        </div>
      </Col>
    </Row>
    <label className="py-2">Dirección Fiscal</label>
    <Row>
      <Col md={12}>
        <Label htmlFor="formrow-firstname-Input">RFC</Label>
        <div className="mb-3">
          <input
              className="form-control"
              name="rfc"
              type="text"
              placeholder=""
              onChange={ (e) => { console.log(e.target.value); props.setRfc(e.target.value.toUpperCase()) } }
              value={ props.rfc }
              maxLength={13}
            />
        </div>
      </Col>
    </Row>
    <AdrressForm entityAddress={provider?.invoice_address ? provider.invoice_address : {}} />
    </Fragment>
  )
}

ProviderForm.propTypes = {
  provider: PropTypes.object.isRequired,
  rfc: PropTypes.any,
  setRfc: PropTypes.func.isRequired,
}

export default ProviderForm
