import React, { useCallback, useEffect, useRef, useState } from "react"
import axios from "axios"

import useRequest from "./useRequest"
import { getSatUnits } from "helpers/backend_helper"

const useSatUnits = (search = "") => {
  const cancelToken = useRef(null)
  const satUnitsRequest = useRequest()

  const [satUnits, setSatUnits] = useState([])

  const fetchSatUnits = useCallback(async search => {
    if (cancelToken.current) {
      cancelToken.current.cancel()
    }

    cancelToken.current = axios.CancelToken.source()

    const config = {
      params: {
        search,
      },
      cancelToken: cancelToken.current.token,
    }

    const response = await satUnitsRequest.req(getSatUnits(config))
    if (!response) return

    setSatUnits(response)
  }, [])

  useEffect(() => {
    fetchSatUnits(search)
  }, [fetchSatUnits, search])

  return {
    isLoadingSatUnits: satUnitsRequest.isLoading,
    satUnits,
    fetchSatUnits,
  }
}

export default useSatUnits
