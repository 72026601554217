import React from 'react';
import PropTypes from 'prop-types';

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 


export default function TableMovementItem({ item }) {

    const {
        name,
        balance_initial,
        venta_neta,
        cobro,
        balance_finish,
        diff
    } = item;

    return (
        <>
            <tr>
                <td className="text-start">
                    { name }
                </td>
                <td className="text-right">
                    { nf2.format(balance_initial) }
                </td>
                <td className="text-right">
                    { nf2.format(venta_neta) }
                </td>
                <td className="text-right">
                    { nf2.format(cobro) }
                </td>
                <td className="text-right">
                    { nf2.format(balance_finish) }
                </td>
                <td className={"text-right " + (diff > 0 ? 'text-danger':'text-success')}>
                    { nf2.format(diff) }
                </td>
                <td className={"text-center " + (diff > 0 ? 'text-danger':'text-success')}>
                    { diff > 0 ? 'Incremento': (diff < 0 ? 'Disminución':'') }
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object
};