import React from 'react';
import PropTypes from 'prop-types';

export default function TableHeader({ setColumnOrder, columnOrder, multiple }) {
    return (
        <>
            <tr className='table-head'>
                <th className="text-center" style={{minWidth: '120px'}}>
                    Fecha
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="date"
                    />
                </th>
                {multiple &&
                    <th className="text-center" style={{minWidth: '120px'}}>
                        Proveedor
                        <ArrowsOrder 
                            setColumnOrder={ setColumnOrder }
                            columnOrder={ columnOrder }
                            column="account_name"
                        />
                    </th>
                }
                <th className="text-center" style={{minWidth: '80px'}}>
                    Total compra
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="total"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Merma
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="decrease"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Otros Descuentos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="discount"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Otros Cargos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="discount"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Pagos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="payment"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Efecto Deuda
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="net"
                    />
                </th>

                <th className="text-center" style={{minWidth: '80px'}}>
                    Saldo
                </th>
            </tr>
        </>
    );
}

TableHeader.propTypes = {
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object,
    multiple: PropTypes.bool
};

const ArrowsOrder = ({ setColumnOrder, column, columnOrder }) => {
    
    const isArrowDownActive = (columnOrder.column === column && columnOrder.order === 'desc');
    const isArrowUpActive = (columnOrder.column === column && columnOrder.order === 'asc');

    return (
        <span className="order arrows-order">
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'desc'
                })}
                className={`dropdown arrows-order__asc ${ isArrowDownActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'asc'
                })}
                className={`dropup arrows-order__desc ${isArrowUpActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
        </span>
    )
}

ArrowsOrder.propTypes = {
    column: PropTypes.string,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};