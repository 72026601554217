import moment from "moment"
import * as Yup from "yup"
import { es } from "yup-locales"

Yup.setLocale(es)

export const initialValues = {
  accounting_account_id: null,
  accounting_account_type: null,
  c_cost_id: null,
  c_bank_id: null,
  c_client_id: null,
  c_provider_id: null,
  cta: "",
  deposit: 0,
  payment: 0,
  description: "",
  folio: "",
  mov_date: moment().format("YYYY-MM-DD"),
  mov_date2: "",
  observation: "",
  mov_costs: [],
  mov_clients: [],
  mov_providers: [],
}

export const bankValidation = Yup.object({
  mov_date: Yup.date().required(),
  folio: Yup.string().required(),
  c_bank_id: Yup.string().required().label("Banco"),
  accounting_account_id: Yup.string().nullable(),
  deposit: Yup.number()
    .required()
    .moreThan(0)
    .typeError("el valor debe ser numérico")
    .label("Depósito"),
  payment: Yup.number()
    .required()
    .min(0)
    .typeError("el valor debe ser numérico")
    .label("Pago"),
  /* deposit: Yup.number()
    .label("Depósito")
    .when("accounting_account_type", {
      is: val => val === "client" || !val,
      then: schema =>
        schema.required().moreThan(0).typeError("el valor debe ser numérico"),
      otherwise: schema => schema.min(0),
    }),
  payment: Yup.number()
    .label("Pago")
    .when("accounting_account_type", {
      is: val => val === "provider" || val === "cost",
      then: schema =>
        schema.required().moreThan(0).typeError("el valor debe ser numérico"),
      otherwise: schema => schema.min(0),
    }), */
  observation: Yup.string().nullable().required().label("Observaciones"),
})
