import moment from "moment"
import * as Yup from "yup"
import { es } from "yup-locales"

Yup.setLocale(es)

export const validationSchema = Yup.object({
  company_name: Yup.string().required("Razón social"),
  rfc: Yup.string()
    .required()
    .matches(
      /^[A-Za-z0-9]{12,13}$/,
      "El RFC debe tener 12 o 13 caracteres y solo puede contener letras o números"
    )
    .label("RFC"),
  tax_regime: Yup.string().required().label("Régimen Fiscal"),
  invoice_address: Yup.object({
    cp: Yup.string()
      .required()
      .matches(
        /^[0-9]{5}$/,
        "El Código Postal debe tener 5 caracteres numéricos"
      )
      .label("Código Postal"),
  }),
})

export const initCsdValues = {
  name: "",
  pass: "",
  cert: "",
  key: "",
}

export const csdValidationSchema = Yup.object({
  //name: Yup.string().required().label("Nombre"),
  name: Yup.string()
    .required()
    .matches(
      /^[A-Za-z0-9]{12,13}$/,
      "El RFC debe tener 12 o 13 caracteres y solo puede contener letras o números"
    )
    .label("RFC"),
  pass: Yup.string().required().label("Contraseña"),
  key: Yup.mixed().required().label("Archivo .key"),
  cert: Yup.mixed().required().label("Archivo .cert"),
})

export const cardBodyStyles = { minHeight: "300px" }
