import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"

import { Modal, Alert, Spinner } from "reactstrap"

import "./custom.scss"
import {
  getClientInvoice,
  getMovHistory,
  getSaleTicket,
} from "helpers/backend_helper"
import useRequest from "hooks/useRequest"
import { useState } from "react"
import { downloadFile } from "helpers/files"
import ModalEmail from "./ModalEmail"

const nf = new Intl.NumberFormat("es-MX", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

function ModalPayment({ isOpen, setOpenModal, row }) {
  const [isSending, setIsSending] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [openEmailModal, setOpenEmailModal] = useState(false)

  const { id } = row

  const { msgError, isError, isLoading, req } = useRequest()

  const { activeTab, dataReports } = useSelector(
    state => state.AdministrativeData
  )

  const { url_history, columns_history } = dataReports[activeTab]

  const onClickOpenEmailModal = () => {
    setOpenEmailModal(true)
  }

  const downloadInvoice = async () => {
    setIsDownloading(true)
    try {
      const config = {
        responseType: "blob",
      }

      const data = {}

      const file = await getClientInvoice(row.id, data, config)
      console.log({ file })
      downloadFile(file, "pagos.pdf")
    } catch (error) {
      console.log(error)
    } finally {
      setIsDownloading(false)
    }
  }

  const onSendEmail = async email => {
    setIsSending(true)
    try {
      const data = { email, sendEmail: true }

      await getClientInvoice(row.id, data)

      setOpenEmailModal(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsSending(false)
    }
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setOpenModal(false)
        }}
        size="lg"
      >
        <form>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Relación de Pagos
            </h5>
            <button
              type="button"
              onClick={() => {
                setOpenModal(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            {isError && (
              <Alert className="alert alert-danger">{msgError}</Alert>
            )}
            {isLoading && <Spinner />}

            {row.movs_clients_payments?.length > 0 && (
              <div className="col-lg-12">
                <p>
                  <b>Folio Venta: </b>
                  {row.folio} <br />
                  <b>Fecha de la venta: </b>
                  {row.mov_date} <br />
                </p>
                <h5>Pagos Realizados</h5>
                <table className="table table-condensed table-bordered">
                  <thead>
                    <tr>
                      <th>Folio Pago</th>
                      <th>Fecha Pago</th>
                      <th>Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th></th>
                      <th className="text-end">Venta Neta</th>
                      <th>${nf.format(row.venta_neta)}</th>
                    </tr>
                    {row.movs_clients_payments.map(e => (
                      <tr key={e.id}>
                        <td>{e.folio}</td>
                        <td>{e.mov_date}</td>
                        <td>${nf.format(e.pivot.import)}</td>
                      </tr>
                    ))}
                    <tr>
                      <th></th>
                      <th className="text-end">Total Abonado</th>
                      <th>
                        ${nf.format(row.venta_neta - row.pendingToPayment)}
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th className="text-end">Por Pagar</th>
                      <th>${nf.format(row.pendingToPayment)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {row.movs_clients_sales?.length > 0 && (
              <div className="col-lg-12">
                <p>
                  <b>Folio Pago: </b>
                  {row.folio} <br />
                  <b>Fecha de la venta: </b>
                  {row.mov_date} <br />
                  <b>Cobro: </b> ${nf.format(row.cobro)}
                </p>
                <h5>Abonos aplicados</h5>
                <table className="table table-condensed table-bordered">
                  <thead>
                    <tr>
                      <th>Folio Venta</th>
                      <th>Fecha</th>
                      <th>Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.movs_clients_sales.map(e => (
                      <tr key={e.id}>
                        <td>{e.folio}</td>
                        <td>{e.mov_date}</td>
                        <td>${nf.format(e.pivot.import)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-dismiss="modal"
              onClick={onClickOpenEmailModal}
            >
              <i className="mdi mdi-email" /> Enviar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              disabled={isDownloading}
              onClick={downloadInvoice}
            >
              <i
                className={`${
                  !isDownloading && "d-none"
                } bx bx-loader bx-spin font-size-16 align-middle me-2`}
              ></i>
              {isDownloading ? "Descargando..." : "Descargar"}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenModal(false)
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </form>
      </Modal>
      <ModalEmail
        isOpen={openEmailModal}
        setOpenModal={setOpenEmailModal}
        onSendEmail={onSendEmail}
        isSending={isSending}
      />
    </div>
  )
}

ModalPayment.propTypes = {
  isOpen: PropTypes.bool,
  setOpenModal: PropTypes.func,
  row: PropTypes.object,
}

export default ModalPayment
