import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { sumBy } from "lodash"

import useRequest from "hooks/useRequest"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import { getMovsCostToPay } from "helpers/backend_helper"

import CurrencyFormat from "common/nibble/CurrencyFormat"

import "flatpickr/dist/themes/material_red.css"

const ChargeForm = ({ validation }) => {
  const { bankAccounts, costAccounts } = useSelector(
    state => state.AccountingAccounts
  )

  const movsCostToPayReq = useRequest()

  const {
    values: { account_id, payment, movs_costs_sales },
    errors: formErrors,
  } = validation

  const getMovsCosts = () => {
    if (!account_id) return

    movsCostToPayReq.req(getMovsCostToPay(account_id))
  }

  // calculate sum import when fields are valid
  const calculateTotalImports = () => {
    if (formErrors.payment) return
    if (formErrors.movs_costs_sales) return

    const total = sumBy(movs_costs_sales, "import")

    validation.setFieldValue("total_imports", total, true)
  }

  useEffect(getMovsCosts, [account_id])
  useEffect(calculateTotalImports, [payment, movs_costs_sales, formErrors])

  return (
    <>
      <Row>
        <NibbleBaseInput
          fieldType="date"
          fieldName="date"
          label="Fecha *"
          validation={validation}
          md={3}
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName="folio"
          label="Consecutivo"
          validation={validation}
          md={3}
          disabled
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName="account_id"
          label="Cuenta contable"
          selectItems={costAccounts}
          validation={validation}
          md={6}
        />
        <NibbleBaseInput
          fieldType="number"
          fieldName="payment"
          label="Importe de pago *"
          validation={validation}
          md={4}
        />
        <NibbleBaseInput
          fieldType="multi-select"
          fieldName="movs_costs_sales"
          label="Gastos relacionados"
          selectItems={movsCostToPayReq.data?.map(mov => ({
            id: mov.id,
            name: mov.date + " - f-" + (mov.folio ?? "") + " - $" + mov.total,
            import: Number(mov.total),
            folio: mov.folio,
            pendingToPayment: mov.pendingToPayment,
          }))}
          validation={validation}
          md={8}
        />
      </Row>
      {validation.values?.movs_costs_sales?.length ? (
        <Row>
          <Col md={12}>
            <div className="table-responsive">
              <table className="table table-bordered table-sm">
                <thead className="table-light">
                  <tr>
                    <th>Folio Venta</th>
                    <th>Pendiente de pago</th>
                    <th>Importe</th>
                  </tr>
                </thead>
                <tbody>
                  {validation.values.movs_costs_sales.map((mov, index) => {
                    return (
                      <tr key={mov.id}>
                        <td>{mov.folio}</td>
                        <td className="text-right">
                          <CurrencyFormat
                            value={mov.pendingToPayment}
                            prefix="$"
                          />
                        </td>
                        <td>
                          <NibbleBaseInput
                            fieldType="number"
                            fieldName={`movs_costs_sales[${index}].import`}
                            validation={validation}
                            md={12}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {validation.errors?.total_imports ? (
                <div className="text-danger pb-3">
                  {validation.errors.total_imports}
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      ) : null}
      <Row>
        <NibbleBaseInput
          fieldType="text"
          fieldName="payment_type"
          label="Forma de pago"
          validation={validation}
          md={4}
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName="c_bank_id"
          label="Banco"
          selectItems={bankAccounts}
          validation={validation}
          md={4}
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName="bank_folio"
          label="Banco folio"
          validation={validation}
          md={4}
        />
      </Row>
      <Row>
        <Col md={12}>
          <NibbleBaseInput
            fieldType="text"
            fieldName="observations"
            label="Observaciones"
            validation={validation}
            md={12}
          />
        </Col>
      </Row>
    </>
  )
}

ChargeForm.propTypes = {
  validation: PropTypes.any,
}

export default ChargeForm
