import React from "react"
import PropTypes from "prop-types"
import { Nav, NavItem, NavLink } from "reactstrap"

export const tabName = {
  taxData: "datos-fiscales",
  csdsCatalogs: "catalogo-csds",
  otherCharges: "BILLING_CONFIGURATION_OTHER_CHARGES",
  otherDiscounts: "BILLING_CONFIGURATION_OTHER_DISCOUNTS",
  globalInvoice: "BILLING_CONFIGURATION_GLOBAL_INVOICE",
  configurationFolios: "configurarion-folios",
}

const Tabs = ({ tabActive, setTabActive }) => {
  return (
    <div className="mt-2">
      <Nav tabs>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={{ active: tabActive == tabName.taxData }}
            onClick={() => {
              setTabActive(tabName.taxData)
            }}
          >
            Datos fiscales distribuidor
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={{ active: tabActive == tabName.csdsCatalogs }}
            onClick={() => {
              setTabActive(tabName.csdsCatalogs)
            }}
          >
            Catálogo de CSDs
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={{ active: tabActive == tabName.otherCharges }}
            onClick={() => {
              setTabActive(tabName.otherCharges)
            }}
          >
            Concepto Otros Cargos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={{ active: tabActive == tabName.otherDiscounts }}
            onClick={() => {
              setTabActive(tabName.otherDiscounts)
            }}
          >
            Concepto Otros Descuentos
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={{ active: tabActive == tabName.defaultConcepts }}
            onClick={() => {
              setTabActive(tabName.defaultConcepts)
            }}
          >
            Conceptos Predeterminados
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={{ active: tabActive == tabName.configurationFolios }}
            onClick={() => {
              setTabActive(tabName.configurationFolios)
            }}
          >
            Configuración de folios
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  )
}

Tabs.propTypes = {
  tabActive: PropTypes.string,
  setTabActive: PropTypes.func,
}

export default Tabs
