import React, { useEffect, useState } from "react"

import { Card, CardBody } from "reactstrap"

import FullSpinner from "components/Common/FullSpinner"
import PageBase from "components/Common/PageBase"
import Filters from "./Filters"
import HeaderReport from "./HeaderReport"
import TableReport from "./TableReport"

import useRequest from "hooks/useRequest"
import {
  getSalesConcentrateReport,
  getCostsConcentrateReport,
  getCostsAccountStatementsReport,
} from "helpers/backend_helper"

import "../../../../assets/scss/reports/daily-movements.scss"
import moment from "moment"
import { getAccountingAcounts } from "store/accounting-accounts/actions"
import { useDispatch } from "react-redux"

const initialPeriod =
  moment().startOf("month").format("YYYY-MM-DD") +
  "/" +
  moment().endOf("month").format("YYYY-MM-DD")

const breadcrumbList = [
  { id: "1", title: "Administrativo", active: false },
  { id: "2", title: "Reportes", active: false },
  { id: "3", title: "Estado de cuenta", active: true },
]

export default function CostsAccountStatementsContainer() {
  const dispatch = useDispatch()

  const [period, setPeriod] = useState(initialPeriod)
  const [columnOrder, setColumnOrder] = useState({
    column: "",
    order: "",
  })

  const { column, order } = columnOrder

  const { data, isLoading, req } = useRequest()

  const handleFilters = ({ periodo, account, provider }) => {
    const config = {
      params: {
        period: periodo,
        provider,
        account,
        column,
        order,
      },
    }
    req(getCostsAccountStatementsReport(config))
  }

  useEffect(() => {
    dispatch(getAccountingAcounts())
  }, [])

  return (
    <>
      {isLoading && <FullSpinner />}
      <PageBase
        titleMeta="Reporte de estados de cuenta"
        titleBread="Reporte de estados de cuenta"
        itemsBread={breadcrumbList}
      >
        <Card>
          <CardBody>
            <Filters
              data={data}
              handleFilters={handleFilters}
              setPeriod={setPeriod}
              columnOrder={columnOrder}
              period={period}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {data && data.report.length ? (
              <>
                <HeaderReport period={period} />
                <TableReport
                  data={data.report}
                  totals={data.totals}
                  setColumnOrder={setColumnOrder}
                  columnOrder={columnOrder}
                />
              </>
            ) : (
              <p>No hay datos con el periodo seleccionado</p>
            )}
          </CardBody>
        </Card>
      </PageBase>
    </>
  )
}
