import React from 'react';
import PropTypes from 'prop-types';

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableTotal({ totals }) {

    const {
     pieces,
     kgs,
     total_vta,
     merma,
     mortalidad,
     cobro,
     venta_neta,
     pagado,
     pagos,
     nota_cargo,
     nota_credito,
     payments
 } = totals;

    return (
        
            <tr className='table-head'>
                <th colSpan={5} className="text-danger f-bold text-right">Totales</th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(pieces) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(kgs) }
                </th>
                <th></th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(total_vta) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(merma) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(mortalidad) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(nota_credito) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(nota_cargo) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(venta_neta) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(payments) }
                </th>
                <th></th>
            </tr>
        
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object
};