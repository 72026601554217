import { tax_configuration } from "common/sat/Taxes"

export const parseConfig = config => {
  const data = config?.value ? JSON.parse(config.value) : {}

  return {
    Id: config.id,
    ProductCode: data?.ProductCode || "",
    Description: data?.Description || "",
    UnitCode: data?.UnitCode || "",
    TaxObject: data?.TaxObject || "",
    tax_configuration: data?.tax_configuration || tax_configuration,
  }
}
