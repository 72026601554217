import React from "react"
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

const TopCities = ({ topClients }) => {

  const total = topClients.reduce((prev,current) => prev + (+current.venta_neta), 0);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top Clientes</CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-group text-danger display-4" />
            </div>
            <h3>{ total.toFixed(2) }</h3>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
              {topClients.map(client => (
                  <tr key={client.name}>
                    <td style={{ width: "30%" }}>
                      <p className="mb-0">{ client.name }</p>
                    </td>
                    <td style={{ width: "25%" }} className="text-right">
                      <h5 className="mb-0">
                        { client.venta_neta }
                      </h5>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopCities

TopCities.propTypes = {
  topClients: PropTypes.array
}

