import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';
import Select from 'react-select'

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import { PropTypes } from 'prop-types';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from 'react-router-dom';
import { useState } from "react";
import { useRef } from "react";
import { getClientDetail, getRegions, postDelivery, putClient, putDevelivery, putProvider, validateZipCode } from "helpers/backend_helper";
import { Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import RangeTime from "components/Common/RangeTime";
import BreadcrumbNav from "components/Common/BreadcrumbNav";

import toastr from "toastr"
import ClientForm from '../common/ClientForm';
import AddressForm from "../common/AddressForm";
import AddressWithGpsForm from "../common/AddressWithGPSForm";

const breadcrumbItems = [
  { id:'1', title: 'Catálogos', link: '', active: false },
  { id:'2', title: 'Clientes', link: '/catalogs/clients', active: false },
  { id:'3', title: 'Editar', link: '', active: true }
];

const CatalogClientFormEdit = (props) => {
  const { page } = props.location.state;

  console.log(props.location.state)

  const [model, setModel] = useState (props.location.state)
  const [selectedRegion, setSelectedRegion] = useState(model.invoice_address?.region_id)
  const [curAddress, setCurAddress] = useState({})
  const [hours, setHours] = useState({start: null, end: null})
  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const [regions, setRegions] = useState ([])
  const refForm = useRef('form-user-create')
  const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false)

  useEffect(() => {
    async function getData()  {
      //setLoading(true);
      //const response = await getRegions()
      const response2 = await getClientDetail(model.id)
      console.log('response2', response2)
      //setLoading(false);
      //setRegions(response.map( e => { return {label: e.name, value: e.id} } ))
      setModel({...model, deliveries_address: response2.deliveries_address, distribuitors: response2.distribuitors})
      setHours({start: response2.distribuitors[0]?.pivot.start ?? '10:00', end: response2.distribuitors[0]?.pivot.end ?? '11:00'})
    }

    getData();
  }, []);

  const handleValidSubmit = async (event, values) => {
    console.log('values TT', values)
    try {
      const resp = await validateZipCode({ cp: values.cp })
      if(!resp.cp) {
        toastr.error('Código postal no válido');
        return;
      };
    } catch (error) {
      toastr.error(error);
    }

    setModel({...model, ...values, ...hours })
    setBtnSaveLoad(true)    
    try {
      const response = await putClient({id: model.id, ...values, ...hours})
      setModel(response)
      setError(null)
      setCompleted('Se completo la operación')
    } catch(error) {
      console.log(error)
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const handleChange = (values) => {
    setHours({start: values.start, end: values.end})
  }

  const handleValidSubmitAddress = async (event, values) => {
    console.log('values', values)
    setCurAddress({...curAddress, ...values})
    setBtnSaveLoad(true)
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      console.log('user', obj.user);
      console.log('modela', {id: model.id, ...values})

      let newModel = {}
      if ('indexItem' in curAddress && curAddress.indexItem != null) {
        const response = await putDevelivery(obj.user.id, {id: curAddress.id, ...values, region_id: selectedRegion})
        newModel = {...model }
        newModel.deliveries_address[curAddress.indexItem] = response;
      } else {
        const response = await postDelivery(obj.user.enterprise_id, {...values, region_id: selectedRegion})
        newModel = {...model }
        newModel.deliveries_address.push(response)
      }

      setModel(newModel)
      setError(null)
      setCompleted('Se completo la operación')
    } catch(error) {
      console.log(error)
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const addAddress = (indexItem, edit = false) => {
    const address = indexItem != null  ? model.deliveries_address[indexItem] : {}
    address.edit = edit
    address.indexItem = indexItem
    setCurAddress(address)
    setOffCanvasIsOpen(true)
  }
  const delAddress = (indexItem) => {
    const newAddres = [...model.deliveries_address]
    newAddres.splice(indexItem, 1)
    setModel({...model, deliveries_address: newAddres})
  }

  const toggleRightCanvas = () => {
    setCompleted(null)
    setOffCanvasIsOpen(!offCanvasIsOpen)
  }

  console.log('model', model)
  console.log('curAddress', curAddress)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de Clientes</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Clientes"  items={breadcrumbItems}/>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? <Alert color="success">{completed}</Alert> : null}

                    <ClientForm 
                      client={model} 
                      handleChangeTimes={(values) => setHours({...hours, ...values})}
                      times={hours}
                    ></ClientForm>

                    <table className="table table-responsive table-bordered table-sm">
                      <thead>
                      <tr>
                          <th colSpan={5} className="text-center">
                            Direcciones de entrega
                          </th>
                        </tr>
                        <tr>
                          <th>Región</th>
                          <th>Dirección</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                      {( model.deliveries_address || [] ).map((address, key) => (
                        <tr key={key}>
                          <td>{address.region?.name}</td>
                          <td>{address.address_string}</td>
                          <td>
                            <Button type="button" className="btn btn-warning btn-sm" onClick={ () => addAddress(key, true) }>
                              <i className="fas fa-edit"></i>
                            </Button> <Button type="button" className="btn btn-danger btn-sm" onClick={ () => delAddress(key) }>
                              <i className="fas fa-trash"></i>
                            </Button> 
                          </td>
                        </tr>
                      ))}
                      { model.deliveries_address?.length == 0 && (
                        <tr>
                          <td colSpan={6} className="text-center">Sin Direcciones de Entrega</td>
                        </tr>
                      )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={6} className="text-center">
                            <Button type="button" className="btn btn-danger btn-sm" onClick={ () => addAddress() }>
                              <i className="fas fa-plus"></i> Agregar dirección de entrega
                            </Button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    <div className="text-center mb-5">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> { model.isActivated ? 'Guardar': 'Activar' }
                      </button>
                      <Link to={`/catalogs/clients?page=${page}`} className="btn w-md btn-sm btn-danger ms-2">
                        <i className="fas fa-reply"></i> Atrás
                      </Link>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}

        <Offcanvas
            isOpen={ offCanvasIsOpen }
            direction="end"
            toggle={ toggleRightCanvas }
        >                
          <OffcanvasHeader toggle={ toggleRightCanvas }>
            { 'edit' in curAddress && curAddress.edit ? 'Modificar dirección de entrega' : 'Nueva dirección de entrega' }
          </OffcanvasHeader>
          <OffcanvasBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmitAddress(e, v)
              }}
            >
              {error ? <Alert color="danger">{error}</Alert> : null}
              {completed ? <Alert color="success">{completed}</Alert> : null}
                
              <AddressWithGpsForm entityAddress={curAddress} />

              <div className="text-center mt-5">
                <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                  <i className="fas fa-save"></i> Guardar
                </button>
                <button type="button" className="btn btn-sm btn-danger w-md ms-2" disabled={ btnSaveLoad } onClick={toggleRightCanvas}>
                  <i className="fas fa-reply"></i> Cerrar
                </button>
              </div>
            </AvForm>
            
          </OffcanvasBody>
        </Offcanvas>

      </div>
    </React.Fragment>
  )
}

CatalogClientFormEdit.propTypes = {
  provider: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object.isRequired,
  })
};

export default CatalogClientFormEdit
