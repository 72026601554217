import React from 'react';

import {
    Table
} from 'reactstrap';

import TableMovementItem from './TableMovementItem';
import Header from './TableHeader';
import TableTotal from './TableTotal';

export default function TableReport() {
    return (
        <div className="table-responsive">
            <Table className="table mb-0 report-table">
                <tbody>
                    <Header />
                    <TableMovementItem />
                    <TableMovementItem />
                    <TableMovementItem />
                    <TableMovementItem />
                    <TableMovementItem />
                    <TableMovementItem />
                    <TableMovementItem />
                    <TableMovementItem />                    
                    <TableTotal />
                </tbody>
            </Table>
        </div>
    );
}