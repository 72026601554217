import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "pages/Authentication/UserProfile/ProfileContainer"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword/ForgetPasswordContainer"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Catalogs
import CatalogUserList from "../pages/Catalogos/Users/list"
import CatalogUserFormCreate from "../pages/Catalogos/Users/form-create"
import CatalogProductList from "pages/Catalogos/Products/list"
import CatalogProductFormCreate from "pages/Catalogos/Products/form-create"
import CatalogProviderList from "pages/Catalogos/Providers/list"
import CatalogProviderFormCreate from "pages/Catalogos/Providers/form-create"
import CatalogClientsList from '../pages/Catalogos/Clients/list';
import CatalogClientFormCreate from '../pages/Catalogos/Clients/form-create';
import ProviderDailyFormCreate from "pages/Provider/Daily/form-create"
import ProviderOrders from "pages/Provider/Daily/orders"
import ClientDailyFormCreate from "pages/Clients/Daily/form-create"
import CientOrders from "pages/Clients/Daily/orders"
import DistributorOrders from "pages/Distributor/Daily/orders"
import CatalogProductFormEdit from 'pages/Catalogos/Products/form-edit';
import DistribuitorDailyFormCreate from "pages/Distributor/Daily/form-create"
import CatalogProviderFormEdit from "pages/Catalogos/Providers/form-edit"
import CatalogClientFormEdit from 'pages/Catalogos/Clients/form-edit';
import ClientsDeliveriesList from "pages/Clients/Deliveries/list"
import ClientsDeliveriesFormCreate from 'pages/Clients/Deliveries/form-create';
import ClientsDeliveriesFormEdit from "pages/Clients/Deliveries/form-edit"
import ConfigsFormEdit from "pages/Distributor/Configs/form-edit"
import OrderContainer from "pages/OrderDetail/OrderContainer"
import CatalogUnitList from '../pages/Catalogos/Units/list';
import CatalogUnitFormCreate from '../pages/Catalogos/Units/form-create';
import CatalogUnitFormEdit from '../pages/Catalogos/Units/form-edit';
import CatalogRegionList from '../pages/Catalogos/Regions/list';
import CatalogRegionFormCreate from '../pages/Catalogos/Regions/form-create';
import CatalogRegionFormEdit from '../pages/Catalogos/Regions/form-edit';
import CatalogUserFormEdit from "pages/Catalogos/Users/form-edit"

import ClientProviderContainer from "pages/Administrative/ClientProvider/ClientProviderContainer"
import BanksContainer from "pages/Administrative/Banks/BanksContainer";
import CostsContainer from "pages/Administrative/Costs/CostsContainer";
import CatalogUserInternalList from '../pages/Catalogos/UsersInternal/list';
import CatalogUserInternalFormCreate from '../pages/Catalogos/UsersInternal/form-create';
import CatalogUserInternalFormEdit from '../pages/Catalogos/UsersInternal/form-edit';
import CatalogAccountingList from '../pages/Administrative/Accounts/list';
import CatalogAccountingFormCreate from '../pages/Administrative/Accounts/form-create';
import CatalogAccountingFormEdit from '../pages/Administrative/Accounts/form-edit';
import SalesContainer from '../pages/Administrative/Reports/Sales/SalesContainer';
import DailyMovementsContainer from "pages/Administrative/Reports/DailyMovements/DailyMovementsContainer"
import CashContainer from "pages/Administrative/Reports/Cash/CashContainer"
import ReviewContainer from "pages/Administrative/Reports/Review/ReviewContainer"
import BillingContainer from "pages/Administrative/Reports/Billing/BillingContainer"
import SalesFolioContainer from "pages/Administrative/Reports/SalesFolio/SalesFolioContainer"
import ClientLanding from "pages/Landing/Client"
import AccountingConcentratesContainer from "pages/Administrative/Reports/AccountingConcentrates/AccountingConcentratesContainer"
import ProviderLanding from "pages/Landing/Provider"
import DistributorOrdersReconcile from '../pages/Distributor/Daily/orders_concile';
import OrderReconcileContainer from '../pages/OrderDetailConcile/OrderConcileContainer';
import SalesConcentrateContainer from "pages/Administrative/Reports/SalesConcentrate/SalesConcentrateContainer"
import ShoppingConcentrate from "pages/Administrative/Reports/ShoppingConcentrate/ShoppingConcentrateContainer"
import CatalogPriceProviderList from "pages/Catalogos/PricesProviders/list"
import DailyFormUserCreate from '../pages/Distributor/Clients/Daily/form-create';
import CatalogHolidaysList from "pages/Catalogos/Holidays/list"
import OrderConcileContainer from "../pages/OrderDetailConcile/OrderConcileContainer"
import Availability from "pages/Distributor/Availability/Availability"
import ProviderBranches from '../pages/Provider/Branches/form-edit';
import DailyFormProviderCreate from '../pages/Distributor/Providers/Daily/form-create';
import DistributorProviderOrders from "pages/Distributor/Daily/provider_orders"
import OrderProviderContainer from '../pages/OrderProviderDetail/OrderProviderContainer';
import AnalizePrices from "pages/Distributor/AnalizePrices/analize_prices"
import BalanceClientContainer from '../pages/Administrative/Reports/BalanceClients/BalanceClientsContainer';
import OperationsClientContainer from '../pages/Administrative/Reports/Operations/OperationsClientContainer';
import OperationsProviderContainer from '../pages/Administrative/Reports/OperationsProvider/OperationsProviderContainer';
import BillingDetailContainer from '../pages/Administrative/Reports/BillingDetail/BillingDetailContainer';
import AccountsReceivableContainer from "pages/Administrative/Reports/AccountsReceivable/AccountsReceivableContainer";
import InventoryContainer from "pages/Administrative/Reports/Inventory/InventoryContainer";
import AccountStatementsContainer from "pages/Administrative/Reports/AccountStatements/AccountStatementsContainer";
import AccountProvStatementsContainer from "pages/Administrative/Reports/AccountProvStatements/AccountProvStatementsContainer";
import AccountsProviderPay from "pages/Administrative/Reports/AccountsProviderPay/AccountsProviderPay";
import CostsConcentrate from "pages/Administrative/Reports/CostsConcentrate/CostsConcentrateContainer";
import AccountBankStatementsContainer from "pages/Administrative/Reports/AccountBankStatements/AccountBankStatementsContainer";
import CostsAccountStatementsContainer from "pages/Administrative/Reports/CostsAccountStatements/CostsAccountStatementsContainer"
import BanksCashFlowContainer from "pages/Administrative/Reports/BanksCashFlow/BanksCashFlowContainer"
import CatalogRoles from "pages/Catalogos/Roles/RolesContainer"
import EnterpriseDetailContainer from "pages/Authentication/EnterpriseDetail/EnterpriseDetailContainer"
import CatalogEnterprisesList from "pages/Catalogos/Enterprises/list"
import CatalogEnterpriseFormEdit from "pages/Catalogos/Enterprises/form-edit"
import CatalogEnterprisesFormCreate from "pages/Catalogos/Enterprises/form-create"
import BillingConfiguration from "pages/BillingConfiguration/BillingConfiguration"
import Billing from "pages/Billing/Billing"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/user/profile", component: UserProfile },
  { path: "/enterprise/detail", component: EnterpriseDetailContainer },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  { path: "/catalogs/users", component: CatalogUserList },
  { path: "/catalogs/users/form-create", component: CatalogUserFormCreate },
  { path: "/catalogs/users/form-edit", component: CatalogUserFormEdit },

  { path: "/catalogs/users-internals", component: CatalogUserInternalList },
  { path: "/catalogs/users-internals/form-create", component: CatalogUserInternalFormCreate },
  { path: "/catalogs/users-internals/form-edit", component: CatalogUserInternalFormEdit },

  { path: "/catalogs/products", component: CatalogProductList },
  { path: "/catalogs/products/form-create", component: CatalogProductFormCreate },
  { path: "/catalogs/products/form-edit", component: CatalogProductFormEdit },

  { path: "/catalogs/providers", component: CatalogProviderList },
  { path: "/catalogs/providers/form-create", component: CatalogProviderFormCreate },
  { path: "/catalogs/providers/form-edit", component: CatalogProviderFormEdit },

  { path: "/catalogs/clients", component: CatalogClientsList },
  { path: "/catalogs/clients/form-create", component: CatalogClientFormCreate },
  { path: "/catalogs/clients/form-edit", component: CatalogClientFormEdit },
  { path: "/catalogs/enterprises", component: CatalogEnterprisesList },
  { path: "/catalogs/enterprises/form-create", component: CatalogEnterprisesFormCreate },
  { path: "/catalogs/enterprises/form-edit", component: CatalogEnterpriseFormEdit },

  { path: "/catalogs/units", component: CatalogUnitList },
  { path: "/catalogs/units/form-create", component: CatalogUnitFormCreate },
  { path: "/catalogs/units/form-edit", component: CatalogUnitFormEdit },

  { path: "/catalogs/regions", component: CatalogRegionList },
  { path: "/catalogs/regions/form-create", component: CatalogRegionFormCreate },
  { path: "/catalogs/regions/form-edit", component: CatalogRegionFormEdit },

  { path: "/catalogs/prices-providers", component: CatalogPriceProviderList},

  { path: "/catalogs/holidays", component: CatalogHolidaysList },
  
  { path: "/catalogs/roles", component: CatalogRoles },

  { path: "/provider/daily", component: ProviderDailyFormCreate },
  { path: "/provider/orders", component: ProviderOrders },
  { path: "/provider/branches", component: ProviderBranches },

  { path: "/client/daily", component: ClientDailyFormCreate },
  { path: "/client/orders", component: CientOrders },
  
  { path: "/client/deliveries", component: ClientsDeliveriesList },
  { path: "/client/deliveries/form-create", component: ClientsDeliveriesFormCreate },
  { path: "/client/deliveries/form-edit", component: ClientsDeliveriesFormEdit },

  { path: "/distributor/availability", component: Availability },
  { path: "/distributor/availability/create", component: DailyFormProviderCreate },
  { path: "/distributor/daily", component: DistribuitorDailyFormCreate },
  { path: "/distributor/orders", component: DistributorOrders },
  { path: "/distributor/provider-orders", component: DistributorProviderOrders },
  { path: "/distributor/orders/create", component: DailyFormUserCreate },
  { path: "/distributor/configs", component: ConfigsFormEdit },
  { path: "/distribuitor/analize-prices", component: AnalizePrices},

  { path: "/order/:orderId", component: OrderContainer },  
  { path: "/order/reconcile/:orderId", component: OrderConcileContainer },  

  { path: "/order-provider/:orderId", component: OrderProviderContainer },  
  { path: "/order-provider/reconcile/:orderId", component: OrderConcileContainer },  

  { path: "/administrative/clients-providers", component: ClientProviderContainer },
  { path: "/administrative/banks", component: BanksContainer },
  { path: "/administrative/costs", component: CostsContainer },

  { path: "/administrative/accountings", component: CatalogAccountingList },
  { path: "/administrative/accountings/form-create", component: CatalogAccountingFormCreate },
  { path: "/administrative/accountings/form-edit", component: CatalogAccountingFormEdit },

  { path: "/administrative/reports/sales", component: SalesContainer },
  { path: "/administrative/reports/daily-movements", component: DailyMovementsContainer },
  { path: "/administrative/reports/cash", component: CashContainer },
  { path: "/administrative/reports/review", component: ReviewContainer },
  { path: "/administrative/reports/billing", component: BillingContainer },
  { path: "/administrative/reports/sales-folio", component: SalesFolioContainer},
  { path: "/administrative/reports/accounting-concentrates", component: AccountingConcentratesContainer},
  { path: "/administrative/reports/sales-concentrate", component: SalesConcentrateContainer },
  { path: "/administrative/reports/shopping-concentrate", component: ShoppingConcentrate },
  { path: "/administrative/reports/clients-balance", component: BalanceClientContainer },
  { path: "/administrative/reports/clients-operations", component: OperationsClientContainer },
  { path: "/administrative/reports/providers-operations", component: OperationsProviderContainer },
  { path: "/administrative/reports/billing-detail", component: BillingDetailContainer },
  { path: "/administrative/reports/accounts-receivable", component: AccountsReceivableContainer },
  { path: "/administrative/reports/inventory", component: InventoryContainer },
  { path: "/administrative/reports/accounts-statements", component: AccountStatementsContainer },
  { path: "/administrative/reports/accounts-prov-statements", component: AccountProvStatementsContainer },
  { path: "/administrative/reports/accounts-pay", component: AccountsProviderPay },
  { path: "/administrative/reports/costs-concentrate", component: CostsConcentrate },
  { path: "/administrative/reports/costs-account-statements", component: CostsAccountStatementsContainer },
  { path: "/administrative/reports/accounts-bank-statements", component: AccountBankStatementsContainer },
  { path: "/administrative/reports/banks-cash-flow", component: BanksCashFlowContainer },
  
  { path: "/billing", component: Billing },
  { path: "/billing-configuration", component: BillingConfiguration },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

const landingRoutes = [
  { path: "/landing/client", component: ClientLanding },
  { path: "/landing/provider", component: ProviderLanding }
];

export { publicRoutes, authProtectedRoutes, landingRoutes }
