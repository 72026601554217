import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { formatPeriod } from 'helpers/formats';

const TITLE = process.env.REACT_APP_REPORT_TITLE

export default function HeaderReport({ period }) {
    return (
        <>
            <h3 className="text-danger f-bold text-center">{ TITLE }</h3>
            <h4 className="mb-4 f-bold text-center">Concentrado de Gastos</h4>
            <Row className='mb-4'>
                <Col md={12}>
                    <span className="d-block f-bold">Periodo Reporte: { formatPeriod(period) }</span>
                    <span className="d-block f-bold">Fecha Impresión: { new Date().toLocaleString("es-MX") }</span>
                </Col>
            </Row>
        </>
    );
}

HeaderReport.propTypes = {
    period: PropTypes.string
};