import React from 'react';
import { useSelector } from 'react-redux';

import PageBase from 'components/Common/PageBase';
import UserInfo from './UserInfo';
import UserForm from './UserForm';

const breadcrumbList = [
    { id: '1', title: 'Usuario', active: false },
    { id: '2', title: 'Perfil', active: true }
];

const ProfileContainer = () => {

    const user = useSelector(state => state.Login.user);    

    return (
        <PageBase
            titleMeta="Detalle del perfil"
            titleBread="Perfil"
            itemsBread={ breadcrumbList }
        >
            <UserInfo 
                user={ user }
            />
            <UserForm />
        </PageBase>
    )
}

export default ProfileContainer;