import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';
import Select from 'react-select'

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useState } from "react";
import { useRef } from "react";
import { postProduct, putProduct, putRegion, putUnit } from "helpers/backend_helper";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Alert } from "reactstrap";
import { getUnits, getProviders } from "helpers/backend_helper";
import { set } from "lodash-es";

const CatalogRegionFormEdit = (props) => {
  const [model, setModel] = useState (props.location.state)

  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')

  // Table Data
  let [color, setColor] = useState("#f46a6a");
  const [loading, setLoading] = useState (false);

  const handleValidSubmit = async (event, values) => {
    console.log('values', values)
    console.log({...model, ...values})
    setModel({...model, ...values})
    setBtnSaveLoad(true)
    try {
      console.log('==>', model);
      const response = await putRegion({id:model.id, ...values})
      setModel(response)
      setError(null)
      setCompleted('Se completo la operación')
      console.log(refForm);
    } catch(error) {
      console.log(error.toString())
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  console.log('model', props.location.state);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de REgiones</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Catalogos" breadcrumbItem="Regiones" breadcrumbSubItem="Editar">
            <Breadcrumbs breadcrumbItem="Crear" />
          </Breadcrumbs>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? <Alert color="success">{completed}</Alert> : null}
                      
                    <div className="mb-3">
                      <AvField
                          name="name"
                          label="Nombre"
                          value={model.name}
                          type="text"
                          required
                          errorMessage="Campo requerido"
                          placeholder=""
                        />
                    </div>
                    <input name="region_id" type="hidden" /> 
                    <div className="text-center">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> Guardar
                      </button>
                      <Link to="/catalogs/regions" className="btn w-md btn-sm btn-danger ms-2">
                        <i className="fas fa-reply"></i> Atrás
                      </Link>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

CatalogRegionFormEdit.propTypes = {
  product: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object.isRequired,
  })
};

export default CatalogRegionFormEdit
