import { getConfigFindOne, getProductFindOne } from "helpers/backend_helper"
import {
  buildConfigProductToCfdi,
  buildMovClientToCfdi,
  buildProductToCfdi,
} from "./helpers"
import { parseConfig } from "pages/BillingConfiguration/helpers"
import { tabName } from "pages/BillingConfiguration/components/Tabs"

export const getMovClientCfdiProduct = movClient => {
  //if (!movClient?.product) return
  if (movClient?.is_credit_note) return
  if (movClient?.is_debit_note) return


  const config = {
    params: {
      name: movClient.product,
    },
  }

  return getProductFindOne(config).then(product => {
    let Item = null

    if (product?.id) {
      Item = buildProductToCfdi(product, movClient)
    } else {
      Item = buildMovClientToCfdi(movClient)
    }

    return Item
  })
}

export const getOtherChargesCfdiProduct = movClient => {
  if (movClient?.nota_cargo === "0.00") return
  if (movClient?.is_credit_note) return
  if (movClient?.is_debit_note) return

  const config = {
    params: {
      name: tabName.otherCharges,
    },
  }

  return getConfigFindOne(config).then(resp => {
    const configParsed = parseConfig(resp)

    const Item = buildConfigProductToCfdi(configParsed, movClient.nota_cargo)

    return Item
  })
}

export const getOtherDiscountsCfdiProduct = movClient => {
  if (movClient?.nota_credito === "0.00") return
  if (movClient?.is_credit_note) return
  if (movClient?.is_debit_note) return

  const config = {
    params: {
      name: tabName.otherDiscounts,
    },
  }

  return getConfigFindOne(config).then(resp => {
    const configParsed = parseConfig(resp)

    const Item = buildConfigProductToCfdi(configParsed, movClient.nota_credito)

    return Item
  })
}
