import React from "react"
import { getCancellationXml } from "helpers/backend_helper"
import useRequest from "./useRequest"

const useDownloadCancellationXml = () => {
  const downloadRequest = useRequest()

  const createLink = cfdi => {
    if (!cfdi) return
    const a = document.createElement("a")
    a.href = cfdi.url
    a.target = "_blank"
    a.download = cfdi.filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const downloadXml = invoiceId => {
    downloadRequest.req(getCancellationXml(invoiceId)).then(cfdi => {
      createLink(cfdi)
    })
  }

  return {
    isDownloading: downloadRequest.isLoading,
    downloadXml,
  }
}

export default useDownloadCancellationXml
