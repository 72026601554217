import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_ACCOUNTING_ACCOUNTS
} from "./actionTypes";

import {
  getAccountingAcountsFail,
  getAccountingAcountsSuccess
} from "./actions"

import { getAccountingAccounts } from "helpers/backend_helper";

function* onGetAccountingAccounts(){
  try {
      const response = yield call(getAccountingAccounts);
      yield put(getAccountingAcountsSuccess(response));

  } catch (errors) {
      yield put(getAccountingAcountsFail(errors));
  }
}

function* accountingAcountsSaga() {
  yield takeEvery(GET_ACCOUNTING_ACCOUNTS, onGetAccountingAccounts)
}

export default accountingAcountsSaga;