import React from 'react';

export default function TableHeader() {
    return (
        <>
            <tr className="no-border-b bg-table-grey">
                <th colSpan={3}></th>
                <th colSpan={2} className="text-center">ENERO 2021</th>
                <th colSpan={2} className="text-center">TOTAL</th>
            </tr>
            <tr className="bg-table-grey">
                <th>Clave</th>
                <th>Atributo</th>
                <th>Unidad</th>
                <th className="text-right">Vta. en Uni.</th>
                <th className="text-right">Vta.Neta M.N.</th>
                <th className="text-right">Vta. en Uni.</th>
                <th className="text-right">Vta.Neta M.N.</th>
            </tr>
        </>
    );
}