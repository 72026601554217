import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Alert,
  Spinner,
  Modal,
  Nav,
  NavItem,
  NavLink
} from "reactstrap"
import Select from 'react-select'

import 'moment/locale/es';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getDistribuitorDaily, postDistribuitorDailyTruckAuth, postDistribuitorOrderPrice, sendOrdersNotificationsProviders } from 'helpers/backend_helper';
import "flatpickr/dist/themes/material_red.css";
import DistribuitorOrderApproveRow from '../../../components/Common/DistribuitorOrderApproveRow';

import './form-create.css'
import { deleteDistribuitorOrderItem, sendOrdersNotificationsClients } from '../../../helpers/backend_helper';
import * as _ from 'lodash';
import * as moment from 'moment';
import { postDistribuitorOrderItem } from 'helpers/backend_helper';
import Resume from "./resume";
import ReactDragListView from '../../../../node_modules/react-drag-listview/es/ReactDragListView';
import Authorization from './authorization';
import ResumeTrack from "./resume-track";

const DailyFormCreate = () => {
  // Tiempo restante
  
  const [tabActive, setTabActive] = useState(1);
  const [state, setState] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [proveedores, setProveedores] = useState(null)
  const [dates, setDates] = useState([{label: '', value: ''}])
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedTruck, setSelectedTruck] = useState(null)
  const [selectedOrderItem, setSelectedOrderItem] = useState(null)

  const [modalUpdateTrucks, setmodalUpdateTrucks] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [modalApproveTruck, setModalApproveTruck] = useState(false)
  const [modalUpdateOrderItem, setModalUpdateOrderItem] = useState(false)
  const [modalAuthTruck, setModalAuthTruck] = useState(false)  
  const [listLogistic, setListLogistic] = useState([]);
  
  const useFocus = () => {
    const ref = React.createRef()
    const setFocus = () => { 
      console.log('ref.current', ref.current)

      setTimeout(() => {
        ref.current && ref.current.focus()
      }, 100)
    }
  
    return {setFocus, ref}
  }
  const {setFocus, ref} = useFocus();
  
  const dragProps = {
    onDragEnd: (fromIndex, toIndex) => {
      const data = [...listLogistic];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setListLogistic(data);
    },
    nodeSelector: 'li',
    handleSelector: 'a'
  };

  const updateLocalOrders = (order) => {
    let newProviders = [...proveedores]
    newProviders?.forEach( newProveedor => { 
      newProveedor.orders = newProveedor.orders.map( e => {
        if (e.id == order.id)
          return order
        return e
      });
    });
    
    setProveedores(newProviders)
  }

  const calculatedData = () => {
    proveedores?.forEach( proveedor => {
      let orderItems = {};
      proveedor.orders.forEach( order => {
        order.items.forEach( item => {
          if( !(order.farm_id in orderItems) )
            orderItems[order.farm_id] = {}
          
          if( !(item.product_id in orderItems[order.farm_id]) )
            orderItems[order.farm_id][item.product_id] = []

          item.quantity_assigned = item.trucks.map( e => { return Number(e.quantity) }).reduce((a, b) => a + b, 0)

          let truckAsigned = {}
          let truckSort= {}
          item.trucks.forEach( (truck, idx) => {
            truckAsigned[truck.truck_name] = Number(truck.quantity);
            truckSort[truck.truck_name] = truck.sort ? truck.sort:null;
          });
          item.truckAsigned = truckAsigned
          item.truckSort = truckSort

          item.order = {...order, items: null}
          orderItems[order.farm_id][item.product_id].push(item)
        });
      });
      
      proveedor.rows.forEach(producto => {
        producto.orderItems = (producto.farm_id in orderItems && producto.product_id in orderItems[producto.farm_id]) ? orderItems[producto.farm_id][producto.product_id] : [];
        
        let truckAsigned = {}
        let truckDeliveryList = {}
        producto.quantities.forEach( truck => {
          let total = 0;
          producto.orderItems.forEach(e => {
            if(e.cancelled_at != null) return;
            total += truck.truck_name in e.truckAsigned ? e.truckAsigned[truck.truck_name]:0;

            if(e.truckAsigned[truck.truck_name] > 0) {
              if(!(truck.truck_name in truckDeliveryList))
                truckDeliveryList[truck.truck_name] = [];
              truckDeliveryList[truck.truck_name].push({...e});
            }

          });
          truckAsigned[truck.truck_name] = total;
        });

        producto.truckAsigned = truckAsigned;

        const truckDeliveryListSorted = {}
        for(const [k, v] of Object.entries(truckDeliveryList)) {
          truckDeliveryListSorted[k] = _.sortBy(v, [(i) => { return (k in i.truckSort && i.truckSort[k] != null) ? i.truckSort[k] : moment(i.order.delivery_date).unix() }])
        }
        producto.truckDeliveryList = truckDeliveryListSorted;
      })
    })
  }
  calculatedData();

  const tog_update_trucks = (item) => {
    setSelectedTruck(item)
    setmodalUpdateTrucks(!modalUpdateTrucks);
  }

  const tog_modal_cancel = () => {
    setModalCancel(!modalCancel);
  }

  const tog_modal_approve_truck = (item) => {
    setSelectedTruck(item)
    setModalApproveTruck(!modalApproveTruck);
  }

  const tog_modal_update_order_item = (item) => {
    console.log('--', item)
    setSelectedOrderItem(item)
    setModalUpdateOrderItem(!modalUpdateOrderItem);
  }

  const tog_modal_auth_truck = (list, truck) => {
    if(list) setListLogistic(list)
    if(truck) setSelectedTruck(truck)
    setModalAuthTruck(!modalAuthTruck);
  }

  async function getDataInit()  {
    setLoading(true);
    const response = await getDistribuitorDaily();
    setLoading(false);
    console.log('rows', response.data)
    setProveedores(response.data);
    setDates(response.dates)
    setSelectedDate(response.dates[0])
  }

  useEffect(() => {
    getDataInit();
  }, []);

  useEffect(()=>{
    if(modalUpdateOrderItem)
      setFocus()
  }, [modalUpdateOrderItem])

  const handleInputChangeAssignedTruckUnit = (truckName, evt) => {
    const newSelectedOrderItem = {...selectedOrderItem} 
    newSelectedOrderItem.truckAsigned[truckName] = Number(evt.target.value);
    setSelectedOrderItem(newSelectedOrderItem)
  }

  const handleInputChangeAssignedPrice = (evt) => {
    const newSelectedOrderItem = {...selectedOrderItem} 
    newSelectedOrderItem.unit_price_shipped_new = Number(evt.target.value);
    setSelectedOrderItem(newSelectedOrderItem)
  }

  const handleAuthTruck = async () => {
    setLoadingModal(true);
    try {
      const response = await postDistribuitorDailyTruckAuth({truck: {...selectedTruck}, list_order_items: [...listLogistic]});
      getDataInit()
      /*
      const newproveedores = [...proveedores]
      newproveedores.forEach(prov => {
        prov.rows.forEach((e,i) => {
          console.log('updating', e, response)
        
          if( e.id==response.id ) {
            prov.rows[i] = response
          }
        });
      });
      setProveedores(newproveedores);
      */
      tog_modal_auth_truck()
      
    } catch (err) {
      console.error(err);
    }
    setLoadingModal(false);
  }

  const handleDateSelected = (option) => {
    setSelectedDate(option.value)
  }

  // Remote actions
  const updateSelectedTruck = async () => {
    setLoadingModal(true);
    try {
      const response = await postDistribuitorOrderPrice(selectedTruck);
      const newproveedores = [...proveedores]
      newproveedores.forEach(prov => {
        prov.rows.forEach(obj => {
          console.log('updating', obj, response)
          obj.quantities.forEach( e => {
            if( e.id==response.id ) {
              e.truck_units = response.truck_units
            }
          });
        });
      });
      setProveedores(newproveedores);
      tog_update_trucks(null)
    } catch (err) {
      console.error(err);
    }
    
    setLoadingModal(false);
  }

  const updateOrderItem = async () => {
    try {
      let quantities = selectedOrderItem?.formDaily?.quantities
      delete selectedOrderItem.order.items;
      delete selectedOrderItem.formDaily.orderItems;
      const data = {order_item: {...selectedOrderItem, unit_price_shipped_new: 'unit_price_shipped_new' in selectedOrderItem ? selectedOrderItem.unit_price_shipped_new:selectedOrderItem.unit_price_shipped}};

      for (const [key, value] of Object.entries(selectedOrderItem.truckAsigned)) {
        let truck = data.order_item.trucks.filter(e => { return e.truck_name == key })
        if (truck.length > 0) {
          truck[0].quantity_new = value;
        } else {
          data.order_item.trucks.push({ 
            truck_name: key,
            quantity_new: value ? value : 0,
            order_item_id: data.order_item.id,
            daily_quantity_id: _.find(quantities, {truck_name: key}).id
          })
        }
      }

      /*
      data.order_item.trucks.forEach( e => {
        e.quantity_new = selectedOrderItem.truckAsigned[e.truck_name];
      });
      */
      setLoadingModal(true)
      const response = await postDistribuitorOrderItem(data)
      setLoadingModal(false)
      updateLocalOrders(response)
      tog_modal_update_order_item()
    } catch(error) {
      console.log(error.toString())
      setLoadingModal(false)
    }
  }

  const cancelOrderItem = async () => {
    setLoadingModal(true);
    try {
      let response = await deleteDistribuitorOrderItem(selectedOrderItem.id);
      response = response.data;
      const providers = [...proveedores]
      providers.forEach(prov => {
        prov.orders.forEach(obj => {
          if(obj.id != response.id) return;
          obj.items.forEach( e => {
            if( obj.id==response.id && e.id==selectedOrderItem.id) {
              console.log('set cancelled_at', response.items.filter(f => f.id==selectedOrderItem.id)[0])
              e.cancelled_at = response.items.filter(f => f.id==selectedOrderItem.id)[0].cancelled_at;
            }
          });
        });
      });
      setState(providers)
      tog_modal_cancel(null)
      tog_modal_update_order_item(null)
    } catch (err) {
      console.error(err);
    }
    
    setLoadingModal(false);
  }

  const notifyProviders = async () => {
    setLoadingModal(true);
    try {
      let response = await sendOrdersNotificationsProviders({date: selectedDate});
    } catch (err) {
      console.error(err);
    }
  
    setLoadingModal(false);
  }

  const notifyClients = async () => {
    setLoadingModal(true);
    try {
      let response = await sendOrdersNotificationsClients({date: selectedDate});
    } catch (err) {
      console.error(err);
    }
  
    setLoadingModal(false);
  }

  console.log('selectedOrderItem', selectedOrderItem)
  console.log('listLogistic',listLogistic)
  return (
    <React.Fragment>

    <div className="page-content ">
      <MetaTags>
        <title>
          Logística de Pedidos
        </title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumbs title="Distribuidor" breadcrumbItem="Logística de pedidos" />
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody className="distribuitor">

                <Row>
                    <Col lg={4} className="text-end">
                      <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                        Fecha
                      </label>
                    </Col>
                    <Col lg={4} className="mb-3 text-center">
                      <Select
                        className="text-start"
                        id="formrow2-InputState"
                        value={{label: selectedDate, value: selectedDate}}
                        options={ dates.map( e => { return {label: e, value: e} } ) }
                        defaultInputValue={selectedDate}
                        onChange={handleDateSelected}
                        placeholder="- Seleccionar fecha -"
                      />
                    </Col>
                    <Col lg={4} className="mb-3 text-center">
                      <button type="button" onClick={(evt) => {  }} className="btn btn-danger btn-sm ms-2 mb-2 d-none"><i className="fas fa-envelope"></i> Autorizar y Notificar</button> <br />
                      <button type="button" onClick={(evt) => {  }} className="btn btn-danger btn-sm ms-2 mb-2 d-none"><i className="fas fa-envelope"></i> Notificar Pedidos a Proveedores</button> <br />
                      <button type="button" onClick={(evt) => {  }} className="btn btn-danger btn-sm ms-2 d-none"><i className="fas fa-envelope"></i> Notificar Pedidos a Clientes</button>
                    </Col>
                  </Row>

                  {loading && <div className="text-center"><Spinner style={{color:"#f46a6a"}} animation="border" variant="danger" /></div>}

                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={{active: tabActive == 1}}
                        onClick={() => {
                          setTabActive(1)
                        }}
                      >
                        Asignación Pedidos
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={{active: tabActive == 2}}
                        onClick={() => {
                          setTabActive(2)
                        }}
                      >
                        Autorización Logística
                      </NavLink>
                    </NavItem>
                    {
                    /*
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={{active: tabActive == 3}}
                        onClick={() => {
                          setTabActive(3)
                        }}
                      >
                        Resumen por Cliente
                      </NavLink>
                    </NavItem>
                    */
                    }
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={{active: tabActive == 4}}
                        onClick={() => {
                          setTabActive(4)
                        }}
                      >
                        Resumen por Camión
                      </NavLink>
                    </NavItem>
                  </Nav>

                <div className="mt-2">
                  { proveedores && proveedores.length == 0 && <Alert color="info">{'Aún no hay pedidos del dia'}</Alert> }

                  {
                    tabActive == 2 &&
                    <p className="text-end" >
                      <button 
                        type="button"
                        onClick={notifyProviders}
                        className="btn btn-danger btn-sm ms-2 mt-2"
                        disabled={loadingModal}
                      >
                        <i className="fas fa-envelope"></i> Notificar a Proveedores
                      </button> <br />
                    </p>
                  }

                  {
                    tabActive == 4 &&
                    <p className="text-end" >
                      <button
                        type="button"
                        onClick={notifyClients}
                        className="btn btn-danger btn-sm ms-2 mt-2"
                      >
                        <i className="fas fa-envelope"></i> Notificar Ordenes a Clientes
                      </button> <br />
                    </p>
                  }

                  {
                    tabActive == 1 &&
                      selectedDate  && (proveedores || []).map((proveedor, key) => (
                        proveedor.rows.filter( e => { return e.date == selectedDate; } ).length > 0 && <DistribuitorOrderApproveRow 
                          id={'provider' + key}
                          key={key}
                          rows={_.sortBy(proveedor.rows.filter( e => { return e.date == selectedDate; } ), ['product_position'],['ASC'])}
                          orders={proveedor.orders.filter( e => { return e.order_date == selectedDate; } )}
                          date={selectedDate}
                          toggleModal={tog_update_trucks}
                          toggleModalCancelar={tog_modal_cancel}
                          toggleModalApproveTruck={tog_modal_approve_truck}
                          toggleModalUpdateOrderItem={tog_modal_update_order_item}
                        />
                      ))
                  }
                  {
                    tabActive == 2 &&
                      selectedDate && (proveedores || []).map((proveedor, key) => (
                        proveedor.rows.filter( e => { return e.date == selectedDate; } ).length > 0 &&
                        <Authorization
                          id={'provider' + key}
                          key={key}
                          rows={proveedor.rows.filter( e => { return e.date == selectedDate; } )}
                          orders={proveedor.orders.filter( e => { return e.order_date == selectedDate; } )}
                          handleAuthTruck={handleAuthTruck}
                          date={selectedDate}
                          toggleModalAuth={tog_modal_auth_truck}
                        />
                      ))
                  }
                  {
                    /*tabActive == 3 &&
                      selectedDate && 
                        <Resume
                          id={'provider'}
                          rows={[].concat.apply([], proveedores.map( e => { return e.rows; } ))}
                          orders={[].concat.apply([], proveedores.map( e => { return e.orders; } ))}
                          handleAuthTruck={handleAuthTruck}
                          date={selectedDate}
                          toggleModalAuth={tog_modal_auth_truck}
                        />
                      */
                  }
                  {
                    tabActive == 4 &&
                      selectedDate && 
                        <ResumeTrack
                          id={'provider'}
                          rows={[].concat.apply([], proveedores.map( e => { return e.rows; } ))}
                          orders={[].concat.apply([], proveedores.map( e => { return e.orders; } ))}
                          handleAuthTruck={handleAuthTruck}
                          date={selectedDate}
                          toggleModalAuth={tog_modal_auth_truck}
                        />
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>

    { modalUpdateTrucks &&
      <Modal
        isOpen={modalUpdateTrucks}
        backdrop='static'
        toggle={() => {
          tog_update_trucks();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Modificar camión { selectedTruck?.truck_name }
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodalUpdateTrucks(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            disabled={loadingModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg={4} className="text-lg-end">
              <label>Unidades</label>
            </Col>
            <Col lg={6}>
              <input
                className="text-center w-100"
                name="change_units"
                type="number"
                placeholder=""
                min="0"
                value={selectedTruck?.truck_units}
                onChange={(evt) => { setSelectedTruck({ ...selectedTruck, truck_units: evt.currentTarget.value }) } }
                onWheel={(e) => e.target.blur() }
              /> 
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_update_trucks();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
            disabled={loadingModal}
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            onClick={(evt) => updateSelectedTruck()}
            type="button"
            className="btn btn-danger"
            disabled={loadingModal}
          >
            <i className="fas fa-check"></i> Modificar
          </button>
        </div>
      </Modal>
    }

    { modalApproveTruck &&
      <Modal
        isOpen={modalApproveTruck}
        backdrop='static'
        toggle={() => {
          tog_modal_approve_truck();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Autorizar Camión {selectedItem?.truck_name}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalApproveTruck(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            disabled={loadingModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg={12}>
                <p>
                {(getOrdersItems(selectedItem?.formDaily, selectedItem?.formDaily?.product_id, selectedItem?.formDaily?.farm_id)).map((orderItem, key2) => (
                  orderItem.cancelled_at == null &&
                  // getQuantityPerTruckClient(selectedItem.formDaily, orderItem, selectedItem) > 0 && 
                  <React.Fragment key={'a'+key2}>
                    {orderItem.client.company_name}: {(selectedItem.formDaily?.quantities || []).map((truck2, key) => (
                      <React.Fragment key={key2 + key}>
                        {   
                          selectedItem.truck_name == truck2.truck_name ? 
                          <b>{ getItemQuantityTruck(orderItem.id, truck2.id) } Pzas</b> : ''
                        }
                      </React.Fragment>
                    ))} <br />
                  </React.Fragment>
                ))}  
                </p>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_modal_approve_truck();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
            disabled={loadingModal}
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            onClick={(evt) => cancelOrderItem(selectedItem)}
            type="button"
            className="btn btn-danger"
            disabled={loadingModal}
          >
            <i className="fas fa-check"></i> Aceptar
          </button>
        </div>
      </Modal>
    }

    { modalUpdateOrderItem &&
      <Modal
        isOpen={modalUpdateOrderItem}
        backdrop='static'
        toggle={() => {
          tog_modal_update_order_item();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Actualizar Pedido
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalUpdateOrderItem(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            disabled={loadingModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
          {selectedOrderItem &&
            <Col lg={12} className="text-center">
              
              Cliente: {selectedOrderItem.order?.client?.company_name}<br/>
              Pedido: <b>{selectedOrderItem.quantity_original}</b><br/> <br/>
            </Col>
          }
            <Col lg={12}>
              <p className="text-center">
                <span className="me-2 d-inline-block">Precio Asignado:</span>
                <input
                  style={{width: '80px'}}
                  className="text-right"
                  name="price"
                  type="number"
                  placeholder="0"
                  step={0.10}
                  min={selectedOrderItem?.cost}
                  value={(selectedOrderItem && 'unit_price_shipped_new' in selectedOrderItem) ? selectedOrderItem?.unit_price_shipped_new : selectedOrderItem?.unit_price_shipped}
                  onChange={(evt) => handleInputChangeAssignedPrice(evt) }
                  onWheel={(e) => e.target.blur() }
                  disabled={selectedOrderItem?.cancelled_at?true:false}
                />
              </p>

              <table className="table table-sm text-center">
                <thead className="table-light">
                  <tr className="table-primary">
                    <th>Camión</th>
                    <th>Disponible</th>
                    <th>Unidades Asignadas</th>
                  </tr>
                </thead>
                <tbody>
                {(selectedOrderItem?.formDaily?.quantities || []).map((truck, key) => (
                  <React.Fragment key={selectedOrderItem.key2 + key}>
                  { selectedOrderItem.status != 'authorized' || (truck.truck_units - selectedOrderItem.formDaily.truckAsigned[truck.truck_name]) > 0 ?
                    <tr>
                      <td className="text-center" style={{width:'150px'}}>{truck.truck_name}</td>
                      <td>{ truck.truck_units - selectedOrderItem.formDaily.truckAsigned[truck.truck_name] }</td>
                      <td style={{width:'200px'}}>
                        <input
                          ref={key == 0 ? ref : null}
                          className="text-center w-100"
                          name={selectedOrderItem?.id + "_" + truck.truck_name}
                          type="number"
                          placeholder=""
                          min="0"
                          max={truck.truck_units - selectedOrderItem.formDaily.truckAsigned[truck.truck_name]}
                          value={ selectedOrderItem.truckAsigned[truck.truck_name] }
                          onChange={(evt) => handleInputChangeAssignedTruckUnit(truck.truck_name, evt) }
                          onWheel={(e) => e.target.blur() }
                          disabled={selectedOrderItem?.cancelled_at?true:false}
                        /> 
                      </td>
                    </tr>
                    : ''
                  }
                  </React.Fragment> 
                ))}
                </tbody>
              </table>
              
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="row w-100">
            <div className="col-6">
              <button
                disabled={selectedOrderItem?.cancelled_at?true:false}
                type="button" 
                onClick={() => tog_modal_cancel(selectedOrderItem)}
                className="btn btn-danger me-5"
              ><i className="fas fa-times"></i> Cancelar Órden</button>
            </div>
            <div className="col-6 offset-md- text-end">
              <button
                type="button"
                onClick={() => {
                  tog_modal_update_order_item();
                }}
                className="btn btn-secondary"
                data-dismiss="modal"
                disabled={loadingModal}
              >
                <i className="fas fa-times"></i> Cerrar
              </button>
              <button
                onClick={(evt) => updateOrderItem(selectedOrderItem)}
                type="button"
                className="btn btn-danger ms-2"
                disabled={loadingModal}
              >
                <i className="fas fa-check"></i> Aceptar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    }

    { modalCancel &&
      <Modal
        isOpen={modalCancel}
        backdrop='static'
        toggle={() => {
          tog_modal_cancel();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Cancelar Pedido de {selectedOrderItem?.order?.client?.company_name}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalCancel(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            disabled={loadingModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg={12}>
              <p>¿Desea cancelar el pedido de {selectedOrderItem?.quantity} unidades?</p>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_modal_cancel();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
            disabled={loadingModal}
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            onClick={(evt) => cancelOrderItem()}
            type="button"
            className="btn btn-danger"
            disabled={loadingModal}
          >
            <i className="fas fa-check"></i> Aceptar
          </button>
        </div>
      </Modal>
    }

    { modalAuthTruck &&
      <Modal
        isOpen={modalAuthTruck}
        backdrop='static'
        toggle={() => {
          tog_modal_auth_truck();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Autorizar Camión {selectedTruck?.truck_name}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalAuthTruck(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            disabled={loadingModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg={12}>
              <p>¿Deseas autorizar el camión {selectedTruck?.truck_name} y el siguiente orden de entrega?</p>
              <ReactDragListView {...dragProps}>
                <ul className="list-unstyled">
                {
                  listLogistic.map((orderItem, index) => (
                    <li className="bg-gray bg-soft p-1 mb-2" key={index}>
                      <a href="#"><i className="fas fa-arrows-alt text-danger fs-5"></i></a> <b>Orden: {index + 1} | Día {orderItem.delivery_date.substr(8)} | {orderItem.delivered ? orderItem?.delivered_region:''} {orderItem.delivered ? orderItem?.delivered_subregion:''} | {orderItem?.truckAsigned[selectedTruck.truck_name]}Pzas | {orderItem.order.client.company_name}</b>
                    </li>
                  ))
                }
                </ul>
              </ReactDragListView>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_modal_auth_truck();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
            disabled={loadingModal}
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            onClick={(evt) => handleAuthTruck()}
            type="button"
            className="btn btn-danger"
            disabled={loadingModal}
          >
            <i className="fas fa-check"></i> Aceptar
          </button>
        </div>
      </Modal>
    }

    </React.Fragment>
  )
}

export default DailyFormCreate;
