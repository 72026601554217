import React from 'react';
import PropTypes from 'prop-types';

const nf = new Intl.NumberFormat('es-MX');

export default function TableTotal({ totals }) {

    const {
        total,
    } = totals;

    return (
        <>
            <tr>
                <td colSpan={3} className="f-bold f-blue text-right">Total</td>
                <td className="text-danger  f-bold f-blue border-b-blue text-right">
                    {total}
                </td>
                
            </tr>
        </>
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object
};