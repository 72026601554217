import React from "react"
import PropTypes from 'prop-types'
import { Col, Row, Alert, Form, Table, Button, Popover, PopoverHeader, PopoverBody } from "reactstrap"
import { useState } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { postClientOrder, postDistribuitorOrder, postDistribuitorOrderItem } from "helpers/backend_helper"
import RatingCustom from "./RatingCustom"
import { quantity } from 'chartist';
import { getClassNameOrderStatus } from "helpers/order_helper"
import './styles.css';

const DistribuitorOrderApproveRow = props => {
  const [popover, setpopover] = useState({});
  
  //const [state, setState] = useState([...props.rows])
  const state = props.rows;
  const orders = props.orders;
  //const ordersParent = props.orders;
  //const [orders, setOrders] = useState(props.orders)
  const [values, setValues] = useState({
    provider_id: null,
    products: {},
    timeDelivery: '',
  })
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)

  


  const isValidPedido = (itemId, clientId) => {
    let trucks = {};
        
    orders.forEach( o => {
      o.items.forEach( i => {        
        if ('quantity_new' in i) {} else { 
          i.quantity_new = i.quantity
        }
      })
    });
    
    let total_quantity_per_truck = {};
    orders.forEach( o => {
      o.items.forEach( i => {
        if(i.id != itemId) return;

        let total_quantity = 0;
        if (i.quantity_new > 0) {
          i.trucks.forEach( truck => {
            const quantityTruck = getItemQuantityTruck(i.id, truck.daily_quantity_id)
            total_quantity += quantityTruck

            const clave = i.product_id + '_' + truck.truck_name
            if(clave in total_quantity_per_truck)
              total_quantity_per_truck[clave] += quantityTruck
            else
              total_quantity_per_truck[clave] = quantityTruck
              
          })

          const trucks = i.trucks.filter( truck => {
            return truck.new_quantity > 0
          })
          
          if (trucks.length == 0)
            throw new Error('Debe colocar las cantidades a despachar de cada camión del cliente ' + o.client.company_name);

          console.log('-', total_quantity, i.quantity_new, total_quantity !== parseFloat(i.quantity_new))
          if (total_quantity !== parseFloat(i.quantity_new)) {
            throw new Error('El total de cantidades despachadas debe coincidir con la cantidad ordenada del cliente ' + o.client.company_name);
          }          
        }
      })
    });
    console.log(total_quantity_per_truck)

    state.forEach((s) => {
      s.quantities.forEach((q) => {
        const clave = s.product_id + '_' + q.truck_name
        if (clave in total_quantity_per_truck && total_quantity_per_truck[clave] > q.truck_units)
          throw new Error('La cantidad de camion ' + q.truck_name + ' del producto ' + s.product + ' esta sobrepasado');
      })
    })
  
    for (const key in trucks) {
      const item = trucks[key];
      if( item.ordered > item.available) {
        throw new Error('Verifica los pedidos del producto ' + item.product + ' sobrepasan el disponible');
      }
    }

    return true;
  }

  const save = async (data) => {
    try {
      const response = await postDistribuitorOrder({ orders: data })
      setCompleted('Se completo la operación')
      setOrders(response)
      setError(null)
    } catch(error) {
      console.log(error.toString())
      setError(error.toString())
      setCompleted(null)
    }
  }

  const updateOrders = (order) => {
    let newOrders = [...orders]
    newOrders = newOrders.map( e => {
      if (e.id == order.id)
        return order
      return e
    })
    setOrders(newOrders)
  }

  const saveOrderItem = async (data) => {
    try {
      const response = await postDistribuitorOrderItem({ order_item: data })
      setCompleted('Se completo la operación')
      updateOrders(response)
      setError(null)
    } catch(error) {
      console.log(error.toString())
      setError(error.toString())
      setCompleted(null)
    }
  }

  const handleValidSubmit = async (e, formValues) => {
    try {
      setBtnSaveLoad(true)
      isValidPedido();
      await save(orders)
      //props.onSubmit(data)
      setError(null)
    } catch (e) {
      setError(e.toString())
    }
    setBtnSaveLoad(false)
  }

  const setItemPrice = (orderItemId, evt) => {
    const value = evt.target.valueAsNumber ? evt.target.valueAsNumber : 0;
    
    let newOrders = [...orders]
    newOrders.forEach( e => { 
      const A = e.items.filter( item => {
        return item.id == orderItemId
      })
      A.forEach( item => {
        item.unit_price_shipped_new = value;
      })
    })

    console.log('newOrder', newOrders)
    setOrders(newOrders);
  }

  const setItemTruck = (orderItemId, providerTruck, evt) => {
    const value = evt.target.valueAsNumber ? evt.target.valueAsNumber : 0;
    
    let newOrders = [...orders]
    newOrders.forEach( e => { 
      const items = e.items.filter( item => {
        return item.id == orderItemId
      })
      if (items.length > 0) {
        const item = items[0]
        const trucks = item.trucks.filter( truck => {
          return truck.truck_name == providerTruck.truck_name
        })

        if (trucks.length) {
          trucks[0].new_quantity = value;
        } else {
          const truck = {
            new_quantity: value,
            truck_name: providerTruck.truck_name,
            order_item_id: item.id,
            daily_quantity_id: providerTruck.id, 
          }
          item.trucks.push(truck);
        }
      }
    })
    setOrders(newOrders)
  }

  const getTotalTruck = (productId, truck) => {
    let total_quantity = 0;
    orders.forEach( o => {
      o.items.forEach( i => {
        if (i.product_id == productId && i.quantity > 0) {
          i.trucks.forEach( truckItem => {
            if(truck.truck_name == truckItem.truck_name) {
              total_quantity += parseFloat(truckItem.new_quantity)
            }
          })
        }
      })
    })
    return (<span className={total_quantity > parseFloat(truck.truck_units) ? "text-danger":"" }>{total_quantity}</span>)
  }

  const addCancelled = (orderItem) => {
    let A = null;
    orders.forEach( e => { 
      const temp = e.items.filter( f => {
        return f.id == orderItem.id
      })
      if(temp.length > 0) {
        orderItem.cancelled_at = temp[0].cancelled_at;
      }
    });
  }
  
  const getOrdersItems = (productId, farmId) => {
    const items = []
    orders.forEach( e => { 
      if(e.farm_id == parseInt(farmId)) {
        const A = e.items.filter( f => {
          return f.product_id == productId
        })
        A.forEach( f => {
          f.delivery_date = e.delivery_date;
          f.client = e.client;
          f.address = e.address;
          f.origin = addCancelled(f)
          f.unit_price_shipped_new = 'unit_price_shipped_new' in f ? f.unit_price_shipped_new : f.unit_price_shipped
          items.push(f);
        })
      }
    })
    
    return items;
  }

  const getItemQuantityTruck = (dailyId, dailyQuantityId) => {
    let quantity = 0.0
    orders.forEach( e => { 
      const items = e.items.filter( f => {
        return f.id == dailyId
      })
      const item = items.length > 0 ? items[0] : null;

      if (item) {
        const trucks = item.trucks.filter( truck => {
          if (dailyQuantityId == null) return true
          return truck.daily_quantity_id == dailyQuantityId
        })

        trucks.forEach(truck => {
          let qty = truck.quantity ? parseFloat(truck.quantity) : 0
          let new_qty = 'new_quantity' in truck ? parseFloat(truck.new_quantity) : parseFloat(truck.quantity)
          quantity += new_qty
        });
        // quantity = trucks.length > 0 ? parseFloat(trucks[0].quantity) : 0
      }
    })
    return quantity;
  }

  const isValidRow = (orderItem) => {

    let total_quantity_per_truck = {};
    orders.forEach( o => {
      
      o.items.forEach( i => {
        let total_quantity = 0;
        
        i.trucks.forEach( truck => {
          const quantityTruck = getItemQuantityTruck(i.id, truck.daily_quantity_id)
          total_quantity += quantityTruck

          const clave = i.product_id + '_' + truck.truck_name
          if(clave in total_quantity_per_truck)
            total_quantity_per_truck[clave] += quantityTruck
          else
            total_quantity_per_truck[clave] = quantityTruck
            
        })

        if (total_quantity == 0)
          if(i.id == orderItem.id)
            throw new Error('La cantidad de unidades despachadas debe ser mayor a 0');
      })
    });

    // Validar el disponible del camiones
    state.forEach((s) => {
      s.quantities.forEach((q) => {
        const clave = s.product_id + '_' + q.truck_name
        if (clave in total_quantity_per_truck && total_quantity_per_truck[clave] > q.truck_units)
          throw new Error('La cantidad del camión ' + q.truck_name + ' del producto ' + s.product + ' esta sobrepasado');
      })
    })
  }

  const handleUpdateOrderItem = async (orderItem) => {
    props.toggleModalUpdateOrderItem(orderItem)
    
    /*
    props.toggleModalCancelar(orderItem);

    console.log('orderItem', orderItem)
    try {
      //setBtnSaveLoad(true)
      isValidRow(orderItem);
      await saveOrderItem(orderItem)
      //props.onSubmit(data)
      setError(null)
    } catch (e) {
      setError(e.toString())
    }
    //setBtnSaveLoad(false)
    */
  }

  const handleUpdateQuantityItem = async (quantityItem) => {
    props.toggleModal(quantityItem);
  }

  const handleCancelOrderItem = async (orderItem) => {
    console.log('orderItem', orderItem)
    props.toggleModalCancelar(orderItem);
  }

  const handleApproveTruck = async (orderItem) => {
    console.log('approve', orderItem)
    props.toggleModalApproveTruck(orderItem);
  }

  const getQuantityPerTruckClient = (formDaily, orderItem, truck) => {    
    let quantity = 0.0;
    (formDaily.quantities || []).map((truck2, key) => {
      quantity += truck.truck_name == truck2.truck_name ? getItemQuantityTruck(orderItem.id, truck2.id) : 0;
    });
    quantity += getItemQuantityTruck(orderItem.id, truck.id)
    return quantity;
  }
      
  console.log('state',state, 'orders', orders)

  return (
    <React.Fragment>
    { (state.length == 0) &&
      <div className="page-content">
        <Alert color="warning">No hay disponibilidad de proveedores para la zona y fecha seleccionada.</Alert>
      </div>
    }

    { state.length > 0 &&
    <Form
        className="form-horizontal"
    >
        <div className="bg-light mt-3">
            <Row>
              <Col lg={12} className="text-center">
                  {error ? <Alert color="danger" className="mt-3">{error}</Alert> : null}
                  <div className="mt-3">
                    <b>{ state[0].provider.company_name } - Sucursal { state[0].farm.name }</b>
                  </div>
              </Col>
            </Row>
            
            <Row style={{paddingTop:'30px'}}>
              <Col lg={12} className="mb-3">
                {(state || []).map((formDaily, keyDaily) => (
                  <Row key={keyDaily} className="">
                    <Col lg={12}>
                      <div className="bg-dark text-light pt-1 pb-1 mb-1 text-center"><b>{formDaily.product}</b></div>
                    </Col>
                    <Col lg={4}>
                      <div>Cantidad del dia: <b>{formDaily.quantity} Pzas</b></div>
                      <div>Disponible: <b>{formDaily.available_product} Pzas</b></div>
                      <div>Peso Promedio: <b>{formDaily.weight} - {formDaily.weight_max} Kg.</b></div>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col lg={6}>
                          Pigmento:
                        </Col>
                        <Col lg={6}>
                          <RatingCustom
                            max={3}
                            disabled={true}
                            defaultRating={formDaily.quality}
                            styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                            ActiveComponent={
                              <i
                                className="mdi mdi-star text-warning"
                                style={{}}
                              />
                            }
                            InActiveComponent={
                              <i
                                className="mdi mdi-star-outline text-muted"
                                style={{}}
                              />
                            }
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6}>
                          Calidad:
                        </Col>
                        <Col lg={6}>
                          <RatingCustom
                            max={3}
                            disabled={true}
                            defaultRating={formDaily.quality_2}
                            styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                            ActiveComponent={
                              <i
                                className="mdi mdi-star text-warning"
                                style={{}}
                              />
                            }
                            InActiveComponent={
                              <i
                                className="mdi mdi-star-outline text-muted"
                                style={{}}
                              />
                            }
                          />{" "}
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6}>
                          Salud del Ave:
                        </Col>
                        <Col lg={6}>
                          <RatingCustom
                            max={3}
                            disabled={true}
                            defaultRating={formDaily.quality_3}
                            styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                            ActiveComponent={
                              <i
                                className="mdi mdi-star text-warning"
                                style={{}}
                              />
                            }
                            InActiveComponent={
                              <i
                                className="mdi mdi-star-outline text-muted"
                                style={{}}
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
  
                    <Col lg={4}>
                      <div>Observaciones: <b>{formDaily.observations}</b></div><br/>
                    </Col>
                    <Col lg={ 3 } className="mt-2 mb-2 text-lg-center text-center">
                      <Table className="table table-striped table-sm w-100">
                        <thead className="table-light">
                            <tr className="table-primary">
                              <th colSpan={4}>Camiones</th>
                            </tr>
                            <tr className="table-primary">
                              <th>Camión</th>
                              <th>Unidades / Camión</th>
                              <th className="d-none">Pedidos Actuales</th>
                              <th>Disponible</th>
                            </tr>
                        </thead>
                        <tbody>
                          {(formDaily.quantities || []).map((truck, key) => (
                            <React.Fragment key={key}>
                              <tr>
                                <td style={{width: '80px'}}>
                                  {truck.truck_name}
                                </td>
                                <td>
                                  <b>{truck.truck_units} {key}</b>
                                  <input
                                    style={{width: '80px'}}
                                    className="text-center d-none"
                                    name={truck.id}
                                    type="number"
                                    placeholder="0"
                                    min="0"
                                    value={'truck_units_new' in truck ? truck.truck_units_new : truck.truck_units}
                                    onChange={(evt) => setItemQuantity(truck.id, evt) }
                                    onWheel={(e) => e.target.blur() }
                                  />
                                  <button type="button" onClick={(evt) => {handleUpdateQuantityItem(truck)}} className="btn btn-danger btn-sm ms-2"><i className="fas fa-edit"></i></button>
                                </td>
                                <td className="d-none">
                                  {formDaily.orderItems.map((orderItem, key2) => (
                                    orderItem.cancelled_at == null &&
                                    getQuantityPerTruckClient(formDaily, orderItem, truck) > 0 && 
                                    <React.Fragment key={'a'+key2}>
                                      {orderItem.order.client.company_name}: {(formDaily.quantities || []).map((truck2, key) => (
                                        <React.Fragment key={key2 + key}>
                                          { 
                                            truck.truck_name == truck2.truck_name ? 
                                            <b>{ getItemQuantityTruck(orderItem.id, truck2.id) } Pzas</b> : ''
                                          }
                                        </React.Fragment>
                                      ))} <br />
                                    </React.Fragment>
                                  ))}
                                </td>
                                <td><b>{ truck.truck_units - formDaily.truckAsigned[truck.truck_name] }</b></td>
                              </tr> 
                            </React.Fragment>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                    { 
                      (orders && orders.length > 0) ? 
                      <Col lg={9} className="mt-2 mb-2 text-lg-center text-center">
                      <Table className="table table-striped table-sm w-100">
                        <thead className="table-light">
                            <tr className="table-primary">
                              <th colSpan={5}>Pedidos</th>
                            </tr>
                            <tr className="table-primary">
                              <th>Cliente / Destino / Horario</th>
                              <th>Pedido/unidades</th>
                              <th>Precio</th>
                              <th>Despachado por</th>
                              <th></th>
                            </tr>
                        </thead>
                        <tbody>
                          {formDaily.orderItems.map((orderItem, key2) => (
                            <React.Fragment key={'a'+key2}>
                            <tr className={orderItem?.cancelled_at? 'd-none':''}>
                              <td style={{maxWidth: '180px'}}>
                                <b>{orderItem.order.client.company_name}</b><br />
                                {orderItem.delivered ? '':'Recoge en sucursal'}<b>{orderItem.delivered ? orderItem?.delivered_region:''} {orderItem.delivered ? orderItem?.delivered_subregion:''} | {orderItem.delivery_date}</b> <br />
                                {orderItem.observations}
                              </td>
                              <td style={{maxWidth:'100px'}}>  
                                Pedido: <b>{orderItem.quantity_original}</b> <br/>
                                Asignado: <b><span>{ orderItem.quantity_assigned }</span></b><br />
                                <span className="badge rounded-pill bg-danger">{orderItem?.cancelled_at ? 'Cancelado':''}</span>
                              </td>
                              <td style={{maxWidth:'50px'}}>
                                Asignado: <b>{orderItem.unit_price_shipped}</b><br/>
                              </td>
                              <td style={{width:'120px'}}>
                                <Table className="table table-sm">
                                  <tbody>
                                    {(formDaily.quantities || []).map((truck, key) => (
                                      <React.Fragment key={key2 + key}>
                                        { orderItem.truckAsigned[truck.truck_name] > 0 &&
                                        <tr>
                                          <td className="text-center" style={{}}>{truck.truck_name}</td>
                                          <td style={{}}>
                                            { orderItem.truckAsigned[truck.truck_name] }
                                          </td>
                                        </tr>
                                        }
                                      </React.Fragment>
                                    ))}
                                  </tbody>
                                </Table>
                              </td>
                              <td>
                                <button type="button" 
                                  onClick={() => {handleUpdateOrderItem({...orderItem, formDaily, key2}); }} 
                                  className="btn btn-danger btn-sm"
                                  disabled={orderItem.cancelled_at?true:false}
                                ><i className="fas fa-edit"></i></button> <br/>
                              </td>
                            </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                    : <Col lg={6} className="mb-2 text-lg-center text-center pe-4">
                      <span className="">Sin pedidos</span>
                    </Col>
                    }
                  </Row>
                ))}
              </Col>
            </Row>
          { false && orders && orders.length > 0 && orders[0].status == 'requested' &&
          <Row className="">
            <Col lg={12} className="mb-3 text-center">
              { 
                <button onClick={handleValidSubmit} type="button" className="btn btn-danger w-md btn-sm ms-3" disabled={ btnSaveLoad }>
                    <i className="fas fa-list-alt"></i> Autorizar Pedidos
                </button>
              }
            </Col>
          </Row>
          }
        </div>
    </Form>
    }
    </React.Fragment>
  )
}

DistribuitorOrderApproveRow.propTypes = {
  rows: PropTypes.array,
  orders: PropTypes.array,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggleModalCancelar: PropTypes.func.isRequired,
  toggleModalApproveTruck: PropTypes.func.isRequired,
  toggleModalUpdateOrderItem: PropTypes.func.isRequired,
  setFocus: PropTypes.func.isRequired,
}

export default DistribuitorOrderApproveRow
