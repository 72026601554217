import React from 'react';
import PropTypes from 'prop-types';

import {
    Row,
    Col,
    Card,
    CardBody,
    Media
} from 'reactstrap';

import avatar from '../../../assets/images/users/avatar.png';

function UserInfo({ user }) {

    const { name, email, type_string } = user;

    return (
        <Row>
            <Col lg="12">
                <Card>
                    <CardBody>
                        <Media>
                            <div className="ms-3">
                                <img
                                    src={ avatar }
                                    alt=""
                                    className="avatar-md rounded-circle img-thumbnail"
                                />
                            </div>
                            <Media body className="align-self-center">
                                <div className="text-muted">
                                    <h5>{name}</h5>
                                    <p className="mb-1">{email}</p>
                                    <p className="mb-0">Tipo #{type_string}</p>
                                </div>
                            </Media>
                        </Media>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

UserInfo.propTypes = {
    user: PropTypes.object
};

export default UserInfo;