import { GET_ORDER_PROVIDER, GET_ORDER_PROVIDER_SUCCESS, GET_ORDER_PROVIDER_FAILED, ADD_COMMENT_ORDER_PROVIDER } from "./actionTypes";

export const getOrder = id => ({
    type: GET_ORDER_PROVIDER,
    payload: id
});

export const getOrderSuccess = order => ({
    type: GET_ORDER_PROVIDER_SUCCESS,
    payload: order
});

export const getOrderFailed = error => ({
    type: GET_ORDER_PROVIDER_FAILED,
    payload: error
});

export const addCommentOrder = comment => ({
    type: ADD_COMMENT_ORDER_PROVIDER,
    payload: comment
});