import {
  GET_TAX_REGIME,
  GET_TAX_REGIME_SUCCESS,
  GET_TAX_REGIME_FINISHED,
  GET_OBJECT_IMP,
  GET_OBJECT_IMP_SUCCESS,
  GET_OBJECT_IMP_FINISHED,
  GET_USE_CFDI,
  GET_USE_CFDI_SUCCESS,
  GET_USE_CFDI_FINISHED,
  GET_PAYMENT_FORM,
  GET_PAYMENT_FORM_SUCCESS,
  GET_PAYMENT_FORM_FINISHED,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FINISHED,
  GET_INVOICE_TYPE,
  GET_INVOICE_TYPE_SUCCESS,
  GET_INVOICE_TYPE_FINISHED,
} from "./actionTypes"

const initialState = {
  objeto_imp: {
    data: [],
    isLoading: false,
  },
  tax_regime: {
    data: [],
    isLoading: false,
  },
  use_cfdi: {
    data: [],
    isLoading: false,
  },
  invoice_type: {
    data: [],
    isLoading: false,
  },
  payment_form: {
    data: [],
    isLoading: false,
  },
  payment_method: {
    data: [],
    isLoading: false,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OBJECT_IMP:
      return {
        ...state,
        objeto_imp: {
          ...state.objeto_imp,
          isLoading: true,
        },
      }
    case GET_OBJECT_IMP_SUCCESS:
      return {
        ...state,
        objeto_imp: {
          ...state.objeto_imp,
          data: action.payload,
        },
      }
    case GET_OBJECT_IMP_FINISHED:
      return {
        ...state,
        objeto_imp: {
          ...state.objeto_imp,
          isLoading: false,
        },
      }
    case GET_TAX_REGIME:
      return {
        ...state,
        tax_regime: {
          ...state.tax_regime,
          isLoading: true,
        },
      }
    case GET_TAX_REGIME_SUCCESS:
      return {
        ...state,
        tax_regime: {
          ...state.tax_regime,
          data: action.payload,
        },
      }
    case GET_TAX_REGIME_FINISHED:
      return {
        ...state,
        tax_regime: {
          ...state.tax_regime,
          isLoading: false,
        },
      }
    case GET_USE_CFDI:
      return {
        ...state,
        use_cfdi: {
          ...state.use_cfdi,
          isLoading: true,
        },
      }
    case GET_USE_CFDI_SUCCESS:
      return {
        ...state,
        use_cfdi: {
          ...state.use_cfdi,
          data: action.payload,
        },
      }
    case GET_USE_CFDI_FINISHED:
      return {
        ...state,
        use_cfdi: {
          ...state.use_cfdi,
          isLoading: false,
        },
      }
    case GET_INVOICE_TYPE:
      return {
        ...state,
        invoice_type: {
          ...state.invoice_type,
          isLoading: true,
        },
      }
    case GET_INVOICE_TYPE_SUCCESS:
      return {
        ...state,
        invoice_type: {
          ...state.invoice_type,
          data: action.payload,
        },
      }
    case GET_INVOICE_TYPE_FINISHED:
      return {
        ...state,
        invoice_type: {
          ...state.invoice_type,
          isLoading: false,
        },
      }
    case GET_PAYMENT_FORM:
      return {
        ...state,
        payment_form: {
          ...state.payment_form,
          isLoading: true,
        },
      }
    case GET_PAYMENT_FORM_SUCCESS:
      return {
        ...state,
        payment_form: {
          ...state.payment_form,
          data: action.payload,
        },
      }
    case GET_PAYMENT_FORM_FINISHED:
      return {
        ...state,
        payment_form: {
          ...state.payment_form,
          isLoading: false,
        },
      }
    case GET_PAYMENT_METHOD:
      return {
        ...state,
        payment_method: {
          ...state.payment_method,
          isLoading: true,
        },
      }
    case GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        payment_method: {
          ...state.payment_method,
          data: action.payload,
        },
      }
    case GET_PAYMENT_METHOD_FINISHED:
      return {
        ...state,
        payment_method: {
          ...state.payment_method,
          isLoading: false,
        },
      }
    default:
      return state
  }
}

export default reducer
