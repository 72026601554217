import React from "react"
import { Row, Col, Card, CardBody, Button, Alert, Modal } from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ClipLoader from "react-spinners/ClipLoader"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import "../datatables.scss"
import { useState } from "react"
import { useEffect } from "react"
import {
  deleteClient,
  getEnterprisesList,
} from "helpers/backend_helper"
import BreadcrumbNav from "components/Common/BreadcrumbNav"

const breadcrumbItems = [
  { id: "1", title: "Catálogos", link: "", active: false },
  { id: "2", title: "Empresas", link: "", active: true },
]

import usePage from "hooks/usePage"
import ConfigModal from "./config-modal"

const CatalogEnterprisesList = () => {
  const { page, sizePerPage, setPage } = usePage()

  const [error, setError] = useState(null)
  const [modal_standard, setmodal_standard] = useState(false)
  const [row, setRow] = useState(null)
  const [openConfigModal, setOpenConfigModal] = useState(false)

  async function handleDelete(evt, row) {
    setLoading(true)
    setError(null)
    try {
      const response = await deleteClient(row.id)
      setData(data.filter(item => item.id !== row.id))
    } catch (error) {
      setError(error.toString())
    }
    setLoading(false)
    tog_standard()
  }

  function buttonActions(cell, row, rIdx) {
    return (
      <span>
        <Button
          type="button"
          className="btn btn-sm btn-danger ms-1"
          onClick={evt => {
            setOpenConfigModal(true)
            setRowData(row)
          }}
          title="Configuración"
        >
          <i className="fas fas fa-feather"></i>
        </Button>
        <Link
          className="btn btn-sm btn-danger ms-1"
          to={{
            pathname: "/catalogs/enterprises/form-edit",
            state: { ...row, page },
          }}
          title="Edit"
        >
          <i className="fas fa-edit"></i>
        </Link>
        {/* <Button
          type="button"
          className="btn btn-sm btn-danger ms-1"
          onClick={evt => {
            tog_standard()
            setRow(row)
          }}
          title="Eliminar"
        >
          <i className="fas fa-times"></i>
        </Button> */}
      </span>
    )
  }

  const columns = [
    {
      dataField: "company_name",
      text: "Empresa",
      sort: true,
    },
    {
      dataField: "contact_name",
      text: "Contacto",
      sort: true,
    },
    {
      dataField: "isActivatedString",
      text: "Activado",
      sort: true,
    },
    {
      dataField: "id",
      text: "Acciones",
      formatter: buttonActions,
    },
  ]

  let [color, setColor] = useState("#f46a6a")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [totalSize, setTotalSize] = useState(0)
  const [rowData, setRowData] = useState()

  async function getData() {
    setLoading(true)
    console.log('asdsd')
    const response = await getEnterprisesList()
    setLoading(false)
    console.log(response)
    setData(response)
    setTotalSize(response.length)
  }

  useEffect(() => {
    getData()
  }, [])

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage,
    totalSize,
    custom: true,
    page,
    onPageChange: setPage,
    hideSizePerPage: true,
  }

  const { SearchBar } = Search

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  const afterSearch = results => {
    setTotalSize(results.length)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo Empresas</title>
        </MetaTags>
        <div className="container-fluid">
          <BreadcrumbNav title="Empresas" items={breadcrumbItems} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error ? <Alert color="danger">{error}</Alert> : null}

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={data}
                        search={{ afterSearch }}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder="Buscar"
                                      onSearch={data => {
                                        setPage(1)
                                        toolkitProps.searchProps.onSearch(data)
                                      }}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="8" className="text-end">
                                <Link
                                  to="/catalogs/enterprises/form-create"
                                  className="btn btn-danger btn-sm"
                                >
                                  <i className="fas fa-plus"></i> Crear Empresa
                                </Link>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={
                                      loading ? (
                                        <ClipLoader
                                          color={color}
                                          loading={loading}
                                          size={30}
                                        />
                                      ) : (
                                        "Sin datos"
                                      )
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Borrar Usuario
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>¿Está seguro de borrar el usuario?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            onClick={evt => handleDelete(evt, row)}
            type="button"
            className="btn btn-danger"
          >
            <i className="fas fa-check"></i> Borrar
          </button>
        </div>
      </Modal>
      {openConfigModal && (
        <ConfigModal
          rowData={rowData}
          openModal={openConfigModal}
          setOpenModal={setOpenConfigModal}
          getData={getData}
        />
      )}
    </React.Fragment>
  )
}

export default CatalogEnterprisesList
