import React, { useEffect, useLayoutEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import {
  getAdministrativeData,
  setAdministrativeFilters,
} from "store/catalogs/administrative-data/actions"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"

import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Saldos from "./Saldos"

import "./datatables.scss"
import { useRef } from "react"
import ArrowsOrder from "./ArrowsOrder"
import useDownloadCfdi from "hooks/useDownloadCfdi"
import { InvoiceStatus, InvoiceType } from "constants/invoice"

const DataTable = ({
  handleRemision,
  handleEdit,
  handleDelete,
  handlePaymentDetail,
  handlePaymentProvDetail,
  handleHistory = () => { },
  handleInvoice,
  handleInvoiceList,
}) => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const userTypeRol = user?.user?.type_rol || user?.type_rol
  const [cols, setCols] = useState([])

  const dispatch = useDispatch()
  const { downloadInvoice } = useDownloadCfdi()

  const {
    activeTab,
    dataReports,
    pagination: {
      data,
      current_page,
      per_page: size_per_page,
      total: totalSize,
    },
    columnsView,
  } = useSelector(state => state.AdministrativeData)

  const { column_names } = dataReports[activeTab]

  const handleTableChange = (type, { page }) => {
    dispatch(setAdministrativeFilters({ page }))
    dispatch(getAdministrativeData())
  }

  const actionsCol = {
    dataField: "id",
    text: "Acciones",
    formatter: (cell, row) =>
      row?.deleted_at ? (
        <button
          className="btn btn-sm"
          style={{ display: "inline-block", padding: "0px" }}
          title="Registro eliminado"
        >
          <i className="mdi mdi-delete-empty font-size-16 me-1" />
        </button>
      ) : (
        <>
          {row?.invoices?.length > 0 && (
            <button
              className="btn btn-sm"
              style={{ display: "inline-block", padding: "0px" }}
              type="button"
              title="Facturas"
              onClick={() => {
                handleInvoiceList(row)
              }}
            >
              <i className="mdi mdi-ballot-outline font-size-16 text-primary me-1" />{" "}
            </button>
          )}

          {(row.total_vta ?? "0.00") !== "0.00" && (
            <button
              className="btn btn-sm"
              style={{ display: "inline-block", padding: "0px" }}
              type="button"
              title="Nota de remisión"
              onClick={() => {
                handleRemision(row)
              }}
            >
              <i className="mdi mdi-note font-size-16 me-1" />{" "}
            </button>
          )}

          {row.movs_clients_payments?.length > 0 && (
            <button
              className="btn btn-sm"
              style={{ display: "inline-block", padding: "0px" }}
              type="button"
              title="Relación de pagos"
              onClick={() => {
                handlePaymentDetail(row)
              }}
            >
              <i className="mdi mdi-currency-usd font-size-16 me-1" />{" "}
            </button>
          )}

          {row.movs_clients_sales?.length > 0 && (
            <button
              className="btn btn-sm"
              style={{ display: "inline-block", padding: "0px" }}
              type="button"
              title="Relación de pagos"
              onClick={() => {
                handlePaymentDetail(row)
              }}
            >
              <i className="mdi mdi-currency-usd font-size-16 me-1" />{" "}
            </button>
          )}

          {row.movs_providers_payments?.length > 0 && (
            <button
              className="btn btn-sm"
              style={{ display: "inline-block", padding: "0px" }}
              type="button"
              title="Relación de pagos"
              onClick={() => {
                handlePaymentProvDetail(row)
              }}
            >
              <i className="mdi mdi-currency-usd font-size-16 me-1" />{" "}
            </button>
          )}

          {row.movs_providers_buys?.length > 0 && (
            <button
              className="btn btn-sm"
              style={{ display: "inline-block", padding: "0px" }}
              type="button"
              title="Relación de pagos"
              onClick={() => {
                handlePaymentProvDetail(row)
              }}
            >
              <i className="mdi mdi-currency-usd font-size-16 me-1" />{" "}
            </button>
          )}
          <ActionsGroup
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleHistory={handleHistory}
            handleRemision={handleRemision}
            handleInvoice={handleInvoice}
            row={row}
            activeTab={activeTab}
            downloadInvoice={downloadInvoice}
            handleInvoiceList={handleInvoiceList}
          />
        </>
      ),
    classes: "text-center",
  }

  useEffect(() => {
    const tableColumns = columnsView?.length
      ? column_names.filter(({ text }) =>
        columnsView.find(cv => cv.label == text && cv.show)
      )
      : column_names
    const column_names_actions =
      activeTab !== "comprobacion_almacen" &&
        activeTab !== "cargos" &&
        activeTab !== "bancos_cargos"
        ? userTypeRol != 3
          ? [actionsCol, ...tableColumns]
          : [...tableColumns]
        : userTypeRol != 3
          ? [actionsCol, ...tableColumns]
          : [...tableColumns]

    setCols(column_names_actions)
  }, [activeTab, columnsView])

  const refTable = useRef()

  useLayoutEffect(() => {
    //console.log(refTable.current.getBoundingClientRect())
  }, [data])

  return (
    <div id="clients-providers-table-box">
      <Saldos />
      {cols.length > 0 && (
        <RemotePagination
          refTable={refTable}
          columnNames={cols}
          data={data}
          page={current_page}
          sizePerPage={size_per_page}
          totalSize={totalSize}
          onTableChange={handleTableChange}
          rowEvents={{
            onDoubleClick: (a, row) => {
              handleEdit(row)
            },
          }}
        />
      )}
    </div>
  )
}

DataTable.propTypes = {
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleHistory: PropTypes.func,
  handlePaymentDetail: PropTypes.func,
  handlePaymentProvDetail: PropTypes.func,
  handleRemision: PropTypes.func,
  handleInvoice: PropTypes.func,
  handleInvoiceList: PropTypes.func,
  currentReport: PropTypes.string,
}

export default DataTable

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const RemotePagination = ({
  columnNames,
  data,
  page,
  sizePerPage,
  onTableChange,
  rowEvents,
  totalSize,
  refTable,
}) => {
  const dispatch = useDispatch()
  const [tableHeight, setTableHeight] = useState(150)

  const { activeTab, filters } = useSelector(state => state.AdministrativeData)

  const filterColumns = () => {
    if (activeTab !== "comprobacion_almacen") return columnNames

    return columnNames.map(col => ({
      ...col,
      headerFormatter: column => {
        if (!column.isSortable) return column.text
        else
          return (
            <>
              {column.text}{" "}
              <ArrowsOrder
                column={column.dataField}
                columnOrder={{ column: "provider", order: "asc" }}
              />
            </>
          )
      },
    }))
  }

  const rowClass = row => {
    if (row?.deleted_at) return "table-secondary"
    if (row?.mov_changes_count === 1) return "table-warning"
    if (row?.mov_changes_count > 1) return "table-danger"

    return null
  }

  useEffect(() => {
    if (activeTab !== "comprobacion_almacen") return

    dispatch(getAdministrativeData())
  }, [filters.column, filters.order])

  const updateTableHeight = () => {
    if (!data?.length) return

    const screenHeight = window.innerHeight
    const screenWidth = document.body.clientWidth
    const saldosDiv = document.getElementById("saldos-box")
    const bodyElement = document.querySelector("body")

    const saldosDivBottom = saldosDiv.getBoundingClientRect().bottom
    const paddingBottom = 80 //aprox

    const tableHeight = screenHeight - saldosDivBottom - paddingBottom

    if (screenWidth >= 992) {
      bodyElement.style.overflow = "hidden"
      setTableHeight(tableHeight)
    } else {
      bodyElement.style.overflow = "auto"
      setTableHeight(null)
    }
  }

  useEffect(() => {
    updateTableHeight()
  }, [data])

  useEffect(() => {
    function handleResize() {
      console.log("Resize---->")
      updateTableHeight()
    }

    window.addEventListener("resize", handleResize)
    return () => {
      console.log("UNMOUNT CLIENT PROVIDER")
      const bodyElement = document.querySelector("body")
      window.removeEventListener("resize", handleResize)
      bodyElement.style.overflow = "auto"
    }
  }, [])

  return (
    <Row>
      <Col style={{ position: "relative", paddingBottom: "60px" }}>
        <div
          className="table-responsive"
          ref={refTable}
          style={{
            minHeight: "150px",
            ...(tableHeight ? { maxHeight: tableHeight + "px" } : {}),
          }}
        >
          {" "}
          {/* position: 'relative',  */}
          <BootstrapTable
            classes="table-sm fs-7 administrative-table mb-4"
            headerWrapperClasses="table-light"
            remote
            keyField="id"
            data={data}
            columns={filterColumns()}
            pagination={paginationFactory({
              page,
              sizePerPage,
              totalSize,
              hideSizePerPage: true,
            })}
            onTableChange={onTableChange}
            rowEvents={rowEvents}
            noDataIndication={() => "Sin registros para mostrar"}
            rowClasses={rowClass}
          />
        </div>
      </Col>
    </Row>
  )
}

RemotePagination.propTypes = {
  columnNames: PropTypes.any,
  data: PropTypes.any,
  page: PropTypes.any,
  sizePerPage: PropTypes.any,
  onTableChange: PropTypes.any,
  totalSize: PropTypes.any,
  rowEvents: PropTypes.any,
  refTable: PropTypes.any,
}

const ActionsGroup = ({
  handleRemision,
  handleEdit,
  handleDelete,
  handleHistory,
  handleInvoice,
  row,
  activeTab,
  downloadInvoice,
  handleInvoiceList,
}) => {
  const showHistorico = row.mov_changes_count ? true : false

  if (row?.type === "comprobacion_almacen") {
    return (
      <UncontrolledDropdown>
        <DropdownToggle href="#" className="card-drop action-points" tag="i">
          <i className="mdi mdi-dots-horizontal font-size-14" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-enddd actions-menu">
          <DropdownItem
            href="#"
            onClick={e => {
              e.preventDefault()
              handleEdit(row)
            }}
          >
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
            Editar
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  const getInvoiceDropdownItem = () => {
    if (activeTab !== "clientes") return null
    if (row.cobro != "0.00") return null
    //if(row.invoice) return null;

    const hasActiveInvoice = row?.invoices?.find(
      invoice =>
        invoice?.invoice_type === InvoiceType.Invoice &&
        invoice.status === InvoiceStatus.Active
    )

    const hasPPDActiveInvoice = row?.invoices?.find(
      invoice =>
        invoice?.invoice_type === InvoiceType.Invoice &&
        invoice.status === InvoiceStatus.Active &&
        invoice.payment_method?.includes("PPD")
    )

    return (
      <>
        {!hasActiveInvoice ? (
          <DropdownItem
            href="#"
            onClick={e => {
              e.preventDefault()
              handleInvoice({
                ...row,
                InvoiceType: InvoiceType.Invoice,
              })
            }}
          >
            <i className="mdi mdi-ballot-outline font-size-16 text-primary me-1" />{" "}
            Facturar venta
          </DropdownItem>
        ) : null}
        {hasPPDActiveInvoice ? (
          <DropdownItem
            href="#"
            onClick={e => {
              e.preventDefault()
              handleInvoice({
                ...row,
                CfdiType: "P",
                InvoiceType: InvoiceType.Invoice,
              })
            }}
          >
            <i className="mdi mdi-ballot-outline font-size-16 text-primary me-1" />{" "}
            Agregar complemento
          </DropdownItem>
        ) : null}
        {hasActiveInvoice ? (
          <>
            <DropdownItem
              href="#"
              onClick={e => {
                e.preventDefault()
                handleInvoice({
                  ...row,
                  is_credit_note: true,
                  InvoiceType: InvoiceType.CreditNote,
                  RelatedUuid: hasActiveInvoice.uuid,
                })
              }}
            >
              <i className="mdi mdi-ballot-outline font-size-16 text-primary me-1" />{" "}
              Nota de crédito
            </DropdownItem>
            <DropdownItem
              href="#"
              onClick={e => {
                e.preventDefault()
                handleInvoice({
                  ...row,
                  is_debit_note: true,
                  InvoiceType: InvoiceType.DebitNote,
                  RelatedUuid: hasActiveInvoice.uuid,
                })
              }}
            >
              <i className="mdi mdi-ballot-outline font-size-16 text-primary me-1" />{" "}
              Nota de débito
            </DropdownItem>
          </>
        ) : null}
      </>
    )
  }

  const getDownloadInvoiceDropdownItems = () => {
    if (activeTab !== "clientes") return null
    if (!row.invoices.length) return null

    return (
      <>
        {row.invoices.lenght === 1 ? (
          <>
            <DropdownItem
              href="#"
              onClick={e => {
                e.preventDefault()
                downloadInvoice("xml", row.invoices[0].sat_provider_id)
              }}
            >
              <i className="mdi mdi-xml font-size-16 text-primary me-1" />{" "}
              Descargar XML
            </DropdownItem>
            <DropdownItem
              href="#"
              onClick={e => {
                e.preventDefault()
                downloadInvoice("pdf", row.invoices[0].sat_provider_id)
              }}
            >
              <i className="mdi mdi-file-pdf-box-outline font-size-16 text-primary me-1" />{" "}
              Descargar PDF
            </DropdownItem>
          </>
        ) : null}
        <DropdownItem
          href="#"
          onClick={e => {
            e.preventDefault()
            handleInvoiceList(row)
          }}
        >
          <i className="mdi mdi-file-document-multiple-outline font-size-16 text-primary me-1" />{" "}
          Documentos fiscales
        </DropdownItem>
      </>
    )
  }

  return (
    <UncontrolledDropdown>
      {row.client_account?.name != "Ajuste de almacén" && (
        <>
          <DropdownToggle
            href="#"
            className="card-drop action-points"
            tag="i"
            title="Más acciones"
          >
            <i className="mdi mdi-dots-horizontal font-size-14" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-enddd actions-menu"
            style={{ minWidth: "13rem" }}
          >
            {getInvoiceDropdownItem()}
            {getDownloadInvoiceDropdownItems()}
            {showHistorico && (
              <DropdownItem
                href="#"
                onClick={e => {
                  e.preventDefault()
                  handleHistory(row)
                }}
              >
                <i className="mdi mdi-history font-size-16 text-warning me-1" />{" "}
                Histórico
              </DropdownItem>
            )}

            {row.total_vta !== "0.00" && (
              <DropdownItem
                href="#"
                onClick={e => {
                  e.preventDefault()
                  handleRemision(row)
                }}
              >
                <i className="mdi mdi-note font-size-16 text-info me-1" /> Nota
                Remisión
              </DropdownItem>
            )}

            <DropdownItem
              href="#"
              onClick={e => {
                e.preventDefault()
                handleEdit(row)
              }}
            >
              <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
              Editar
            </DropdownItem>

            <DropdownItem
              href="#"
              onClick={e => {
                e.preventDefault()
                handleDelete(row)
              }}
            >
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
              Eliminar
            </DropdownItem>
          </DropdownMenu>
        </>
      )}
    </UncontrolledDropdown>
  )
}

ActionsGroup.propTypes = {
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleHistory: PropTypes.func,
  handleRemision: PropTypes.func,
  handleInvoice: PropTypes.func,
  downloadInvoice: PropTypes.func,
  handleInvoiceList: PropTypes.func,
  row: PropTypes.object,
  activeTab: PropTypes.string,
}
