import React, { useRef } from "react"
import { Button, Col, Row } from "reactstrap"

import useBilling from "./useBilling"
import Filters from "./components/Filters"
import PageBase from "components/Common/PageBase"
import CustomTable from "common/nibble/CustomTable"
import { getColumns } from "./Columns"
import useShowPdfCfdi from "hooks/useShowPdfCfdi"
import PreviewPDF from "common/nibble/PreviewPdf"
import FullSpinner from "components/Common/FullSpinner"
import useDownloadCfdi from "hooks/useDownloadCfdi"
import useDownloadCancellationXml from "hooks/useDownloadCancellationXml"
import CancelCfdiModal from "pages/Administrative/ClientProvider/RightForms/InvoiceList/CancelCfdiModal"
import InvoiceSidebar from "./components/InvoiceSidebar"
import GlobalInvoiceSidebar from "./components/GlobalInvoiceSidebar"
import MovsClientSearchModal from "./components/MovsClientSearchModal"
import DownloadButtons from "./components/DownloadButtons"
import CurrencyFormat from "common/nibble/CurrencyFormat"

const breadcrumbList = [
  { id: "2", title: "Facturación", link: "", active: true },
]

const Billing = () => {
  const showPdfCfdi = useShowPdfCfdi()
  const downloadCfdi = useDownloadCfdi()
  const downloadCancellationXml = useDownloadCancellationXml()

  const { validation, state, methods } = useBilling()

  const columns = getColumns({
    showPdfCfdi,
    downloadCfdi,
    downloadCancellationXml,
    onCancelCfdi: methods.onCancelCfdi,
    onAddComplement: methods.onAddComplement,
  })

  return (
    <>
      {showPdfCfdi.isLoading && <FullSpinner message="Cargando factura..." />}
      {downloadCfdi.isDownloadingPdf && (
        <FullSpinner message="Descargando PDF..." />
      )}
      {downloadCfdi.isDownloadingXml && (
        <FullSpinner message="Descargando XML..." />
      )}
      {downloadCancellationXml.isDownloading && (
        <FullSpinner message="Descargando XML de cancelación..." />
      )}
      <PageBase
        titleMeta="Facturación"
        titleBread="Facturación"
        itemsBread={breadcrumbList}
      >
        <CustomTable
          loading={state.isLoading}
          validation={validation}
          columns={columns}
          data={state.data}
          filters={
            <>
              <Filters validation={validation} />
              <Row>
                <Col className="text-start">
                  <div className="fw-bold fs-5 bg-light py-1 px-3">
                    Importe Total: $ 
                    <CurrencyFormat value={state.totalInvoices} />
                  </div>
                </Col>
                <Col className="pb-2 text-end">
                  <Button
                    onClick={methods.toggleSalesSearchModal}
                    size="sm"
                    color="light"
                    className="me-2"
                  >
                    Factura global
                  </Button>
                  <Button
                    onClick={methods.onAddInvoice}
                    size="sm"
                    color="primary"
                  >
                    Nueva factura
                  </Button>
                  <DownloadButtons
                    isDownloading={state.isDownloading}
                    downloadInvoices={methods.downloadInvoices}
                  />
                </Col>
              </Row>
            </>
          }
        />
      </PageBase>
      {state.isOpenSidebar && (
        <InvoiceSidebar
          isOpenSidebar={state.isOpenSidebar}
          reloadData={methods.fetchInvoices}
          toggleSidebar={methods.toggleSidebar}
          inititalInvoiceValues={state.inititalInvoiceValues}
        />
      )}
      {state.isOpenGlobalInvoiceSidebar && (
        <GlobalInvoiceSidebar
          isOpenSidebar={state.isOpenGlobalInvoiceSidebar}
          toggleSidebar={methods.toggleGlobalInvoiceSidebar}
          reloadData={methods.fetchInvoices}
          movsClientSelected={state.movsClientSelected}
        />
      )}
      {state.isOpenModal && (
        <CancelCfdiModal
          isOpen={state.isOpenModal}
          invoice={state.invoice}
          toggleModal={methods.toggleModal}
          fetchCfdis={methods.fetchInvoices}
        />
      )}
      {showPdfCfdi.modalPdf.show && (
        <PreviewPDF
          modalPdf={showPdfCfdi.modalPdf}
          setModalPdf={showPdfCfdi.setModalPdf}
        />
      )}
      {state.isOpenSalesSearchModal && (
        <MovsClientSearchModal
          isOpenModal={state.isOpenSalesSearchModal}
          toggleModal={methods.toggleSalesSearchModal}
          onSelectMovsClient={methods.onSelectMovsClient}
        />
      )}
    </>
  )
}

export default Billing
