import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';
import Select from 'react-select'

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from 'react-router-dom';
import { AvForm } from "availity-reactstrap-validation";
import { useState } from "react";
import { useRef } from "react";
import { PropTypes } from 'prop-types';
import { Alert } from "reactstrap";
import { postClient, validateZipCode } from "helpers/backend_helper";
import BreadcrumbNav from "components/Common/BreadcrumbNav";

import toastr from "toastr"
import AdrressForm from 'pages/Catalogos/common/AddressForm';
import ClientForm from "../common/ClientForm";

const breadcrumbItems = [
  { id:'1', title: 'Catálogos', link: '', active: false },
  { id:'2', title: 'Clientes', link: '/catalogs/clients', active: false },
  { id:'3', title: 'Crear', link: '', active: true }
];

const cpRegExp = /^[0-9]{5}/;

const CatalogClientFormCreate = (props) => {
  const [model, setModel] = useState ({
    start: '10:00',
    end: '11:00'
  })
  const [hours, setHours] = useState({
    start: '10:00',
    end: '11:00'
  })
  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')
  const [selectedRegion, setSelectedRegion] = useState(null)

  const handleValidSubmit = async (event, values) => {
    console.log('values', values)
    try {
      const resp = await validateZipCode({ cp: values.cp })
      if(!resp.cp) {
        toastr.error('Código postal no válido');
        return;
      };
    } catch (error) {
      toastr.error(error);
    }

    setBtnSaveLoad(true)    
    try {
      const response = await postClient({...values, ...model, region_id: selectedRegion, start: hours.start, end: hours.end})
      setModel(response)
      setError(null)
      setCompleted('Se completo la operación')
      console.log(refForm);
      refForm && refForm.current.reset();
    } catch(error) {
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const handleChange = (values) => {
    setModel({...model, start: values.start, end: values.end})
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de Clientes</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Clientes"  items={breadcrumbItems}/>
          
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? <Alert color="success">{completed}</Alert> : null}
                      
                    <ClientForm 
                      client={{}} 
                      handleChangeTimes={(values) => setHours({...hours, ...values})}
                      times={hours}
                    ></ClientForm>

                    <div className="text-center mb-5">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> Guardar
                      </button>
                      <Link to="/catalogs/clients" className="btn w-md btn-sm btn-danger ms-2">
                        <i className="fas fa-reply"></i> Atrás
                      </Link>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

CatalogClientFormCreate.propTypes = {
  provider: PropTypes.object
};

export default CatalogClientFormCreate
