//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_LOGIN_TOKEN = "/tokens/create"

//USERS
export const GET_USERS = "/users"
export const GET_USER_DETAIL = "/users"
export const ADD_USER = "/users"
export const UPDATE_USER = "/users"
export const DELETE_USER = "/users"
export const UPDATE_USER_PASSWORD = "/users/password";
export const RESET_USER_PASSWORD = "/users/reset-password";

export const GET_USERS_INTERNALS = "/users-internals"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCT_DETAIL = "/products"
export const ADD_PRODUCT = "/products"
export const UPDATE_PRODUCT = "/products"
export const DELETE_PRODUCT = "/products"

// PROVIDERS CLIENTS
export const GET_ENTERPRISES = "/enterprises"

//PROVIDERS
export const GET_PROVIDERS = "/providers"
export const GET_PROVIDER_DETAIL = "/providers"
export const ADD_PROVIDER = "/providers"
export const UPDATE_PROVIDER = "/providers"
export const DELETE_PROVIDER = "/providers"
export const LANDING_ADD_PROVIDER = "landing/provider"
export const PROVIDERS_DASHBOARD = "providers/dashboard";

//CLIENTS
export const GET_CLIENTS = "/clients"
export const GET_CLIENT_DETAIL = "/clients"
export const ADD_CLIENT = "/clients"
export const UPDATE_CLIENT = "/clients"
export const DELETE_CLIENT = "/clients"
export const LANDING_ADD_CLIENT = "landing/client"
export const CLIENT_DASHBOARD = "clients/dashboard";

//ENTERPRISES
export const ENTERPRISES_RESOURCE = "/enterprises"

//SAT
export const SAT_RESOURCE = "/sat"

export const INVOICES_RESOURCE = "/invoices"


//CONFIG
export const CONFIGS_RESOURCE = "/configs"

//FILES
export const FILES_RESOURCE = "/files"

//UNITS
export const GET_UNITS = "/units"
export const GET_UNIT_DETAIL = "/units"
export const ADD_UNIT = "/units"
export const UPDATE_UNIT = "/units"
export const DELETE_UNIT = "/units"

//REGION
export const CATALOG_REGION = "/regions"
export const CATALOG_SUBREGION = "provider/subregions"


//CLIENTS DELIVERY
export const GET_DELIVERY = "/clients/deliveries"
export const GET_DELIVERY_DETAIL = "/clients/deliveries"
export const ADD_DELIVERY = "/clients/deliveries"
export const UPDATE_DELIVERY = "/clients/deliveries"
export const DELETE_DELIVERY = "/clients/deliveries"

// PROVIDER DAILY
export const GET_PROVIDERS_AVAILABILITY = "/provider/availability";
export const ADD_PROVIDER_DAILY = "/provider/daily"
export const GET_PROVIDER_DAILY_DETAIL = "/provider/daily"
export const GET_PROVIDER_DAILY_PREVIOUS = "/provider/daily_previous"

export const GET_DISTRIBUITOR_CONFIG = "/distribuitor/configs"
export const SET_DISTRIBUITOR_CONFIG = "/distribuitor/configs"

export const UPDATE_PROVIDER_BRANCHES = "/provider/branches"

export const GET_DISTRIBUITOR_PROVIDER_DAILY_AVAILABLE = "/distribuitor/provider/daily-available"

// CLIENT
export const GET_CLIENT_DAILY_AVAILABLE = "/client/daily-available"
export const SET_CLIENT_DAILY_ORDER = "/client/daily-order"


// ORDERS
export const GET_CLIENT_ORDERS = "/client/orders"
export const GET_CLIENT_ORDER = "/client/order"
export const GET_PROVIDER_ORDERS = "/provider/orders"
export const GET_PROVIDER_ORDER = "/provider/order"
export const GET_DISTRIBUITOR_ORDERS = "/distribuitor/orders"
export const GET_DISTRIBUITOR_PROVIDER_ORDERS = "/distribuitor/orders-provider"


// DISTRIBUITORS
export const GET_DISTRIBUITOR_DAILY = "/distribuitor/daily-order"
export const SET_DISTRIBUITOR_DAILY_ORDER = "/distribuitor/daily-order"
export const SET_DISTRIBUITOR_DAILY_ORDER_ITEM = "/distribuitor/daily-order-item"
export const SET_DISTRIBUITOR_DAILY_TRUCK_AUTH = "/distribuitor/daily-order-truck/auth"
export const SET_DISTRIBUITOR_DAILY_ORDER_ITEM_PRICE = "/distribuitor/daily-order-price"
export const DELETE_DISTRIBUITOR_DAILY_ORDER_ITEM = "/distribuitor/daily-order-item"
export const DISTRIBUIDOR_DASHBOARD = "/distribuitor/dashboard"
export const DISTRIBUIDOR_PRICE_TABLE = "/distribuitor/price-table"
export const DISTRIBUIDOR_PRICE_HISTORY = "/distribuitor/price-history"
export const SET_DISTRIBUITOR_DAILY_NOTIFY_PROVIDERS = "/distribuitor/daily-order/notify-providers"
export const SET_DISTRIBUITOR_DAILY_NOTIFY_CLIENTS = "/distribuitor/daily-order/notify-clients"


export const GET_DISTRIBUITOR_CLIENT_DAILY_AVAILABLE = "/distribuitor/client/daily-available"
export const SET_DISTRIBUITOR_CLIENT_DAILY_ORDER = "/distribuitor/client/daily-order"

// COMMENTS 
export const ADD_ORDER_COMMENT = "/comments";

//ORDER RATING
export const ADD_ORDER_RATING = "/rating";

//ACCOUNTING ACCOUNTS
export const GET_ACCOUNTING_ACCOUNTS = "administrative/accounting/jlist";
export const GET_ACCOUNTING_ACCOUNTS_ALL = "administrative/accounting";
export const GET_ACCOUNTING_ACCOUNTS_EXPORT = "administrative/account";

export const GET_MOV_CLIENTS_FOLIO = "administrative/clients/mov/folio";
export const POST_MOV_CLIENT   = "administrative/clients/mov";
export const UPDATE_MOV_CLIENT = "administrative/clients/mov";
export const DELETE_MOV_CLIENT = "administrative/clients/mov";
export const GET_MOV_CLIENTS   = "administrative/clients/jlist";
export const UPLOAD_FILE_MOV_CLIENTS = "administrative/clients/file";
export const GET_MOV_BY_CLIENT = "administrative/clients/movements";
export const GET_MOV_CLIENTS_HISTORY ="administrative/clients/mov/{id}/history";
export const GET_MOV_PROVIDERS_BY_DATE = "administrative/clients/providers";
export const GET_MOV_CLIENTS_TO_PAY   = "administrative/clients/movements-to-pay";

export const GET_MOV_PROVIDERS_TO_PAY   = "administrative/providers/movements-to-pay";

export const GET_MOV_PROVIDERS_FOLIO = "administrative/providers/mov/folio";
export const POST_MOV_PROVIDER   = "administrative/providers/mov";
export const UPDATE_MOV_PROVIDER = "administrative/providers/mov";
export const DELETE_MOV_PROVIDER = "administrative/providers/mov";
export const GET_MOV_PROVIDERS   = "administrative/providers/jlist";
export const UPLOAD_FILE_MOV_PROVIDERS = "administrative/providers/file";
export const GET_MOV_PROVIDERS_HISTORY ="administrative/providers/mov/{id}/history";
export const GET_MOV_BY_PROVIDER = "administrative/providers/movements";

export const POST_MOV_BANK = "administrative/banks/mov";
export const UPDATE_MOV_BANK = "administrative/banks/mov";
export const DELETE_MOV_BANK = "administrative/banks/mov";
export const GET_MOV_BANKS   = "administrative/banks/jlist";
export const UPLOAD_FILE_MOV_BANKS = "administrative/banks/file";
export const GET_MOV_BANKS_HISTORY ="administrative/banks/mov/{id}/history";
export const GET_BANKS_CHARGES = "administrative/banks/check";
export const GET_MOV_BANKS_FOLIO = "administrative/banks/mov/folio";
export const GET_MOV_BANKS_FOLIOS = "administrative/banks/mov/folios";

export const GET_WAREHOUSE = "administrative/warehouse/check";
//export const OBSERVATION_WAREHOUSE = "administrative/warehouse/observation";

export const GET_CHARGES = "administrative/charges/check";
export const OBSERVATION_CHARGE = "administrative/charges/observation";
//COSTS
export const GET_COSTS_MOV    = "administrative/costs/jlist";
export const GET_COSTS_DATA   = "administrative/cost";
export const GET_COST_FOLIO = "administrative/costs/mov/folio";
export const GET_ACCOUNT_COSTS   = "administrative/costs/accounts";
export const POST_COSTS   = "administrative/cost";
export const PUT_COSTS   = "administrative/cost";
export const DELETE_COST = "administrative/cost";
export const GET_COST_HISTORY ="administrative/costs/mov/{id}/history";
export const GET_MOVS_COSTS_TO_PAY ="administrative/costs/movements-to-pay";
export const GET_MOV_BY_COST = "administrative/costs/movements";

//REPORTS
export const GET_BILLING_REPORT = "administrative/reports/billing";
export const GET_BILLINGDETAIL_REPORT = "administrative/reports/billing-detail";
export const GET_DATA_CONFIG = "administrative/reports/configs";
export const PUT_REPORT_CONFIG_OPTIONS = "administrative/reports/configs/options";

export const GET_SALES_FOLIO_REPORT = "administrative/reports/sales-folio";
export const GET_ACCOUNTING_CONTRATES_REPORT = "administrative/reports/accounting-concentrates";
export const GET_SALES_CONTRATE_REPORT = "administrative/reports/sales-concentrate";
export const GET_SHOPPING_CONTRATE_REPORT = "administrative/reports/shopping-concentrate";
export const GET_CLIENTS_BALANCE_REPORT = "administrative/reports/clients-balance";
export const GET_CLIENTS_OPERATION_REPORT = "administrative/reports/clients-operations";
export const GET_PROVIDERS_OPERATION_REPORT = "administrative/reports/provider-operations";
export const GET_ACCOUNTS_RECEIVABLE_REPORT = "administrative/reports/accounts-receivable";
export const GET_INVENTORY_REPORT = "administrative/reports/inventory";
export const GET_ACCOUNT_STATEMENTS_REPORT = "administrative/reports/accountsStatements";
export const GET_ACCOUNT_PROV_STATEMENTS_REPORT = "administrative/reports/accountsProvStatements";
export const GET_ACCOUNT_BANK_STATEMENTS_REPORT = "administrative/reports/accountsBankStatements";
export const GET_ACCOUNT_PROVIDER_PAY = "administrative/reports/accountsProviderPay";
export const GET_SALE_TICKET = "administrative/reports/sale-ticket";
export const GET_CLIENT_INVOICE = "administrative/clients/invoice";
export const CLIENT_CFDI = "administrative/clients/cfdi";
export const CLIENT_DOWNLOAD_CFDI = "administrative/clients/download-cfdi";
export const CLIENT_NEXT_INVOICE_FOLIO = "administrative/clients/next-invoice-folio";

//AJUSTAMENTS INVENTORY
export const AJUSTAMENT_RESOURCE = "administrative/ajustament";

export const GET_COSTS_CONTRATE_REPORT = "administrative/reports/costs-concentrate";
export const GET_COSTS_ACCOUNT_STATEMENTS_REPORT = "administrative/reports/costs-account-statements";
export const GET_BANKS_CASH_FLOW_REPORT = "administrative/reports/banks-cash-flow";

//PRICES PROVIDERS
export const GET_PRICES_PROVIDERS = "prices-providers";
export const POST_PRICES_PROVIDERS = "prices-providers";
export const UPDATE_PRICES_PROVIDERS = "prices-providers";
export const DELETE_PRICES_PROVIDERS = "prices-providers";

//HOLIDAYS
export const GET_HOLIDAYS = "holidays";
export const POST_HOLIDAYS = "holidays";
export const PUT_HOLIDAYS = "holidays";
export const DELETE_HOLIDAYS = "holidays";

export const ROLES_SOURCE = "roles";

export const VALIDATE_ZIP_CODE = "zip-code/validate";

export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS

//export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
//export const UPDATE_USER = "/update/user"
//export const DELETE_USER = "/delete/user"

export const GET_ME = "/me";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

export const GET_PRODUCTS_DETAIL = ''

export const GET_BANXICO = "banxico";

export const GET_BANCOS_OPTIONS = "/administrative/clients/bankOptions";