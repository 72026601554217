import React from "react"
import PropTypes from 'prop-types'
import { Col, Row, Alert, Form, Table } from "reactstrap"
import { useState } from "react"
import { postDistribuitorOrder } from "helpers/backend_helper"
import RatingCustom from "components/Common/RatingCustom"
import moment from "moment"

const Authorization = props => {
  const [popover, setpopover] = useState({});
  //const [state, setState] = useState([...props.rows])
  const state = props.rows;
  const orders = props.orders;
  //const ordersParent = props.orders;
  //const [orders, setOrders] = useState(props.orders)
  const [values, setValues] = useState({
    provider_id: null,
    products: {},
    timeDelivery: '',
  })
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)


  const isValidPedido = (itemId, clientId) => {
    let trucks = {};
        
    orders.forEach( o => {
      o.items.forEach( i => {        
        if ('quantity_new' in i) {} else { 
          i.quantity_new = i.quantity
        }
      })
    });
    
    let total_quantity_per_truck = {};
    orders.forEach( o => {
      o.items.forEach( i => {
        if(i.id != itemId) return;

        let total_quantity = 0;
        if (i.quantity_new > 0) {
          i.trucks.forEach( truck => {
            const quantityTruck = getItemQuantityTruck(i.id, truck.daily_quantity_id)
            total_quantity += quantityTruck

            const clave = i.product_id + '_' + truck.truck_name
            if(clave in total_quantity_per_truck)
              total_quantity_per_truck[clave] += quantityTruck
            else
              total_quantity_per_truck[clave] = quantityTruck
              
          })

          const trucks = i.trucks.filter( truck => {
            return truck.new_quantity > 0
          })
          
          if (trucks.length == 0)
            throw new Error('Debe colocar las cantidades a despachar de cada camión del cliente ' + o.client.company_name);

          console.log('-', total_quantity, i.quantity_new, total_quantity !== parseFloat(i.quantity_new))
          if (total_quantity !== parseFloat(i.quantity_new)) {
            throw new Error('El total de cantidades despachadas debe coincidir con la cantidad ordenada del cliente ' + o.client.company_name);
          }          
        }
      })
    });
    console.log(total_quantity_per_truck)

    state.forEach((s) => {
      s.quantities.forEach((q) => {
        const clave = s.product_id + '_' + q.truck_name
        if (clave in total_quantity_per_truck && total_quantity_per_truck[clave] > q.truck_units)
          throw new Error('La cantidad de camion ' + q.truck_name + ' del producto ' + s.product + ' esta sobrepasado');
      })
    })
  
    for (const key in trucks) {
      const item = trucks[key];
      if( item.ordered > item.available) {
        throw new Error('Verifica los pedidos del producto ' + item.product + ' sobrepasan el disponible');
      }
    }

    return true;
  }

  const save = async (data) => {
    try {
      const response = await postDistribuitorOrder({ orders: data })
      setCompleted('Se completo la operación')
      setOrders(response)
      setError(null)
    } catch(error) {
      console.log(error.toString())
      setError(error.toString())
      setCompleted(null)
    }
  }

  const updateOrders = (order) => {
    let newOrders = [...orders]
    newOrders = newOrders.map( e => {
      if (e.id == order.id)
        return order
      return e
    })
    setOrders(newOrders)
  }

  const handleValidSubmit = async (e, formValues) => {
    try {
      setBtnSaveLoad(true)
      isValidPedido();
      await save(orders)
      //props.onSubmit(data)
      setError(null)
    } catch (e) {
      setError(e.toString())
    }
    setBtnSaveLoad(false)
  }

  const getItemQuantityTruck = (dailyId, dailyQuantityId) => {
    let quantity = 0.0
    orders.forEach( e => { 
      const items = e.items.filter( f => {
        return f.id == dailyId
      })
      const item = items.length > 0 ? items[0] : null;

      if (item) {
        const trucks = item.trucks.filter( truck => {
          if (dailyQuantityId == null) return true
          return truck.daily_quantity_id == dailyQuantityId
        })

        trucks.forEach(truck => {
          let qty = truck.quantity ? parseFloat(truck.quantity) : 0
          let new_qty = 'new_quantity' in truck ? parseFloat(truck.new_quantity) : parseFloat(truck.quantity)
          quantity += new_qty
        });
        // quantity = trucks.length > 0 ? parseFloat(trucks[0].quantity) : 0
      }
    })
    return quantity;
  }

  const isValidRow = (orderItem) => {

    let total_quantity_per_truck = {};
    orders.forEach( o => {
      
      o.items.forEach( i => {
        let total_quantity = 0;
        
        i.trucks.forEach( truck => {
          const quantityTruck = getItemQuantityTruck(i.id, truck.daily_quantity_id)
          total_quantity += quantityTruck

          const clave = i.product_id + '_' + truck.truck_name
          if(clave in total_quantity_per_truck)
            total_quantity_per_truck[clave] += quantityTruck
          else
            total_quantity_per_truck[clave] = quantityTruck
            
        })

        if (total_quantity == 0)
          if(i.id == orderItem.id)
            throw new Error('La cantidad de unidades despachadas debe ser mayor a 0');
      })
    });

    // Validar el disponible del camiones
    state.forEach((s) => {
      s.quantities.forEach((q) => {
        const clave = s.product_id + '_' + q.truck_name
        if (clave in total_quantity_per_truck && total_quantity_per_truck[clave] > q.truck_units)
          throw new Error('La cantidad del camión ' + q.truck_name + ' del producto ' + s.product + ' esta sobrepasado');
      })
    })
  }
      
  console.log('state',state, 'orders', orders)

  return (
    <React.Fragment>
    { (state.length == 0) &&
      <div className="page-content">
        <Alert color="warning">No hay disponibilidad de proveedores para la zona y fecha seleccionada.</Alert>
      </div>
    }

    { state.length > 0 &&
    <Form
        className="form-horizontal"
    >
        <div className="mt-3">
            <Row>
              <Col lg={12} className="text-center">
                  {error ? <Alert color="danger" className="mt-3">{error}</Alert> : null}
                  <div className="mt-1">
                    <div className="bg-dark text-light pt-1 pb-1 mb-1 text-center"><b>{ state[0].provider.company_name } - Sucursal { state[0].farm.name }</b></div>
                  </div>
              </Col>
            </Row>
            
            <Row style={{paddingTop:'5px'}}>
              <Col lg={12} className="mb-3">
                {(state || []).map((formDaily, keyDaily) => (
                  <Row key={keyDaily} className="">
                    <Col lg={12}>
                      <div className="bg-dark bg-soft text-dark pt-1 pb-1 mb-1 text-center"><b>{formDaily.product}</b></div>
                    </Col>
                    <Col lg={3}>
                      <div>Cantidad del dia: <b>{formDaily.quantity} Pzas</b></div>
                      <div>Disponible: <b>{formDaily.available_product} Pzas</b></div>
                      <div>Peso Promedio: <b>{formDaily.weight} - {formDaily.weight_max} Kg.</b></div>
                      <Row>
                        <Col lg={6}>
                          Pigmento:
                        </Col>
                        <Col lg={6}>
                          <RatingCustom
                            max={3}
                            disabled={true}
                            defaultRating={formDaily.quality}
                            styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                            ActiveComponent={
                              <i
                                className="mdi mdi-star text-warning"
                                style={{}}
                              />
                            }
                            InActiveComponent={
                              <i
                                className="mdi mdi-star-outline text-muted"
                                style={{}}
                              />
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          Calidad:
                        </Col>
                        <Col lg={6}>
                          <RatingCustom
                            max={3}
                            disabled={true}
                            defaultRating={formDaily.quality_2}
                            styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                            ActiveComponent={
                              <i
                                className="mdi mdi-star text-warning"
                                style={{}}
                              />
                            }
                            InActiveComponent={
                              <i
                                className="mdi mdi-star-outline text-muted"
                                style={{}}
                              />
                            }
                          />{" "}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          Salud del Ave:
                        </Col>
                        <Col lg={6}>
                          <RatingCustom
                            max={3}
                            disabled={true}
                            defaultRating={formDaily.quality_3}
                            styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                            ActiveComponent={
                              <i
                                className="mdi mdi-star text-warning"
                                style={{}}
                              />
                            }
                            InActiveComponent={
                              <i
                                className="mdi mdi-star-outline text-muted"
                                style={{}}
                              />
                            }
                          />
                        </Col>
                      </Row>
                      <div>Observaciones: <b>{formDaily.observations}</b></div><br/>
                    </Col>

                    <Col lg={ 9 } className="mt-2 mb-2 text-lg-center text-center">
                      <div className="table-responsive">
                      <Table className="table table-striped table-sm w-100">
                        <thead className="table-light">
                            <tr className="table-primary">
                              <th colSpan={6}>Camiones</th>
                            </tr>
                            <tr className="table-primary">
                              <th>Camión</th>
                              <th>Unidades / Camión</th>
                              <th>Disponibles</th>
                              <th className="">Pedidos Actuales</th>
                              <th className="">Status</th>
                              <th></th>
                            </tr>
                        </thead>
                        <tbody>
                          {(formDaily.quantities || []).map((truck, key) => (
                            <React.Fragment key={key}>
                              <tr>
                                <td style={{width: '80px'}}>
                                  {truck.truck_name}
                                </td>
                                <td style={{width: '100px'}}>
                                  <b>{truck.truck_units}</b>
                                </td>
                                <td style={{width: '100px'}}>
                                  <b>{ truck.truck_units - formDaily.truckAsigned[truck.truck_name] }</b>
                                </td>
                                <td className="">
                                  {(truck.truck_name in formDaily.truckDeliveryList ? formDaily.truckDeliveryList[truck.truck_name]:[]).sort((A,B) => { return moment(A.delivery_date).unix() - moment(B.delivery_date).unix()}).map((orderItem, key2) => (
                                    <div key={'a'+key2} className="mb-2">
                                      <span className="badge bg-danger ps-1 pe-1 me-1">{key2 + 1}</span><b>Día {orderItem.delivery_date.substr(8)} | {orderItem.delivered ? orderItem?.delivered_region:''} {orderItem.delivered ? orderItem?.delivered_subregion:''}  | {orderItem?.truckAsigned[truck.truck_name]}Pzas | {orderItem.order.client.company_name}</b> <br />
                                      <i className="d-none">{orderItem.delivered ? orderItem?.order.address?.address_string:''}</i>
                                    </div>
                                  ))}
                                </td>
                                <td style={{width: '50px'}}>
                                  <span className={truck.status=='pending' ? 'badge rounded-pill bg-warning':'badge rounded-pill bg-success'}>{truck.status_string}</span>
                                </td>
                                <td style={{width: '50px'}}>
                                  {
                                  formDaily.truckAsigned[truck.truck_name] > 0 &&
                                  //truck.status=='pending' &&
                                  <button onClick={(evt) => { props.toggleModalAuth((truck.truck_name in formDaily.truckDeliveryList ? formDaily.truckDeliveryList[truck.truck_name]:[]), truck); }} type="button" className="btn btn-danger btn-sm ms-3" disabled={ btnSaveLoad }>
                                      <i className="fas fa-check"></i>
                                  </button>
                                  }
                                </td>
                              </tr> 
                            </React.Fragment>
                          ))}
                        </tbody>
                      </Table>
                      </div>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          { false && orders && orders.length > 0 && orders[0].status == 'requested' &&
          <Row className="">
            <Col lg={12} className="mb-3 text-center">
              { 
                <button onClick={props.handleAuthTruck} type="button" className="btn btn-danger w-md btn-sm ms-3" disabled={ btnSaveLoad }>
                    <i className="fas fa-list-alt"></i> Autorizar Pedidos
                </button>
              }
            </Col>
          </Row>
          }
        </div>
    </Form>
    }
    </React.Fragment>
  )
}

Authorization.propTypes = {
  rows: PropTypes.array,
  orders: PropTypes.array,
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  date: PropTypes.string.isRequired,
  toggleModalAuth: PropTypes.func.isRequired,
  handleAuthTruck: PropTypes.func.isRequired,
}

export default Authorization
