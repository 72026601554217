import React from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Row } from "reactstrap"

import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import useProducts from "hooks/useProducts"
import { tax_configuration } from "common/sat/Taxes"

const ProductFinder = ({
  fetchSatUnits,
  fetchSatProducts,
  productValidation,
}) => {
  const { products, isLoadingProducts } = useProducts()

  const onChangeSearch = option => {
    finderValidation.setFieldValue("productId", option?.id ?? null, true)

    if (!option) return

    const ProductCode = option?.codigo_sat || ""
    const UnitCode = option?.unidad_sat || ""

    const productItem = {
      Id: new Date().getTime(), //
      ProductCode,
      Description: option?.name,
      UnitCode,
      Quantity: 1,
      UnitPrice: 0,
      Subtotal: 0,
      Discount: 0,
      Total: 0,
      TaxObject: option?.objeto_imp_sat,
      Taxes: [],
      tax_configuration: option?.tax_configuration || tax_configuration,
    }

    productValidation.setValues(productItem)

    fetchSatUnits(UnitCode)
    fetchSatProducts(ProductCode)
  }

  const finderValidation = useFormik({
    enableReinitialize: true,
    initialValues: { productId: null },
    validationSchema: Yup.object({
      productId: Yup.string().nullable(),
    }),
    onSubmit: () => {},
  })

  return (
    <>
      <Row>
        <NibbleBaseInput
          fieldType="select"
          fieldName="productId"
          label="Buscar producto"
          placeHolder="Seleccionar del catálogo de productos"
          selectItems={products}
          validation={finderValidation}
          md={12}
          isLoading={isLoadingProducts}
          onChange={onChangeSearch}
        />
      </Row>
      <hr />
    </>
  )
}

ProductFinder.propTypes = {
  productValidation: PropTypes.object,
  fetchSatUnits: PropTypes.func,
  fetchSatProducts: PropTypes.func,
}

export default ProductFinder
