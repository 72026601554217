import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useSelector } from "react-redux"

import BtnDownloadExcel from "../common/BtnDownloadExcel"
import BtnDownloadPdf from "../common/BtnDownloadPdf"
import { useForm } from "hooks/useForm"
import { getBanksCashFlow } from "../../../../helpers/backend_helper"
import { getFilters } from "selectors"
import NibbleFilter from "common/nibble/NibbleFilter"
import NibblePeriodFilter from "common/nibble/NibblePeriodFilter"
import { header_excel, header_pdf, initialPeriod } from "static"

const typeOptions = [
  { id: "depositos", name: "Depósitos" },
  { id: "retiros", name: "Retiros" },
]

const initFilter = {
  period: initialPeriod,
  type: "",
  banks: [],
  account: "",
  search_qty: "",
}

const getParsedParams = (form, column, order) => ({
  ...form,
  banks: form.banks.join(","),
  column,
  order,
})

export default function Filters({
  data,
  setPeriod,
  columnOrder,
  handleFilters,
}) {
  const { costAccounts, clientAccounts, providerAccounts } =
    useSelector(getFilters)

  const { column, order } = columnOrder

  const { form, handleSpecificInput, handleDatePeriod } = useForm(initFilter)

  const params = getParsedParams(form, column, order)

  const downloadFile = (type = "excel") =>
    getBanksCashFlow({
      params,
      headers: type === "excel" ? header_excel : header_pdf,
    })

  useEffect(() => {
    handleFilters(params)
  }, [
    params.type,
    params.banks,
    params.order,
    params.column,
    params.period,
    params.account,
    params.search_qty,
  ])

  return (
    <>
      <Row>
        <NibblePeriodFilter
          form={form}
          handleDatePeriod={handleDatePeriod}
          handleSpecificInput={handleSpecificInput}
          setPeriod={setPeriod}
        />
        <Col className="text-end">
          {data?.report?.length ? (
            <div>
              <BtnDownloadExcel downloadExcel={downloadFile} />
              <BtnDownloadPdf downloadPdf={downloadFile} />
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <NibbleFilter
          isMulti
          name="banks"
          form={form}
          handleInput={handleSpecificInput}
        />
        <NibbleFilter
          form={form}
          name="select"
          label="Tipo"
          fieldName="type"
          options={typeOptions}
          handleInput={handleSpecificInput}
        />
        <NibbleFilter
          name="accounts"
          form={form}
          options={[...costAccounts, ...providerAccounts, ...clientAccounts]}
          handleInput={handleSpecificInput}
        />
        <NibbleFilter name="searchQty" handleInput={handleSpecificInput} />
      </Row>
    </>
  )
}

Filters.propTypes = {
  data: PropTypes.any,
  period: PropTypes.string,
  setPeriod: PropTypes.func,
  columnOrder: PropTypes.object,
  handleFilters: PropTypes.func,
}
