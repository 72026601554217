import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Row, Button } from "reactstrap"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"

import { useSelector } from "react-redux"

import "flatpickr/dist/themes/material_red.css"

const CostForm = ({ validation }) => {
  const { costAccounts, providerAccounts } = useSelector(
    state => state.AccountingAccounts
  )

  const {
    values: { amount, iva },
    errors: formErrors,
  } = validation

  const calculateIVA = () => {
    if (formErrors.amount) return
    if (formErrors.iva) return

    const total = Number(amount) * 0.16
    const iva = total ? Math.round(total * 100) / 100 : 0

    validation.setFieldValue("iva", iva, true)
  }

  const calculateTotal = () => {
    if (formErrors.amount) return
    if (formErrors.iva) return

    const total = Number(amount) + Number(iva)

    validation.setFieldValue("total", total, true)
  }

  useEffect(calculateTotal, [amount, iva, formErrors])

  return (
    <>
      <Row>
        <NibbleBaseInput
          fieldType="date"
          fieldName="date"
          label="Fecha *"
          validation={validation}
          md={4}
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName="invoice"
          label="Folio factura"
          validation={validation}
          md={4}
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName="folio"
          label="Consecutivo"
          validation={validation}
          md={4}
          disabled
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName="c_provider_id"
          label="Proveedor"
          selectItems={providerAccounts}
          validation={validation}
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName="account_id"
          label="Cuenta contable"
          selectItems={costAccounts}
          validation={validation}
        />
      </Row>
      <Row>
        <Col
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <Button
            onClick={calculateIVA}
            className="btn btn-danger"
            type="button"
          >
            Calcular IVA
          </Button>
        </Col>
        <Col md={6}>
          <Row>
            <NibbleBaseInput
              fieldType="number"
              fieldName="amount"
              label="Importe *"
              validation={validation}
              md={12}
            />
            <NibbleBaseInput
              fieldType="number"
              fieldName="iva"
              label="IVA"
              validation={validation}
              md={12}
            />
            <NibbleBaseInput
              fieldType="number"
              fieldName="total"
              label="Total"
              validation={validation}
              md={12}
              disabled
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <NibbleBaseInput
            fieldType="text"
            fieldName="observations"
            label="Observaciones"
            validation={validation}
            md={12}
          />
        </Col>
      </Row>
    </>
  )
}

CostForm.propTypes = {
  validation: PropTypes.any,
}

export default CostForm
