import React, { useEffect, useState } from 'react';

import { 
    Card,
    CardBody
} from 'reactstrap';

import FullSpinner from 'components/Common/FullSpinner';
import PageBase from 'components/Common/PageBase';
import Filters from './Filters';
import HeaderReport from './HeaderReport';
import TableReport from './TableReport';

import useRequest from 'hooks/useRequest';
import { getSalesFolioReport } from 'helpers/backend_helper';

import '../../../../assets/scss/reports/daily-movements.scss';
import moment from 'moment';
import _ from 'lodash';

const initialPeriod = moment().startOf('month').format('YYYY-MM-DD') + '/' + moment().endOf('month').format('YYYY-MM-DD');

const breadcrumbList = [
    { id: '1', title: 'Administrativo', active: false },
    { id: '2', title: 'Reportes', active: false },
    { id: '3', title: 'Ventas por folio consecutivo', active: true }
];

export const getParams = (filters) => ({
    client: _.map(filters.client, 'id').join(','),
    provider: _.map(filters.provider, 'id').join(','),
});


export default function SalesFolioContainer() {

    const [ period, setPeriod ] = useState(initialPeriod);
    const [ columnOrder, setColumnOrder ] = useState({
        column: '',
        order: ''
    });
    const [filters, setFilters] = useState({
        client: [],
        provider: [],
    })

    const {
        column,
        order
    } = columnOrder;

    const {
        data,
        isLoading,
        req
    } = useRequest();

    const config = {
        params: {
            period,
            column,
            order,
            ...getParams(filters)
        }
    };

    const getData = () => req(getSalesFolioReport(config));

    useEffect(() => {
        getData();
    }, [ period, column, order, filters ]);
    
    return (
        <>  
            { isLoading && <FullSpinner /> }
            <PageBase
                titleMeta="Reporte de ventas por Folio Consecutivo"
                titleBread="Reporte de ventas por Folio Consecutivo"
                itemsBread={ breadcrumbList }
            >
                <Card>
                    <CardBody>
                        <Filters 
                            period={ period }
                            data={ data }
                            defaultPeriod={ initialPeriod }
                            setPeriod={ setPeriod }
                            columnOrder={ columnOrder }
                            filters={ filters }
                            setFilters={ setFilters }
                        />
                    </CardBody>
                </Card>                
                <Card style={{minHeight: '400px'}}>
                    <CardBody>                        
                        { data && data.report.length  ? (
                            <>
                                <HeaderReport period={ period }/>
                                <TableReport
                                    data={ data.report }
                                    totals={ data.totals }
                                    setColumnOrder={ setColumnOrder }
                                    columnOrder={ columnOrder }
                                />
                            </>
                        ) : <p>No hay datos con el periodo seleccionado</p>}
                    </CardBody>
                </Card>
            </PageBase>
        </>
    );
}