import React from 'react';
import useUserPassword from 'hooks/useUserPassword';

import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button, 
    Spinner,
    Label,
    FormGroup
} from 'reactstrap';

function UserForm() {

    const {
        handleChange,
        handleSubmit,
        formUi,
        form
    } = useUserPassword();

    const { isLoading, isSuccess, isError } = formUi;
    const { password, password_confirmation, current_password } = form;

    return (
        <>
            <h4 className="card-title mb-4">Cambiar contraseña</h4>

            <Card>
                <CardBody>

                    { isError ? <Alert color="danger">{ isError }</Alert> : null }
                    { isSuccess ? <Alert color="success">Tu contraseña ha sido actualizada</Alert> : null }

                    <form
                        className="form-horizontal"
                        onSubmit={ handleSubmit }
                    >
                        <Row>
                            <Col md="4">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationCurrentPassword">Contraseña actual</Label>
                                    <div className="col-md-12">
                                        <input
                                            onChange={ handleChange }
                                            value={ current_password }
                                            name="current_password"
                                            className="form-control"
                                            type="password"
                                            placeholder="Ingresa tu contraseña actual"
                                            id="validationCurrentPassword"
                                            required
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationNewPassword">Nueva contraseña</Label>
                                    <div className="col-md-12">
                                        <input
                                            onChange={ handleChange }
                                            value={ password }
                                            name="password"
                                            className="form-control"
                                            type="password"
                                            placeholder="Ingresa tu nueva contraseña"
                                            minLength={ 6 }
                                            id="validationNewPassword"
                                            required
                                        />
                                        <small>Min. 6 caracteres</small>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationConfirmPassword">Repetir contraseña</Label>
                                    <div className="col-md-12">
                                        <input
                                            onChange={ handleChange }
                                            value={ password_confirmation }
                                            name="password_confirmation"
                                            className="form-control"
                                            type="password"
                                            placeholder="Repite tu nueva contraseña"
                                            id="validationConfirmPassword"
                                            minLength={ 6 }
                                            required
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>                                                        
                        <div className="text-center mt-4">
                            {
                                !isLoading ? (
                                    <Button type="submit" color="danger" className='btn-sm'>
                                        Actualizar contraseña
                                    </Button>
                                ) : <Spinner />
                            }                                
                        </div>
                    </form>
                </CardBody>
            </Card>
        </>
    )
}

export default UserForm;