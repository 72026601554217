import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import moment from "moment"

import useRequest from "hooks/useRequest"
import { getCfdisList } from "helpers/backend_helper"
import useCancelableRequest from "hooks/useCancelableRequest"

const startOfMonth = moment().startOf("M").format("YYYY-MM-DD")
const endOfMonth = moment().endOf("M").format("YYYY-MM-DD")

const useBilling = () => {
  const invoicesRequest = useRequest()
  const invoicesDownloadRequest = useRequest()
  const getCancelTokenSource = useCancelableRequest()

  const [data, setData] = useState([])
  const [totalInvoices, setTotalInvoices] = useState(0)
  const [invoice, setInvoice] = useState()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const [inititalInvoiceValues, setInititalInvoiceValues] = useState({})
  const [isOpenGlobalInvoiceSidebar, setIsOpenGlobalInvoiceSidebar] =
    useState(false)
  const [movsClientSelected, setMovsClientSelected] = useState([])
  const [isOpenSalesSearchModal, setIsOpenSearchSalesModal] = useState(false)

  const toggleSidebar = () => setIsOpenSidebar(!isOpenSidebar)

  const toggleGlobalInvoiceSidebar = () =>
    setIsOpenGlobalInvoiceSidebar(!isOpenGlobalInvoiceSidebar)

  const toggleSalesSearchModal = () =>
    setIsOpenSearchSalesModal(!isOpenSalesSearchModal)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      page: 1,
      per_page: 20,
      total: 200,
      period: `${startOfMonth}/${endOfMonth}`,
      status: null,
      issuer: "",
      receiver: "",
      total_amount: "",
      total_amount_state: "",
      uuid: "",
      payment_method: "",
    },
    onSubmit: function () {},
  })

  const toggleModal = () => setIsOpenModal(!isOpenModal)

  const onSelectMovsClient = movs => {
    setMovsClientSelected(movs)
    toggleSalesSearchModal()
    toggleGlobalInvoiceSidebar()
  }

  const onAddInvoice = () => {
    const values = { CfdiType: "I" }

    setInititalInvoiceValues(values)
    toggleSidebar()
  }

  const onAddComplement = invoice => {
    const values = {
      CfdiType: "P",
      ppd_invoice_id: invoice.id,
      Receiver: {
        Rfc: invoice?.receiver || invoice?.payload_sent?.Receiver?.Rfc,
        Name: invoice?.payload_sent?.Receiver?.Name,
        CfdiUse: invoice?.payload_sent?.Receiver?.CfdiUse,
        TaxZipCode: invoice?.payload_sent?.Receiver?.TaxZipCode,
        FiscalRegime: invoice?.payload_sent?.Receiver?.FiscalRegime,
      },
    }

    setInititalInvoiceValues(values)
    toggleSidebar()
  }

  const onCancelCfdi = invoice => {
    setInvoice(invoice)
    toggleModal()
  }

  const fetchInvoices = () => {
    const config = {
      params: validation.values,
      cancelToken: getCancelTokenSource(),
    }

    invoicesRequest.req(getCfdisList(config)).then(resp => {
      setData(resp.data)
      validation.setFieldValue("total", resp.metadata.total)
      setTotalInvoices(Number(resp.metadata.total_invoices))
    })
  }

  const downloadInvoices = headers => {
    const config = {
      params: validation.values,
      headers,
    }

    invoicesDownloadRequest.req(getCfdisList(config)).then(resp => {
      window.open(resp?.data?.file, "_blank")
    })
  }

  useEffect(fetchInvoices, [
    validation.values.page,
    validation.values.uuid,
    validation.values.period,
    validation.values.status,
    validation.values.issuer,
    validation.values.receiver,
    validation.values.per_page,
    validation.values.total_amount,
    validation.values.payment_method,
  ])

  return {
    validation,
    state: {
      isOpenModal,
      invoice,
      isLoading: invoicesRequest.isLoading,
      isDownloading: invoicesDownloadRequest.isLoading,
      data,
      isOpenSidebar,
      inititalInvoiceValues,
      isOpenGlobalInvoiceSidebar,
      isOpenSalesSearchModal,
      movsClientSelected,
      totalInvoices
    },
    methods: {
      toggleModal,
      onCancelCfdi,
      fetchInvoices,
      toggleSidebar,
      onAddInvoice,
      onAddComplement,
      downloadInvoices,
      onSelectMovsClient,
      toggleSalesSearchModal,
      toggleGlobalInvoiceSidebar,
    },
  }
}

export default useBilling
