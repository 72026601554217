import {
  GET_INVOICE_TYPE,
  GET_INVOICE_TYPE_FINISHED,
  GET_INVOICE_TYPE_SUCCESS,
  GET_OBJECT_IMP,
  GET_OBJECT_IMP_FINISHED,
  GET_OBJECT_IMP_SUCCESS,
  GET_PAYMENT_FORM,
  GET_PAYMENT_FORM_FINISHED,
  GET_PAYMENT_FORM_SUCCESS,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_FINISHED,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_TAX_REGIME,
  GET_TAX_REGIME_FINISHED,
  GET_TAX_REGIME_SUCCESS,
  GET_USE_CFDI,
  GET_USE_CFDI_FINISHED,
  GET_USE_CFDI_SUCCESS,
} from "./actionTypes"

export const getObjetoImp = () => ({
  type: GET_OBJECT_IMP,
})

export const getObjetoImpSuccess = data => ({
  type: GET_OBJECT_IMP_SUCCESS,
  payload: data,
})

export const getObjetoImpFinished = () => ({
  type: GET_OBJECT_IMP_FINISHED,
})

export const getTaxRegime = () => ({
  type: GET_TAX_REGIME,
})

export const getTaxRegimeSuccess = data => ({
  type: GET_TAX_REGIME_SUCCESS,
  payload: data,
})

export const getTaxRegimeFinished = () => ({
  type: GET_TAX_REGIME_FINISHED,
})

export const getUseCfdi = () => ({
  type: GET_USE_CFDI,
})

export const getUseCfdiSuccess = data => ({
  type: GET_USE_CFDI_SUCCESS,
  payload: data,
})

export const getUseCfdiFinished = () => ({
  type: GET_USE_CFDI_FINISHED,
})

export const getInvoiceType = () => ({
  type: GET_INVOICE_TYPE,
})

export const getInvoiceTypeSuccess = data => ({
  type: GET_INVOICE_TYPE_SUCCESS,
  payload: data,
})

export const getInvoiceTypeFinished = () => ({
  type: GET_INVOICE_TYPE_FINISHED,
})

export const getPaymentForm = () => ({
  type: GET_PAYMENT_FORM,
})

export const getPaymentFormSuccess = data => ({
  type: GET_PAYMENT_FORM_SUCCESS,
  payload: data,
})

export const getPaymentFormFinished = () => ({
  type: GET_PAYMENT_FORM_FINISHED,
})

export const getPaymentMethod = () => ({
  type: GET_PAYMENT_METHOD,
})

export const getPaymentMethodSuccess = data => ({
  type: GET_PAYMENT_METHOD_SUCCESS,
  payload: data,
})

export const getPaymentMethodFinished = () => ({
  type: GET_PAYMENT_METHOD_FINISHED,
})
