import React from 'react';

export default function TableHeader() {
    return (
        <>
            <tr className="no-border-b bg-table-grey">
                <th>Movimiento</th>
                <th className="text-right">Suc</th>
                <th>Lugar</th>
                <th>Tipo</th>
                <th>Detalle</th>
                <th className="text-right">Origen/Destino</th>
                <th></th>
                <th className="text-right">Fecha</th>
                <th colSpan={2} className="text-center">Referencia</th>
            </tr>
            <tr className="bg-table-grey">
                <th>Clave</th>
                <th></th>
                <th>Descripción</th>
                <th></th>
                <th>Lote/Serie/Pedimento</th>
                <th className="text-right">Cantidad</th>
                <th>Unidad Atributo</th>
                <th className="text-right">Cos. en M.N.</th>
                <th className="text-right">Total en M.N.</th>
                <th className="text-right">Total en Kg.</th>
            </tr>
        </>
    );
}