import React from 'react';

export default function TableCustomerItem() {
    return (
        <>
            <tr className="report-table__col-border--blue">
                <td className="f-bold">Cliente</td>
                <td colSpan={9} className="f-bold">23 OSCAR</td>
            </tr>
            <tr>
                <td>C21</td>
                <td></td>
                <td>Nota de cargo</td>
                <td>GENERAL</td>
                <td>1</td>
                <td className="text-right">2,940,774.11</td>
                <td>PESOS</td>
                <td>01/01/2021</td>
                <td>/ /</td>
                <td>01/01/2021</td>
            </tr>
            <tr>
                <td>C21</td>
                <td></td>
                <td>Nota de cargo</td>
                <td>GENERAL</td>
                <td>1</td>
                <td className="text-right">2,940,774.11</td>
                <td>PESOS</td>
                <td>01/01/2021</td>
                <td>/ /</td>
                <td>01/01/2021</td>
            </tr>
            <tr>
                <td>C21</td>
                <td></td>
                <td>Nota de cargo</td>
                <td>GENERAL</td>
                <td>1</td>
                <td className="text-right">2,940,774.11</td>
                <td>PESOS</td>
                <td>01/01/2021</td>
                <td>/ /</td>
                <td>01/01/2021</td>
            </tr>
            <tr>
                <td>C21</td>
                <td>R</td>
                <td>Factura</td>
                <td>GENERAL</td>
                <td>1</td>
                <td className="text-right">2,940,774.11</td>
                <td>PESOS</td>
                <td>01/01/2021</td>
                <td>/ /</td>
                <td>01/01/2021</td>
            </tr>
            <tr>
                <td>C21</td>
                <td>R</td>
                <td>Factura</td>
                <td>GENERAL</td>
                <td>1</td>
                <td className="text-right">2,940,774.11</td>
                <td>PESOS</td>
                <td>01/01/2021</td>
                <td>/ /</td>
                <td>01/01/2021</td>
            </tr>
            <tr>
                <td>C21</td>
                <td>R</td>
                <td>Factura</td>
                <td>GENERAL</td>
                <td>1</td>
                <td className="text-right">2,940,774.11</td>
                <td>PESOS</td>
                <td>01/01/2021</td>
                <td>/ /</td>
                <td>01/01/2021</td>
            </tr>
            <tr>
                <td colSpan={4} className="f-bold text-right">Total por cliente en PESOS</td>
                <td className="border-b-black"></td>
                <td className="border-b-black text-right">5,269,780.96</td>
                <td colSpan={4}></td>
            </tr>
            <tr>
                <td className="f-bold">Documentos</td>
                <td></td>
                <td className="text-right">50</td>
                <td className="f-bold text-right">Saldo total del cliente en pesos</td>
                <td></td>
                <td className="text-right">5,269,780.96</td>
                <td colSpan={4}></td>
            </tr>
        </>
    );
}