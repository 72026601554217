import React from 'react';

export default function TableTotal() {
    return (
        <>
            <tr className="report-table__total report-table__total--border">
                <td colSpan={3} className="text-right f-bold">TOTAL en M.N.</td>
                <td className="text-right">1,275,471.90</td>
                <td className="text-right">44,403,710.38</td>
                <td className="text-right">1,275,471.90</td>
                <td className="text-right">44,403,710.38</td>
            </tr>
            <tr className="report-table__total report-table__total--border">
                <td colSpan={4} className="text-right f-bold">Total de Notas de Créd sin Referencia:</td>
                <td className="text-right">0.00</td>
                <td className="text-right"></td>
                <td className="text-right">0.00</td>
            </tr>
            <tr className="report-table__total report-table__total--border">
                <td colSpan={4} className="text-right f-bold">Tot. Fact - Notas Créd sin Referencia:</td>
                <td className="text-right">44,403,710.38</td>
                <td className="text-right"></td>
                <td className="text-right">44,403,710.38</td>
            </tr>
        </>
    );
}
