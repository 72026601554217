import React from "react"
import PropTypes from "prop-types"
import { Media } from "reactstrap"

const GeneralDataTab = ({ enterprise }) => {
  return (
    <div>
      <Media body className="align-self-center">
        <div className="text-muted">
          <h5>{enterprise?.company_name}</h5>
          <p className="mb-1">{enterprise?.email}</p>
          <p className="mb-1">{enterprise?.rfc}</p>
          <p className="mb-1">{enterprise?.phone}</p>
        </div>
      </Media>
      <Media body className="align-self-center">
        <div className="text-muted">
          <p className="mb-1">{enterprise?.invoice_address?.address_string}</p>
        </div>
      </Media>
    </div>
  )
}

GeneralDataTab.propTypes = {
  enterprise: PropTypes.any,
}

export default GeneralDataTab
