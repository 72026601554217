import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { Alert, Form, Modal, Row } from "reactstrap"
import * as Yup from "yup"
import * as _ from "lodash"

import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import { useSelector } from "react-redux"
import { getEnterprise, updateEnterpriseTaxData } from "helpers/backend_helper"
import useRequest from "hooks/useRequest"

const getEnterpriseValues = enterprise => ({
  id: _.get(enterprise, "id", ""),
  company_name: _.get(enterprise, "company_name", ""),
  rfc: _.get(enterprise, "rfc", ""),
  tax_regime: _.get(enterprise, "tax_regime", ""),
  invoice_address: {
    cp: _.get(enterprise, "invoice_address.cp", ""),
  },
})

const updateInvoice = (isIssuer, enterprise, validation) => {
  const enterpriseValues = getEnterpriseValues(enterprise)

  if (isIssuer) {
    validation.setFieldValue(
      "ExpeditionPlace",
      enterpriseValues.invoice_address.cp
    )
    validation.setFieldValue("Issuer.FiscalRegime", enterpriseValues.tax_regime)
    validation.setFieldValue("Issuer.Rfc", enterpriseValues.rfc)
    validation.setFieldValue("Issuer.Name", enterpriseValues.company_name)
  } else {
    validation.setFieldValue(
      "Receiver.TaxZipCode",
      enterpriseValues.invoice_address.cp
    )
    validation.setFieldValue(
      "Receiver.FiscalRegime",
      enterpriseValues.tax_regime
    )
    validation.setFieldValue("Receiver.Rfc", enterpriseValues.rfc)
    validation.setFieldValue("Receiver.Name", enterpriseValues.company_name)
  }
}

const EnterpriseModal = ({
  isOpen,
  isIssuer,
  validation,
  toggleModal,
  enterpriseId,
}) => {
  const issuerRequest = useRequest()

  const { tax_regime } = useSelector(state => state.Sat)

  const onSubmit = formValues => {
    issuerRequest.req(updateEnterpriseTaxData(formValues)).then(enterprise => {
      const enterpriseValues = getEnterpriseValues(enterprise)

      updateInvoice(isIssuer, enterpriseValues, validation)
      toggleModal()
    })
  }

  const enterpriseValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: enterpriseId,
      company_name: "",
      rfc: "",
      tax_regime: null,
      invoice_address: {
        cp: "",
      },
    },
    validationSchema: Yup.object({
      id: Yup.string().required(),
      company_name: Yup.string().required().label("Razón social"),
      rfc: Yup.string().min(12).max(13).required().label("RFC"),
      tax_regime: Yup.string().required().label("Régimen fiscal"),
      invoice_address: Yup.object({
        cp: Yup.string()
          .required()
          .matches(
            /^[0-9]{5}$/,
            "Código postal debe tener 5 caracteres numéricos"
          )
          .label("Código postal"),
      }),
    }),
    onSubmit,
  })

  const fetchEnterprise = () => {
    if (!enterpriseId) return

    getEnterprise(enterpriseId).then(enterprise => {
      const enterpriseValues = getEnterpriseValues(enterprise)

      enterpriseValidation.setValues(enterpriseValues)
    })
  }

  useEffect(fetchEnterprise, [])

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop="static">
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Datos del {isIssuer ? "Emisor" : "Cliente"}
        </h5>
        <button
          type="button"
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {!enterpriseId ? (
          <Alert color="danger">
            No se encontró el ID de la empresa del cliente. <br />
            Debe relacionar la cuenta contable con un cliente. 
          </Alert>
        ) : null}

        <Form
          onSubmit={e => {
            e.preventDefault()
            enterpriseValidation.handleSubmit()
            return false
          }}
          id="client-modal-form"
        >
          <Row>
            <NibbleBaseInput
              fieldType="text"
              fieldName="company_name"
              label="Razón social"
              validation={enterpriseValidation}
              md={6}
            />
            <NibbleBaseInput
              fieldType="text"
              fieldName="rfc"
              label="RFC"
              validation={enterpriseValidation}
              md={6}
            />
            <NibbleBaseInput
              fieldType="text"
              fieldName="invoice_address.cp"
              label="C.P."
              validation={enterpriseValidation}
              md={6}
            />
            <NibbleBaseInput
              fieldType="select"
              fieldName="tax_regime"
              label="Régimen fiscal"
              selectItems={tax_regime?.data?.map(item => ({
                ...item,
                id: item.code,
              }))}
              validation={enterpriseValidation}
              selectOptionValue={option => option.id}
              selectOptionLabel={option =>
                `${option.code} - ${option.description}`
              }
              md={6}
              placeHolder="Seleccionar"
              isLoading={tax_regime.isLoading}
            />
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={toggleModal}
          className="btn btn-secondary "
          data-dismiss="modal"
          disabled={issuerRequest.isLoading}
        >
          <i className="fas fa-times"></i> Cerrar
        </button>
        <button
          type="submit"
          className="btn btn-danger"
          form="client-modal-form"
          disabled={!enterpriseId || issuerRequest.isLoading}
        >
          <i className="fas fa-check"></i> Guardar
        </button>
      </div>
    </Modal>
  )
}

EnterpriseModal.propTypes = {
  isOpen: PropTypes.bool,
  isIssuer: PropTypes.bool,
  request: PropTypes.object,
  productRow: PropTypes.object,
  validation: PropTypes.object,
  stampedInvoice: PropTypes.object,
  toggleModal: PropTypes.func,
  handleEditProductModal: PropTypes.func,
  enterpriseId: PropTypes.any,
}

export default EnterpriseModal
