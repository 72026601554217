import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, InputGroup, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import * as moment from 'moment';

import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import 'flatpickr/dist/themes/material_red.css';

import PropTypes from 'prop-types';
import _ from "lodash";
import { getDistribuitorDashboardExpenses, getProviders, getProvidersSelect } from "helpers/backend_helper";
import { rangeDatePicker } from "helpers/functions";

const initialPeriod = moment().startOf('month').format('YYYY-MM-DD') + '/' + moment().endOf('month').format('YYYY-MM-DD');

const nf2 = new Intl.NumberFormat('es-MX', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const numberFormat = new Intl.NumberFormat('es-MX', { minimumFractionDigits: 2 });

const getOptionsDefault = (categories) => { return {
  chart: {
    toolbar: "false",
    dropShadow: {
      enabled: !0,
      color: "#000",
      top: 18,
      left: 7,
      blur: 8,
      opacity: 0.2,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  xaxis: {
    categories: categories
  },
  colors: ['#a0a0a0', "#f46a6a"],
  stroke: {
    curve: "smooth",
    width: 3,
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return "$" + nf2.format(value);
      }
    },
  },
}};

function Expenses(props) {
  const [monthIndex, setMonthIndex] = useState(null)
  const [months, setMonths] = useState(props.months)
  const [dataGraph, setDataGraph] = useState(null)
  const [totals, setTotals] = useState([0,0,0,0])
  const [percent, setPercent] = useState(0)
  const [loading, setLoading] = useState (false);
  const [options, setOptions] = useState (null);
  const [provider, setProvider] = useState('')
  const [providers, setProviders] = useState(props.providers)
  const [period, setPeriod] = useState(initialPeriod)

  useEffect(() => {
    setProviders(props.providers);
  }, [props.providers]); // 👈️ add props as dependencies

  useEffect(() => {
    getDataServer(initialPeriod,'');
  }, []); // 👈️ add props as dependencies


  const getPercent = (monthIndex) => {
    return monthIndex > 0 &&  totals[monthIndex-1] > 0 ? (totals[monthIndex] - totals[monthIndex-1]) / totals[monthIndex-1] * 100 : 0
  }

  async function getDataServer(period, provider)  {
    const config = {
      params: {
        period: period,
        provider: provider,
      }
    }
    setLoading(true);
    const dataDashboard = await getDistribuitorDashboardExpenses(config);
    setLoading(false);
    console.log(dataDashboard);
    setOptions(getOptionsDefault(dataDashboard.labels))
    setDataGraph(dataDashboard);
    setTotals([
      dataDashboard.previous.reduce((prev, cur) => { return prev + (Number(cur) ? Number(cur):0) }, 0 ).toFixed(2),
      dataDashboard.current.reduce((prev, cur) => { return prev + (Number(cur) ? Number(cur):0) }, 0 ).toFixed(2),
    ]);
    setMonthIndex(1);
  }

  const handleMonthSelect = (e) => {
    setPeriod(e.val);
    getDataServer(e.val, provider);
  }

  const handleSelectedProvider = (e) => {
    setProvider(e);
    getDataServer(period, e);
  }

  console.log('provider',provider)

  return (
    <React.Fragment>
      <Col xl="12">
        <Card>
          <CardBody>
            <div className="clearfix">
              <div className="float-end">
                <FormGroup className="mb-4" style={{width:'230px'}}>
                  <InputGroup size="sm">
                    <Flatpickr
                        className="form-control d-block"
                        placeholder=""
                        onChange={ (dates) => {
                            if(dates.length !== 2) return;
                            handleMonthSelect({
                                key: 'dates',
                                val: rangeDatePicker(dates)
                            })
                        }}
                        options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            locale: Spanish,
                            defaultDate: initialPeriod.split('/')
                        }}
                    />
                    <label className="input-group-text">Periodo</label>
                  </InputGroup>
                </FormGroup>
              </div>
              <div className="float-end me-2">
                <div className="input-group input-group-sm">
                    <select
                      style={{maxWidth: '200px'}}
                      className="form-select form-select-sm"
                      defaultValue={0}
                      onChange={ (e) => { handleSelectedProvider(e.target.value); } }
                    >
                      <option selected={provider==''} value={''}>--- Todos ---</option>
                      { providers.map( e => (
                          <option selected={provider==e.id} value={e.id} key={e.id}>{e.name}</option>
                        )) 
                      }
                    </select>
                    {/* <div className="input-group-append"> */}
                    <label className="input-group-text">Proveedor</label>
                    {/* </div> */}
                  </div>
                </div>
              <h4 className="card-title mb-4">Compras</h4>
            </div>

            <Row>
              <Col lg="3" className="">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>Periodo actual</p>
                    <h4>$ { monthIndex ? numberFormat.format(totals[monthIndex]) : '' }</h4>
                    <div>
                      <span className={ getPercent(monthIndex) >= 0 ? "badge badge-soft-success font-size-12 me-1": "badge badge-soft-danger font-size-12 me-1"}>
                        {" "}
                        { getPercent(monthIndex).toFixed(2)  }%{" "}
                      </span>{" "}
                      <br/>
                      comparado con el año anterior
                    </div>
                  </div>

                  <div className="mt-4">
                    <p className="mb-2">Año anterior</p>
                    <h5>$ { monthIndex ? numberFormat.format(monthIndex > 0 ? totals[monthIndex-1]:0) : '' }</h5>
                  </div>
                </div>

                <div className="mt-5 d-none">
                    <Link to="#" className="btn btn-danger btn-sm">
                      Ir a reporte Compras{" "}
                      <i className="mdi mdi-chevron-right ms-1"></i>
                    </Link>
                  </div>
              </Col>

              <Col lg="9">
                <div id="line-chart" dir="ltr">
                { dataGraph && <ReactApexChart
                    series={[
                      {
                        name: "Compras Año Anterior", 
                        data: dataGraph.previous.map(function(k) { return k.toFixed(2) }),
                      },
                      {
                        name: "Compras Periodo Actual", 
                        data: dataGraph.current.map(function(k) { return k.toFixed(2) }),
                      }
                    ]}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                }
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

Expenses.propTypes = {
  months: PropTypes.array,
  options: PropTypes.any,
  dashboardData: PropTypes.array,
  currentMonth: PropTypes.number,
  prevMonth: PropTypes.number,
  providers: PropTypes.array,
}

export default Expenses;
