import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Alert, Form, Modal, Row } from "reactstrap"

import useRequest from "hooks/useRequest"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import { getUnits } from "helpers/backend_helper"

import Taxes from "common/sat/Taxes"
import useSatUnits from "hooks/useSatUnits"
import useSatProducts from "hooks/useSatProducts"

const ProductModal = ({
  isOpen,
  request,
  dataRow,
  validation,
  toggleModal,
}) => {
  const unitsRequest = useRequest()

  const { satUnits, fetchSatUnits } = useSatUnits(dataRow.unidad_sat)
  const { satProducts, fetchSatProducts } = useSatProducts(dataRow.codigo_sat)

  const [units, setUnits] = useState([])

  const fetchUnits = () => {
    unitsRequest.req(getUnits()).then(response => {
      if (!response) return
      setUnits(response)
    })
  }

  useEffect(fetchUnits, [])

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {!dataRow?.id ? "Agregar Producto" : "Editar Producto"}
        </h5>
        <button
          type="button"
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
          id="create-form"
        >
          {request.isError ? (
            <Alert className="alert alert-danger">{request.msgError}</Alert>
          ) : (
            ""
          )}
          <Row>
            <NibbleBaseInput
              fieldType="text"
              fieldName="skey"
              label="Clave"
              validation={validation}
              md={6}
            />
            <NibbleBaseInput
              fieldType="text"
              fieldName="name"
              label="Nombre *"
              validation={validation}
              md={6}
            />
            <NibbleBaseInput
              fieldType="text"
              fieldName="description"
              label="Descripción *"
              validation={validation}
              md={6}
            />
            <NibbleBaseInput
              fieldType="select"
              fieldName="unit_id"
              label="Unidad"
              placeHolder="Seleccionar"
              selectItems={units}
              validation={validation}
              md={6}
            />
            <NibbleBaseInput
              fieldType="select"
              fieldName="unidad_sat"
              label="Unidad SAT"
              placeHolder="Seleccionar"
              selectItems={satUnits.map(item => ({
                ...item,
                id: item.code,
              }))}
              validation={validation}
              md={6}
              selectOptionLabel={option =>
                `${option.code} - ${option.name} ${
                  option.symbol ? `(${option.symbol})` : ""
                }`
              }
              onInputChange={search => {
                if (!search) return
                fetchSatUnits(search)
              }}
            />
            <NibbleBaseInput
              fieldType="select"
              fieldName="codigo_sat"
              label="Código SAT"
              placeHolder="Seleccionar"
              selectItems={satProducts.map(item => ({
                ...item,
                id: item.code,
              }))}
              validation={validation}
              md={6}
              selectOptionValue={option => option.code}
              selectOptionLabel={option =>
                `${option.code} - ${option.description}`
              }
              onInputChange={search => {
                if (!search) return
                fetchSatProducts(search)
              }}
            />
            <NibbleBaseInput
              fieldType="text"
              fieldName="position"
              label="Orden"
              validation={validation}
              md={6}
            />
          </Row>
          <Taxes validation={validation} TaxObjectFieldName="objeto_imp_sat" />
        </Form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={toggleModal}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          <i className="fas fa-times"></i> Cerrar
        </button>
        <button
          type="submit"
          className="btn btn-danger"
          disabled={request.isLoading}
          form="create-form"
        >
          <i className="fas fa-check"></i> Guardar
        </button>
      </div>
    </Modal>
  )
}

ProductModal.propTypes = {
  isOpen: PropTypes.bool,
  request: PropTypes.object,
  toggleModal: PropTypes.func,
  validation: PropTypes.object,
  dataRow: PropTypes.object,
}

export default ProductModal
