import React from 'react';
import PropTypes from 'prop-types';

const nf = new Intl.NumberFormat('es-MX');

export default function TableTotal({ totals }) {

    const {
        pieces,
        kgs,
        total_vta,
        nota_cargo,
        nota_credito,
        decrease,
        venta_neta,
        mortalidad_percentaje,
        total_vta_percentaje,
        utility,
        utility_percentaje
    } = totals;

    return (
        <>
            <tr className='table-head'>
                <td colSpan={2} className="text-danger f-bold text-right">Totales</td>
                <td className="text-danger f-bold border-b-black text-right">
                    { nf.format(pieces) }
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { nf.format(kgs) }
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { nf.format(total_vta) }
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { nf.format(decrease) }
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { nf.format(nota_credito) }
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { nf.format(nota_cargo) }
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { nf.format(venta_neta) }
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { nf.format(utility) }
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { Math.round(utility_percentaje) }%
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { Math.round(total_vta_percentaje) }%
                </td>
                <td className="text-danger f-bold border-b-black text-right">
                    { nf.format(mortalidad_percentaje) }%
                </td>
            </tr>
            <tr></tr>
        </>
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object
};