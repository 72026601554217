import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment"

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

const format = new Intl.NumberFormat('es-MX'); 


export default function TableMovementItem({ item }) {

    const {
        product,
        c_provider_id,
        mov_date,
        pieces,
        kgs,
        cost,
        pieces_client,
        kgs_client,
        pov_name,
        bal_pieces,
        bal_kgs,
        fecha,
        valuation,
        observaciones
    } = item;

    return (
        <>
            <tr>
                <td className="text-end">
                    {c_provider_id}
                </td>
                <td className="text-end">
                    {pov_name}
                </td>                
                <td className="text-end">
                    {fecha}
                </td>
                <td className="text-end">
                    {product}
                </td>
                <td className="text-end">
                    PZS
                </td>
                <td className="text-right">
                    { format.format(pieces) }
                </td>
                <td className="text-right">
                    { format.format(pieces_client) }
                </td>
                <td className="text-right">
                    { format.format(bal_pieces) }
                </td>
                <td className="text-end">
                    KG
                </td>
                <td className="text-right">
                    { nf2.format(kgs) }
                </td>
                <td className="text-right">
                    { nf2.format(kgs_client) }
                </td>
                <td className="text-right">
                    { nf2.format(bal_kgs) }
                </td>
                <td className="text-right">
                    { nf2.format(cost) }
                </td>
                <td className="text-right">
                    { nf2.format(valuation) }
                </td>
                <td className="text-center">
                    {observaciones}
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object
};