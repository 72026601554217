import React from 'react';
import PropTypes from 'prop-types';

const nf = new Intl.NumberFormat('es-MX');

export default function TableMovementItem({ item }) {

    const {
        skey,
        provider,
        pieces,
        kgs,
        total,
        decrease,
        discount,
        net,
        other_charges,
        total_percentaje,
        total_decrease
    } = item;

    return (
        <>
            <tr>
                <td className="text-center">
                    { skey }
                </td>
                <td className="text-center">
                    { provider }
                </td>
                <td className="text-right">
                    { nf.format(pieces) }
                </td>
                <td className="text-right">
                    { nf.format(kgs) }
                </td>
                <td className="text-right">
                    { nf.format(total) }
                </td>
                <td className="text-right">
                    { nf.format(decrease) }
                </td>
                <td className="text-right">
                    { nf.format(discount) }
                </td>
                <td className="text-right">
                    { nf.format(other_charges) }
                </td>
                <td className="text-right">
                    { nf.format(net) }
                </td>
                <td className="text-right">
                    { nf.format(total_percentaje) }%
                </td>
                <td className="text-right">
                    { nf.format(total_decrease) }%
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object
};