import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import moment from 'moment';

import { 
    Col, 
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle, 
    FormGroup, 
    InputGroup, 
    Label, 
    Row, 
    UncontrolledDropdown
} from 'reactstrap';
import BtnDownloadExcel from '../common/BtnDownloadExcel';
import { getSalesFolioReport } from 'helpers/backend_helper';
import BtnDownloadPdf from '../common/BtnDownloadPdf';
import { monthsFilterValues } from 'common/data/months';
import MonthButton from 'pages/Administrative/common/MonthButton';
import Select from 'react-select';
import { useForm } from 'hooks/useForm';
import { getAccountingConcentratesReport } from '../../../../helpers/backend_helper';

const tipoOptions = [
    {
        id: '1',
        label: 'Clientes',
        value: 'clientes'
    },
    {
        id: '2',
        label: 'Proveedores',
        value: 'proveedores'
    }
];

const header_excel = {
    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

const header_pdf = {
    'Accept': 'application/pdf'
};

const initialPeriod = moment().startOf('month').format('YYYY-MM-DD') + '/' + moment().endOf('month').format('YYYY-MM-DD');

const initForm = {
    periodo: initialPeriod,
    tipo: { id: '', label: 'Clientes', value: 'clientes' },
    cuenta: { id: '', label: '', value: '' }
}

const curYear = moment().year()
const optionsYears = Array(8).fill(0).map((_, i) => { return {id: curYear - i, name: curYear - i} });

export default function Filters({ data, accountingAccounts, handleFilters, config, setPeriod, period, columnOrder }) {

    const {
        column,
        order
    } = columnOrder;

    const {
        form,
        handleSpecificInput
    } = useForm(initForm);

    const handleDatePicker = dates => {
        if(dates.length !== 2) return;

        const parseDates = dates.map( 
                                date => ( moment(date).format('YYYY-MM-DD') )
                            ).join('/');
        
        handleSpecificInput({
            key: 'periodo',
            value: parseDates
        });
        setPeriod(parseDates);
    }

    const downloadFile = (type = 'excel') => getAccountingConcentratesReport ({
        params: {
            period: form.periodo,
            tipo: form.tipo.value,
            cuenta: form.cuenta.id,
            column,
            order
        },
        headers: (type === 'excel') ? header_excel : header_pdf
    });

    const [ year, setYear ] = useState({id: curYear, name: curYear});
    const handleSelectYear = (year) => {
        const dates = period.split('/')
        const start = moment(dates[0])
        const end = moment(dates[1])
        start.year(year)
        end.year(year)
        const parseDates = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
        handleSpecificInput({
            key: 'periodo',
            value: parseDates
        });
        setPeriod(parseDates)
    }

    useEffect(() => {
        handleFilters(form)
    }, [ form.periodo, form.tipo.value, form.cuenta.id, column, order ]);

    return (
        <Row>
            <Col lg={3}>
                <FormGroup className="mb-4">
                    <Label>Periodo</Label>
                    <InputGroup>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder=""
                        onChange={ handleDatePicker }
                        options={{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                            locale: Spanish,
                            defaultDate: initialPeriod.split('/')
                        }}
                    />
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col md={3}>
                <div className="mb-3">
                    <Label>Tipo</Label>
                    <Select                        
                        value={ form.tipo }
                        onChange={ (event) => {
                            handleSpecificInput({
                                key: 'tipo',
                                value: event
                            });
                        } }
                        options={ tipoOptions }
                        getOptionLabel={ option => option.label }
                        getOptionValue={ option => option.value }
                        placeholder="Seleccionar"
                        classNamePrefix="select2-selection"
                    />
                </div>
            </Col>
            <Col md={3}>
                <div className="mb-3">
                    <Label> Cuentas contable</Label>
                    <Select
                        value={ form.cuenta }
                        onChange={ event => {
                            console.log(event)
                            handleSpecificInput({
                                key: 'cuenta',
                                value: event ? event : initForm.cuenta
                            });
                        }}
                        options={ accountingAccounts }
                        getOptionLabel={ option => option.name }
                        getOptionValue={ option => option.id }
                        placeholder="Seleccionar"
                        classNamePrefix="select2-selection"
                        isClearable={ true }
                    />
                </div>
            </Col>
            <Col className="text-right">
            { (data && data.report.length) ? (
                    <>
                        <BtnDownloadExcel downloadExcel={ downloadFile }/>
                        <BtnDownloadPdf downloadPdf={ downloadFile }/>
                    </>
                ) : ''}
            </Col>
            <Col md={12}>
                <UncontrolledDropdown
                    style={{ cursor: 'pointer' }}
                    className="d-inline-block me-1 pt-0 mt-0"
                >
                    <DropdownToggle
                        href="#"
                        className="card-drop action-points"
                        tag="i"
                    > 
                        <button className='btn btn-sm btn-danger'>{ year.name } <i className="mdi mdi-chevron-down"></i>
                        </button>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-enddd actions-menu">
                    { optionsYears.map( (val, key) => { return (
                            <DropdownItem
                                key={key}
                                href="#"
                                onClick={ e => {
                                    e.preventDefault()
                                    setYear({
                                        id: val.id,
                                        name: val.name
                                    })
                                    handleSelectYear(val.id)
                                }}
                            >
                                { val.name }
                            </DropdownItem>
                        )})
                    }
                            
                    </DropdownMenu>
                </UncontrolledDropdown>
                {
                    monthsFilterValues.map( (val, key) => {
                        return (<MonthButton
                            key={key} 
                            month={key}
                            monthString={val}
                            year={year.id}
                            handler={ periodo => {
                                handleSpecificInput({
                                    key: 'periodo',
                                    value: periodo
                                });
                                setPeriod(periodo)
                            }}
                        />)
                    })
                }
            </Col>
        </Row>
    );
}

Filters.propTypes = {
    data: PropTypes.object,
    accountingAccounts: PropTypes.array,
    handleFilters: PropTypes.func,
    config: PropTypes.object,
    setPeriod: PropTypes.func,
    period: PropTypes.string,
    columnOrder: PropTypes.object
};