import React from 'react';
import PropTypes from 'prop-types';

const nf = new Intl.NumberFormat('es-MX');

export default function TableTotal({ totals }) {

    const {
        venta_neta,
        cobro,
        balance_initial,
        balance_finish,
        diff
    } = totals;

    return (
        
            <tr className='table-head'>
                <th colSpan={1} className="text-danger f-bold text-right">Totales</th>
                <th className="text-danger f-bold text-right">
                    { nf.format(balance_initial) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf.format(venta_neta) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf.format(cobro) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf.format(balance_finish) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf.format(diff) }
                </th>
                <th>
                    
                </th>
            </tr>
        
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object
};