import React from 'react';

export default function TableHeader() {
    return (
        <>
            <tr className="report-table__header">
                <th>Folio</th>
                <th>Clave</th>
                <th>Documento</th>
                <th>Almacén</th>
                <th>Agente</th>
                <th className="text-right">Saldo</th>
                <th>Moneda</th>
                <th>F. de alta</th>
                <th>F. de revisión<br/>Contrarecibo</th>
                <th>F. de venc.</th>
            </tr>
        </>
    );
}