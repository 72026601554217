import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"

import { Col, Row, Button, Form, Alert } from "reactstrap"

import { getAdministrativeData } from "store/catalogs/administrative-data/actions"
import useRequest from "hooks/useRequest"

import {
  getMovBankNextFolio,
  getMovByClient,
  getMovByCost,
  getMovByProvider,
  postMovBank,
  putMovBank,
} from "helpers/backend_helper"
import { useFormik } from "formik"
import { bankValidation as validationSchema, initialValues } from "./static"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"

import "flatpickr/dist/themes/material_red.css"

const BankForm = ({ dataRow, isNew, toggleRightCanvas }) => {
  const dispatch = useDispatch()

  const [movClientsList, setMovClientsList] = useState([])
  const [movProvidersList, setMovProvidersList] = useState([])
  const [movCostsList, setMovCostsList] = useState([])

  const { clientAccounts, bankAccounts, providerAccounts, costAccounts } =
    useSelector(state => state.AccountingAccounts)

  const { msgError, isLoading, isSubmitted, req } = useRequest()

  const onSubmit = formValues => {
    console.log({ formValues })

    if (isNew) {
      req(postMovBank(formValues)).then(() => {
        dispatch(getAdministrativeData())
        validation.resetForm()
      })
    } else {
      req(putMovBank(formValues.id, formValues)).then(() => {
        dispatch(getAdministrativeData())
      })
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: isNew ? initialValues : dataRow,
    validationSchema,
    onSubmit,
  })

  const { mov_date, accounting_account_id, accounting_account_type } =
    validation.values

  const onChangeAccountingAccount = option => {
    if (option === null) {
      option = { id: "", type: "" }
    }

    let newFormValues = {
      c_cost_id: null,
      c_client_id: null,
      c_provider_id: null,
      accounting_account_id: option.id,
      accounting_account_type: option.type,
      mov_costs: [],
      mov_clients: [],
      mov_providers: [],
    }

    if (option.type === "client") {
      newFormValues.c_client_id = option.id
    }

    if (option.type === "cost") {
      newFormValues.c_cost_id = option.id
    }

    if (option.type === "provider") {
      newFormValues.c_provider_id = option.id
    }

    validation.setValues({ ...validation.values, ...newFormValues })

    setMovCostsList([])
    setMovClientsList([])
    setMovProvidersList([])
  }

  const getMovList = () => {
    if (!accounting_account_id || !accounting_account_type) return

    if (accounting_account_type === "cost") {
      getMovByCost(accounting_account_id, mov_date).then(movs => {
        setMovCostsList(movs)
      })
    }

    if (accounting_account_type === "provider") {
      getMovByProvider(accounting_account_id, mov_date).then(movs => {
        setMovProvidersList(movs)
      })
    }

    if (accounting_account_type === "client") {
      getMovByClient(accounting_account_id, mov_date).then(movs => {
        setMovClientsList(movs)
      })
    }
  }

  const getNextFolio = () => {
    async function getDataFolio() {
      const response = await getMovBankNextFolio()
      validation.setFieldValue("folio", response.folio)
    }

    if (isNew) {
      getDataFolio()
    }
  }

  useEffect(getNextFolio, [])
  useEffect(getMovList, [
    mov_date,
    accounting_account_id,
    accounting_account_type,
  ])

  console.log({
    formikValues: validation.values,
    errors: validation.errors,
  })

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        validation.handleSubmit()
        return false
      }}
    >
      {msgError ? <Alert className="alert alert-danger">{msgError}</Alert> : ""}
      {isSubmitted ? (
        <Alert className="alert alert-success">
          {isNew ? "Registro creado" : "Registro actualizado"}
        </Alert>
      ) : (
        ""
      )}
      <Row>
        <NibbleBaseInput
          fieldType="select"
          fieldName="c_bank_id"
          label="Banco *"
          selectItems={bankAccounts}
          validation={validation}
          selectOptionLabel={option => option.name}
          selectOptionValue={option => option.id}
          md={6}
          placeHolder="Seleccionar"
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName="accounting_account_id"
          label="Cliente/Proveedor/Gastos"
          selectItems={[
            ...clientAccounts,
            ...providerAccounts,
            ...costAccounts,
          ]}
          validation={validation}
          selectOptionLabel={option => option.name}
          selectOptionValue={option => option.id}
          md={6}
          placeHolder="Seleccionar"
          onChange={onChangeAccountingAccount}
        />
      </Row>
      <Row>
        <NibbleBaseInput
          fieldType="multi-select"
          fieldName="mov_clients"
          label="Ventas del cliente"
          selectOptionLabel={option =>
            `${option.mov_date} - F${option?.folio ? option?.folio : ""} - $${
              option.total_vta
            }`
          }
          selectItems={movClientsList}
          validation={validation}
          md={12}
          placeHolder="Seleccionar"
        />
        <NibbleBaseInput
          fieldType="multi-select"
          fieldName="mov_providers"
          label="Cobros"
          selectOptionLabel={option =>
            `${option.mov_date} - F${option?.folio ? option?.folio : ""} - $${
              option.net
            }`
          }
          selectItems={movProvidersList}
          validation={validation}
          md={12}
          placeHolder="Seleccionar"
        />
        <NibbleBaseInput
          fieldType="multi-select"
          fieldName="mov_costs"
          label="Gastos"
          selectOptionLabel={option =>
            `${option.date} - F${option?.folio} - $${option.total}`
          }
          selectItems={movCostsList}
          validation={validation}
          md={12}
          placeHolder="Seleccionar"
        />
      </Row>
      <Row>
        <NibbleBaseInput
          fieldType="date"
          fieldName="mov_date"
          label="Fecha"
          validation={validation}
          md={6}
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName="description"
          label="Descripción"
          validation={validation}
          md={6}
        />
      </Row>
      <Row>
        <NibbleBaseInput
          fieldType="number"
          fieldName="deposit"
          label="Depósito *"
          validation={validation}
          md={6}
        />
        <NibbleBaseInput
          fieldType="number"
          fieldName="payment"
          label="Pago *"
          validation={validation}
          md={6}
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName="folio"
          label="Folio"
          validation={validation}
          md={6}
          disabled
        />
      </Row>
      <Row>
        <NibbleBaseInput
          fieldType="text"
          fieldName="observation"
          label="Observaciones *"
          validation={validation}
          md={12}
        />
      </Row>
      <Row>
        <Col md={12}>
          <p>
            <strong>*</strong> Campos obligatorios
          </p>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            onClick={toggleRightCanvas}
            color="secondary"
            className="btn btn-secondary btn-sm"
            style={{ marginRight: "10px" }}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            className="btn btn-danger btn-sm"
            disabled={isLoading}
            type="submit"
          >
            <i
              className={`${
                isLoading ? "" : "d-none"
              } bx bx-loader bx-spin font-size-16 align-middle me-2`}
            ></i>
            {isLoading ? "Guardando" : "Guardar"}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

BankForm.propTypes = {
  dataRow: PropTypes.object,
  toggleRightCanvas: PropTypes.func,
  isNew: PropTypes.bool,
}

export default BankForm

/*
useEffect(() => {
        
        if(!form.client_account?.id) return;

        if(isNew) handleSpecificInput({ key: 'mov_clients', value: [] });
        
        getMovByClient(form.client_account.id, form.mov_date).then(movimientos => {            
            setMovClientsList(movimientos);
        });
    }, [ form.client_account ? form.client_account.id:'', form.mov_date ]);
 */
