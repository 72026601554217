import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useLocation } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser } from "../../store/actions"

// import images
import logoImg from "assets/images/logo-cresta.png"
import { getMe } from "helpers/backend_helper"
import { redirectUserToHome } from '../../helpers/login';


const Login = props => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const dispatch = useDispatch();

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
  }

  useEffect(() => {
    if(!token) return;

    getMe({
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(user => {
      localStorage.setItem("authUser", JSON.stringify({ token, user }))
      redirectUserToHome(user, props.history)
      const userType= user.user?.type || user.type
      props.history.push(userType == 2 ? "/provider/daily": (userType == 3 ? "/client/daily": 'dashboard'))
    });
  }, [])
  

  return (
    <React.Fragment>
      <MetaTags>
        <title>Cresta - Ingresar</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-warning bg-soft">
                  <Row>
                    <Col className="col-12">
                      <div className="text p-4 text-center">
                        <img
                            src={logoImg}
                            alt=""
                            height="55"
                          />
                        <p className="pt-4">Ingresar a Plataforma.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Correo"
                          value=""
                          className="form-control"
                          placeholder=""
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Contraseña"
                          value=""
                          type="password"
                          required
                          placeholder=""
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Recordarme
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-warning btn-block"
                          type="submit"
                        >
                          Ingresar
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          ¿Olvidaste tu password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center d-none">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
