import React from 'react';

export default function TableTotal() {
    return (
        <>
            <tr>
                <td colSpan={6} className="text-right f-bold">Total por fecha en M.N.</td>
                <td className="text-right">395,931.83</td>
            </tr>
            <tr className="report-table__col-border--white">
                <td colSpan={6} className="text-right f-bold">Total en M.N.</td>
                <td className="f-blue f-bold text-right bg-gray">2,172,093.13</td>
            </tr>
        </>
    );
}
