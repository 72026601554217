import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import Select from "react-select"
import Flatpickr from "react-flatpickr"
import { isEmpty } from "lodash"
import { Spanish } from "flatpickr/dist/l10n/es.js"

import { Col, Row, Label, Button, Form, Input, Alert } from "reactstrap"

import { useForm } from "hooks/useForm"
import useRequest from "hooks/useRequest"

import "flatpickr/dist/themes/material_red.css"
import moment from "moment"
import { postAjustament } from "helpers/backend_helper"
import { InputGroup } from "reactstrap"
import { getAdministrativeData } from "store/catalogs/administrative-data/actions"
const nf = new Intl.NumberFormat("es-MX")

const todayDate = moment().format("YYYY-MM-DD")

const initForm = {
  kgs_provider: "0.00",
  pieces_provider: "0",
  pieces_diff: "0.00",
  kgs_client: "0.00",
  pieces_client: "0",
  kgs_diff: "0.00",
  cost: "0.00",
  date: todayDate,
  observations: "",
}

const AjustForm = ({
  isNew = true,
  toggleRightCanvas,
  handleFilters,
  dataRow,
}) => {
  const dispatch = useDispatch()
  const [formErr, setFormErr] = useState({})

  const dataROW = isNew ? {} : { ...dataRow }
  console.log("isNew", isNew, dataRow)
  const {
    form,
    handleInputChange,
    handleDatePicker,
    handleSpecificInput,
    validateNumeric,
    resetForm,
  } = useForm({ ...initForm, ...dataROW })

  const { isError, isLoading, isSubmitted, msgError, req } = useRequest()

  const handleSubmit = e => {
    e.preventDefault()

    if (!isEmpty(formErr)) return
    //if(isNew){
    req(postAjustament(form)).then(async () => {
      //handleFilters();
      dispatch(getAdministrativeData())
      //resetForm()
    })
    /*}else{
            req(putCost(form.id, formSend)).then(() => {
                dispatch(getAdministrativeData());
            });
        }*/
  }

  useEffect(() => {
    handleSpecificInput({
      key: "pieces_diff",
      value: (form.pieces_provider ?? 0) - (form.pieces_client ?? 0),
    })
  }, [form.pieces_provider, form.pieces_client])

  useEffect(() => {
    handleSpecificInput({
      key: "kgs_diff",
      value: (form.kgs_provider ?? 0) - (form.kgs_client ?? 0),
    })
  }, [form.kgs_provider, form.kgs_client])

  useEffect(() => {
    
    handleSpecificInput({
      key: "adjust_kgs",
      value: (form.adjust_pieces ?? 0) * (form.prom ?? 0),
    })
  }, [form.adjust_pieces])

  useEffect(() => {
    handleSpecificInput({
      key: "adjust_import",
      value: (form.adjust_kgs ?? 0) * (form.cost ?? 0),
    })
  }, [form.adjust_kgs])

  const totalPromedio = () => {
    let total = Number(form.kgs_provider) / Number(form.pieces_provider)
    total = total ? Math.round(total * 100) / 100 : 0
    return total ? total : 0
  }

  const totalPromedioCliente = () => {
    let total = Number(form.kgs_client) / Number(form.pieces_client)
    total = total ? Math.round(total * 100) / 100 : 0
    return total ? total : 0
  }

  const diffPieces =
    form?.provider_pieces - form?.client_pieces - form?.adjust_pieces
  const diffKgs = form?.provider_kgs - form?.client_kgs - form?.adjust_kgs
  const adjustImport = form?.diffKgs * form?.cost

  console.log("form", form)
  return (
    <>
      <Form onSubmit={handleSubmit}>
        {isError ? (
          <Alert className="alert alert-danger">{msgError}</Alert>
        ) : (
          ""
        )}
        {isSubmitted ? (
          <Alert className="alert alert-success">
            Operación completada correctamente
          </Alert>
        ) : (
          ""
        )}
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Fecha *</Label>
              <Flatpickr
                value={form.date ? form.date : ""}
                className="form-control d-block"
                placeholder="dd M,yyyy"
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d",
                  locale: Spanish,
                }}
                onChange={dates => handleDatePicker(dates, "date")}
                disabled
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Proveedor No pzas</Label>
              <Input
                value={form.provider_pieces ? form.provider_pieces : ""}
                onChange={e => {
                  setFormErr(validateNumeric(e, formErr))
                }}
                name="provider_pieces"
                type="text"
                readOnly={true}
                className={
                  formErr?.provider_pieces
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
              <div className="invalid-feedback">
                {formErr?.provider_pieces ? formErr.provider_pieces : ""}
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Cliente No pzas</Label>
              <Input
                value={form.client_pieces ? form.client_pieces : ""}
                onChange={e => {
                  setFormErr(validateNumeric(e, formErr))
                }}
                name="client_pieces"
                type="text"
                readOnly={true}
                className={
                  formErr?.client_pieces
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
              <div className="invalid-feedback">
                {formErr?.client_pieces ? formErr.client_pieces : ""}
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Ajuste piezas</Label>
              <InputGroup style={{ zIndex: 0 }}>
                <Input
                  value={form.adjust_pieces}
                  onChange={e => {
                    setFormErr(validateNumeric(e, formErr))
                  }}
                  name="adjust_pieces"
                  type="number"
                  className={
                    formErr?.adjust_pieces
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                <div className="invalid-feedback">
                  {formErr?.adjust_pieces ? formErr.adjust_pieces : ""}
                </div>
              </InputGroup>
            </div>
          </Col>

          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Diferencias</Label>
              <InputGroup style={{ zIndex: 0 }}>
                <Input
                  value={diffPieces}
                  onChange={e => {
                    setFormErr(validateNumeric(e, formErr))
                  }}
                  name="diff_pieces"
                  type="number"
                  readOnly={true}
                  className={
                    formErr?.diff_pieces
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                <div className="invalid-feedback">
                  {formErr?.diff_pieces ? formErr.diff_pieces : ""}
                </div>
              </InputGroup>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Proveedor Kgs</Label>
              <Input
                value={form.provider_kgs ? form.provider_kgs : ""}
                onChange={e => {
                  setFormErr(validateNumeric(e, formErr))
                }}
                name="provider_kgs"
                type="text"
                readOnly={true}
                className={
                  formErr?.provider_kgs
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
              <div className="invalid-feedback">
                {formErr?.provider_kgs ? formErr.provider_kgs : ""}
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Cliente Kgs</Label>
              <Input
                value={form.client_kgs ? form.client_kgs : ""}
                onChange={e => {
                  setFormErr(validateNumeric(e, formErr))
                }}
                name="client_kgs"
                type="text"
                readOnly={true}
                className={
                  formErr?.client_kgs
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
              <div className="invalid-feedback">
                {formErr?.client_kgs ? formErr.client_kgs : ""}
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Ajuste kgs</Label>
              <InputGroup style={{ zIndex: 0 }}>
                <Input
                  value={form.adjust_kgs}
                  onChange={e => {
                    setFormErr(validateNumeric(e, formErr))
                  }}
                  name="adjust_kgs"
                  type="number"
                  className={
                    formErr?.adjust_kgs
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                <div className="invalid-feedback">
                  {formErr?.adjust_kgs ? formErr.adjust_kgs : ""}
                </div>
              </InputGroup>
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Diferencias</Label>
              <InputGroup style={{ zIndex: 0 }}>
                <Input
                  value={diffKgs}
                  onChange={e => {
                    setFormErr(validateNumeric(e, formErr))
                  }}
                  name="diff_kgs"
                  type="number"
                  readOnly={true}
                  className={
                    formErr?.diff_kgs
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                <div className="invalid-feedback">
                  {formErr?.diff_kgs ? formErr.diff_kgs : ""}
                </div>
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Promedio</Label>
              <Input
                value={form.prom ? form.prom : ""}
                onChange={e => {
                  setFormErr(validateNumeric(e, formErr))
                }}
                name="prom"
                type="text"
                className={
                  formErr?.prom ? "form-control is-invalid" : "form-control"
                }
                disabled
              />
              <div className="invalid-feedback">
                {formErr?.prom ? formErr.prom : ""}
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Precio unitario</Label>
              <Input
                value={form.cost ? form.cost : ""}
                onChange={e => {
                  setFormErr(validateNumeric(e, formErr))
                }}
                name="cost"
                type="text"
                className={
                  formErr?.cost ? "form-control is-invalid" : "form-control"
                }
                disabled
              />
              <div className="invalid-feedback">
                {formErr?.cost ? formErr.cost : ""}
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Importe Ajuste</Label>
              <InputGroup style={{ zIndex: 0 }}>
                <Input
                    disabled
                  value={form.adjust_import}
                  onChange={e => {
                    setFormErr(validateNumeric(e, formErr))
                  }}
                  name="adjust_import"
                  type="number"
                  className={
                    formErr?.adjust_import
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                <div className="invalid-feedback">
                  {formErr?.adjust_import ? formErr.adjust_import : ""}
                </div>
              </InputGroup>
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Observaciones</Label>
              <Input
                value={form.adjust_comment ? form.adjust_comment : ""}
                onChange={handleInputChange}
                name="adjust_comment"
                type="text"
                maxLength={255}
                className={
                  formErr?.adjust_comment
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
              <div className="invalid-feedback">
                {formErr?.adjust_comment ? formErr.adjust_comment : ""}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <p>
              <strong>*</strong> Campos obligatorios
            </p>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              onClick={toggleRightCanvas}
              color="secondary"
              className="btn btn-secondary btn"
              style={{ marginRight: "10px" }}
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              className="btn btn-danger btn"
              disabled={isLoading}
              type="submit"
            >
              <i
                className={`${
                  isLoading ? "" : "d-none"
                } bx bx-loader bx-spin font-size-16 align-middle me-2`}
              ></i>
              {isLoading ? "Guardando" : "Guardar"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

AjustForm.propTypes = {
  toggleRightCanvas: PropTypes.func,
  handleFilters: PropTypes.func,
  dataRow: PropTypes.object,
  isNew: PropTypes.bool,
  dataRow: PropTypes.object,
}

export default AjustForm
