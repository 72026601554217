import { getAdministrativeData } from "helpers/backend_helper";
import { call, put, select, takeEvery } from "redux-saga/effects"
import { getAdministrativeDataSuccess } from "./actions";
import { GET_ADMINISTRATIVE_DATA } from "./actionsTypes";

/*
 * Selector. The query depends by the state shape
 */
export const getParams = ({ AdministrativeData: { filters } }) => ({
  page:   filters.page,
  search: filters.search,
  client: filters.client.id,
  provider: filters.provider.id,
  provider_client: filters.provider_client.id,
  dates: filters.dates,
  bank: filters.bank.id,
  payment: filters.payment.id,
  mostrar: filters.mostrar.name,
  column: filters.column,
  order: filters.order,
  year: filters.year,
  banco: filters.banco.name,
  search_qty: filters.search_qty,
  product: filters.product.id,
  sucursal: filters.sucursal.id,
  payment_type: filters.payment_type.id,
  account_id: filters.account_id.id,
});

export const getUrlReport = ({ AdministrativeData }) => {
  const activeTab =  AdministrativeData.activeTab;
  const dataReports = AdministrativeData.dataReports;
  const { url_data } = dataReports[activeTab];
  
  return url_data;
}

function* fetchAdministrativeData(){

    try {
        const params = yield select(getParams);
        const url = yield select(getUrlReport);
        
        const response = yield call(getAdministrativeData, url, { params });

        if(response)
          yield put( getAdministrativeDataSuccess(response) );
    } catch (error) {
        console.log(error);
    }
}

function* administrativeDataSaga() {
  yield takeEvery(GET_ADMINISTRATIVE_DATA, fetchAdministrativeData);
}

export default administrativeDataSaga;