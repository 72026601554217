import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { invoiceValidation as validationSchema } from "./static"
import { useDispatch } from "react-redux"
import * as _ from "lodash"
import {
  getInvoiceType,
  getPaymentForm,
  getPaymentMethod,
  getTaxRegime,
  getUseCfdi,
} from "store/sat/actions"
import {
  getClientDetail,
  getEnterprise,
  postMovClientCfdi,
  postNextInvoiceFolio,
} from "helpers/backend_helper"
import getStorageUser from "helpers/api_helper"
import {
  fillCfdiGeneralData,
  fillCfdiIssuer,
  fillCfdiReceiver,
} from "./helpers"
import { initProduct } from "./components/product-modal/static"
import useRequest from "hooks/useRequest"
import { getAdministrativeData } from "store/catalogs/administrative-data/actions"
import {
  getMovClientCfdiProduct,
  getOtherChargesCfdiProduct,
  getOtherDiscountsCfdiProduct,
} from "./actions"

const useInvoiceMovClient = ({ movClient, validation }) => {
  const user = getStorageUser()

  const [isOpenReceiverModal, setIsOpenReceiverModal] = useState(false)

  const {
    client_account: { related = { id: "" } }, // id empresa del cliente
  } = movClient

  const toggleReceiverModal = () => {
    setIsOpenReceiverModal(!isOpenReceiverModal)
  }

  const fillData = () => {
    const newData = fillCfdiGeneralData(movClient)

    validation.setValues({
      ...validation.values,
      ...newData,
    })
  }

  const fetchClient = () => {
    if (!related?.id) return

    getClientDetail(related.id).then(enterprise => {
      fillCfdiReceiver(enterprise, validation)
    })
  }

  const preloadInvoiceProducts = () => {
    if (validation.values.CfdiType === "P") return

    const fillProducts = async () => {
      const Items = []

      const movClientProduct = await getMovClientCfdiProduct(movClient)
      const otherChargesProduct = await getOtherChargesCfdiProduct(movClient)
      const otherDiscountsProduct = await getOtherDiscountsCfdiProduct(
        movClient
      )

      if (movClientProduct) {
        Items.push(movClientProduct)
      }

      if (otherChargesProduct) {
        Items.push(otherChargesProduct)
      }

      if (otherDiscountsProduct) {
        Items.push(otherDiscountsProduct)
      }

      validation.setFieldValue("Items", Items)
    }

    fillProducts()
  }

  useEffect(fillData, [])
  useEffect(fetchClient, [related?.id])
  useEffect(preloadInvoiceProducts, [
    movClient.product,
    movClient.nota_cargo,
    movClient.nota_credito,
  ])

  return {
    isOpenReceiverModal,
    toggleReceiverModal,
  }
}

export default useInvoiceMovClient
