import React, { useEffect } from "react"
import PropTypes from "prop-types"

import { Modal, Row, Col, Label, Input } from "reactstrap"

import "./custom.scss"
import { useState } from "react"

function ModalEmail({
  isOpen,
  setOpenModal,
  onSendEmail,
  isSending,
  title = "Enviar",
}) {
  const [email, setEmail] = useState("")

  const onSubmit = e => {
    e.preventDefault()

    onSendEmail(email)
  }

  useEffect(() => {
    setEmail("")
  }, [isOpen])

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setOpenModal(false)
        }}
        size="sm"
      >
        <form onSubmit={onSubmit}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {title}
            </h5>
          </div>
          <div className="modal-body text-center">
            <Row>
              <Col md={12}>
                <Label
                  htmlFor="form row-email-Input"
                  className="text-start d-block"
                >
                  Correo electrónico:
                </Label>
                <Input
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  name="email"
                  type="email"
                  placeholder="Ingresa el correo electrónico"
                  required
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              disabled={isSending}
            >
              <i
                className={`${
                  !isSending && "d-none"
                } bx bx-loader bx-spin font-size-16 align-middle me-2`}
              ></i>
              {isSending ? "Enviando..." : "Enviar"}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenModal(false)
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

ModalEmail.propTypes = {
  isOpen: PropTypes.bool,
  isSending: PropTypes.bool,
  setOpenModal: PropTypes.func,
  onSendEmail: PropTypes.func,
  title: PropTypes.string,
}

export default ModalEmail
