import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from 'react-router-dom';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useState } from "react";
import { useRef } from "react";
import { PropTypes } from 'prop-types';
import { Alert } from "reactstrap";
import { getRegions, postClient, postDelivery } from "helpers/backend_helper";
import { i18n } from 'i18next';
import Select from 'react-select';
import AddressForm from 'pages/Catalogos/common/AddressForm';

const ClientsDeliveriesFormCreate = (props) => {
  const [model, setModel] = useState (props.provider)
  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [regions, setRegions] = useState ([])

  const handleValidSubmit = async (event, values) => {
    console.log(event)
    console.log(values)
    setBtnSaveLoad(true)
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      console.log('user', obj.user);
      const response = await postDelivery(obj.user.enterprise_id, {...values, region_id: selectedRegion})
      setModel(response)
      setError(null)
      setCompleted('Se completo la operación')
      console.log(refForm);
      refForm && refForm.current.reset();
    } catch(error) {
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  /*
  useEffect(() => {
    async function getData()  {
      //setLoading(true);
      const response = await getRegions()
      //setLoading(false);
      setRegions(response.map( e => { return {label: e.name, value: e.id} } ))
    }

    getData();
  }, []);
  */

  const handleSelectChangeRegion = async (option) => {
    setSelectedRegion(option.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Direcciones de entrega</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Cliente" breadcrumbItem="Direcciones de Entrega" breadcrumbSubItem="Crear">
            <Breadcrumbs breadcrumbItem="Crear" />
          </Breadcrumbs>
          
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? <Alert color="success">{completed}</Alert> : null}

                    <AddressForm entityAddress={{}}></AddressForm>

                    <div className="text-center">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> Guardar
                      </button>
                      <Link to="/client/deliveries" className="btn w-md btn-sm btn-danger ms-2">
                        <i className="fas fa-reply"></i> Atrás
                      </Link>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

ClientsDeliveriesFormCreate.propTypes = {
  provider: PropTypes.object
};

export default ClientsDeliveriesFormCreate
