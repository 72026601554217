import React, { useEffect, useState, useRef, } from "react"
import MetaTags from 'react-meta-tags';
import Select from 'react-select';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from 'react-router-dom';

import { AvField, AvForm } from "availity-reactstrap-validation";
import { getEnterprises, postUserInternals } from "helpers/backend_helper";
import { PropTypes } from 'prop-types';
import BreadcrumbNav from "components/Common/BreadcrumbNav";

const breadcrumbItems = [
  { id:'1', title: 'Catálogos', link: '', active: false },
  { id:'2', title: 'Usuarios Internos', link: '/catalogs/users-internals', active: false },
  { id:'3', title: 'Crear', link: '', active: true }
];

const CatalogUserInternalFormCreate = (props) => {
  const [selectedRol, setSelectedRol] = useState(null);
  const [user, setUser] = useState (props.user)
  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')

  const validateProvider = (option) => {
    if (selectedRol) return true;
    throw new Error('Debe completar el campo de rol');
  }

  const handleValidSubmit = async (event, values) => {
    try {
      validateProvider()
      setBtnSaveLoad(true)
      const response = await postUserInternals({...values, type_rol: selectedRol})
      setUser(response)
      setError(null)
      setCompleted('Se completo la operación')
      console.log(refForm);
      refForm && refForm.current.reset();
    } catch(error) {
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const handleSelectChangeRol = (option) => {
    setSelectedRol(option.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de Usuarios</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Usuarios"  items={breadcrumbItems}/>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                      ref={refForm}
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      {completed ? <Alert color="success">{completed}</Alert> : null}
                      
                    <div className="mb-3">
                      <AvField
                          name="name"
                          label="Nombre"
                          value=""
                          type="text"
                          required
                          errorMessage="Campo requerido"
                          placeholder=""
                        />
                    </div>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            value=""
                            type="email"
                            required
                            errorMessage="Campo requerido"
                            placeholder=""
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            value=""
                            type="password"
                            required
                            errorMessage="Campo requerido"
                            placeholder=""
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Rol</Label>
                        <Select
                          id="formrow-InputState"
                          options={[{label:'Super',value:1}, {label:'Administrativo',value:2}, {label:'Administrativo Lectura',value:3}]}
                          onChange={handleSelectChangeRol}
                        />
                      </div>
                    </Row>

                    <div className="text-center">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> Guardar
                      </button>
                      <Link to="/catalogs/users-internals" className="btn w-md btn-sm btn-danger ms-2">
                        <i className="fas fa-reply"></i> Atrás
                      </Link>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

CatalogUserInternalFormCreate.propTypes = {
  user: PropTypes.object
};

export default CatalogUserInternalFormCreate
