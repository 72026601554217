import PropTypes from "prop-types"
import { Alert, Row } from "reactstrap"
import React, { useState, useEffect } from "react"

import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import { calculateComplementAmounts } from "../calculations"

const ComplementItem = ({ index, validation, ppdInvoices, isLoading }) => {
  const [invoice, setInvoice] = useState()

  const { Receiver, Complemento } = validation.values
  const { Amount, RelatedDocuments } = Complemento

  const error = RelatedDocuments[index].Error
  const amountPaid = RelatedDocuments[index].AmountPaid
  const uuidInvoice = RelatedDocuments[index].Uuid

  const disableAmountPaidInput = !Amount || !uuidInvoice

  const saveInvoiceSelected = () => {
    const invoiceFound = ppdInvoices?.find(
      invoice => invoice.uuid === uuidInvoice
    )

    if (invoiceFound) {
      setInvoice(invoiceFound)

      const partialityNumber = invoiceFound.complements.length
        ? invoiceFound.complements.length + 1
        : 1

      validation.setFieldValue(
        `Complemento.RelatedDocuments[${index}].Folio`,
        invoiceFound?.folio
      )
      validation.setFieldValue(
        `Complemento.RelatedDocuments[${index}].Serie`,
        invoiceFound?.serie
      )
      validation.setFieldValue(
        `Complemento.RelatedDocuments[${index}].PartialityNumber`,
        partialityNumber
      )
    }
  }

  const calculateAmounts = () => {
    if (!invoice) return

    const { ImpSaldoInsoluto, PreviousBalanceAmount } =
      calculateComplementAmounts(invoice, amountPaid)

    let errorMessage = false
    if (ImpSaldoInsoluto < 0) {
      errorMessage = "El importe del saldo insoluto no debe ser menor a 0"
    }

    validation.setFieldValue(
      `Complemento.RelatedDocuments[${index}].PreviousBalanceAmount`,
      PreviousBalanceAmount
    )
    validation.setFieldValue(
      `Complemento.RelatedDocuments[${index}].ImpSaldoInsoluto`,
      ImpSaldoInsoluto
    )
    validation.setFieldValue(
      `Complemento.RelatedDocuments[${index}].Error`,
      errorMessage
    )
  }

  useEffect(calculateAmounts, [invoice, amountPaid])
  useEffect(saveInvoiceSelected, [ppdInvoices, uuidInvoice])

  return (
    <>
      {error ? <Alert color="danger">{error}</Alert> : null}
      <Row>
        <NibbleBaseInput
          fieldType="select"
          fieldName={`Complemento.RelatedDocuments[${index}].Uuid`}
          label="UUID"
          selectItems={ppdInvoices.map(item => ({
            ...item,
            id: item.uuid,
          }))}
          validation={validation}
          selectOptionValue={option => option.uuid}
          selectOptionLabel={option =>
            `$${option.total} - ${option.serie} - ${option.folio} - ${option.uuid}`
          }
          md={12}
          placeHolder="Seleccionar"
          isLoading={isLoading}
          disabled={!Receiver?.Rfc}
        />

        <NibbleBaseInput
          fieldType="text"
          fieldName={`Complemento.RelatedDocuments[${index}].PreviousBalanceAmount`}
          label="Monto previo"
          validation={validation}
          md={4}
          readOnly
        />
        <NibbleBaseInput
          fieldType="number"
          fieldName={`Complemento.RelatedDocuments[${index}].AmountPaid`}
          label="Monto pagado"
          validation={validation}
          md={4}
          readOnly={disableAmountPaidInput}
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName={`Complemento.RelatedDocuments[${index}].ImpSaldoInsoluto`}
          label="Importe Saldo Insoluto"
          validation={validation}
          md={4}
          readOnly
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName={`Complemento.RelatedDocuments[${index}].PartialityNumber`}
          label="# Parcialidad"
          validation={validation}
          md={4}
          readOnly
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName={`Complemento.RelatedDocuments[${index}].Serie`}
          label="Serie"
          validation={validation}
          md={4}
          readOnly
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName={`Complemento.RelatedDocuments[${index}].Folio`}
          label="Folio"
          validation={validation}
          md={4}
          readOnly
        />
      </Row>
    </>
  )
}

ComplementItem.propTypes = {
  index: PropTypes.number,
  isLoading: PropTypes.bool,
  validation: PropTypes.object,
  ppdInvoices: PropTypes.array,
}

export default ComplementItem
