import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import moment from 'moment';

import { 
    Col, 
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle, 
    FormGroup, 
    InputGroup, 
    Label, 
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import BtnDownloadExcel from '../common/BtnDownloadExcel';

import BtnDownloadPdf from '../common/BtnDownloadPdf';
import { monthsFilterValues } from 'common/data/months';
import MonthButton from 'pages/Administrative/common/MonthButton';
import Select from 'react-select';
import { useForm } from 'hooks/useForm';
import { getInventoryReport } from '../../../../helpers/backend_helper';
import { useSelector } from 'react-redux';


const header_excel = {
    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

const header_pdf = {
    'Accept': 'application/pdf'
};

const initialPeriod = moment().startOf('month').format('YYYY-MM-DD') + '/' + moment().endOf('month').format('YYYY-MM-DD');

const initForm = {
    periodo: initialPeriod,
    providers: [],
    product: { id: "", name: "Todos" }
}

const curYear = moment().year()
const optionsYears = Array(8).fill(0).map((_, i) => { return {id: curYear - i, name: curYear - i} });

export default function Filters({ data, handleFilters, setPeriod, columnOrder, period,toggleRightCanvas,setIsNew }) {

    const providerAccounts = useSelector(state => state.AccountingAccounts.providerAccounts);

    const {
        column,
        order
    } = columnOrder;

    const {
        form,
        handleSpecificInput,
        handleDatePeriod
    } = useForm(initForm);

    const providersIds = form.providers.map(provider => provider.id).join();

    const downloadFile = (type = 'excel') => getInventoryReport({
        params: {
            period: form.periodo,
            providers: providersIds,
            column,
            order
        },
        headers: (type === 'excel') ? header_excel : header_pdf
    });

    useEffect(() => {
        handleFilters({
            ...form,
            providers: providersIds,
            product: form.product.id
        })
    }, [ form.periodo, form.providers, form.product.id, column, order ]);

    const [ year, setYear ] = useState({id: curYear, name: curYear});
    const handleSelectYear = (year) => {
        const dates = period.split('/')
        const start = moment(dates[0])
        const end = moment(dates[1])
        start.year(year)
        end.year(year)
        const parseDates = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
        handleSpecificInput({
            key: 'periodo',
            value: parseDates
        });
        setPeriod(parseDates)
    }


    return (
        <>
            <Row>
                <Col md='auto'>
                    <div className="w-100 mt-1">
                        <Label className="small fw-bold p-0 m-0 d-block">Año - Mes</Label>
                        <UncontrolledDropdown
                            style={{ cursor: 'pointer' }}
                            className="d-inline-block me-1 pt-0 mt-0"
                        >
                            <DropdownToggle
                                href="#"
                                className="card-drop action-points"
                                tag="i"
                            > 
                                <button className='btn btn-sm btn-danger'>{ year.name } <i className="mdi mdi-chevron-down"></i>
                                </button>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-enddd actions-menu">
                            { optionsYears.map( (val, key) => { return (
                                    <DropdownItem
                                        key={key}
                                        href="#"
                                        onClick={ e => {
                                            e.preventDefault()
                                            setYear({
                                                id: val.id,
                                                name: val.name
                                            })
                                            handleSelectYear(val.id)
                                        }}
                                    >
                                        { val.name }
                                    </DropdownItem>
                                )})
                            }
                                    
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {
                            monthsFilterValues.map( (val, key) => {
                                return (<MonthButton
                                    key={key} 
                                    month={key}
                                    monthString={val}
                                    year={year.id}
                                    handler={ periodo => {
                                        handleSpecificInput({
                                            key: 'periodo',
                                            value: periodo
                                        });
                                        setPeriod(periodo)
                                    }}
                                />)
                            })
                        }
                    </div>
                </Col>
                <Col lg={3}>
                    <FormGroup className="mb-4">
                        <Label className='small fw-bold p-0 m-0'>Periodo</Label>
                        <InputGroup>
                        <Flatpickr
                            className="form-control d-block"
                            placeholder=""
                            onChange={ dates => handleDatePeriod({
                                key: 'periodo',
                                dates
                            })}
                            options={{
                                mode: 'range',
                                dateFormat: 'Y-m-d',
                                locale: Spanish,
                                defaultDate: initialPeriod.split('/')
                            }}
                        />
                        </InputGroup>
                    </FormGroup>
                </Col>
                    <Col className='text-right'>
                
                    <UncontrolledDropdown
                                style={{ cursor: 'pointer' }}
                            >
                            { (data && data.report.length) ? (
                        <>
                            <BtnDownloadExcel downloadExcel={ downloadFile }/>
                            <BtnDownloadPdf downloadPdf={ downloadFile }/> &nbsp;
                        </>
                    ) : ''}        
                    </UncontrolledDropdown>
                    
                </Col>
            </Row>
            <Row className='mt-1'>
                <Col lg={3}>
                    <div className="mb-3">
                        <label className="control-label small fw-bold p-0 m-0">
                            Proveedores
                        </label>
                        <Select
                            value={form.providers}
                            isMulti={true}
                            onChange={(value) => handleSpecificInput({
                                key: 'providers',
                                value
                            })}
                            getOptionLabel={ option => option.name}
                            getOptionValue={ option => option.id }
                            placeholder="Seleccionar"
                            options={providerAccounts}
                            classNamePrefix="select2-selection"
                        />
                    </div>
                </Col>
                <Col md={3}>
                    <div className="w-100">
                    <Label className="small fw-bold p-0 m-0">Producto</Label>
                    <Select
                        value={form.product}
                        onChange={event => {
                        const defaultSel = { id: "", name: "Todos" }

                        handleSpecificInput({
                            key: "product",
                            value: event ? event : defaultSel,
                        })
                        }}
                        options={[{ id: "", name: "Todos" }, ...(data?.selects?.products ? data.selects.products : [])]}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        placeholder=""
                        classNamePrefix="select2-selection"
                        isClearable={true}
                    />
                    </div>
                </Col>
            </Row>
        </>
    );
}

Filters.propTypes = {
    data: PropTypes.any,
    setIsNew: PropTypes.any,
    handleFilters: PropTypes.func,
    setPeriod: PropTypes.func,
    toggleRightCanvas: PropTypes.func,
    columnOrder: PropTypes.object,
    period: PropTypes.string
};