import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Alert,
  Spinner,
  Label,
  Modal,
  Button,
  Nav,
  NavItem,
  NavLink
} from "reactstrap"
import * as _ from 'lodash';

import 'moment/locale/es';
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { getProducts, getProviderDaily, getProviderDailyPrevious, getProviderDetail, postSubRegions } from 'helpers/backend_helper';

import ProviderDailyAvailabilityRow from "components/Common/ProviderDailyAvailabilityRow";
import getStorageUser from "helpers/api_helper";
import Select from 'react-select';
import { getProviders, getDistribuitorProviderDailyAvailable } from '../../../../helpers/backend_helper';
import DistribuitorProviderDailyAvailabilityRow from "components/Common/DistribuitorProviderDailyAvailabilityRow";

const starStyle = {}

const DailyFormProviderCreate = () => {
  const [curFarm, setCurFarm] = useState(null)
  const [curRegion, setCurRegion] = useState(null)
  const [curSubRegiones, setCurSubRegiones] = useState([])

  const [farmSelected, setFarmSelected] = useState(null)
  const [dateSelected, setDateSelected] = useState(null)
  const [farms, setFarms] = useState([])
  const [dates, setDates] = useState([])
  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [productos, setProductos] = useState([])
  const [rows2, setRows2] = useState([])
  const [dailyPreviuos, setDailyPreviuos] = useState([])
  const [loading, setLoading] = useState(false)
  const [disableForm, setDisableForm] = useState(false)
  const [regions, setRegions] = useState([])
  const [providers, setProviders] = useState([])
  const [providerSelected, setProviderSelected] = useState({label:'', value:''})
  const [modalUpdateZonas, setModalUpdateZonas] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [tabActive, setTabActive] = useState(1);


  // Load Products
  useEffect(() => {
    async function getProductos() {
      setLoading(true);
      const response = await getProducts();
      setLoading(false);
      setProductos(response);
    }

    async function getProvidersList() {
      const user = getStorageUser();
      console.log(user);
      setLoading(true);
      const response = await getProviders();
      setProviders(response)
      setLoading(false);
    }

    //getProductos();
    getProvidersList();
  }, []);

  useEffect(() => {
    async function getDataDaily(providerId) {
      setLoading(true);
      const response = await getDistribuitorProviderDailyAvailable(providerId);
      setRows2(response.data)
      setDates(response.dates)
      setDateSelected(response.dates[0])
      setLoading(false);
    }

    async function getFarms(providerId) {
      const user = getStorageUser();
      console.log(user);
      setLoading(true);
      const response = await getProviderDetail(providerId);
      setFarms(response.farms)
      setLoading(false);
    }

    if (providerSelected.value) {
      console.log('providerSelected', providerSelected)
      getDataDaily(providerSelected.value);
      getFarms(providerSelected.value);
    }
  }, [providerSelected])

  useEffect(() => {
    if (farms[0]?.id) {
      console.log('setting farm init', farms[0]?.id)
      handleFarmSelected({label: farms[0].name, value: farms[0].id})
    }
  }, [farms])

  const handleDateSelected = (option) => {
    setDateSelected(option.value)

    async function getDataPrevDay() {
      setLoading(true);
      const response = await getProviderDailyPrevious({farm:option.value});
      setDailyPreviuos(response.data)
      setLoading(false);
    }

    getDataPrevDay();
  }

  const handleFarmSelected = (option) => {
    setFarmSelected(option)    
    console.log({id: option.value})
    const farm = _.find(farms, {id: option.value})
    console.log('farm', farm)
    setRegions(farm.regions);
    setCurRegion(farm.regions[0])
    setProductos(_.orderBy(farm.products,['position'],['asc']))
    setCurFarm(_.find(farms, {id: option.value}))

    async function getDataPrevDay() {
      setLoading(true);
      const response = await getProviderDailyPrevious({farm:option.value});
      setDailyPreviuos(response.data)
      setLoading(false);
    }

    getDataPrevDay();
  }

  const handleSaveDaily = (daily) => {
    let ban = false;
    let news = [];
    rows2.forEach( e => {
      if(e.id == daily.id) {
        e = {...daily}
        ban = true;
      }
      news.push({...e})
    })
    if(!ban){
      setRows2([...rows2, daily])
    } else {
      setRows2(news)
    }
  }

  const getDaily = (farm_id, product_id, date) => {
    const dailies = rows2.filter(e => { return e.product_id == product_id && e.farm_id == parseInt(farm_id) && date == e.date })
    return dailies.length > 0 ? dailies : null
  }

  const getDailyPrevious = (product_id) => {
    const daily = dailyPreviuos.filter(e => { return e.product_id == product_id })
    return daily.length > 0 ? daily[0] : null
  }

  const handleProviderSelected = (option) => {
    setFarmSelected(null)
    setDateSelected(null)
    setProviderSelected(option)
  }
  
  const toggleModalZones = () => {
    if(curFarm.regions.length > 0) {
      setCurRegion(curFarm.regions[0]);
      setCurSubRegiones(_.filter(curFarm.regions, { parent_id: curFarm.regions[0].id}))
    }
    setModalUpdateZonas(!modalUpdateZonas)
  }

  const updateSubZonas = async () => {
    try {
      setLoadingModal(true)
      let payload = {regions: [...curSubRegiones], farm: curFarm};
      console.log('payload', payload)
      let response = await postSubRegions(payload)
      setRegions(response)
      setLoadingModal(false)
      setModalUpdateZonas(false)
    } catch (error) {
      console.log(error.toString())
      setLoadingModal(false)
    } 
  }

  console.log('regions', regions)
  console.log(!disableForm, farmSelected, regions.length > 0, productos.length > 0)

  return (
    <React.Fragment>
      { (!disableForm) &&
      <div className="page-content">
        <MetaTags>
          <title>
            Alta de Disponibilidad
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Pedidos" breadcrumbItem="Alta de Disponibilidad" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody style={{minHeight: '600px'}}>
                  
                  <Row className="mb-3">
                    <Col lg={4}>
                      <Label for="formrow-Farm">Proveedor</Label>
                      <Select
                        id="formrow-Farm"
                        value={providerSelected}
                        options={ providers.map( e => { return {label: e.company_name, value: e.id} } ) }
                        onChange={ handleProviderSelected }
                        placeholder="Seleccionar Proveedor"
                      />
                    </Col>
                    { farms.length > 0 ?
                      <>
                        <Col lg={4}>
                          <Label for="formrow-Farm">Fecha</Label>
                          <Select
                            id="formrow-Farm"
                            value={{label: dateSelected, value: dateSelected}}
                            options={ dates.map( e => { return {label: e, value: e} } ) }
                            onChange={ handleDateSelected }
                            placeholder="Seleccionar Fecha"
                          />
                        </Col>

                        <Col lg={4  }>
                          <Label for="formrow-Farm">Sucursal</Label>
                          <Select
                            id="formrow-Farm"
                            value={farmSelected}
                            options={ farms.map( e => { return {label: e.name, value: e.id} } ) }
                            onChange={ handleFarmSelected }
                            placeholder="Seleccionar Surcursal"
                          />
                        </Col>
                      </> : ''
                    }
                  </Row>

                  {loading && <div className="text-center"><Spinner animation="border" variant="danger" style={{color:"#f46a6a"}}/></div>}

                  <Nav tabs>
                  {(productos || []).map((formRow, key) => (
                    <NavItem key={key}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={{active: tabActive == key}}
                        onClick={() => {
                          setTabActive(key)
                        }}
                      >
                        {formRow.name}
                      </NavLink>
                    </NavItem>
                  ))}
                  </Nav>


                  { !disableForm && farmSelected && regions.length > 0 && productos.length > 0 &&
                    <div>
                      {(productos || []).map((formRow, key) => (
                        <>
                          {tabActive == key &&
                          <>
                            <DistribuitorProviderDailyAvailabilityRow 
                              product={formRow}
                              farmId={farmSelected.value}
                              regions={regions}
                              date={dateSelected}
                              daily_availability={null}
                              farm={curFarm}
                              daily_previous={null}
                              onSave={handleSaveDaily}
                              providerId={providerSelected.value}
                              toggleModalZones={toggleModalZones}
                              forceEdit={true}
                            />
                              { (getDaily(farmSelected.value, formRow.id, dateSelected) || []).map((e => (
                                <DistribuitorProviderDailyAvailabilityRow 
                                  key={key}
                                  product={formRow}
                                  farmId={farmSelected.value}
                                  regions={regions}
                                  date={dateSelected}
                                  daily_availability={e}
                                  farm={curFarm}
                                  daily_previous={getDailyPrevious(formRow.id)}
                                  onSave={handleSaveDaily}
                                  providerId={providerSelected.value}
                                  toggleModalZones={toggleModalZones}
                                  forceEdit={true}
                                />
                              ))) }
                          </>
                          }
                        </>
                      ))}
                    </div>
                  }
                  {
                    farmSelected && productos.length == 0 &&
                    <div className="page-content">
                      <Alert color="warning">No hay productos asignados al proveedor.</Alert>
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      }

      { curFarm &&
      <Modal
        isOpen={modalUpdateZonas}
        backdrop='static'
        toggle={() => {
          toggleModalZones();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Actualizar SubZonas
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalUpdateZonas(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            disabled={loadingModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg={12}>
              <Select
                value={{label: curRegion?.name, value: curRegion?.id}}
                options={_.filter(curFarm.regions, (e) => { return !e.parent_id; }).map( e => {return {label: e.name, value: e.id} })}
                onChange={(evt) => { 
                  setCurRegion(_.find(curFarm.regions, {id: evt.value}));
                  setCurSubRegiones(_.filter(curFarm.regions, { parent_id: evt.value})) 
                }}
              />
              <table className="table table-sm text-center mt-2">
                <thead className="table-light">
                  <tr className="table-primary">
                    <th>Zubzonas para {curRegion?.name}</th>
                  </tr> 
                </thead>
                <tbody>
                { curSubRegiones.map((subregion, key) => (
                  <React.Fragment key={key}>
                    <tr>
                      <td className="text-center" style={{width:'150px'}}>
                        <input 
                          type={'text'} 
                          value={subregion.name} 
                          onChange={ (evt) => { curSubRegiones[key].name = evt.target.value; setCurSubRegiones([...curSubRegiones]); }}
                        />
                        {
                          !subregion?.id &&
                          <Button className="btn btn-danger btn-sm ms-1" onClick={ (evt) => { const newState = [...curSubRegiones]; newState.splice(key, 1); setCurSubRegiones(newState); } }><i className="fa fa-times"></i></Button>
                        }
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
                {
                  curSubRegiones.length == 0 &&
                  <tr>
                    <td className="text-center" style={{width:'150px'}}>
                      Sin Subzonas
                    </td>
                  </tr>
                }
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <Button className="btn btn-danger btn-sm" onClick={ (evt) => { setCurSubRegiones( [...curSubRegiones, {name:'', parent_id: curRegion.id}]) } }> + Agregar</Button>
                    </td>
                  </tr>
                </tfoot>
              </table>
              
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <div className="row w-100">
            <div className="col-12 text-end">
              <button
                type="button"
                onClick={() => {
                  toggleModalZones();
                }}
                className="btn btn-secondary"
                data-dismiss="modal"
                disabled={loadingModal}
              >
                <i className="fas fa-times"></i> Cerrar
              </button>
              <button
                onClick={(evt) => updateSubZonas()}
                type="button"
                className="btn btn-danger ms-2"
                disabled={loadingModal}
              >
                <i className="fas fa-check"></i> Aceptar
              </button>
            </div>
          </div>
        </div>
      </Modal>
      }

    </React.Fragment>
  )
}

export default DailyFormProviderCreate