import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useRequest from 'hooks/useRequest';
import { useSelector } from 'react-redux';

import { getAdministrativeData } from 'helpers/backend_helper';
import { getParams } from 'store/catalogs/administrative-data/saga';

const headers = {
    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export default function DownloadExcel({ setIsDownloadExcel }) {

    const eleRef = useRef();

    const {
        activeTab,
        dataReports,
        filters
    } = useSelector(state => state.AdministrativeData);

    const state = useSelector(state => state);

    const {
        url_data
    } = dataReports[activeTab];
    
    const {
        data,
        isLoading,
        isError,
        req
    } = useRequest();

    /* const params = {
        page:   filters.page,
        search: filters.search,
        client: filters.client.id,
        provider: filters.provider.id,
        dates: filters.dates,
        bank: filters.bank.id,
        payment: filters.payment.id,
        column: filters.column,
        order: filters.order,
        mostrar: filters.mostrar.name,
        product: filters.product.id,
    };     */

    const handleDownload = () => {
        const params = getParams(state);
        
        req( getAdministrativeData(url_data, { params, headers }) ).then(() => {            
            if(eleRef.current)
                eleRef.current.click();
            
            setIsDownloadExcel(false);
        })
    }

    useEffect(() => {
        handleDownload();
    }, []);

    return (
        <>
            <div>
                <i className={`${!isLoading && 'd-none' } bx bx-loader bx-spin font-size-16 align-middle me-2`}></i>
                { isLoading && 'Descargando archivo...'}
            </div>
            { isError && (
                <span className="alert alert-danger d-block text-center">Ocurrio un error al descargar el archivo</span>
            )}
            { data && (
                <a 
                    href={ data.file }
                    ref={ eleRef }
                    download='reporte.xlsx'
                    className="d-none my-4"
                ></a>
            )}
        </>
    );
}

DownloadExcel.propTypes = {
    setIsDownloadExcel: PropTypes.func
};