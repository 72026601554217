import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment"

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

const format = new Intl.NumberFormat('es-MX'); 


export default function TableMovementItem({ item }) {

    const {
        id,
        c_provider_id,
        mov_date,
        fecha,
        cobro,
        venta_neta,
        balance,
        account_name,
        detalleBanco,
        detalleTipo
    } = item;

    return (
        <>
            <tr>
                <td className="text-end">
                    {fecha}
                </td>
                <td className="text-end">
                    {id}
                </td>
                <td className="text-end">
                    {detalleTipo}
                </td>
                <td className="text-end">
                    { detalleBanco }
                </td>
                <td className="text-right">
                    { format.format(venta_neta) }
                </td>
                <td className="text-right">
                    { format.format(cobro) }
                </td>
                <td className="text-right">
                    { nf2.format(balance) }
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object
};