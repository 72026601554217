import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import { Card, Col, Container, Row, CardBody, Label, Form } from "reactstrap"

//Import Breadcrumb
import { PropTypes } from "prop-types"
import BreadcrumbNav from "components/Common/BreadcrumbNav"

import EnterpriseForm from "./enterprise-form"
import { set } from "lodash"
import { getClientDetail, getEnterprise } from "helpers/backend_helper"
import { id } from "yup-locales"

const breadcrumbItems = [
  { id: "1", title: "Catálogos", link: "", active: false },
  { id: "2", title: "Empresas", link: "/catalogs/enterprises", active: false },
  { id: "3", title: "Crear", link: "", active: true },
]


const CatalogEnterprisesFormEdit = (props) => {
  const { page } = props.location.state;
  console.log('state', props.location.state)
  const [model, setModel] = useState(null)

  useEffect(() => {
    async function getData()  {
      const response2 = await getEnterprise(props.location.state.id)
      console.log('response2', response2)
      setModel({
        ...response2, 
        ...response2.invoice_address, 
        user_name: response2.users[0].name,
        user_email: response2.users[0].email,
        id: response2.id
      })
    }

    getData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de Empresas</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Empresas" items={breadcrumbItems} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {model && (
                  <EnterpriseForm 
                    isNew={false}
                    dataRow={model}
                    setDataRow={set}
                  />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

CatalogEnterprisesFormEdit.propTypes = {
  location: PropTypes.object,
}

export default CatalogEnterprisesFormEdit
