import React from "react"
import PropTypes from "prop-types"

export default function TableHeader({ setColumnOrder, columnOrder }) {
  return (
    <>
      <tr className="table-head">
        <th className="text-center" style={{ minWidth: "90px" }}>
          Clave
          <ArrowsOrder
            setColumnOrder={setColumnOrder}
            columnOrder={columnOrder}
            column="folio"
          />
        </th>
        <th className="text-center" style={{ minWidth: "90px" }}>
          Fecha pago
          <ArrowsOrder
            setColumnOrder={setColumnOrder}
            columnOrder={columnOrder}
            column="date"
          />
        </th>
        <th className="text-center" style={{ minWidth: "90px" }}>
          No documento
        </th>
        <th className="text-center" style={{ minWidth: "150px" }}>
          Nombre Proveedor
        </th>
        <th className="text-center" style={{ minWidth: "150px" }}>
          Nombre de cuenta contable
        </th>
        <th className="text-center" style={{ minWidth: "170px" }}>
          Banco
        </th>
        <th className="text-center" style={{ minWidth: "170px" }}>
          Gasto con impuesto
          <ArrowsOrder
            setColumnOrder={setColumnOrder}
            columnOrder={columnOrder}
            column="total"
          />
        </th>
        <th className="text-center" style={{ minWidth: "170px" }}>
          Pago
        </th>
        <th className="text-center" style={{ minWidth: "170px" }}>
          Por pagar
        </th>
      </tr>
    </>
  )
}

TableHeader.propTypes = {
  setColumnOrder: PropTypes.func,
  columnOrder: PropTypes.object,
}

const ArrowsOrder = ({ setColumnOrder, column, columnOrder }) => {
  const isArrowDownActive =
    columnOrder.column === column && columnOrder.order === "desc"
  const isArrowUpActive =
    columnOrder.column === column && columnOrder.order === "asc"

  return (
    <span className="order arrows-order">
      <span
        onClick={() =>
          setColumnOrder({
            column,
            order: "desc",
          })
        }
        className={`dropdown arrows-order__asc ${
          isArrowDownActive && "active"
        }`}
      >
        <span className="caret"></span>
      </span>
      <span
        onClick={() =>
          setColumnOrder({
            column,
            order: "asc",
          })
        }
        className={`dropup arrows-order__desc ${isArrowUpActive && "active"}`}
      >
        <span className="caret"></span>
      </span>
    </span>
  )
}

ArrowsOrder.propTypes = {
  column: PropTypes.string,
  setColumnOrder: PropTypes.func,
  columnOrder: PropTypes.object,
}
