import React from "react"
import { useSelector } from "react-redux"

import { Card, CardBody, Col, Media, Row } from "reactstrap"

import { balanceLabels } from "./static-data"

export const nf = new Intl.NumberFormat("es-MX", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export default function Saldos() {
  const { activeTab, saldos, filters, totales } = useSelector(
    state => state.AdministrativeData
  )

  console.log(totales)

  if (activeTab === "comprobacion_almacen")
    return (
      <Row className="" id="saldos-box">
        <Col lg={4} className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2 text-center">
              <h6 className="text-black mb-1 bg-light text-center p-1">
                Periodo de trabajo
              </h6>
              <h6 className="mb-0 text-danger">
                {filters.dates.replace("/", " al ")}
              </h6>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2">
              <Media>
                <Media body>
                  <h6 className="text-black mb-1 bg-light p-1 text-center">
                    Unidad (pzs)
                  </h6>
                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">
                          Entradas (compras)
                        </small>
                        <span className="text-danger w-100 pe-3 text-end">
                          {nf.format(totales?.provider_pieces)}
                        </span>
                      </div>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">
                          Salidas (ventas)
                        </small>
                        <span className="text-danger w-100 pe-3 text-end">
                          {nf.format(totales?.client_pieces)}
                        </span>
                      </div>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">Ajustes</small>
                        <span className="text-danger w-100 pe-3 text-end">
                          {nf.format(totales?.adjust_pieces)}
                        </span>
                      </div>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">
                          Existencias
                        </small>
                        <span className="text-danger w-100 pe-3 text-end">
                          {nf.format(totales?.stock_pieces)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Media>
              </Media>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2">
              <Media>
                <Media body>
                  <h6 className="text-black mb-1 bg-light p-1 text-center">
                    Unidad (kg)
                  </h6>
                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">
                          Entradas (compras):
                        </small>
                        <span className="text-danger w-100 pe-3 text-end">
                          {nf.format(totales?.provider_kgs)}
                        </span>
                      </div>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">
                          Salidas (ventas):
                        </small>
                        <br />
                        <span className="text-danger w-100 pe-3 text-end">
                          {nf.format(totales?.client_kgs)}
                        </span>
                      </div>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">Ajustes:</small>
                        <br />
                        <span className="text-danger w-100 pe-3 text-end">
                          {nf.format(totales?.adjust_kgs)}
                        </span>
                      </div>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">
                          Existencias:
                        </small>
                        <br />
                        <span className="text-danger w-100 pe-3 text-end">
                          {nf.format(totales?.stock_kgs)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Media>
              </Media>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )

  if (activeTab === "clientes")
    return (
      <Row className="" id="saldos-box">
        <Col lg={2} className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2">
              <h6 className="text-black mb-1 bg-light text-center p-1">
                Periodo de trabajo
              </h6>
              <h6 className="mb-0 text-danger text-center">
                {filters.dates.split("/")[0]}
                <br />
                al
                <br />
                {filters.dates.split("/")[1]}
              </h6>
            </CardBody>
          </Card>
        </Col>

        <Col lg={3} className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2">
              <Media>
                <Media body>
                  <h6 className="text-black mb-1 bg-light text-center p-1">
                    Saldos
                  </h6>
                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">Inicial</small>
                        <span className="text-danger w-100 pe-3 text-end">
                          ${nf.format(saldos.ini)}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">Final</small>
                        <span className="text-danger w-100 pe-3 text-end">
                          ${nf.format(saldos.fin)}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">
                          Efecto deuda
                        </small>
                        <span className="text-danger w-100 pe-3 text-end">
                          ${nf.format(totales?.diferencia ?? 0)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Media>
              </Media>
            </CardBody>
          </Card>
        </Col>

        {totales && (
          <Col className="p-0">
            <Card className="mini-stats-wid mb-0">
              <CardBody className="p-2">
                <Media>
                  <Media body>
                    <h6 className="text-black mb-1 bg-light text-center p-1">
                      Totales
                    </h6>
                    <Row>
                      {[
                        {
                          name: "Total Pzas",
                          data: totales["pieces"],
                        },
                        {
                          name: "Merma",
                          data: totales["decrease"],
                          currency: "$",
                        },
                        {
                          name: "Otros Cargos",
                          data: totales["nota_cargo"],
                          currency: "$",
                        },
                        {
                          name: "Ventas netas",
                          data: totales["venta_neta"],
                          currency: "$",
                        },
                        { name: "IVA", data: totales["iva"], currency: "$" },
                      ].map((e, i) => (
                        <Col style={{ lineHeight: "1em" }} key={i}>
                          <div className="mb-1">
                            <small className="fw-medium">{e.name}:</small>
                            <br />
                            <span className="text-danger">
                              {e.currency ?? ""}
                              {nf.format(e.data)}
                            </span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      {[
                        {
                          name: "Total Kgs",
                          data: totales["kgs"],
                        },
                        {
                          name: "Mortalidad",
                          data: totales["mortalidad"],
                          currency: "$",
                        },
                        {
                          name: "Otros Descuentos",
                          data: totales["nota_credito"],
                          currency: "$",
                        },
                        {
                          name: "Cobros",
                          data: totales["cobro"],
                          currency: "$",
                        },
                        {
                          name: "Retenciones",
                          data: totales["retencion"],
                          currency: "$",
                        }
                      ].map((e, i) => (
                        <Col style={{ lineHeight: "1em" }} key={i}>
                          {e ? (
                            <div className="mb-1">
                              <small className="fw-medium">{e.name}:</small>
                              <br />
                              <span className="text-danger">
                                {e.currency ?? ""}
                                {nf.format(e.data)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      ))}
                    </Row>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    )

  if (activeTab === "proveedores")
    return (
      <Row className="" id="saldos-box">
        <Col lg={2} className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2">
              <h6 className="text-black mb-1 bg-light text-center p-1">
                Periodo de trabajo
              </h6>
              <h6 className="mb-0 text-danger text-center">
                {filters.dates.split("/")[0]}
                <br />
                al
                <br />
                {filters.dates.split("/")[1]}
              </h6>
            </CardBody>
          </Card>
        </Col>

        <Col lg={3} className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2">
              <Media>
                <Media body>
                  <h6 className="text-black mb-1 bg-light text-center p-1">
                    Saldos
                  </h6>
                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">Inicial</small>
                        <span className="text-danger w-100 pe-3 text-end">
                          ${nf.format(saldos.ini)}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">Final</small>
                        <span className="text-danger w-100 pe-3 text-end">
                          ${nf.format(saldos.fin)}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3">
                          Efecto deuda
                        </small>
                        <span className="text-danger w-100 pe-3 text-end">
                          ${nf.format(totales?.diferencia ?? 0)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Media>
              </Media>
            </CardBody>
          </Card>
        </Col>

        {totales && (
          <Col className="p-0">
            <Card className="mini-stats-wid mb-0">
              <CardBody className="p-2">
                <Media>
                  <Media body>
                    <h6 className="text-black mb-1 bg-light text-center p-1">
                      Totales
                    </h6>
                    <Row>
                      {[
                        {
                          name: "Total Pzas",
                          data: totales["pieces"],
                        },
                        {
                          name: "Merma",
                          data: totales["decrease"],
                          currency: "$",
                        },
                        {
                          name: "Otros Cargos",
                          data: totales["other_charges"],
                          currency: "$",
                        },
                        {
                          name: "Compras netas",
                          data: totales["net"],
                          currency: "$",
                        },
                        { name: "IVA", data: totales["iva"], currency: "$" },
                      ].map((e, i) => (
                        <Col style={{ lineHeight: "1em" }} key={i}>
                          <div className="mb-1">
                            <small className="fw-medium">{e.name}:</small>
                            <br />
                            <span className="text-danger">
                              {e.currency ?? ""}
                              {nf.format(e.data)}
                            </span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      {[
                        {
                          name: "Total Kgs",
                          data: totales["kgs"],
                        },
                        {
                          name: "Otros Descuentos",
                          data: totales["discount"],
                          currency: "$",
                        },
                        null,
                        {
                          name: "Pagos",
                          data: totales["payment"],
                          currency: "$",
                        },
                        {
                          name: "Retenciones",
                          data: totales["retencion"],
                          currency: "$",
                        },
                      ].map((e, i) => (
                        <Col style={{ lineHeight: "1em" }} key={i}>
                          {e ? (
                            <div className="mb-1">
                              <small className="fw-medium">{e.name}:</small>
                              <br />
                              <span className="text-danger">
                                {e.currency ?? ""}
                                {nf.format(e.data)}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      ))}
                    </Row>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    )

  if (activeTab === "costos")
    return (
      <Row className="mb-1 text-center" id="saldos-box">
        <Col lg={2} className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2">
              <h6 className="text-black mb-1 bg-light text-center p-1">
                Periodo de trabajo
              </h6>
              <h6 className="mb-0 text-danger text-center">
                {filters.dates.split("/")[0]}
                <br />
                al
                <br />
                {filters.dates.split("/")[1]}
              </h6>
            </CardBody>
          </Card>
        </Col>
        
        <Col lg={3} className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2">
              <Media>
                <Media body>
                  <h6 className="text-black mb-1 bg-light text-center p-1">
                    Saldos
                  </h6>
                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3 text-start">Inicial</small>
                        <span className="text-danger w-100 pe-3 text-end">
                          ${nf.format(saldos.ini)}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3 text-start">Final</small>
                        <span className="text-danger w-100 pe-3 text-end">
                          ${nf.format(saldos.fin)}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} style={{ lineHeight: "1em" }}>
                      <div className="mb-1 d-flex flex-row">
                        <small className="fw-medium w-100 ps-3 text-start">
                          Diferencia
                        </small>
                        <span className="text-danger w-100 pe-3 text-end">
                          ${nf.format(saldos.fin - saldos.ini)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Media>
              </Media>
            </CardBody>
          </Card>
        </Col>
        <Col className="p-0">
            <Card className="mini-stats-wid mb-0">
              <CardBody className="p-2">
                <Media>
                  <Media body>
                    <h6 className="text-black mb-1 bg-light">Totales</h6>
                    <Row>
                      {totales && Object.keys(totales).map(key => (
                        <Col
                          style={{ lineHeight: "1em" }}
                          key={key}
                        >
                          <div className="mb-1 text-start">
                            <small className="fw-medium">
                              {balanceLabels[key]?.label}:
                            </small>
                            <br />
                            <span className="text-danger">
                              {balanceLabels[key]?.currency}
                              {nf.format(totales[key])}
                            </span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Media>
                </Media>
              </CardBody>
            </Card>
        </Col>
      </Row>
    )

  return (
    <Row className="mb-1 text-center" id="saldos-box">
      <Col lg={2} className="p-0">
        <Card className="mini-stats-wid mb-0">
          <CardBody className="p-2">
            <h6 className="text-black mb-1 bg-light">Periodoo</h6>
            <h6 className="mb-0 text-danger">
              {filters.dates.replace("/", " al ")}
            </h6>
          </CardBody>
        </Card>
      </Col>
      {activeTab !== "comprobacion_almacen" && activeTab !== "cargos" && (
        <>
          <Col lg={2} className="p-0">
            <Card className="mini-stats-wid mb-0">
              <CardBody className="p-2">
                <Media>
                  <Media body>
                    <h6 className="text-black mb-1 bg-light">Saldos</h6>
                    <h6 className="mb-0">
                      Inicial{" "}
                      <span className="text-danger">
                        ${nf.format(saldos.ini)}
                      </span>
                    </h6>
                    <h6 className="mb-0">
                      Final{" "}
                      <span className="text-danger">
                        ${nf.format(saldos.fin)}
                      </span>
                    </h6>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
        </>
      )}
      {activeTab === "comprobacion_almacen" && (
        <>
          <Col lg={5} className="p-0">
            <Card className="mini-stats-wid mb-0">
              <CardBody className="p-2">
                <Media>
                  <Media body>
                    <h6 className="text-black mb-1 bg-light">Unidad (pzs)</h6>
                    <Row>
                      <Col xs={12} style={{ lineHeight: "1em" }}>
                        <div className="mb-1 ">
                          <small className="fw-medium">
                            Entradas (compras):
                          </small>
                          <br />
                          <span className="text-danger">
                            {nf.format(totales?.provider_pieces)}
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} style={{ lineHeight: "1em" }}>
                        <div className="mb-1 ">
                          <small className="fw-medium">Salidas (ventas):</small>
                          <br />
                          <span className="text-danger">
                            {nf.format(totales?.client_pieces)}
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} style={{ lineHeight: "1em" }}>
                        <div className="mb-1 ">
                          <small className="fw-medium">Ajustes:</small>
                          <br />
                          <span className="text-danger">
                            {nf.format(totales?.adjust_pieces)}
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} style={{ lineHeight: "1em" }}>
                        <div className="mb-1 ">
                          <small className="fw-medium">Existencias:</small>
                          <br />
                          <span className="text-danger">
                            {nf.format(totales?.stock_pieces)}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col lg={5} className="p-0">
            <Card className="mini-stats-wid mb-0">
              <CardBody className="p-2">
                <Media>
                  <Media body>
                    <h6 className="text-black mb-1 bg-light">Unidad (kg)</h6>
                    <Row>
                      <Col xs={12} style={{ lineHeight: "1em" }}>
                        <div className="mb-1 ">
                          <small className="fw-medium">
                            Entradas (compras):
                          </small>
                          <br />
                          <span className="text-danger">
                            {nf.format(totales?.provider_kgs)}
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} style={{ lineHeight: "1em" }}>
                        <div className="mb-1 ">
                          <small className="fw-medium">Salidas (ventas):</small>
                          <br />
                          <span className="text-danger">
                            {nf.format(totales?.client_kgs)}
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} style={{ lineHeight: "1em" }}>
                        <div className="mb-1 ">
                          <small className="fw-medium">Ajustes:</small>
                          <br />
                          <span className="text-danger">
                            {nf.format(totales?.adjust_kgs)}
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} style={{ lineHeight: "1em" }}>
                        <div className="mb-1 ">
                          <small className="fw-medium">Existencias:</small>
                          <br />
                          <span className="text-danger">
                            {nf.format(totales?.stock_kgs)}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
        </>
      )}
      {activeTab !== "comprobacion_almacen" && totales && (
        <Col className="p-0">
          <Card className="mini-stats-wid mb-0">
            <CardBody className="p-2">
              <Media>
                <Media body>
                  <h6 className="text-black mb-1 bg-light">Totales</h6>
                  <Row>
                    {Object.keys(totales).map(key => (
                      <Col
                        xs={6}
                        style={{ lineHeight: "1em" }}
                        md={Object.keys(totales).length <= 6 ? 4 : 3}
                        key={key}
                      >
                        <div className="mb-1 ">
                          <small className="fw-medium">
                            {balanceLabels[key]?.label}:
                          </small>
                          <br />
                          <span className="text-danger">
                            {balanceLabels[key]?.currency}
                            {nf.format(totales[key])}
                          </span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Media>
              </Media>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  )
}
