/**
 * Calcular montos del complemento basado en la factura
 */
export const calculateComplementAmounts = (invoice, amountPaid = 0) => {
  const totalInvoice = Number(invoice?.total)
  const totalComplementsAmount = invoice.complements.reduce(
    (acc, complement) => acc + Number(complement?.pivot?.amount),
    0
  )

  let PartialityNumber = 1
  let PreviousBalanceAmount = totalInvoice
  let ImpSaldoInsoluto = Math.round((totalInvoice - amountPaid) * 100) / 100

  if (invoice.complements.length) {
    PartialityNumber = invoice.complements.length + 1
    PreviousBalanceAmount =
      Math.round((totalInvoice - totalComplementsAmount) * 100) / 100
    ImpSaldoInsoluto =
      Math.round((PreviousBalanceAmount - amountPaid) * 100) / 100
  }

  return {
    PartialityNumber,
    ImpSaldoInsoluto,
    PreviousBalanceAmount,
  }
}
