
export const balanceLabels = {
    pieces: {
        label: 'No pzas',
        currency: ''
    },
    kgs: {
        label: 'KG',
        currency: ''
    },
    prom: {
        label: 'Promedio',
        currency: ''
    },
    pv: {
        label: 'PV',
        currency: ''
    },
    venta_neta: {
        label: 'Venta neta',
        currency: '$'
    },
    cobro: {
        label: 'Total Cobrado',
        currency: '$'
    },
    diferencia: {
        label: 'Diferencia',
        currency: '$'
    },
    cost: {
        label: 'Precio compra',
        currency: '$'
    },
    total: {
        label: 'Compra total',
        currency: '$'
    },
    net: {
        label: 'Compras Netas',
        currency: '$'
    },
    decrease: {
        label: 'Merma',
        currency: '$'
    },
    discount: {
        label: 'Otros descuentos',
        currency: '$'
    },
    payment: {
        label: 'Pago',
        currency: '$'
    },
    deposit: {
        label: 'Depósito',
        currency: '$'
    },
    nota_credito: {
        label: 'Nota Crédito',
        currency: '$'
    },
    nota_cargo: {
        label: 'Nota Cargo',
        currency: '$'
    },
    client_kgs: {
        label: 'Kgs Clientes',
        currency: ''
    },
    client_pieces: {
        label: 'Pzas Cliente',
        currency: ''
    },
    kgs_diff: {
        label: 'Diferencias Kgs',
        currency: ''
    },
    pieces_diff: {
        label: 'Diferencias Pzas',
        currency: ''
    },
    provider_kgs: {
        label: 'Kgs Proveedor',
        currency: ''
    },
    provider_pieces: {
        label: 'Pzas Proveedor',
        currency: ''
    },
    provider_charge: {
        label: 'Proveedor ',
        currency: ''
    },
    other_charges: {
        label: 'Otros cargos ',
        currency: '$'
    },
    retencion: {
        label: 'Retención',
        currency: '$'
    },
    total_total: {
        label: 'Total',
        currency: '$'
    },
    client_charge: {
        label: 'Clientes ',
        currency: ''
    },
    charges_diff: {
        label: 'Diferencias ',
        currency: ''
    },
    bank_charge: {
        label: 'Bancos ',
        currency: ''
    },
    total_payment: {
        label: 'Pagos',
        currency: '$'
    }, 
    amount: {
        label: 'Importe',
        currency: '$'
    }, 
    iva: {
        label: 'Iva',
        currency: '$'
    },
};