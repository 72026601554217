import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { formatPeriod } from 'helpers/formats';
import * as moment from 'moment';

const TITLE = process.env.REACT_APP_REPORT_TITLE
const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function HeaderReport({ period,total,end_date }) {
    return (
        <>
            <h4 className="text-danger f-bold text-center">{ TITLE }</h4>
            <h5 className="mb-4 f-bold text-center">Reporte de cobranza</h5>
            <Row className='mb-4'>
                <Col md={6}>
                    <span className="d-block f-bold">Periodo Reporte: { formatPeriod(period) }</span>
                    <span className="d-block f-bold">Fecha Impresión: { new Date().toLocaleString("es-MX") }</span>
                    
                </Col>
                <Col md={6} className="text-end">
                    <span>Total cobrado al { moment(end_date).format('DD/MM/YYYY') }: </span>
                    <span className="d-block f-bold"><mark>$ {nf2.format(total)}</mark></span>
                </Col>
            </Row>
        </>
    );
}

HeaderReport.propTypes = {
    period: PropTypes.string,
    end_date:PropTypes.string,
    total:PropTypes.number
};