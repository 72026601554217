import React from "react"

import PropTypes from "prop-types"
import CurrencyFormat from "common/nibble/CurrencyFormat"

const ProductTotals = ({ productValidation }) => {
  const iva = productValidation.values.Taxes.find(item => item.Name === "IVA")
  const ivaRet = productValidation.values.Taxes.find(
    item => item.Name === "IVA RET"
  )
  const ieps = productValidation.values.Taxes.find(item => item.Name === "IEPS")
  const isr = productValidation.values.Taxes.find(item => item.Name === "ISR")

  return (
    <div className="d-flex justify-content-end gap-4">
      <p className="text-end">
        <strong>Subtotal</strong> <br />
        <CurrencyFormat value={productValidation.values.Subtotal} />
      </p>
      {productValidation.values.Discount ? (
        <p className="text-end">
          <strong>Descuento</strong> <br />
          <CurrencyFormat value={productValidation.values.Discount} />
        </p>
      ) : null}
      {iva && (
        <p className="text-end">
          <strong>IVA</strong> <br />
          <CurrencyFormat value={iva.Total} />
        </p>
      )}
      {ivaRet && (
        <p className="text-end">
          <strong>IVA RET</strong> <br />
          <CurrencyFormat value={ivaRet.Total} />
        </p>
      )}
      {ieps && (
        <p className="text-end">
          <strong>IEPS</strong> <br />
          <CurrencyFormat value={ieps.Total} />
        </p>
      )}
      {isr && (
        <p className="text-end">
          <strong>ISR</strong> <br />
          <CurrencyFormat value={isr.Total} />
        </p>
      )}
      <p className="text-end">
        <strong>Total</strong> <br />
        <CurrencyFormat value={productValidation.values.Total} />
      </p>
    </div>
  )
}

ProductTotals.propTypes = {
  productValidation: PropTypes.object,
}

export default ProductTotals
