import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";

//redux
import PropTypes from 'prop-types';
import _ from "lodash";

function ComparePricesGraph(props) {
  const getOptions = (axisX) => {
    return {
      chart: {
        toolbar: "false",
        dropShadow: {
          enabled: !0,
          color: "#000",
          top: 18,
          left: 7,
          blur: 8,
          opacity: 0.2,
        },
      },
      xaxis: {
        categories: axisX
      },
      dataLabels: {
        enabled: !1,
      },
      color: ['#ED5565', '#FC6E51', '#FFCE54', '#A0D468', '#48CFAD', '#4FC1E9', '#5D9CEC', '#AC92EC', '#EC87C0', '#F5F7Fa', '#CCD1D9', '#656D78', '#DA4453', '#E9573F', '#F6BB42', '#8CC152', '#37BC9B', '#3BAFDA', '#4A89DC', '#967ADC', '#D770AD', '#E6E9ED', '#AAB2BD', '#434A54'],
      stroke: {
        curve: "smooth",
        width: 3,
      },
    };
  }

  const numberFormat = new Intl.NumberFormat('es-MX', { minimumFractionDigits: 2 });

  return (
    <React.Fragment>
      <Col xl="12">
        <Card>
          <CardBody>
            <div className="clearfix">
              <h4 className="card-title mb-4 text-center">Histórico de Precios</h4>
            </div>

            <Row>
              <Col lg="12">
                <div id="line-chart" dir="ltr">
                  { props.series.length > 0 && props.options.length > 0 &&
                  <ReactApexChart
                    series={props.series}
                    options={getOptions(props.options)}
                    height={320}
                    className="apex-charts"
                  />
                  }
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

ComparePricesGraph.propTypes = {
  series: PropTypes.array,
  options: PropTypes.array,
}

export default ComparePricesGraph;
