import { useState } from 'react';
import { postUserPassword } from 'helpers/backend_helper';

const INIT_FORM = {
    current_password: '',
    password: '',
    password_confirmation: ''
}

const INIT_UI = {
    isLoading: false,
    isError: '',
    isSuccess: false
}

const authLocalStorage = localStorage.getItem('authUser') 
                            ? JSON.parse(localStorage.getItem('authUser')) : {};

export default function useUserPassword() {

    const [form, setForm] = useState(INIT_FORM);
    const [formUi, setFormUi] = useState(INIT_UI);
       
    const handleChange = ({ target }) => {
        setForm(prev => ({
            ...prev,
            [target.name]: target.value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setFormUi({...INIT_UI, isLoading: true});
            
            const { token } = await postUserPassword(form);
            localStorage.setItem('authUser', JSON.stringify({...authLocalStorage, token}));

            setForm(INIT_FORM);
            setFormUi({...INIT_UI, isSuccess: true});
        }catch (error) {
            setFormUi({...INIT_UI, isError: error});
        }
        
    }

    return {
        handleChange,
        handleSubmit,
        formUi,
        form
    }
}
