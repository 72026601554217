import React from "react"
import PropTypes from "prop-types"
import CurrencyFormat from "common/nibble/CurrencyFormat"

const nf = new Intl.NumberFormat("es-MX")

export default function TableTotal({ totals }) {
  const { total, pendingToPayment, payment } = totals

  return (
    <>
      <tr className="table-head">
        <td colSpan={6} className="f-bold text-right">
          Totales
        </td>
        <td className="f-bold  border-b-black text-right">
          <CurrencyFormat prefix="$" value={total} />
        </td>
        <td className="f-bold  border-b-black text-right">
          <CurrencyFormat prefix="$" value={payment} />
        </td>
        <td className="f-bold  border-b-black text-right">
          <CurrencyFormat prefix="$" value={pendingToPayment} />
        </td>
      </tr>
    </>
  )
}

TableTotal.propTypes = {
  totals: PropTypes.object,
}
