import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { Col, Button, Row, Alert, Table, Popover, PopoverHeader, PopoverBody } from "reactstrap"
import "flatpickr/dist/themes/material_red.css"
import { useState } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { putDistribuitorProviderDaily } from "helpers/backend_helper"
import RatingTooltip from "react-rating-tooltip"
import RatingCustom from "./RatingCustom"
import { postDistribuitorProviderDaily } from '../../helpers/backend_helper';

const starStyle = {}

const DistribuitorProviderDailyAvailabilityRow = props => {
  const forceEdit = 'forceEdit' in props ? props.forceEdit : false;
  let daily = props.daily_availability
  const farm = props.farm
  const date = props.date
  const local_region = farm.local_delivery ? farm.region:null
  //const [daily, setDaily] = useState(props.daily_availability)
  const [dailyPrev, setDailyPrev] = useState(props.daily_previous)
  const initPedido = [] 
  const initPrices = {}
  const initQuantities= {}
  
  const [pedido, setPedido] = useState(initPedido)
  const [product, setProduct] = useState(props.product)
  const [prices, setPrices] = useState(initPrices)
  const [quantities, setQuantities] = useState(initQuantities)
  //const [regions, setRegions] = useState(props.regions)
  const regions = props.regions
  const [trucks, setTrucks] = useState([{quantity: '', truck_units: ''}])
  const [values, setValues] = useState({
    provider_id: null,
    products: {},
    timeDelivery: '',
  })
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)
  const [completeSave, setCompleteSave] = useState(false)
  const [popover, setpopover] = useState({});

  useEffect(() => {
    if(daily) {
      initPedido[props.product.id] = {...daily}
      daily.prices.forEach(e => {
        initPrices[e.region_id + '_' + e.subregion_id] = e;
        setPrices({...initPrices})
      });
      daily.quantities.forEach(e => {
        initQuantities[e.id] = { id: e.id, truck_name: e.truck_name, truck_units: e.truck_units};
        setQuantities({...initQuantities})
      });
    }
  }, [props.daily_availability])

  const handleUpdatePedido = async (event, values, producto) => {
    try {
      if (producto.id in pedido) {
        pedido[producto.id] = {...pedido[producto.id], ...values}
      } else {
        pedido[producto.id] = {...values}
      }
      setPedido({...pedido})
      console.log(pedido)
    } catch(error) {
      console.log(error)
    }
  }

  const handleUpdatePrices = async (event, values, producto) => {
    values.shipped = true;
    try {
      let newPrices = {...prices}
      newPrices[values.region_id + '_' + values.subregion_id] = values
      setPrices(newPrices)
    } catch(error) {
      console.log(error)
    }
  }

  const handleValidSubmit = async (event, values) => {
    try {
      validateExtraFields()
      setBtnSaveLoad(true)
      console.log('values',values)
      let payload = null;
      let response = null;
      payload= { data: toArrayPedido(), date: date, providerId: props.providerId ? props.providerId:null}
      response = await postDistribuitorProviderDaily(payload)
      props.onSave(response[0])
      let initQuantities = {};
      response[0].quantities.forEach(e => {
        initQuantities[e.id] = { id: e.id, truck_name: e.truck_name, truck_units: e.truck_units};
      });
      setQuantities(initQuantities)
      setTrucks([{quantity: '', truck_units: ''}])
      daily = response[0];
      setCompleteSave(true)
      setError(null)
      setCompleted('Se completo la operación')
      setBtnSaveLoad(false)
    } catch (error) {
      console.log(error)
      console.log(error.toString())
      setError(error.toString())
      setCompleted(null)
      setBtnSaveLoad(false)
    }
  }

  const handleValidUpdateSubmit = async (event, values) => {
    try {
      //validateExtraFields()
      setBtnSaveLoad(true)
      let payload = null;
      let response = null;
    
      payload = { 
        data: toArrayPedido(), 
        date: date, 
        providerId: props.providerId ? props.providerId:null,
        daily_availability_id: daily?.id
      }
      //setBtnSaveLoad(false)
      console.log('payload',payload);
      //return;
      response = await putDistribuitorProviderDaily(payload)
    
      props.onSave(response[0])
      let initQuantities = {};
      response[0].quantities.forEach(e => {
        initQuantities[e.id] = { id: e.id, truck_name: e.truck_name, truck_units: e.truck_units};
      });
      setQuantities(initQuantities)
      setTrucks([{quantity: '', truck_units: ''}])

      daily = response[0];
      setCompleteSave(true)
      setError(null)
      setCompleted('Se completo la operación')
      setBtnSaveLoad(false)
    } catch (error) {
      console.log(error)
      console.log(error.toString())
      setError(error.toString())
      setCompleted(null)
      setBtnSaveLoad(false)
    }
  }

  const hasValueAttribute = (id, attr) => {
    const value = pedido[id]?.[attr];
    return !(value === '' || value === undefined)
  }

  const hasValue = (id) => {
    const value = pedido[id]?.weight || pedido[id]?.quantity || pedido[id]?.unit_price || pedido[id]?.unit_price_shipped || pedido[id]?.quality || pedido[id]?.quality2 || pedido[id]?.quality3;
    return !(value === '' || value === undefined)
  }

  const validateExtraField = (id) => {
    const value = pedido[id]?.quality;
    return !(value === '' || value === undefined || value === null || value === NaN)
  }

  const validateExtraField2 = (id) => {
    const value = pedido[id]?.quality_2;
    return !(value === '' || value === undefined || value === null || value === NaN)
  }

  const validateExtraField3 = (id) => {
    const value = pedido[id]?.quality_3;
    return !(value === '' || value === undefined || value === null || value === NaN)
  }

  const validateExtraFields = () => {
    for (const key in pedido) {
      if (Object.hasOwnProperty.call(pedido, key)) {
        const element = pedido[key];
        console.log(pedido[key])
        if (!validateExtraField(key) || !validateExtraField2(key) || !validateExtraField3(key)) {
          throw Error('completar campos');
        }

        if(parseFloat(element['quantity']) == 0.0)
          throw Error('La cantidad debe ser mayor a 0');

        let upZero = 0;
        for (const key2 in prices) {
          if(parseFloat(prices[key2].price_shipped) > 0.0) {
            upZero++;
          }
        }
        if (upZero == 0) {
          throw Error('Debes colocar algun precio mayor a 0');
        }
        
      }
    }
  }

  const toArrayPedido = () => {
    const response = [];
    const response_prices = [];
    const response_quantities = [];
    //console.log(pedido);
    for (const key in prices) {
      response_prices.push({...prices[key]})
    }
    for (const key in quantities) {
      response_quantities.push({...quantities[key]})
    }
    for (const key in pedido) {
      response.push({
        farm_id: props.farmId,
        product: product.name,
        product_id: product.id,
        ...pedido[key],
        prices: response_prices,
        quantities: response_quantities,
        trucks: trucks,
        id: daily ? daily.id : null
      })
    }
    return response;
  }

  const addTruck = () => {
    setTrucks([...trucks, {quantity: '', truck_units: ''} ])
  }

  const removeTruck = (idx) => {
    const newTrucks = [];
    trucks.forEach( (e, i) => { if(idx !== i) newTrucks.push({...e}); } )
    setTrucks(newTrucks)
  }
  
  const handleUpdateTrucks = (idx, obj) => {
    const newTrucks = [];
    trucks.forEach( (e, i) => { 
      if(idx == i)
        newTrucks.push({...e, ...obj});
      else
        newTrucks.push({...e});
    })
    setTrucks(newTrucks)
  }

  const setPopOverDetail = (id, value) => {
    console.log('update', id, value)
    const values = {...popover}
    if( id in popover) {
      values[id] = value;
      setpopover(values)
    } else {
      values[id] = true;
      setpopover(values)
    }
  };

  const getPopOverDetail = (id) => {
    const values = {...popover}
    if( id in values) {
      return values[id]
    } else {
      return false
    }
  };

  console.log('daily', daily) 
  console.log('pedido', pedido)
  console.log('prices', prices)
  console.log('quantities', quantities)
  return (
    <React.Fragment>
    { (daily == null || forceEdit) && <AvForm className="row bg-light repeater mb-3" encType="multipart/form-data" onValidSubmit={(e,v) => { daily ? handleValidUpdateSubmit(e, v):handleValidSubmit(e, v)}}>
      <Row>
        <Col lg={12} className="mb-1 text-center">
          {error ? <Alert color="danger">{error}</Alert> : null}
          {completed ? <Alert color="success">{completed}</Alert> : null}

          <label htmlFor=""></label>
          <div className="bg-dark text-light pt-1 pb-1"><b>{ product.name }</b></div>
        </Col>

        <Col lg={3} className="text-center">
          <label htmlFor="email">Peso Mínimo</label>
          <AvField
            type="number"
            name={'weight' + product.id}
            className="form-control"
            required={ hasValue(product.id) }
            onChange={(event) => { handleUpdatePedido(event, {weight: event.target.valueAsNumber}, {...product}) }}
            errorMessage="Campo requerido"
            min={0}
            value={daily ? daily.weight : (dailyPrev ? dailyPrev.weight:'')}
            onWheel={(e) => e.target.blur() }
          />
        </Col>
        <Col lg={3} className="text-center">
          <label htmlFor="email">Peso Máximo</label>
          <AvField
            type="number"
            name={'weight2' + product.id}
            className="form-control"
            required={ hasValue(product.id) }
            onChange={(event) => { handleUpdatePedido(event, {weight_max: event.target.valueAsNumber}, {...product}) }}
            errorMessage="Campo requerido"
            min={0}
            value={daily ? daily.weight_max : (dailyPrev ? dailyPrev.weight_max:'')}
            onWheel={(e) => e.target.blur() }
          />
        </Col>

        <Col lg={2} className="text-center">
          <label htmlFor="subject">Pigmento</label>
          <div className={hasValue(product.id) && !hasValueAttribute(product.id, 'quality') ? 'is-invalid':''}>
            <RatingTooltip
              max={3}
              validate={ (a) => {return false;} }
              defaultRating={daily ? daily.quality : (dailyPrev ? dailyPrev.quality:'')}
              onChange={rate => {
                handleUpdatePedido(null, {quality: rate > 0 ? rate:''}, {...product})
              }}
              ActiveComponent={
                <i
                  className="mdi mdi-star text-warning"
                  style={starStyle}
                />
              }
              InActiveComponent={
                <i
                  className="mdi mdi-star-outline text-muted"
                  style={starStyle}
                />
              }
            />{" "}
          </div>
          <div className="invalid-feedback">Campo requerido</div>
        </Col>
        <Col lg={2} className="text-center">
        <label htmlFor="subject">Calidad</label>
          <div className={hasValue(product.id) && !hasValueAttribute(product.id, 'quality') ? 'is-invalid':''}>
            <RatingTooltip
              max={3}
              validate={ (a) => {return false;} }
              defaultRating={daily ? daily.quality_2 : (dailyPrev ? dailyPrev.quality_2:'')}
              onChange={rate => {
                handleUpdatePedido(null, {quality_2: rate > 0 ? rate:''}, {...product})
              }}
              ActiveComponent={
                <i
                  className="mdi mdi-star text-warning"
                  style={starStyle}
                />
              }
              InActiveComponent={
                <i
                  className="mdi mdi-star-outline text-muted"
                  style={starStyle}
                />
              }
            />{" "}
          </div>
          <div className="invalid-feedback">Campo requerido</div>
        </Col>
        <Col lg={2} className="text-center">
        <label htmlFor="subject">Salud del ave</label>
          <div className={hasValue(product.id) && !hasValueAttribute(product.id, 'quality') ? 'is-invalid':''}>
            <RatingTooltip
              max={3}
              validate={ (a) => {return false;} }
              defaultRating={daily ? daily.quality_3 : (dailyPrev ? dailyPrev.quality_3:'')}
              onChange={rate => {
                handleUpdatePedido(null, {quality_3: rate > 0 ? rate:''}, {...product})
              }}
              ActiveComponent={
                <i
                  className="mdi mdi-star text-warning"
                  style={starStyle}
                />
              }
              InActiveComponent={
                <i
                  className="mdi mdi-star-outline text-muted"
                  style={starStyle}
                />
              }
            />{" "}
          </div>
          <div className="invalid-feedback">Campo requerido</div>
          <AvField
            type="hidden"
            name={'product_id' + product.id}
            value={product.id}
          />
        </Col>
        
      </Row>
      <Row className="p-0">
        <Col lg={3} className="text-right">
          <label htmlFor="email">Observaciones</label>
        </Col>
        <Col lg={9} className="text-center">
          <AvField
            type="text"
            name={'observations' + product.id}
            className="form-control"
            onChange={(event) => { handleUpdatePedido(event, {observations: event.target.value}, {...product}) }}
            value={daily ? daily.observations : (dailyPrev ? dailyPrev.observastions:'')}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="text-center">
          <Row>
            <Col lg={12} className="text-center">
              { daily &&
                <Table className="table table-sm">
                  <thead className="table-light">
                      <tr className="table-dark">
                        <th>Camión</th>
                        <th>Unidades</th>
                      </tr>
                  </thead>
                  <tbody>
                    {(daily?.quantities || []).map((truck, key) => (
                      <tr key={key}>
                        <td>
                          {truck.truck_name}
                        </td>
                        <td>
                          <AvField
                            type="number"
                            name={'quantity' + truck.id}
                            className="form-control"
                            errorMessage="Campo requerido"
                            min={0}
                            value={quantities[truck.id]?.truck_units}
                            onWheel={(e) => e.target.blur() }
                            onChange={(e) => { let newQuantities = {...quantities}; newQuantities[truck.id] = {id: truck.id, truck_units: e.target.valueAsNumber}; setQuantities(newQuantities); }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              }
              <Table className="table table-sm">
                <thead className="table-light">
                    <tr className="table-dark">
                      <th style={{ width:"30px" }}></th>
                      <th>Núm Camiones</th>
                      <th>Unidades/Camión</th>
                    </tr>
                </thead>
                <tbody>
                  {(trucks || []).map((truck, key) => (
                    <tr key={key}>
                      <td>
                        { key > 0 &&
                        <button type="button" className="btn btn-sm btn-danger mt-1" onClick={() => {removeTruck(key)}}>
                          <i className="fas fa-times"></i>
                        </button>
                        }
                      </td>
                      <td>
                        <AvField
                          type="number"
                          name={'quantity' + product.id}
                          className="form-control"
                          required={ daily ? false:hasValue(product.id) }
                          onChange={(event) => { handleUpdateTrucks(key, {quantity: event.target.valueAsNumber}) }}
                          errorMessage="Campo requerido"
                          min={0}
                          value={truck.quantity}
                          onWheel={(e) => e.target.blur() }
                        />
                      </td>
                      <td>
                        <AvField
                          type="number"
                          name={'quantity' + product.id}
                          className="form-control"
                          required={ daily ? false:hasValue(product.id) }
                          onChange={(event) => { handleUpdateTrucks(key, {truck_units: event.target.valueAsNumber}) }}
                          errorMessage="Campo requerido"
                          min={0}
                          value={truck.truck_units}
                          onWheel={(e) => e.target.blur() }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3}>
                      <button type="button" className="btn btn-sm btn-danger w-md" onClick={addTruck}>
                        <i className="fas fa-plus"></i> agregar camión
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Col>
        <Col lg={6} className="text-center">
          <Row>
            <Col lg={12} className="text-center">
              <Table className="table table-sm zonas">
                <thead>
                    <tr className="table-dark">
                        <th style={{ minWidth: "120px" }}>Zona</th>
                        <th>Precio/unidad</th>
                    </tr>
                </thead>
                <tbody>
                  {local_region && (
                  <tr>
                    <td>En Sucursal {local_region.name}</td>
                    <td>
                    <AvField
                      type="number"
                      name="price_local"
                      step={0.1}
                      className="form-control"
                      onChange={(event) => { handleUpdatePrices(event, {region_id: 'local', price: event.target.valueAsNumber}, {...product}) }}
                      errorMessage="Campo requerido"
                      min={0}
                      onWheel={(e) => e.target.blur() }
                    />
                    </td>
                  </tr>
                  )}
                  {(_.orderBy(props.regions, (e) => { return e.parent_id ? e.parent_id + '_' + e.name: e.id + '_'+ e.name}) || []).map((region, key) => (
                    <tr key={key}>
                      <td>{ region.parent_id ?  props.regions.filter(e => {return e.id == region.parent_id})[0].name + ' | ' + region?.name : region?.name }</td>
                      <td>
                        <AvField
                          type="number"
                          name="price"
                          step={0.1}
                          className="form-control"
                          onChange={(event) => { region.parent_id ? 
                            handleUpdatePrices(event, {subregion_id: region.id, region_id: region.parent_id, price_shipped: event.target.valueAsNumber}, {...product}) :
                            handleUpdatePrices(event, {subregion_id: null, region_id: region.id, price_shipped: event.target.valueAsNumber}, {...product})
                          }}
                          errorMessage="Campo requerido"
                          min={0}
                          onWheel={(e) => e.target.blur() }
                          value={ region.parent_id ? 
                              (region.parent_id+'_'+region.id in prices ? prices[region.parent_id+'_'+region.id].price_shipped:''):
                              (region.id+'_'+null in prices ? prices[region.id+'_'+null].price_shipped:'')
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      { props.regions.length > 0 &&
                      <button type="button" className="btn btn-sm btn-danger w-md" onClick={(evt) => { props.toggleModalZones()}}>
                        <i className="fas fa-map-marked-alt"></i>  sub-zonas
                      </button>
                      }
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={12} className="mb-3 text-center">
          <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad || (!daily && pedido.length == 0) }>
            <i className="fas fa-save"></i> Guardar
          </button>
        </Col>
      </Row>
    </AvForm>
    }
    {
      (daily != null && !forceEdit) && 
      <Row className="mb-4 pt-3 pb-3 bg-light">
        <Col lg={5} className="mb-2 text-lg-center text-center">
        <div><b>{daily.product}</b></div>
        <div>Cantidad disponible: <b>{daily.available_product} Pzas</b></div>
        { 'available_product' in daily ? <div>Cantidad Asignada: <b>{daily.quantity-daily.available_product} Pzas</b></div>:'' }
        <div>Peso Promedio: <b>{daily.weight} - {daily.weight_max} Kg.</b></div>
        <div>Observaciones: <b>{daily.observations}</b></div>
        <Row>
          <Col md={4}>
            <div style={{alignSelf: 'flex-end'}}>Pigmento
              <RatingCustom
                max={3}
                disabled={true}
                defaultRating={daily.quality}
                styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                ActiveComponent={
                  <i
                    className="mdi mdi-star text-warning"
                    style={starStyle}
                  />
                }
                InActiveComponent={
                  <i
                    className="mdi mdi-star-outline text-muted"
                    style={starStyle}
                  />
                }
              />{" "}
            </div>
          </Col>
          <Col md={4}>
           <div>Calidad
              <RatingCustom
                max={3}
                disabled={true}
                defaultRating={daily.quality_2}
                styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                ActiveComponent={
                  <i
                    className="mdi mdi-star text-warning"
                    style={starStyle}
                  />
                }
                InActiveComponent={
                  <i
                    className="mdi mdi-star-outline text-muted"
                    style={starStyle}
                  />
                }
              />{" "}
            </div>
          </Col>
          <Col md={4}>
           <div>Salud del ave
            <RatingCustom
              max={3}
              disabled={true}
              defaultRating={daily.quality_3}
              styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
              ActiveComponent={
                <i
                  className="mdi mdi-star text-warning"
                  style={starStyle}
                />
              }
              InActiveComponent={
                <i
                  className="mdi mdi-star-outline text-muted"
                  style={starStyle}
                />
              }
            />{" "}
            </div>
          </Col>
        </Row>
        </Col>
        <Col lg={3} className="mb-2 text-lg-center text-center">
          <Table className="table table-sm">
            <thead className="table-light">
                <tr className="table-dark">
                  <th>Núm Camiones</th>
                  <th>Unidades/Camión</th>
                </tr>
            </thead>
            <tbody>
              {(daily.quantities || []).map((truck, key) => (
                <tr key={key}>
                  <td>
                    {truck.quantity}
                  </td>
                  <td>
                    {truck.truck_units}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col lg={4} className="mb-2 text-lg-center text-center">
         <Table className="table table-sm">
            <thead>
                <tr className="table-dark">
                    <th style={{ minWidth: "120px" }}>Región</th>
                    <th>Precio/unidad</th>
                </tr>
            </thead>
            <tbody>
              {local_region && daily.unit_price && (
                <tr>
                <td>Sucursal { local_region.name }</td>
                <td>
                  $ {daily.unit_price}
                </td>
              </tr>
              )}
              {(daily.prices || []).map((region, key) => (
                <tr key={key}>
                  <td>{ region?.region }</td>
                  <td>
                    $ {region.price && region.price > 0 ? region.price : region.price_shipped}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    }
    </React.Fragment>
  )
}

DistribuitorProviderDailyAvailabilityRow.propTypes = {
  product: PropTypes.object.isRequired,
  farmId: PropTypes.number.isRequired,
  farm: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  regions: PropTypes.array.isRequired,
  daily_availability: PropTypes.object,
  daily_previous: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  providerId: PropTypes.number,
  toggleModalZones: PropTypes.func.isRequired,
  forceEdit: PropTypes.bool
}

export default DistribuitorProviderDailyAvailabilityRow
