import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Alert,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap"

import useInvoice from "common/invoice/hooks/useInvoice"
import GeneralData from "common/invoice/components/GeneralData"
import Issuer from "common/invoice/components/Issuer"
import Client from "common/invoice/components/Client"
import SearchClientModal from "common/invoice/components/SearchClientModal"
import Products from "common/invoice/components/Products"
import ProductsTotals from "common/invoice/components/ProductsTotal"
import ProductModal from "common/invoice/product-modal/ProductModal"
import FormActions from "common/invoice/components/FormActions"
import InvoiceDownloadButtons from "common/invoice/components/InvoiceDownloadButtons"
import ComplementData from "common/invoice/components/complements/ComplementData"
import GlobalInformation from "common/invoice/components/GlobalInformation"

const InvoiceSidebar = ({
  reloadData,
  toggleSidebar,
  isOpenSidebar,
  inititalInvoiceValues,
}) => {
  const { validation, state, methods } = useInvoice(inititalInvoiceValues)

  const [isOpenSearchClientModal, setIsOpenSearchClientModal] = useState(false)

  const isComplementPayment = validation.values.CfdiType === "P"

  const { productRow, cfdiRequest, isOpenProductModal } = state

  const {
    toggleProductModal,
    handleDeleteProduct,
    handleNewProductModal,
    handleEditProductModal,
    onClickGlobalReceiver,
  } = methods

  const toggleSearchClientModal = () =>
    setIsOpenSearchClientModal(!isOpenSearchClientModal)

  useEffect(() => {
    if (state.cfdiRequest.isSubmitted) {
      reloadData()
    }
  }, [state.cfdiRequest.isSubmitted])

  return (
    <>
      <Offcanvas
        style={{ width: "600px" }}
        isOpen={isOpenSidebar}
        direction="end"
        toggle={toggleSidebar}
      >
        <OffcanvasHeader
          toggle={toggleSidebar}
          className="border-bottom border-danger"
        >
          Nueva factura
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            {cfdiRequest.isError ? (
              <Alert color="danger">{cfdiRequest.msgError}</Alert>
            ) : null}

            <GeneralData validation={validation} disableCfdiType={false} />

            {isComplementPayment ? (
              <>
                <Issuer validation={validation} />
                <Client
                  disableRfc
                  disableName
                  disableUseCfdi
                  disableTaxZipCode
                  disableFiscalRegime
                  validation={validation}
                  title={
                    <h5>
                      Cliente
                      <span
                        onClick={onClickGlobalReceiver}
                        role="button"
                        title="Usuario de público en general"
                      >
                        <i
                          className="fa fa-user cursor-pointer ms-2 me-1"
                          style={{ fontSize: 12 }}
                        />
                      </span>
                      <span
                        onClick={toggleSearchClientModal}
                        role="button"
                        title="Buscar cliente"
                      >
                        <i
                          className="fa fa-search cursor-pointer ms-2"
                          style={{ fontSize: 12 }}
                        />
                      </span>
                    </h5>
                  }
                />
                <ComplementData validation={validation} />
              </>
            ) : (
              <>
                <Issuer validation={validation} />
                <Client
                  validation={validation}
                  disableName={false}
                  disableRfc={false}
                  disableTaxZipCode={false}
                  disableFiscalRegime={false}
                  title={
                    <h5>
                      Cliente
                      <span
                        onClick={onClickGlobalReceiver}
                        role="button"
                        title="Usuario de público en general"
                      >
                        <i
                          className="fa fa-user cursor-pointer ms-2 me-1"
                          style={{ fontSize: 12 }}
                        />
                      </span>
                      <span
                        onClick={toggleSearchClientModal}
                        role="button"
                        title="Buscar cliente"
                      >
                        <i
                          className="fa fa-search cursor-pointer ms-2"
                          style={{ fontSize: 12 }}
                        />
                      </span>
                    </h5>
                  }
                />
                {validation.values.Receiver.Rfc === "XAXX010101000" ? (
                  <GlobalInformation validation={validation} />
                ) : null}
                <Products
                  validation={validation}
                  handleDeleteProduct={handleDeleteProduct}
                  handleNewProductModal={handleNewProductModal}
                  handleEditProductModal={handleEditProductModal}
                />
                <ProductsTotals validation={validation} />
              </>
            )}

            {cfdiRequest.data ? (
              <InvoiceDownloadButtons
                stampedInvoice={cfdiRequest.data}
                toggleRightCanvas={toggleSidebar}
              />
            ) : (
              <FormActions
                validation={validation}
                isStampingCfdi={cfdiRequest.isLoading}
                toggleClose={toggleSidebar}
              />
            )}
          </Form>
        </OffcanvasBody>
      </Offcanvas>
      {isOpenProductModal && (
        <ProductModal
          isOpen={isOpenProductModal}
          productRow={productRow}
          toggleModal={toggleProductModal}
          validation={validation}
        />
      )}
      {isOpenSearchClientModal && (
        <SearchClientModal
          isOpen={isOpenSearchClientModal}
          toggleModal={toggleSearchClientModal}
          validation={validation}
        />
      )}
    </>
  )
}

InvoiceSidebar.propTypes = {
  inititalInvoiceValues: PropTypes.any,
  reloadData: PropTypes.func,
  toggleSidebar: PropTypes.func,
  isOpenSidebar: PropTypes.bool,
}

export default InvoiceSidebar
