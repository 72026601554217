import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import './arrows-order.scss';
import { setAdministrativeFilters } from 'store/catalogs/administrative-data/actions';

const ArrowsOrder = ({ column, columnOrder }) => {

    const dispatch = useDispatch();
    const { filters } = useSelector(state => state.AdministrativeData)
    
    const isArrowDownActive = (filters.column === column && filters.order === 'desc');
    const isArrowUpActive = (filters.column === column && filters.order === 'asc');    
    

    return (
        <span className="order arrows-order">
            <span 
                onClick={() => dispatch(setAdministrativeFilters({ column, order: 'desc' }))}
                className={`dropdown arrows-order__asc ${ isArrowDownActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
            <span 
                onClick={() => dispatch(setAdministrativeFilters({ column, order: 'asc' }))}
                className={`dropup arrows-order__desc ${isArrowUpActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
        </span>
    )
}

ArrowsOrder.propTypes = {
    column: PropTypes.string,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};

export default ArrowsOrder;