import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getOrder } from '../../store/orders/actions';

import {
    Row,
    Col,
    Card,
    CardBody,
    Spinner
} from 'reactstrap';

import PageBase from 'components/Common/PageBase';
import OrderHeader from './OrderHeader';
import OrderSummary from './OrderSummary';
import PageNotFound from 'pages/Utility/PageNotFound';

import 'flatpickr/dist/themes/material_red.css';
import MovsSummary from './MovsSummary';


const breadcrumbList = [
    { id: '1', title: 'Pedidos', active: false },
    { id: '2', title: 'Conciliación pedido', active: true }
];

export default function OrderConcileContainer() {   
    const dispatch    = useDispatch();
    const { orderId } = useParams();
    const [movs, setMovs] = useState([]);

    const { orderActive, ui } = useSelector(state => state.Orders);

    const { isLoading, isError } = ui;
    const { id, provider, client, items, order_date, comments, rating, status, status_string } = orderActive;
    
    useEffect(() => {
        dispatch(
            getOrder(orderId)
        );

        //const response = getMovsFromOrder(orderId);
        //setMovs(response.data)

    }, []);

    if(isError) return <PageNotFound />

    return (
        <>  
            <PageBase
                titleMeta="Conciliar pedido"
                titleBread="Pedido - Conciliación"
                itemsBread={ breadcrumbList }
            >
                {
                    isLoading ? <Spinner /> : null
                }          
                { !isLoading && !isEmpty(orderActive) && (
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    { orderActive.status != 'completed' ? 
                                        <div className='alert alert-warning'>
                                            La orden aun no ha sido autorizada
                                        </div> : 
                                        <>
                                        <OrderHeader 
                                            id={ id }
                                            provider={ provider }
                                            client={ client }
                                            status={ status } 
                                            status_string={ status_string}
                                            order_date={ order_date }
                                        />
                                        <OrderSummary 
                                            items={ items }
                                        />
                                        <MovsSummary
                                            items={ movs }
                                        />
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </PageBase>
        </>
    )
}