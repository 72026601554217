import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as _ from "lodash"
import * as Yup from "yup"
import { es } from "yup-locales"

Yup.setLocale(es)

import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import NibbleInputImage from "common/nibble/NibbleInputImage"
import { postConfigUpsertMany, uploadFile } from "helpers/backend_helper"
import useRequest from "hooks/useRequest"

const ENTERPRISE_PRIMARY_COLOR_CONFIG = "ENTERPRISE_PRIMARY_COLOR_CONFIG"
const ENTERPRISE_SECONDARY_COLOR_CONFIG = "ENTERPRISE_SECONDARY_COLOR_CONFIG"
const ENTERPRISE_LOGO_CONFIG = "ENTERPRISE_CONFIG_LOGO_CONFIG"
const STORAGE_URL = process.env.REACT_APP_BACKEND_STORAGE

const ConfigModal = ({ openModal, setOpenModal, rowData, getData }) => {
  const configRequest = useRequest()

  const primaryColorFound = _.find(rowData.configs, {
    name: ENTERPRISE_PRIMARY_COLOR_CONFIG,
  })

  const secondaryColorFound = _.find(rowData.configs, {
    name: ENTERPRISE_SECONDARY_COLOR_CONFIG,
  })

  const logoFound = _.find(rowData.configs, {
    name: ENTERPRISE_LOGO_CONFIG,
  })

  const onSubmit = formValues => {
    const data = {
      configurations: [
        formValues.primaryColorConfig,
        formValues.secondaryColorConfig,
        formValues.logoConfig,
      ],
    }

    configRequest.req(postConfigUpsertMany(data)).then(() => {
      getData()
      setOpenModal(false)
    })
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      primaryColorConfig: {
        name: ENTERPRISE_PRIMARY_COLOR_CONFIG,
        value: primaryColorFound?.value ?? "",
        enterprise_id: rowData.id,
      },
      secondaryColorConfig: {
        name: ENTERPRISE_SECONDARY_COLOR_CONFIG,
        value: secondaryColorFound?.value ?? "",
        enterprise_id: rowData.id,
      },
      logoConfig: {
        name: ENTERPRISE_LOGO_CONFIG,
        value: logoFound?.value ?? "",
        url: logoFound?.value ? `${STORAGE_URL}/${logoFound.value}` : "",
        enterprise_id: rowData.id,
      },
    },
    validationSchema: Yup.object({
      primaryColorConfig: Yup.object({
        name: Yup.string().required(),
        value: Yup.string().required().label("Color primario"),
        enterprise_id: Yup.string().required(),
      }),
      secondaryColorConfig: Yup.object({
        name: Yup.string().required(),
        value: Yup.string().required().label("Color secundario"),
        enterprise_id: Yup.string().required(),
      }),
      logoConfig: Yup.object({
        name: Yup.string().required(),
        value: Yup.string().required().label("Logo"),
        url: Yup.string().nullable(),
        enterprise_id: Yup.string().required(),
      }),
    }),
    onSubmit,
  })

  const postFileFunction = async file => {
    const formData = new FormData()
    formData.append("file", file)

    const response = await uploadFile(formData)

    validation.setFieldValue("logoConfig.url", response.url)

    return response
  }

  console.log({ formValues: validation.values, formErrors: validation.errors })

  return (
    <Modal isOpen={openModal} className="modal-dialog">
      <ModalHeader className="bg-primary">
        Configuración de tema y logo
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
          id="enterprise-config-form"
        >
          <div className="row">
            <NibbleBaseInput
              fieldType="color"
              fieldName="primaryColorConfig.value"
              label="Color primario"
              validation={validation}
              md={6}
            />
            <NibbleBaseInput
              fieldType="color"
              fieldName="secondaryColorConfig.value"
              label="Color secundario"
              validation={validation}
              md={6}
            />
            <NibbleInputImage
              md={12}
              label="Logo"
              fieldName="logoConfig.value"
              validation={validation}
              postFileFunction={postFileFunction}
              fileUrl={validation.values.logoConfig.url}
            />
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          form="enterprise-config-form"
          className="btn btn-danger"
          onClick={() => { }}
          type="submit"
          disabled={configRequest.isLoading}
        >
          {" "}
          <i className="fa fa-save"></i> Guardar
        </Button>
        <Button color="secondary" onClick={() => setOpenModal(false)}>
          {" "}
          <i className="fa fa-times"></i> Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ConfigModal.propTypes = {
  openModal: PropTypes.bool,
  title: PropTypes.string,
  setOpenModal: PropTypes.func,
  getData: PropTypes.func,
  fileName: PropTypes.string,
  rowData: PropTypes.object,
}

export default ConfigModal
