import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import { isEmpty } from 'lodash';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

import {
    Col,
    Row,
    Label,
    Button,
    Form,
    Input,
    Alert,
    Table
} from 'reactstrap';

import { useForm } from 'hooks/useForm';
import { getAdministrativeData } from 'store/catalogs/administrative-data/actions';
import useRequest from 'hooks/useRequest';

import "flatpickr/dist/themes/material_blue.css";
import moment from 'moment';
import { putCost, getProvidersDate, putConfig } from 'helpers/backend_helper';
import { InputGroup } from 'reactstrap';
const nf = new Intl.NumberFormat('es-MX');

const todayDate = moment().format('YYYY-MM-DD');

const initForm = {
    kgs_provider: '0.00',
    pieces_provider: '0',
    prom_provider:'0.00',
    kgs_client: '0.00',
    pieces_client: '0',
    prom_client:'0.00',
    cost:'0.00',
    date:todayDate
};

const ConfigurationsForm = ({ isNew, toggleRightCanvas,setConfigs,configs,getDataConf }) => {

    const dispatch = useDispatch();
    const {
        isError,
        isLoading,
        isSubmitted,
        msgError,
        req
    } = useRequest();

    const [ formErr, setFormErr ] = useState({});
    const [ configsEdit,setConfigsEdit ] = useState(configs);
    const [ movsProv, setMovsProv ] = useState([]);
    
    const options = useSelector(state => state.AccountingAccounts.bankAccounts);

    const save = () => {
        req(putConfig(configsEdit,'billing')).then(async (response) => {
            getDataConf()
        });
    }

    const handlerOnChange = (e,val) =>{
        if(configsEdit[e]){
            setConfigsEdit({...configsEdit, [e]:``})
        }else{
            setConfigsEdit({...configsEdit, [e]:`${e}`})
        }
    }
    
    //console.log('form', form)
    return (
        <>
        <Form onSubmit={ save }>
            { isError ? (
                <Alert className="alert alert-danger">
                    { msgError }
                </Alert>
            ) : '' }
            { isSubmitted ? (
                <Alert className="alert alert-success">
                    { isNew ? 'Registro creado' : 'Registro actualizado' }
                </Alert>
            ) : '' }            
            <Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Banco</th>
                            <th>Selección</th>
                        </tr>
                    </thead>
                    <tbody>
                    { options.map(option => (
                        <tr key={option.id}>
                            <th scope="row" >{option.name}</th>
                            <th scope="row">   
                                <Input
                            checked={  configsEdit[option.id] ? true:false }
                            onChange={ e => {
                                handlerOnChange(option.id,configsEdit[option.id] ? false:true);
                            }}
                            name="pieces_provider"
                            type="checkbox"
                            className={ formErr?.pieces_provider ? 'form-control is-invalid' : 'form-control' }
                        />
                            </th>
                        </tr>
                    ))
                    
                    }
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end">
                    <Button
                        onClick={ toggleRightCanvas }
                        color="secondary"
                        className="btn btn-secondary btn"
                        style={{ marginRight: '10px' }}
                        disabled={ isLoading }
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        className="btn btn-danger btn"
                        disabled={ isLoading }
                        onClick={ e => {
                                save();
                            }}
                    >
                        <i 
                            className={`${isLoading ? '' : 'd-none' } bx bx-loader bx-spin font-size-16 align-middle me-2`}
                        ></i>
                        { isLoading ? 'Guardando' : 'Guardar' }
                    </Button>
                </Col>                
            </Row>
        </Form>
        </>
    )
}

ConfigurationsForm.propTypes = {
    toggleRightCanvas: PropTypes.func,
    dataRow: PropTypes.object,
    isNew: PropTypes.bool,
    configs: PropTypes.object,
    setConfigs: PropTypes.func,
    getDataConf: PropTypes.func
}

export default ConfigurationsForm;