import React from "react"
import PropTypes from "prop-types"
import { Alert, Badge, Button, Table } from "reactstrap"

import CurrencyFormat from "common/nibble/CurrencyFormat"

import _ from "lodash"

const Products = ({
  validation,
  handleDeleteProduct,
  handleNewProductModal,
  handleEditProductModal,
}) => {
  return (
    <div className="pb-4">
      <div className="d-flex justify-content-between pb-2">
        <h5>Productos</h5>
        <Button size="sm" color="primary" onClick={handleNewProductModal}>
          <i className={"bx bx-plus"}></i>
          Agregar
        </Button>
      </div>
      <Table bordered size="sm" responsive>
        <thead>
          <tr>
            <th>Cant.</th>
            <th style={{ minWidth: 180 }}>Producto</th>
            <th>Precio Unitario</th>
            <th>Subtotal</th>
            <th>Descuentos</th>
            <th>Impuestos</th>
            <th>Total</th>
            <th style={{ minWidth: 100 }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {validation.values.Items.map((item, index) => {
            const taxesObject = _.keyBy(item.Taxes, "Name")

            const errorsFound = _.get(validation.errors, `Items[${index}]`, {})
            const errorsArrStr = _.values(errorsFound)

            return (
              <tr key={item.Id}>
                <td>{item?.Quantity}</td>
                <td>
                  <span className="d-block">{item?.Description}</span>
                  {item?.ProductCode && (
                    <Badge className="mb-1 product-badge">
                      CLP: {item.ProductCode}
                    </Badge>
                  )}
                  {item.UnitCode && (
                    <Badge className="ms-1 product-badge">
                      CLU: {item.UnitCode}
                    </Badge>
                  )}
                  <ul className="mt-2">
                    {errorsArrStr.map(error => (
                      <li
                        key={error}
                        className="text-sm text-danger"
                        style={{ fontSize: "11px" }}
                      >
                        {error}
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <CurrencyFormat value={item?.UnitPrice} />
                </td>
                <td className="text-end">
                  <CurrencyFormat value={item?.Subtotal} />
                </td>
                <td className="text-end">
                  <CurrencyFormat value={item?.Discount} />
                </td>
                <td>
                  {item?.TaxObject && (
                    <Badge className="d-block product-badge mb-1">
                      ObjetoImp <br />
                      {item.TaxObject}
                    </Badge>
                  )}
                  {taxesObject["IVA"] && (
                    <Badge className="d-block product-badge mb-1">
                      IVA <br />
                      {taxesObject["IVA"].Rate}
                    </Badge>
                  )}
                  {taxesObject["IVA RET"] && (
                    <Badge className="d-block product-badge mb-1">
                      IVA RET <br />
                      {taxesObject["IVA RET"].Rate}
                    </Badge>
                  )}
                  {taxesObject["ISR"] && (
                    <Badge className="d-block product-badge mb-1">
                      ISR <br />
                      {taxesObject["ISR"].Rate}
                    </Badge>
                  )}
                  {taxesObject["IEPS"] && (
                    <Badge className="d-block product-badge">
                      IEPS <br />
                      {taxesObject["IEPS"].Rate}
                    </Badge>
                  )}
                </td>
                <td className="text-end">
                  <CurrencyFormat value={item?.Total} />
                </td>
                <td>
                  {item?.IsEditable ? (
                    <span>
                      <Button
                        onClick={() => handleEditProductModal(item)}
                        className="btn btn-sm btn-danger"
                        title="Edit"
                      >
                        <i className="fas fa-edit"></i>
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-sm btn-danger ms-1"
                        onClick={() => {
                          handleDeleteProduct(item.Id)
                        }}
                        title="Borrar"
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </span>
                  ) : null}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {validation.submitCount &&
      validation.errors?.Items &&
      typeof validation.errors.Items === "string" ? (
        <Alert color="danger" className="mt-4">
          {validation.errors?.Items}
        </Alert>
      ) : null}
    </div>
  )
}

Products.propTypes = {
  validation: PropTypes.object,
  handleDeleteProduct: PropTypes.func,
  handleNewProductModal: PropTypes.func,
  handleEditProductModal: PropTypes.func,
}

export default Products
