import React from 'react';
import PropTypes from 'prop-types';

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableTotal({ totals,multiple }) {
    console.log(multiple)
    const {
        total,
        discount,
        decrease,
        other_charges,
        payment,
        net,
        percialDiario,
    } = totals;

    return (
        
            <tr className='table-head'>
                <th colSpan={multiple ? 2:1} className="text-danger f-bold text-right">Totales</th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(total) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(decrease) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(discount) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(other_charges) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(payment) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(percialDiario) }
                </th>
                
                <th>
                    
                </th>
            </tr>
        
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object,
    multiple: PropTypes.bool,
};