import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const StackedColumnChart = ({ periodData, dashboardData }) => {
  
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1
      },
      zoom: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%"
        // endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      show: true,
      categories: dashboardData.graph.categories/* [
        "13 mar",
        "14 mar",
        "15 mar",
        "16 mar",
        "17 mar",
        "18 mar",
        "19 mar"
      ] */,
      labels: {
        show: true
      }
    },
    colors: ["#f46a6a", "#f1b44c", "#34c38f"],
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    }
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        //series={[...periodData]}
        series={[
          {
            name: "Pollo",
            data: dashboardData.graph.pollo
          },
          {
              name: "Huevo",
              data: dashboardData.graph.huevo
          },
        ]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  );
}

export default StackedColumnChart;

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
  options: PropTypes.any,
  dashboardData: PropTypes.object
}