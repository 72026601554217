import React from "react"
import CurrencyFormat from "common/nibble/CurrencyFormat"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { InvoiceStatus } from "constants/invoice"

export const getColumns = ({
  showPdfCfdi,
  downloadCfdi,
  onCancelCfdi,
  onAddComplement,
  downloadCancellationXml,
}) => {
  return [
    {
      name: "Fecha",
      selector: row => row.date,
      wrap: true,
      width: "160px",
    },
    {
      name: "UUID",
      selector: row => row.uuid,
      wrap: true,
      width: "320px",
    },
    {
      name: "Folio factura",
      selector: row => `${row?.serie} ${row?.folio}`,
      wrap: true,
      width: "120px",
    },
    {
      name: "Tipo de factura",
      selector: row => row.type,
      width: "120px",
    },
    {
      name: "Método de pago",
      selector: row => {
        if (row?.type?.includes("P")) return "CP"

        const typeParts = row?.payment_method?.split(" - ")

        return typeParts?.[0]
      },
      width: "125px",
      center: true,
    },
    {
      name: "Emisor RFC",
      selector: row => row?.payload?.Issuer?.Rfc,
      wrap: true,
      width: "135px",
    },
    {
      name: "Receptor RFC",
      selector: row => row?.payload?.Receiver?.Rfc,
      wrap: true,
      width: "135px",
    },
    {
      name: "Receptor",
      selector: row => row?.payload?.Receiver?.Name,
      wrap: true,
      width: "200px",
    },
    {
      name: "Venta folio",
      center: true,
      selector: row => row?.mov_client?.folio || "-",
    },
    {
      name: "Estatus",
      cell: row => {
        const isActive = row.status === InvoiceStatus.Active
        return (
          <span
            className={`me-1 badge rounded-pill ${
              isActive ? "bg-success" : "bg-danger"
            }`}
          >
            {row.status_str}
          </span>
        )
      },
    },
    {
      name: "Total",
      selector: row => <CurrencyFormat value={row.total} prefix="$" />,
      width: "125px",
      right: true
    },
    {
      name: "Tipo",
      selector: row => row.invoice_type,
      wrap: true,
      width: "125px",
    },
    {
      name: "Acciones",
      center: true,
      cell: invoice => {
        const isPPDInvoice = invoice.payment_method?.includes("PPD")

        return (
          <UncontrolledDropdown>
            <DropdownToggle
              href="#"
              className="card-drop action-points"
              tag="i"
            >
              <i
                style={{ cursor: "pointer" }}
                className="mdi mdi-dots-horizontal font-size-14"
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-enddd actions-menu">
              {isPPDInvoice ? (
                <DropdownItem
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    onAddComplement(invoice)
                  }}
                >
                  <i className="mdi mdi-ballot-outline font-size-16 text-info me-1" />{" "}
                  Agregar complemento
                </DropdownItem>
              ) : null}

              {invoice?.sat_provider_id && (
                <DropdownItem
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    showPdfCfdi.handleShowPdf(invoice.sat_provider_id)
                  }}
                >
                  <i className="mdi mdi-monitor-eye font-size-16 text-warning me-1" />{" "}
                  Ver factura
                </DropdownItem>
              )}

              {invoice?.sat_provider_id ? (
                <DropdownItem
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    downloadCfdi.downloadInvoice("pdf", invoice.sat_provider_id)
                  }}
                >
                  <i className="mdi mdi-file-pdf-box-outline font-size-16 text-info me-1" />{" "}
                  Descargar PDF
                </DropdownItem>
              ) : null}

              {invoice?.sat_provider_id ? (
                <DropdownItem
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    downloadCfdi.downloadInvoice("xml", invoice.sat_provider_id)
                  }}
                >
                  <i className="mdi mdi-xml font-size-16 text-info me-1" />{" "}
                  Descargar XML
                </DropdownItem>
              ) : null}

              {invoice?.sat_provider_id &&
              invoice?.status === InvoiceStatus.Active ? (
                <DropdownItem
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    onCancelCfdi(invoice)
                  }}
                >
                  <i className="mdi mdi-bell-cancel font-size-16 text-danger me-1" />{" "}
                  Cancelar CFDI
                </DropdownItem>
              ) : null}

              {invoice?.sat_provider_id &&
              invoice?.status === InvoiceStatus.Canceled ? (
                <DropdownItem
                  href="#"
                  onClick={e => {
                    e.preventDefault()

                    downloadCancellationXml.downloadXml(invoice?.id)
                  }}
                >
                  <i className="mdi mdi-xml font-size-16 text-info me-1" />{" "}
                  Descargar XML Cancelación
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      },
    },
  ]
}
