import React, { useEffect, useState } from "react"

import useRequest from "hooks/useRequest"
import { getRoles, postTogglePermissionForRole } from "helpers/backend_helper"

const useRoles = () => {
  const [roles, setRoles] = useState([])
  const [permissions, setPermissions] = useState([])

  const reqRoles = useRequest()
  const reqUpdate = useRequest()

  const updateRolePermissionState = (permissionId, roleId) => {
    const newRoles = roles.map(role => {
      if (role.id !== roleId) return role

      const existPermission = role.permissions.find(p => p.id === permissionId)

      return {
        ...role,
        permissions: existPermission
          ? role.permissions.filter(p => p.id !== permissionId)
          : [...role.permissions, { id: permissionId }],
      }
    })

    setRoles(newRoles)
  }

  const onClickPermissionForRole = (permissionId, roleId) => {
    reqUpdate
      .req(postTogglePermissionForRole(roleId, { permissionId }))
      .then(() => {
        updateRolePermissionState(permissionId, roleId)
      })
  }

  const fetchRoles = () => {
    reqRoles.req(getRoles()).then(resp => {
      setRoles(resp.roles)
      setPermissions(resp.permissions)
    })
  }

  useEffect(fetchRoles, [])

  return {
    state: {
      roles,
      permissions,
      isLoading: reqRoles.isLoading,
      isUpdating: reqUpdate.isLoading,
    },
    events: {
      onClickPermissionForRole,
    },
  }
}

export default useRoles
