import React from 'react';
import PropTypes from 'prop-types';

import CommentForm from './CommentForm';
import CommentItem from './CommentItem';

const Comments = ({ comments }) => {

    return (
      <div>
          
        <div className="mt-5">
            <h5 className="font-size-14 fw-bold">
                <i className="bx bx-message-dots text-muted align-middle me-1"></i>{" "}
                Comentarios
            </h5>
            <div>
                {
                    comments.map(({ id, comment, user, created_at }) => (
                        <CommentItem 
                            key={ id }
                            user={ user.name }
                            comment={ comment }
                            created_at={ created_at }
                        />
                    ))
                }            
            </div>

            <CommentForm />
        </div>
      </div>
    )
}

Comments.propTypes = {
    comments: PropTypes.array
};

export default Comments;
