import React, { useRef, useState } from 'react'
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Alert } from "reactstrap";
import { postRegion, putRegion } from 'helpers/backend_helper';

const ModalForm = ({ isOpenModal, setIsOpenModal, region, getData }) => {    

    const [error, setError] = useState (null)
    const [completed, setCompleted] = useState (null)
    const [btnSaveLoad, setBtnSaveLoad] = useState (false)
    const refForm = useRef('form-user-create');

    const handleValidSubmit = async (event, values) => {
        setBtnSaveLoad(true)
        try {
          region.id ? await putRegion({id:region.id, ...values}) : await postRegion({...values});
          setError(null)
          setCompleted('Se completo la operación')
          refForm && refForm.current.reset();
          getData();
        } catch(error) {
          setError(error.toString())
          setCompleted(null)
        }
        setBtnSaveLoad(false)
    }

    return (
        <div>
            <Modal
                isOpen={isOpenModal}
                toggle={() => {
                  setIsOpenModal(false);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                      {region.id ? 'Editar' : 'Crear'} región
                    </h5>
                    <button
                      type="button"
                      onClick={() => {
                        setIsOpenModal(false);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    <div className="modal-body">
                        <AvForm
                            ref={refForm}
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                            }}
                        >
                            {error ? <Alert color="danger">{error}</Alert> : null}
                            {completed ? <Alert color="success">{completed}</Alert> : null}
                            
                            <div className="mb-3">
                            <AvField
                                name="name"
                                label="Nombre"
                                value={region.name}
                                type="text"
                                required
                                errorMessage="Campo requerido"
                                placeholder=""
                                />
                            </div>
                            <input name="unit_id" type="hidden" /> 
                            <div className="text-center">
                                <button type="submit" className="btn btn-danger w-md" disabled={ btnSaveLoad }>
                                    <i className="fas fa-save"></i> Guardar
                                </button>
                                <button 
                                    onClick={() => {
                                        setIsOpenModal(false);
                                    }} 
                                    className="btn w-md btn-danger ms-2"
                                >
                                    <i className="fas fa-reply"></i> Atrás
                                </button>
                            </div>
                        </AvForm>
                    </div>
                    {/* <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                            setIsOpenModal(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary "
                        >
                            Save changes
                        </button>
                    </div> */}
            </Modal>
        </div>
    )
}

ModalForm.propTypes = {
    isOpenModal: PropTypes.bool,
    setIsOpenModal: PropTypes.func,
    region: PropTypes.object,
    getData: PropTypes.func
};

export default ModalForm