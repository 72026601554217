import moment from "moment"
import * as Yup from "yup"
import { es } from "yup-locales"
import { productValidation } from "./components/product-modal/static"

Yup.setLocale(es)

export const initialValues = {
  mov_client_id: '',
  NameId: "",
  CfdiType: "I",
  PaymentForm: "",
  PaymentMethod: "",
  Currency: "MXN",
  Exportation: "", //opcional
  Date: moment().format("YYYY-MM-DD hh:mm:ss"),
  ExpeditionPlace: "",
  Folio: "",
  Issuer: {
    FiscalRegime: "",
    Rfc: "",
    Name: "",
  },
  Receiver: {
    Rfc: "",
    Name: "",
    FiscalRegime: "",
    CfdiUse: "",
    TaxZipCode: "",
  },
  Items: [],
  //Relations: { Type: "", Cfdis: [] }, // https://apisandbox.facturama.mx/guias/cfdi40/nota-credito
}

export const invoiceValidation = Yup.object({
  NameId: Yup.string().nullable(),
  CfdiType: Yup.string().required().label("Tipo de Cfdi"),
  PaymentForm: Yup.string().required().label("Forma de pago"),
  PaymentMethod: Yup.string().required().label("Método de pago"),
  Currency: Yup.string().required().label("Moneda"),
  Exportation: Yup.string().nullable(),
  Date: Yup.date().required().label("Fecha"),
  ExpeditionPlace: Yup.string().required().label("C.P. Expedición"),
  Folio: Yup.string().required().label("Folio"),
  Issuer: Yup.object({
    FiscalRegime: Yup.string().required().label("Régimen fiscal"),
    Rfc: Yup.string().required().label("RFC"),
    Name: Yup.string().required().label("Razón social"),
  }),
  Receiver: Yup.object({
    Rfc: Yup.string().required().label("RFC"),
    Name: Yup.string().required().label("Razón social"),
    FiscalRegime: Yup.string().required().label("Régimen fiscal"),
    CfdiUse: Yup.string().required().label("Uso de CFDI"),
    TaxZipCode: Yup.string().required().label("C.P."),
  }),
  Items: Yup.array().of(productValidation).min(1),
  Relations: Yup.object().when(["IsDebitNote", "IsCreditNote"], {
    is: (IsDebitNote, IsCreditNote) => IsDebitNote || IsCreditNote,
    then: () =>
      Yup.object({
        Type: Yup.string().nullable(),
        Cfdis: Yup.array()
          .of(
            Yup.object().shape({
              Uuid: Yup.string().required().label("UUID Cfdi relacionado"),
            })
          )
          .min(1)
          .max(1),
      }),
    otherwise: schema => schema.nullable(),
  }),
  IsDebitNote: Yup.bool().nullable(),
  IsCreditNote: Yup.bool().nullable(),
})
