import axios from 'axios';
import { useRef } from 'react';

const useCancelableRequest = () => {
  const cancelTokenSource = useRef(null);

  const getCancelTokenSource = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    cancelTokenSource.current = axios.CancelToken.source();
    return cancelTokenSource.current.token;
  };

  return getCancelTokenSource;
};

export default useCancelableRequest
