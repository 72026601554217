import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button, Modal, Label } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom"
import Select from 'react-select';

import BtnDownloadExcel from '../Reports/common/BtnDownloadExcel';
import BtnDownloadPdf from '../Reports/common/BtnDownloadPdf';
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ClipLoader from "react-spinners/ClipLoader";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "./datatables.scss"
import { deleteAccount, deleteUser, getAccountAccountingsAll,getAccountAccountingsExport } from '../../../helpers/backend_helper';
import BreadcrumbNav from "components/Common/BreadcrumbNav";
import { ACCOUNT_TYPES } from "./form-create";

const breadcrumbItems = [
  { id:'1', title: 'Administrativo', link: '', active: false },
  { id:'2', title: 'Cuentas Contables', link: '', active: true }
];

const CatalogAccountingList = () => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const [userType, setUserType] = useState(user.user?.type || user.type)
  const [userTypeRol, setUserTypeRol] = useState(user.user?.type_rol || user.type_rol)
  const [filters, setFilters] = useState({search: '', type: ''});
  const [currentPage, setCurrentPage] = useState(1);
  
  const [error, setError] = useState (null)
  const [modal_standard, setmodal_standard] = useState(false);
  const [row, setRow] = useState(null)

  async function handleDelete(evt, row) {
    setLoading(true);
    try {
      const response = await deleteAccount(row.id);
      setData(data.filter( item => item.id !== row.id))
    } catch(error) {
      setError(error.toString())
    }
    setLoading(false);
    tog_standard();
  }  

  const header_excel = {
    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  };

  const header_pdf = {
      'Accept': 'application/pdf'
  };
   
  const downloadFile = (type = 'excel') => getAccountAccountingsExport ({
        params: filters,
        headers: (type === 'excel') ? header_excel : header_pdf,

    },type);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function actionButtons(cell, row, rIdx) {
    return userTypeRol != 3 ? (    
      <span>
        <Link className="btn btn-sm btn-danger" to={{ pathname: "accountings/form-edit", state: row }} title="Edit">
          <i className="fas fa-edit"></i>
        </Link>
        <Button 
          type="button" 
          className="btn btn-sm btn-danger ms-1" 
          onClick={(evt) => { setmodal_standard(true); setRow(row); } } 
          title="Eliminar">
          <i className="fas fa-times"></i>
        </Button>
      </span>
    ) : ''
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const columns = [{
    dataField: 'related.skey',
    text: 'Clave',
    sort: true,
    formatter: (cell, row) => {
      return row?.related?.skey || row?.product?.skey
    }
  }, {
    dataField: 'name',
    text: 'Nombre',
    sort: true
  }, {
    dataField: 'type_string',
    text: 'Tipo',
    sort: true
  }, {
    dataField: 'balance_initial',
    text: 'Saldo Inicial',
    sort: true
  }, {
  }, {
    dataField: 'movements',
    text: 'Cuenta con Movimientos',
    sort: true,
    formatter: (cell, row) => {
      return (
        <span className={row?.hasMovements ? 'text-success' : '' }>
          {row?.hasMovements ? 'Sí' : 'No'}
        </span>
      );
    }
  }, {
    dataField: 'id',
    text: 'Acciones',
    formatter: actionButtons
  }]; 

  // Table Data
  let [color, setColor] = useState("#f46a6a");
  const [loading, setLoading] = useState (false);
  const [data, setData] = useState ([]);

  useEffect(() => {
    async function getData()  {
      setLoading(true);
      const response = await getAccountAccountingsAll();
      setLoading(false);
      setData(response);
    }

    getData();
  }, []);

  async function getDataReport(type)  {
    const response = await getAccountAccountingsExport(type);
  }

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const dataFiltered = data.filter(item => item.name.toLowerCase().includes(filters.search.toLowerCase()))
                            .filter(item => item.type.includes(filters.type))
  const pageOptions = {
    sizePerPage: 10,
    totalSize: dataFiltered.length, // replace later with size(customers),
    custom: true,
    page: currentPage,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (data).length }];

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cuentas Contables</title>
        </MetaTags>
        <div className="container-fluid">
          <BreadcrumbNav title="Cuentas Contables"  items={breadcrumbItems}/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={dataFiltered}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={dataFiltered}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="3">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder='Buscar'
                                      searchText={filters.search}
                                      onSearch={(search) => {
                                        setFilters({...filters, search})
                                        setCurrentPage(1)
                                      }}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="3">
                                <Select
                                  value={ACCOUNT_TYPES.find(item => item.id === filters.type)}
                                  onChange={event => {
                                    let type = '';
                                    if(event){
                                      type = event.id
                                    }
                                    setFilters({...filters, type});
                                    setCurrentPage(1)
                                  }}
                                  options={ACCOUNT_TYPES}
                                  getOptionLabel={ option => option.name }
                                  getOptionValue={ option => option.id }
                                  placeholder="Buscar por tipo"
                                  classNamePrefix="select2-selection"
                                  isClearable={ true }
                                />
                              </Col>
                        
                              <Col md="4" className="text-end">
                                  <BtnDownloadExcel downloadExcel={ downloadFile }/>
                                  &nbsp;
                                  <BtnDownloadPdf downloadPdf={ downloadFile }/>
                                  &nbsp;
                                { userTypeRol != 3 ? 
                                  <Link to="/administrative/accountings/form-create" className="btn btn-danger btn-sm mb-3" style={{marginLeft: '5px'}}>
                                    <i className="fas fa-plus"></i> Crear Cuenta
                                  </Link> : ''
                                }
                                
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={ loading ? <ClipLoader color={color} loading={loading} size={30} /> : 'Sin datos' }
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Borrar Cuenta
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            ¿Está seguro de borrar la cuenta contable?
          </p>
          <p className="text-danger f-bold">
            ADVERTENCIA <br />
            Al borrar esta cuenta, las ventas, compras, movimientos bancarios y gastos asociados serán eliminados.
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            onClick={async (evt) => { await handleDelete(evt, row) }}
            type="button"
            className="btn btn-danger"
          >
            <i className="fas fa-check"></i> Borrar
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CatalogAccountingList
