import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Toast,
} from "reactstrap"
import { PropTypes } from "prop-types"
import * as _ from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { useState } from "react"
import { useRef } from "react"
import {
  getRegions,
  postProvider,
  validateZipCode,
} from "helpers/backend_helper"
import { Alert } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import FarmForm from "../common/FarmForm"
import ProviderForm from "../common/ProviderForm"
import BreadcrumbNav from "components/Common/BreadcrumbNav"

import toastr from "toastr"

const breadcrumbItems = [
  { id: "1", title: "Catálogos", link: "", active: false },
  { id: "2", title: "Proveedores", link: "/catalogs/providers", active: false },
  { id: "3", title: "Crear", link: "", active: true },
]

const CatalogProviderFormCreate = props => {
  const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false)
  const [farms, setFarms] = useState([])
  const [curFarm, setCurFarm] = useState({})
  const [model, setModel] = useState({ farms: [] })
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)
  const refForm = useRef("form-user-create")
  const [loading, setLoading] = useState(false)
  const [regions, setRegions] = useState([])
  const [rfc, setRfc] = useState("")

  const handleValidSubmit = async (event, values) => {
    const { cp, company_name, contact_name, skey, phone, email, ...address } =
      values

    try {
      const resp = await validateZipCode({ cp })
      if (!resp.cp) {
        toastr.error("Código postal no válido")
        return
      }
    } catch (error) {
      toastr.error(error)
    }

    try {
      values["farms"] = farms
      values["rfc"] = rfc
      address["cp"] = cp
      console.log(values)
      setBtnSaveLoad(true)
      const response = await postProvider({ ...values, address })
      response.farms.forEach(e => {
        e.regions.forEach(f => {
          f.label = f.name
        })
      })
      setModel(response)
      setError(null)
      setCompleted("Se completo la operación")
      console.log(refForm)
      refForm && refForm.current.reset()
      setFarms([])
    } catch (error) {
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const addFarm = (indexFarm, edit = false) => {
    const farm = indexFarm != null ? farms[indexFarm] : {}
    farm.edit = edit
    farm.indexFarm = indexFarm
    setCurFarm(farm)
    setOffCanvasIsOpen(true)
  }

  const delFarm = indexFarm => {
    const newFarms = [...farms]
    newFarms.splice(indexFarm, 1)
    setFarms(newFarms)
  }

  const toggleRightCanvas = () => {
    setOffCanvasIsOpen(!offCanvasIsOpen)
  }

  const onCompleteAddFarm = farm => {
    console.log("add Farm", farm)
    if ("edit" in farm && farm.edit) {
      farms[farm.indexFarm] = farm
      setFarms([...farms])
    } else {
      console.log({
        farm,
      })
      setFarms([...farms, farm])
    }
    toggleRightCanvas()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de Proveedores</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Proveedores" items={breadcrumbItems} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? (
                      <Alert color="success">{completed}</Alert>
                    ) : null}

                    <ProviderForm provider={{}} rfc={rfc} setRfc={setRfc} />

                    <table className="table table-responsive table-bordered table-sm">
                      <thead>
                        <tr>
                          <th colSpan={5} className="text-center">
                            Sucursales
                          </th>
                        </tr>
                        <tr>
                          <th>Sucursal</th>
                          <th>Entrega en sucursal</th>
                          <th>Región de sucursal</th>
                          <th>Zonas de cobertura</th>
                          <th>Productos</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {farms.map((farm, key) => (
                          <tr key={key}>
                            <td>{farm.name}</td>
                            <td>{farm.local_delivery ? "Sí" : "No"}</td>
                            <td>{farm.region ? farm.region.name : ""}</td>
                            <td>
                              {farm.regions
                                ? farm.regions.reduce((prev, cur) => {
                                    return (
                                      prev +
                                      ("label" in cur ? cur.label : cur.name) +
                                      " - "
                                    )
                                  }, "")
                                : ""}
                            </td>
                            <td>
                              {farm.products.map(prod => prod.name).join(", ")}
                            </td>
                            <td>
                              <Button
                                type="button"
                                className="btn btn-warning btn-sm"
                                onClick={() => addFarm(key, true)}
                              >
                                <i className="fas fa-edit"></i>
                              </Button>{" "}
                              <Button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => delFarm(key)}
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                        {farms.length == 0 && (
                          <tr>
                            <td colSpan={6} className="text-center">
                              Sin sucursales
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={6} className="text-center">
                            <Button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => addFarm()}
                            >
                              <i className="fas fa-plus"></i> Agregar sucursal
                            </Button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-sm btn-danger w-md"
                        disabled={btnSaveLoad}
                      >
                        <i className="fas fa-save"></i> Guardar
                      </button>
                      <Link
                        to="/catalogs/providers"
                        className="btn w-md btn-sm btn-danger ms-2"
                      >
                        <i className="fas fa-reply"></i> Atrás
                      </Link>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Offcanvas
          isOpen={offCanvasIsOpen}
          direction="end"
          toggle={toggleRightCanvas}
        >
          <OffcanvasHeader toggle={toggleRightCanvas}>
            {"edit" in curFarm && curFarm.edit
              ? "Modificar Sucursal"
              : "Nueva Sucursal"}
          </OffcanvasHeader>
          <OffcanvasBody>
            <FarmForm
              regions={regions}
              entityFarm={curFarm}
              editMode={"edit" in curFarm ? curFarm.edit : false}
              handleComplete={onCompleteAddFarm}
            />
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  )
}

CatalogProviderFormCreate.propTypes = {
  provider: PropTypes.object,
}

export default CatalogProviderFormCreate
