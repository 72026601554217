import React from "react"
import { Row, Col, Card, CardBody, Spinner, Table, FormGroup, Label, InputGroup } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../datatables.scss"
import { useState } from "react";
import { useEffect } from "react";
import { getDistribuitorProviderOrders } from '../../../helpers/backend_helper';
import { getClassNameOrderStatus } from "helpers/order_helper";
import BreadcrumbNav from "components/Common/BreadcrumbNav";
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import * as moment from 'moment';
import { getProviderOrders } from 'helpers/backend_helper';

const breadcrumbItems = [
  { id:'1', title: 'Distribuidor', link: '#', active: false },
  { id:'2', title: 'Pedidos Proveedor', link: '#', active: true }
];

function buttonActions(cell, row, rIdx) {
  return (    
    <span>
      <Link className="btn btn-sm btn-danger" to={{ pathname: "/order-provider/" + row.id, state: row }} title="Ver detalle">
        <i className="fas fa-receipt"></i>
      </Link>
    </span>
  )
}

const initDate = moment().format('Y-MM-DD')

const ProviderOrders = () => {
  const today = new Date()
  const [date, setDate] = useState(initDate)
  const [isLoading, setIsLoading] = useState(false)
  // Table Data
  const [data, setData] = useState ([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getData()  {
      setLoading(true);
      const response = await getProviderOrders({date});
      setLoading(false);
      setData(response);
    }

    getData();
  }, [date]);

  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pedidos Proveedor</title>
        </MetaTags>
        <div className="container-fluid">
          <BreadcrumbNav title="Pedidos Proveedor"  items={breadcrumbItems}/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12}>                            
                        <FormGroup className="mb-0">
                            <Row>
                              <Col lg={4} className="text-end"><Label>Fecha</Label></Col>
                                <Col lg={4}>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder=""
                                            onChange={(value, parsed) => setDate(parsed)}
                                            options={{
                                                dateFormat: 'Y-m-d',
                                                locale: Spanish,
                                                defaultDate: today
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>                        
                    </Col>
                    {isLoading && <div className="text-center"><Spinner animation="border" variant="danger" style={{color:"#f46a6a"}}/></div>}
                  </Row>

                  <div className="table-responsive">
                    <Table className="table-nowrap">
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th>Proveedor</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (data || []).map( e => (
                            <tr key={e[0].id}>
                              <td>{e[0].date}</td>
                              <td>{e[0].provider.company_name}</td>
                              <td>
                                <Link className="btn btn-sm btn-danger" to={{ pathname: "/order-provider/" + e[0].id }} title="Ver detalle">
                                  <i className="fas fa-receipt"></i>
                                </Link>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProviderOrders
