import React from "react"
import { Row, Col, Card, CardBody, Button, Modal } from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import ClipLoader from "react-spinners/ClipLoader"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../datatables.scss"
import { useState } from "react"
import { useEffect } from "react"
import { getProducts, postProduct, putProduct } from "helpers/backend_helper"
import { deleteProduct } from "helpers/backend_helper"
import { Alert } from "reactstrap"
import BreadcrumbNav from "components/Common/BreadcrumbNav"
import usePage from "hooks/usePage"
import ProductModal from "./product-modal"
import { initModel, productValidation } from "./static"
import { useFormik } from "formik"
import useRequest from "hooks/useRequest"

const breadcrumbItems = [
  { id: "1", title: "Catálogos", link: "", active: false },
  { id: "2", title: "Productos", link: "", active: true },
]

const CatalogProductList = props => {
  const { page, sizePerPage, setPage } = usePage()

  const [error, setError] = useState(null)
  const [row, setRow] = useState(null)
  const [modal_standard, setmodal_standard] = useState(false)

  const [dataRow, setDataRow] = useState(initModel)
  const [productModal, setProductModal] = useState(false)

  const request = useRequest()

  const createProduct = () => {
    setDataRow(initModel)
    setProductModal(true)
  }

  const editProduct = row => {
    const parsedRow = {
      ...row,
      tax_configuration: row.tax_configuration ? row.tax_configuration : initModel.tax_configuration,
    }

    setDataRow(parsedRow)
    setProductModal(true)
  }

  const toggleProductModal = () => {
    setProductModal(!productModal)
  }

  const onSubmit = formValues => {
    if (!formValues.id) {
      request.req(postProduct(formValues)).then(() => {
        validation.resetForm()
        toggleProductModal()
        fetchData()
      })
    } else {
      request.req(putProduct(formValues)).then(() => {
        toggleProductModal()
        fetchData()
      })
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: dataRow,
    validationSchema: productValidation,
    onSubmit,
  })

  async function handleDelete(evt, row) {
    setLoading(true)
    try {
      const response = await deleteProduct(row.id)
      setData(data.filter(item => item.id !== row.id))
    } catch (error) {
      setError(error.toString())
    }
    setLoading(false)
    tog_standard()
  }

  function buttonActions(cell, row, rIdx) {
    return (
      <span>
        {/* <Link className="btn btn-sm btn-danger" to={{ pathname: "/catalogs/products/form-edit", state: { ...row, page } }} title="Edit">
          <i className="fas fa-edit"></i>
        </Link> */}
        <Button
          onClick={() => editProduct(row)}
          className="btn btn-sm btn-danger"
          title="Edit"
        >
          <i className="fas fa-edit"></i>
        </Button>
        <Button
          type="button"
          className="btn btn-sm btn-danger ms-1"
          onClick={evt => {
            tog_standard()
            setRow(row)
          }}
          title="Borrar"
        >
          <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  buttonActions.displayName = "buttonEdit"

  const columns = [
    {
      dataField: "skey",
      text: "Clave",
      sort: true,
    },
    {
      dataField: "name",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "description",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "unit.name",
      text: "Unidad",
      sort: true,
    },
    {
      dataField: "position",
      text: "Orden",
      sort: true,
    },
    {
      dataField: "id",
      text: "Acciones",
      formatter: buttonActions,
    },
  ]

  // Table Data
  let [color, setColor] = useState("#f46a6a")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [totalSize, setTotalSize] = useState(0)

  const fetchData = () => {
    async function getData() {
      setLoading(true)
      try {
        const response = await getProducts()
        setData(response)
        setTotalSize(response.length)
        setError(null)
      } catch (error) {
        setError(error.toString())
      }
      setLoading(false)
    }

    getData()
  }

  useEffect(fetchData, [])

  const defaultSorted = [
    {
      dataField: "position",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage,
    totalSize,
    custom: true,
    page,
    onPageChange: setPage,
    hideSizePerPage: true,
  }

  const { SearchBar } = Search

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  const afterSearch = results => {
    setTotalSize(results.length)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo Productos</title>
        </MetaTags>
        <div className="container-fluid">
          <BreadcrumbNav title="Productos" items={breadcrumbItems} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error ? <Alert color="danger">{error}</Alert> : null}

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={data}
                        search={{ afterSearch }}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder="Buscar"
                                      onSearch={data => {
                                        setPage(1)
                                        toolkitProps.searchProps.onSearch(data)
                                      }}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="8" className="text-end">
                                {/* <Link to="/catalogs/products/form-create" className="btn btn-danger btn-sm">
                                  <i className="fas fa-plus"></i> Crear Producto
                                </Link> */}
                                <Button
                                  onClick={createProduct}
                                  className="btn btn-danger btn-sm"
                                >
                                  <i className="fas fa-plus"></i> Crear Producto
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={
                                      loading ? (
                                        <ClipLoader
                                          color={color}
                                          loading={loading}
                                          size={30}
                                        />
                                      ) : (
                                        "Sin datos"
                                      )
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Borrar Producto
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>¿Está seguro de borrar el producto?</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            onClick={evt => handleDelete(evt, row)}
            type="button"
            className="btn btn-danger"
          >
            <i className="fas fa-check"></i> Borrar
          </button>
        </div>
      </Modal>
      {productModal && (
        <ProductModal
          isOpen={productModal}
          toggleModal={toggleProductModal}
          validation={validation}
          request={request}
          dataRow={dataRow}
        />
      )}
    </React.Fragment>
  )
}

export default CatalogProductList
