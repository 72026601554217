import React from "react"
import PropTypes from "prop-types"
import useRequest from "hooks/useRequest"
import { cancelCfdi } from "helpers/backend_helper"
import { Alert, Form, Modal, Row } from "reactstrap"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import { useFormik } from "formik"

import * as Yup from "yup"
import { es } from "yup-locales"

Yup.setLocale(es)

const cancelationStatus = [
  {
    id: "01",
    name: "01 - Comprobante emitido con errores con relación",
  },
  {
    id: "02",
    name: "02 - Comprobante emitido con errores sin relación",
  },
  {
    id: "03",
    name: "03 - No se llevó a cabo la operación",
  },
  {
    id: "04",
    name: "04 - Operación nominativa relacionada con una factura global",
  },
]

const CancelCfdiModal = ({
  invoice,
  toggleModal,
  isOpen,
  fetchCfdis = null,
}) => {
  const cancelCfdiRequest = useRequest()

  const onSubmit = formValues => {
    const config = {
      params: {
        ...formValues,
        mov_client_id: invoice.mov_client_id,
      },
    }

    cancelCfdiRequest.req(cancelCfdi(config)).then(resp => {
      fetchCfdis && fetchCfdis()
    })
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      cfdiId: invoice.sat_provider_id,
      motive: null,
      uuidReplacement: "",
    },
    validationSchema: Yup.object({
      cfdiId: Yup.string().required().label("Cfdi Id"),
      motive: Yup.string().required().label("Motivo"),
      uuidReplacement: Yup.string()
        .when("motive", {
          is: "01",
          then: () =>
            Yup.string().required("UUID del comprobante es obligatorio"),
          otherwise: () => Yup.string().nullable(),
        })
        .label("UUID del comprobante"),
    }),
    onSubmit,
  })

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop="static">
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Cancelar CFDI
        </h5>
        <button
          type="button"
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
          id="create-form-product"
        >
          {cancelCfdiRequest.data ? (
            <Alert color="success">{cancelCfdiRequest.data?.description}</Alert>
          ) : null}
          {cancelCfdiRequest.isError ? (
            <Alert color="danger">{cancelCfdiRequest.msgError}</Alert>
          ) : null}
          <Row>
            <NibbleBaseInput
              fieldType="select"
              fieldName="motive"
              label="Motivo"
              selectItems={cancelationStatus}
              validation={validation}
              md={12}
              placeHolder="Seleccionar"
            />
            {validation.values.motive === "01" ? (
              <NibbleBaseInput
                fieldType="text"
                fieldName="uuidReplacement"
                label="UUID del comprobante que sustituye al cancelado"
                validation={validation}
                md={12}
              />
            ) : null}
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={toggleModal}
          className="btn btn-secondary "
          data-dismiss="modal"
          disabled={cancelCfdiRequest.isLoading}
        >
          <i className="fas fa-times"></i> Cerrar
        </button>
        {!cancelCfdiRequest.data ? (
          <button
            type="submit"
            className="btn btn-danger"
            form="create-form-product"
            disabled={cancelCfdiRequest.isLoading}
          >
            <i className="fas fa-check"></i> Guardar
          </button>
        ) : null}
      </div>
    </Modal>
  )
}

CancelCfdiModal.propTypes = {
  invoice: PropTypes.object,
  toggleModal: PropTypes.func,
  fetchCfdis: PropTypes.any,
  isOpen: PropTypes.bool,
}

export default CancelCfdiModal
