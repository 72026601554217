import React from "react"
import { Card, CardBody } from "reactstrap"

import PageBase from "components/Common/PageBase"
import useRoles from "./useRoles"
import FullSpinner from "components/Common/FullSpinner"
import PermissionsTable from "./components/PermissionsTable"

const breadcrumbList = [
  { id: "1", title: "Administrativo", active: false },
  { id: "2", title: "Reportes", active: false },
  { id: "3", title: "Estados de Cuenta Bancarios", active: true },
]

const CatalogRoles = () => {
  const { state, events } = useRoles()

  return (
    <>
      {state.isLoading && <FullSpinner />}
      <PageBase
        titleMeta="Roles"
        titleBread="Roles"
        itemsBread={breadcrumbList}
      >
        <Card>
          <CardBody>
            <PermissionsTable
              roles={state.roles}
              isUpdating={state.isUpdating}
              permissions={state.permissions}
              onClickPermissionForRole={events.onClickPermissionForRole}
            />
          </CardBody>
        </Card>
      </PageBase>
    </>
  )
}

export default CatalogRoles
