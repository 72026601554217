import {
  tax_configuration,
  tax_configuration_validation,
} from "common/sat/Taxes"
import * as Yup from "yup"
import { es } from "yup-locales"

Yup.setLocale(es)

export const initConfig = {
  Id: "", //
  ProductCode: "",
  Description: "",
  UnitCode: "",
  TaxObject: "",
  tax_configuration,
}

export const configValidation = Yup.object({
  ProductCode: Yup.string().nullable().required().label("Código SAT"),
  Description: Yup.string().required().label("Producto"),
  UnitCode: Yup.string().nullable().required().label("Unidad SAT"),
  TaxObject: Yup.string().nullable().required().label("Objeto Impuesto"),
  tax_configuration: tax_configuration_validation,
})

export const cardBodyStyles = { minHeight: "300px" }
