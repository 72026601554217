import React, { useEffect, useRef, useState } from 'react'
import { AvField, AvForm } from 'availity-reactstrap-validation';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import { Col, Input, Label, Row } from 'reactstrap'

import AddressForm from "pages/Catalogos/common/AddressForm";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useForm } from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { postRegion, validateZipCode } from 'helpers/backend_helper';
import { MEXICO_STATES } from '../../helpers/states';


const FormFarm = ({ farm, regions, handleFarms, setRegions, setFarm }) => {

    const reqRegion = useRequest();
    const [inputRegion, setInputRegion] = useState('');
    const [zonas, setZonas] = useState([]);

    const handleValidSubmit = async(event, values) => {
        
        if(!farm.region) return;

        const addressFields = ['street', 'ext_num', 'int_num', 'location', 'city', 'country', 'cp', 'state', 'region_id']
        const address = _.pick(values, addressFields)
        const newFarm = {...farm, ..._.omit(values, addressFields), address: address};

        handleFarms(newFarm);
    }

    const handleCheckRegionsDelivery = (idxRegion, value) => {
        idxRegion = parseInt(idxRegion)
        let newregions = [...farm.regions];
        if (value) {  // add
          const element = _.find(newregions, {id: idxRegion});
          if (!element) {
            newregions.push( _.find(regions, {id: idxRegion}) )
          }
        } else {    // remove
          newregions = _.remove(newregions, (region) => { return region.id != idxRegion })
        }
        
        setFarm({...farm, regions: newregions})
      }

    const createRegion = async (name) => {
        const { req } = reqRegion;

        const region = await req(postRegion({ name }));
        
        setRegions(prev => [...prev, region])
        
        return region;
    }

    const handleSelectFarm = async (select) => {
        if(!select?.__isNew__){
            setFarm({
                ...farm,
                region_id: select.value,
                region: _.find(regions, {id: select.value})
            })

            return;
        }

        const region = await createRegion(select.value);
        setFarm({
          ...farm,
          region_id: region.id,
          region
      })
    }

    const submitInputRegion = async() => {      
      if(!inputRegion.trim()) return;

      await createRegion(inputRegion)
      setInputRegion('')
    }

    const handleZones = (e) => {
      setZonas(e);
    }

  return (
        <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
            }}
        >            
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <AvField
                          name="name"
                          label="Nombre"
                          value={ farm.name }
                          type="text"
                          required
                          errorMessage="Campo requerido"
                          placeholder=""
                        />
                    </div>
                  </Col>
                </Row>
                <AddressForm entityAddress={ farm.address } />
                <Row className="mb-3">
                  <Col md={7}>
                    <h5 className="font-size-14 mb-4">
                      <i
                        className="mdi mdi-arrow-right text-danger mr-1"></i> ¿Entrega en sucursal?
                    </h5>
                  </Col>
                  <Col md={4}>
                    <div className="form-check form-check-danger mb-3" style={{display:"inline-block", width:"120px"}}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={"local_delivery"}
                        defaultChecked={farm.local_delivery}
                        //onChange={ (value) => handleCheckHasLocalDelivery(value.currentTarget.id, value.currentTarget.checked) }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"local_delivery"}
                      >
                        Sí
                      </label>
                    </div>
                  </Col>
                  
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Zonas de cobertura</Label>
                      <Select
                        //isClearable
                        id="formrow-InputState"
                        isMulti={true}
                        options={ MEXICO_STATES.map( ({ id, name }) => ({ label: name, value: name}) ) }
                        onChange={ handleZones }
                        //defaultValue={ {label: farm.region?.name, value: farm.region_id} }
                        value = { zonas }
                      />
                      { farm.region_id == null ? <div className="invalid-feedback">Campo requerido</div>:''}
                    </div>
                  </Col>
                  
                </Row>
                <div className="text-center">
                  <button type="submit" className="btn btn-sm btn-danger w-md">
                    <i className="fas fa-plus"></i>Agregar
                  </button>
                </div>
                </AvForm>
  )
}

FormFarm.propTypes = {
    farm: PropTypes.object,
    regions: PropTypes.array,
    handleFarms: PropTypes.func,
    setRegions: PropTypes.func,
    setFarm: PropTypes.func
}

  
export default FormFarm