import React from "react"
import PropTypes from 'prop-types'
import { Col, Label, FormGroup, InputGroup, Button, Row, Alert, Table } from "reactstrap"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_red.css"
import { useEffect, useState } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { postClientOrder } from "helpers/backend_helper"
import RatingCustom from "./RatingCustom"
import { getClassNameOrderStatus } from "helpers/order_helper"
import getStorageUser from "helpers/api_helper"
import { postDistribuitorClientOrder } from '../../helpers/backend_helper';
import moment from "moment"
import { Spanish } from 'flatpickr/dist/l10n/es.js';
const starStyle = {}


const today = moment().format('YYYY-MM-DD');
const tomorrow = moment(today, "YYYY-MM-DD").add('days', 1).format('YYYY-MM-DD');

const initMinTime = '19:00';

const enable =  [
  {
    from: today,
    to: tomorrow
  }
];

//const defaultDate = `${today} ${initMinTime}`;
const defaultDate = null;

const ClientOrderRow = props => {
  //console.log(props)
  const [state, setState] = useState([...props.rows])
  const [order, setOrder] = useState(props.order)
  const [values, setValues] = useState({
    provider_id: null,
    orders: {},
    timeDelivery: '',
  })
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)
  const [pickLocal, setPickLocal] = useState(false)
  const [minTime, setMinTime] = useState(initMinTime)

  if (props.order != order)
    setOrder(props.order);

  if (props.rows != state)
    setState(props.rows);

  const isValidPedido = (data) => {
    let productsValids = 0;
    //console.log('isValidPedido', data)
    for (const key in data.orders) {
      if (Object.hasOwnProperty.call(data.orders, key)) {
        const element = data.orders[key];
        if (parseInt(element.value) > 0 ) {
          productsValids++;
        }
      }
    }
    //console.log('productsValids', productsValids)
    if (productsValids == 0)
      throw Error('Debes ordenar al menos un producto.')
    
    if (state[0].provider.with_delivery && values.timeDelivery == '')
      throw Error('Debes colocar un horario de entrega')

    return true;
  }

  const handleDateTimePicker = (selectDates, dateStr, instance) => {
    const date = dateStr.split(' ')[0];
    setMinTime(date === tomorrow ? '00:00' : initMinTime);

    console.log({
      selectDates, dateStr, instance
    })
    setValues({...values, timeDelivery: dateStr})
  }

  const save = async (data) => {
    try {
      console.log('data', data)
      const response = props.enterprise_id ? 
        await postDistribuitorClientOrder({...data, enterprise_id: props.enterprise_id, date: props.date, address_id: props.address }) : 
        await postClientOrder({...data, date: props.date, address_id: props.address})
      console.log('response', response)
      props.onSubmit({...response})
      setCompleted('Se completo la operación')
      setError(null)
    } catch(error) {
      console.log(error.toString())
      setError(error.toString())
      setCompleted(null)
    }
  }

  const valuesToObj = (values, pickLocal = false) => {
    const response = {}
    for (const key in values) {
      if(key[0] == 'l') continue;
      if (Object.hasOwnProperty.call(values, key)) {
        const element = values[key];
        response[key] = {value: element, local: pickLocal, region_id: props.region}
      }
    }

    for (const key in values) {
      if(key[0] !== 'l') continue;
      const realKey = key.substring(1);
      if (Object.hasOwnProperty.call(values, realKey)) {
        const element = values[realKey];
        response[realKey] = {...response[realKey], local: true}
      }
    }

    return response;
  }

  const handleValidSubmit = async (e, v) => {
    try {
      setBtnSaveLoad(true)
      const data = {
        ...values,
        provider_id: state[0].provider.id,
        farm_id: state[0].farm.id,
        orders: valuesToObj(v, pickLocal),
      };
      console.log('data', data);
      setValues(data);
      
      isValidPedido(data);
      await save(data)
      //props.onSubmit(data)
      setError(null)
    } catch (e) {
      setError(e.toString())
    }
    setBtnSaveLoad(false)
  }

  const handleCheckPickLocal = (value) => {
    setPickLocal(value)
  }

  const hasProduct = (state) => {
    const available = state.map( (formDaily) => {
      return formDaily.available_product;
    })

    return available.reduce((a, b) => a + b, 0) > 0
  }

  /* console.log('address', props.address);
  console.log('order', order);
  console.log('productos', state); */
  return (
    <React.Fragment>

    { !order && state.length > 0 &&
    <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}
    >
      { hasProduct(state) ?
      <Row className="bg-light mt-3">
        <Row>
          <Col lg={12} className="text-center">
              <div className="mt-3">
                <b>{ state[0].provider.company_name } - Punto de distribución { state[0].farm.name }</b>
              </div>
              {error ? <Alert color="danger" className="mt-3">{error}</Alert> : null}
          </Col>
        </Row>
          
          { !order && state[0].farm.local_delivery ?
            <Row className="mt-3">
              <Col lg={4} className="text-end">
                <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                  Recoger en Sucursal
                </label>
              </Col>
              <Col md={7}>
                <div className="form-check form-check-danger" style={{display:"inline-block", width:"120px"}}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"local_delivery"}
                    defaultChecked={false}
                    onChange={ (value) => handleCheckPickLocal(value.currentTarget.checked) }
                  />
                  <label
                    className="form-check-label"
                    htmlFor={"local_delivery"}
                  >
                    Sí
                  </label>
                </div>
              </Col>
            </Row> : ''
          }

          { !pickLocal && !order && state[0].provider &&
            <Row className="mt-3">
              <Col lg={4} className="mb-3 text-end">
                <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                  Hora deseada de recepción (Formato 24 hrs)
                </label>
              </Col>
              <Col lg={6} className="text-center">
                <FormGroup>
                  <InputGroup>
                      <Flatpickr
                          className="form-control d-block"
                          placeholder="Seleccionar horario"
                          options={{
                            enableTime: true,
                            //noCalendar: false,
                            //dateFormat: "H:i",
                            time_24hr: true,
                            //onChange: handleTimeDelivery,
                            onChange: handleDateTimePicker,
                            enable,
                            defaultDate,
                            minTime,
                            locale: Spanish
                          }}
                      />
                      <div className="input-group-append">
                          <span className="input-group-text">
                          <i className="mdi mdi-clock-outline" />
                          </span>
                      </div>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          }
          {
            (pickLocal) && 
            <Row className="mt-3">
              <Col lg={12} className="text-center">
                <Alert color="info" className="mt-3">Se recoje en sucursal</Alert>
              </Col>
            </Row>
          }
          {
            order && 
            <div className="text-center pt-2">Hora de recepción solicitada: <b>{order.delivery_date}</b></div>
          }
          <Row style={{}}>
            <Col lg={12} className="mb-3">
              {(state || []).map((formDaily, keyDaily) => (
                formDaily.available_product > 0 ?
              <Row key={keyDaily} className="mb-4">
                <div className="bg-dark text-light text-center p-1 pb-1"><b>{formDaily.product}</b></div>
                <Col lg={8} className="mb-2 text-center">
                  <div>Disponible: <b>{formDaily.available_product} Pzas</b></div>
                  <div>Peso Promedio: <b>{formDaily.weight} - {formDaily.weight_max} Kg.</b></div>
                  { 
                    <div>Precio con entrega a {formDaily.prices.find( e => { return e.region_id == props.region})?.region}: <b>${formDaily.prices.find( e => { return e.region_id == props.region})?.unit_price_shipped }</b></div>
                  }
                  { state[0].farm.local_delivery && formDaily.unit_price > 0 ?
                    <div>Precio en sucursal: <b>${formDaily.unit_price}</b></div> : ''
                  }
                  <Row>
                    <Col md={4} className="">
                      <div style={{alignSelf: 'flex-end'}}>Pigmento
                        <RatingCustom
                          max={3}
                          disabled={true}
                          defaultRating={formDaily.quality}
                          styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                          ActiveComponent={
                            <i
                              className="mdi mdi-star text-warning"
                              style={starStyle}
                            />
                          }
                          InActiveComponent={
                            <i
                              className="mdi mdi-star-outline text-muted"
                              style={starStyle}
                            />
                          }
                        />{" "}
                      </div>
                    </Col>
                    <Col md={4} className="">
                      <div>Calidad
                        <RatingCustom
                          max={3}
                          disabled={true}
                          defaultRating={formDaily.quality_2}
                          styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                          ActiveComponent={
                            <i
                              className="mdi mdi-star text-warning"
                              style={starStyle}
                            />
                          }
                          InActiveComponent={
                            <i
                              className="mdi mdi-star-outline text-muted"
                              style={starStyle}
                            />
                          }
                        />{" "}
                      </div>
                    </Col>
                    <Col md={4} className="">
                      <div>Salud del ave
                        <RatingCustom
                          max={3}
                          disabled={true}
                          defaultRating={formDaily.quality_3}
                          styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                          ActiveComponent={
                            <i
                              className="mdi mdi-star text-warning"
                              style={starStyle}
                            />
                          }
                          InActiveComponent={
                            <i
                              className="mdi mdi-star-outline text-muted"
                              style={starStyle}
                            />
                          }
                        />{" "}
                      </div>
                    </Col>
                  </Row>
                  
                </Col>
                
                <Col lg={4} className="mb-2 text-lg-center text-center">
                  <AvField
                    label="Ordenar"
                    name={'' + formDaily.id}
                    type="number"
                    errorMessage="El valor debe estar dentro del disponible"
                    min="0"
                    max={formDaily.available_product}
                    onWheel={(e) => e.target.blur() }
                  />
                </Col>
              </Row> : ''
              ))}
            </Col>
          </Row>
          <Row className="">
            <Col lg={12} className="mb-3 text-center">
              { order == null  &&
                <button type="submit" className="btn btn-danger w-md btn-sm ms-3" disabled={ btnSaveLoad }>
                    <i className="fas fa-list-alt"></i> Solicitar Pedido
                </button>
              }
            </Col>
          </Row>
      </Row>: ''
      }
    </AvForm>
    }
    { order && state.length > 0 &&
      <Row className="bg-light mt-3">
        <Row>
          <Col lg={12} className="text-center">
              <div className="mt-3">
                <b>{ state[0].provider.company_name } - Sucursal { state[0].farm.name }</b>
                {
                  order ?
                  <span className={"badge rounded-pill float-end bg-" + getClassNameOrderStatus(order.status)}>
                  {order.status_string}
                  </span> : ''
                }
                
              </div>
              {error ? <Alert color="danger" className="mt-3">{error}</Alert> : ''}
          </Col>
        </Row>

        {
          (pickLocal) && 
          <Row className="mt-3">
            <Col lg={12} className="text-center">
              <Alert color="info" className="mt-3">Se recoje en sucursal</Alert>
            </Col>
          </Row>
        }
        {
          (order && order.items[0]?.delivered) ? <div className="text-center pt-1">Hora de recepción solicitada: <b>{order.delivery_date}</b></div> : ''
        }
        <Row style={{paddingTop:'15px'}}>
          <Col lg={12} className="mb-3">
            <Table className="table table-sm">
              <thead className="table-light">
                <tr className="table-dark">
                  <th>Producto</th>
                  <th>Calidad</th>
                  <th>Peso Prom.</th>
                  <th>Pedido</th>
                  <th>Precio unitario</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
              {(order.items || []).map((formDaily, key) => (
                <tr key={key}>
                  <td>
                    <b>{formDaily.product}</b><br />
                    { formDaily.delivered ? 
                    <div>Con entrega</div> :
                    <div>En sucursal</div>
                    }
                  </td>
                  <td className="text-center">
                      Pigmento
                      <RatingCustom
                        max={3}
                        disabled={true}
                        defaultRating={formDaily.quality}
                        styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                        ActiveComponent={
                          <i
                            className="mdi mdi-star text-warning"
                            style={starStyle}
                          />
                        }
                        InActiveComponent={
                          <i
                            className="mdi mdi-star-outline text-muted"
                            style={starStyle}
                          />
                        }
                      />{" "}
                      Calidad
                      <RatingCustom
                        max={3}
                        disabled={true}
                        defaultRating={formDaily.quality_2}
                        styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                        ActiveComponent={
                          <i
                            className="mdi mdi-star text-warning"
                            style={starStyle}
                          />
                        }
                        InActiveComponent={
                          <i
                            className="mdi mdi-star-outline text-muted"
                            style={starStyle}
                          />
                        }
                      />{" "}

                      Salud del ave
                      <RatingCustom
                        max={3}
                        disabled={true}
                        defaultRating={formDaily.quality_3}
                        styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                        ActiveComponent={
                          <i
                            className="mdi mdi-star text-warning"
                            style={starStyle}
                          />
                        }
                        InActiveComponent={
                          <i
                            className="mdi mdi-star-outline text-muted"
                            style={starStyle}
                          />
                        }
                      />{" "}
                  </td>
                  <td>{formDaily.weight} - {formDaily.weight_max} Kg.</td>
                  <td>
                    {formDaily.quantity} {formDaily.unit}
                  </td>
                  <td>
                    $ { formDaily.delivered ? formDaily.unit_price_shipped : formDaily.unit_price }
                  </td>
                  <td>
                    <b>$ { formDaily.total }</b>
                  </td>
                </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Row>
    }
    </React.Fragment>
  )
}

ClientOrderRow.propTypes = {
  rows: PropTypes.array,
  order: PropTypes.object,
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  enterprise_id: PropTypes.number,
  date: PropTypes.string.isRequired,
  region: PropTypes.number.isRequired,
  address: PropTypes.number.isRequired
}

export default ClientOrderRow




