import React from "react"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import moment from "moment"
import * as _ from "lodash"
import { Link } from "react-router-dom"

import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import CustomTable from "common/nibble/CustomTable"
import { useState } from "react"
import { getAdministrativeData, getConfigFindOne } from "helpers/backend_helper"
import { useEffect } from "react"
import CurrencyFormat from "common/nibble/CurrencyFormat"

const startOfMonth = moment().startOf("M").format("YYYY-MM-DD")
const endOfMonth = moment().endOf("M").format("YYYY-MM-DD")

const globalConfig = "BILLING_CONFIGURATION_GLOBAL_INVOICE"

const MovsClientSearchModal = ({
  isOpenModal,
  toggleModal,
  onSelectMovsClient,
}) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [movsClientSelected, setMovsClientSelected] = useState({})

  const [config, setConfig] = useState(null)
  const [isLoadingConfig, setIsLoadingConfig] = useState(true)

  const movsClientSelectedArr = Object.values(movsClientSelected).filter(
    item => item.selected
  )
  const totalMovsClientSelected = _.sumBy(movsClientSelectedArr, ({ total }) =>
    Number(total)
  )

  const onSubmit = () => {
    onSelectMovsClient(movsClientSelectedArr)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      page: 1,
      per_page: 10000,
      total: 0,
      dates: `${startOfMonth}/${endOfMonth}`,
    },
    onSubmit: function () { },
  })

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      wrap: true,
      width: "80px",
      style: {},
    },
    {
      name: "Cliente",
      selector: row => row?.client_account?.name,
      wrap: true,
      style: {},
    },
    {
      name: "Proveedor",
      selector: row => row?.mov_provider?.provider_account?.name,
      wrap: true,
      style: {},
    },
    {
      name: "Folio interno",
      selector: row => row?.folio,
      wrap: true,
      width: "100px",
      style: {},
    },
    {
      name: "Folio proveedor",
      selector: row => row?.mov_provider?.folio,
      wrap: true,
      width: "120px",
      style: {},
    },
    {
      name: "Fecha",
      selector: row => row?.mov_date,
      wrap: true,
      width: "100px",
    },
    {
      name: "Total",
      right: true,
      selector: row => <CurrencyFormat value={row?.total} prefix="$" />,
      wrap: true,
      width: "150px",
    },
    {
      name: "Acciones",
      center: true,
      selector: row => (
        <input
          style={{ cursor: "pointer" }}
          className="form-check-input"
          type="checkbox"
          id={"local_delivery"}
          //defaultChecked={}
          checked={movsClientSelected[row.id]?.selected ? true:false}
          onChange={e => onSelectSale(e, row.id)}
        />
      ),
      wrap: true,
      width: "80px",
    },
  ]

  const selectNoneSales = () => {
    const newSelectedItems = { }

    data.forEach(item => {
      if (newSelectedItems[item.id] === undefined) {
        newSelectedItems[item.id] = {
          ...item,
          selected: false,
        }
      }
    })

    setMovsClientSelected(newSelectedItems)
  }

  const selectAllSales = () => {
    const newSelectedItems = { }

    data.forEach(item => {
      if (newSelectedItems[item.id] === undefined) {
        newSelectedItems[item.id] = {
          ...item,
          selected: true,
        }
      }
    })

    setMovsClientSelected(newSelectedItems)
  }

  const onSelectSale = (e, id) => {
    const newSelectedItems = {
      ...movsClientSelected,
    }

    newSelectedItems[id].selected = e.target.checked

    setMovsClientSelected(newSelectedItems)
  }

  const initializeSelectedItems = (data, salesSelected) => {
    const newSelectedItems = { ...salesSelected }

    data.forEach(item => {
      if (newSelectedItems[item.id] === undefined) {
        newSelectedItems[item.id] = {
          ...item,
          selected: true,
        }
      }
    })

    setMovsClientSelected(newSelectedItems)
  }

  const fetchData = salesSelected => {
    setIsLoading(true)
    const config = {
      params: validation.values,
    }

    getAdministrativeData("administrative/clients/jlist-invoice", config)
      .then(resp => {
        validation.setFieldValue("total", resp.data.total)

        const movsClient = resp.data.data
        setData(movsClient)

        initializeSelectedItems(movsClient, salesSelected)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const clearSelectedSalesOnChangeFilter = () => {
    setMovsClientSelected({})
    fetchData({})
  }

  const fetchGlobalConfig = () => {
    const config = {
      params: {
        name: globalConfig,
      },
    }

    getConfigFindOne(config)
      .then(config => {
        if (config?.id) {
          setConfig(config)
        }
      })
      .finally(() => {
        setIsLoadingConfig(false)
      })
  }

  useEffect(() => {
    //fetchData(movsClientSelected)
  }, [validation.values.page])
  useEffect(clearSelectedSalesOnChangeFilter, [validation.values.dates])
  useEffect(fetchGlobalConfig, [])

  return (
    <Modal isOpen={isOpenModal} style={{ minWidth: '80%' }}>
      <ModalHeader>Seleccionar ventas para la factura</ModalHeader>
      <ModalBody>
        {!isLoadingConfig && !config ? (
          <Alert color="warning">
            Debe completar la configuración de facturacion / conceptos
            predeterminados / factura global para poder realizar la factura
            global, puedes configurarla{" "}
            <Link to="/billing-configuration" className="alert-link">
              aquí
            </Link>
            .
          </Alert>
        ) : null}
        <CustomTable
          maxHeight="400px"
          validation={validation}
          columns={columns}
          data={data}
          showPaginationRowsPerPageOptions={false}
          loading={isLoading}
          filters={
            <Row>
              <NibbleBaseInput
                fieldType="date-range"
                fieldName="dates"
                label="Periodo"
                validation={validation}
                md={4}
              />
              <Col md={8} className="text-end">
                <Button size="sm" color="primary" onClick={() => selectAllSales()}> Seleccionar todo</Button>
                <Button className="ms-2" size="sm" color="primary" onClick={() => selectNoneSales()}> Quitar todo</Button>
              </Col>
            </Row>
          }
        />
        <p className="text-end">
          Ventas seleccionadas <strong>{movsClientSelectedArr.length}</strong>
        </p>
        <p className="text-end">
          Total:{" "}
          <strong>
            <CurrencyFormat value={totalMovsClientSelected} prefix="$" />
          </strong>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger"
          onClick={onSubmit}
          type="button"
          disabled={movsClientSelectedArr.length === 0 || !config}
        >
          {" "}
          <i className="fa fa-save"></i> Continuar
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          {" "}
          <i className="fa fa-times"></i> Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

MovsClientSearchModal.propTypes = {
  isOpenModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  onSelectMovsClient: PropTypes.func,
}

export default MovsClientSearchModal
