import React from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Spinner,
} from "reactstrap"
import { PropTypes } from 'prop-types';
import "flatpickr/dist/themes/material_red.css"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useState } from "react";
import { useRef } from "react";
import { getDistributorConfigs, putDistributorConfigs } from "helpers/backend_helper";
import { Alert } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import RangeTime from '../../../components/Common/RangeTime';
import { useEffect } from "react";

const ConfigsFormEdit = (props) => {

  const [model, setModel] = useState ({})
  const [error, setError] = useState (null)
  const [loading, setLoading] = useState(false)
  const [configs, setConfigs] = useState ([])
  const [rows, setRows] = useState(null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')

  useEffect(() => {
    async function getData()  {
      setLoading(true);
      const response = await getDistributorConfigs();
      setLoading(false);
      setConfigs(response.configs);
      setRows(response.enterprises.filter(e => {return e.isActivated}));
    }
    getData()
  }, [])

  const toArrayModel = () => {
    const response = [];
    for (const key in model) {
      response.push({...model[key]})
    }
    return response;
  }

  const handleValidSubmit = async (event) => {
    console.log('model', model)
    setBtnSaveLoad(true)
    try {
      const response = await putDistributorConfigs({ data: toArrayModel() })
      setConfigs(response.configs);
      setRows(response.enterprises);
      setModel({})
      setError(null)
      setCompleted('Se completo la operación')
    } catch(error) {
      console.log(error)
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const handleChange = (values) => {
    let newModel = {...model}
    newModel[values.id] = values  
    setModel(newModel)
    console.log(newModel)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Configuraciones</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Distribuidor" breadcrumbItem="Configuración" breadcrumbSubItem="Editar">
            <Breadcrumbs breadcrumbItem="Editar" />
          </Breadcrumbs>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {error ? <Alert color="danger">{error}</Alert> : null}
                  {completed ? <Alert color="success">{completed}</Alert> : null}
                  {loading && <Spinner animation="border" variant="danger" style={{color:"#f46a6a"}}/>}

                  { rows &&
                  <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <Row>
                      <RangeTime 
                        id="providers"
                        label='Proveedores'
                        startTime={configs.find(e => { return e.name == 'PROVIDERS_TIME_START' }).value}
                        endTime={configs.find(e => { return e.name == 'PROVIDERS_TIME_END' }).value}
                        onChange={handleChange} />
                    </Row>

                    {(rows || []).map((row, key) => (
                      <>
                      <Row key={key}>
                        <RangeTime 
                          id={row.id}
                          label={ 'Cliente ' + row.company_name }
                          startTime={ row.pivot.start }
                          endTime={ row.pivot.end }
                          onChange={handleChange}
                          fee_per_unit={ row.fee_per_unit }
                          isClient={true}
                        />
                      </Row>
                      </>
                    ))}

                    <div className="text-center mt-5">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> Guardar
                      </button>
                    </div>
                  </AvForm>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

ConfigsFormEdit.propTypes = {
  provider: PropTypes.object,
};

export default ConfigsFormEdit
