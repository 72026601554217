import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import * as _ from "lodash"
import Select from "react-select"

import { getClients } from "helpers/backend_helper"

const SearchClientModal = ({ isOpen, toggleModal, validation }) => {
  const [clients, setClients] = useState([])

  const onChange = enterprise => {
    if (!enterprise) return

    validation.setFieldValue("Receiver.CfdiUse", enterprise?.use_cfdi)
    validation.setFieldValue("Receiver.Name", enterprise?.company_name)
    validation.setFieldValue("Receiver.Rfc", enterprise?.rfc)
    validation.setFieldValue("Receiver.FiscalRegime", enterprise?.tax_regime)
    validation.setFieldValue(
      "Receiver.TaxZipCode",
      enterprise?.invoice_address?.cp
    )

    toggleModal()
  }

  const fetchClients = () => {
    getClients().then(resp => {
      setClients(resp)
    })
  }

  useEffect(fetchClients, [])

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop="static">
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Buscar cliente
        </h5>
        <button
          type="button"
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Select
          options={clients}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.company_name}
          onChange={onChange}
        />
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={toggleModal}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          <i className="fas fa-times"></i> Cerrar
        </button>
      </div>
    </Modal>
  )
}

SearchClientModal.propTypes = {
  isOpen: PropTypes.bool,
  isIssuer: PropTypes.bool,
  request: PropTypes.object,
  productRow: PropTypes.object,
  validation: PropTypes.object,
  stampedInvoice: PropTypes.object,
  toggleModal: PropTypes.func,
  handleEditProductModal: PropTypes.func,
  enterpriseId: PropTypes.any,
  onChange: PropTypes.any,
}

export default SearchClientModal
