import { useState } from 'react';
import * as moment from 'moment';
import { omit } from 'lodash';


export const useForm = ( initialState = {} ) => {
    
    const [values, setValues] = useState(initialState);

    const reset = ( NewFormState = initialState ) => {
        setValues( NewFormState );
    }


    const handleInputChange = ({ target }) => {

        setValues({
            ...values,
            [ target.name ]: target.value
        });

    }

    const handleSpecificInput = ({ key, value }) => {
        setValues(prev => ({
            ...prev,
            [key]: value
        }));
    }
    
    const handleFormValues = (newValues) => {
        setValues(prev => ({
            ...prev,
            ...newValues
        }));
    }

    const handleDatePicker = (dates, key) => {
        const parseDates = dates.map( 
                                date => ( moment(date).format('YYYY-MM-DD') )
                            ).join('/');

        setValues({
            ...values,
            [key]: parseDates
        });
    }

    const validateNumeric = (e, formErr) => {
        handleInputChange(e);
        const re= /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        if (e.target.value === '' || re.test(e.target.value)){                             
            return omit(formErr, [e.target.name]);
        }else
            return {...formErr, [e.target.name]: 'Dato de tipo numérico'};
    }

    const handleDatePeriod = ({ dates, key }) => {
        if(dates.length !== 2) return;

        const parseDates = dates.map( 
                                date => ( moment(date).format('YYYY-MM-DD') )
                            ).join('/');
        
        setValues({
            ...values,
            [key]: parseDates
        });
    }

    const validateMatchImportsCobro = (name, cobro, items, formErr) => {
        if(items.length == 0) return omit(formErr, [name]);

        const cobrado = Number(cobro) ?? 0.0
        const items_cobrado = items.map(e => e.import ?? 0.0).reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        const items_en_cero = items.filter(e => (!e.import) || Number(e.import) == 0);
        console.log('validateMatchImportsCobro',items_cobrado, cobrado)
        if(items_cobrado != cobrado) {
            return {...formErr, [name]: 'No coincide la suma de los importes con el importe cobrado'};
        }
        if(items_en_cero.length > 0) {
            return {...formErr, [name]: 'Los importes deben ser mayor a cero'};
        }
        return omit(formErr, [name]);
    }

    const validateMatchImportsPayment = (name, cobro, items, formErr) => {
        if(items.length == 0) return omit(formErr, [name]);
        
        const cobrado = Number(cobro) ?? 0.0
        const items_cobrado = items.map(e => e.import ?? 0.0).reduce((partialSum, a) => Number(partialSum) + Number(a), 0);
        const items_en_cero = items.filter(e => (!e.import) || Number(e.import) == 0);
        console.log('validateMatchImportsPayment',items_cobrado, cobrado)
        if(items_cobrado != cobrado) {
            return {...formErr, [name]: 'No coincide la suma de los importes con el importe cobrado'};
        }
        if(items_en_cero.length > 0) {
            return {...formErr, [name]: 'Los importes deben ser mayor a cero'};
        }
        return omit(formErr, [name]);
    }

    return {
        form: values, 
        handleInputChange,
        resetForm: reset,
        handleSpecificInput,
        handleDatePicker,
        validateNumeric,
        handleDatePeriod,
        validateMatchImportsCobro,
        validateMatchImportsPayment,
        handleFormValues
    };

}