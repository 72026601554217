import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { formatPeriod } from 'helpers/formats';
import moment from 'moment';
import { dataReports } from 'store/catalogs/administrative-data/data';

const TITLE = process.env.REACT_APP_REPORT_TITLE
//const TITLE = 'CRESTA'
const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function HeaderReport({ period, totals }) {
    return (
        <>
            <h4 className="text-danger f-bold text-center">{ TITLE }</h4>
            <h5 className="mb-4 f-bold text-center">Estado de cuenta Proveedores</h5>
            <Row className='mb-4'>
                <Col md={6}>
                    <span>Periodo Reporte:</span>
                    <span className="d-block f-bold"> { formatPeriod(period) }</span>
                    <span>Fecha Impresión:</span>
                    <span className="d-block f-bold">{ moment().format('DD/MM/Y H:mm') }</span>
                </Col>
                <Col md={6} className="text-end">
                    <span>Saldo al día: </span>
                    <span className="d-block f-bold"><mark>{ nf2.format(totals.balanceFinal) } </mark></span>
                </Col>
            </Row>
        </>
    );
}

HeaderReport.propTypes = {
    totals: PropTypes.object,
    period: PropTypes.string,
};