import { call, put, takeEvery } from "redux-saga/effects";

import { getProviderOrder } from '../../helpers/backend_helper'
import { getOrderSuccess, getOrderFailed } from "./actions";
import { GET_ORDER_PROVIDER } from './actionTypes';

function* getOrderProvider({ payload: id }){
    try {
        const response = yield call(getProviderOrder, id)
        yield put(getOrderSuccess(response));
    }catch (error) {
        yield put(
            getOrderFailed('Sorry! the page you are looking for could not be found')
        );
    }
}

function* ordersProviderSaga(){
    yield takeEvery(GET_ORDER_PROVIDER, getOrderProvider);
}

export default ordersProviderSaga;