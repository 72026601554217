import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { useSelector } from "react-redux"

import getStorageUser from "helpers/api_helper"
import EnterpriseModal from "./EnterpriseModal"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"

const Issuer = ({ validation }) => {
  const user = getStorageUser()
  const { tax_regime } = useSelector(state => state.Sat)
  const [isOpenIssuerModal, setIsOpenIssuerModal] = useState(false)

  const toggleIssuerModal = () => setIsOpenIssuerModal(!isOpenIssuerModal)

  return (
    <>
      <div className="pb-4">
        <h5>
          Emisor
          <span onClick={toggleIssuerModal} role="button">
            <i
              className="fa fa-edit cursor-pointer ms-2"
              style={{ fontSize: 12 }}
            />
          </span>
        </h5>
        <Row>
          <NibbleBaseInput
            fieldType="text"
            fieldName="Issuer.Name"
            label="Razón social"
            validation={validation}
            md={4}
            disabled
          />
          <NibbleBaseInput
            fieldType="text"
            fieldName="Issuer.Rfc"
            label="RFC"
            validation={validation}
            md={4}
            disabled
          />
          <NibbleBaseInput
            fieldType="select"
            fieldName="Issuer.FiscalRegime"
            label="Régimen fiscal"
            selectItems={tax_regime?.data?.map(item => ({
              ...item,
              id: item.code,
            }))}
            validation={validation}
            selectOptionValue={option => option.id}
            selectOptionLabel={option =>
              `${option.code} - ${option.description}`
            }
            md={4}
            disabled
            placeHolder="Seleccionar"
            isLoading={tax_regime.isLoading}
          />
        </Row>
      </div>

      {isOpenIssuerModal && (
        <EnterpriseModal
          validation={validation}
          isOpen={isOpenIssuerModal}
          toggleModal={toggleIssuerModal}
          enterpriseId={user?.enterprise_id}
          isIssuer={true}
        />
      )}
    </>
  )
}

Issuer.propTypes = {
  validation: PropTypes.object,
  toggleIssuerModal: PropTypes.func,
}

export default Issuer
