import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from "classnames"
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import { isEmpty } from 'lodash';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import moment from 'moment';

import {
    Col,
    Row,
    Label,
    Button,
    Form,
    Input,
    Alert,
    InputGroup,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

import { useForm } from 'hooks/useForm';
import { getAdministrativeData } from 'store/catalogs/administrative-data/actions';
import useRequest from 'hooks/useRequest';

import { getMovProviderNextFolio, getProviderMovementToPay, postMovProvider, putMovProvider } from 'helpers/backend_helper';

import 'flatpickr/dist/themes/material_red.css';
import { getProducts } from 'helpers/backend_helper';

const todayDate = moment().format('YYYY-MM-DD');
const nf = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 
const ivaOptions = [
    { name: '16%', value: '0.16' },
    { name: '8%', value: '0.08' },
    { name: '0%', value: '0' }
]
const retencionOptions = [
    { name: 'No aplica', value: '0' },
    { name: '1.25%', value: '0.0125' },
]
const initForm = {
    cost: '0.00',
    decrease: '0.00',
    discount: '0.00',
    other_charges: '0.00',
    folio: '',
    invoice: '',
    kgs: '0.00',
    mov_date: todayDate,
    net: '0.00',
    observations: '',
    origin: '',
    payment: '0.00',
    provider_account: { id: '', name: ''},
    pieces: '0.00',
    product: '',
    prom: '0.00',
    payment_account: { id: '', name: ''},    
    rem: '',
    total: '0.00',
    iva_opt: { name: '16%', value: '0.16' },
    retencion_opt: { name: 'No aplica', value: '0' },
    movsProvidersBuys: [],
};

const selfPaymentOpt = {
    'label': 'A la misma compra',
    'value': '0'
}

const getDefaultTab = (isNew, dataRow) => {
    if (isNew) return "registro-compra"
    if (dataRow?.payment === "0.00") return "registro-compra"
    return "registro-pago"
  }

  const isValidFormValues = (form, setFormErr, activeTab) => {
    let isValid = true;

    console.log({form, activeTab});
  
    if(!form?.provider_account?.id) {
      setFormErr(prev => ({...prev, provider_account: 'El campo proveedor es obligatorio'}));
      isValid = false;
    }
  
    if(activeTab === 'registro-compra') {
        if(form?.total_total <= 0) {
            setFormErr(prev => ({...prev, total_total: 'El total debe ser mayor a 0'}));
            isValid = false;
        }

        return isValid;
    }
  
    if(form?.payment === '' || form?.payment === '0.00' || form?.payment <= 0) {
      setFormErr(prev => ({...prev, payment: 'El campo importe pago debe ser mayor a 0'}));
      isValid = false;
    }
  
    return isValid;
  }

const ProviderForm = ({ dataRow, isNew, toggleRightCanvas }) => {

    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(getDefaultTab(isNew, dataRow))
    const [ formErr, setFormErr ] = useState({});
    const [ products, setProducts ] = useState([]);
    const [ comprasRelatedOpts, setComprasRelatedOpts ] = useState([]);

    let editData
    if(!isNew) {
        let ivaExist = ivaOptions.filter(e => e.value == dataRow.type_iva)
        let newIvaOpt;
        if(ivaExist.length == 0) {
            newIvaOpt = { name: dataRow.type_iva, value: dataRow.type_iva }
            ivaOptions.push(newIvaOpt)
        }
        let retencionExist = retencionOptions.filter(e => e.value == dataRow.type_retencion)
        let newRetencionOpt;
        if(retencionExist.length == 0) {
            newRetencionOpt = { name: dataRow.type_retencion, value: dataRow.type_retencion }
            retencionOptions.push(newRetencionOpt)
        }
        editData = {
            ...dataRow,
            iva_opt: ivaExist.length == 0 ? newIvaOpt : { name: ivaExist[0].name, value: dataRow.type_iva},
            retencion_opt: retencionExist.length == 0 ? newRetencionOpt : { name: retencionExist[0].name, value: dataRow.type_retencion},
            productSelected: { label: dataRow.product, value: dataRow.product},
            movsProvidersBuys: dataRow.movs_providers_buys?.map( e => { return {value: e.id, label: e.mov_date + ' - f-' + (e.folio ?? '') + ' - ' + e.net, import: e.pivot?.import ?? null, obj: e} } ) ?? []
        }
        console.log('editData',editData)
    }

    const { 
        form,
        handleInputChange,
        handleDatePicker,
        handleSpecificInput,
        validateNumeric,
        resetForm,
        validateMatchImportsPayment
    } = useForm(isNew ? initForm : editData);

    const {
        paymentAccounts,
        providerAccounts,
        clientAccounts
    } = useSelector(state => state.AccountingAccounts);
    
    const {
        isError,
        isLoading,
        isSubmitted,
        msgError,
        req
    } = useRequest();

    const {
        mov_date,
    } = form;
    const provider_account_id = form.provider_account?.id

    useEffect(() => {
        async function getProductsCatalog() {
            const response = await getProducts();
            let products = response.map(e => { return {
                label: `${e.skey} ${e.name}`,
                value: e.name,
                unit: e?.unit?.name,
            }; })
            setProducts(products)

            if(!isNew) {
                let exist = response.filter( e => e.name == dataRow.product)
                if(exist.length == 0 ){
                    setProducts([...products, {label: dataRow.product, value: dataRow.product}])
                }
            }
        }
        
        getProductsCatalog();
    }, []);

    useEffect(() => {
        if(!provider_account_id) return;
        
        const params = {
            date    : form.mov_date,
        };

        getProviderMovementToPay(provider_account_id, params)
            .then((res) => { 
                setComprasRelatedOpts(
                    [
                        ...res.map(e => { 
                            return {
                                value: e.id, 
                                label: e.mov_date + ' - f-' + (e.folio ?? '') + ' - $' + nf.format(e.total_total),
                                obj: e
                            };
                        })
                    ]
                )
            });
    }, [ mov_date, provider_account_id ]);

    useEffect(() => {
        async function getDataFolio() {
          const response = await getMovProviderNextFolio()
          handleSpecificInput({ key: "folio", value: response.folio })
        }
        if (isNew) {
          getDataFolio()
        }
    }, [])

    useEffect(() => {
        setFormErr({});
    }, [activeTab])

    useEffect(() => {
        if(form?.product_unit !== 'Kg') {
          handleSpecificInput({ key: 'prom', value: 0 });
          handleSpecificInput({ key: 'kgs', value: 0 });
        }
      }, [form?.product_unit])

    const handleSubmit = async (e) => {
        e.preventDefault();

        //if(!isEmpty(formErr)) return;

        const formSend = {
            ...form, 
            total: totalCompra(),
            net: subtotal(),
            total_total: totalTotal(),
            product: form.productSelected?.value,
            iva: subtotalIva(),
            retencion: subtotalRetencion(),
            type_iva: form.iva_opt?.value,
            type_retencion: form.retencion_opt?.value
        };

        if(!isValidFormValues(formSend, setFormErr, activeTab)) return;

        if(isNew){
            req(postMovProvider(formSend)).then(() => {
                dispatch(getAdministrativeData());
                resetForm();
            });
        }else{
            console.log({
                action: 'Update',
                form: formSend
            })
            req(putMovProvider(form.id, formSend)).then(() => {
                dispatch(getAdministrativeData());
            });
        }
    }

    const updateMerma = (kgs, cost, provider = form.provider_account) => {
        console.log(provider)
        if (provider?.name == 'F SAN ANTONIO') {
            const newDecrease = Number(kgs) * Number(cost) * 0.005
            handleSpecificInput({ key: 'decrease', value: newDecrease})
        } else {
            handleSpecificInput({ key: 'decrease', value: 0})
        }
    }

    const totalPromedio = () => {
        let total = Number(form.kgs) / Number(form.pieces)
        total = total ? Math.round(total * 100) / 100 : 0;
        return total ? total : 0
    }

    const totalCompra = () => {
        let total = 0
        if(isNotUnitKg) {
            total = Number(form.pieces) * Number(form.cost)
        } else {
            total = Number(form.kgs) * Number(form.cost)
        }
        
        total = total ? Math.round(total * 100) / 100 : 0;
        return total ? total : 0
    }

    const subtotal = () => {
        let subtotal = totalCompra()
        let merma = Number(form.decrease)
        let descuentos = Number(form.discount)
        let other_charges = Number(form.other_charges)
        let total = subtotal - (merma ? merma : 0) - (descuentos ? descuentos : 0) + (other_charges ? other_charges:0)
        total = total ? Math.round(total * 100) / 100 : 0
        return total ? total : 0
    }

    const subtotalIva = () => {
        let subtotalVta = subtotal()
        let iva = subtotalVta * Number(form.iva_opt?.value)
        return iva ?? 0;
    }

    const subtotalRetencion = () => {
        let subtotalVta = subtotal()
        let retencion = subtotalVta * Number(form.retencion_opt?.value)
        return retencion ?? 0;
    }

    const totalTotal = () => {
        let total = subtotal() + subtotalIva() - subtotalRetencion()
        total = total ? Math.round(total * 100) / 100 : 0;
        return total ? total : 0
    }

    const isNotUnitKg = form?.product_unit !== 'Kg'

    console.log('form', form)

    return (
        <Form onSubmit={ handleSubmit }>
            { isError ? (
                <Alert className="alert alert-danger">
                    { msgError }
                </Alert>
            ) : ''}
            { isSubmitted ? (
                <Alert className="alert alert-success">
                    { isNew ? 'Registro creado' : 'Registro actualizado' }
                </Alert>
            ) : ''}
            <Row>
                <Col md={12} className="pb-3">
                    <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: "registro-compra" === activeTab,
                                })}
                                onClick={() => setActiveTab("registro-compra")}
                                >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">Registro de compra</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: "registro-pago" === activeTab,
                                })}
                                onClick={() => setActiveTab("registro-pago")}
                                >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">Registro de pago</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
            </Row>
            {activeTab == 'registro-compra' ? (
                <div>
                    <Row className='d-none'>
                        <Col md={12}>
                            <p className='bg-danger text-light p-2'>Registro de compra</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Fecha *</Label>
                                <Flatpickr
                                    value={ form.mov_date ? form.mov_date : '' }
                                    className="form-control d-block"
                                    placeholder="YYYY-MM-DD"
                                    options={{
                                        altInput: true,
                                        altFormat: "Y-m-d",
                                        dateFormat: "Y-m-d",
                                        locale: Spanish
                                    }}
                                    onChange={ dates => handleDatePicker(dates, 'mov_date') }                            
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Folio</Label>
                                <Input
                                    value={ form.folio ? form.folio : '' }
                                    onChange={ handleInputChange }
                                    name="folio"
                                    type="text"
                                    className="form-control"
                                    disabled
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <div className="mb-3">
                                <Label htmlFor="">Remisión</Label>
                                <Input
                                    value={ form.rem ? form.rem : '' }
                                    onChange={ handleInputChange }
                                    name="rem"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="mb-3">
                                <Label htmlFor="">Factura</Label>
                                <Input
                                    value={ form.invoice ? form.invoice : '' }
                                    onChange={ handleInputChange }
                                    name="invoice"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Ruta</Label>
                                <Input
                                    value={ form.origin ? form.origin : '' }
                                    onChange={ handleInputChange }
                                    name="origin"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Proveedor *</Label>
                                <Select                            
                                    value={ form.provider_account }
                                    onChange={ event => { 
                                            handleSpecificInput({
                                                key: 'provider_account',
                                                value: event
                                            });
                                            updateMerma(form.kgs, form.cost, event)
                                        }
                                    }
                                    options={ providerAccounts }
                                    getOptionLabel={ option => option.name }
                                    getOptionValue={ option => option.id }
                                    placeholder="Seleccionar"
                                    classNamePrefix="select2-selection"
                                    className={
                                        formErr?.provider_account
                                          ? "form-control is-invalid"
                                          : ""
                                      }
                                    />
                                    <div className="invalid-feedback">
                                      {formErr?.provider_account ? formErr.provider_account : ""}
                                    </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Producto</Label>
                                <Select
                                    value={ form.productSelected }
                                    onChange={ event => {
                                        handleSpecificInput({
                                            key: 'productSelected',
                                            value: event
                                        })
                                        handleSpecificInput({
                                            key: 'product_unit',
                                            value: event?.unit
                                        })
                                    }}
                                    options={ products }
                                    placeholder="Seleccionar"
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                        
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">No pzas</Label>
                                <Input
                                    value={ form.pieces ? form.pieces : '' }
                                    onChange={ e => {
                                        setFormErr( validateNumeric(e, formErr) );
                                    }}
                                    name="pieces"
                                    type="text"
                                    className={ formErr?.pieces ? 'form-control is-invalid' : 'form-control' }
                                />
                                <div className="invalid-feedback">{ formErr?.pieces ? formErr.pieces : '' }</div>
                            </div>
                        </Col>         
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Kgs</Label>
                                <Input
                                    value={ form.kgs ? form.kgs : '' }
                                    onChange={ e => {
                                        setFormErr( validateNumeric(e, formErr) );
                                        updateMerma(e.target.value, form.cost)
                                    }}
                                    name="kgs"
                                    type="text"
                                    className={ formErr?.kgs ? 'form-control is-invalid' : 'form-control' }
                                    disabled={isNotUnitKg}
                                />
                                <div className="invalid-feedback">{ formErr?.kgs ? formErr.kgs : '' }</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Promedio</Label>
                                <InputGroup style={{ zIndex: 0 }}>
                                    <Input
                                        value={ form.prom }
                                        onChange={ e => {
                                            setFormErr( validateNumeric(e, formErr) );
                                        }}
                                        name="prom"
                                        type="number"
                                        className={ formErr?.prom ? 'form-control is-invalid' : 'form-control' }
                                        disabled={isNotUnitKg}
                                    />
                                    <Button onClick={ () => { handleSpecificInput({key: 'prom', value: totalPromedio() }); } } className='btn btn-sm btn-danger' type='button'>calcular</Button>
                                    <div className="invalid-feedback">{ formErr?.prom ? formErr.prom : '' }</div>
                                </InputGroup>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Precio unitario</Label>
                                <Input
                                    value={ form.cost ? form.cost : '' }
                                    onChange={ e => {
                                        setFormErr( validateNumeric(e, formErr) );
                                        updateMerma(form.kgs, e.target.value)
                                    }}
                                    name="cost"
                                    type="text"
                                    className={ formErr?.cost ? 'form-control is-invalid' : 'form-control' }
                                />
                                <div className="invalid-feedback">{ formErr?.cost ? formErr.cost : '' }</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-end">
                            <Label className="mt-1 f-bold" htmlFor="">Importe Compra total</Label>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <p className='f-bold text-end'>$ { nf.format(totalCompra()) }</p>
                                <Input
                                    value={ form.total ? form.total : '' }
                                    onChange={ e => {
                                        setFormErr( validateNumeric(e, formErr) );
                                    }}
                                    name="total"
                                    type="hidden"
                                    className={ formErr?.total ? 'form-control is-invalid' : 'form-control' }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-end">
                            <Label className="mt-2" htmlFor="">(-) Descuento Mortalidad</Label>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Input
                                    value={ form.decrease ? form.decrease : '' }
                                    onChange={ e => {
                                        setFormErr( validateNumeric(e, formErr) );
                                    }}
                                    name="decrease"
                                    type="text"
                                    className={ formErr?.decrease ? 'form-control text-end is-invalid' : 'form-control text-end' }
                                />
                                <div className="invalid-feedback">{ formErr?.decrease ? formErr.decrease : '' }</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-end">
                            <Label className="mt-2" htmlFor="">(-) Otros descuentos</Label>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Input
                                    value={ form.discount ? form.discount : '' }
                                    onChange={ e => {
                                        setFormErr( validateNumeric(e, formErr) );
                                    }}
                                    name="discount"
                                    type="text"
                                    className={ formErr?.discount ? 'form-control text-end is-invalid' : 'form-control text-end' }
                                />
                                <div className="invalid-feedback">{ formErr?.discount ? formErr.discount : '' }</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-end">
                            <Label className="mt-2" htmlFor="">(+) Otros cargos</Label>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Input
                                    value={ form.other_charges ? form.other_charges : '' }
                                    onChange={ e => {
                                        setFormErr( validateNumeric(e, formErr) );
                                    }}
                                    name="other_charges"
                                    type="text"
                                    className={ formErr?.other_charges ? 'form-control text-end is-invalid' : 'form-control text-end' }
                                />
                                <div className="invalid-feedback">{ formErr?.other_charges ? formErr.other_charges : '' }</div>
                            </div>
                        </Col>
                    </Row>
                    <Row> 
                        <Col md={6}>
                            <div className="mb-3 text-end">
                                <Label className='f-bold' >Subtotal</Label>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <p className='text-end f-bold'>$ { nf.format(subtotal()) }</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <div className="pt-2 text-end">
                                <Label className='' >(+) IVA</Label>
                            </div>
                        </Col>
                        <Col md={3}>
                            <Select
                                placeholder="Seleccionar"
                                value={ form.iva_opt }
                                onChange={ event => handleSpecificInput({
                                    key: 'iva_opt',
                                    value: event
                                }) }
                                options={ ivaOptions }
                                getOptionLabel={ option => option.name }
                                classNamePrefix="select2-selection"
                            />
                        </Col>
                        <Col md={6}>
                            <div className="pt-2">
                                <p className='text-end'>$ { nf.format(subtotalIva()) }</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <div className="pt-2 text-end">
                                <Label className='' >(-) Retenciones</Label>
                            </div>
                        </Col>
                        <Col md={3}>
                            <Select
                                placeholder="Seleccionar"
                                value={ form.retencion_opt }
                                onChange={ event => handleSpecificInput({
                                    key: 'retencion_opt',
                                    value: event
                                }) }
                                options={ retencionOptions }
                                getOptionLabel={ option => option.name }
                                classNamePrefix="select2-selection"
                            />
                        </Col>
                        <Col md={6}>
                            <div className="pt-2">
                                <p className='text-end'>$ { nf.format(subtotalRetencion()) }</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="pt-2 text-end">
                                <Label className='f-bold' >Total</Label>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="pt-2">
                                <p className='text-end f-bold'>$ { nf.format(totalTotal()) }</p>
                                {formErr?.total_total ? <div className="invalid-feedback d-block text-end">{formErr.total_total}</div> : null}
                            </div>
                            <Input
                                value={ form.net ? form.net : '' }
                                onChange={ e => {
                                    setFormErr( validateNumeric(e, formErr) );
                                }}
                                name="net"
                                type="hidden"
                                className={ formErr?.net ? 'form-control is-invalid' : 'form-control' }
                                required
                            />
                            <div className="invalid-feedback">{ formErr?.net ? formErr.net : '' }</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="">Observaciones</Label>
                                <Input
                                    value={ form.observations ? form.observations : '' }
                                    onChange={ handleInputChange }
                                    name="observations"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </Col>  
                    </Row>
                </div>
            ) : null}
            {activeTab == 'registro-pago' ? (
                <div>
                    <Row className='d-none'>
                        <Col md={12}>
                            <p className='bg-danger text-light p-2'>Registro de pago</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Fecha *</Label>
                                <Flatpickr
                                    value={ form.mov_date ? form.mov_date : '' }
                                    className="form-control d-block"
                                    placeholder="YYYY-MM-DD"
                                    options={{
                                        altInput: true,
                                        altFormat: "Y-m-d",
                                        dateFormat: "Y-m-d",
                                        locale: Spanish
                                    }}
                                    onChange={ dates => handleDatePicker(dates, 'mov_date') }                            
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Proveedor *</Label>
                                <Select                            
                                    value={ form.provider_account }
                                    onChange={ event => { 
                                            handleSpecificInput({
                                                key: 'provider_account',
                                                value: event
                                            });
                                            updateMerma(form.kgs, form.cost, event)
                                        }
                                    }
                                    options={ providerAccounts }
                                    getOptionLabel={ option => option.name }
                                    getOptionValue={ option => option.id }
                                    placeholder="Seleccionar"
                                    classNamePrefix="select2-selection"
                                    className={
                                        formErr?.provider_account
                                          ? "form-control is-invalid"
                                          : ""
                                    }
                                />
                                <div className="invalid-feedback">
                                    {formErr?.provider_account ? formErr.provider_account : ""}
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="mb-3">
                                <Label htmlFor="">Importe Pagado *</Label>
                                <Input
                                    value={ form.payment ? form.payment : '' }
                                    onChange={ e => {
                                        setFormErr( validateNumeric(e, formErr) );
                                        setFormErr( validateMatchImportsPayment ('movsProvidersBuys', e.target.value, form.movsProvidersBuys,  formErr))
                                    }}
                                    name="payment"
                                    type="text"
                                    min={0}
                                    step={0.01}
                                    className={ formErr?.payment || formErr?.movsProvidersBuys ? 'form-control is-invalid' : 'form-control' }
                                    required
                                />
                                <div className="invalid-feedback">{ formErr?.payment ? formErr.payment : '' }</div>
                                <div className='text-muted'>Por asignar ${
                                    nf.format(Number(form.payment) - (form.movsProvidersBuys || []).map(e => e.import ?? 0.0).reduce((partialSum, a) => Number(partialSum) + Number(a), 0))
                                }</div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="mb-3">
                                <Label htmlFor="">Compra relacionada</Label>
                                <Select
                                    isMulti={true}
                                    value={ form.movsProvidersBuys }
                                    onChange={ event => {
                                            console.log('event',event)
                                            for(let i=0; i<event.length ;i++) {
                                                if(!('import' in event[i]))
                                                    event[i].import = event[i].obj.pendingToPayment
                                            }
                                            handleSpecificInput({
                                                key: 'movsProvidersBuys',
                                                value: event
                                            })
                                            setFormErr( validateMatchImportsPayment ('movsProvidersBuys', form.payment, event,  formErr))
                                        }
                                    }
                                    options={ comprasRelatedOpts }
                                    placeholder="Seleccionar"
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col>       
                    </Row>
                    { (form.movsProvidersBuys || []).length > 0 &&
                    <Row>
                        <Col md={12}>
                            <div className='table-responsive'>
                                <table className={ formErr?.movsProvidersBuys ? 'is-invalid table table-bordered table-sm': 'table table-bordered table-sm'}>
                                    <thead className='table-light'>
                                        <tr>
                                            <th>Folio</th>
                                            <th>Fecha</th>
                                            <th>Por pagar</th>
                                            <th>Pago</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { (form.movsProvidersBuys || []).map((e, i) => { 
                                            return (
                                            <tr key={i}>
                                                <td>{e.obj?.folio}</td>
                                                <td>{e.obj?.mov_date}</td>
                                                <td className='text-right'>$ {nf.format(e?.obj?.pendingToPayment)}</td>
                                                <td>
                                                    <div className="mb">
                                                        <Input
                                                            value={ form.movsProvidersBuys[i].import ?? '' }
                                                            onChange={ (evt) => {
                                                                form.movsProvidersBuys[i].import = evt.target.value
                                                                handleSpecificInput({ key: 'movsProvidersBuys', value: form.movsProvidersBuys})
                                                                setFormErr( validateMatchImportsPayment ('movsProvidersBuys', form.payment, form.movsProvidersBuys,  formErr))
                                                            }}
                                                            name="movsProvidersBuys"
                                                            type="text"
                                                            max={e.obj.net}
                                                            min={0}
                                                            step={0.01}
                                                            required
                                                            className={ formErr?.movsProvidersBuys ? 'form-control is-invalid text-right' : 'form-control text-right' }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            );
                                        }) }
                                    </tbody>
                                </table>
                                <div className="invalid-feedback">{ formErr?.movsProvidersBuys ? formErr.movsProvidersBuys : '' }</div>
                            </div>
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Banco</Label>
                                <Select                            
                                    value={ form.payment_account }
                                    onChange={ event => handleSpecificInput({
                                        key: 'payment_account',
                                        value: event ? event : initForm.payment_account
                                    }) }
                                    options={ paymentAccounts.concat(clientAccounts) }
                                    getOptionLabel={ option => option.name }
                                    getOptionValue={ option => option.id }
                                    classNamePrefix="select2-selection"
                                    placeholder="Seleccionar"
                                    isClearable
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Forma de Pago</Label>
                                <Input
                                    value={ form.payment_type ? form.payment_type : '' }
                                    onChange={ handleInputChange }
                                    name="payment_type"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </Col> 
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Descripción bancaria</Label>
                                <Input
                                    value={ form.desc_bank ? form.desc_bank : '' }
                                    onChange={ handleInputChange }
                                    name="desc_bank"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="">Folio de conciliación bancaria</Label>
                                <Input
                                    value={ form.folio ? form.folio : '' }
                                    onChange={ handleInputChange }
                                    name="folio"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </Col> 
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="mb-3">
                                <Label htmlFor="">Observaciones</Label>
                                <Input
                                    value={ form.observations ? form.observations : '' }
                                    onChange={ handleInputChange }
                                    name="observations"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </Col>  
                    </Row>
                </div>
            ) : null}
            <Row>
                <Col md={12}>
                    <p><strong>*</strong> Campos obligatorios</p>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button
                        onClick={ toggleRightCanvas }
                        color="secondary"
                        className="btn btn-secondary btn"
                        style={{ marginRight: '10px' }}
                        disabled={ isLoading }
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        className="btn btn-danger btn"
                        disabled={ isLoading }
                        type="submit"
                    >
                        <i 
                            className={`${isLoading ? '' : 'd-none' } bx bx-loader bx-spin font-size-16 align-middle me-2`}
                        ></i>
                        { isLoading ? 'Guardando' : 'Guardar' }
                    </Button>
                </Col>                
            </Row>
        </Form>
    )
}

ProviderForm.propTypes = {
    toggleRightCanvas: PropTypes.func,
    dataRow: PropTypes.object,
    isNew: PropTypes.bool
}

export default ProviderForm;