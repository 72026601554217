import React from 'react';
import PropTypes from 'prop-types';

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableTotal({ totals,multiple }) {

    const {
        total_vta,
        nota_cargo,
        nota_credito,
        cobro,
        decrease,
        total_periodo
    } = totals;

    return (
        
            <tr className='table-head'>
                <th colSpan={multiple ? 2:1} className="text-danger f-bold text-right">Totales</th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(total_vta) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(decrease) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(nota_credito) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(nota_cargo) }
                </th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(cobro) }
                </th>
                <th></th>
                <th className="text-danger f-bold text-right">
                    { nf2.format(total_periodo) }
                </th>
            </tr>
        
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object,
    multiple: PropTypes.bool,
};