import React from 'react';

export default function TableMovementItem() {
    return (
        <>
            <tr className="report-table__b-blue">
                <td></td>
                <td className="text-right">118 MAT</td>
                <td>GENERAL</td>
                <td>E</td>
                <td>COMPRA</td>
                <td className="text-right"></td>
                <td></td>
                <td className="text-right">02/01/2021 F</td>
                <td colSpan={2} className="text-center">0201317775</td>
            </tr>
            <tr>
                <td>SA</td>
                <td></td>
                <td>POLLO VIVO</td>
                <td></td>
                <td></td>
                <td className="text-right border-b-black">6,070.0000</td>
                <td>KG</td>
                <td className="text-right border-b-black">29.55</td>
                <td className="text-right border-b-black">179,377.61</td>
                <td className="text-right border-b-black"></td>
            </tr>
            <tr className="no-border-b">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-right">6,070.0000</td>
                <td></td>
                <td colSpan={2} className="text-center">179,377.61</td>
                <td className="text-right"></td>
            </tr>
        </>
    );
}