import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import Taxes from "common/sat/Taxes"
import { useFormik } from "formik"
import useSatProducts from "hooks/useSatProducts"
import useSatUnits from "hooks/useSatUnits"
import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "reactstrap"
import PropTypes from "prop-types"
import {
  initConfig as initialValues,
  configValidation as validationSchema,
} from "../static"
import {
  getConfigFindOne,
  postConfig,
  updateConfig,
} from "helpers/backend_helper"
import useRequest from "hooks/useRequest"
import { parseConfig } from "../helpers"
import FullSpinner from "components/Common/FullSpinner"

const ConfigForm = ({ tabActive, formId = "create-form-product" }) => {
  const getConfigRequest = useRequest()
  const saveConfigRequest = useRequest()

  const { satUnits, fetchSatUnits, isLoadingSatUnits } = useSatUnits()
  const { satProducts, fetchSatProducts, isLoadingSatProducts } =
    useSatProducts()

  const onSubmit = formValues => {
    const data = {
      name: tabActive,
      value: JSON.stringify(formValues),
    }

    if (formValues.Id) {
      saveConfigRequest.req(updateConfig(formValues.Id, data))
    } else {
      saveConfigRequest.req(postConfig(data))
    }
  }

  const productValidation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  })

  const fetchConfig = () => {
    const config = {
      params: {
        name: tabActive,
      },
    }

    getConfigRequest.req(getConfigFindOne(config)).then(resp => {
      if (resp?.id) {
        const configParsed = parseConfig(resp)

        fetchSatUnits(configParsed.UnitCode)
        fetchSatProducts(configParsed.ProductCode)

        productValidation.setValues(configParsed, false)
      } else {
        productValidation.setValues(initialValues, false)
      }
    })
  }

  useEffect(fetchConfig, [tabActive])

  return (
    <div>
      {getConfigRequest.isLoading ? <FullSpinner /> : ""}
      <Form
        onSubmit={e => {
          e.preventDefault()
          productValidation.handleSubmit()
          return false
        }}
        id={formId}
      >
        <Row>
          <NibbleBaseInput
            fieldType="text"
            fieldName="Description"
            label="Concepto"
            validation={productValidation}
            md={12}
          />
          <NibbleBaseInput
            fieldType="select"
            fieldName="UnitCode"
            label="Unidad SAT"
            placeHolder="Seleccionar"
            selectItems={satUnits.map(item => ({
              ...item,
              id: item.code,
            }))}
            validation={productValidation}
            md={6}
            selectOptionLabel={option =>
              `${option.code} - ${option.name} ${
                option.symbol ? `(${option.symbol})` : ""
              }`
            }
            onInputChange={search => {
              if (!search) return
              fetchSatUnits(search)
            }}
            isLoading={isLoadingSatUnits}
          />
          <NibbleBaseInput
            fieldType="select"
            fieldName="ProductCode"
            label="Código SAT"
            placeHolder="Seleccionar"
            selectItems={satProducts.map(item => ({
              ...item,
              id: item.code,
            }))}
            validation={productValidation}
            md={6}
            selectOptionValue={option => option.code}
            selectOptionLabel={option =>
              `${option.code} - ${option.description}`
            }
            onInputChange={search => {
              if (!search) return
              fetchSatProducts(search)
            }}
            isLoading={isLoadingSatProducts}
          />
        </Row>
        <Taxes validation={productValidation} />
        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-danger"
            form={formId}
            disabled={saveConfigRequest.isLoading}
          >
            <i className="fas fa-check"></i> Guardar
          </button>
        </div>
      </Form>
    </div>
  )
}

ConfigForm.propTypes = {
  tabActive: PropTypes.string,
  formId: PropTypes.any,
  setTabActive: PropTypes.func,
}

export default ConfigForm
