import React, { useState } from "react"
import { getDownloadMovClientCfdi } from "helpers/backend_helper"
import useRequest from "./useRequest"

const useDownloadCfdi = () => {
  const downloadRequest = useRequest()

  const [formatSelected, setFormatSelected] = useState()

  const isDownloadingPdf = formatSelected === "pdf" && downloadRequest.isLoading
  const isDownloadingXml = formatSelected === "xml" && downloadRequest.isLoading

  const createLink = (id, cfdi) => {
    if (!cfdi) return

    const url = `data:application/${cfdi.ContentType};base64,${cfdi.Content}`
    const fileName = `factura-${id}.${cfdi.ContentType}`

    const a = document.createElement("a")
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const downloadInvoice = (format, invoiceId) => {
    setFormatSelected(format)

    const config = {
      params: {
        format,
      },
    }

    downloadRequest
      .req(getDownloadMovClientCfdi(invoiceId, config))
      .then(cfdi => {
        createLink(invoiceId, cfdi)
      })
  }
  return {
    isDownloadingPdf,
    isDownloadingXml,
    isDownloadingInvoice: downloadRequest.isLoading,
    downloadInvoice,
  }
}

export default useDownloadCfdi
