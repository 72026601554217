export const MEXICO_STATES = [
	{
		"name": "Ciudad de México",
		"code": "MX-DIF",
		"id": "09",
		"subdivision": "federal district"
	},
	{
		"name": "Aguascalientes",
		"code": "MX-AGU",
		"id": "01",
		"subdivision": "state"
	},
	{
		"name": "Baja California",
		"code": "MX-BCN",
		"id": "02",
		"subdivision": "state"
	},
	{
		"name": "Baja California Sur",
		"code": "MX-BCS",
		"id": "03",
		"subdivision": "state"
	},
	{
		"name": "Campeche",
		"code": "MX-CAM",
		"id": "04",
		"subdivision": "state"
	},
	{
		"name": "Chiapas",
		"code": "MX-CHP",
		"id": "07",
		"subdivision": "state"
	},
	{
		"name": "Chihuahua",
		"code": "MX-CHH",
		"id": "08",
		"subdivision": "state"
	},
	{
		"name": "Coahuila",
		"code": "MX-COA",
		"id": "05",
		"subdivision": "state"
	},
	{
		"name": "Colima",
		"code": "MX-COL",
		"id": "06",
		"subdivision": "state"
	},
	{
		"name": "Durango",
		"code": "MX-DUR",
		"id": "10",
		"subdivision": "state"
	},
	{
		"name": "Guanajuato",
		"code": "MX-GUA",
		"id": "11",
		"subdivision": "state"
	},
	{
		"name": "Guerrero",
		"code": "MX-GRO",
		"id": "12",
		"subdivision": "state"
	},
	{
		"name": "Hidalgo",
		"code": "MX-HID",
		"id": "13",
		"subdivision": "state"
	},
	{
		"name": "Jalisco",
		"code": "MX-JAL",
		"id": "14",
		"subdivision": "state"
	},
	{
		"name": "Michoacán",
		"code": "MX-MIC",
		"id": "16",
		"subdivision": "state"
	},
	{
		"name": "Morelos",
		"code": "MX-MOR",
		"id": "17",
		"subdivision": "state"
	},
	{
		"name": "México",
		"code": "MX-MEX",
		"id": "15",
		"subdivision": "state"
	},
	{
		"name": "Nayarit",
		"code": "MX-NAY",
		"id": "18",
		"subdivision": "state"
	},
	{
		"name": "Nuevo León",
		"code": "MX-NLE",
		"id": "19",
		"subdivision": "state"
	},
	{
		"name": "Oaxaca",
		"code": "MX-OAX",
		"id": "20",
		"subdivision": "state"
	},
	{
		"name": "Puebla",
		"code": "MX-PUE",
		"id": "21",
		"subdivision": "state"
	},
	{
		"name": "Querétaro",
		"code": "MX-QUE",
		"id": "22",
		"subdivision": "state"
	},
	{
		"name": "Quintana Roo",
		"code": "MX-ROO",
		"id": "23",
		"subdivision": "state"
	},
	{
		"name": "San Luis Potosí",
		"code": "MX-SLP",
		"id": "24",
		"subdivision": "state"
	},
	{
		"name": "Sinaloa",
		"code": "MX-SIN",
		"id": "25",
		"subdivision": "state"
	},
	{
		"name": "Sonora",
		"code": "MX-SON",
		"id": "26",
		"subdivision": "state"
	},
	{
		"name": "Tabasco",
		"code": "MX-TAB",
		"id": "27",
		"subdivision": "state"
	},
	{
		"name": "Tamaulipas",
		"code": "MX-TAM",
		"id": "28",
		"subdivision": "state"
	},
	{
		"name": "Tlaxcala",
		"code": "MX-TLA",
		"id": "29",
		"subdivision": "state"
	},
	{
		"name": "Veracruz",
		"code": "MX-VER",
		"id": "30",
		"subdivision": "state"
	},
	{
		"name": "Yucatán",
		"code": "MX-YUC",
		"id": "31",	
		"subdivision": "state"
	},
	{
		"name": "Zacatecas",
		"code": "MX-ZAC",
		"id": "32",
		"subdivision": "state"
	}
]