import React, { useEffect } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { es } from "yup-locales"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import {
  iepsOptions,
  isrOptions,
  ivaRetencionOptions,
  ivaTrasladoOptions,
} from "./static"
import { useDispatch, useSelector } from "react-redux"
import { Input, Label, Row } from "reactstrap"
import { getObjetoImp } from "store/sat/actions"

Yup.setLocale(es)

export const tax_configuration = {
  iva_t: {
    Code: "002",
    Name: "IVA",
    Label: "IVA",
    Rate: null,
    IsRetention: false,
    IsQuota: false,
  },
  iva_r: {
    Code: "002",
    Name: "IVA RET",
    Rate: null,
    IsRetention: true,
    IsQuota: false,
  },
  ieps: {
    Code: "003",
    Name: "IEPS",
    Rate: null,
    IsRetention: false,
    IsQuota: false,
  },
  isr: {
    Code: "001",
    Name: "ISR",
    Rate: null,
    IsRetention: true,
    IsQuota: false,
  },
}

export const tax_configuration_validation = Yup.object().shape({
  ieps: Yup.object().shape({
    Rate: Yup.mixed().when("IsQuota", {
      is: val => val,
      then: schema =>
        Yup.number()
          .moreThan(0)
          .max(5)
          .typeError("el valor debe ser numérico")
          .label("Cuota"),
      otherwise: schema => Yup.string().nullable(),
    }),
  }),
})

const Taxes = ({ validation, TaxObjectFieldName = "TaxObject" }) => {
  const dispatch = useDispatch()

  const { objeto_imp } = useSelector(state => state.Sat)

  const { values } = validation

  /* const clearRateOnChangeIepsIsQuota = () => {
    if (values.tax_configuration.ieps.Rate) return

    validation.setFieldValue("tax_configuration.ieps.Rate", null)
  }

  useEffect(clearRateOnChangeIepsIsQuota, [
    values.tax_configuration.ieps.Rate,
    values.tax_configuration.ieps.IsQuota,
  ]) */

  useEffect(() => {
    dispatch(getObjetoImp())
  }, [])

  const IEPSLabel = (
    <div className="d-flex justify-content-between">
      <Label className="form-label">IEPS</Label>
      <div className="d-flex flex-1">
        <div style={{ marginRight: "10px" }}>
          <Label className="form-check-label" for="typeRemision">
            Tasa
          </Label>
          <Input
            className="form-check-input"
            checked={validation.values.tax_configuration.ieps.IsQuota === false}
            type="radio"
            value="remision"
            name="ieps_quota"
            onChange={evt => {
              if (evt.target.checked) {
                validation.setFieldValue(
                  "tax_configuration.ieps.IsQuota",
                  false
                )
              }
            }}
            onClick={() => {
              if (!values.tax_configuration.ieps.IsQuota) return

              validation.setFieldValue("tax_configuration.ieps.Rate", null)
            }}
          ></Input>
        </div>
        <div>
          <Label className="form-check-label" for="typeRemision">
            Couta
          </Label>
          <Input
            className="form-check-input"
            checked={validation.values.tax_configuration.ieps.IsQuota === true}
            type="radio"
            value="remision"
            name="ieps_quota"
            onChange={evt => {
              if (evt.target.checked) {
                validation.setFieldValue("tax_configuration.ieps.IsQuota", true)
              }
            }}
            onClick={() => {
              if (values.tax_configuration.ieps.IsQuota) return

              validation.setFieldValue("tax_configuration.ieps.Rate", null)
            }}
          ></Input>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <h5>Impuestos</h5>
      <Row>
        <NibbleBaseInput
          fieldType="select"
          fieldName="tax_configuration.iva_t.Rate"
          label="IVA"
          validation={validation}
          selectItems={ivaTrasladoOptions}
          md={6}
          selectIsClearable={false}
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName="tax_configuration.iva_r.Rate"
          label="IVA RET"
          validation={validation}
          selectItems={ivaRetencionOptions}
          md={6}
          disabled={
            validation.values.tax_configuration.ieps.Rate !== null
              ? true
              : false
          }
          selectIsClearable={false}
        />
        {!validation.values.tax_configuration.ieps.IsQuota ? (
          <NibbleBaseInput
            fieldType="select"
            fieldName="tax_configuration.ieps.Rate"
            label={IEPSLabel}
            validation={validation}
            selectItems={iepsOptions}
            md={6}
            disabled={
              validation.values.tax_configuration.iva_r.Rate !== null
                ? true
                : false
            }
            selectIsClearable={false}
          />
        ) : (
          <NibbleBaseInput
            fieldType="number"
            fieldName="tax_configuration.ieps.Rate"
            label={IEPSLabel}
            validation={validation}
            md={6}
            placeHolder="Ingresa valor"
            disabled={
              validation.values.tax_configuration.iva_r.Rate !== null
                ? true
                : false
            }
            selectIsClearable={false}
          />
        )}
        <NibbleBaseInput
          fieldType="select"
          fieldName="tax_configuration.isr.Rate"
          label="ISR"
          validation={validation}
          selectItems={isrOptions}
          md={6}
          selectIsClearable={false}
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName={TaxObjectFieldName}
          label="Objeto Impuesto"
          placeHolder="Seleccionar"
          selectItems={objeto_imp.data.map(item => ({
            ...item,
            id: item.code,
          }))}
          validation={validation}
          md={12}
          selectOptionValue={option => option.code}
          selectOptionLabel={option => `${option.code} - ${option.description}`}
          selectIsClearable={false}
          isLoading={objeto_imp.isLoading}
        />
      </Row>
    </>
  )
}

Taxes.propTypes = {
  validation: PropTypes.object,
  TaxObjectFieldName: PropTypes.string,
}

export default Taxes
