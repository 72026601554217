import React from 'react';
import PropTypes from 'prop-types';

const nf = new Intl.NumberFormat('es-MX');

export default function TableTotal({ totals }) {

    const {
        pieces,
        kgs,
        total,
        decrease,
        discount,
        net,
        other_charges,
        total_percentaje,
        total_decrease
    } = totals;

    return (
        <>
            <tr className='table-head'>
                <td colSpan={2} className="f-bold text-right">Totales</td>
                <td className="f-bold  border-b-black text-right">
                    { nf.format(pieces) }
                </td>
                <td className="f-bold  border-b-black text-right">
                    { nf.format(kgs) }
                </td>
                <td className="f-bold  border-b-black text-right">
                    { nf.format(total) }
                </td>
                <td className="f-bold  border-b-black text-right">
                    { nf.format(decrease) }
                </td>
                <td className="f-bold  border-b-black text-right">
                    { nf.format(discount) }
                </td>
                <td className="f-bold  border-b-black text-right">
                    { nf.format(other_charges) }
                </td>
                <td className="f-bold  border-b-black text-right">
                    { nf.format(net) }
                </td>
                <td className="f-bold  border-b-black text-right">
                    { Math.round(total_percentaje) }%
                </td>
                <td className="f-bold  border-b-black text-right">
                    { nf.format(total_decrease) }%
                </td>
            </tr>
        </>
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object
};