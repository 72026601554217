import React, { useState } from "react"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"

import { tabName } from "./Tabs"
import ConfigForm from "./ConfigForm"

const DefaultConfigTab = () => {
  const [open, setOpen] = useState("1")

  const toggle = id => {
    if (open === id) {
        setOpen('');
      } else {
        setOpen(id);
      }
  }

  return (
    <div>
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">Otros cargos</AccordionHeader>
          <AccordionBody accordionId="1">
            {open === "1" && (
              <ConfigForm
                tabActive={tabName.otherCharges}
                formId="other-charges-form"
              />
            )}
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">Otros descuentos</AccordionHeader>
          <AccordionBody accordionId="2">
            {open === "2" && (
              <ConfigForm
                tabActive={tabName.otherDiscounts}
                formId="other-discounts-form"
              />
            )}
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="3">Factura global</AccordionHeader>
          <AccordionBody accordionId="3">
            {open === "3" && (
              <ConfigForm
                tabActive={tabName.globalInvoice}
                formId="global-invoice-form"
              />
            )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default DefaultConfigTab
