import axios from "axios"
import { error } from "jquery"
import { del, get, post, postFile, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.lenght> 0 ? err[1] : err
            break
        }
      }
      throw message
    })
}

// Login Method

//export const postLoginToken = data => post(url.POST_LOGIN_TOKEN, data)

export const postLoginToken = (data) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  
  return axios
    .post(process.env.REACT_APP_BACKEND_API_URL + url.POST_LOGIN_TOKEN, data, { headers: headers })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      console.log('err', err);
      var message = ''
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 422:
            message = getError(err)
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.response?.data?.message || err.message
            break
        }
      } else {
        message = err.message
      }
      throw message
    })
}

// get Users
export const getUsers = () => get(url.GET_USERS)
export const postUser = user => post(url.ADD_USER, user)
export const putUser = user => put(`${url.ADD_USER}/${user.id}`, user)
export const postUserPassword = data => put(url.UPDATE_USER_PASSWORD, data)
export const deleteUser = id => del(`${url.DELETE_USER}/${id}`)
export const postUserResetPassword = data => post(url.RESET_USER_PASSWORD, data)

export const getUsersInternals = () => get(url.GET_USERS_INTERNALS)
export const postUserInternals = user => post(url.GET_USERS_INTERNALS, user)
export const putUserInternals = user => put(`${url.GET_USERS_INTERNALS}/${user.id}`, user)
export const deleteUserInternals = id => del(`${url.GET_USERS_INTERNALS}/${id}`)

// get Products
export const getProducts = (config = {}) => get(url.GET_PRODUCTS, config)
export const postProduct = product => post(url.ADD_PRODUCT, product)
export const putProduct = product => put(`${url.UPDATE_PRODUCT}/${product.id}`, product)
export const getProductDetail = id => get(`${url.GET_PRODUCT_DETAIL}/${id}`)
export const getProductFindOne = config => get(`${url.GET_PRODUCTS}/findOne`, config)
export const deleteProduct = id => del(`${url.DELETE_PRODUCT}/${id}`)

// get Enterprises
export const getEnterprises = () => get(url.GET_ENTERPRISES)
export const getEnterprisesList = (config) => get(`${url.ENTERPRISES_RESOURCE}/jlist`, config)
export const postEnterprise = data => post(url.ENTERPRISES_RESOURCE, data)
export const putEnterprise = provider => put(`${url.ENTERPRISES_RESOURCE}/${provider.id}`, provider)
export const getEnterprise = id => get(`${url.ENTERPRISES_RESOURCE}/${id}`)
export const updateEnterpriseTaxData = data => put(`${url.ENTERPRISES_RESOURCE}/update-tax-data/${data.id}`, data)
export const getEnterpriseCsds = data => get(`${url.ENTERPRISES_RESOURCE}/certificates`, data)
export const deleteEnterpriseCsd = id => del(`${url.ENTERPRISES_RESOURCE}/${id}`)
export const postEnterpriseCsd = data => post(`${url.ENTERPRISES_RESOURCE}/certificate`, data)


// get Providers
export const getProviders = () => get(url.GET_PROVIDERS)
export const getProvidersSelect = () => get(url.GET_PROVIDERS + '/select')
export const postProvider = provider => post(url.ADD_PROVIDER, provider)
export const putProvider = provider => put(`${url.UPDATE_PROVIDER}/${provider.id}`, provider)
export const getProviderDetail = id => get(`${url.GET_PROVIDER_DETAIL}/${id}`)
export const deleteProvider = id => del(`${url.DELETE_PROVIDER}/${id}`)
export const postLandingProvider = provider => post(url.LANDING_ADD_PROVIDER, provider)
export const getProviderDashboard = config => get(url.PROVIDERS_DASHBOARD, config)
export const putProviderBranches = provider => put(`${url.UPDATE_PROVIDER_BRANCHES}`, provider)

// get Clients
export const getClients = (params) => get(url.GET_CLIENTS, {params:params})
export const getOneClient = (params) => get(url.GET_CLIENTS+'/find-one', {params})
export const postClient = user => post(url.ADD_CLIENT, user)
export const putClient = provider => put(`${url.UPDATE_CLIENT}/${provider.id}`, provider)
export const getClientDetail = id => get(`${url.GET_CLIENT_DETAIL}/${id}`)
export const getClientDetailByAccountingId = accountingId => get(`${url.GET_CLIENT_DETAIL}/by-accounting/${accountingId}`)
export const deleteClient = id => del(`${url.DELETE_CLIENT}/${id}`)
export const postLandingClient = user => post(url.LANDING_ADD_CLIENT, user)
export const getClientDashboard = config => get(url.CLIENT_DASHBOARD, config)

// get Delivery Client
export const getDeliveries = (enterprise_id) => get(`${url.GET_DELIVERY}/${enterprise_id}`)
export const postDelivery = (enterprise_id, obj) => post(`${url.ADD_DELIVERY}/${enterprise_id}`, obj)
export const putDevelivery = (enterprise_id, obj) => put(`${url.UPDATE_DELIVERY}/${enterprise_id}/${obj.id}`, obj)
export const getDeviveryDetail = id => get(`${url.GET_DELIVERY_DETAIL}/${id}`, { params: { id } })
export const deleteDelivery = (enterprise_id, obj) => del(`${url.DELETE_DELIVERY}/${enterprise_id}/${obj.id}`)

// get Units
export const getUnits = () => get(url.GET_UNITS)
export const postUnit = unit => post(url.ADD_UNIT, unit)
export const putUnit = unit => put(`${url.UPDATE_UNIT}/${unit.id}`, unit)
export const getUnitDetail = id => get(`${url.GET_UNIT_DETAIL}/${id}`)
export const deleteUnit = id => del(`${url.DELETE_UNIT}/${id}`)

// get Regions
export const getRegions = () => get(url.CATALOG_REGION)
export const postRegion = region => post(url.CATALOG_REGION, region)
export const putRegion = region => put(`${url.CATALOG_REGION}/${region.id}`, region)
export const getRegionDetail = id => get(`${url.CATALOG_REGION}/${id}`)
export const deleteRegion = id => del(`${url.CATALOG_REGION}/${id}`)

export const postSubRegions = subregions => post(url.CATALOG_SUBREGION, subregions)

// get ProviderDaily
export const getProvidersAvailability = (config) => get(url.GET_PROVIDERS_AVAILABILITY, config)
export const getProviderDaily = (obj) => get(url.ADD_PROVIDER_DAILY, obj)
export const postProviderDaily = (obj) => post(url.ADD_PROVIDER_DAILY, obj)
export const getProviderDailyPrevious = (obj) => get(url.GET_PROVIDER_DAILY_PREVIOUS, obj)

// get Configs
export const getDistributorConfigs = () => get(url.GET_DISTRIBUITOR_CONFIG)
export const putDistributorConfigs = (obj) => post(url.SET_DISTRIBUITOR_CONFIG, obj)

// get Client DailyAvailable
export const getClientDailyAvailable = (day) => get(`${url.GET_CLIENT_DAILY_AVAILABLE}`)
export const postClientOrder = (obj) => post(url.SET_CLIENT_DAILY_ORDER, obj)

// get Distribuitor Daily Orders
export const getDistribuitorDaily = (day) => get(`${url.GET_DISTRIBUITOR_DAILY}`)
export const postDistribuitorOrder = (obj) => post(url.SET_DISTRIBUITOR_DAILY_ORDER, obj)
export const postDistribuitorOrderItem = (obj) => post(url.SET_DISTRIBUITOR_DAILY_ORDER_ITEM, obj)
export const postDistribuitorOrderPrice = (obj) => post(url.SET_DISTRIBUITOR_DAILY_ORDER_ITEM_PRICE, obj)
export const deleteDistribuitorOrderItem = (id) => del(url.DELETE_DISTRIBUITOR_DAILY_ORDER_ITEM+'/'+id)
export const postDistribuitorDailyTruckAuth = (obj) => post(url.SET_DISTRIBUITOR_DAILY_TRUCK_AUTH, obj)
export const sendOrdersNotificationsProviders = (obj) => post(url.SET_DISTRIBUITOR_DAILY_NOTIFY_PROVIDERS, obj)
export const sendOrdersNotificationsClients = (obj) => post(url.SET_DISTRIBUITOR_DAILY_NOTIFY_CLIENTS, obj)

export const getDistribuitorDashboard = config => get(url.DISTRIBUIDOR_DASHBOARD, config)
export const getDistribuitorDashboardEarning = config => get(url.DISTRIBUIDOR_DASHBOARD + '/earning', config)
export const getDistribuitorDashboardExpenses = config => get(url.DISTRIBUIDOR_DASHBOARD + '/expenses', config)

export const getDistribuitorPriceTable = config => get(url.DISTRIBUIDOR_PRICE_TABLE, config)
export const getDistribuitorPriceHistory = config => get(url.DISTRIBUIDOR_PRICE_HISTORY, config)

export const getDistribuitorClientDailyAvailable = (params) => get(`${url.GET_DISTRIBUITOR_CLIENT_DAILY_AVAILABLE}`, {params:params})
export const postDistribuitorClientOrder = (obj) => post(url.SET_DISTRIBUITOR_CLIENT_DAILY_ORDER, obj)

export const getDistribuitorProviderDailyAvailable = (providerId) => get(url.GET_DISTRIBUITOR_PROVIDER_DAILY_AVAILABLE, {params: {providerId}})
export const postDistribuitorProviderDaily = (obj) => post(url.GET_DISTRIBUITOR_PROVIDER_DAILY_AVAILABLE, obj)
export const putDistribuitorProviderDaily = (obj) => put(url.GET_DISTRIBUITOR_PROVIDER_DAILY_AVAILABLE, obj)

// Orders
export const getClientOrders = () => get(`${url.GET_CLIENT_ORDERS}`)
export const getDistribuitorOrders = () => get(`${url.GET_DISTRIBUITOR_ORDERS}`)
export const getDistribuitorProviderOrders = (params) => get(`${url.GET_DISTRIBUITOR_PROVIDER_ORDERS}`, {params:params})
export const getClientOrder = orderId => get(`${url.GET_CLIENT_ORDER}/${orderId}`)

export const getProviderOrders = (params) => get(`${url.GET_PROVIDER_ORDERS}`, {params:params})
export const getProviderOrder = orderId => get(`${url.GET_PROVIDER_ORDER}/${orderId}`)

// Comments
export const postComment = data => post(url.ADD_ORDER_COMMENT, data);

// Order rating
export const postRating = data => post(url.ADD_ORDER_RATING, data);

// Administrative data
//export const getAdministrativeData = (url, query, config) => get(`${url}${query}`, config);
export const getAdministrativeData = (url, config) => get(`${url}`, config);

//Upload file
export const uploadExcel = (url, file) => postFile(url, file);

// Accounting Accounts
export const getAccountingAccounts = (params = {}) => get(`${url.GET_ACCOUNTING_ACCOUNTS}`, {params});

export const getAccountAccountingsAll = (params = {}) => get(`${url.GET_ACCOUNTING_ACCOUNTS_ALL}`, {params});
export const postAccount = (account, params = {}) => post(`${url.GET_ACCOUNTING_ACCOUNTS_ALL}`, account);
export const putAccount = (account, params = {}) => put(`${url.GET_ACCOUNTING_ACCOUNTS_ALL}/${account.id}`, account);
export const deleteAccount = (id, params = {}) => del(`${url.GET_ACCOUNTING_ACCOUNTS_ALL}/${id}`, {params});
export const getAccountAccountingsExport = (params,type = 'excel') => get(`${url.GET_ACCOUNTING_ACCOUNTS_EXPORT}/${type}`,params);


export const putMovClient = (id, data) => put(`${url.UPDATE_MOV_CLIENT}/${id}`, data);
export const postMovClient = (data) => post(`${url.UPDATE_MOV_CLIENT}`, data);
export const getMovByClient = (id, date) => get(`${url.GET_MOV_BY_CLIENT}/${id}?date=${date}`);
export const getProvidersDate = (config = {}) => get(`${url.GET_MOV_PROVIDERS_BY_DATE}`, config);
export const postMovClientCfdi = (id, data, config) => post(`${url.CLIENT_CFDI}/${id}`, data, config);
export const getDownloadMovClientCfdi = (id, config) => get(`${url.CLIENT_DOWNLOAD_CFDI}/${id}`, config);
export const postNextInvoiceFolio = data => post(`${url.CLIENT_NEXT_INVOICE_FOLIO}`, data);

export const getClientMovementToPay = (id, params = {}) => get(`${url.GET_MOV_CLIENTS_TO_PAY}/${id}`, {params});
export const getProviderMovementToPay = (id, params = {}) => get(`${url.GET_MOV_PROVIDERS_TO_PAY}/${id}`, {params});

export const getMovClientNextFolio = (params = {}) => get(`${url.GET_MOV_CLIENTS_FOLIO}`, {params});

export const postMovProvider = data => post(`${url.POST_MOV_PROVIDER}`, data);
export const putMovProvider = (id, data ) => put(`${url.UPDATE_MOV_PROVIDER}/${id}`, data);
export const getMovProviderNextFolio = (params = {}) => get(`${url.GET_MOV_PROVIDERS_FOLIO}`, {params});
export const getMovByProvider = (id, date) => get(`${url.GET_MOV_BY_PROVIDER}/${id}?date=${date}`);

export const postMovBank = data => post(`${url.POST_MOV_BANK}`, data);
export const putMovBank = (id, data ) => put(`${url.UPDATE_MOV_BANK}/${id}`, data);
export const getMovBankNextFolio = (params = {}) => get(`${url.GET_MOV_BANKS_FOLIO}`, {params});
export const getMovBankFolios = (params = {}) => get(`${url.GET_MOV_BANKS_FOLIOS}`, {params});

export const deleteMov = url => del(url);
export const getMovHistory = url => get(url);

/* export const addObservationWarehouse = data => post(`${url.OBSERVATION_WAREHOUSE}`, data);
export const updObservationWarehouse = (data, id) => put(`${url.OBSERVATION_WAREHOUSE}/${id}`, data);
export const delObservationWarehouse = id => del(`${url.OBSERVATION_WAREHOUSE}/${id}`);

export const addObservationCharges = data => post(`${url.OBSERVATION_CHARGE}`, data);
export const updObservationCharges = (data, id) => put(`${url.OBSERVATION_CHARGE}/${id}`, data);
export const delObservationCharges = id => del(`${url.OBSERVATION_CHARGE}/${id}`); */

export const addObservation = data => post(`${url.OBSERVATION_CHARGE}`, data);
export const updateObservation = (data, id) => put(`${url.OBSERVATION_CHARGE}/${id}`, data);
export const delObservation = id => del(`${url.OBSERVATION_CHARGE}/${id}`);

// administrative reports 
export const getBillingReport = config => get(`${url.GET_BILLING_REPORT}`, config);
export const getDataConfig = (report) => get(`${url.GET_DATA_CONFIG}/${report}`);
export const putConfig = (data,report) => put(`${url.GET_DATA_CONFIG}/${report}`,data);
export const putReportConfigOptions = (data, report) => put(`${url.PUT_REPORT_CONFIG_OPTIONS}/${report}`,data);


export const getSalesFolioReport = config => get(`${url.GET_SALES_FOLIO_REPORT}`, config);
export const getAccountingConcentratesReport = config => get(`${url.GET_ACCOUNTING_CONTRATES_REPORT}`, config);
export const getSalesConcentrateReport = config => get(`${url.GET_SALES_CONTRATE_REPORT}`, config);
export const getShoppingConcentrateReport = config => get(`${url.GET_SHOPPING_CONTRATE_REPORT}`, config);
export const getClientsBalanceReport = config => get(`${url.GET_CLIENTS_BALANCE_REPORT}`, config);
export const getClientsOperationReport = config => get(`${url.GET_CLIENTS_OPERATION_REPORT}`, config);
export const getProvidersOperationReport = config => get(`${url.GET_PROVIDERS_OPERATION_REPORT}`, config);
export const getBillingDetailReport = config => get(`${url.GET_BILLINGDETAIL_REPORT}`, config);
export const getAccountsReceivableReport = config => get(`${url.GET_ACCOUNTS_RECEIVABLE_REPORT}`, config);
export const getInventoryReport = config => get(`${url.GET_INVENTORY_REPORT}`, config);
export const getAccountStatementsReport = config => get(`${url.GET_ACCOUNT_STATEMENTS_REPORT}`, config);
export const getAccountProvStatementsReport = config => get(`${url.GET_ACCOUNT_PROV_STATEMENTS_REPORT}`, config);
export const getAccountProviderPay = config => get(`${url.GET_ACCOUNT_PROVIDER_PAY}`, config);
export const getCostsConcentrateReport = config => get(`${url.GET_COSTS_CONTRATE_REPORT}`, config);
export const getCostsAccountStatementsReport = config => get(`${url.GET_COSTS_ACCOUNT_STATEMENTS_REPORT}`, config);
export const getAccountBankStatementsReport = config => get(`${url.GET_ACCOUNT_BANK_STATEMENTS_REPORT}`, config);
export const getSaleTicket = (id, data, config) => post(`${url.GET_SALE_TICKET}/${id}`, data, config);
export const getClientInvoice = (id, data, config) => post(`${url.GET_CLIENT_INVOICE}/${id}`, data, config);
export const getBanksCashFlow = (config) => get(`${url.GET_BANKS_CASH_FLOW_REPORT}`, config);

//divisa
export const getBanxico = () => get(url.GET_BANXICO); 
export const getMe = (config) => get(url.GET_ME, config);

// get Prices Providers
export const getPricesProviders = () => get(url.GET_PRICES_PROVIDERS);
export const postPricesProviders = data => post(url.POST_PRICES_PROVIDERS, data)
export const updatePricesProviders = data => put(`${url.UPDATE_PRICES_PROVIDERS}/${data.id}`, data)
export const deletePricesProviders = id => del(`${url.UPDATE_PRICES_PROVIDERS}/${id}`)

//holidays
export const getHolidays = () => get(url.GET_HOLIDAYS)
export const postHolidays = data => post(url.POST_HOLIDAYS, data)
export const updateHolidays = data => put(`${url.POST_HOLIDAYS}/${data.id}`, data)
export const deleteHolidays = id => del(`${url.DELETE_HOLIDAYS}/${id}`)

//cp
export const validateZipCode = data => post(url.VALIDATE_ZIP_CODE, data);

//costs-count
export const getCostData = config => get(`${url.GET_COSTS_DATA}`, config);
export const getAccountCosts = () => get(`${url.GET_ACCOUNT_COSTS}`);
export const postCost = data => post(url.POST_COSTS, data)
export const putCost = (id, data) => put(`${url.PUT_COSTS}/${id}`,data)
export const getDeleteCost = id => del(`${url.DELETE_COST}/${id}`)
export const getMovCostNextFolio = (params = {}) => get(`${url.GET_COST_FOLIO}`, {params});
export const getMovsCostToPay = (id, params = {}) => get(`${url.GET_MOVS_COSTS_TO_PAY}/${id}`, {params});
export const getMovByCost = (id, date) => get(`${url.GET_MOV_BY_COST}/${id}?date=${date}`);
//
export const postAjustament = data => post(url.AJUSTAMENT_RESOURCE, data)


//Roles
export const getRoles = config => get(`${url.ROLES_SOURCE}`, config);
export const postTogglePermissionForRole = (roleId, data) => post(`${url.ROLES_SOURCE}/${roleId}`, data)

//SAt
export const getRegimenesFiscales = () => get(`${url.SAT_RESOURCE}/regimenes-fiscales`)
export const getSatUnits = (config) => get(`${url.SAT_RESOURCE}/units`, config)
export const getSatProducts = (config) => get(`${url.SAT_RESOURCE}/products`, config)
export const getSatObjectoImp = (config) => get(`${url.SAT_RESOURCE}/objeto-imp`, config)
export const getSatUseCfdi = (config) => get(`${url.SAT_RESOURCE}/uso-cfdi`, config)
export const getSatInvoiceType = (config) => get(`${url.SAT_RESOURCE}/invoice-type`, config)
export const getSatPaymentForm = (config) => get(`${url.SAT_RESOURCE}/payment-form`, config)
export const getSatPaymentMethod = (config) => get(`${url.SAT_RESOURCE}/payment-method`, config)

export const getCfdis = (config) => get(`${url.INVOICES_RESOURCE}`, config)
export const cancelCfdi = (config) => get(`${url.INVOICES_RESOURCE}/cancel-cfdi`, config)
export const getPreviewCfdi = data => post(`${url.INVOICES_RESOURCE}/preview`, data)
export const getCfdisList = config => get(`${url.INVOICES_RESOURCE}/list`, config)
export const getCancellationXml = (invoiceId) => get(`${url.INVOICES_RESOURCE}/cancellation-xml/${invoiceId}`)
export const postCfdi = data => post(`${url.INVOICES_RESOURCE}`, data)
export const getPPDInvoices = data => get(`${url.INVOICES_RESOURCE}/ppd`, data)

export const getConfigFindOne = (config) => get(`${url.CONFIGS_RESOURCE}/findOne`, config)
export const getConfigFindMany = (config) => get(`${url.CONFIGS_RESOURCE}/findMany`, config)
export const postConfigUpsertMany = (data) => post(`${url.CONFIGS_RESOURCE}/upsertMany`, data)
export const postConfig = (data) => post(`${url.CONFIGS_RESOURCE}`, data)
export const updateConfig = (id, data) => put(`${url.CONFIGS_RESOURCE}/${id}`, data)

//Files
export const uploadFile = (data) => post(`${url.FILES_RESOURCE}`, data)

const getError = err => {
  const message = 'Invalido';
  const data = err.response?.data
  if (data?.errors) {
    if (typeof data?.errors === 'object')
      return Object.values(data?.errors).join(', ')
    if (typeof data?.errors === 'object')
      return data?.errors.join(', ')
  }
  if (data?.message)
    return data?.message
  return message;
}

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
// export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
//export const getProductDetail = id =>
//  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)


// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add customer
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update customer
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete customer
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// add project
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update project
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete project
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)


// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
//export const deleteUser = user =>
//  del(url.DELETE_USER, { headers: { user } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail => post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)
// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

//Opciones para Baco tipos de pago
export const getBancosOptionData = () => get(url.GET_BANCOS_OPTIONS)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile
}
