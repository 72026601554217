import React from "react"
import PropTypes from "prop-types"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

const header_excel = {
  Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

const header_pdf = {
  Accept: "application/pdf",
}

const DownloadButtons = ({ isDownloading, downloadInvoices }) => {
  return (
    <UncontrolledDropdown
      style={{ cursor: "pointer" }}
      className="d-inline-block ms-2"
    >
      <DropdownToggle href="#" className="card-drop action-points" tag="i">
        <button className="btn btn-sm btn-danger">
          Registros
          {isDownloading ? (
            <i className="ms-1 bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
          ) : (
            <i className="mdi mdi-chevron-down"></i>
          )}
        </button>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-enddd actions-menu">
        <DropdownItem
          href="#"
          onClick={e => {
            e.preventDefault()
            downloadInvoices(header_excel)
          }}
        >
          <i className="mdi mdi-microsoft-excel font-size-16 text-danger"></i>{" "}
          Exportar
        </DropdownItem>
        <DropdownItem
          href="#"
          onClick={e => {
            e.preventDefault()
            downloadInvoices(header_pdf)
          }}
        >
          <i className="mdi mdi mdi-file-pdf-box font-size-16 text-danger"></i>{" "}
          Exportar
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

DownloadButtons.propTypes = {
  isDownloading: PropTypes.bool,
  downloadInvoices: PropTypes.func,
}

export default DownloadButtons
