import React from "react"
import PropTypes from "prop-types"
import CurrencyFormat from "common/nibble/CurrencyFormat"

const nf = new Intl.NumberFormat("es-MX")

export default function TableMovementItem({ item }) {
  const {
    total,
    amount,
    iva,
    payment,
    account_id,
    provider_name,
    account_name,
    skey,
    recurrencia_gasto,
  } = item

  return (
    <>
      <tr>
        <td>{skey}</td>
        <td>{provider_name}</td>
        <td>{account_name}</td>

        <td className="text-right">
          <CurrencyFormat value={amount} />
        </td>
        <td className="text-right">
          <CurrencyFormat value={iva} />
        </td>
        <td className="text-right">
          <CurrencyFormat value={total} />
        </td>
        <td className="text-right">
          <CurrencyFormat value={recurrencia_gasto} /> %
        </td>
      </tr>
    </>
  )
}

TableMovementItem.propTypes = {
  item: PropTypes.object,
}
