import React from "react"
import PropTypes from "prop-types"
import {
  Col,
  Label,
  FormGroup,
  InputGroup,
  Button,
  Row,
  Alert,
  Table,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate"
import "flatpickr/dist/themes/material_red.css"

import { useEffect, useState } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { postClientOrder } from "helpers/backend_helper"
import RatingCustom from "./RatingCustom"
import { postDistribuitorClientOrder } from "../../helpers/backend_helper"
import moment from "moment"
import { Spanish } from "flatpickr/dist/l10n/es.js"
const starStyle = {}

const numberFormat = new Intl.NumberFormat("es-MX", {
  minimumFractionDigits: 2,
})
const today = moment().format("YYYY-MM-DD")
const tomorrow = moment(today, "YYYY-MM-DD").add("days", 1).format("YYYY-MM-DD")

const initMinTime = "19:00"

const enable = [
  {
    from: today,
    to: tomorrow,
  },
]

//const defaultDate = `${today} ${initMinTime}`;
const defaultDate = null

const DistribuitorClientOrderRow = props => {
  //console.log(props)
  const [state, setState] = useState([...props.rows])
  const [order, setOrder] = useState(props.order)
  const [values, setValues] = useState({
    provider_id: null,
    orders: {},
    timeDelivery: null,
    observations: {},
  })
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)
  const [pickLocal, setPickLocal] = useState(false)
  const [minTime, setMinTime] = useState(initMinTime)
  const [curOrders, setCurOrders] = useState({})
  const [priceSelected, setPriceSelected] = useState({})
  const [prices, setPrices] = useState({})
  const [observations, setObservations] = useState({})

  useEffect(() => {
    if (props.rows) {
      let pricesInit = {}
      let newPriceSelected = {}
      ;(props.rows || []).forEach((formDaily, keyDaily) => {
        pricesInit[formDaily.id] = {}
        console.log("prices " + formDaily.prices.length)

        return formDaily.prices.forEach((price, keyPrice) => {
          if (formDaily.prices.length == 1) {
            console.log("set price " + formDaily.prices[0].id)
            newPriceSelected[formDaily.id] = formDaily.prices[0].id
          }
          return (pricesInit[formDaily.id][price.id] = price.unit_price_shipped)
        })
      })
      setPriceSelected(newPriceSelected)
      setPrices(pricesInit)
    }
  }, [props.rows])

  const force_edit = true

  if (props.order != order) setOrder(props.order)

  if (props.rows != state) setState(props.rows)

  const isValidPedido = data => {
    let productsValids = 0
    console.log("isValidPedido", data)
    for (const key in data.orders) {
      if (Object.hasOwnProperty.call(data.orders, key)) {
        const element = data.orders[key]
        if (parseInt(element.value) > 0) {
          productsValids++

          if (priceSelected[key] == null)
            throw Error("Debes seleccionar un precio para el producto.")
        }
      }
    }

    if (values.timeDelivery == "" || values.timeDelivery == null)
      throw Error("Debes colocar un horario de entrega")

    return true
  }

  const handleDateTimePicker = (selectDates, dateStr, instance) => {
    const date = dateStr.split(" ")[0]

    console.log({
      dateStr,
      instance,
    })
    setValues({ ...values, timeDelivery: dateStr })
    setMinTime(date === tomorrow ? "00:00" : initMinTime)
  }

  const save = async data => {
    console.log("data", data)
    const response = props.enterprise_id
      ? await postDistribuitorClientOrder({
          ...data,
          enterprise_id: props.enterprise_id,
          date: props.date,
          address_id: props.address,
          order_id: order ? order.id : null,
        })
      : await postClientOrder({
          ...data,
          date: props.date,
          address_id: props.address,
          order_id: order ? order.id : null,
        })
    console.log("response", response)
    props.onSubmit({ ...response })
    setCompleted("Se completo la operación")
    setError(null)
  }

  const valuesToObj = (values, pickLocal = false) => {
    const response = {}
    for (const key in values) {
      if (key[0] == "l") continue
      if (Object.hasOwnProperty.call(values, key)) {
        const element = values[key]
        response[key] = {
          value: element,
          local: pickLocal,
          region_id: props.region,
        }
      }
    }

    for (const key in values) {
      if (key[0] !== "l") continue
      const realKey = key.substring(1)
      if (Object.hasOwnProperty.call(values, realKey)) {
        const element = values[realKey]
        response[realKey] = { ...response[realKey], local: true }
      }
    }

    return response
  }

  const handleValidSubmit = async (e, v) => {
    try {
      setBtnSaveLoad(true)
      const data = {
        ...values,
        provider_id: state[0].provider.id,
        farm_id: state[0].farm.id,
        orders: valuesToObj(curOrders, pickLocal),
        prices: priceSelected,
        prices_amount: prices,
        observations: observations,
      }
      console.log("data", data)
      setValues(data)

      isValidPedido(data)
      await save(data)
      //props.onSubmit(data)
      setCurOrders({})
      setValues({
        provider_id: null,
        orders: {},
        timeDelivery: values.timeDelivery,
        observations: {},
      })
      setObservations({})
      setPriceSelected({})
      setError(null)
    } catch (e) {
      console.log(e.toString())
      setError(e.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const handleCheckPickLocal = value => {
    setPickLocal(value)
  }

  const hasProduct = state => {
    const available = state.map(formDaily => {
      return formDaily.available_product
    })

    return available.reduce((a, b) => a + b, 0) > 0
  }

  console.log("priceSelected", priceSelected)
  console.log("values", values)
  /* console.log('address', props.address);
  console.log('order', order);
  console.log('productos', state); */
  return (
    <React.Fragment>
      {(!order || force_edit == true) && state.length > 0 && (
        <div>
          {hasProduct(state) ? (
            <Row className="bg-light mt-3">
              <div>
                <Col lg={12} className="text-center">
                  <div className="mt-3">
                    <b>
                      {state[0].provider.company_name} - Punto de distribución{" "}
                      {state[0].farm.name}
                    </b>
                  </div>
                  {error ? (
                    <Alert color="danger" className="mt-3">
                      {error}
                    </Alert>
                  ) : null}
                </Col>
              </div>

              {!order && state[0].farm.local_delivery ? (
                <Row className="mt-3">
                  <Col lg={4} className="text-end">
                    <label
                      htmlFor=""
                      style={{ minHeight: "28px", paddingTop: "10px" }}
                    >
                      Recoger en Sucursal
                    </label>
                  </Col>
                  <Col md={7}>
                    <div
                      className="form-check form-check-danger"
                      style={{ display: "inline-block", width: "120px" }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={"local_delivery"}
                        defaultChecked={false}
                        onChange={value =>
                          handleCheckPickLocal(value.currentTarget.checked)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"local_delivery"}
                      >
                        Sí
                      </label>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {!pickLocal && state[0].provider && (
                <Row className="mt-3">
                  <Col lg={4} className="mb-3 text-end">
                    <label
                      htmlFor=""
                      style={{ minHeight: "28px", paddingTop: "10px" }}
                    >
                      Hora deseada de recepción (Formato 24 hrs)
                    </label>
                  </Col>
                  <Col lg={6} className="text-center">
                    <FormGroup>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Seleccionar horario"
                          options={{
                            enableTime: true,
                            defaultHour: 19,
                            //noCalendar: false,
                            //dateFormat: "H:i",
                            time_24hr: true,
                            //onChange: handleTimeDelivery,
                            onChange: handleDateTimePicker,
                            enable,
                            minTime,
                            locale: Spanish,
                            mode: "single",
                            plugins: [new confirmDatePlugin({})],
                          }}
                        />
                        <div className="input-group-append">
                          <a className="input-group-text" data-toggle>
                            <i className="mdi mdi-clock-outline" />
                          </a>
                        </div>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {pickLocal && (
                <Row className="mt-3">
                  <Col lg={12} className="text-center">
                    <Alert color="info" className="mt-3">
                      Se recoje en sucursal
                    </Alert>
                  </Col>
                </Row>
              )}
              <Col lg={12} className="mb-3">
                {(state || []).map((formDaily, keyDaily) =>
                  formDaily.available_product > 0 ? (
                    <Row key={keyDaily} className="mb-4">
                      <div className="bg-dark text-light text-center p-1 pb-1">
                        <b>{formDaily.product}</b>
                      </div>
                      <Col lg={7} className="mb-2 text-center">
                        <div>
                          Disponible: <b>{formDaily.available_product} Pzas</b>
                        </div>
                        <div>
                          Peso Promedio:{" "}
                          <b>
                            {formDaily.weight} - {formDaily.weight_max} Kg.
                          </b>
                        </div>
                        {state[0].farm.local_delivery &&
                        formDaily.unit_price > 0 ? (
                          <div>
                            Precio en sucursal: <b>${formDaily.unit_price}</b>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          Observaciones: <b>{formDaily.observations}</b>
                        </div>
                        <Row>
                          <Col md={4} className="">
                            <div style={{ alignSelf: "flex-end" }}>
                              Pigmento
                              <RatingCustom
                                max={3}
                                disabled={true}
                                defaultRating={formDaily.quality}
                                styleConfig={{
                                  starContainer: {
                                    fontSize: "15px",
                                    justifyContent: "center",
                                  },
                                }}
                                ActiveComponent={
                                  <i
                                    className="mdi mdi-star text-warning"
                                    style={starStyle}
                                  />
                                }
                                InActiveComponent={
                                  <i
                                    className="mdi mdi-star-outline text-muted"
                                    style={starStyle}
                                  />
                                }
                              />{" "}
                            </div>
                          </Col>
                          <Col md={4} className="">
                            <div>
                              Calidad
                              <RatingCustom
                                max={3}
                                disabled={true}
                                defaultRating={formDaily.quality_2}
                                styleConfig={{
                                  starContainer: {
                                    fontSize: "15px",
                                    justifyContent: "center",
                                  },
                                }}
                                ActiveComponent={
                                  <i
                                    className="mdi mdi-star text-warning"
                                    style={starStyle}
                                  />
                                }
                                InActiveComponent={
                                  <i
                                    className="mdi mdi-star-outline text-muted"
                                    style={starStyle}
                                  />
                                }
                              />{" "}
                            </div>
                          </Col>
                          <Col md={4} className="">
                            <div>
                              Salud del ave
                              <RatingCustom
                                max={3}
                                disabled={true}
                                defaultRating={formDaily.quality_3}
                                styleConfig={{
                                  starContainer: {
                                    fontSize: "15px",
                                    justifyContent: "center",
                                  },
                                }}
                                ActiveComponent={
                                  <i
                                    className="mdi mdi-star text-warning"
                                    style={starStyle}
                                  />
                                }
                                InActiveComponent={
                                  <i
                                    className="mdi mdi-star-outline text-muted"
                                    style={starStyle}
                                  />
                                }
                              />{" "}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={5} className="mb-2 text-lg-left text-left">
                        <Label>Ordenar</Label>
                        <input
                          className="text-center w-100 form-control"
                          name={"" + formDaily.id}
                          type="number"
                          placeholder=""
                          min="0"
                          value={
                            formDaily.id in curOrders
                              ? curOrders[formDaily.id]
                              : ""
                          }
                          onChange={evt => {
                            const newData = { ...curOrders }
                            newData[formDaily.id] = evt.target.value
                            setCurOrders(newData)
                          }}
                          onWheel={evt => evt.target.blur()}
                        />

                        <fieldset id={"precio_" + formDaily.id}>
                          <Label className="mt-3">Región</Label>
                          {formDaily.prices.map((price, keyPrice) => (
                            <Row key={keyPrice}>
                              <Col lg={6}>
                                <div className="form-check mt-2">
                                  <input
                                    checked={
                                      priceSelected[formDaily.id] == price.id
                                    }
                                    type="radio"
                                    className="form-check-input"
                                    name={"precio_" + formDaily.id}
                                    onChange={evt => {
                                      let newPrice = { ...priceSelected }
                                      newPrice[formDaily.id] = price.id
                                      setPriceSelected(newPrice)
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"precio_" + formDaily.id}
                                    onClick={evt => {
                                      let newPrice = { ...priceSelected }
                                      newPrice[formDaily.id] = price.id
                                      setPriceSelected(newPrice)
                                    }}
                                  >
                                    Entrega en {price.region}{" "}
                                    {price.subregion ? price.subregion : ""}
                                  </label>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <input
                                  className="text-center w-100 form-control"
                                  name={"" + formDaily.id}
                                  type="number"
                                  placeholder=""
                                  min="0"
                                  step={0.1}
                                  value={
                                    formDaily.id in prices
                                      ? Math.round(
                                          prices[formDaily.id][price.id] * 100
                                        ) / 100
                                      : ""
                                  }
                                  onChange={evt => {
                                    const newPrices = { ...prices }
                                    newPrices[formDaily.id][price.id] =
                                      parseFloat(
                                        Number(evt.target.value).toFixed(2)
                                      )
                                    setPrices(newPrices)
                                  }}
                                  onWheel={evt => evt.target.blur()}
                                />
                              </Col>
                            </Row>
                          ))}
                        </fieldset>
                        <br />
                        <Label>Observaciones</Label>
                        <textarea
                          className="text-left w-100 form-control"
                          placeholder=""
                          value={
                            formDaily.id in observations
                              ? observations[formDaily.id]
                              : ""
                          }
                          onChange={evt => {
                            let newValue = { ...observations }
                            newValue[formDaily.id] = evt.target.value
                            setObservations(newValue)
                          }}
                        ></textarea>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )
                )}
              </Col>

              <Row className="">
                <Col lg={12} className="mb-3 text-center">
                  {(order == null || force_edit == true) && (
                    <button
                      type="button"
                      className="btn btn-danger w-md btn-sm ms-3"
                      disabled={btnSaveLoad}
                      onClick={handleValidSubmit}
                    >
                      <i className="fas fa-list-alt"></i> Solicitar Pedido
                    </button>
                  )}
                </Col>
              </Row>
            </Row>
          ) : (
            ""
          )}
        </div>
      )}
      {order && state.length > 0 && (
        <Row className="bg-light mt-3">
          {pickLocal && (
            <div className="mt-3">
              <Col lg={12} className="text-center">
                <Alert color="info" className="mt-3">
                  Se recoje en sucursal
                </Alert>
              </Col>
            </div>
          )}
          <div style={{ paddingTop: "15px" }}>
            <Col lg={12} className="mb-3">
              <div className="mb-3 bg-light text-center">
                <b>
                  {state[0].provider.company_name} - Punto de distribución{" "}
                  {state[0].farm.name}
                </b>
              </div>
              <Table className="table table-sm">
                <thead className="table-light">
                  <tr className="table-dark">
                    <th>Producto</th>
                    <th>Recepción Solicitada</th>
                    <th>Calidad</th>
                    <th>Peso Prom.</th>
                    <th>Pedido</th>
                    <th>Precio unitario</th>
                    <th className="text-end">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {(order.items || []).map((formDaily, key) => (
                    <tr key={key}>
                      <td>
                        <b>{formDaily.product}</b>
                        <br />
                        {formDaily.delivered ? (
                          <div>Con entrega</div>
                        ) : (
                          <div>En sucursal</div>
                        )}
                        <span
                          className={
                            formDaily.status == "requested"
                              ? "badge rounded-pill bg-warning"
                              : "badge rounded-pill bg-success"
                          }
                        >
                          {formDaily.status_string}
                        </span>
                      </td>
                      <td className="text-center">
                        {formDaily.delivered_region}{" "}
                        {formDaily.delivered_subregion}
                        {formDaily.delivery_date ? (
                          <div className="text-center pt-1">
                            <b>{formDaily.delivery_date}</b>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>{formDaily.observations}</div>
                      </td>
                      <td className="">
                        <Row>
                          <Col lg={6}>Pigmento:</Col>
                          <Col lg={6}>
                            <RatingCustom
                              max={3}
                              disabled={true}
                              defaultRating={formDaily.quality}
                              styleConfig={{
                                starContainer: {
                                  fontSize: "15px",
                                  justifyContent: "center",
                                },
                              }}
                              ActiveComponent={
                                <i
                                  className="mdi mdi-star text-warning"
                                  style={{}}
                                />
                              }
                              InActiveComponent={
                                <i
                                  className="mdi mdi-star-outline text-muted"
                                  style={{}}
                                />
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>Calidad:</Col>
                          <Col lg={6}>
                            <RatingCustom
                              max={3}
                              disabled={true}
                              defaultRating={formDaily.quality_2}
                              styleConfig={{
                                starContainer: {
                                  fontSize: "15px",
                                  justifyContent: "center",
                                },
                              }}
                              ActiveComponent={
                                <i
                                  className="mdi mdi-star text-warning"
                                  style={{}}
                                />
                              }
                              InActiveComponent={
                                <i
                                  className="mdi mdi-star-outline text-muted"
                                  style={{}}
                                />
                              }
                            />{" "}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>Salud del Ave:</Col>
                          <Col lg={6}>
                            <RatingCustom
                              max={3}
                              disabled={true}
                              defaultRating={formDaily.quality_3}
                              styleConfig={{
                                starContainer: {
                                  fontSize: "15px",
                                  justifyContent: "center",
                                },
                              }}
                              ActiveComponent={
                                <i
                                  className="mdi mdi-star text-warning"
                                  style={{}}
                                />
                              }
                              InActiveComponent={
                                <i
                                  className="mdi mdi-star-outline text-muted"
                                  style={{}}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </td>
                      <td>{numberFormat.format(formDaily.weight_prom)} Kg</td>
                      <td>
                        {formDaily.quantity} {formDaily.unit}
                      </td>
                      <td>
                        $
                        {numberFormat.format(
                          formDaily.delivered
                            ? formDaily.unit_price_shipped
                            : formDaily.unit_price
                        )}
                      </td>
                      <td className="text-end">
                        <b>${numberFormat.format(formDaily.total)}</b>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </div>
        </Row>
      )}
    </React.Fragment>
  )
}

DistribuitorClientOrderRow.propTypes = {
  rows: PropTypes.array,
  order: PropTypes.object,
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  enterprise_id: PropTypes.number,
  date: PropTypes.string.isRequired,
  region: PropTypes.number.isRequired,
  address: PropTypes.number.isRequired,
}

export default DistribuitorClientOrderRow
