import { DocumentoRelacionado } from "./static"
import { calculateComplementAmounts } from "./calculations"

/**
 * Agregar documento relacionado cuando se asocia un complemento a una venta PPD
 */
export const addFirstRelatedDocumentToMovClient = ({
  validation,
  ppdInvoices,
  mov_client_id,
}) => {
  const invoice = ppdInvoices.find(
    invoice => invoice.mov_client_id == mov_client_id
  )
  if (!invoice) return

  addRelatedDocumentToInvoice(validation, invoice)
}

/**
 * Agregar documento relacionado cuando se asocia un complemento a una factura PPD en la facturación global
 */
export const addFirstRelatedDocumentToPpdInvoice = ({
  validation,
  ppdInvoices,
  ppd_invoice_id,
}) => {
  const invoice = ppdInvoices.find(invoice => invoice.id == ppd_invoice_id)
  if (!invoice) return

  addRelatedDocumentToInvoice(validation, invoice)
}

const addRelatedDocumentToInvoice = (validation, invoice) => {
  const { PartialityNumber, ImpSaldoInsoluto, PreviousBalanceAmount } =
    calculateComplementAmounts(invoice)

  const newDocumentoRelacionado = {
    ...DocumentoRelacionado,
    Uuid: invoice.uuid,
    Serie: invoice.serie,
    Folio: invoice.serie,
    AmountPaid: 0,
    PartialityNumber,
    ImpSaldoInsoluto,
    PreviousBalanceAmount,
  }

  validation.setFieldValue("Complemento.RelatedDocuments", [
    newDocumentoRelacionado,
  ])
}
