import React, { useCallback, useEffect, useRef, useState } from "react"
import axios from "axios"

import useRequest from "./useRequest"
import { getProducts, getSatUnits } from "helpers/backend_helper"

const useProducts = (search = "") => {
  const cancelToken = useRef(null)
  const productsRequest = useRequest()

  const [products, setProducts] = useState([])

  const fetchProducts = useCallback(async search => {
    if (cancelToken.current) {
      cancelToken.current.cancel()
    }

    cancelToken.current = axios.CancelToken.source()

    const config = {
      params: {
        search,
      },
      cancelToken: cancelToken.current.token,
    }

    const response = await productsRequest.req(getProducts(config))
    if (!response) return

    setProducts(response)
  }, [])

  useEffect(() => {
    fetchProducts(search)
  }, [fetchProducts, search])

  return {
    isLoadingProducts: productsRequest.isLoading,
    products,
    fetchProducts,
  }
}

export default useProducts
