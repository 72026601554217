import React from 'react';

import {
    Table
} from 'reactstrap';
import CustomerItem from './TableCustomerItem';
import Header from './TableHeader';
import Total from './TableTotal';

export default function TableReport() {
    return (
        <div className="table-responsive">
            <Table className="table mb-0 report-table">
                <tbody>
                    <Header />
                    <CustomerItem />
                    <CustomerItem />
                    <CustomerItem />
                    <Total />
                </tbody>
            </Table>
        </div>
    );
}