import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableMovementItem({ item,period,configs }) {
   
    return (
        <>
            <tr>
                <td className="text-center" >
                    { item.skey }
                </td>
                <td  onClick={() => getDetail(item.client_name,'')}>
                    <Link className="text-black"
                    onClick={
                        () => localStorage.setItem("config", JSON.stringify({client_name:item.client_name,bank_name:'',periodo:period}))
                    }  
                    to={{pathname:`/administrative/reports/billing-detail`}} target='_blank'>
                   { item.client_name }
                   </Link>
                </td>
                { configs.map(col => (
                <td className="text-right" key={col.value+'th'}>
                    <Link className="text-black"
                    onClick={
                        () => localStorage.setItem("config", JSON.stringify({client_name:item.client_name,bank_name:col.bank_id,periodo:period}))
                    }  
                    to={{pathname:`/administrative/reports/billing-detail`}} target='_blank'>
                       { item[col.value] ? nf2.format(item[col.value]) : '-' }
                    </Link>
                </td>
                ))}
                <td className="text-right">
                    <Link className="text-black"
                    onClick={
                        () => localStorage.setItem("config", JSON.stringify({client_name:item.client_name,bank_name:'OTROS',periodo:period}))
                    }  
                    to={{pathname:`/administrative/reports/billing-detail`}} target='_blank'>
                       { item.otros ? nf2.format(item.otros) : '-' }
                    </Link>
                    
                </td>
                <td className="text-right">
                    { item.total_row ? nf2.format(item.total_row) : '-' }
                </td>

            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object,
    period: PropTypes.any,
    configs: PropTypes.array
};