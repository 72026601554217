import React from "react"
import { Row, Col, Card, CardBody, Button, Alert } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ClipLoader from "react-spinners/ClipLoader";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../datatables.scss"
import { useState } from "react";
import { useEffect } from "react";
import { deleteDelivery, getDeliveries } from "helpers/backend_helper";

const ClientsDeliveriesList = () => {

  const [error, setError] = useState (null)
  
  async function handleDelete(evt, row) {
    setLoading(true);
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      const response = await deleteDelivery(obj.user.enterprise_id, row);
      setData(data.filter( item => item.id !== row.id))
    } catch(error) {
      setError(error.toString())
    }
    setLoading(false);
  }  

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Link className="btn btn-sm btn-danger" to={{ pathname: "/client/deliveries/form-edit", state: row }} title="Edit">
          <i className="fas fa-edit"></i>
        </Link>
        <Button type="button" className="btn btn-sm btn-danger ms-1" onClick={(evt) => handleDelete(evt, row)} title="Eliminar">
          <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'region.name',
      text: 'Región',
      sort: true
    },
    {
      dataField: 'address_string',
      text: 'Dirección',
      sort: true
    }, 
    {
      dataField: 'id',
      text: 'Acciones',
      formatter: buttonActions
    }
  ]; 
  
  let [color, setColor] = useState("#f46a6a");
  const [loading, setLoading] = useState (false);
  const [data, setData] = useState ([]);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    async function getData()  {
      setLoading(true);
      const response = await getDeliveries(obj.user.enterprise_id);
      setLoading(false);
      console.log(response);
      setData(response);
    }

    getData();
  }, []);


  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: data.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (data).length }
  ];


  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Direcciones de Entrega</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Cliente" breadcrumbItem="Direcciones de Entrega" breadcrumbSubItem="Lista" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error ? <Alert color="danger">{error}</Alert> : null}

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={data}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder='Buscar'
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="8" className="text-end">
                                <Link to="/client/deliveries/form-create" className="btn btn-danger btn-sm">
                                  <i className="fas fa-plus"></i> Crear Dirección de Entrega
                                </Link>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">

                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={ loading ? <ClipLoader color={color} loading={loading} size={30} /> : 'Sin datos' }
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ClientsDeliveriesList
