import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

import PreviewPDF from "common/nibble/PreviewPdf"
import useRequest from "hooks/useRequest"
import { getPreviewCfdi } from "helpers/backend_helper"

const PreviewBtn = ({ validation }) => {
  const previewRequest = useRequest()

  const [modalPdf, setModalPdf] = useState({
    show: false,
    url: "",
    filename: "",
  })

  const onPreviewInvoice = () => {
    previewRequest.req(getPreviewCfdi(validation.values)).then(resp => {
      if (resp?.file) {
        setModalPdf({
          show: true,
          url: resp.file,
          filename: resp.name,
        })
      }
    })
  }

  return (
    <>
      <Button
        outline
        type="button"
        color="primary"
        className="me-2"
        onClick={onPreviewInvoice}
        disabled={previewRequest.isLoading}
      >
        {previewRequest.isLoading ? (
          <i className={`bx bx-loader bx-spin font-size-16 align-middle`}></i>
        ) : (
          <i className="fas fa-file-invoice mr-1"></i>
        )}{" "}
        Vista previa
      </Button>
      <PreviewPDF
        title="Vista previa de la factura"
        modalPdf={modalPdf}
        fileName={modalPdf.filename}
        setModalPdf={setModalPdf}
      />
    </>
  )
}

PreviewBtn.propTypes = {
  validation: PropTypes.object,
  toggleReceiverModal: PropTypes.func,
}

export default PreviewBtn
