import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col} from 'reactstrap';
import logoCresta from '../../assets/images/logo-cresta.png'
import { getClassNameOrderStatus } from 'helpers/order_helper';

function OrderHeader({ id, provider, client, status, status_string, order_date }) {
    const claseStatus = getClassNameOrderStatus(status);
    return (
        <>
            <div className="invoice-title">
                <h4 className="float-end font-size-16">
                    Pedido # {id}
                </h4>
                <div className="mb-4">
                    <img src={ logoCresta } alt="logo" height="20" />
                </div>
            </div>
            <hr />
            <Row>
                <Col xs="6">
                    <address>
                        <strong>
                            Proveedor
                        </strong>
                        <br />
                        <span>{ provider.company_name }</span>
                        <br />
                        <span>{ provider.contact_name }</span>
                        <br />
                        <span>{ provider.email }</span>
                        <br />
                        <span>{ provider.phone }</span>
                        <br />
                    </address>
                </Col>
                <Col xs="6" className="text-end">
                    <address>
                        <strong>Enviado a</strong>
                        <br />
                        <span>{ client.company_name }</span>
                        <br />
                        <span>{ client.contact_name }</span>
                        <br />
                        <span>{ client.email }</span>
                        <br />
                        <span>{ client.phone }</span>
                        <br />
                        <span>{ client?.invoice_address?.street || '' }</span>
                        <br />
                        <span>{ client?.invoice_address?.state || '' }</span>
                        <br />
                    </address>
                </Col>
            </Row>
            <Row>
                <Col xs="6" className="mt-3">
                    <address>
                    <strong>Estatus:</strong>
                    <br />
                    <span className={"badge rounded-pill bg-" + claseStatus}>
                        { status_string }
                    </span>
                    </address>
                </Col>
                <Col xs="6" className="mt-3 text-end">
                    <address>
                    <strong>Fecha pedido:</strong>
                    <br />
                    { order_date }
                    <br />
                    <br />
                    </address>
                </Col>
            </Row>
        </>
    )
}

OrderHeader.propTypes = {
    id: PropTypes.any,
    provider: PropTypes.any,
    client: PropTypes.any,
    status: PropTypes.any,
    status_string: PropTypes.any,
    order_date: PropTypes.any
};

export default OrderHeader;