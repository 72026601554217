import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';

import {
    Modal,
    Alert,
    Spinner
} from 'reactstrap';


import './custom.scss';
import { getMovHistory } from 'helpers/backend_helper';
import useRequest from 'hooks/useRequest';
import { useState } from 'react';

function ModalHistory({ isOpen, setOpenModal, row }) {

    const [ history, setHistory ] = useState(null);

    const {
        id
    } = row;

    const {
        msgError,
        isError,
        isLoading,
        req
    } = useRequest();

    const {
        activeTab,
        dataReports
    } = useSelector(state => state.AdministrativeData);

    const {
        url_history,
        columns_history
    } = dataReports[activeTab];

    useEffect(() => {
        
        req(getMovHistory( url_history.replace('{id}', id) )).then(resp => {
            const newHistory = [
                resp,
                ...resp.mov_changes
            ];
            setHistory(newHistory);
        });
    }, [ url_history, id ]);


    return (
        <div>
            <Modal
                isOpen={ isOpen }
                toggle={() => {
                    setOpenModal(false);
                }}
                size="lg"
            >
                <form>                    
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Histórico
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        { isError && (
                            <Alert className="alert alert-danger">
                                { msgError }
                            </Alert>
                        )}
                        { isLoading && <Spinner /> }
                        <div className="table-responsive">                            
                            { history && (
                                <BootstrapTable
                                    keyField='id' 
                                    data={ history }
                                    columns={ columns_history }
                                    noDataIndication="No hay datos para mostrar"
                                    striped
                                    hover
                                    condensed
                                    classes='history-table'
                                />
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Cerrar
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

ModalHistory.propTypes = {
    isOpen: PropTypes.bool,
    setOpenModal: PropTypes.func,
    row: PropTypes.object
};

export default ModalHistory;