import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import useRequest from "hooks/useRequest"
import { useSelector } from "react-redux"

import { getAdministrativeData } from "helpers/backend_helper"

const headers = {
  Accept: "application/pdf",
}

const responseType = "blob"

export default function DownloadPdf({ setIsDownloadPdf }) {
  const { activeTab, dataReports, filters } = useSelector(
    state => state.AdministrativeData
  )

  const { url_data } = dataReports[activeTab]

  const { isLoading, isError, req } = useRequest()

  const params = {
    page: filters.page,
    search: filters.search,
    client: filters.client.id,
    provider: filters.provider.id,
    dates: filters.dates,
    bank: filters.bank.id,
    payment: filters.payment.id,
    column: filters.column,
    order: filters.order,
    mostrar: filters.mostrar.name,
    product: filters.product.id,
  }

  const handleDownload = () => {
    req(
      getAdministrativeData(url_data, { params, headers, responseType })
    ).then(file => {
      if (file) {
        const href = window.URL.createObjectURL(file)
        const anchorElement = document.createElement("a")

        anchorElement.href = href
        anchorElement.download = "reporte.pdf"

        document.body.appendChild(anchorElement)
        anchorElement.click()

        document.body.removeChild(anchorElement)
        window.URL.revokeObjectURL(href)
      }

      setIsDownloadPdf(false)
    })
  }

  useEffect(() => {
    handleDownload()
  }, [])

  return (
    <>
      <div>
        <i
          className={`${
            !isLoading && "d-none"
          } bx bx-loader bx-spin font-size-16 align-middle me-2`}
        ></i>
        {isLoading && "Descargando archivo..."}
      </div>
      {isError && (
        <span className="alert alert-danger d-block text-center">
          Ocurrio un error al descargar el archivo
        </span>
      )}
    </>
  )
}

DownloadPdf.propTypes = {
  setIsDownloadPdf: PropTypes.func,
}
