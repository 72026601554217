import DashboardClient from "pages/Clients/Dashboard";
import DashboardDistribuitor from "pages/Distributor/Dashboard";
import DashboardProvider from "pages/Provider/Dashboard";
import React, { Component, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
} from "reactstrap"

const Dashboard = props => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  
  const [typeRol, setTypeRol] = useState(user.user?.type_rol || user.type_rol)
  const [userType, setUserType] = useState(user.user?.type || user.type)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard - Cresta</title>
        </MetaTags>
        
        <Container fluid>
          { typeRol == 4 &&
            <></>
          }

          { typeRol != 4 && userType == 1 &&
            <DashboardDistribuitor />
          }

          { typeRol != 4 && userType == 2 &&
            <DashboardProvider />
          }

          { typeRol != 4 && userType == 3 &&
            <DashboardClient />
          }
          
        </Container>
      </div>
    </React.Fragment>
  )
}


export default Dashboard;
