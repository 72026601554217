import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useRequest from 'hooks/useRequest';

import { Button } from 'reactstrap';

export default function BtnDownloadExcel({ downloadExcel }) {

    const eleRef = useRef();
    
    const {
        data,
        isLoading,
        isError,
        req
    } = useRequest();

    const handleClick = () => {
        req( downloadExcel() ).then(() => {
            if(eleRef.current)
                eleRef.current.click();
        });
    }

    return (
        <>
            <Button
                onClick={ handleClick }
                className="btn btn-danger btn-sm float-right mb-3"
                style={{ marginLeft: '5px' }}
            >
                <i className={`${!isLoading && 'd-none' } bx bx-loader bx-spin font-size-16 align-middle me-2`}></i>
                <i className="mdi mdi-microsoft-excel"></i> Excel
            </Button>
            { isError && (
                <span className="alert alert-danger d-block text-center">Ocurrio un error al descargar el archivo</span>
            )}
            { data && (
                <a 
                    href={ data.file }
                    ref={ eleRef }
                    download='report.xlsx'
                    className="d-none my-4"
                ></a>
            )}
        </>
    );
}

BtnDownloadExcel.propTypes = {
    downloadExcel: PropTypes.func
};