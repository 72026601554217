
export const getFilters =  state => ({
    filters   : state.AdministrativeData.filters,
    activeTab : state.AdministrativeData.activeTab,
    typeReport: state.AdministrativeData.typeReport,
    data: state.AdministrativeData.pagination.data,
    products: state.AdministrativeData.selects.products,
    paymentType: state.AdministrativeData.selects.payment_type,
    sucursales: state.AdministrativeData.selects.sucursales,
    bankAccounts    : state.AccountingAccounts.bankAccounts,
    clientAccounts  : state.AccountingAccounts.clientAccounts,
    providerAccounts: state.AccountingAccounts.providerAccounts,
    paymentAccounts : state.AccountingAccounts.paymentAccounts,
    costAccounts : state.AccountingAccounts.costAccounts,
});



export const getFiltersReports =  state => ({
    bankAccounts    : state.AccountingAccounts.bankAccounts,
    clientAccounts  : state.AccountingAccounts.clientAccounts,
    providerAccounts: state.AccountingAccounts.providerAccounts,
    paymentAccounts : state.AccountingAccounts.paymentAccounts,
    costAccounts : state.AccountingAccounts.costAccounts,

});