export const ivaTrasladoOptions = [
  {
    id: "0.16",
    name: "IVA 16%",
  },
  {
    id: "0.08",
    name: "IVA 8%",
  },
  {
    id: "0",
    name: "IVA 0%",
  },
  {
    id: "Exento",
    name: "Exento",
  },
  {
    id: null,
    name: "-",
  },
]

export const ivaRetencionOptions = [
  { id: "0.16", name: "IVA Ret 16%" },
  { id: "0.106668", name: "IVA Ret 10.6668%" },
  { id: "0.106667", name: "IVA Ret 10.6667%" },
  { id: "0.106666", name: "IVA Ret 10.6666%" },
  { id: "0.1066667", name: "IVA Ret 10.66667%" },
  { id: "0.10667", name: "IVA Ret 10.667%" },
  { id: "0.1067", name: "IVA Ret 10.67%" },
  { id: "0.1066", name: "IVA Ret 10.66%" },
  { id: "0.106", name: "IVA Ret 10.6%" },
  { id: "0.1", name: "IVA Ret 10%" },
  { id: "0.0919", name: "IVA Ret 9.19%" },
  { id: "0.08", name: "IVA Ret 8%" },
  { id: "0.06", name: "IVA Ret 6%" },
  { id: "0.054", name: "IVA Ret 5.4%" },
  { id: "0.053333", name: "IVA Ret 5.3333%" },
  { id: "0.05", name: "IVA Ret 5%" },
  { id: "0.04", name: "IVA Ret 4%" },
  { id: "0.03", name: "IVA Ret 3%" },
  { id: "0.025", name: "IVA Ret 2.5%" },
  { id: "0.02", name: "IVA Ret 2%" },
  { id: "0.007", name: "IVA Ret 0.7%" },
  { id: "0.005333", name: "IVA Ret 0.5333%" },
  { id: "0.005", name: "IVA Ret 0.5%" },
  { id: "0.002", name: "IVA Ret 0.2%" },
  { id: "0", name: "IVA Ret 0%" },
  { id: null, name: "-" },
]

export const isrOptions = [
  { id: "0.35", name: "ISR 35%" },
  { id: "0.30", name: "ISR 30%" },
  { id: "0.25", name: "ISR 25%" },
  { id: "0.2", name: "ISR 20%" },
  { id: "0.10666", name: "ISR 10.666%" },
  { id: "0.1", name: "ISR 10%" },
  { id: "0.054", name: "ISR 5.4%" },
  { id: "0.04", name: "ISR 4%" },
  { id: "0.03", name: "ISR 3%" },
  { id: "0.021", name: "ISR 2.10%" },
  { id: "0.02", name: "ISR 2%" },
  { id: "0.0125", name: "ISR 1.25%" },
  { id: "0.011", name: "ISR 1.1%" },
  { id: "0.01", name: "ISR 1%" },
  { id: "0.009", name: "ISR 0.9%" },
  { id: "0.005", name: "ISR 0.5%" },
  { id: "0.004", name: "ISR 0.4%" },
  { id: "0.001", name: "ISR 0.1%" },
  { id: "0", name: "ISR 0%" },
  { id: null, name: "-" },
]

export const iepsOptions = [
  { id: "3.000000", name: "IEPS 300%" },
  { id: "1.600000", name: "IEPS 160%" },
  { id: "0.530000", name: "IEPS 53%" },
  { id: "0.500000", name: "IEPS 50%" },
  { id: "0.350000", name: "IEPS 35%" },
  { id: "0.304000", name: "IEPS 30.4%" },
  { id: "0.300000", name: "IEPS 30%" },
  { id: "0.298800", name: "IEPS 29.88%" },
  { id: "0.265000", name: "IEPS 26.5%" },
  { id: "0.250000", name: "IEPS 25%" },
  { id: "0.090000", name: "IEPS 9%" },
  { id: "0.080000", name: "IEPS 8%" },
  { id: "0.070000", name: "IEPS 7%" },
  { id: "0.060000", name: "IEPS 6%" },
  { id: "0.059100", name: "IEPS 5.91%" },
  { id: "0.040000", name: "IEPS 4%" },
  { id: "0.030000", name: "IEPS 3%" },
  { id: null, name: "-" },
]
