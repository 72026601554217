import React from 'react';
import PropTypes from 'prop-types';

import { REPORTS } from 'store/catalogs/administrative-data/actionsTypes';
import BankForm from './BankForm';

export const TypeForm = ({ activeTab, dataRow, toggleRightCanvas, isNew }) => {

    switch (activeTab) {
        case REPORTS.BANKS:
            return  <BankForm 
                        dataRow={ dataRow }
                        isNew={ isNew }
                        toggleRightCanvas={ toggleRightCanvas } 
                    />;
        default:
            return <p>No match</p>;
    }
}

TypeForm.propTypes = {
    activeTab: PropTypes.string,
    dataRow: PropTypes.object,
    toggleRightCanvas: PropTypes.func,
    isNew: PropTypes.bool
}
