import React, { useState } from "react"

import useRequest from "./useRequest"
import { getDownloadMovClientCfdi } from "helpers/backend_helper"

const useShowPdfCfdi = () => {
  const pdfRequest = useRequest()
  const [modalPdf, setModalPdf] = useState({
    show: false,
    url: "",
    fileName: "",
  })

  const handleShowPdf = invoiceId => {
    const config = {
      params: {
        format: "pdf",
      },
    }

    pdfRequest.req(getDownloadMovClientCfdi(invoiceId, config)).then(resp => {
      setModalPdf({
        show: true,
        url: resp.url,
        fileName: `factura-${invoiceId}.pdf`,
      })
    })
  }

  return {
    isLoading: pdfRequest.isLoading,
    modalPdf,
    setModalPdf,
    handleShowPdf,
  }
}

export default useShowPdfCfdi
