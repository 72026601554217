import React, { useEffect } from "react"
import PropTypes from "prop-types"
import DataTable from "react-data-table-component"

import { Card, CardBody, CardHeader, Spinner } from "reactstrap"

const CustomTable = ({
  columns = [],
  data = [],
  showPagination = true,
  loading = false,
  handlePerRowsChange = null,
  handlePageChange = null,
  validation = null,
  totalRows = 0,
  filters = null,
  showPaginationRowsPerPageOptions = true,
  paginationRowsPerPageOptions = [20, 30, 50],
}) => {
  const [maxHeight, setMaxHeight] = React.useState(150)
  const customStyles = {
    table: {
      style: {
        minHeight: "350px",
      },
    },
    rows: {
      style: {
        //minHeight: 'auto',
      },
    },
    headRow: {
      style: {
        minHeight: "auto",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#f8f9fa",
        color: "#495057",
        minHeight: "auto",
        //padding: '0.3rem 0.3rem',
        padding: "0.75rem 0.75rem",
      },
    },
    cells: {
      style: {
        //padding: '0.3rem 0.3rem',
        padding: "0.75rem 0.75rem",
      },
    },
  }

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: false,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: showPaginationRowsPerPageOptions ? false : true,
  }

  const onChangePage = page => {
    if (validation?.setFieldValue) {
      validation.setFieldValue("page", page)
    }

    if (handlePageChange) {
      handlePageChange(page)
    }
  }

  const onChangePerRows = (per_page, page) => {
    if (validation?.setValues) {
      validation.setValues({
        ...validation.values,
        per_page,
        page: 1,
      })
    }

    if (handlePerRowsChange) {
      handlePerRowsChange(per_page, page)
    }
  }

  const updateTableHeight = () => {
    if (!data?.length) return

    const screenHeight = window.innerHeight
    const screenWidth = document.body.clientWidth
    const filtersBillingDiv = document.getElementById("billing-filters")
    const bodyElement = document.querySelector("body")

    const filtersBillingDivBottom = filtersBillingDiv.getBoundingClientRect().bottom
    const paddingBottom = 100 //aprox

    const tableHeight = screenHeight - filtersBillingDivBottom - paddingBottom

    console.log("screenHeight", screenHeight)
    console.log("filtersBillingDivBottom", filtersBillingDivBottom)
    console.log("tableHeight", tableHeight)

    if (screenWidth >= 992) {
      bodyElement.style.overflow = "hidden"
      setMaxHeight(tableHeight)
    } else {
      bodyElement.style.overflow = "auto"
      setMaxHeight(null)
    }
  }

  useEffect(() => {
    updateTableHeight()
  }, [data])

  useEffect(() => {
    function handleResize() {
      updateTableHeight()
    }

    window.addEventListener("resize", handleResize)
    return () => {
      const bodyElement = document.querySelector("body")
      window.removeEventListener("resize", handleResize)
      bodyElement.style.overflow = "auto"
    }
  }, [])

  return (
    <Card>
      <CardBody>
        {filters}
        <div
          className="table-responsive"
          
        >
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight={maxHeight + 'px'}
            columns={columns}
            data={data}
            customStyles={customStyles}
            paginationComponentOptions={paginationComponentOptions}
            pagination={showPagination}
            progressPending={loading}
            paginationServer={showPagination}
            paginationPerPage={validation?.values?.per_page}
            paginationTotalRows={validation?.values?.total || totalRows}
            onChangeRowsPerPage={onChangePerRows}
            onChangePage={onChangePage}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            progressComponent={
              <div className="text-center py-4">
                <Spinner />
                <p className="pt-2">Cargando información...</p>
              </div>
            }
            noDataComponent={
              <div className="text-center">
                <p>No se encontró información</p>
              </div>
            }
            style={{
              minHeight: "400px",
            }}
            dense
          />
        </div>
      </CardBody>
    </Card>
  )
}

CustomTable.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  showPagination: PropTypes.bool,
  loading: PropTypes.bool,
  showPaginationRowsPerPageOptions: PropTypes.bool,
  handlePerRowsChange: PropTypes.any,
  handlePageChange: PropTypes.any,
  validation: PropTypes.any,
  totalRows: PropTypes.any,
  filters: PropTypes.any,
  paginationRowsPerPageOptions: PropTypes.any,
  maxHeight: PropTypes.any,
}

export default CustomTable
