import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Alert,
  Spinner,
} from "reactstrap"
import Select from 'react-select'

import Moment from 'react-moment';
import 'moment/locale/es';
import { getDistribuitorClientDailyAvailable } from 'helpers/backend_helper';
import "flatpickr/dist/themes/material_red.css";
import { getClients } from 'helpers/backend_helper';
import BreadcrumbNav from "components/Common/BreadcrumbNav";
import * as moment from 'moment';
import DistribuitorClientOrderRow from '../../../../components/Common/DistribuitorClientOrderRow';

const breadcrumbItems = [
  { id: '1', title: 'Cliente', link:'#', active: false },
  { id: '2', title: 'Realizar Pedido', link: '#', active: true }
];

const DailyFormUserCreate = () => {
  // const deliveryAddress = getStorageUser().enterprise?.deliveries_address[0]
  // Tiempo restante
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState({label:'', value:''})
  const [deliveryAddress, setDeliveryAddress] = useState(null)
  const [clients, setClients] = useState([])
  const [dates, setDates] = useState([])
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [state, setState] = useState({})
  const [loading, setLoading] = useState(false)
  const [rows1, setrows1] = useState([])
  const [disableForm, setDisableForm] = useState(false)
  const [addresses, setAddresses] = useState ([])

  useEffect(() => {
    async function getData()  {
      setLoading(true);
      const responseClients = await getClients();
      setLoading(false);
      const responseClientsActived = responseClients.filter( e => { return e.isActivated; })
      setClients(responseClientsActived.map( e => { return {label: e.company_name, value: e.id} } ))
    }
    getData();
  }, []);

  const handleSelectClient = async (client) => {
    setLoading(true);
    setSelectedClient(client)
    const response = await getDistribuitorClientDailyAvailable({enterprise_id: client.value});
    setLoading(false);
    setrows1(response.data);
    setDates(response.days)
    setSelectedDate(response.days[0])
    setAddresses(response.enterprise.deliveries_address);
    setSelectedDeliveryAddress({label: response.enterprise.deliveries_address[0].address_string, value: response.enterprise.deliveries_address[0].id})
    setDeliveryAddress( response.enterprise.deliveries_address[0] )
  }

  const handleCompletePedido = async (pedido) => {
    const response = await getDistribuitorClientDailyAvailable({enterprise_id: selectedClient.value});
    setrows1(response.data);
    //setSelectedDeliveryAddress(response.enterprise.deliveries_address[0])
  }

  const handleDateSelected = (option) => {
    setSelectedDate(option.value)
  }

  const handleDeliverySelected = (option) => {
    setSelectedDeliveryAddress(option)
    setDeliveryAddress( addresses.find( e => { return e.id == option.value }) )
  }

  const hasProduct = (state) => {
    let availableProvider = state.map( (provider) => {
      let available = provider.rows.map( (formDaily) => {
        return formDaily.available_product;
      })
      return available.reduce((a, b) => a + b, 0)
    })
    return availableProvider.reduce((a, b) => a + b, 0) > 0
  }

  const hasPedidos = (state) => {
    let availableProvider = state.map( (provider) => {
      return provider.orders.length
    })
    return availableProvider.reduce((a, b) => a + b, 0) > 0
  }

  console.log('deliveryAddress',deliveryAddress)
  console.log(rows1.length > 0, !hasProduct(rows1), !hasPedidos(rows1))

  return (
    <React.Fragment>
    { (disableForm) &&
      <div className="page-content">
        <Alert color="warning">Fuera de tiempo para realizar pedidos</Alert>
      </div>
    }

    { (!disableForm) &&
      <div className="page-content">
        <MetaTags>
          <title>
            Realizar Pedido
          </title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title={ "Realizar Pedido del día " + moment().format('DD/MMMM/YYYY') }  items={breadcrumbItems}/>
          <Row>
            <Col xs={12}>
              <Card style={{minHeight: '600px'}}>
                <CardBody>
                  <CardTitle className="mb-4 d-none">
                    Pedido del día <Moment locale="es" format="DD/MMMM/YYYY" date={ new Date() } />
                  </CardTitle>
                  <Row>
                    <Col lg={7}>
                      <Row>
                        <Col lg={3} className="text-end">
                          <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                            Clientes
                          </label>
                        </Col>
                        <Col lg={9} className="mb-3 text-center">
                          <Select
                            className="text-start"
                            id="formrow-InputState"
                            options={clients}
                            defaultInputValue={selectedClient}
                            onChange={handleSelectClient}
                            placeholder="- Seleccionar cliente -"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}>
                      <Row>
                        <Col lg={4} className="text-end">
                          <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                            Fecha
                          </label>
                        </Col>
                        <Col lg={8} className="mb-3 text-center">
                          <Select
                              className="text-start"
                              id="formrow2-InputState"
                              value={{label: selectedDate, value: selectedDate}}
                              options={ dates.map( e => { return {label: e, value: e} } ) }
                              defaultInputValue={selectedDate}
                              onChange={handleDateSelected}
                              placeholder="- Seleccionar fecha -"
                            />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                 
                  <Row>
                    <Col lg={7}>
                      <Row>
                        <Col lg={3} className="text-end">
                          <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                            Dirección de entrega
                          </label>
                        </Col>
                        <Col lg={9} className="mb-3 text-center">
                          <Select
                              className="text-start"
                              id="formrow2-InputState"
                              value={{label: selectedDeliveryAddress.label, value: selectedDeliveryAddress.value}}
                              options={ addresses.map( e => { return {label: e.address_string, value: e.id} } ) }
                              defaultInputValue={selectedDate}
                              onChange={handleDeliverySelected}
                              placeholder="- Seleccionar dirección -"
                            />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5}>
                      <Row>
                        <Col lg={4} className="text-end">
                          <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                            Región de entrega
                          </label>
                        </Col>
                        <Col lg={8} className="mb-3 text-center">
                          <div style={{minHeight:'28px', paddingTop:'10px'}}>
                            { deliveryAddress?.region?.name }
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {loading && <div className="text-center"><Spinner animation="border" variant="danger" style={{color:"#f46a6a"}}/></div>}
                  <div>
                    { selectedDeliveryAddress.value && (rows1.length == 0) &&
                      <div className="pt-4">
                        <Alert color="warning">No hay disponibilidad de proveedores para la zona de entrega seleccionada.</Alert>
                      </div>
                    }
                    {
                      rows1.length > 0 && !hasProduct(rows1) && !hasPedidos(rows1) &&
                      <div className="pt-4">
                        <Alert color="warning">No hay disponibilidad de producto.</Alert>
                      </div>
                    }
                    {selectedDeliveryAddress.value && selectedDate && (rows1).map((formRow, key) => (
                      <DistribuitorClientOrderRow 
                        id={'provider' + key}
                        key={key}
                        rows={_.sortBy(formRow.rows.filter( e => { return e.date == selectedDate && e.prices.filter( (f) => { return f.region_id == deliveryAddress?.region_id}).length > 0 ; } ),['product_position'],['ASC'])}
                        order={formRow.orders.find( e => { return e.order_date == selectedDate && e.address_id == deliveryAddress?.id }) }
                        date={selectedDate}
                        address={deliveryAddress?.id}
                        region={deliveryAddress?.region_id}
                        onSubmit={handleCompletePedido}
                        enterprise_id={selectedClient.value}
                      />
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    }
    </React.Fragment>
  )
}

export default DailyFormUserCreate
