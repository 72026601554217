import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { 
    Row,
    Col, 
    BreadcrumbItem 
} from 'reactstrap';

import './styles.css';

const BreadcrumbNav = ({ title, items }) => {
    
    return (
        <Row>
            <Col xs="12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-2 bg-light text-light border-bottom border-secondary">
                    <h4 className="mb-0 font-size-18">{ title }</h4>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            {
                                items.map(({ id, title, active, link }) => (
                                    <BreadcrumbItem 
                                        key={ id }
                                        active={ active }
                                    >
                                        { link ? <Link to={link}>{ title }</Link> : title }
                                    </BreadcrumbItem>
                                ))
                            }
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
  )
}

BreadcrumbNav.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array
}

export default BreadcrumbNav;