import React from "react"
import PropTypes from "prop-types"
import { FormGroup, Input, Spinner } from "reactstrap"

const PermissionsTable = ({
  roles,
  permissions,
  onClickPermissionForRole,
  isUpdating,
}) => {
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th className="bg-light text-center">
              {isUpdating && (
                <Spinner color="primary" size="sm">
                  Loading...
                </Spinner>
              )}
            </th>
            <th colSpan={roles?.length} className="text-center bg-light">
              Roles
            </th>
          </tr>
          <tr>
            <th scope="col" className="bg-light text-center">
              Permisos
            </th>
            {roles?.map(role => (
              <th key={role.id} scope="col" className="text-center">
                {role.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {permissions?.map(permission => (
            <tr key={permission.id}>
              <th scope="row" className="bg-light">
                {permission.name}
              </th>
              {roles?.map(role => (
                <td key={role.id} scope="col" className="text-center">
                  <FormGroup check className="d-inline-block">
                    <Input
                      type="checkbox"
                      onChange={() =>
                        onClickPermissionForRole(permission.id, role.id)
                      }
                      checked={
                        role.permissions.find(p => p.id == permission.id)
                          ? true
                          : false
                      }
                      disabled={isUpdating}
                    />
                  </FormGroup>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

PermissionsTable.propTypes = {
  roles: PropTypes.array,
  isUpdating: PropTypes.bool,
  permissions: PropTypes.array,
  onClickPermissionForRole: PropTypes.func,
}

export default PermissionsTable
