import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import Flatpickr from "react-flatpickr";
import { 
    Row, 
    Col, 
    Alert,
    Spinner,
    Input,
    Label,
    Form,
    InputGroup
} from 'reactstrap';
import { useOrderRating } from 'hooks/useOrderRating';

import "flatpickr/dist/themes/material_red.css";

const OrderRating = ({ rating }) => {

    const {
        handleInputChange,
        handleChange,
        handleSubmit,
        type_string,
        formUi,
        form
    } = useOrderRating( rating );

    const { isLoading, isError, isSend } = formUi;

    const isDisabled = rating ? true : false;

    if(type_string !== 'Cliente' && !rating) return null;

    return (
        <div>

            <div className="mt-5">
                <h5 className="font-size-14 fw-bold">
                    <i className="bx bx-comment-check text-muted align-middle me-1"></i>{" "}
                    { rating ? 'Evaluación del pedido' : 'El pedido aún no ha sido evaludado' }
                </h5>
                {
                    isSend ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                            Calificación enviada
                        </Alert>
                    ) : null
                }
                {
                    isError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                            { isError }
                        </Alert>
                    ) : null
                }
                <Form className="pt-3" onSubmit={handleSubmit}>
                    <Row>
                        <Col md="4" sm="6">
                            <div className="p-4 text-center">
                                <h5 className="font-size-12 fw-bold m-b-15">Pigmento</h5>
                                <Rating
                                    start={0}
                                    initialRating={ form.atencion }
                                    stop={3}
                                    emptySymbol="mdi mdi-star-outline fa-2x text-muted"
                                    fullSymbol={"mdi mdi-star fa-2x text-primary"}
                                    onChange={ e => handleChange({ value: e, name: 'atencion' }) }
                                    readonly={ isDisabled }
                                />
                            </div>                        
                        </Col>
                        <Col md="4" sm="6">
                            <div className="p-4 text-center">
                                <h5 className="font-size-12 fw-bold m-b-15">Calidad</h5>
                                <Rating
                                    start={0}
                                    initialRating={ form.calidad }
                                    stop={3}
                                    emptySymbol="mdi mdi-star-outline fa-2x text-muted"
                                    fullSymbol={"mdi mdi-star fa-2x text-primary"}
                                    onChange={ e => handleChange({ value: e, name: 'calidad' }) }
                                    readonly={ isDisabled }
                                />
                            </div>
                        </Col>
                        <Col md="4" sm="6">
                            <div className="p-4 text-center">
                                <h5 className="font-size-12 fw-bold m-b-15">Salud del ave</h5>
                                <Rating
                                    start={0}
                                    initialRating={ form.tiempo }
                                    stop={3}
                                    emptySymbol="mdi mdi-star-outline fa-2x text-muted"
                                    fullSymbol={"mdi mdi-star fa-2x text-primary"}
                                    onChange={ e => handleChange({ value: e, name: 'tiempo' }) }
                                    readonly={ isDisabled }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <div className="mb-3">
                                <Label htmlFor="formrow-InputCity">Hora de llegada</Label>
                                <InputGroup>
                                    <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Select time"
                                        options={{
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            onChange: (arrTime, time) => {
                                                handleChange({
                                                    name: 'arrival_time',
                                                    value: time
                                                })
                                            }
                                        }}
                                        value={form.arrival_time}
                                        disabled={ isDisabled }
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="mdi mdi-clock-outline" />
                                        </span>
                                    </div>
                                </InputGroup>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3">
                                <Label htmlFor="formrow-InputState">Hora de salida</Label>
                                <InputGroup>
                                    <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Select time"
                                        options={{
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            onChange: (arrTime, time) => {
                                                handleChange({
                                                    name: 'departure_time',
                                                    value: time
                                                })
                                            }
                                        }}
                                        value={form.departure_time}
                                        disabled={ isDisabled }
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="mdi mdi-clock-outline" />
                                        </span>
                                    </div>
                                </InputGroup>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">Inicio de descarga</Label>
                                <InputGroup>
                                    <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Select time"
                                        options={{
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            onChange: (arrTime, time) => {
                                                handleChange({
                                                    name: 'start_discharge',
                                                    value: time
                                                })
                                            }
                                        }}
                                        value={form.start_discharge}
                                        disabled={ isDisabled }
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="mdi mdi-clock-outline" />
                                        </span>
                                    </div>
                                </InputGroup>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">Fin de descarga</Label>
                                <InputGroup>
                                    <Flatpickr
                                        className="form-control d-block"
                                        placeholder="Select time"
                                        options={{
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            onChange: (arrTime, time) => {
                                                handleChange({
                                                    name: 'end_discharge',
                                                    value: time
                                                })
                                            }
                                        }}
                                        value={form.end_discharge}
                                        disabled={ isDisabled }
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="mdi mdi-clock-outline" />
                                        </span>
                                    </div>
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className="mb-3">
                                <Label htmlFor="formrow-InputCity">Muertos</Label>
                                <Input
                                    type="number"
                                    className="form-control"
                                    id="formrow-InputCity"
                                    name="dead"
                                    min={0}
                                    value={form.dead}
                                    onChange={handleInputChange}
                                    disabled={ isDisabled }
                                    required
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <Label htmlFor="formrow-InputState">Lastimados</Label>
                                <Input
                                    type="number"
                                    className="form-control"
                                    id="formrow-InputCity"
                                    name="injured"
                                    min={0}
                                    value={form.injured}
                                    onChange={handleInputChange}
                                    disabled={ isDisabled }
                                    required
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">Remolochas</Label>
                                <Input
                                    type="number"
                                    className="form-control"
                                    id="formrow-InputCity"
                                    name="beets"
                                    min={0}
                                    value={form.beets}
                                    onChange={handleInputChange}
                                    disabled={ isDisabled }
                                    required
                                />
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="mb-3">
                                <Label htmlFor="formrow-InputZip">Observaciones</Label>
                                <Input
                                    type="textarea"
                                    className="form-control"
                                    id="formrow-InputCity"
                                    name="observations"
                                    value={form.observations}
                                    onChange={handleInputChange}
                                    disabled={ isDisabled }
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        { type_string === 'Cliente' && !rating  && (
                            <Col className="text-center">
                                { !isSend ? (
                                    <button                                        
                                        type="submit"
                                        className="btn w-sm btn-danger btn-sm"
                                        disabled={ isLoading }
                                    >
                                        <i className="fas fa-check"></i> Enviar Evaluación
                                    </button>
                                ) : null }
                                <div className="text-center mt-3">
                                    { isLoading ? <Spinner /> : null }
                                </div>                            
                            </Col>
                        )}
                    </Row>
                </Form>                
            </div>
        </div>
    )
}

OrderRating.propTypes = {
    rating: PropTypes.any,
    orderStatus: PropTypes.string
};

export default OrderRating;