export const GET_OBJECT_IMP = "GET_OBJECT_IMP"
export const GET_OBJECT_IMP_SUCCESS = "GET_OBJECT_IMP_SUCCESS"
export const GET_OBJECT_IMP_FINISHED = "GET_OBJECT_IMP_FINISHED"

export const GET_TAX_REGIME = "GET_TAX_REGIME"
export const GET_TAX_REGIME_SUCCESS = "GET_TAX_REGIME_SUCCESS"
export const GET_TAX_REGIME_FINISHED = "GET_TAX_REGIME_FINISHED"

export const GET_USE_CFDI = "GET_USE_CFDI"
export const GET_USE_CFDI_SUCCESS = "GET_USE_CFDI_SUCCESS"
export const GET_USE_CFDI_FINISHED = "GET_USE_CFDI_FINISHED"

export const GET_INVOICE_TYPE = "GET_INVOICE_TYPE"
export const GET_INVOICE_TYPE_SUCCESS = "GET_INVOICE_TYPE_SUCCESS"
export const GET_INVOICE_TYPE_FINISHED = "GET_INVOICE_TYPE_FINISHED"

export const GET_PAYMENT_FORM = "GET_PAYMENT_FORM"
export const GET_PAYMENT_FORM_SUCCESS = "GET_PAYMENT_FORM_SUCCESS"
export const GET_PAYMENT_FORM_FINISHED = "GET_PAYMENT_FORM_FINISHED"

export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD"
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS"
export const GET_PAYMENT_METHOD_FINISHED = "GET_PAYMENT_METHOD_FINISHED"
