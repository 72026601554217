import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { userForgetPasswordInit } from '../../../store/actions';

import { 
    Row, 
    Col, 
    Card, 
    CardBody, 
    Container 
} from 'reactstrap';

import profile from '../../../assets/images/logo-cresta.png';
import logo from '../../../assets/images/logo.svg';
import ForgetForm from './ForgetForm';

const ForgetPasswordContainer = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( userForgetPasswordInit() );
    }, []);

    return (
        <React.Fragment>
            <MetaTags>
                <title>
                    Recuperar cuenta | Cresta
                </title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                        <Card className="overflow-hidden">
                            <div className="bg-warning bg-soft">
                                <Row>
                                    <Col xs={7}>
                                    <div className="text-dark p-4">
                                        <h5 className="text-dark">Bienvenido de nuevo</h5>
                                        <p>Si olvidaste tu contraseña, ingresa tu correo electrónico para ayudarte a recuperarla</p>
                                    </div>
                                    </Col>
                                    <Col className="col-5 align-self-end">
                                        <img src={profile} alt="" className="img-fluid" />
                                    </Col>
                                </Row>
                            </div>
                            <CardBody className="pt-0">
                                <div>
                                    <Link to="/">
                                    <div className="avatar-md profile-user-wid mb-4">
                                        <span className="avatar-title rounded-circle bg-light">
                                        <img
                                            src={logo}
                                            alt=""
                                            className="rounded-circle"
                                            height="34"
                                        />
                                        </span>
                                    </div>
                                    </Link>
                                </div>
                            
                                <ForgetForm />

                            </CardBody>
                        </Card>
                        <div className="mt-5 text-center">
                            <p className="text-muted">
                                Regresar a {" "}
                                <Link to="login" className="font-weight-medium text-dark">
                                    Inicio de sesión
                                </Link>{" "}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
  )
}

export default withRouter(ForgetPasswordContainer);