import React from 'react';
import PropTypes from 'prop-types';

import { REPORTS } from 'store/catalogs/administrative-data/actionsTypes';

import ClientForm from './ClientForm';
import ProviderForm from './ProviderForm';
import WarehouseCheckForm from './WarehouseCheckForm';
import AjustForm from 'pages/Administrative/Reports/Inventory/Ajustaments/AjustForm';

export const TypeForm = ({ activeTab, dataRow, toggleRightCanvas, isNew }) => {

    switch (activeTab) {
        case REPORTS.CLIENTS:
            return <ClientForm 
                        dataRow={ dataRow } 
                        isNew={ isNew } 
                        toggleRightCanvas={ toggleRightCanvas } 
                    />
        case REPORTS.PROVIDERS:
            return <ProviderForm 
                        dataRow={ dataRow }
                        isNew={ isNew }
                        toggleRightCanvas={ toggleRightCanvas }
                    />
        case REPORTS.WAREHOUSE_CHECK:
            return <AjustForm 
                        dataRow={ dataRow } 
                        toggleRightCanvas={ toggleRightCanvas } 
                        isNew={ isNew }
                    />;
        default:
            return <p>No match</p>;
    }
}

TypeForm.propTypes = {
    activeTab: PropTypes.string,
    dataRow: PropTypes.object,
    toggleRightCanvas: PropTypes.func,
    isNew: PropTypes.bool
}
