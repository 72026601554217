import { tax_configuration_validation } from "common/sat/Taxes"
import { InvoiceType } from "constants/invoice"
import moment from "moment"
import * as Yup from "yup"
import { es } from "yup-locales"
import { DocumentoRelacionado } from "./components/complements/static"

Yup.setLocale(es)

const formattedToday = moment().format("YYYY-MM-DD HH:mm")

export const initialValues = {
  InvoiceType: InvoiceType.Invoice, // internal flag
  movs_client_ids: [], //optional
  mov_client_id: "", //optional
  ppd_invoice_id: "",
  NameId: "",
  CfdiType: "I",
  PaymentForm: "",
  PaymentMethod: "",
  Currency: "MXN",
  Exportation: "", //opcional
  Date: formattedToday,
  ExpeditionPlace: "",
  Folio: "",
  Issuer: {
    FiscalRegime: "",
    Rfc: "",
    Name: "",
  },
  Receiver: {
    Rfc: "",
    Name: "",
    FiscalRegime: "",
    CfdiUse: "",
    TaxZipCode: "",
  },
  Items: [],
  Complemento: {
    Error: "",
    Date: "",
    PaymentForm: "",
    Amount: "",
    RelatedDocuments: [
      {
        ...DocumentoRelacionado,
      },
    ],
  },
  GlobalInformation: {
    Periodicity: "",
    Months: "",
    Year: "",
  },
  //Relations: { Type: "", Cfdis: [] }, // https://apisandbox.facturama.mx/guias/cfdi40/nota-credito
}

export const productValidation = Yup.object({
  ProductCode: Yup.string().nullable().required().label("Código SAT"),
  Description: Yup.string().required().label("Producto"),
  UnitCode: Yup.string().nullable().required().label("Unidad SAT"),
  Quantity: Yup.number().moreThan(0).required().label("Cantidad"),
  UnitPrice: Yup.number().moreThan(0).required().label("Precio Unitario"),
  Subtotal: Yup.number().moreThan(0).required().label("Subtotal"),
  Discount: Yup.number()
    .min(0)
    .test(
      "is-less-than-subtotal",
      "El descuento no puede ser mayor que el subtotal",
      function (value) {
        const subtotal = this.parent.Subtotal
        return value <= subtotal
      }
    )
    .label("Descuento"),
  Total: Yup.number().label("Total"),
  TaxObject: Yup.string().nullable().required().label("Objeto Impuesto"),
  Taxes: Yup.array().of(
    Yup.object({
      Base: Yup.number(),
      IsRetention: Yup.bool(),
      IsQuota: Yup.bool(),
      Name: Yup.string(),
      Rate: Yup.number(),
      Total: Yup.number(),
      IsFederalTax: Yup.bool(),
    })
  ),
  tax_configuration: tax_configuration_validation,
})

export const invoiceValidation = Yup.object({
  NameId: Yup.string().nullable(),
  CfdiType: Yup.string().required().label("Tipo de Cfdi"),
  PaymentForm: Yup.string().required().label("Forma de pago"),
  PaymentMethod: Yup.string()
    .nullable()
    .label("Método de pago")
    .when("CfdiType", {
      is: "P",
      //then: schema => schema.nullable(),
      otherwise: schema => schema.required(),
    }),
  Currency: Yup.string().required().label("Moneda"),
  Exportation: Yup.string().nullable(),
  Date: Yup.string().test({
    name: "max-date",
    exclusive: true,
    message: "La fecha solo puede ser menor a 72 horas como máximo",
    test: value => {
      const today = moment(formattedToday)
      const valueDate = moment(value)
      const minDate = today.clone().subtract(72, "hours")

      if (valueDate.isAfter(today)) return false
      if (valueDate.isBefore(minDate)) return false

      return true
    },
  }),
  ExpeditionPlace: Yup.string().required().label("C.P. Expedición"),
  Folio: Yup.string().required().label("Folio"),
  Issuer: Yup.object({
    FiscalRegime: Yup.string().required().label("Régimen fiscal"),
    Rfc: Yup.string().required().label("RFC"),
    Name: Yup.string().required().label("Razón social"),
  }),
  Receiver: Yup.object({
    Rfc: Yup.string().required().label("RFC"),
    Name: Yup.string().required().label("Razón social"),
    FiscalRegime: Yup.string().required().label("Régimen fiscal"),
    //CfdiUse: Yup.string().required().label("Uso de CFDI"),
    CfdiUse: Yup.string()
      .nullable()
      .test("is-ppd", "Uso de CFDI es requerido", (value, context) => {
        const { CfdiType } = context.options.context
        const isPPD = CfdiType === "P"

        if (isPPD) return true
        if (value) return true
        return false
      }),
    TaxZipCode: Yup.string().required().label("C.P."),
  }),
  Items: Yup.array()
    .of(productValidation)
    .label("Productos")
    .when("CfdiType", {
      is: value => {
        return value === "P"
      },
      then: schema => schema.min(0),
      otherwise: schema => schema.min(1),
    }),
  Relations: Yup.object().when(["IsDebitNote", "IsCreditNote"], {
    is: (IsDebitNote, IsCreditNote) => IsDebitNote || IsCreditNote,
    then: () =>
      Yup.object({
        Type: Yup.string().nullable(),
        Cfdis: Yup.array()
          .of(
            Yup.object().shape({
              Uuid: Yup.string().required().label("UUID Cfdi relacionado"),
            })
          )
          .min(1)
          .max(1),
      }),
    otherwise: schema => schema.nullable(),
  }),
  IsDebitNote: Yup.bool().nullable(),
  IsCreditNote: Yup.bool().nullable(),
  mov_client_id: Yup.string().nullable(),
  ppd_invoice_id: Yup.string().nullable(),
  InvoiceType: Yup.string().nullable(),
  movs_client_ids: Yup.array().of(Yup.number()).min(0),
  Complemento: Yup.object().when("CfdiType", {
    is: "P",
    then: schema =>
      schema.shape({
        Error: Yup.boolean().required(), //Flag
        Date: Yup.string().nullable(),
        PaymentForm: Yup.string().nullable(),
        Amount: Yup.number()
          .nullable()
          .min(0)
          .required()
          .label("Monto a pagar"),
        RelatedDocuments: Yup.array().of(
          Yup.object({
            Error: Yup.boolean().required(), //Flag
            TaxObject: Yup.string().nullable(),
            Uuid: Yup.string().nullable().required().label("UUID"),
            Serie: Yup.string().nullable(),
            Folio: Yup.string().nullable(),
            PaymentMethod: Yup.string().nullable(),
            PartialityNumber: Yup.number().min(0).nullable(),
            PreviousBalanceAmount: Yup.number()
              .min(0)
              .nullable()
              .required()
              .label("Monto previo"),
            AmountPaid: Yup.number()
              .positive()
              .nullable()
              .required()
              .label("Monto pagado"),
            ImpSaldoInsoluto: Yup.number()
              .min(0)
              .nullable()
              .required()
              .label("Importe Saldo Insoluto"),
          })
        ),
      }),
  }),
  GlobalInformation: Yup.object().when("Receiver.Rfc", {
    is: "XAXX010101000",
    then: () =>
      Yup.object().shape({
        Periodicity: Yup.string().required().label("Periodicidad"),
        Months: Yup.string().required().label("Meses"),
        Year: Yup.string().required().label("Año"),
      }),
    otherwise: () =>
      Yup.object().shape({
        Periodicity: Yup.string().label("Periodicidad"),
        Months: Yup.string().label("Meses"),
        Year: Yup.string().label("Año"),
      }),
  }),
})

/*
Complemento: {
  Payments: [
    {
      Date: "2022-04-10",
      PaymentForm: "03",
      Amount: "",
      RelatedDocuments: [
        {
          TaxObject: "01",
          Uuid: "",
          Serie: "",
          Folio: "",
          PaymentMethod: "PPD",
          PartialityNumber: "",
          PreviousBalanceAmount: "",
          AmountPaid: "",
          ImpSaldoInsoluto: "",
        },
      ],
    },
  ],
},
*/
