import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setActiveTabAdminiData, setTypeAdminiData } from 'store/catalogs/administrative-data/actions';
import { REPORTS, REPORT_TYPE } from 'store/catalogs/administrative-data/actionsTypes';
import { TypeForm } from './RightForms/TypeForm';

import './custom.scss';
import FullSpinner from 'components/Common/FullSpinner';
import PageBase from 'components/Common/PageBase';
import Filters from './Filters';
import Tabs from '../common/Tabs';
import DataTable from '../common/DataTable';
import OffCanvas from '../common/OffCanvas';
import { Card, CardBody } from 'reactstrap';
import ModalDelete from '../common/ModalDelete';
import { getAccountingAcounts } from 'store/accounting-accounts/actions';
import ModalHistory from '../common/ModalHistory';

const breadcrumbList = [
    { id: '1', title: 'Administrativo', link:'', active: false },
    { id: '2', title: 'Bancos', link:'', active: true }
];

export default function BanksContainer() {

    const dispatch = useDispatch();
    
    const [ isNew, setIsNew ] = useState(false);
    const [ isRight, setIsRight ] = useState(false);
    const [ dataRow, setDataRow ] = useState({});
    const [ modalDelete, setModalDelete ] = useState(false);
    const [ modalHistory, setModalHistory ] = useState(false);

    const { 
        activeTab, 
        dataReports,
        ui: { isLoading }
    } = useSelector(state => state.AdministrativeData);

    const {
        clientAccounts
    } = useSelector(state => state.AccountingAccounts);

    const { title } = dataReports[activeTab];

    const handleEdit =  row =>  {
        const { cost_account, client_account, provider_account } = row;

        const getAccountInfo = account => (
            account ? {
                accounting_account_id: account.id,
                accounting_account_type: account.type
            } : {}
        );
    
        const parsedRow = {
            ...row,
            ...getAccountInfo(cost_account),
            ...getAccountInfo(client_account),
            ...getAccountInfo(provider_account),
        };

        setIsNew(false);
        setDataRow(parsedRow);
        setIsRight(true);  
    }

    const handleDelete = row => {
        setModalDelete(prev => !prev);
        setDataRow(row);
    }

    const handleHistory = row => {
        setModalHistory(true);
        setDataRow(row);
    }
    
    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    }

    useEffect(() => {
        dispatch(setActiveTabAdminiData(REPORTS.BANKS));
        dispatch(setTypeAdminiData(REPORT_TYPE.BANKS));
    }, []);

    useEffect(() => {
        //if(clientAccounts.length) return;
        
        dispatch(getAccountingAcounts());
    }, []);

    return (
        <>  
            { isLoading ? <FullSpinner /> : '' }
            <PageBase
                titleMeta="Bancos"
                titleBread="Bancos"
                itemsBread={ breadcrumbList }
            >
                <Card>
                    <CardBody>
                        <Filters 
                            setIsNew={ setIsNew }
                            toggleRightCanvas={ toggleRightCanvas }
                        />
                        <Tabs />
                        <DataTable 
                            handleEdit={ handleEdit }
                            handleDelete={ handleDelete }
                            handleHistory={ handleHistory }
                        />
                        <OffCanvas 
                            isNew={ isNew }
                            isRight={ isRight }
                            toggleRightCanvas={ toggleRightCanvas }
                            reportTitle={ title }
                        >
                            <TypeForm 
                                isNew={ isNew }
                                activeTab={ activeTab }
                                dataRow={ dataRow }
                                toggleRightCanvas={ toggleRightCanvas }
                            />
                        </OffCanvas>
                        <ModalDelete
                            isOpen={ modalDelete } 
                            setOpenModal={ setModalDelete }
                            row={ dataRow }
                        />
                        { modalHistory && <ModalHistory
                            isOpen={ modalHistory }
                            setOpenModal={ setModalHistory }
                            row={ dataRow }
                        /> }
                    </CardBody>
                </Card>
            </PageBase>
        </>
    )
}