import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { useSelector } from "react-redux"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"

const GeneralData = ({ validation, disableCfdiType = true }) => {
  const { invoice_type, payment_form, payment_method } = useSelector(
    state => state.Sat
  )

  const isComplementPayment = validation.values.CfdiType === "P"
  const isPPDInvoice = validation.values.PaymentMethod === "PPD"

  useEffect(() => {
    if (isPPDInvoice) {
      validation.setFieldValue("PaymentForm", "99")
    }
  }, [isPPDInvoice])

  return (
    <div className="pb-4">
      <h5>Generales</h5>
      <Row>
        <NibbleBaseInput
          fieldType="select"
          fieldName="CfdiType"
          label="Tipo CFDI"
          selectItems={invoice_type?.data
            ?.map(item => ({
              ...item,
              id: item.code,
            }))
            .filter(item => ["I", "E", "P"].includes(item.code))}
          validation={validation}
          selectOptionValue={option => option.id}
          selectOptionLabel={option => `${option.code} - ${option.description}`}
          md={4}
          disabled={disableCfdiType}
          placeHolder="Seleccionar"
          isLoading={invoice_type.isLoading}
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName="ExpeditionPlace"
          label="C.P. Expedición"
          validation={validation}
          md={4}
          disabled
        />
        <NibbleBaseInput
          fieldType="datetime"
          fieldName="Date"
          label="Fecha"
          validation={validation}
          md={4}
        />
        <NibbleBaseInput
          fieldType="text"
          fieldName="Folio"
          label="Folio factura"
          validation={validation}
          md={4}
          disabled
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName="PaymentForm"
          label="Forma de pago"
          selectItems={payment_form?.data?.map(item => ({
            ...item,
            id: item.code,
          }))}
          validation={validation}
          selectOptionValue={option => option.id}
          selectOptionLabel={option => `${option.code} - ${option.description}`}
          md={4}
          placeHolder="Seleccionar"
          isLoading={payment_form.isLoading}
          disabled={isPPDInvoice}
        />
        {!isComplementPayment ? (
          <NibbleBaseInput
            fieldType="select"
            fieldName="PaymentMethod"
            label="Método de pago"
            selectItems={payment_method?.data?.map(item => ({
              ...item,
              id: item.code,
            }))}
            validation={validation}
            selectOptionValue={option => option.id}
            selectOptionLabel={option =>
              `${option.code} - ${option.description}`
            }
            md={4}
            placeHolder="Seleccionar"
            isLoading={payment_method.isLoading}
          />
        ) : null}
      </Row>
    </div>
  )
}

GeneralData.propTypes = {
  validation: PropTypes.object,
  disableCfdiType: PropTypes.bool,
}

export default GeneralData
