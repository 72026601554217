import React from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';

import {
    Container
} from 'reactstrap';

import BreadcrumbNav from './BreadcrumbNav';

const PageBase = ({ children, titleMeta, titleBread, itemsBread }) => {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{ titleMeta }</title>
                </MetaTags>

                <Container fluid>                    
                    <BreadcrumbNav 
                        title={ titleBread }
                        items={ itemsBread }
                    />

                    { children }

                </Container>
            </div>
        </React.Fragment>
    )
}

PageBase.propTypes = {
    children: PropTypes.any,
    titleMeta: PropTypes.string,
    titleBread: PropTypes.string,
    itemsBread: PropTypes.array
}

export default PageBase;