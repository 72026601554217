import React, { useState } from "react"

import PropTypes from "prop-types"
import { Button, Col, Row } from "reactstrap"
import useDownloadCfdi from "hooks/useDownloadCfdi"

const InvoiceDownloadButtons = ({ stampedInvoice, toggleRightCanvas }) => {
  const {
    downloadInvoice,
    isDownloadingPdf,
    isDownloadingXml,
    isDownloadingInvoice,
  } = useDownloadCfdi()

  return (
    <div className="py-4">
      <Row>
        <Col className="d-flex justify-content-end gap-2">
          <Button
            onClick={toggleRightCanvas}
            color="secondary"
            className="btn btn-secondary btn"
            disabled={isDownloadingInvoice}
          >
            Cerrar
          </Button>
          <Button
            onClick={() => downloadInvoice("xml", stampedInvoice.Id)}
            color="primary"
            disabled={isDownloadingInvoice}
          >
            <i
              className={`${
                isDownloadingXml ? "bx-loader bx-spin" : "bx-file"
              } bx font-size-16 align-middle me-2`}
            ></i>
            Descargar XML
          </Button>
          <Button
            onClick={() => downloadInvoice("pdf", stampedInvoice.Id)}
            color="primary"
            disabled={isDownloadingInvoice}
          >
            <i
              className={`${
                isDownloadingPdf ? "bx-loader bx-spin" : "bxs-file-pdf"
              } bx font-size-16 align-middle me-2`}
            ></i>{" "}
            Descargar PDF
          </Button>
        </Col>
      </Row>
    </div>
  )
}

InvoiceDownloadButtons.propTypes = {
  stampedInvoice: PropTypes.object,
  toggleRightCanvas: PropTypes.func,
}

export default InvoiceDownloadButtons
