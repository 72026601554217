import { InvoiceType } from "constants/invoice"
import * as _ from "lodash"
export const buildInvoice = formValues => {
  //TaxObject: '01' o '03' o '04' el nodo hijo Impuestos(Taxes) del nodo concepto no debe existir
  const newItems = formValues.Items.map(item => {
    const taxesNotRequired = ["01", "03", "04"].includes(item.TaxObject)
    if (taxesNotRequired) {
      return _.omit(item, "Taxes")
    }

    return item
  })

  //Solo aplica para Complementos de pago por eso se omite
  const newFormValues = _.omit(formValues, "Complemento")

  if (formValues.Receiver.Rfc !== "XAXX010101000") {
    delete newFormValues.GlobalInformation
  }

  return {
    ...newFormValues,
    Items: newItems,
  }
}

export const buildComplementInvoice = formValues => {
  const Amount = _.get(formValues, "Complemento.Amount", 0)
  const RelatedDocuments = _.get(formValues, "Complemento.RelatedDocuments", [])

  return {
    InvoiceType: InvoiceType.Invoice, // internal flag
    CfdiType: "P",
    NameId: "14",
    Folio: formValues?.Folio || "",
    ExpeditionPlace: formValues?.ExpeditionPlace || "",
    Issuer: {
      FiscalRegime: formValues?.Issuer?.FiscalRegime,
      Rfc: formValues?.Issuer?.Rfc,
      Name: formValues?.Issuer?.Name,
    },
    Receiver: {
      //En el nodo Receptor el atributo CfdiUse debe ser "CP01"
      CfdiUse: "CP01",
      Rfc: formValues?.Receiver?.Rfc,
      Name: formValues?.Receiver?.Name,
      FiscalRegime: formValues?.Receiver?.FiscalRegime,
      TaxZipCode: formValues?.Receiver?.TaxZipCode,
    },
    Complemento: {
      Payments: [
        {
          Date: formValues?.Date || "",
          PaymentForm: formValues?.PaymentForm || "",
          Amount: Amount,
          RelatedDocuments: RelatedDocuments.map(doc => _.omit(doc, "Error")),
        },
      ],
    },
    movs_client_ids: [], //only present
  }
}
