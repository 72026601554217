import * as moment from 'moment';

export function formatPeriod(period){
    const dates = period.split('/');
    const formatedPeriod = moment(dates[0]).format('DD/MM/Y') + ' al ' + moment(dates[1]).format('DD/MM/Y')
    return formatedPeriod
}

export function convertToNumber(numberString) {
    const number = String(numberString).replace(/\,/g,'');
    return Number(number);
}

const numberFormat1 = new Intl.NumberFormat('es-MX', { minimumFractionDigits: 2 });
export function formatNumber(number) {
    return numberFormat1.format(number)
}