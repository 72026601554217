import {React, useState} from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import moment from 'moment';

import { 
    Col, 
    FormGroup, 
    InputGroup, 
    Label, 
    Row
} from 'reactstrap';
import BtnDownloadExcel from '../common/BtnDownloadExcel';
import { getBillingReport } from 'helpers/backend_helper';
import BtnDownloadPdf from '../common/BtnDownloadPdf';
import { monthsFilterValues } from 'common/data/months';
import MonthButton from 'pages/Administrative/common/MonthButton';
import { DropdownMenu, DropdownToggle, DropdownItem, UncontrolledDropdown } from 'reactstrap';

const header_excel = {
    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

const header_pdf = {
    'Accept': 'application/pdf'
};

const curYear = moment().year()
const optionsYears = Array(8).fill(0).map((_, i) => { return {id: curYear - i, name: curYear - i} });

export default function Filters({ data, defaultPeriod, setPeriod, period, columnOrder, toggleRightCanvas, setIsNew }) {

    const {
        column,
        order
    } = columnOrder;

    const config = {
        params: {
            period,
            column,
            order
        }
    };

    const handleDatePicker = dates => {
        if(dates.length !== 2) return;

        const parseDates = dates.map( 
                                date => ( moment(date).format('YYYY-MM-DD') )
                            ).join('/');
        setPeriod(parseDates);
    }

    const downloadFile = (type = 'excel') => getBillingReport({
        ...config,
        headers: (type === 'excel') ? header_excel : header_pdf
    });

    const [ year, setYear ] = useState({id: curYear, name: curYear});
    const handleSelectYear = (year) => {
        const dates = period.split('/')
        const start = moment(dates[0])
        const end = moment(dates[1])
        start.year(year)
        end.year(year)
        const parseDates = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
        setPeriod(parseDates)
    }

    return (
        <Row>
            <Col lg={3}>
                <FormGroup className="mb-4">
                    <Label>Periodo</Label>
                    <InputGroup>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder=""
                        onChange={ handleDatePicker }
                        options={{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                            locale: Spanish,
                            defaultDate: defaultPeriod.split('/')
                        }}
                    />
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col className="text-right">
                <UncontrolledDropdown
                            style={{ cursor: 'pointer' }}
                        >
                        { (data && data.report.length) ? (
                    <>
                        <BtnDownloadExcel downloadExcel={ downloadFile }/>
                        <BtnDownloadPdf downloadPdf={ downloadFile }/> &nbsp;
                    </>
                ) : ''}
                        <DropdownToggle
                            href="#"
                            className="card-drop action-points"
                            tag="i"
                            onClick={ () => console.log(false) }
                        >
                            <button className='btn btn-sm btn-danger mb-3'>Acciones <i className="mdi mdi-chevron-down "></i>
                            </button>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-enddd actions-menu">                                                                
                            <DropdownItem
                                href="#"
                                onClick={ e => {
                                    e.preventDefault()
                                    toggleRightCanvas()
                                    setIsNew(true)
                                }}
                            >
                                <i className="bx bx-plus font-size-16 text-danger" />{" "}
                                Configuracion de reporte
                            </DropdownItem>                            
                        </DropdownMenu>
                    </UncontrolledDropdown>
            </Col>
            <Col md={12}>
                <UncontrolledDropdown
                    style={{ cursor: 'pointer' }}
                    className="d-inline-block me-1 pt-0 mt-0"
                >
                    <DropdownToggle
                        href="#"
                        className="card-drop action-points"
                        tag="i"
                    > 
                        <button className='btn btn-sm btn-danger'>{ year.name } <i className="mdi mdi-chevron-down"></i>
                        </button>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-enddd actions-menu">
                    { optionsYears.map( (val, key) => { return (
                            <DropdownItem
                                key={key}
                                href="#"
                                onClick={ e => {
                                    e.preventDefault()
                                    setYear({
                                        id: val.id,
                                        name: val.name
                                    })
                                    handleSelectYear(val.id)
                                }}
                            >
                                { val.name }
                            </DropdownItem>
                        )})
                    }
                            
                    </DropdownMenu>
                </UncontrolledDropdown>
                {
                    monthsFilterValues.map( (val, key) => {
                        return (<MonthButton
                            key={key} 
                            month={key}
                            monthString={val}
                            year={year.id}
                            handler={ period => {
                                setPeriod(period)
                            }}
                        />)
                    })
                }
            </Col>
        </Row>
    );
}

Filters.propTypes = {
    setPeriod: PropTypes.func,
    defaultPeriod: PropTypes.string,
    data: PropTypes.object,
    setIsNew: PropTypes.any,
    period: PropTypes.string,
    columnOrder: PropTypes.object,
    toggleRightCanvas: PropTypes.func,
};