import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"
import toastr from "toastr"

export default function getStorageUser() {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  return obj && 'user' in obj ? obj.user:{};
}

//apply base url for axios
const API_URL = process.env.REACT_APP_BACKEND_API_URL

const axiosApi = axios.create({
  baseURL: API_URL
})

function addHeaderAuthorization() {
  const auth = authHeader()
  if ('Authorization' in auth)
    axiosApi.defaults.headers.common["Authorization"] = 'Bearer ' + auth.Authorization
}

export async function get(url, config = {}) {
  addHeaderAuthorization();
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(err => {
      if (axios.isCancel(err)) return;
      var message = ''
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 422:
            message = getError(err)
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.response?.data?.message || err.message
            break
        }
      } else {
        message = err.message
      }
      toastr.error(message);
      throw message
    })
}

export function postFile(url, file, config = {}){

  const formData = new FormData();
  formData.append('excel', file);

  addHeaderAuthorization();
  return axiosApi
    .post(url, formData, { ...config })
    .then(response => response.data)
    .catch(err => {
      var message = ''
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 422:
            message = getError(err)
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.response?.data?.message || err.message
            break
        }
      } else {
        message = err.message
      }
      toastr.error(message);
      throw message
    })
}

export async function post(url, data, config = {}) {
  addHeaderAuthorization();
  return axiosApi
    //.post(url, { ...data }, { ...config })
    .post(url, data, { ...config })
    .then(response => { 
      toastr.success('Completado');
      return response.data
    })
    .catch(err => {
      var message = ''
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 422:
            message = getError(err)
            break
          case 500:
            message = getError(err)
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.response?.data?.message || err.message
            break
        }
      } else {
        message = err.message
      }
      toastr.error(message);
      throw message
    })
}

export async function put(url, data, config = {}) {
  addHeaderAuthorization();
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => {
      toastr.success('Completado');
      return response.data
    })
    .catch(err => {
      var message = ''
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 422:
            message = getError(err)
            break
          case 500:
            message = getError(err) ??
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.response?.data?.message || err.message
            break
        }
      } else {
        message = err.message
      }
      toastr.error(message);
      throw message
    })
}

export async function del(url, config = {}) {
  addHeaderAuthorization();
  return await axiosApi
    .delete(url, { ...config })
    .then(response => {
      toastr.success('Completado');
      return response;
    })
    .catch(err => {
      var message = ''
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 422:
            message = getError(err)
            break
          case 500:
            message = getError(err)
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.response?.data?.message || err.message
            break
        }
      } else {
        message = err.message
      }
      toastr.error(message);
      throw message
    })
}

const getError = err => {
  const message = 'Invalido';
  const data = err.response?.data
  if (data?.errors) {
    if (typeof data?.errors === 'object')
      return Object.values(data?.errors).join(', ')
    if (typeof data?.errors === 'object')
      return data?.errors.join(', ')
  }
  if (data?.message)
    return data?.message
  if (data?.error)
    return data?.error
  return message;
}