import moment from 'moment';

export const orderByName = (a, b) => {
    if (a?.name > b?.name) {
      return 1;
    }
    if (a?.name < b?.name) {
      return -1;
    }
    return 0;
}

export const rangeDatePicker = dates => {
  return dates.map( 
                  date => ( moment(date).format('YYYY-MM-DD') )
                ).join('/');
}