import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, Label } from "reactstrap"
import { AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import { MEXICO_STATES } from "../../../helpers/states"
import MyGoogleMap from "components/Common/Maps/MyGoogleMap"

const AddressWithGpsForm = props => {
  const initState = {
    id: null,
    street: null,
    ext_num: null,
    int_num: null,
    location: null,
    city: "Puebla",
    country: "México",
    cp: null,
    state: "Puebla",
    region_id: null,
  }
  const [address, setAddress] = useState({
    ...initState,
    ...props.entityAddress,
  })

  const [position, setPosition] = useState(null)

  useEffect(() => {
    if (position) {
      setAddress({ ...address, gps: `${position.lat},${position.lng}` })
    }
  }, [position])

  const handleState = e => {
    setAddress({ ...address, state: e.value })
    if (props.handleStateChange) props.handleStateChange(e)
  }

  console.log('props.entityAddress', props.entityAddress)

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <AvField
            name="street"
            label="Calle"
            value={address.street}
            type="text"
            required
            errorMessage="Campo requerido"
            placeholder=""
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="mb-3">
            <AvField
              name="ext_num"
              label="Num. Ext."
              value={address.ext_num}
              type="text"
              required
              errorMessage="Campo requerido"
              placeholder=""
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <AvField
              name="int_num"
              label="Num. Int."
              value={address.int_num}
              type="text"
              errorMessage="Campo requerido"
              placeholder=""
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <AvField
              name="cp"
              label="C.P."
              value={address.cp}
              type="text"
              required
              errorMessage="Campo requerido"
              placeholder=""
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <AvField
              name="location"
              label="Colonia"
              value={address.location}
              type="text"
              required
              errorMessage="Campo requerido"
              placeholder=""
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <AvField
              name="city"
              label="Ciudad/Municipio"
              value={address.city}
              type="text"
              required
              errorMessage="Campo requerido"
              placeholder=""
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-firstname-Input">Estado</Label>
            <Select
              //isClearable
              id="formrow-InputState"
              options={MEXICO_STATES.map(({ id, name }) => ({
                label: name,
                value: name,
              }))}
              onChange={handleState}
              //defaultValue={ {label: farm.region?.name, value: farm.region_id} }
              value={{ label: address.state, value: address.state }}
            />
            {address.state == null ? (
              <div className="invalid-feedback">Campo requerido</div>
            ) : (
              ""
            )}
          </div>
          <AvField
            name="state"
            value={address.state}
            type="hidden"
            required
            errorMessage="Campo requerido"
            placeholder=""
          />
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <AvField
              name="country"
              label="Pais"
              value={address.country}
              type="text"
              required
              errorMessage="Campo requerido"
              placeholder=""
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <AvField
              name="gps"
              label="Ubicación GPS"
              value={address.gps}
              type="text"
              errorMessage="Campo requerido"
              placeholder=""
              disabled={true}
            />
          </div>
          <div className="mb-3">
            <AvField
              name="goo_address"
              value={address.goo_address}
              type="hidden"
              placeholder=""
            />
          </div>
        </Col>
        <Col md={12}>
          <MyGoogleMap
            setPosition={setPosition}
            setAddress={newAddress => {
              setAddress({ ...address, goo_address: newAddress })
            }}
            startPosition={
              address.gps
                ? {
                    lat: Number(address.gps.split(",")[0]),
                    lng: Number(address.gps.split(",")[1]),
                  }
                : null
            }
          />
        </Col>
      </Row>
    </Fragment>
  )
}

AddressWithGpsForm.propTypes = {
  entityAddress: PropTypes.object.isRequired,
  handleStateChange: PropTypes.func.isRequired,
}

export default AddressWithGpsForm
