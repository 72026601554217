import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

import {
    Row,
    Col,
    Card,
    CardBody,
    Spinner,
    FormGroup,
    Label,
    InputGroup
} from 'reactstrap';

import PageBase from 'components/Common/PageBase';
import PageNotFound from 'pages/Utility/PageNotFound';
import { getProducts, getProvidersAvailability } from 'helpers/backend_helper';
import useRequest from 'hooks/useRequest';
import moment from 'moment';
import ProviderDailyAvailabilityRow from 'components/Common/ProviderDailyAvailabilityRow';
import BreadcrumbNav from '../../../components/Common/BreadcrumbNav';


const breadcrumbList = [
    { id: '1', title: 'Pedidos', link:'#', active: false },
    { id: '2', title: 'Detalle del pedido', link: '#', active: true }
];

const today = moment().format('YYYY-MM-DD');

export default function OrderContainer() {

    const [date, setDate] = useState(today);
    const { data: availability, req, isLoading } = useRequest();


    const fetchAvailability = () => {

        const config = {
            params: {
                date
            }
        }
        req(getProvidersAvailability(config));
    }

    useEffect(() => {

        fetchAvailability();
    }, [date]);

    const breadcrumbItems = [
        { id: "1", title: "Dashboard", link: "", active: true },
      ]

    return (
        <>  
            <PageBase
                titleMeta="Visualizar Disponibilidad"
                titleBread="Visualizar Disponibilidad"
                itemsBread={ breadcrumbList }
            > 
            
                <Card>
                    <CardBody>  
                        <Row>
                            <Col lg={12}>                            
                                <FormGroup className="mb-0">
                                    <Row>
                                      <Col lg={4} className="text-end"><Label>Fecha</Label></Col>
                                        <Col lg={4}>
                                            <InputGroup>
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    placeholder=""
                                                    onChange={(value, parsed) => setDate(parsed)}
                                                    options={{
                                                        dateFormat: 'Y-m-d',
                                                        locale: Spanish,
                                                        defaultDate: today
                                                    }}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </FormGroup>                        
                            </Col>
                            {isLoading && <div className="text-center"><Spinner animation="border" variant="danger" style={{color:"#f46a6a"}}/></div>}
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col lg="12">
                        {!isEmpty(availability) && (
                            <Card>
                                <CardBody>
                                    {availability.data.map(item => (
                                        <div key={item.id}>
                                            <Row>
                                                <Col lg={4}><h6>Proveedor: <b>{item.provider.company_name}</b></h6></Col>
                                                <Col lg={4}><h6>Sucursal: {item.farm.name}</h6></Col>
                                                <Col lg={4}><span className="d-block mb-2">Fecha: {item.date}</span></Col>
                                            </Row>
                                            
                                            <ProviderDailyAvailabilityRow 
                                                product={item}
                                                farmId={item.farm.id}
                                                regions={[]}
                                                date={date}
                                                daily_availability={item}
                                                farm={item.farm}
                                                daily_previous={item} 
                                                onSave={() => {}}
                                            />
                                        </div>
                                    ))}
                                    {availability.data.length === 0 && <p>No hay registros</p>}
                                </CardBody>
                            </Card>
                        )}
                    </Col>
                </Row>
            </PageBase>
        </>
    )
}