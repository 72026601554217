import React from 'react';
import PropTypes from 'prop-types';

const nf = new Intl.NumberFormat('es-MX');

export default function TableMovementItem({ item }) {

    const {
        skey,
        client,
        pieces,
        kgs,
        total_vta,
        nota_cargo,
        nota_credito,
        venta_neta,
        decrease,
        mortalidad_percentaje,
        total_vta_percentaje,
        utility,
        utility_percentaje
    } = item;

    return (
        <>
            <tr>
                <td className="text-center">
                    { skey }
                </td>
                <td className="text-center">
                    { client }
                </td>
                <td className="text-right">
                    { nf.format(pieces) }
                </td>
                <td className="text-right">
                    { nf.format(kgs) }
                </td>
                <td className="text-right">
                    { nf.format(total_vta) }
                </td>
                <td className="text-right">
                    { nf.format(decrease) }
                </td>
                <td className="text-right">
                    { nf.format(nota_credito) }
                </td>
                <td className="text-right">
                    { nf.format(nota_cargo) }
                </td>
                <td className="text-right">
                    { nf.format(venta_neta) }
                </td>
                <td className="text-right">
                    { nf.format(utility) }
                </td>
                <td className="text-right">
                    { nf.format(utility_percentaje) }%
                </td>
                <td className="text-right">
                    { nf.format(total_vta_percentaje) }%
                </td>
                <td className="text-right">
                    { nf.format(mortalidad_percentaje) }%
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object
};