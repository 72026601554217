// https://apisandbox.facturama.mx/guias/api-web/cfdi/factura

import { NumberFixed } from "./numbers"

// Facturama Tax
export const getTaxesCalculated = (Subtotal, Discount, tax_configuration) => {
  //console.log({ Subtotal, Discount, tax_configuration })

  let Base = Subtotal - Discount
  let Total = Base
  let Taxes = []

  if (tax_configuration.isr.Rate) {
    const TaxItem = calculateTax(Base, tax_configuration.isr)

    Total -= TaxItem.Total

    Taxes = [...Taxes, TaxItem]
  }

  if (tax_configuration.iva_r.Rate) {
    const TaxItem = calculateTax(Base, tax_configuration.iva_r)

    Total -= TaxItem.Total

    Taxes = [...Taxes, TaxItem]
  }

  if (tax_configuration.ieps.Rate) {
    const TaxItem = calculateTax(Base, tax_configuration.ieps)

    Total += TaxItem.Total
    Base += TaxItem.Total

    Taxes = [...Taxes, TaxItem]
  }

  if (tax_configuration.iva_t.Rate) {
    const TaxItem = calculateTax(Base, tax_configuration.iva_t)

    Total += TaxItem.Total

    Taxes = [...Taxes, TaxItem]
  }

  return {
    Taxes,
    Total: NumberFixed(Total),
  }
}

const calculateTax = (Base, tax_config) => {
  const { Name, Rate, IsRetention, IsQuota } = tax_config

  let Total = 0

  if (IsQuota) {
    Total = Rate
  } else {
    Total = Base * tax_config.Rate
  }

  return {
    Name,
    Rate,
    Total: NumberFixed(Total),
    Base,
    IsRetention,
    IsFederalTax: "true",
  }
}
