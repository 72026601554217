import React, { useCallback, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import Select from 'react-select';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useState } from "react";
import { useRef } from "react";
import { getSatProducts, getSatUnits, postProduct } from "helpers/backend_helper";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Alert } from "reactstrap";
import { getUnits, getProviders } from "helpers/backend_helper";
import BreadcrumbNav from "components/Common/BreadcrumbNav";
import axios from "axios";
import useRequest from "hooks/useRequest";
import { useForm } from "hooks/useForm";

const breadcrumbItems = [
  { id:'1', title: 'Catálogos', link: '', active: false },
  { id:'2', title: 'Productos', link: '/catalogs/products', active: false },
  { id:'3', title: 'Crear', link: '', active: true }
];

const CatalogProductFormCreate = (props) => {
  const cancelToken = useRef(null);
  const productsCancelToken = useRef(null);

  const satUnitsReq = useRequest()
  const satProductsReq = useRequest()

  const [model, setModel] = useState (props.product)
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')

  const [satUnits, setSatUnits] = useState([])
  const [satProducts, setSatProducts] = useState([])
  
  const {form, handleSpecificInput} = useForm({unidad_sat: '', codigo_sat: ''})

  // Table Data
  let [color, setColor] = useState("#f46a6a");
  const [loading, setLoading] = useState (false);
  const [unidades, setUnidades] = useState ([]);
  const [proveedores, setProveedores] = useState ([]);

  useEffect(() => {
    async function getData()  {
      setLoading(true);
      const response = await getUnits();
      setLoading(false);
      setUnidades(response.map( e => { return {label: e.name, value: e.id} } ));
    }

    getData();
  }, []);

  const fetchSatUnits = useCallback(
    async (search = '') => {
      if (cancelToken.current) {
        cancelToken.current.cancel();
      }

      cancelToken.current = axios.CancelToken.source();

      const config = {
        params: {
          search
        },
        cancelToken: cancelToken.current.token
      }
      const response = await satUnitsReq.req(getSatUnits(config));
      if(!response) return;
      setSatUnits(response)
    },
    [],
  )
  
  const fetchSatProducts = useCallback(
    async (search = '') => {
      if (productsCancelToken.current) {
        productsCancelToken.current.cancel();
      }

      productsCancelToken.current = axios.CancelToken.source();

      const config = {
        params: {
          search
        },
        cancelToken: productsCancelToken.current.token
      }
      const response = await satProductsReq.req(getSatProducts(config));
      if(!response) return;
      setSatProducts(response)
    },
    [],
  )
  
  useEffect(() => {
    fetchSatUnits()
    fetchSatProducts()
  }, [fetchSatUnits, fetchSatProducts]);

  useEffect(() => {
    async function getData()  {
      setLoading(true);
      const response = await getProviders();
      setLoading(false);
      setProveedores(response.map( e => { return {label: e.name, value: e.id} } ));
    }

    getData();
  }, []);

  const handleValidSubmit = async (event, values) => {
    setBtnSaveLoad(true)
    try {
      const response = await postProduct({...values, unit_id: selectedUnit, ...form})
      setModel(response)
      setError(null)
      setCompleted('Se completo la operación')
      console.log(refForm);
      refForm && refForm.current.reset();
    } catch(error) {
      console.log(error)
      console.log(error.toString())
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const handleSelectChangeUnit = (option) => {
    setSelectedUnit(option.value)
  }

  console.log({ form })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de Productos</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Productos"  items={breadcrumbItems}/>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? <Alert color="success">{completed}</Alert> : null}
                      
                    <div className="mb-3">
                      <AvField
                          name="skey"
                          label="Clave"
                          value=""
                          type="text"
                          maxlength={10}
                          errorMessage="Campo requerido"
                          placeholder=""
                        />
                    </div>
                    <div className="mb-3">
                      <AvField
                          name="name"
                          label="Nombre"
                          value=""
                          type="text"
                          required
                          errorMessage="Campo requerido"
                          placeholder=""
                        />
                    </div>
                    <div className="mb-3">
                      <AvField
                          name="description"
                          label="Descripción"
                          value=""
                          type="text"
                          required
                          errorMessage="Campo requerido"
                          placeholder=""
                        />
                    </div>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Unidades</Label>
                        <Select
                          id="formrow-InputState"
                          options={unidades}
                          onChange={handleSelectChangeUnit}
                        />
                      </div>
                    </Row>
                    <div className="mb-3">
                      <AvField
                          name="position"
                          label="Orden"
                          value=""
                          type="text"
                          errorMessage="Campo requerido"
                          placeholder=""
                        />
                    </div>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Unidad SAT</Label>
                        <Select
                          id="formrow-InputState"
                          options={satUnits}
                          getOptionValue={option => option.id}
                          getOptionLabel={option => `${option.code} - ${option.name} ${option.symbol ? `(${option.symbol})` : ''}`}
                          onInputChange={search => {
                            if(!search) return;
                            fetchSatUnits(search)
                          }}
                          isLoading={satUnitsReq.isLoading}
                          onChange={option => {
                            handleSpecificInput({key: 'unidad_sat', value: option.code})
                          }}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Código SAT</Label>
                        <Select
                          id="formrow-InputState"
                          options={satProducts}
                          getOptionValue={option => option.id}
                          getOptionLabel={option => `${option.code} - ${option.description}`}
                          onInputChange={search => {
                            if(!search) return;
                            fetchSatProducts(search)
                          }}
                          isLoading={satUnitsReq.isLoading}
                          onChange={option => handleSpecificInput({key: 'codigo_sat', value: option.code})}
                        />
                      </div>
                    </Row>
                    <input name="unit_id" type="hidden" /> 
                    <div className="text-center">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> Guardar
                      </button>
                      <Link to="/catalogs/products" className="btn w-md btn-sm btn-danger ms-2">
                        <i className="fas fa-reply"></i> Atrás
                      </Link>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

CatalogProductFormCreate.propTypes = {
  product: PropTypes.object
};

export default CatalogProductFormCreate
