import PropTypes from 'prop-types'
import React, { useEffect } from "react"

import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import { connect, useDispatch } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes, landingRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

import { loginSuccess } from 'store/actions'
import { getAccountingAcounts } from 'store/accounting-accounts/actions'


const authUser = localStorage.getItem("authUser")

const App = props => {

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  const dispatch = useDispatch();

  useEffect(() => {
    if (!authUser) return;
    const { user } = JSON.parse(authUser);
    dispatch(loginSuccess(user));
  }, []);

  /* useEffect(() => {
    dispatch(getAccountingAcounts());
  }, []); */

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          {landingRoutes.map(({ path, component: Component }, idx) => (
            <Route
              key={idx}
              path={path}
              component={Component}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
