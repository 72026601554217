import { takeEvery, put, call, select } from "redux-saga/effects"

import {
  GET_INVOICE_TYPE,
  GET_OBJECT_IMP,
  GET_PAYMENT_FORM,
  GET_PAYMENT_METHOD,
  GET_TAX_REGIME,
  GET_USE_CFDI,
} from "./actionTypes"

import {
  getInvoiceTypeFinished,
  getInvoiceTypeSuccess,
  getObjetoImpFinished,
  getObjetoImpSuccess,
  getPaymentFormFinished,
  getPaymentFormSuccess,
  getPaymentMethodFinished,
  getPaymentMethodSuccess,
  getTaxRegimeFinished,
  getTaxRegimeSuccess,
  getUseCfdiFinished,
  getUseCfdiSuccess,
} from "./actions"

import {
  getRegimenesFiscales,
  getSatInvoiceType,
  getSatObjectoImp,
  getSatPaymentForm,
  getSatPaymentMethod,
  getSatUseCfdi,
} from "helpers/backend_helper"

function* onGetObjetoImpuesto() {
  try {
    const {
      Sat: { objeto_imp },
    } = yield select()

    if (objeto_imp.data.length) return

    const response = yield call(getSatObjectoImp)
    yield put(getObjetoImpSuccess(response))
  } catch (error) {
    console.log("error: getObjetoImp", error)
  } finally {
    yield put(getObjetoImpFinished())
  }
}

function* onGetTaxRegime() {
  try {
    const {
      Sat: { tax_regime },
    } = yield select()

    if (tax_regime.data.length) return

    const response = yield call(getRegimenesFiscales)
    yield put(getTaxRegimeSuccess(response))
  } catch (error) {
    console.log("error: getTaxRegime", error)
  } finally {
    yield put(getTaxRegimeFinished())
  }
}

function* onGetUseCfdi() {
  try {
    const {
      Sat: { use_cfdi },
    } = yield select()

    if (use_cfdi.data.length) return

    const response = yield call(getSatUseCfdi)
    yield put(getUseCfdiSuccess(response))
  } catch (error) {
    console.log("error: getTaxRegime", error)
  } finally {
    yield put(getUseCfdiFinished())
  }
}

function* onGetInvoiceType() {
  try {
    const {
      Sat: { invoice_type },
    } = yield select()

    if (invoice_type.data.length) return

    const response = yield call(getSatInvoiceType)
    yield put(getInvoiceTypeSuccess(response))
  } catch (error) {
    console.log("error: getSatInvoiceType", error)
  } finally {
    yield put(getInvoiceTypeFinished())
  }
}

function* onGetPaymentForm() {
  try {
    const {
      Sat: { payment_form },
    } = yield select()

    if (payment_form.data.length) return

    const response = yield call(getSatPaymentForm)
    yield put(getPaymentFormSuccess(response))
  } catch (error) {
    console.log("error: getSatPaymentForm", error)
  } finally {
    yield put(getPaymentFormFinished())
  }
}

function* onGetPaymentMethod() {
  try {
    const {
      Sat: { payment_method },
    } = yield select()

    if (payment_method.data.length) return

    const response = yield call(getSatPaymentMethod)
    yield put(getPaymentMethodSuccess(response))
  } catch (error) {
    console.log("error: getSatPaymentMethod", error)
  } finally {
    yield put(getPaymentMethodFinished())
  }
}

function* satSaga() {
  yield takeEvery(GET_OBJECT_IMP, onGetObjetoImpuesto)
  yield takeEvery(GET_TAX_REGIME, onGetTaxRegime)
  yield takeEvery(GET_USE_CFDI, onGetUseCfdi)
  yield takeEvery(GET_INVOICE_TYPE, onGetInvoiceType)
  yield takeEvery(GET_PAYMENT_FORM, onGetPaymentForm)
  yield takeEvery(GET_PAYMENT_METHOD, onGetPaymentMethod)
}

export default satSaga
