import { GET_ORDER, GET_ORDER_SUCCESS, GET_ORDER_FAILED, ADD_COMMENT_ORDER } from "./actionTypes";

export const getOrder = id => ({
    type: GET_ORDER,
    payload: id
});

export const getOrderSuccess = order => ({
    type: GET_ORDER_SUCCESS,
    payload: order
});

export const getOrderFailed = error => ({
    type: GET_ORDER_FAILED,
    payload: error
});

export const addCommentOrder = comment => ({
    type: ADD_COMMENT_ORDER,
    payload: comment
});