import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Alert,
  Spinner,
} from "reactstrap"

import Moment from 'react-moment';
import 'moment/locale/es';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getClientDailyAvailable } from 'helpers/backend_helper';
import "flatpickr/dist/themes/material_red.css";
import ClientOrderRow from "components/Common/ClientOrderRow";
import getStorageUser from "helpers/api_helper";
import Timer from "components/Common/Timer";
import Select from 'react-select'
import DistribuitorClientOrderRow from "components/Common/DistribuitorClientOrderRow";

const DailyFormCreate = () => {
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState({label:'', value:''})
  const [deliveryAddress, setDeliveryAddress] = useState(null)

  // Tiempo restante
  const [time, setTime] = useState(0)
  const [state, setState] = useState({})
  const [dates, setDates] = useState([])
  const [selectedDate, setSelectedDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [rows1, setrows1] = useState([])
  const [disableForm, setDisableForm] = useState(false)
  const [addresses, setAddresses] = useState ([])

  async function getData()  {
    setLoading(true);
    const response = await getClientDailyAvailable();
    setLoading(false);
    console.log('rows', response.data)
    setTime(response.time * 60);
    setrows1(response.data);
    setDates(response.days)
    if (addresses.length == 0) {
      setAddresses(response.enterprise.deliveries_address);
    }
    if (!selectedDeliveryAddress.label) {
      setSelectedDeliveryAddress({label: response.enterprise.deliveries_address[0].address_string, value: response.enterprise.deliveries_address[0].id})
      setDeliveryAddress( response.enterprise.deliveries_address[0] )
    }
    if (!selectedDate) {
      setSelectedDate(response.days[0])
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const handleCompletePedido = async (pedido) => {
    /*
    async function getData()  {
      setLoading(true);
      const response = await getClientDailyAvailable();
      setLoading(false);
      console.log(response)
      setrows1(response);
    }
    */
    getData();
  }

  const handleDateSelected = (option) => {
    setSelectedDate(option.value)
  }

  const handleDeliverySelected = (option) => {
    setSelectedDeliveryAddress(option)
    setDeliveryAddress( addresses.find( e => { return e.id == option.value }) )
  }

  const hasProduct = (state) => {
    let availableProvider = state.map( (provider) => {
      let available = provider.rows.map( (formDaily) => {
        return formDaily.available_product;
      })
      return available.reduce((a, b) => a + b, 0)
    })
    return availableProvider.reduce((a, b) => a + b, 0) > 0
  }

  const hasPedidos = (state) => {
    let availableProvider = state.map( (provider) => {
      return provider.orders.length
    })
    return availableProvider.reduce((a, b) => a + b, 0) > 0
  }

  console.log('deliveryAddress',deliveryAddress)

  return (
    <React.Fragment>
    { (time==0 || disableForm) &&
      <div className="page-content">
        <Alert color="warning">Fuera de tiempo para realizar pedidos</Alert>
      </div>
    }

    { (time > 0 && !disableForm) &&
      <div className="page-content">
        <MetaTags>
          <title>
            Realizar Pedido
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Cliente" breadcrumbItem="Realizar Pedido" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Pedido
                    { !disableForm &&
                      <Timer timeLeft={time} callback={ setDisableForm } />
                    }
                  </CardTitle>
                  <Row>
                    <Col lg={4} className="text-end">
                      <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                        Fecha
                      </label>
                    </Col>
                    <Col lg={6} className="mb-3 text-center">
                      <Select
                          className="text-start"
                          id="formrow2-InputState"
                          value={{label: selectedDate, value: selectedDate}}
                          options={ dates.map( e => { return {label: e, value: e} } ) }
                          defaultInputValue={selectedDate}
                          onChange={handleDateSelected}
                          placeholder="- Seleccionar fecha -"
                        />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className="text-end">
                      <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                        Dirección de entrega
                      </label>
                    </Col>
                    <Col lg={6} className="mb-3 text-center">
                      <Select
                          className="text-start"
                          id="formrow2-InputState"
                          value={{label: selectedDeliveryAddress.label, value: selectedDeliveryAddress.value}}
                          options={ addresses.map( e => { return {label: e.address_string, value: e.id} } ) }
                          defaultInputValue={selectedDate}
                          onChange={handleDeliverySelected}
                          placeholder="- Seleccionar dirección -"
                        />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className="text-end">
                      <label htmlFor="" style={{minHeight:'28px', paddingTop:'10px'}}>
                        Región de entrega
                      </label>
                    </Col>
                    <Col lg={6} className="mb-3 text-center">
                      <div style={{minHeight:'28px', paddingTop:'10px'}}>
                        { deliveryAddress?.region?.name }
                      </div>
                    </Col>
                  </Row>

                  {loading && <div className="text-center"><Spinner animation="border" variant="danger" /></div>}
                  <div>
                    { (rows1.length == 0) &&
                      <div className="page-content">
                        <Alert color="warning">No hay disponibilidad de proveedores para su zona.</Alert>
                      </div>
                    }
                    {
                      rows1.length > 0 && !hasProduct(rows1) && !hasPedidos(rows1) &&
                      <div className="page-content">
                        <Alert color="warning">No hay disponibilidad de producto.</Alert>
                      </div>
                    }
                    {selectedDeliveryAddress.value && selectedDate && (rows1).map((formRow, key) => (
                      <DistribuitorClientOrderRow 
                        id={'provider' + key}
                        key={key}
                        rows={formRow.rows.filter( e => { return e.date == selectedDate && e.prices.filter( (f) => { return f.region_id == deliveryAddress?.region_id}).length > 0 ; } )}
                        order={formRow.orders.find( e => { return e.order_date == selectedDate && e.address_id == deliveryAddress?.id }) }
                        date={selectedDate}
                        address={deliveryAddress?.id}
                        region={deliveryAddress?.region_id}
                        onSubmit={handleCompletePedido}
                      />
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    }
    </React.Fragment>
  )
}

export default DailyFormCreate
