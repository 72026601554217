import React, { Fragment, useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Label } from "reactstrap"
import { AvField } from "availity-reactstrap-validation"

import AdrressForm from './AddressForm';
import RangeTime from '../../../components/Common/RangeTime';
import { getRegimenesFiscales, getSatUseCfdi } from "helpers/backend_helper"

const ClientForm = props => {
  const initState = {
    company_name: '',
    skey: '',
    contact_name: '',
    phone: '',
    phone2: '',
    local_delivery: false,
    region_id: null,
    regions: null,
    start: '',
    end: '',
    fee_per_unit: '1.00',
    email: ''
  }
  
  const [client, setClient] = useState({...initState, ...props.client})
  const [satUseCfdi, setSatUseCfdi] = useState([])
  const [satRegimenFiscal, setSatRegimenFiscal] = useState([])

  const handleChange = (values) => {
    console.log('update times', values)
    setClient({...client, start: values.start ?? initState.start, end: values.end  ?? initState.end})
    if(props.handleChangeTimes)
      props.handleChangeTimes({start: values.start, end: values.end})
  }

  useEffect(() => {
    getSatUseCfdi().then(setSatUseCfdi)
    getRegimenesFiscales().then(setSatRegimenFiscal)
  }, [])
  

  console.log('client', client)
  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <div className="mb-3">
            <AvField
                name="company_name"
                label="Nombre Comercial o Razón Social"
                value={client.company_name}
                type="text"
                required
                errorMessage="Campo requerido"
                placeholder=""
              />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <AvField
                name="skey"
                label="Clave Interna"
                value={client.skey}
                type="text"
                errorMessage="Campo requerido"
                placeholder=""
              />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <div className="mb-3">
            <AvField
                name="contact_name"
                label="Nombre Completo Persona Contacto"
                value={client.contact_name}
                type="text"
                required
                errorMessage="Campo requerido"
                placeholder=""
              />
          </div>
        </Col>

      <Col md={4}>
          <div className="mb-3">
            <AvField
                name="fee_per_unit"
                label="Comisión por unidad ($)"
                value={client.fee_per_unit}
                type="number"
                required
                min="0"
                errorMessage="Campo requerido"
                placeholder="$"
              />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <div className="mb-3">
            <AvField
                name="rfc"
                label="RFC"
                value={client.rfc}
                type="text"
                required
                errorMessage="Campo requerido"
                placeholder=""
                validate={{
                  minLength: {value: 12, errorMessage: 'El RFC debe contener 12 o 13 caracteres'},
                  maxLength: {value: 13, errorMessage: 'El RFC debe contener 12 o 13 caracteres'}
                }}
              />
          </div>
        </Col>
        <Col md={4}>
          <AvField value={client.tax_regime} type="select" label="Régimen fiscal" name="tax_regime" helpMessage="">
            <option value="">Seleccionar...</option>
            {satRegimenFiscal.map(item => (
              <option key={item.id} value={item.code}>{item.code} - {item.description}</option>
            ))}
          </AvField>
        </Col>
        <Col md={4}>
          <AvField value={client.use_cfdi} type="select" label="Uso CFDI" name="use_cfdi" helpMessage="">
            <option value="">Seleccionar...</option>
            {satUseCfdi.map(item => (
              <option key={item.id} value={item.code}>{item.code} - {item.description}</option>
            ))}
          </AvField>
        </Col>
      </Row>
      
      <Row>
        <Col md={4}>
          <div className="mb-3">
            <AvField
                name="phone"
                label="Teléfono"
                value={client.phone}
                type="text"
                required
                errorMessage="Campo requerido"
                placeholder=""
              />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <AvField
                name="phone2"
                label="Teléfono 2"
                value={client.phone2}
                type="text"
                errorMessage="Campo requerido"
                placeholder=""
              />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <AvField
                name="email"
                label="Correo Electrónico"
                value={client.email}
                type="email"
                required
                errorMessage="Campo requerido"
                placeholder=""
              />
          </div>
        </Col>
      </Row>

      <AdrressForm entityAddress={client?.invoice_address ? client.invoice_address : {}} />

      <Row className="mb-3">
        <RangeTime
            id="horario"
            label="Pedidos cliente"
            startTime={props.times.start}
            endTime={props.times.end}
            onChange={handleChange}
          />                    
      </Row>
    </Fragment>
  )
}

ClientForm.propTypes = {
  client: PropTypes.object.isRequired,
  handleChangeTimes: PropTypes.func.isRequired,
  setModel: PropTypes.func,
  times: PropTypes.object.isRequired
}

export default ClientForm
