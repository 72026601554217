import React, { useEffect, useState, useRef, } from "react"
import MetaTags from 'react-meta-tags';
import Select from 'react-select';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from 'react-router-dom';

import { AvField, AvForm } from "availity-reactstrap-validation";
import { getEnterprises, getProducts, postUser, putUser } from "helpers/backend_helper";
import { PropTypes } from 'prop-types';
import _ from "lodash";
import { putAccount } from '../../../helpers/backend_helper';
import BreadcrumbNav from "components/Common/BreadcrumbNav";
import { ACCOUNT_TYPES } from "./form-create";

const breadcrumbItems = [
  { id: '1', title: 'Administrativo', link: '', active: false },
  { id: '2', title: 'Cuentas Contables', link: '/administrative/accountings', active: false },
  { id: '3', title: 'Editar', link: '', active: true }
];

const CatalogAccountingFormEdit = (props) => {
  const [model, setModel] = useState(props.location.state)
  const [selectedTipo, setSelectedTipo] = useState(model.type);
  const [selectedRelated, setSelectedRelated] = useState(model.related ? model.related.id : null);
  const [selectedProduct, setSelectedProduct] = useState(model?.product ? model.product.id : null);

  const [user, setUser] = useState(props.user)
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)
  const refForm = useRef('form-user-create')

  const [products, setProducts] = useState([]);
  const [enterprises, setEnterprises] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await getEnterprises();
      setEnterprises(response.map(e => { return { type: e.type == '2' ? 'provider' : (e.type == '3' ? 'client' : ''), label: e.clave ? (e.clave + ' ' + e.company_name) : e.company_name, value: e.id } }));
    }

    getData();
  }, []);

  const validateType = () => {
    if (selectedTipo) return true;
    throw new Error('Debe completar el campo de proveedor');
  }

  const handleSelectChangeTipo = (option) => {
    setSelectedTipo(option.value)
  }

  const handleSelectRelated = (option) => {
    setSelectedRelated(option.value)
  }

  const handleValidSubmit = async (event, values) => {
    setModel({ ...model, ...values })
    setBtnSaveLoad(true)
    try {
      validateType()
      const response = await putAccount({
        id: model.id,
        ...values,
        type: selectedTipo,
        related_id: selectedRelated,
        product_id: selectedProduct
      })
      setModel(response)
      setError(null)
      setCompleted('Se completo la operación')

    } catch (error) {

      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const getOptionByValue = (value) => {
    const obj = _.find(ACCOUNT_TYPES, { value: value })
    return obj ? obj.label : ''
  }

  useEffect(() => {
    getProducts().then(setProducts)
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de Usuarios</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Cuentas Contables" items={breadcrumbItems} />
          <Row>
            <Col lg={12} style={{ minHeight: '600px' }}>
              <Card>
                <CardBody>
                  <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? <Alert color="success">{completed}</Alert> : null}

                    <div className="mb-3">
                      <AvField
                        name="name"
                        label="Nombre"
                        value={model.name}
                        type="text"
                        required
                        errorMessage="Campo requerido"
                        placeholder=""
                      />
                    </div>

                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Tipo {selectedTipo}</Label>
                        <Select
                          id="formrow-InputState"
                          options={ACCOUNT_TYPES}
                          onChange={handleSelectChangeTipo}
                          defaultValue={{ value: selectedTipo, label: getOptionByValue(selectedTipo) }}
                        />
                      </div>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            name="balance_initial"
                            label="Saldo Inicial"
                            value={model.balance_initial}
                            type="number"
                            required
                            errorMessage="Campo requerido"
                            placeholder=""
                          />
                        </div>
                      </Col>
                      {
                        selectedTipo == 'client' || selectedTipo == 'provider' ?
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">Proveedor/Cliente Relacionado</Label>
                              <Select
                                id="formrow-InputRelated"
                                options={_.filter(enterprises, { type: selectedTipo })}
                                onChange={handleSelectRelated}
                                defaultValue={{ label: model.related?.company_name, value: selectedRelated }}
                              />
                            </div>
                          </Col> :
                          ''
                      }
                      {selectedTipo == 'product' ?
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">Producto</Label>
                            <Select
                              id="formrow-InputState"
                              value={products.find(item => item.id === selectedProduct)}
                              options={products}
                              onChange={event => setSelectedProduct(event?.id)}
                              getOptionLabel={option => option.name}
                              getOptionValue={option => option.id}
                            />
                          </div> </Col> : null
                      }
                    </Row>

                    <div className="text-center">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={btnSaveLoad}>
                        <i className="fas fa-save"></i> Guardar
                      </button>
                      <Link to="/administrative/accountings" className="btn w-md btn-sm btn-danger ms-2">
                        <i className="fas fa-reply"></i> Atrás
                      </Link>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

CatalogAccountingFormEdit.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object.isRequired,
  })
};

export default CatalogAccountingFormEdit
