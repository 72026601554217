import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Table
} from 'reactstrap';

import TableMovementItem from './TableMovementItem';
import Header from './TableHeader';
import TableTotal from './TableTotal';

export default function TableReport({ data, totals, setColumnOrder, columnOrder, period, configs }) {
    const [ options, setOptions ] = useState(['BANCOMER','SANTANDER','HSBC','PIL_MONTANO', 'TARJETA',   'EFECTIVO']);
    console.log(data)
    return (
        <div className="table-responsive" style={{position: 'relative', maxHeight: '500px'}}>
            <Table className="table mb-0 report-table table-bordered table-sm administrative-table">
                <thead className='table-light'>
                    <TableTotal totals={ totals } configs={configs}/>
                    <Header setColumnOrder={ setColumnOrder }  columnOrder={columnOrder} options={options} configs={configs} />
                </thead>
                <tbody>
                    { data.map(item => (
                        <TableMovementItem period={period} key={ item.id } item={ item } options={options} configs={configs} />
                    )) }
                </tbody>
            </Table>
        </div>
    );
}

TableReport.propTypes = {
    data: PropTypes.any,
    totals: PropTypes.object,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object,
    period: PropTypes.any,
    configs: PropTypes.array,
};