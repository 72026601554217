import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Alert,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap"
import toastr from "toastr"
import * as _ from "lodash"

import useInvoice from "common/invoice/hooks/useInvoice"
import GeneralData from "common/invoice/components/GeneralData"
import Issuer from "common/invoice/components/Issuer"
import Client from "common/invoice/components/Client"
import Products from "common/invoice/components/Products"
import ProductsTotals from "common/invoice/components/ProductsTotal"
import ProductModal from "common/invoice/product-modal/ProductModal"
import FormActions from "common/invoice/components/FormActions"
import InvoiceDownloadButtons from "common/invoice/components/InvoiceDownloadButtons"
import { getConfigFindOne } from "helpers/backend_helper"
import { initProduct } from "common/invoice/product-modal/static"
import { tax_configuration } from "common/sat/Taxes"
import { getTaxesCalculated } from "helpers/sat"
import GlobalInformation from "common/invoice/components/GlobalInformation"

const globalConfig = "BILLING_CONFIGURATION_GLOBAL_INVOICE"

const GlobalInvoiceSidebar = ({
  isOpenSidebar,
  toggleSidebar,
  reloadData,
  movsClientSelected,
}) => {
  const { validation, state, methods } = useInvoice()

  const { productRow, cfdiRequest, isOpenProductModal } = state

  const {
    toggleProductModal,
    handleDeleteProduct,
    handleNewProductModal,
    handleEditProductModal,
    onClickGlobalReceiver,
  } = methods

  const fillGlobalReceiver = () => {
    onClickGlobalReceiver()
  }

  const afterStampingInvoice = () => {
    if (state.cfdiRequest.isSubmitted) {
      reloadData()
    }
  }

  const fetchGlobalConfig = () => {
    const config = {
      params: {
        name: globalConfig,
      },
    }

    getConfigFindOne(config).then(config => {
      if (!config) {
        toastr.warning("No se encontró la configuración de factura global")
        return
      }

      parseGlobalConfigToProduct(config)
    })
  }

  const parseGlobalConfigToProduct = config => {
    const movsClientIds = _.map(movsClientSelected, "id")
    const totalMovsClientSelected = _.sumBy(movsClientSelected, ({ total }) =>
      Number(total)
    )

    const conceptConfig = JSON.parse(config.value)
    const taxConfiguration =
      conceptConfig?.tax_configuration || tax_configuration

    const { Total, Taxes } = getTaxesCalculated(
      totalMovsClientSelected,
      0,
      taxConfiguration
    )

    const product = {
      ...initProduct,
      Id: 1,

      Description: conceptConfig?.Description || "",
      ProductCode: conceptConfig?.ProductCode || "",
      TaxObject: conceptConfig?.TaxObject || "",
      UnitCode: conceptConfig?.UnitCode || "",
      Quantity: 1,
      UnitPrice: totalMovsClientSelected,
      Subtotal: totalMovsClientSelected,
      Total,
      Taxes,
      IsEditable: true,
      tax_configuration: taxConfiguration,
    }

    validation.setFieldValue("movs_client_ids", movsClientIds)
    validation.setFieldValue("Items", [product])
  }

  useEffect(fillGlobalReceiver, [])
  useEffect(fetchGlobalConfig, [])
  useEffect(afterStampingInvoice, [state.cfdiRequest.isSubmitted])

  return (
    <>
      <Offcanvas
        style={{ width: "600px" }}
        isOpen={isOpenSidebar}
        direction="end"
        toggle={toggleSidebar}
      >
        <OffcanvasHeader
          toggle={toggleSidebar}
          className="border-bottom border-danger"
        >
          Nueva factura global
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            {cfdiRequest.isError ? (
              <Alert color="danger">{cfdiRequest.msgError}</Alert>
            ) : null}
            <GeneralData validation={validation} disableCfdiType={true} />
            <Issuer validation={validation} />
            <Client validation={validation} disableUseCfdi />
            <GlobalInformation validation={validation} />
            <Products
              validation={validation}
              handleDeleteProduct={handleDeleteProduct}
              handleNewProductModal={handleNewProductModal}
              handleEditProductModal={handleEditProductModal}
            />
            <ProductsTotals validation={validation} />
            {cfdiRequest.data ? (
              <InvoiceDownloadButtons
                stampedInvoice={cfdiRequest.data}
                toggleRightCanvas={toggleSidebar}
              />
            ) : (
              <FormActions
                validation={validation}
                isStampingCfdi={cfdiRequest.isLoading}
                toggleClose={toggleSidebar}
              />
            )}
          </Form>
        </OffcanvasBody>
      </Offcanvas>
      {isOpenProductModal && (
        <ProductModal
          isOpen={isOpenProductModal}
          productRow={productRow}
          toggleModal={toggleProductModal}
          validation={validation}
        />
      )}
    </>
  )
}

GlobalInvoiceSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  reloadData: PropTypes.func,
  movsClientSelected: PropTypes.any,
}

export default GlobalInvoiceSidebar
