import React from 'react';

import { Label, Form, Alert, Spinner } from 'reactstrap';
import useOrderComment from 'hooks/useOrderComment';

export default function CommentForm() {

    const { form, formUi, handleChange, handleSubmit } = useOrderComment();

    return (
        <div className="mt-4">
            {
                formUi.isSend ? (
                        <Alert color="success" role="alert">
                            El comentario ha sido enviado
                        </Alert>
                    ) : null
            }
            {
                formUi.isError ? (
                        <Alert color="danger" role="alert">
                            { formUi.isError }
                        </Alert>
                    ) : null
            }
            <Form onSubmit={ handleSubmit }>
                <div className="mb-3">
                    <textarea
                        onChange={ handleChange }
                        value={ form.comment }
                        name="comment"
                        className="form-control"
                        id="commentmessage-input"
                        placeholder="Tu comentario..."
                        rows="3"
                        required
                    ></textarea>
                </div>

                <div className="text-end">
                    {
                        formUi.isLoading ? (
                                <Spinner 
                                    className="ms-2" 
                                    color="secondary" 
                                />                             
                            ) : (
                                <button
                                    type="submit"
                                    className="btn btn-danger w-sm btn-sm"
                                >
                                <i className="fas fa-comment"></i> Enviar
                                </button> 
                            )
                    }                    
                </div>
            </Form>
        </div>
    )
}