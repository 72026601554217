import React from 'react';

export default function TableCustomerItem() {
    return (
        <>
            <tr className="report-table__col-border--blue">
                <td></td>
                <td colSpan={6} className="f-bold">4 CRESTA</td>
            </tr>
            <tr>
                <td colSpan={2}></td>
                <td className="text-right">0.00</td>
                <td className="text-right">0.00</td>
                <td className="text-right">0.00</td>
                <td className="text-right">61,617.83</td>
                <td className="text-right">61,617.83</td>
            </tr>
            <tr>
                <td colSpan={2}>Transfieren</td>
                <td className="text-right border-b-black">0.00</td>
                <td className="text-right border-b-black">0.00</td>
                <td className="text-right border-b-black">334,314.00</td>
                <td className="text-right border-b-black">0.00</td>
                <td className="text-right border-b-black">334,314.00</td>
            </tr>
            <tr>
                <td></td>
                <td className="f-bold">Totales por procedencia</td>
                <td className="text-right">0.00</td>
                <td className="text-right">0.00</td>
                <td className="text-right">334,314.00</td>
                <td className="text-right">61,617.83</td>
                <td className="text-right border-b-black">395,931.83</td>
            </tr>            
        </>
    );
}