import React, { Component, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Media, Row } from "reactstrap"
import classNames from "classnames"
import StackedColumnChart from "./StackedColumnChart"
import TopCities from "./TopCities"
import { getClientDashboard } from "helpers/backend_helper"
import BreadcrumbNav from '../../../components/Common/BreadcrumbNav';

const DashboardClient = props => {
  const [dashboard, setDashboard] = useState({
    top_products: [],
    graph: {
      pollo: [],
      huevo: [],
      categories: [],
    },
  })
  const [periodType, setPeriodType] = useState("current")

  useEffect(() => {
    const config = {
      params: {
        week: periodType,
      },
    }
    getClientDashboard(config).then(setDashboard)
  }, [periodType])

  const reports = [
    { title: "Ordenes", iconClass: "bx-copy-alt", description: "-" },
    { title: "Proveedores", iconClass: "bx-user", description: "-" },
    {
      title: "Clientes",
      iconClass: "bx-group",
      description: "-",
    },
  ]

  const [periodData, setPeriodData] = useState([
    {
      name: "Pollo",
      data: [44, 55, 41, 67, 22, 43, 36],
    },
    {
      name: "Huevo",
      data: [13, 23, 20, 8, 13, 27, 18],
    },
  ])

  const onChangeChartPeriod = period => {}

  var curr = new Date() // get current date
  var first = curr.getDate() - curr.getDay() + 1 // First day is the day of the month - the day of the week
  var last = first + 6 // last day is the first day + 6

  var firstday = new Date(curr.setDate(first)).toUTCString()
  var lastday = new Date(curr.setDate(last)).toUTCString()

  const breadcrumbItems = [
    { id: "1", title: "Dashboard", link: "", active: true },
  ]

  return (
    <React.Fragment>
      <BreadcrumbNav title="Resumen" items={breadcrumbItems} />
      <Row className="mt-2">
        <Col lg={8}>
          <Card>
            <CardBody>
              <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">Compras por Producto</h4>
                <div className="ms-auto">
                  <ul className="nav nav-pills">
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={`nav-link ${
                          periodType === "last" ? "bg-danger" : ""
                        }`}
                        style={{
                          color: `${periodType === "last" ? "white" : ""}`,
                        }}
                        /* className={classNames(
                              { active: periodType === "last" },
                              "nav-link"
                            )} */
                        onClick={() => {
                          //onChangeChartPeriod("monthly")
                          setPeriodType("last")
                        }}
                        id="one_month"
                      >
                        Semana anterior
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        /* className={classNames(
                              { active: periodType === "current" },
                              "nav-link bg-danger"
                            )} */
                        className={`nav-link ${
                          periodType === "current" ? "bg-danger" : ""
                        }`}
                        style={{
                          color: `${periodType === "current" ? "white" : ""}`,
                        }}
                        onClick={() => {
                          //onChangeChartPeriod("current")
                          setPeriodType("current")
                        }}
                        id="one_month"
                      >
                        Semana actual
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="clearfix"></div> */}
              <StackedColumnChart
                periodData={periodData}
                dashboardData={dashboard}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <TopCities topProducts={dashboard.top_products} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DashboardClient
