import React from "react";
import PropTypes from 'prop-types';
import _ from "lodash";

const numberFormat = new Intl.NumberFormat('es-MX', { minimumFractionDigits: 2 });

const OrderItem = ({ id, truck_name, truck_units, order_items_trucks, status, status_string }) => {
    return (
        <tr key={ id }>
            <td className="text-center">{ truck_name } <br /><span className={status=='pending' ? 'badge rounded-pill bg-warning':'badge rounded-pill bg-success'}>{status_string}</span></td>
            <td>{ parseInt(truck_units) } pzas</td>
            <td>{ _.sumBy(order_items_trucks, (e)  => {
                   return Number(e.quantity);
                }) } pzas
            </td>
            <td>
                {
                    order_items_trucks.map(order_item_truck => (
                        <div key={'a'+order_item_truck.id} className="mb-2">
                            <span className="badge bg-danger ps-1 pe-1 me-1">{order_item_truck.sort}</span>
                            <b>Día {order_item_truck.order_item.delivery_date.substr(8)} | {order_item_truck.order_item.delivered ? order_item_truck.order_item?.delivered_region:''} {order_item_truck.order_item.delivered ? order_item_truck.order_item?.delivered_subregion:''}  | {order_item_truck.quantity}Pzas | {order_item_truck.order_item.order.client.company_name} | ${numberFormat.format(Number(order_item_truck.order_item.total_cost))}</b> <br />
                            <i className="d-none">{order_item_truck.order_item.delivered ? order_item_truck.order_item?.order.address?.address_string:''}</i>
                        </div>                                
                    ))
                }
            </td>
            <td>$ {numberFormat.format(_.sumBy(order_items_trucks, e => { return e.order_item.total_cost;}))}</td>
        </tr>
    )
}

OrderItem.propTypes = {
    id: PropTypes.number,
    truck_name: PropTypes.string,
    truck_units: PropTypes.number,
    order_items_trucks: PropTypes.array,
    status: PropTypes.string,
    status_string: PropTypes.string,
};

export {
    OrderItem
};