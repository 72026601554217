import { useState } from 'react';

export default function useRequest(initData = null) {

    const [ data, setData ] = useState(initData);
    const [ msgError, setMsgError ] = useState('');
    const [ isError, setIsError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSubmitted, setIsSubmitted ] = useState(false);

    const req = promise => {
        init();

        return promise
        .then(resp => {
            setData(resp);
            setIsSubmitted(true);
            return resp;
        })
        .catch(err => {
            setMsgError(err);
            setIsError(true);
            throw err;
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const init = () => {
        setData(initData);
        setMsgError('');
        setIsError(false);
        setIsLoading(true);
        setIsSubmitted(false);
    }

    const reset = () => {
        setData(initData);
        setMsgError('');
        setIsLoading(false);
        setIsSubmitted(false);
    }

    return {
        data,
        reset,
        msgError,
        isError,
        isLoading,
        isSubmitted,
        req
    }
}