import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container, Media, Row,
} from "reactstrap"
import classNames from "classnames"
import StackedColumnChart from "./StackedColumnChart";
import TopCities from "./TopCities";
import { getProviderDashboard } from "helpers/backend_helper";
import BreadcrumbNav from '../../../components/Common/BreadcrumbNav';


const DashboardProvider = props => {

  const [dashboard, setDashboard] = useState({
    top_clients: [],
    graph: {
      pollo: [],
      huevo: [],
      categories: []
    }
  })

  const [periodType, setPeriodType] = useState('current')

  const reports = [
    { title: "Ordenes", iconClass: "bx-copy-alt", description: "-" },
    { title: "Proveedores", iconClass: "bx-user", description: "-" },
    {
      title: "Clientes",
      iconClass: "bx-group",
      description: "-",
    },
  ]

  const [periodData, setPeriodData] = useState([
    {
        name: "Pollo",
        data: [44, 55, 41, 67, 22, 43, 36]
    },
    {
        name: "Huevo",
        data: [13, 23, 20, 8, 13, 27, 18]
    }
  ])
  useEffect(() => {
    const config = {
      params: {
        week: periodType
      }
    }
    getProviderDashboard(config).then(setDashboard)
  }, [periodType]);

  //  const [periodType, setPeriodType] = useState("yearly")

  const onChangeChartPeriod = (period) => {}

  const breadcrumbItems = [
    { id:'1', title: 'Dashboard', link: '', active: true },
  ];

  return (
    <React.Fragment>
        <BreadcrumbNav title="Resumen"  items={breadcrumbItems}/>
        <Row className="mt-5"> 
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Pedidos</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                      <li className="nav-item">
                          <Link
                            to="#"
                            className={`nav-link ${periodType === "last" ? 'bg-danger' : ''}`}
                            style={{
                              color: `${periodType === "last" ? 'white' : ''}`
                            }}
                            /* className={classNames(
                              { active: periodType === "last" },
                              "nav-link"
                            )} */
                            onClick={() => {
                              //onChangeChartPeriod("monthly")
                              setPeriodType('last')
                            }}
                            id="one_month"
                          >
                            Semana pasada
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            /* className={classNames(
                              { active: periodType === "current" },
                              "nav-link bg-danger"
                            )} */
                            className={`nav-link ${periodType === "current" ? 'bg-danger' : ''}`}
                            style={{
                              color: `${periodType === "current" ? 'white' : ''}`
                            }}
                            onClick={() => {
                              //onChangeChartPeriod("current")
                              setPeriodType('current')
                            }}
                            id="one_month"
                          >
                            Semana actual
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart periodData={periodData} dashboardData={ dashboard } />
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <TopCities topClients={ dashboard.top_clients } />
            </Col>
          </Row>
    </React.Fragment>
  );
}

export default DashboardProvider;
