import React from 'react';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import moment from 'moment';

import { 
    Col, 
    FormGroup, 
    InputGroup, 
    Label, 
    Row 
} from 'reactstrap';

const initialPeriod = moment().startOf('month').format('YYYY-MM-DD') + '/' + moment().endOf('month').format('YYYY-MM-DD');

export default function Filters() {

    const handleDatePicker = () => {

    }

    return (
        <Row>
            <Col lg={3}>
                <FormGroup className="mb-4">
                    <Label>Periodo</Label>
                    <InputGroup>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder=""
                        onChange={ handleDatePicker }
                        options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            locale: Spanish,
                            defaultDate: initialPeriod.split('/')
                        }}
                    />
                    </InputGroup>
                </FormGroup>
            </Col>
        </Row>
    );
}