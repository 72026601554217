import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"

const ProfileMenu = props => {

  const [menu, setMenu] = useState(false);
  const { name, email } = useSelector(state => state.Login.user);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-xl-inline-block ms-2 me-1">{ name }</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="ms-2 me-1 text-center"><small>{ email }</small></div>
          <div className="dropdown-divider"/>
          <Link to="/user/profile" className="dropdown-item">
            <i className="bx bx bx-user font-size-16 align-middle me-1 text-dark"/>
            <span>{props.t("Perfil")}</span>
          </Link>
          <Link to="/enterprise/detail" className="dropdown-item">
            <i className="bx bx bx-flag font-size-16 align-middle me-1 text-dark"/>
            <span>{props.t("Datos fiscales")}</span>
          </Link>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>{props.t("Salir")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)