import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import { PropTypes } from 'prop-types';
import * as _ from 'lodash';

//Import Breadcrumb
import { Link } from 'react-router-dom';
import { useState } from "react";
import { useRef } from "react";
import { getProviderDetail, getRegions, putProviderBranches, validateZipCode } from "helpers/backend_helper";
import { Alert } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import FarmForm from "../common/FarmForm";
import ProviderForm from "../common/ProviderForm";
import BreadcrumbNav from "components/Common/BreadcrumbNav";

import toastr from "toastr"
import getStorageUser from '../../../helpers/api_helper';
import ClipLoader from "react-spinners/ClipLoader";

const breadcrumbItems = [
  { id:'1', title: 'Proveedor', link: '', active: false },
  { id:'2', title: 'Branches', link: '/provider/branches', active: false }
]

const user = getStorageUser();

const ProviderBranches = (props) => {
  const [color, setColor] = useState("#f46a6a");
  const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false)
  const [farms, setFarms] = useState([])
  const [curFarm, setCurFarm] = useState({})
  const [model, setModel] = useState ({})
  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')
  const [loading, setLoading] = useState (false);
  const [regions, setRegions] = useState ([]);

  useEffect(() => {
    async function getData()  {
      setLoading(true);
      const response = await getRegions();
      setRegions(response);
    }

    getData();
  }, []);

  useEffect(() => {
    async function getProviderData()  {
      setLoading(true);
      const response = await getProviderDetail(user.enterprise_id);
      setLoading(false);
      setModel(response);
      response.farms.forEach(e => {
        e.regions.forEach( f => {
          f.label = f.name;
        });
      });
      setFarms(response.farms);
    }

    getProviderData();
  }, []);

  const handleValidSubmit = async (event, values) => {
    try {      
      values['farms'] = farms
      setBtnSaveLoad(true)
      const response = await putProviderBranches({...values})
      setModel(response)
      response.farms.forEach(e => {
        e.regions.forEach( f => {
          f.label = f.name;
        });
      })
      setFarms(response.farms)
      setError(null)
      setCompleted('Se completo la operación')
    } catch(error) {
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const addFarm = (indexFarm, edit = false) => {
    const farm = indexFarm != null  ? farms[indexFarm] : {}
    farm.edit = edit
    farm.indexFarm = indexFarm
    setCurFarm(farm)
    setOffCanvasIsOpen(true)
  }

  const delFarm = (indexFarm) => {
    const newFarms = [...farms]
    newFarms.splice(indexFarm, 1)
    setFarms(newFarms)
  }

  const toggleRightCanvas = () => {
    setOffCanvasIsOpen(!offCanvasIsOpen)
  }

  const onCompleteAddFarm = (farm) => {
    if ('edit' in farm && farm.edit) {
      farms[farm.indexFarm] = farm
      setFarms([...farms])
    } else{
      setFarms([...farms, farm])
    }
    toggleRightCanvas()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Sucursales</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Sucursales"  items={breadcrumbItems}/>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                { loading ? <ClipLoader color={color} loading={loading} size={30} /> : 
                  <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? <Alert color="success">{completed}</Alert> : null}
                    <table className="table table-responsive table-bordered table-sm">
                      <thead>
                      <tr>
                          <th colSpan={5} className="text-center">
                            Sucursales
                          </th>
                        </tr>
                        <tr>
                          <th>Sucursal</th>
                          <th>Entrega en sucursal</th>
                          <th>Región de sucursal</th>
                          <th>Zonas de cobertura</th>
                          <th>Productos</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                      {( farms ).map((farm, key) => (
                        <tr key={key}>
                          <td>{farm.name}</td>
                          <td>{farm.local_delivery ? 'Sí':'No'}</td>
                          <td>{farm.region ? farm.region.name:''}</td>
                          <td>{ farm.regions ? farm.regions.reduce( (prev, cur) => { return prev + cur.label + ' - ' }, '' ) : '' }</td>
                          <td>
                            {farm.products.map(prod => prod.name).join(', ')}
                          </td>
                          <td>
                            <Button type="button" className="btn btn-warning btn-sm" onClick={ () => addFarm(key, true) }>
                              <i className="fas fa-edit"></i>
                            </Button> <Button type="button" className="btn btn-danger btn-sm" onClick={ () => delFarm(key) }>
                              <i className="fas fa-trash"></i>
                            </Button> 
                          </td>
                        </tr>
                      ))}
                      { farms.length == 0 && (
                        <tr>
                          <td colSpan={6} className="text-center">Sin sucursales</td>
                        </tr>
                      )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={6} className="text-center">
                            <Button type="button" className="btn btn-danger btn-sm" onClick={ () => addFarm() }>
                              <i className="fas fa-plus"></i> Agregar sucursal
                            </Button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    

                    <div className="text-center">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> { 'Guardar' }
                      </button>
                    </div>
                  </AvForm>
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Offcanvas
            isOpen={ offCanvasIsOpen }
            direction="end"
            toggle={ toggleRightCanvas }
        >                
          <OffcanvasHeader toggle={ toggleRightCanvas }>
            { 'edit' in curFarm && curFarm.edit ? 'Modificar sucursal' : 'Nueva sucursal' }
          </OffcanvasHeader>
          <OffcanvasBody>
            <FarmForm regions={regions} entityFarm={ curFarm } editMode={ 'edit' in curFarm ? curFarm.edit : false } handleComplete={onCompleteAddFarm} />
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  )
}

ProviderBranches.propTypes = {

};

export default ProviderBranches