import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment"

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 


export default function TableMovementItem({ item,multiple }) {

    const {
        row_period,
        total_vta,
        nota_cargo,
        nota_credito,
        cobro,
        total_periodo,
        balance,
        account_name,
        decrease
    } = item;

    return (
        <>
            <tr>
                <td className="text-end">
                    {row_period.length == 7 ? moment('01-' + row_period, 'DD-MM-YYYY').format('MMMM'):moment(row_period, 'DD-MM-YYYY').format('ddd')} {row_period.length == 7 ? moment('01-' + row_period, 'DD-MM-YYYY').format('YYYY') : row_period}
                </td>
                {multiple &&
                <td className="text-end">
                    {account_name}
                </td>
                }
                <td className="text-right">
                    { nf2.format(total_vta) }
                </td>
                <td className="text-right">
                    { nf2.format(decrease) }
                </td>
                <td className="text-right">
                    { nf2.format(nota_credito) }
                </td>
                <td className="text-right">
                    { nf2.format(nota_cargo) }
                </td>
                <td className="text-right">
                    { nf2.format(cobro) }
                </td>
                <td className={"text-right "}>
                    { multiple? '-':nf2.format(balance) }
                </td>
                <td className={"text-right " + (total_periodo < 0 ? 'text-danger':'')}>
                    { nf2.format(total_periodo) }
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object,
    multiple:PropTypes.bool
};