import React from 'react';

export default function TableCustomerItem() {
    return (
        <>
            <tr className="report-table__b-blue">
                <td>Cliente 1</td>
                <td colSpan={6} className="f-bold">GUTIERREZ MUÑOZ OSCAR</td>
            </tr>
            <tr>
                <td scope="row">SA</td>
                <td>Pollo</td>
                <td>KG</td>
                <td className="text-right">3,777.96</td>
                <td className="text-right">141,296.01</td>  
                <td className="text-right">3,777.96</td> 
                <td className="text-right">141,296.01</td>
            </tr>
            <tr className="report-table__b-black">
                <td scope="row">SA</td>
                <td>Pollo</td>
                <td>KG</td>
                <td className="text-right">2,237.96</td>
                <td className="text-right">121,246.01</td>  
                <td className="text-right">1,347.92</td> 
                <td className="text-right">112,996.21</td>
            </tr>
            <tr className="report-table__total-customer">
                <td colSpan={3} className="text-right f-bold">Total por cliente en M.N.</td>
                <td className="text-right">3,777.96</td>
                <td className="text-right">141,296.01</td>
                <td className="text-right">3,777.96</td>
                <td className="text-right">141,296.01</td>
            </tr>
        </>
    );
}