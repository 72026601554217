import React, { Component, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  getDistribuitorPriceHistory,
  getProducts,
} from "helpers/backend_helper"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  InputGroup,
  Label,
  Row,
  Table,
  Spinner,
} from "reactstrap"
import ComparePricesGraph from "./compare_graph"
import { useEffect } from "react"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import Flatpickr from "react-flatpickr"
import { getDistribuitorPriceTable } from "../../../helpers/backend_helper"
import * as moment from "moment"
import Select from "react-select"
import { select } from 'redux-saga/effects';
import Breadcrumb from "components/Common/Breadcrumb"

const nf = new Intl.NumberFormat('es-MX', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}); 

const initialPeriod =
  moment().startOf("month").format("YYYY-MM-DD") +
  "/" +
  moment().endOf("month").format("YYYY-MM-DD")

const AnalizePrices = props => {
  const [products, setProducts] = useState([])
  const [selectedPeriod, setSelectedPeriod] = useState([moment().startOf("month").toDate(), moment().endOf("month").toDate()])
  const [selectedProduct, setSelectedProduct] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [tableData, setTableData] = useState([])
  const [series, setSeries] = useState([])
  const [options, setOptions] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getProductsCatalog() {
      const response = await getProducts()
      let products = response.map(e => {
        return {
          label: `${e.skey ?? ""} ${e.name}`,
          value: e.id,
        }
      })
      setProducts(products)
    }

    getProductsCatalog()
  }, [])

  const getDataServer = async function () {
    console.log("selectedProduct", selectedProduct)
    const config = {
      params: {
        product: selectedProduct.map(e=>{return e.value}).join(','),
        period: moment(selectedPeriod[0]).format().substring(0, 10)+'/'+moment(selectedPeriod[1]).format().substring(0, 10),
      },
    }
    setLoading(true)
    const dataPrices = await getDistribuitorPriceTable(config)
    setLoading(false)
    console.log("dataPrices", dataPrices)

    let TABLE = []
    const keys = Object.keys(dataPrices.data)
    for(var i=0; i<keys.length; i++) {
      const key = keys[i]
      const [keyProvider, region, keyProduct] = keys[i].split('_')

      let total = 0
      let totalWeight = 0
      let count = 0
      dataPrices.data[key].forEach(e => {
        total += Number(e.price)
        totalWeight += Number(e.weight)
        count++;
      })
      
      let existProvider = dataPrices.providers.filter(e => {return e.id==keyProvider})
      let existProduct = products.filter(e => {return Number(e.value)==Number(keyProduct)})

      TABLE.push({
        provider: existProvider[0]?.id + ' | ' + (existProvider[0]?.skey ?? ''),
        product: existProduct[0]?.value + ' | ' + (existProduct[0]?.label ?? ''),
        region: region,
        price_shipped: total / count,
        weight: totalWeight / count
      })
    }

    const keys2 = Object.keys(dataPrices.others)
    for(var i=0; i<keys2.length; i++) {
      const key = keys2[i]
      const [keyProvider, region, keyProduct] = keys2[i].split('_')

      let total = 0
      let totalWeight = 0
      let count = 0
      dataPrices.others[key].forEach(e => {
        total += Number(e.price)
        totalWeight += Number(e.weight)
        count++;
      })

      TABLE.push({
        provider: dataPrices.others[key][0]?.enterprise ?? '',
        product: dataPrices.others[key][0]?.product ?? '',
        region: dataPrices.others[key][0]?.destino ?? '',
        price_shipped: total / count,
        weight: totalWeight / count
      })
    }

    setTableData(TABLE)

    const startDate = moment(selectedPeriod[0])
    const startDate2 = moment(selectedPeriod[0])
    const endDate = moment(selectedPeriod[1])
    let SERIES = []
    let OPTIONS = []
    for(;startDate2.isSameOrBefore(endDate);startDate2.add(1,'day')){
      OPTIONS.push(startDate2.format('DD-MM'))
    }

    for(var i = 0; i<keys.length ;i++) {
      let A = []
      const key = keys[i]
      const [keyProvider, region, keyProduct] = keys[i].split('_')
      const startDate3 = moment(selectedPeriod[0])
      for(;startDate3.isSameOrBefore(endDate);startDate3.add(1,'day')){
        let value = 0;
        let exist = dataPrices.data[key].filter(e=>{ return e.date == startDate3.format('YYYY-MM-DD')})
        A.push(exist.length > 0 ? exist[0].price : value)
      }

      let existProvider = dataPrices.providers.filter(e => {return e.id==keyProvider})
      let existProduct = products.filter(e => {return e.value==keyProduct})
      SERIES.push({ name: existProvider[0].skey + ' ' + region + ' ' + existProduct[0].label, data: A })
    }

    for(var i = 0; i<keys2.length ;i++) {
      let A = []
      const key = keys2[i]
      const [keyProvider, region, keyProduct] = keys2[i].split('_')
      const startDate3 = moment(selectedPeriod[0])
      for(;startDate3.isSameOrBefore(endDate);startDate3.add(1,'day')){
        let value = 0;
        let exist = dataPrices.others[key].filter(e=>{ return e.price_date == startDate3.format('YYYY-MM-DD')})
        A.push(exist.length > 0 ? exist[0].price : value)
      }

      SERIES.push({ name: dataPrices.others[key][0].enterprise + ' ' + dataPrices.others[key][0].destino + ' ' + dataPrices.others[key][0].product, data: A })
    }

    console.log(SERIES, OPTIONS)
    setSeries(SERIES)
    setOptions(OPTIONS)
  }

  useEffect(() => {
    console.log('selectedPeriod',selectedPeriod)
    if(selectedPeriod.length == 2) {
      console.log('call')
      getDataServer()
    }
  }, [selectedPeriod, selectedProduct])

  console.log(selectedPeriod)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cresta</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Pedidos" breadcrumbItem="Análisis de precios" />
          <Card className="mini-stats-wid">
            <CardBody>
              <Row>
                <Col lg={12} className="text-center">
                  {loading && (
                    <Spinner
                      animation="border"
                      variant="danger"
                      style={{ color: "#f46a6a" }}
                    />
                  )}
                </Col>
                <Col lg={12}>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label>Periodo</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder=""
                            onChange={date => {console.log('period', date); setSelectedPeriod([...date])}}
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                              locale: Spanish,
                              defaultDate: initialPeriod.split("/"),
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="">Producto</Label>
                        <Select
                          isMulti={true}
                          value={selectedProduct}
                          onChange={event => { console.log(event); setSelectedProduct(event) }}
                          options={products}
                          placeholder="Seleccionar"
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <ComparePricesGraph series={series} options={options} />
                </Col>
                <Col md={12}>
                
                    <div className="table-responsive">
                      <Table className="table-nowrap table-sm table-hover">
                        <thead>
                          <tr className="">
                            <th>Proveedor</th>
                            <th className="text">Destino</th>
                            <th className="text">Producto</th>
                            <th className="text">Promedio</th>
                            <th className="text">Precio</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map(item => (
                            <tr
                              key={item.price_id}
                              id={item.price_id}
                            >                              
                              <td>{item.provider}</td>
                              <td>{item.region}</td>
                              <td>{item.product}</td>
                              <td>{nf.format(item.weight)}</td>
                              <td>${nf.format(item.price_shipped)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  
                </Col>
                
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AnalizePrices
