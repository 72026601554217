import React from 'react';
import PropTypes from 'prop-types';

import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';

export default function OffCanvas({ children, isRight, toggleRightCanvas, reportTitle, isNew }) {
    return (
        <Offcanvas
            style={{width: '600px'}}
            isOpen={ isRight }
            direction="end"
            toggle={ toggleRightCanvas }
        >                
            <OffcanvasHeader toggle={ toggleRightCanvas } className='border-bottom border-danger'>                     
                { isNew != null  ? ( isNew ? 'Nuevo ' : 'Editar ') : '' } { reportTitle }
            </OffcanvasHeader>
            <OffcanvasBody>
                { children }
            </OffcanvasBody>
        </Offcanvas>
    )
}
OffCanvas.propTypes = {
    isRight: PropTypes.bool,
    toggleRightCanvas: PropTypes.func,
    reportTitle: PropTypes.string,
    children: PropTypes.any,
    isNew: PropTypes.bool
};
