import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const PreviewPDF = ({ modalPdf, setModalPdf, title = "Archivo" }) => {
  return (
    <Modal isOpen={modalPdf.show} className="modal-dialog modal-xl">
      <ModalHeader className="bg-primary">{title}</ModalHeader>
      <ModalBody>
        <div className="row">
          <iframe
            src={`${modalPdf.url}`}
            allowFullScreen
            style={{
              overflow: "auto",
              height: "500px",
              width: "100%",
            }}
          ></iframe>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            var a = document.createElement("a") //Create <a>
            a.href = modalPdf.url //Image Base64 Goes here
            a.download = modalPdf.fileName ? modalPdf.fileName : "reporte.pdf" //File name Here
            a.target = "_blank"
            a.click() //Downloaded file
          }}
        >
          {" "}
          <i className="fa fa-download"></i> Descargar
        </Button>
        <Button color="secondary" onClick={() => setModalPdf({ show: false })}>
          {" "}
          <i className="fa fa-times"></i> Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

PreviewPDF.propTypes = {
  modalPdf: PropTypes.object,
  title: PropTypes.string,
  setModalPdf: PropTypes.func,
  fileName: PropTypes.string,
}

export default PreviewPDF
