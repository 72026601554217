import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Card, CardBody } from "reactstrap"

import FullSpinner from "components/Common/FullSpinner"
import PageBase from "components/Common/PageBase"
import Filters from "./Filters"
import HeaderReport from "./HeaderReport"
import TableReport from "./TableReport"

import useRequest from "hooks/useRequest"

import { getBanksCashFlow } from "helpers/backend_helper"

import "../../../../assets/scss/reports/daily-movements.scss"
import { getAccountingAcounts } from "store/accounting-accounts/actions"
import { initialPeriod } from "static"

const breadcrumbList = [
  { id: "1", title: "Administrativo", active: false },
  { id: "2", title: "Reportes", active: false },
  { id: "3", title: "Concentrado de gastos", active: true },
]

export default function BanksCashFlowContainer() {
  const dispatch = useDispatch()

  const [period, setPeriod] = useState(initialPeriod)
  const [columnOrder, setColumnOrder] = useState({
    column: "",
    order: "",
  })

  const { data, isLoading, req } = useRequest()

  const handleFilters = params => {
    const config = {
      params,
    }

    req(getBanksCashFlow(config))
  }

  useEffect(() => {
    dispatch(getAccountingAcounts())
  }, [])

  return (
    <>
      {isLoading && <FullSpinner />}
      <PageBase
        titleMeta="Auxiliar de Entradas y Salidas de Dinero"
        titleBread="Auxiliar de Entradas y Salidas de Dinero"
        itemsBread={breadcrumbList}
      >
        <Card>
          <CardBody>
            <Filters
              data={data}
              setPeriod={setPeriod}
              columnOrder={columnOrder}
              handleFilters={handleFilters}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {data?.report?.length ? (
              <>
                <HeaderReport period={period} />
                <TableReport
                  data={data.report}
                  totals={data.totals}
                  setColumnOrder={setColumnOrder}
                  columnOrder={columnOrder}
                />
              </>
            ) : (
              <p>No hay datos con el periodo seleccionado</p>
            )}
          </CardBody>
        </Card>
      </PageBase>
    </>
  )
}
