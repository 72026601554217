import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import {
  Row,
  Col,
  Label,
  FormGroup,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Input,
  Button,
} from "reactstrap"

import Select from "react-select"
import * as moment from "moment"
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import "flatpickr/dist/themes/material_red.css"

import {
  getAdministrativeData,
  setAdministrativeFilters,
} from "store/catalogs/administrative-data/actions"
import ModalFile from "../common/ModalFile"
import MonthButton from "../common/MonthButton"
import { monthsFilterValues } from "common/data/months"

import { getFilters } from "selectors"
import DownloadExcel from "../common/DownloadExcel"
import { orderByName, rangeDatePicker } from "helpers/functions"
import useAdminClientsProviders from "hooks/useAdminClientsProviders"
import { getBancosOptionData } from "../../../helpers/backend_helper"
import DownloadPdf from "../common/DownloadPdf"

const initialPeriod =
  moment().startOf("month").format("YYYY-MM-DD") +
  "/" +
  moment().endOf("month").format("YYYY-MM-DD")

const defaultSel = {
  id: "",
  value: "",
}

const optionsMostrar = [
  { id: 1, name: "Todo" },
  { id: 2, name: "Solo ventas" },
  { id: 3, name: "Solo cobros" },
  { id: 4, name: "Solo Otros descuentos" },
  { id: 5, name: "Solo Otros cargos" },
  { id: 6, name: "Solo Merma mortalidad" },
]

const optionsMostrarProv = [
  { id: 1, name: "Todo" },
  { id: 2, name: "Solo compras" },
  { id: 3, name: "Solo pagos" },
  { id: 4, name: "Solo merma" },
  { id: 5, name: "Solo descuentos" },
  { id: 6, name: "Solo Otros cargos" },
]

const curYear = moment().year()
const optionsYears = Array(8)
  .fill(0)
  .map((_, i) => {
    return { id: curYear - i, name: curYear - i }
  })

const Filters = ({ toggleRightCanvas, setIsNew, toggleColumnsCanvas }) => {
  const [bancosOptions, setBancosOptions] = useState([])
  const [bancoSelect, setBancoSelect] = useState({})
  useAdminClientsProviders(initialPeriod)

  const user = JSON.parse(localStorage.getItem("authUser"))
  const [userTypeRol, setUserTypeRol] = useState(
    user.user?.type_rol || user.type_rol
  )
  const [isDownloadExcel, setIsDownloadExcel] = useState(false)
  const [isDownloadPdf, setIsDownloadPdf] = useState(false)
  const [searchQty, setSearchQty] = useState("")

  const dispatch = useDispatch()

  const [openModal, setOpenModal] = useState(false)

  const {
    data,
    filters,
    products,
    activeTab,
    sucursales,
    bankAccounts,
    clientAccounts,
    paymentAccounts,
    providerAccounts,
  } = useSelector(getFilters)

  console.log(activeTab)

  const paymentOptionsA = [
    ...bankAccounts,
    ...paymentAccounts,
    ...clientAccounts,
  ].sort(orderByName)
  const paymentOptions = []
  const temp = {}
  for (let i = 0; i < paymentOptionsA.length - 1; i++) {
    if (!(paymentOptionsA[i].id in temp)) {
      paymentOptions.push(paymentOptionsA[i])
      temp[paymentOptionsA[i].id] = true
    }
  }

  const handleFilters = ({ key, val }) => {
    dispatch(
      setAdministrativeFilters({
        [key]: val,
        page: 1,
      })
    )
    dispatch(getAdministrativeData())
  }

  const handleSelectYear = year => {
    const dates = filters.dates.split("/")
    const start = moment(dates[0])
    const end = moment(dates[1])
    start.year(year)
    end.year(year)
    const parseDates =
      start.format("YYYY-MM-DD") + "/" + end.format("YYYY-MM-DD")
    handleFilters({
      key: "dates",
      val: parseDates,
    })
  }
  useEffect(() => {
    getBancosOptionData().then(data => {
      setBancosOptions(data)
    })
  }, [])

  if (activeTab === "comprobacion_almacen")
    return (
      <>
        <Row>
          <Col md={"auto"}>
            <div className="w-100 mt-1">
              <Label className="small fw-bold p-0 m-0 d-block">Año - Mes</Label>
              <UncontrolledDropdown
                style={{ cursor: "pointer" }}
                className="d-inline-block me-1 pt-0 mt-0"
              >
                <DropdownToggle
                  href="#"
                  className="card-drop action-points"
                  tag="i"
                >
                  <button className="btn btn-sm btn-danger">
                    {filters.year.name} <i className="mdi mdi-chevron-down"></i>
                  </button>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-enddd actions-menu">
                  {optionsYears.map((val, key) => {
                    return (
                      <DropdownItem
                        key={key}
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          handleFilters({
                            key: "year",
                            val: val,
                          })
                          handleSelectYear(val.id)
                        }}
                      >
                        {val.name}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>

              {monthsFilterValues.map((val, key) => {
                return (
                  <MonthButton
                    key={key}
                    month={key}
                    monthString={val}
                    year={filters.year.name}
                    handler={period => {
                      handleFilters({
                        key: "dates",
                        val: period,
                      })
                    }}
                  />
                )
              })}
            </div>
          </Col>
          <Col lg={3} className="">
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Periodo</Label>
              <InputGroup className="">
                <Flatpickr
                  className="form-control d-block w-100"
                  placeholder=""
                  onChange={dates => {
                    if (dates.length == 0) return
                    handleFilters({
                      key: "dates",
                      val: rangeDatePicker(
                        dates.length == 1 ? [dates[0], dates[0]] : dates
                      ),
                    })
                  }}
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                    locale: Spanish,
                    defaultDate: initialPeriod.split("/"),
                    allowInput: true,
                  }}
                />
              </InputGroup>
            </div>
          </Col>
          <Col className="text-end">
            <UncontrolledDropdown style={{ cursor: "pointer" }}>
              <DropdownToggle
                href="#"
                className="card-drop action-points"
                tag="i"
                onClick={() => setIsDownloadExcel(false)}
              >
                <button className="btn btn-sm btn-danger">
                  Registros <i className="mdi mdi-chevron-down"></i>
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-enddd actions-menu">
                {activeTab !== "comprobacion_almacen" && (
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setOpenModal(true)
                    }}
                  >
                    <i className="bx bx-import font-size-16 text-danger"></i>{" "}
                    Importar
                  </DropdownItem>
                )}
                {data.length ? (
                  <>
                    <DropdownItem
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        setIsDownloadExcel(true)
                      }}
                    >
                      <i className="mdi mdi-microsoft-excel font-size-16 text-danger"></i>{" "}
                      Exportar Excel
                    </DropdownItem>
                    {["comprobacion_almacen"].includes(activeTab) && (
                      <DropdownItem
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          setIsDownloadPdf(true)
                        }}
                      >
                        <i className="mdi mdi-file-pdf font-size-16 text-danger"></i>{" "}
                        Exportar PDF
                      </DropdownItem>
                    )}
                  </>
                ) : (
                  ""
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
            {isDownloadExcel && (
              <DownloadExcel setIsDownloadExcel={setIsDownloadExcel} />
            )}
            {isDownloadPdf && (
              <DownloadPdf setIsDownloadPdf={setIsDownloadPdf} />
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Proveedor</Label>
              <Select
                value={
                  activeTab === "clientes"
                    ? filters.provider_client
                    : filters.provider
                }
                onChange={event => {
                  const defaultSel = { id: "", name: "Todos" }

                  handleFilters({
                    key:
                      activeTab === "clientes" ? "provider_client" : "provider",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todos" }, ...providerAccounts]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Producto</Label>
              <Select
                value={filters.product}
                onChange={event => {
                  const defaultSel = { id: "", name: "Todos" }

                  handleFilters({
                    key: "product",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todos" }, ...products]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
        </Row>

        <ModalFile isOpen={openModal} setOpenModal={setOpenModal} />
      </>
    )
  if (activeTab === "clientes")
    return (
      <>
        <Row>
          <Col md={"auto"}>
            <div className="w-100 mt-1">
              <Label className="small fw-bold p-0 m-0 d-block">Año - Mes</Label>
              <UncontrolledDropdown
                style={{ cursor: "pointer" }}
                className="d-inline-block me-1 mt-0"
              >
                <DropdownToggle
                  href="#"
                  className="card-drop action-points"
                  tag="i"
                >
                  <button className="btn btn-sm btn-danger">
                    {filters.year.name} <i className="mdi mdi-chevron-down"></i>
                  </button>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-enddd actions-menu">
                  {optionsYears.map((val, key) => {
                    return (
                      <DropdownItem
                        key={key}
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          handleFilters({
                            key: "year",
                            val: val,
                          })
                          handleSelectYear(val.id)
                        }}
                      >
                        {val.name}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>

              {monthsFilterValues.map((val, key) => {
                return (
                  <MonthButton
                    key={key}
                    month={key}
                    monthString={val}
                    year={filters.year.name}
                    handler={period => {
                      handleFilters({
                        key: "dates",
                        val: period,
                      })
                    }}
                  />
                )
              })}
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Periodo</Label>
              <InputGroup>
                <Flatpickr
                  className="form-control d-block"
                  placeholder=""
                  onChange={dates => {
                    if (dates.length == 0) return
                    handleFilters({
                      key: "dates",
                      val: rangeDatePicker(
                        dates.length == 1 ? [dates[0], dates[0]] : dates
                      ),
                    })
                  }}
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                    locale: Spanish,
                    defaultDate: initialPeriod.split("/"),
                    allowInput: true,
                  }}
                />
              </InputGroup>
            </div>
          </Col>
          <Col className="text-end">
            <Button
              size="sm float-end ms-1"
              onClick={e => {
                console.log("click")
                toggleColumnsCanvas()
                e.preventDefault()
              }}
              type="button"
            >
              <i className="bx bx-columns fs-6" />{" "}
            </Button>
            <UncontrolledDropdown
              className="d-inline-block"
              style={{ cursor: "pointer" }}
            >
              <DropdownToggle
                href="#"
                className="card-drop action-points"
                tag="i"
                onClick={() => setIsDownloadExcel(false)}
              >
                <button className="btn btn-sm btn-danger">
                  Registros <i className="mdi mdi-chevron-down"></i>
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-enddd actions-menu">
                {userTypeRol != 3 && activeTab !== "cargos" ? (
                  <>
                    <DropdownItem
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        toggleRightCanvas()
                        setIsNew(true)
                      }}
                    >
                      <i className="bx bx-plus font-size-16 text-danger" />{" "}
                      Nuevo registro (Ctrl+i)
                    </DropdownItem>
                  </>
                ) : (
                  ""
                )}
                {["clientes", "proveedores"].includes(activeTab) ? (
                  <>
                    <DropdownItem
                    className="d-none"
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        toggleColumnsCanvas()
                      }}
                    >
                      <i className="bx bx-cog font-size-16 text-danger" />{" "}
                      Configuracion de reporte
                    </DropdownItem>
                  </>
                ) : (
                  ""
                )}
                {activeTab !== "comprobacion_almacen" && (
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setOpenModal(true)
                    }}
                  >
                    <i className="bx bx-import font-size-16 text-danger"></i>{" "}
                    Importar
                  </DropdownItem>
                )}
                {data.length ? (
                  <>
                    <DropdownItem
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        setIsDownloadExcel(true)
                      }}
                    >
                      <i className="mdi mdi-microsoft-excel font-size-16 text-danger"></i>{" "}
                      Exportar Excel
                    </DropdownItem>
                    {["comprobacion_almacen"].includes(activeTab) && (
                      <DropdownItem
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          setIsDownloadPdf(true)
                        }}
                      >
                        <i className="mdi mdi-file-pdf font-size-16 text-danger"></i>{" "}
                        Exportar PDF
                      </DropdownItem>
                    )}
                  </>
                ) : (
                  ""
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
            {isDownloadExcel && (
              <DownloadExcel setIsDownloadExcel={setIsDownloadExcel} />
            )}
            {isDownloadPdf && (
              <DownloadPdf setIsDownloadPdf={setIsDownloadPdf} />
            )}
          </Col>
        </Row>
        <Row className="mt-1">
        <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Cliente</Label>
              <Select
                value={filters.client}
                onChange={event => {
                  handleFilters({
                    key: "client",
                    val: event ? event : defaultSel,
                  })
                }}
                options={clientAccounts}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Proveedor</Label>
              <Select
                value={
                  activeTab === "clientes"
                    ? filters.provider_client
                    : filters.provider
                }
                onChange={event => {
                  const defaultSel = { id: "", name: "Todos" }

                  handleFilters({
                    key:
                      activeTab === "clientes" ? "provider_client" : "provider",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todos" }, ...providerAccounts]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Forma de Pago</Label>
              <Select
                value={bancoSelect}
                onChange={event => {
                  ;[
                    setBancoSelect(event),
                    handleFilters({
                      key: "banco",
                      val: event ? event : defaultSel,
                    }),
                  ]
                }}
                options={bancosOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.name}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>

          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">
                {activeTab === "clientes" ? "Banco" : "Descripción"}
              </Label>
              <Select
                value={filters.payment}
                onChange={event => {
                  handleFilters({
                    key: "payment",
                    val: event ? event : defaultSel,
                  })
                }}
                options={paymentOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Producto</Label>
              <Select
                value={filters.product}
                onChange={event => {
                  const defaultSel = { id: "", name: "Todos" }

                  handleFilters({
                    key: "product",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todos" }, ...products]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Ruta</Label>
              <Select
                value={filters.sucursal}
                onChange={event => {
                  const defaultSel = { id: "", name: "Todas" }

                  handleFilters({
                    key: "sucursal",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todas" }, ...sucursales]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Mostrar</Label>
              <Select
                value={filters.mostrar}
                onChange={event => {
                  handleFilters({
                    key: "mostrar",
                    val: event ? event : defaultSel,
                  })
                }}
                options={optionsMostrar}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label
                className="small fw-bold p-0 m-0"
                htmlFor="formrow-email-Input"
              >
                Buscar cantidad:
              </Label>
              <Input
                value={searchQty}
                onChange={e => {
                  setSearchQty(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleFilters({
                      key: "search_qty",
                      val: searchQty,
                    })
                  }
                }}
                name="search_qty"
                type="number"
                placeholder="Colocar cantidad + [enter]"
              />
            </div>
          </Col>
        </Row>
        <ModalFile isOpen={openModal} setOpenModal={setOpenModal} />
      </>
    )
  if (activeTab === "proveedores")
    return (
      <>
        <Row>
          <Col md={"auto"}>
            <div className="w-100 mt-1">
              <Label className="small fw-bold p-0 m-0 d-block">Año - Mes</Label>
              <UncontrolledDropdown
                style={{ cursor: "pointer" }}
                className="d-inline-block me-1 mt-0"
              >
                <DropdownToggle
                  href="#"
                  className="card-drop action-points"
                  tag="i"
                >
                  <button className="btn btn-sm btn-danger">
                    {filters.year.name} <i className="mdi mdi-chevron-down"></i>
                  </button>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-enddd actions-menu">
                  {optionsYears.map((val, key) => {
                    return (
                      <DropdownItem
                        key={key}
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          handleFilters({
                            key: "year",
                            val: val,
                          })
                          handleSelectYear(val.id)
                        }}
                      >
                        {val.name}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>

              {monthsFilterValues.map((val, key) => {
                return (
                  <MonthButton
                    key={key}
                    month={key}
                    monthString={val}
                    year={filters.year.name}
                    handler={period => {
                      handleFilters({
                        key: "dates",
                        val: period,
                      })
                    }}
                  />
                )
              })}
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Periodo</Label>
              <InputGroup>
                <Flatpickr
                  className="form-control d-block"
                  placeholder=""
                  onChange={dates => {
                    if (dates.length == 0) return
                    handleFilters({
                      key: "dates",
                      val: rangeDatePicker(
                        dates.length == 1 ? [dates[0], dates[0]] : dates
                      ),
                    })
                  }}
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                    locale: Spanish,
                    defaultDate: initialPeriod.split("/"),
                    allowInput: true,
                  }}
                />
              </InputGroup>
            </div>
          </Col>
          <Col className="text-end">
            <Button
              size="sm float-end ms-1"
              onClick={e => {
                console.log("click")
                toggleColumnsCanvas()
                e.preventDefault()
              }}
              type="button"
            >
              <i className="bx bx-columns fs-6" />{" "}
            </Button>
            <UncontrolledDropdown
              className="d-inline-block"
              style={{ cursor: "pointer" }}
            >
              <DropdownToggle
                href="#"
                className="card-drop action-points"
                tag="i"
                onClick={() => setIsDownloadExcel(false)}
              >
                <button className="btn btn-sm btn-danger">
                  Registros <i className="mdi mdi-chevron-down"></i>
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-enddd actions-menu">
                {userTypeRol != 3 && activeTab !== "cargos" ? (
                  <>
                    <DropdownItem
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        toggleRightCanvas()
                        setIsNew(true)
                      }}
                    >
                      <i className="bx bx-plus font-size-16 text-danger" />{" "}
                      Nuevo registro (Ctrl+i)
                    </DropdownItem>
                  </>
                ) : (
                  ""
                )}
                {["clientes", "proveedores"].includes(activeTab) ? (
                  <>
                    <DropdownItem
                    className="d-none"
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        toggleColumnsCanvas()
                      }}
                    >
                      <i className="bx bx-cog font-size-16 text-danger" />{" "}
                      Configuracion de reporte
                    </DropdownItem>
                  </>
                ) : (
                  ""
                )}
                {activeTab !== "comprobacion_almacen" && (
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setOpenModal(true)
                    }}
                  >
                    <i className="bx bx-import font-size-16 text-danger"></i>{" "}
                    Importar
                  </DropdownItem>
                )}
                {data.length ? (
                  <>
                    <DropdownItem
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        setIsDownloadExcel(true)
                      }}
                    >
                      <i className="mdi mdi-microsoft-excel font-size-16 text-danger"></i>{" "}
                      Exportar Excel
                    </DropdownItem>
                    {["comprobacion_almacen"].includes(activeTab) && (
                      <DropdownItem
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          setIsDownloadPdf(true)
                        }}
                      >
                        <i className="mdi mdi-file-pdf font-size-16 text-danger"></i>{" "}
                        Exportar PDF
                      </DropdownItem>
                    )}
                  </>
                ) : (
                  ""
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
            {isDownloadExcel && (
              <DownloadExcel setIsDownloadExcel={setIsDownloadExcel} />
            )}
            {isDownloadPdf && (
              <DownloadPdf setIsDownloadPdf={setIsDownloadPdf} />
            )}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col lg={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Proveedor</Label>
              <Select
                value={
                  activeTab === "clientes"
                    ? filters.provider_client
                    : filters.provider
                }
                onChange={event => {
                  const defaultSel = { id: "", name: "Todos" }

                  handleFilters({
                    key:
                      activeTab === "clientes" ? "provider_client" : "provider",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todos" }, ...providerAccounts]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
          <Col lg={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">
                {activeTab === "clientes" ? "Banco" : "Descripción"}
              </Label>
              <Select
                value={filters.payment}
                onChange={event => {
                  handleFilters({
                    key: "payment",
                    val: event ? event : defaultSel,
                  })
                }}
                options={paymentOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Mostrar</Label>
              <Select
                  value={filters.mostrar}
                  onChange={event => {
                    handleFilters({
                      key: "mostrar",
                      val: event ? event : defaultSel,
                    })
                  }}
                  options={optionsMostrarProv}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder=""
                  classNamePrefix="select2-selection"
                />
            </div>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Producto</Label>
              <Select
                value={filters.product}
                onChange={event => {
                  const defaultSel = { id: "", name: "Todos" }

                  handleFilters({
                    key: "product",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todos" }, ...products]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="w-100">
              <Label className="small fw-bold p-0 m-0">Ruta</Label>
              <Select
                value={filters.sucursal}
                onChange={event => {
                  const defaultSel = { id: "", name: "Todas" }

                  handleFilters({
                    key: "sucursal",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todas" }, ...sucursales]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>

          <Col md={3}>
            <div className="w-100">
              <Label
                className="small fw-bold p-0 m-0"
                htmlFor="formrow-email-Input"
              >
                Buscar cantidad:
              </Label>
              <Input
                value={searchQty}
                onChange={e => {
                  setSearchQty(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleFilters({
                      key: "search_qty",
                      val: searchQty,
                    })
                  }
                }}
                name="search_qty"
                type="number"
                placeholder="Colocar cantidad + [enter]"
              />
            </div>
          </Col>
        </Row>
        <ModalFile isOpen={openModal} setOpenModal={setOpenModal} />
      </>
    )

  return (
    <>
      <Row>
        {activeTab == "clientes" && (
          <>
            <Col lg={2}>
              <div className="mb-3">
                <Label>Cliente</Label>
                <Select
                  value={filters.client}
                  onChange={event => {
                    handleFilters({
                      key: "client",
                      val: event ? event : defaultSel,
                    })
                  }}
                  options={clientAccounts}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder=""
                  classNamePrefix="select2-selection"
                  isClearable={true}
                />
              </div>
            </Col>
          </>
        )}
        <Col lg={2}>
          <div className="mb-3">
            <Label>Proveedor</Label>
            <Select
              value={
                activeTab === "clientes"
                  ? filters.provider_client
                  : filters.provider
              }
              onChange={event => {
                const defaultSel = { id: "", name: "Todos" }

                handleFilters({
                  key:
                    activeTab === "clientes" ? "provider_client" : "provider",
                  val: event ? event : defaultSel,
                })
              }}
              options={[{ id: "", name: "Todos" }, ...providerAccounts]}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder=""
              classNamePrefix="select2-selection"
              isClearable={true}
            />
          </div>
        </Col>
        {["comprobacion_almacen", "clientes", "proveedores"].includes(
          activeTab
        ) && (
          <Col lg={2}>
            <div className="mb-3">
              <Label>Producto</Label>
              <Select
                value={filters.product}
                onChange={event => {
                  const defaultSel = { id: "", name: "Todos" }

                  handleFilters({
                    key: "product",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todos" }, ...products]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
        )}
        {["clientes", "proveedores"].includes(activeTab) && (
          <Col lg={2}>
            <div className="mb-3">
              <Label>Ruta</Label>
              <Select
                value={filters.sucursal}
                onChange={event => {
                  const defaultSel = { id: "", name: "Todas" }

                  handleFilters({
                    key: "sucursal",
                    val: event ? event : defaultSel,
                  })
                }}
                options={[{ id: "", name: "Todas" }, ...sucursales]}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
        )}
        {(activeTab === "clientes" || activeTab === "proveedores") && (
          <Col lg={2}>
            <div className="mb-3">
              <Label>
                {activeTab === "clientes" ? "Banco" : "Descripción"}
              </Label>
              <Select
                value={filters.payment}
                onChange={event => {
                  handleFilters({
                    key: "payment",
                    val: event ? event : defaultSel,
                  })
                }}
                options={paymentOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
                isClearable={true}
              />
            </div>
          </Col>
        )}
        {activeTab == "clientes" && (
          <>
            <Col lg={2}>
              <div className="mb-3">
                <Label>Forma de Pago</Label>
                <Select
                  value={bancoSelect}
                  onChange={event => {
                    ;[
                      setBancoSelect(event),
                      handleFilters({
                        key: "banco",
                        val: event ? event : defaultSel,
                      }),
                    ]
                  }}
                  options={bancosOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.name}
                  placeholder=""
                  classNamePrefix="select2-selection"
                  isClearable={true}
                />
              </div>
            </Col>
          </>
        )}

        {activeTab == "clientes" && (
          <Col lg={2}>
            <div className="mb-3">
              <Label>Mostrar</Label>
              <Select
                value={filters.mostrar}
                onChange={event => {
                  handleFilters({
                    key: "mostrar",
                    val: event ? event : defaultSel,
                  })
                }}
                options={optionsMostrar}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder=""
                classNamePrefix="select2-selection"
              />
            </div>
          </Col>
        )}

        {activeTab == "proveedores" && (
          <>
            <Col lg={2}>
              <div className="mb-3">
                <Label>Mostrar</Label>
                <Select
                  value={filters.mostrar}
                  onChange={event => {
                    handleFilters({
                      key: "mostrar",
                      val: event ? event : defaultSel,
                    })
                  }}
                  options={optionsMostrarProv}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder=""
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </>
        )}
        {/* <Col md={3}>
                    <div className="mb-3">
                        <Label>Buscar</Label>
                        <Input
                            value={ filters.search }
                            onChange={ e => {
                                dispatch( setAdministrativeFilters({ search: e.target.value, page: 1 }) );
                            }}                            
                            name="buscar"
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Filtrar por busqueda"
                        />
                    </div>
                </Col> */}
        <Col className="text-end">
          <UncontrolledDropdown style={{ cursor: "pointer" }}>
            <DropdownToggle
              href="#"
              className="card-drop action-points"
              tag="i"
              onClick={() => setIsDownloadExcel(false)}
            >
              <button className="btn btn-sm btn-danger">
                Registros <i className="mdi mdi-chevron-down"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-enddd actions-menu">
              {userTypeRol != 3 && activeTab !== "cargos" ? (
                <>
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      toggleRightCanvas()
                      setIsNew(true)
                    }}
                  >
                    <i className="bx bx-plus font-size-16 text-danger" /> Nuevo
                    registro (Ctrl+i)
                  </DropdownItem>
                </>
              ) : (
                ""
              )}
              {["clientes", "proveedores"].includes(activeTab) ? (
                <>
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      toggleColumnsCanvas()
                    }}
                  >
                    <i className="bx bx-cog font-size-16 text-danger" />{" "}
                    Configuracion de reporte
                  </DropdownItem>
                </>
              ) : (
                ""
              )}
              {activeTab !== "comprobacion_almacen" && (
                <DropdownItem
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    setOpenModal(true)
                  }}
                >
                  <i className="bx bx-import font-size-16 text-danger"></i>{" "}
                  Importar
                </DropdownItem>
              )}
              {data.length ? (
                <>
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setIsDownloadExcel(true)
                    }}
                  >
                    <i className="mdi mdi-microsoft-excel font-size-16 text-danger"></i>{" "}
                    Exportar Excel
                  </DropdownItem>
                  {["comprobacion_almacen"].includes(activeTab) && (
                    <DropdownItem
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        setIsDownloadPdf(true)
                      }}
                    >
                      <i className="mdi mdi-file-pdf font-size-16 text-danger"></i>{" "}
                      Exportar PDF
                    </DropdownItem>
                  )}
                </>
              ) : (
                ""
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          {isDownloadExcel && (
            <DownloadExcel setIsDownloadExcel={setIsDownloadExcel} />
          )}
          {isDownloadPdf && <DownloadPdf setIsDownloadPdf={setIsDownloadPdf} />}
        </Col>
      </Row>
      <Row>
        <Col md={activeTab == "clientes" || activeTab == "proveedores" ? 6 : 6}>
          <UncontrolledDropdown
            style={{ cursor: "pointer" }}
            className="d-inline-block me-1 pt-0 mt-0"
          >
            <DropdownToggle
              href="#"
              className="card-drop action-points"
              tag="i"
            >
              <button className="btn btn-sm btn-danger">
                {filters.year.name} <i className="mdi mdi-chevron-down"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-enddd actions-menu">
              {optionsYears.map((val, key) => {
                return (
                  <DropdownItem
                    key={key}
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      handleFilters({
                        key: "year",
                        val: val,
                      })
                      handleSelectYear(val.id)
                    }}
                  >
                    {val.name}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </UncontrolledDropdown>

          {monthsFilterValues.map((val, key) => {
            return (
              <MonthButton
                key={key}
                month={key}
                monthString={val}
                year={filters.year.name}
                handler={period => {
                  handleFilters({
                    key: "dates",
                    val: period,
                  })
                }}
              />
            )
          })}
        </Col>
        <Col lg={2}>
          <FormGroup className="mb-4">
            <Label>Periodo</Label>
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder=""
                onChange={dates => {
                  if (dates.length == 0) return
                  handleFilters({
                    key: "dates",
                    val: rangeDatePicker(
                      dates.length == 1 ? [dates[0], dates[0]] : dates
                    ),
                  })
                }}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                  locale: Spanish,
                  defaultDate: initialPeriod.split("/"),
                  allowInput: true,
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        {(activeTab == "clientes" || activeTab == "proveedores") && (
          <>
            <Col md={2}>
              <Label htmlFor="formrow-email-Input">Buscar cantidad:</Label>
              <Input
                value={searchQty}
                onChange={e => {
                  setSearchQty(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleFilters({
                      key: "search_qty",
                      val: searchQty,
                    })
                  }
                }}
                name="search_qty"
                type="number"
                placeholder="Colocar cantidad + [enter]"
              />
            </Col>
          </>
        )}
      </Row>

      <ModalFile isOpen={openModal} setOpenModal={setOpenModal} />
    </>
  )
}

Filters.propTypes = {
  toggleRightCanvas: PropTypes.func,
  toggleColumnsCanvas: PropTypes.func,
  setIsNew: PropTypes.func,
}

export default Filters
