const monthsFilterValues = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct', 
    'Nov',
    'Dic',
]

export {monthsFilterValues}