import React from 'react';
import PropTypes from 'prop-types';
import AjustForm from './AjustForm';

export const TypeForm = ({ toggleRightCanvas, isNew,handleFilters}) => {

    return  <AjustForm 
        isNew={ isNew }
        handleFilters={handleFilters}
        toggleRightCanvas={ toggleRightCanvas } 
    />;    
}

TypeForm.propTypes = {
    toggleRightCanvas: PropTypes.func,
    handleFilters: PropTypes.func,
    isNew: PropTypes.bool,
}
