import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Button, Form, Alert, Spinner } from "reactstrap"
import { useFormik } from "formik"

import useRequest from "hooks/useRequest"
import { validationSchema } from "./static"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"

import {
  getRegimenesFiscales,
  updateEnterpriseTaxData,
} from "helpers/backend_helper"

const TaxDataTab = ({ enterprise }) => {
  const { msgError, isLoading, isSubmitted, req } = useRequest()

  const regimenesFiscalesReq = useRequest()

  const onSubmit = formValues => {
    req(updateEnterpriseTaxData(formValues))
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: enterprise,
    validationSchema,
    onSubmit,
  })

  const fetchRegimenesFiscales = () => {
    regimenesFiscalesReq.req(getRegimenesFiscales())
  }

  useEffect(fetchRegimenesFiscales, [])

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        {msgError ? (
          <Alert className="alert alert-danger">{msgError}</Alert>
        ) : (
          ""
        )}
        {isSubmitted ? (
          <Alert className="alert alert-success">Registro actualizado</Alert>
        ) : (
          ""
        )}
        <Row>
          <NibbleBaseInput
            fieldType="text"
            fieldName="rfc"
            label="RFC"
            validation={validation}
            md={3}
          />
          <NibbleBaseInput
            fieldType="text"
            fieldName="invoice_address.cp"
            label="C.P."
            validation={validation}
            md={3}
          />
          <NibbleBaseInput
            fieldType="text"
            fieldName="company_name"
            label="Razón Social"
            validation={validation}
            md={3}
          />
          <NibbleBaseInput
            fieldType="select"
            fieldName="tax_regime"
            label="Régimen Fiscal"
            selectItems={
              regimenesFiscalesReq.data?.map(item => ({
                ...item,
                id: item.code,
              })) || []
            }
            validation={validation}
            selectOptionLabel={option =>
              `${option.code} - ${option.description}`
            }
            selectOptionValue={option => option.code}
            md={3}
            placeHolder="Seleccionar"
          />
        </Row>
        <div className="text-center mt-4">
          {!isLoading ? (
            <Button type="submit" color="danger" className="btn-sm">
              Actualizar datos
            </Button>
          ) : (
            <Spinner size="sm" />
          )}
        </div>
      </Form>
    </div>
  )
}

TaxDataTab.propTypes = {
  enterprise: PropTypes.any,
}

export default TaxDataTab
