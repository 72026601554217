import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const multiLevel = { paddingLeft: "6rem" }

const SidebarContent = props => {
  const ref = useRef()
  const user = JSON.parse(localStorage.getItem("authUser"))
  const [userType, setUserType] = useState(user.user?.type || user.type)
  const [userTypeRol, setUserTypeRol] = useState(
    user.user?.type_rol || user.type_rol
  ) //user.user?.type_rol || user.type_rol
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            {userType == 1 && (
              <li>
                <Link to="/dashboard" className="">
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Resumen")}</span>
                </Link>
              </li>
            )}

            <li className="menu-title">{props.t("Apps")}</li>

            {[1, 2, 4].includes(userTypeRol) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-dice-1"></i>
                  <span>{props.t("Catálogos")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {[1].includes(userTypeRol) && (
                    <>
                      <li>
                        <Link to="/catalogs/providers">
                          {props.t("Proveedores")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/catalogs/clients">
                          {props.t("Clientes")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/catalogs/users">{props.t("Usuarios")}</Link>
                      </li>
                      <li>
                        <Link to="/catalogs/users-internals">
                          {props.t("Usuarios Internos")}
                        </Link>
                      </li>
                    </>
                  )}
                  {[1, 2].includes(userTypeRol) && (
                    <li>
                      <Link to="/catalogs/products">
                        {props.t("Productos")}
                      </Link>
                    </li>
                  )}
                  {[1].includes(userTypeRol) && (
                    <>
                      <li>
                        <Link to="/catalogs/units">{props.t("Unidades")}</Link>
                      </li>
                      <li>
                        <Link to="/catalogs/regions">
                          {props.t("Regiones")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/catalogs/holidays">
                          {props.t("Días Festivos")}
                        </Link>
                      </li>
                      <li className="d-none hidden">
                        <Link to="/catalogs/roles">
                          {props.t("Permisos y Roles")}
                        </Link>
                      </li>
                    </>
                  )}
                  {[4].includes(userTypeRol) && (
                    <>
                      <li>
                        <Link to="/catalogs/enterprises">
                          {props.t("Empresas")}
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
            )}

            {userType == 1 && [1].includes(userTypeRol) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-package"></i>
                  <span>{props.t("Pedidos")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/distributor/availability/create">
                      {props.t("Alta de disponibilidad")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor/orders/create">
                      {props.t("Crear Pedidos")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor/daily">
                      {props.t("Logística Pedidos")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor/availability">
                      {props.t("Visualizar Disponibilidad")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/catalogs/prices-providers">
                      {props.t("Concentrado de precios")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distribuitor/analize-prices">
                      {props.t("Análisis de Precios")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor/orders">
                      {props.t("Pedidos Clientes")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor/provider-orders">
                      {props.t("Pedidos Proveedores")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributor/configs">
                      {props.t("Configuraciones")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {userType == 1 && [1, 2, 3].includes(userTypeRol) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx bx-cctv"></i>
                  <span>{props.t("Administrativo")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/billing-configuration" className="">
                      {/* <i className="bx bx-cog"></i> */}
                      <span>{props.t("Config. facturación")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/billing" className="">
                      {/* <i className="bx bx-cog"></i> */}
                      <span>{props.t("Facturación")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrative/accountings">
                      {props.t("Catálogo de cuentas")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrative/clients-providers">
                      {props.t("Ventas-Compras-Almacén")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrative/banks">
                      {props.t("Bancos")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/administrative/costs">{props.t("Gastos")}</Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Reportes")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span>{props.t("Clientes")}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                          <li>
                            <Link
                              to="/administrative/reports/sales-folio"
                              style={multiLevel}
                            >
                              {props.t("Ventas por folio")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/sales-concentrate"
                              style={multiLevel}
                            >
                              {props.t("Concentrado de ventas")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/clients-balance"
                              style={multiLevel}
                            >
                              {props.t("Concentrado saldos")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/clients-operations"
                              style={multiLevel}
                            >
                              {props.t("Operaciones por periodo")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/accounts-statements"
                              style={multiLevel}
                            >
                              {props.t("Estados de cuenta")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/accounts-receivable"
                              style={multiLevel}
                            >
                              {props.t("Cuentas por Cobrar")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/billing"
                              style={multiLevel}
                            >
                              {props.t("Cobranza Global")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/billing-detail"
                              style={multiLevel}
                            >
                              {props.t("Cobranza analítica")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span>{props.t("Proveedores")}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="true">
                          <li className="">
                            <Link style={multiLevel}>
                              {props.t("Compras por folio")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/shopping-concentrate"
                              style={multiLevel}
                            >
                              {props.t("Concentrado de compras")}
                            </Link>
                          </li>
                          <li className="">
                            <Link style={multiLevel}>
                              {props.t("Concentrado saldos")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/providers-operations"
                              style={multiLevel}
                            >
                              {props.t("Operaciones por periodo")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/accounts-prov-statements"
                              style={multiLevel}
                            >
                              {props.t("Estados de cuenta")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/accounts-pay"
                              style={multiLevel}
                            >
                              {props.t("Cuentas por Pagar")}
                            </Link>
                          </li>
                          <li className="">
                            <Link style={multiLevel}>
                              {props.t("Pagos globales")}
                            </Link>
                          </li>
                          <li className="">
                            <Link style={multiLevel}>
                              {props.t("Pagos analíticos")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link className="has-arrow">
                          <span>{props.t("Almacén")}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="true">
                          <li>
                            <Link
                              to="/administrative/reports/inventory"
                              style={multiLevel}
                            >
                              {props.t("Valuación de Inventario")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span>{props.t("Gastos")}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="true">
                          <li>
                            <Link
                              to="/administrative/reports/costs-concentrate"
                              style={multiLevel}
                            >
                              {props.t("Concentrado de gastos")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/administrative/reports/costs-account-statements" style={multiLevel}>
                              {props.t("Estados de cuenta")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span>{props.t("Bancos")}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="true">
                          <li className="">
                            <Link to="/administrative/reports/banks-cash-flow" style={multiLevel}>
                              {props.t("Auxiliar")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/administrative/reports/accounts-bank-statements"
                              style={multiLevel}
                            >
                              {props.t("Estados de cuenta bancarios")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="d-none">
                        <Link to="/administrative/reports/sales">
                          {props.t("Ventas")}
                        </Link>
                      </li>
                      <li className="d-none">
                        <Link to="/administrative/reports/daily-movements">
                          {props.t("Movimientos diarios")}
                        </Link>
                      </li>
                      <li className="d-none">
                        <Link to="/administrative/reports/cash">
                          {props.t("Caja")}
                        </Link>
                      </li>
                      <li className="d-none">
                        <Link to="/administrative/reports/review">
                          {props.t("Revisión a cobro")}
                        </Link>
                      </li>
                      <li className="d-none">
                        <Link to="/administrative/reports/accounting-concentrates">
                          {props.t("Concentrados de cuentas contables")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            )}

            {userType == 2 && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-store"></i>
                  <span>{props.t("Proveedor")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/provider/daily">
                      {props.t("Disponibilidad productos")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/provider/orders">{props.t("Pedidos")}</Link>
                  </li>
                  <li>
                    <Link to="/provider/branches">{props.t("Sucursales")}</Link>
                  </li>
                </ul>
              </li>
            )}

            {userType == 3 && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-task"></i>
                  <span>{props.t("Pedidos")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/client/daily">{props.t("Hacer Pedido")}</Link>
                  </li>
                  <li>
                    <Link to="/client/orders">
                      {props.t("Histórico de Pedidos")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/client/deliveries">
                      {props.t("Direcciones de entrega")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
