import React from 'react';
import PropTypes from 'prop-types';

import { Table} from 'reactstrap';
import { OrderItem } from './OrderItem';
import { getClassNameOrderStatus } from 'helpers/order_helper';

function MovsSummary({ items }) {
    return (
        <>
            <div className="py-2 mt-3">
                <h3 className="font-size-14 fw-bold">Operaciones de venta</h3>
            </div>
            <div className="table-responsive">
                <Table className="table-nowrap table-bordered table-condensed">
                    <thead>
                        <tr className='table-light'>
                            <th style={{ width: "70px" }}>No.</th>
                            <th>Producto</th>
                            <th className="text-center">Pzas</th>
                            <th className="text-end">Kgs</th>
                            <th className="text-end">Prom</th>
                            <th className="text-end">Total Vta</th>
                            <th className="text-end">Nota cargo</th>
                            <th className="text-end">Nota crédito</th>
                            <th className="text-end">Venta Neta</th>
                            <th className="text-end">Merma</th>
                            <th className="text-end">Mortalidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map(({ id, product, weight, weight_max, quantity, unit_price, total }) => (
                                <OrderItem 
                                    key={ id }
                                    id={ id }
                                    peso={ (Number(weight) + Number(weight_max)) / 2 }
                                    product={ product }
                                    quantity={ quantity }
                                    unit_price={ unit_price }
                                    total={ total }
                                />
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

MovsSummary.propTypes = {
    items: PropTypes.any
};

export default MovsSummary;
