import React from "react"
import PropTypes from "prop-types"

const nf = new Intl.NumberFormat("es-MX", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const CurrencyFormat = ({ value, prefix = "" }) => {
  if (value == "" || value == null)
    return (
      <span>
        {prefix}{0}
      </span>
    )
  return (
    <span>
      {prefix}{nf.format(value)}
    </span>
  )
}

CurrencyFormat.propTypes = {
    value: PropTypes.any,
    prefix: PropTypes.string,
  }

export default CurrencyFormat
