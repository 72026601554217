import React from 'react';
import PropTypes from 'prop-types';

import Moment from 'react-moment';
import 'moment/locale/es';

function CommentItem({ user, comment, created_at }) {

    return (
        <div className="media py-3 border-top">
            <div className="avatar-xs me-3">
                <div className="avatar-title rounded-circle bg-light text-primary">
                    <i className="bx bxs-user"></i>
                </div>
            </div>
            <div className="media-body">
                <h5 className="font-size-14 mb-1">
                { user }{" "}
                    <small className="text-muted float-end">                    
                        <Moment locale="es" format="DD MMMM YYYY" date={ created_at } />
                    </small>                    
                </h5>
                <p className="text-muted">
                    { comment }                
                </p>
            </div>
        </div>
    )
}

CommentItem.propTypes = {
    user: PropTypes.string,
    comment: PropTypes.string,
    created_at: PropTypes.any
};
  
export default CommentItem;