import moment from "moment"
import * as Yup from "yup"
import { es } from "yup-locales"

Yup.setLocale(es)

export const costFormInitialValues = {
  date: moment().format("YYYY-MM-DD"),
  invoice: "",
  folio: "",
  c_provider_id: null,
  account_id: null,
  c_bank_id: null,
  amount: 0,
  payment: 0,
  iva: 0,
  total: 0,
  observations: "",
  payment_type: "",
  bank_folio: "",
  movs_costs_sales: [],
  total_imports: 0,
}

export const gastoValidation = Yup.object({
  date: Yup.date().required(),
  folio: Yup.string().required(),
  amount: Yup.number()
    .moreThan(0)
    .required()
    .typeError("el valor debe ser numérico")
    .label("Importe"),
  invoice: Yup.string().nullable(),
  c_provider_id: Yup.string().nullable(),
  account_id: Yup.string().nullable(),

  iva: Yup.number().min(0).required().typeError("el valor debe ser numérico"),
  total: Yup.number()
    .moreThan(0)
    .required()
    .typeError("el valor debe ser numérico")
    .label("Total"),
  observations: Yup.string().nullable(),
})

export const cobroValidation = Yup.object({
  account_id: Yup.string().required().label("Cuenta contable"),
  date: Yup.date().required(),
  folio: Yup.string().required(),
  payment: Yup.number()
    .moreThan(0)
    .required()
    .typeError("el valor debe ser numérico")
    .label("Importe de pago"),
  payment_type: Yup.string().nullable(),
  c_bank_id: Yup.string().nullable(),
  bank_folio: Yup.string().nullable(),
  observations: Yup.string().nullable(),
  movs_costs_sales: Yup.array().of(
    Yup.object().shape({
      import: Yup.number()
        .moreThan(0)
        .typeError("el valor debe ser numérico")
        .label("Importe"),
    })
  ),
  total_imports: Yup.number().when("movs_costs_sales", {
    is: movs => movs && movs.length > 0,
    then: schema =>
      schema.test(
        "total-imports",
        "La suma de los importes no coincide con el importe de pago",
        function (value) {
          return value === this.parent.payment
        }
      ),
  }),
})
