import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Table, Row, Col} from 'reactstrap';
import { OrderItem } from './OrderItem';
import _ from 'lodash';
import RatingCustom from 'components/Common/RatingCustom';

function OrderSummary({ dailys }) {
    const user = JSON.parse(localStorage.getItem("authUser"))
    return (
        <>
            <div className="py-2 mt-3">
                <h3 className="font-size-14 fw-bold">Resumen de pedido {dailys[0].date}</h3>
            </div>
            {(dailys || []).map(daily => (
                <Row key={daily.id}>
                    <Col lg={12}>
                      <div className="bg-dark bg-soft text-dark pt-1 pb-1 mb-1 text-center"><b>{daily.product}</b></div>
                    </Col>
                    <Col lg={4}>
                      <div>Cantidad del dia: <b>{daily.quantity} Pzas</b></div>
                      <div>Peso Promedio: <b>{daily.weight} - {daily.weight_max} Kg.</b></div>
                      <div>Observaciones: <b>{daily.observations}</b></div><br/>
                    </Col>
                    <Col lg={4}>
                        <Row>
                            <Col lg={6}>
                            Pigmento:
                            </Col>
                            <Col lg={6}>
                            <RatingCustom
                                max={3}
                                disabled={true}
                                defaultRating={daily.quality}
                                styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                                ActiveComponent={
                                <i
                                    className="mdi mdi-star text-warning"
                                    style={{}}
                                />
                                }
                                InActiveComponent={
                                <i
                                    className="mdi mdi-star-outline text-muted"
                                    style={{}}
                                />
                                }
                            />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                            Calidad:
                            </Col>
                            <Col lg={6}>
                            <RatingCustom
                                max={3}
                                disabled={true}
                                defaultRating={daily.quality_2}
                                styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                                ActiveComponent={
                                <i
                                    className="mdi mdi-star text-warning"
                                    style={{}}
                                />
                                }
                                InActiveComponent={
                                <i
                                    className="mdi mdi-star-outline text-muted"
                                    style={{}}
                                />
                                }
                            />{" "}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                            Salud del Ave:
                            </Col>
                            <Col lg={6}>
                            <RatingCustom
                                max={3}
                                disabled={true}
                                defaultRating={daily.quality_3}
                                styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                                ActiveComponent={
                                <i
                                    className="mdi mdi-star text-warning"
                                    style={{}}
                                />
                                }
                                InActiveComponent={
                                <i
                                    className="mdi mdi-star-outline text-muted"
                                    style={{}}
                                />
                                }
                            />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4}>
                        <div>{daily.prices.map( price => (
                            <div key={price.id}>
                                {price.region} {price.subregion ? price.subregion:''} <b>${price.price ? price.price : price.price_shipped}</b>
                            </div>
                        ))}</div>
                    </Col>
                      
                    <Col lg={12}>
                        <div className="table-responsive">
                            <Table className="table table-sm table-nowrap table-striped">
                                <thead className="table-light">
                                    <tr className="table-primary">
                                        <th style={{ width: "70px" }}>Camión</th>
                                        <th>Cantidad Original</th>
                                        <th>Cantidad Solicitada</th>
                                        <th>Logística de entrega</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.sortBy(_.filter(daily.quantities, {status:'authorized'}),['sort']).map(({ id, truck_name, truck_units, order_items_trucks, status, status_string }) => (
                                        <OrderItem
                                            key={ id }
                                            id={ id }
                                            truck_name={ truck_name }
                                            truck_units={ truck_units }
                                            order_items_trucks={order_items_trucks}
                                            status={status}
                                            status_string={status_string}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            ))}
        </>
    )
}

OrderSummary.propTypes = {
    dailys: PropTypes.array
};

export default OrderSummary;
