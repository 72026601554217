import React from 'react';
import PropTypes from 'prop-types';
import ConfigurationsForm from './ConfigurationsForm';

export const TypeForm = ({ toggleRightCanvas, isNew,setConfigs,configs,getDataConf}) => {

    return  <ConfigurationsForm 
        isNew={ isNew }
        toggleRightCanvas={ toggleRightCanvas } 
        setConfigs={setConfigs}
        configs={configs}
        getDataConf={getDataConf}
    />;    
}

TypeForm.propTypes = {
    toggleRightCanvas: PropTypes.func,
    isNew: PropTypes.bool,
    configs: PropTypes.object,
    setConfigs: PropTypes.func,
    getDataConf: PropTypes.func
}
