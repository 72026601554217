import React, { useEffect, useState } from 'react';

import { 
    Card,
    CardBody
} from 'reactstrap';

import FullSpinner from 'components/Common/FullSpinner';
import PageBase from 'components/Common/PageBase';
import Filters from './Filters';
import HeaderReport from './HeaderReport';
import TableReport from './TableReport';

import useRequest from 'hooks/useRequest';

import '../../../../assets/scss/reports/daily-movements.scss';
import moment from 'moment';
import { getAccountingAcounts } from 'store/accounting-accounts/actions';
import { useDispatch } from 'react-redux';
import { getAccountsReceivableReport } from '../../../../helpers/backend_helper';

const initialPeriod = moment().startOf('month').format('YYYY-MM-DD') + '/' + moment().endOf('month').format('YYYY-MM-DD');

const breadcrumbList = [
    { id: '1', title: 'Administrativo', active: false },
    { id: '2', title: 'Reportes', active: false },
    { id: '3', title: 'Cuentas por cobrar por remisión', active: true }
];

export default function OperationsClientContainer() {

    const dispatch = useDispatch();
    const [ reportType, setReportType ] = useState('DAILY');
    const [ period, setPeriod ] = useState(initialPeriod);
    const [ columnOrder, setColumnOrder ] = useState({
        column: '',
        order: ''
    });

    const {
        column,
        order
    } = columnOrder;

    const {
        data,
        isLoading,
        req
    } = useRequest();

    const handleFilters = ({ periodo, cliente, documentType}) => {
        const config = {
            params: {
                period: periodo,
                cliente,
                column,
                order,
                documentType
            }
        };
        //if(cliente)
        req(getAccountsReceivableReport(config));
    }

    useEffect(() => {
        
        dispatch(getAccountingAcounts());
    }, []);
    
    return (
        <>  
            { isLoading && <FullSpinner /> }
            <PageBase
                titleMeta="Reporte de Cuentas por cobrar por remisión"
                titleBread="Reporte de Cuentas por cobrar por remisión"
                itemsBread={ breadcrumbList }
            >
                <Card className='mb-1'>
                    <CardBody>
                        <Filters
                            data={ data }
                            handleFilters={ handleFilters }
                            setPeriod={ setPeriod }
                            columnOrder={ columnOrder }
                            period={period}
                        />
                    </CardBody>
                </Card>                
                <Card>
                    <CardBody>                  
                        { data && data.report.length  ? (
                            <>
                                <HeaderReport 
                                    period={ period }
                                    balanceFinal={ data.balance_final }
                                    accountName={data.account_name}
                                />
                                <TableReport 
                                    balanceInitial={data.balance_initial}
                                    
                                    data={ data.report } 
                                    totals={ data.totals }
                                    setColumnOrder={ setColumnOrder }
                                    columnOrder={ columnOrder }

                                />
                            </>
                        ) : <p>No hay datos con el periodo seleccionado</p>}
                    </CardBody>
                </Card>
            </PageBase>
        </>
    );
}