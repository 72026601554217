import React from "react"
import PropTypes from "prop-types"
import { Button, Col, Row } from "reactstrap"
import PreviewBtn from "./PreviewBtn"

const FormActions = ({ validation, toggleClose, isStampingCfdi }) => {
  return (
    <div className="py-4">
      <Row>
        <Col className="d-flex justify-content-end">
          <Button
            onClick={toggleClose}
            color="secondary"
            className="btn btn-secondary btn me-2"
            disabled={isStampingCfdi}
          >
            Cancelar
          </Button>
          <PreviewBtn validation={validation} />
          <Button
            color="primary"
            className="btn btn-danger btn"
            type="submit"
            disabled={isStampingCfdi}
          >
            <i
              className={`${
                isStampingCfdi ? "" : "d-none"
              } bx bx-loader bx-spin font-size-16 align-middle me-2`}
            ></i>
            Facturar
          </Button>
        </Col>
      </Row>
    </div>
  )
}

FormActions.propTypes = {
  validation: PropTypes.object,
  toggleClose: PropTypes.func,
  isStampingCfdi: PropTypes.bool,
}

export default FormActions
