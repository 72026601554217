import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import moment from 'moment';

import { 
    Col, 
    DropdownItem, 
    DropdownMenu, 
    DropdownToggle, 
    FormGroup, 
    InputGroup, 
    Label, 
    Row,
    UncontrolledDropdown
} from 'reactstrap';
import BtnDownloadExcel from '../common/BtnDownloadExcel';

import BtnDownloadPdf from '../common/BtnDownloadPdf';
import { monthsFilterValues } from 'common/data/months';
import MonthButton from 'pages/Administrative/common/MonthButton';
import Select from 'react-select';
import { useForm } from 'hooks/useForm';
import { getProvidersOperationReport } from '../../../../helpers/backend_helper';
import { useSelector } from 'react-redux';


const header_excel = {
    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

const header_pdf = {
    'Accept': 'application/pdf'
};

const initialPeriod = moment().startOf('month').format('YYYY-MM-DD') + '/' + moment().endOf('month').format('YYYY-MM-DD');

const initForm = {
    periodo: initialPeriod,
    providers: [],
    reporte: {'value': 'DAILY', 'label': 'Diario'}
}

const curYear = moment().year()
const optionsYears = Array(8).fill(0).map((_, i) => { return {id: curYear - i, name: curYear - i} });

export default function Filters({ data, handleFilters, setPeriod, columnOrder, period, setReportType }) {

    const providerAccounts = useSelector(state => state.AccountingAccounts.providerAccounts);
    const reportsOptions = [{'value': 'MONTHLY', 'label': 'Mensual'}, {'value': 'DAILY', 'label': 'Diario'}];
    const {
        column,
        order
    } = columnOrder;

    const {
        form,
        handleSpecificInput,
        handleDatePeriod
    } = useForm(initForm);

    const providersIds = form.providers.map(provider => provider.id).join();

    const downloadFile = (type = 'excel') => getProvidersOperationReport ({
        params: {
            period: form.periodo,
            reporte: form.reporte.value,
            providers: providersIds,
            column,
            order
        },
        headers: (type === 'excel') ? header_excel : header_pdf
    });

    useEffect(() => {
        handleFilters({
            ...form,
            providers: providersIds,
            reporte: form.reporte?.value
        })
    }, [ form.reporte, form.periodo, form.providers, column, order ]);

    const [ year, setYear ] = useState({id: curYear, name: curYear});
    const handleSelectYear = (year) => {
        const dates = period.split('/')
        const start = moment(dates[0])
        const end = moment(dates[1])
        start.year(year)
        end.year(year)
        const parseDates = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
        handleSpecificInput({
            key: 'periodo',
            value: parseDates
        });
        setPeriod(parseDates)
    }

    return (
        <Row>
            <Col lg={2}>
                <div className="mb-3">
                    <label className="control-label">
                        Reporte
                    </label>
                    <Select
                        value={form.reporte}
                        onChange={(value) => { 
                            handleSpecificInput({
                                key: 'reporte',
                                value
                            });
                            setReportType(value.value)
                        }}
                        placeholder="Seleccionar"
                        options={reportsOptions}
                        classNamePrefix="select2-selection"
                    />
                </div>
            </Col>            
            <Col lg={4}>
                <FormGroup className="mb-4">
                    <Label>Periodo</Label>
                    <InputGroup>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder=""
                        onChange={ dates => handleDatePeriod({
                            key: 'periodo',
                            dates
                        })}
                        options={{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                            locale: Spanish,
                            defaultDate: initialPeriod.split('/')
                        }}
                    />
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col lg={4}>
                <div className="mb-3">
                    <label className="control-label">
                        Proveedores
                    </label>
                    <Select
                        value={form.providers}
                        isMulti={true}
                        onChange={(value) => handleSpecificInput({
                            key: 'providers',
                            value
                        })}
                        getOptionLabel={ option => option.name}
                        getOptionValue={ option => option.id }
                        placeholder="Seleccionar"
                        options={providerAccounts}
                        classNamePrefix="select2-selection"
                    />
                </div>
            </Col>
            <Col className="text-right">
            { (data && data.report.length) ? (
                    <>
                        <BtnDownloadExcel downloadExcel={ downloadFile }/>
                        <BtnDownloadPdf downloadPdf={ downloadFile }/>
                    </>
                ) : ''}
            </Col>
            <Col md={12}>
                <UncontrolledDropdown
                    style={{ cursor: 'pointer' }}
                    className="d-inline-block me-1 pt-0 mt-0"
                >
                    <DropdownToggle
                        href="#"
                        className="card-drop action-points"
                        tag="i"
                    > 
                        <button className='btn btn-sm btn-danger'>{ year.name } <i className="mdi mdi-chevron-down"></i>
                        </button>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-enddd actions-menu">
                    { optionsYears.map( (val, key) => { return (
                            <DropdownItem
                                key={key}
                                href="#"
                                onClick={ e => {
                                    e.preventDefault()
                                    setYear({
                                        id: val.id,
                                        name: val.name
                                    })
                                    handleSelectYear(val.id)
                                }}
                            >
                                { val.name }
                            </DropdownItem>
                        )})
                    }
                            
                    </DropdownMenu>
                </UncontrolledDropdown>
                {
                    monthsFilterValues.map( (val, key) => {
                        return (<MonthButton
                            key={key} 
                            month={key}
                            monthString={val}
                            year={year.id}
                            handler={ periodo => {
                                handleSpecificInput({
                                    key: 'periodo',
                                    value: periodo
                                });
                                setPeriod(periodo)
                            }}
                        />)
                    })
                }
            </Col>
        </Row>
    );
}

Filters.propTypes = {
    data: PropTypes.any,
    handleFilters: PropTypes.func,
    setPeriod: PropTypes.func,
    columnOrder: PropTypes.object,
    period: PropTypes.string,
    setReportType: PropTypes.func,
};