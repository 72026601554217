import React from "react"
import PropTypes from 'prop-types'
import { Col, Row, Table } from "reactstrap"
import { useState } from "react"
import RatingCustom from "components/Common/RatingCustom"
import _ from "lodash"
const starStyle = {}
const numberFormat = new Intl.NumberFormat('es-MX', { minimumFractionDigits: 2 });


const Resume = props => {
  const [popover, setpopover] = useState({});
  //const [state, setState] = useState([...props.rows])
  const state = props.rows;
  const orders = props.orders;
  //const ordersParent = props.orders;
  //const [orders, setOrders] = useState(props.orders)
  const [values, setValues] = useState({
    provider_id: null,
    products: {},
    timeDelivery: '',
  })
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)

  console.log('state',state, 'orders', orders)
  const grouped = _.groupBy(orders, 'client_id');
  return (
    <React.Fragment>
  { Object.keys(grouped).map( (entry, key) => (
    <React.Fragment key={key}>
    <div className="bg-dark text-light pt-1 pb-1 mb-1 text-center"><b>{grouped[entry][0].client.company_name}</b></div>
    <Row style={{paddingTop:'15px'}} key={key}>
        <Col lg={12} className="mb-3">
          <Table className="table table-sm">
            <thead className="">
              <tr className="bg-dark bg-soft">
                <th>Proveedor - Producto</th>
                <th>Entrega</th>
                <th>Calidad</th>
                <th>Peso Prom.</th>
                <th>Pedido</th>
                <th>Precio unitario</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
            { _.sortBy(grouped[entry], ['client_id','provider_id']).map((order, key2) => (
              <React.Fragment key={key}>
              {(order.items || []).map((formDaily, key) => (
                <tr key={key}>
                  <td>
                    <b>{ _.find(state, {farm_id: order.farm_id}).provider.company_name }</b> <br/>
                    <b>{formDaily.product}</b>
                  </td>
                  <td>
                    { formDaily.delivered ? 
                    <div>En {formDaily.delivered_region} {formDaily.delivered_subregion} <br /> el {formDaily.delivery_date}</div> :
                    <div>En sucursal</div>
                    }
                    <span className={formDaily.status=='requested' ? 'badge rounded-pill bg-warning': (formDaily.status=='completed' ? 'badge rounded-pill bg-success' : 'badge rounded-pill bg-danger')}>{formDaily.status_string}</span>
                  </td>
                  <td>
                    <Row>
                      <Col lg={6}>
                        Pigmento:
                      </Col>
                      <Col lg={6}>
                        <RatingCustom
                          max={3}
                          disabled={true}
                          defaultRating={formDaily.quality}
                          styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                          ActiveComponent={
                            <i
                              className="mdi mdi-star text-warning"
                              style={{}}
                            />
                          }
                          InActiveComponent={
                            <i
                              className="mdi mdi-star-outline text-muted"
                              style={{}}
                            />
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        Calidad:
                      </Col>
                      <Col lg={6}>
                        <RatingCustom
                          max={3}
                          disabled={true}
                          defaultRating={formDaily.quality_2}
                          styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                          ActiveComponent={
                            <i
                              className="mdi mdi-star text-warning"
                              style={{}}
                            />
                          }
                          InActiveComponent={
                            <i
                              className="mdi mdi-star-outline text-muted"
                              style={{}}
                            />
                          }
                        />{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        Salud del Ave:
                      </Col>
                      <Col lg={6}>
                        <RatingCustom
                          max={3}
                          disabled={true}
                          defaultRating={formDaily.quality_3}
                          styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                          ActiveComponent={
                            <i
                              className="mdi mdi-star text-warning"
                              style={{}}
                            />
                          }
                          InActiveComponent={
                            <i
                              className="mdi mdi-star-outline text-muted"
                              style={{}}
                            />
                          }
                        />
                      </Col>
                    </Row>
                  </td>
                  <td>{formDaily.weight_prom} Kg.</td>
                  <td>
                    {formDaily.quantity} {formDaily.unit}<br /><br />
                    <b>Camión</b><br />
                    {(formDaily.trucks ? formDaily.trucks:[]).map((truck, key2) => (
                      <div key={'a'+key2} className="mb-2">
                        {truck.truck_name} : {truck.quantity} {formDaily.unit}
                      </div>
                    ))}
                  </td>
                  <td className="text-right">
                    ${ numberFormat.format(formDaily.delivered ? formDaily.unit_price_shipped : formDaily.unit_price) }
                  </td>
                  <td className="text-right">
                    <b>${ numberFormat.format(formDaily.total) }</b>
                  </td>
                </tr>
              ))}
              </React.Fragment>
            ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  ))}
  </React.Fragment>
)}

Resume.propTypes = {
  rows: PropTypes.array,
  orders: PropTypes.array,
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  date: PropTypes.string.isRequired,
  toggleModalAuth: PropTypes.func.isRequired,
  handleAuthTruck: PropTypes.func.isRequired,
}

export default Resume
