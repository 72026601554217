import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment"

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 


export default function TableMovementItem({ item,multiple }) {

    const {
        row_period,
        total,
        decrease,
        discount,
        payment,
        net,
        account_name,
        other_charges
    } = item;

    const efectoDeuda = Number(total) + (Number(other_charges) ?? 0) - Number(decrease) - Number(discount) - Number(payment)

    return (
        <>
            <tr>
                <td className="text-end">
                    {row_period.length == 7 ? moment('01-' + row_period, 'DD-MM-YYYY').format('MMMM'):moment(row_period, 'DD-MM-YYYY').format('ddd')} {row_period.length == 7 ? moment('01-' + row_period, 'DD-MM-YYYY').format('YYYY') : row_period}
                </td>
                {multiple &&
                    <td className="text-end">
                        {account_name}
                    </td>
                }
                <td className="text-right">
                    { nf2.format(total) }
                </td>
                <td className="text-right">
                    { nf2.format(decrease) }
                </td>
                <td className="text-right">
                    { nf2.format(discount) }
                </td>
                <td className="text-right">
                    { nf2.format(other_charges ?? 0) }
                </td>
                <td className={"text-right "}>
                    { nf2.format(payment) }
                </td>
                <td className={"text-right " + (payment < 0 ? 'text-danger':'')}>
                    { nf2.format(efectoDeuda) }
                </td>
                <td className={"text-right "}>
                    { multiple ? '-':nf2.format(net) }
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object,
    multiple: PropTypes.bool
};