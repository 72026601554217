import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postComment } from 'helpers/backend_helper';
import { addCommentOrder } from 'store/orders/actions';

const INIT_FORM = {
    comment: '',
    order_id: ''
};

const INIT_UI = {
    isLoading: false,
    isError: '',
    isSend: false
};

export default function useOrderComment() {

    const dispatch = useDispatch();

    const { id : order_id } = useSelector(state => state.Orders.orderActive);

    const [form, setForm]     = useState(INIT_FORM); 
    const [formUi, setFormUi] = useState(INIT_UI);

    const handleChange = ({ target }) => {
        setForm(prev => ({
            ...prev,
            [target.name]: target.value
        }));
        setFormUi({...INIT_UI, isSend: false});
    }

    const handleSubmit = async (e) => {        
        e.preventDefault();
        
        try {
            setFormUi({...INIT_UI, isLoading: true});

            const comment = await postComment({...form, order_id});
            dispatch( addCommentOrder(comment) );

            setForm(INIT_FORM);
            setFormUi({...INIT_UI, isSend: true});

        } catch (error) {
            setFormUi({...INIT_UI, isError: error});
        }
    }

    return {
        handleChange,
        handleSubmit,
        form,
        formUi
    }
}