import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';

import {
    Modal,
    Alert,
    Spinner
} from 'reactstrap';


import './custom.scss';
import { getMovHistory } from 'helpers/backend_helper';
import useRequest from 'hooks/useRequest';
import { useState } from 'react';

const nf = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

function ModalPaymentProv({ isOpen, setOpenModal, row }) {

    const [ history, setHistory ] = useState(null);

    const {
        id
    } = row;

    const {
        msgError,
        isError,
        isLoading,
        req
    } = useRequest();

    const {
        activeTab,
        dataReports
    } = useSelector(state => state.AdministrativeData);

    const {
        url_history,
        columns_history
    } = dataReports[activeTab];

    return (
        <div>
            <Modal
                isOpen={ isOpen }
                toggle={() => {
                    setOpenModal(false);
                }}
                size="lg"
            >
                <form>                    
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Relación de Pagos
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        { isError && (
                            <Alert className="alert alert-danger">
                                { msgError }
                            </Alert>
                        )}
                        { isLoading && <Spinner /> }
                        
                        { row.movs_providers_payments?.length > 0 && (
                            <div className="col-lg-12">
                                <p>
                                    <b>Folio: </b>{row.folio} <br />
                                    <b>Factura: </b>{row.invoice} <br />
                                </p>
                                <h5>Pagos Realizados</h5>
                                <table className='table table-condensed table-bordered'>
                                    <thead>
                                        <tr>
                                        <th>Folio Pago</th>
                                        <th>Fecha Pago</th>
                                        <th>Importe</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th className='text-end'>Venta Neta</th>
                                            <th>${nf.format(row.net)}</th>
                                        </tr>
                                        { row.movs_providers_payments.map( e => (
                                            <tr key={e.id}>
                                                <td>{e.folio}</td>
                                                <td>{e.mov_date}</td>
                                                <td>${nf.format(e.pivot.import)}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <th></th>
                                            <th className='text-end'>Total Abonado</th>
                                            <th>${nf.format(row.net - row.pendingToPayment)}</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th className='text-end'>Por Pagar</th>
                                            <th>${nf.format(row.pendingToPayment)}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        { row.movs_providers_buys?.length > 0 && (
                            <div className="col-lg-12">
                                <p>
                                    <b>Folio Pago: </b>{row.folio} <br />
                                    <b>Cobro: </b> ${nf.format(row.payment)}
                                </p>
                                <h5>Abonos aplicados</h5>
                                <table className='table table-condensed table-bordered'>
                                    <thead>
                                        <tr>
                                        <th>Folio Venta</th>
                                        <th>Fecha</th>
                                        <th>Importe</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { row.movs_providers_buys.map( e => (
                                            <tr key={e.id}>
                                                <td>{e.folio}</td>
                                                <td>{e.mov_date}</td>
                                                <td>${nf.format(e.pivot.import)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Cerrar
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

ModalPaymentProv.propTypes = {
    isOpen: PropTypes.bool,
    setOpenModal: PropTypes.func,
    row: PropTypes.object
};

export default ModalPaymentProv;