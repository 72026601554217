import React, { useEffect, useState } from 'react';

import { 
    Card,
    CardBody
} from 'reactstrap';

import FullSpinner from 'components/Common/FullSpinner';
import PageBase from 'components/Common/PageBase';
import Filters from './Filters';
import HeaderReport from './HeaderReport';
import TableReport from './TableReport';
import OffCanvas from '../../common/OffCanvas';
import useRequest from 'hooks/useRequest';
import { getBillingReport,getDataConfig } from 'helpers/backend_helper';
import { TypeForm } from './Configurations/TypeForm';
import '../../../../assets/scss/reports/daily-movements.scss';
import * as moment from 'moment';
import { useHistory } from "react-router-dom"
import { getAccountingAcounts } from 'store/accounting-accounts/actions';
import { useDispatch } from 'react-redux';

const breadcrumbList = [
    { id: '1', title: 'Administrativo', active: false },
    { id: '2', title: 'Reportes', active: false },
    { id: '3', title: 'Cobranza', active: true }
];

const initialPeriod = moment().startOf('month').format('YYYY-MM-DD') + '/' + moment().endOf('month').format('YYYY-MM-DD');

export default function BillingContainer() {
    const dispatch = useDispatch();
    const [ isRight, setIsRight ] = useState(false);
    const [ isNew, setIsNew ] = useState(false);
    const [ configs, setConfigs ] = useState({});
    const [ period, setPeriod ] = useState(initialPeriod);
    const [ columnOrder, setColumnOrder ] = useState({
        column: '',
        order: ''
    });

    const {
        column,
        order
    } = columnOrder;

    const {
        data,
        isLoading,
        req
    } = useRequest();

    const config = {
        params: {
            period,
            column,
            order
        }
    };
    const getDataConf = () => {
        getDataConfig('billing').then(response => {
            setConfigs(response)
        })
    };
    const getData = () => req(getBillingReport(config));
    let history = useHistory()
    const getDetail = (client_id,bank=null) => {
        let parametros = config.params;
        parametros.cliente = client_id;
        parametros.banco = bank;

        history.push(
            {
           pathname: '/administrative/reports/billing-detail',
           search:  `?client=${client_id}&bank=${bank}&periodo=${period}`,
           state: { cliente: bank }
       })
            //"/administrative/reports/billing-detail",parametros)
      
    };
    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    }
    useEffect(() => {        
        getData();
    }, [ period, column, order ]);
    useEffect(() => {        
        getDataConf()
        dispatch(getAccountingAcounts());
    }, []);
    
    return (
        <>  
            { isLoading && <FullSpinner /> }
            <PageBase
                titleMeta="Reporte de cobranza"
                titleBread="Reporte de cobranza"
                itemsBread={ breadcrumbList }
            >
                <Card>
                    <CardBody>
                        <Filters 
                            data={ data } 
                            setPeriod={ setPeriod }
                            period={ period }
                            defaultPeriod={ initialPeriod }
                            columnOrder={ columnOrder }
                            toggleRightCanvas={toggleRightCanvas}
                            setIsNew={setIsNew}
                        />
                    </CardBody>
                </Card>                
                <Card style={{minHeight: '400px'}}>
                        <OffCanvas 
                            isNew={ isNew }
                            isRight={ isRight }
                            toggleRightCanvas={ toggleRightCanvas }
                            reportTitle={ 'Ajuste de Inventario' }
                        >
                            <TypeForm 
                                    isNew={ isNew }
                                    toggleRightCanvas={ toggleRightCanvas }
                                    configs={configs}
                                    setConfigs={setConfigs}
                                    getDataConf={getDataConf}
                                />
                        </OffCanvas>
                    <CardBody>                        
                        { data && data.report.length  ? (
                            <>
                                <HeaderReport period={ period } total={data.totals.total} end_date={data.end_date}/>
                                <TableReport 
                                    data={ data.report }
                                    totals={ data.totals }
                                    setColumnOrder={ setColumnOrder }
                                    columnOrder={ columnOrder }
                                    configs={data.columnsView}
                                    period={period}
                                />
                            </>
                        ) : <p>No hay datos con el periodo seleccionado</p>}
                    </CardBody>
                </Card>
            </PageBase>
        </>
    );
}