import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TypeForm } from './RightForms/TypeForm';

import FullSpinner from 'components/Common/FullSpinner';
import PageBase from 'components/Common/PageBase';
import Filters from './Filters';
import Tabs from '../common/Tabs';
import './custom.scss';
import DataTable from '../common/DataTable';
import OffCanvas from '../common/OffCanvas';
import { Card, CardBody } from 'reactstrap';
import { setActiveTabAdminiData, setTypeAdminiData } from 'store/catalogs/administrative-data/actions';
import { REPORTS, REPORT_TYPE } from 'store/catalogs/administrative-data/actionsTypes';
import ModalDelete from '../common/ModalDelete';
import { getAccountingAcounts } from 'store/accounting-accounts/actions';
import ModalHistory from '../common/ModalHistory';
import ModalPayment from '../common/ModalPayment';
import ModalPaymentProv from '../common/ModalPaymentProv';
import ModalRemsion from '../common/ModalRemision';
import ConfigurationsForm from './RightForms/ColumnsViewForm';
import InvoiceForm from './RightForms/Invoice/InvoiceForm';
import InvoiceList from './RightForms/InvoiceList/InvoiceList';

const breadcrumbList = [
    { id: '1', title: 'Administrativo', link: '', active: false },
    { id: '2', title: 'Ventas - Compras - Almacén', link: '', active: true }
];

export default function ClientProviderContainer() {

    const dispatch = useDispatch();

    const [isNew, setIsNew] = useState(false);
    const [modalRemision, setModalRemision] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalHistory, setModalHistory] = useState(false);
    const [modalPayment, setModalPayment] = useState(false);
    const [modalPaymentProv, setModalPaymentProv] = useState(false);
    const [isOpenColumnsCanvas, setIsOpenColumnsCanvas] = useState(false);
    const [isOpenNewInvoice, setIsOpenNewInvoice] = useState(false)
    const [isRight, setIsRight] = useState(false);
    const [dataRow, setDataRow] = useState({});
    const [isOpenInvoiceListCanvas, setIsOpenInvoiceListCanvas] = useState(false)

    const {
        activeTab,
        dataReports,
        ui: { isLoading }
    } = useSelector(state => state.AdministrativeData);

    const {
        clientAccounts
    } = useSelector(state => state.AccountingAccounts);

    const { title_singular } = dataReports[activeTab];

    const handlePaymentDetail = row => {
        setModalPayment(true);
        setDataRow(row);
    }

    const handlePaymentProvDetail = row => {
        setModalPaymentProv(true);
        setDataRow(row);
    }

    const handleEdit = row => {
        if (row?.deleted_at) return;

        setIsNew(false);
        setDataRow(row);
        setIsRight(true);
    }

    const handleDelete = row => {
        setModalDelete(prev => !prev);
        setDataRow(row);
    }

    const handleHistory = row => {
        setModalHistory(true);
        setDataRow(row);
    }

    const handleRemision = row => {
        setModalRemision(true);
        setDataRow(row);
    }

    const handleInvoice = row => {
        setIsOpenNewInvoice(true);
        setDataRow(row);
    }

    const handleInvoiceList = row => {
        setIsOpenInvoiceListCanvas(true);
        setDataRow(row);
    }

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    }

    const toggleColumnsCanvas = () => {
        setIsOpenColumnsCanvas(!isOpenColumnsCanvas)
    }

    const toggleNewInvoice = () => {
        setIsOpenNewInvoice(!isOpenNewInvoice)
    }

    const toggleInvoiceList = () => {
        setIsOpenInvoiceListCanvas(!isOpenInvoiceListCanvas)
    }

    useEffect(() => {
        dispatch(setActiveTabAdminiData(REPORTS.CLIENTS));
        dispatch(setTypeAdminiData(REPORT_TYPE.CLIENT_PROVIDERS));
    }, []);

    useEffect(() => {
        if (clientAccounts.length) return;

        dispatch(getAccountingAcounts());
    }, []);

    useEffect(() => {

        const escFunction = (event) => {
            console.log(event, (event.key), event.keyCode);
            if (event.code === "KeyI" && event.ctrlKey === true) {
                toggleRightCanvas()
            }
        };

        window.addEventListener('keydown', escFunction);
        return () => {
            console.log("UNMOUNT CLIENT PROVIDER")
            window.removeEventListener('keydown', escFunction);
            const bodyElement = document.querySelector("body")
            bodyElement.style.overflow = "auto"
        };

    }, []);

    return (
        <>
            {isLoading ? <FullSpinner /> : null}
            <PageBase
                titleMeta="Ventas - Compras - Almacén"
                titleBread="Ventas - Compras - Almacén"
                itemsBread={breadcrumbList}
            >
                <Card>
                    <CardBody>
                        <Filters
                            setIsNew={setIsNew}
                            toggleRightCanvas={toggleRightCanvas}
                            toggleColumnsCanvas={toggleColumnsCanvas}
                        />
                        <Tabs />
                        <DataTable
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleHistory={handleHistory}
                            handlePaymentDetail={handlePaymentDetail}
                            handlePaymentProvDetail={handlePaymentProvDetail}
                            handleRemision={handleRemision}
                            handleInvoice={handleInvoice}
                            handleInvoiceList={handleInvoiceList}
                        />
                        <OffCanvas
                            isNew={isNew}
                            isRight={isRight}
                            toggleRightCanvas={toggleRightCanvas}
                            reportTitle={title_singular}
                        >
                            <TypeForm
                                isNew={isNew}
                                activeTab={activeTab}
                                dataRow={dataRow}
                                toggleRightCanvas={toggleRightCanvas}
                            />
                        </OffCanvas>
                        <OffCanvas
                            isNew
                            isRight={isOpenColumnsCanvas}
                            toggleRightCanvas={toggleColumnsCanvas}
                            reportTitle="Ajuste de Columnas"
                        >
                            <ConfigurationsForm
                                isNew
                                toggleRightCanvas={toggleColumnsCanvas}
                            />
                        </OffCanvas>
                        <OffCanvas
                            isNew={null}
                            isRight={isOpenNewInvoice}
                            toggleRightCanvas={toggleNewInvoice}
                            reportTitle="Facturar venta"
                        >
                            <InvoiceForm
                                toggleRightCanvas={toggleNewInvoice}
                                dataRow={dataRow}
                            />
                        </OffCanvas>
                        <OffCanvas
                            isNew={null}
                            isRight={isOpenInvoiceListCanvas}
                            toggleRightCanvas={toggleInvoiceList}
                            reportTitle="Comprobantes fiscales"
                        >
                            <InvoiceList
                                toggleRightCanvas={toggleNewInvoice}
                                dataRow={dataRow}
                            />
                        </OffCanvas>
                        <ModalDelete
                            isOpen={modalDelete}
                            setOpenModal={setModalDelete}
                            row={dataRow}
                        />
                        {modalHistory && <ModalHistory
                            isOpen={modalHistory}
                            setOpenModal={setModalHistory}
                            row={dataRow}
                        />}

                        {modalPayment && <ModalPayment
                            isOpen={modalPayment}
                            setOpenModal={setModalPayment}
                            row={dataRow}
                        />}

                        {modalPaymentProv && <ModalPaymentProv
                            isOpen={modalPaymentProv}
                            setOpenModal={setModalPaymentProv}
                            row={dataRow}
                        />}

                        {modalRemision && <ModalRemsion
                            isOpen={modalRemision}
                            setOpenModal={setModalRemision}
                            row={dataRow}
                        />}
                    </CardBody>
                </Card>
            </PageBase>
        </>
    )
}

