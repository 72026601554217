import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';

import {
    Modal,
} from 'reactstrap';

    
import './custom.scss';
import { getClientDetail, getClientDetailByAccountingId, getMovByClient, getMovHistory, getSaleTicket } from 'helpers/backend_helper';
import useRequest from 'hooks/useRequest';
import { useState } from 'react';
import { NumeroALetras } from 'helpers/numberToLetras';
import { downloadFile } from 'helpers/files';
import ModalEmail from './ModalEmail';

function ModalRemsion({ isOpen, setOpenModal, row }) {

    const [ client, setClient ] = useState({});
    const [isDownloading, setIsDownloading] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [openEmailModal, setOpenEmailModal] = useState(false)

    const {
        id
    } = row;

    const {
        activeTab,
        dataReports
    } = useSelector(state => state.AdministrativeData);

    const onClickOpenEmailModal = () => {
        setOpenEmailModal(true)
    }

    const downloadSaleTicket = async () => {
        setIsDownloading(true)
        try {
            const config = {
                responseType: "blob",
            };
            
            const data = {};

            const file = await getSaleTicket(row.id, data, config)
            downloadFile(file, 'ticket.pdf')
        } catch (error) {
            console.log(error)
        } finally {
            setIsDownloading(false)
        }
    }
    
    const onSendEmail = async email => {
        setIsSending(true)
        try {
          const data = { email, sendEmail: true }
    
          await getSaleTicket(row.id, data)
    
          setOpenEmailModal(false)
        } catch (error) {
          console.log(error)
        } finally {
          setIsSending(false)
        }
    }

    useEffect(() => {
        getClientDetailByAccountingId( row.c_client_id ).then(resp => {
            console.log(resp);
            setClient(resp);
        });
    }, [ id ]);

    return (
        <div>
            <Modal
                isOpen={ isOpen }
                toggle={() => {
                    setOpenModal(false);
                }}
                size="lg"
            >
                <form>                    
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Remisión
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        <h2>MARCO ANTONIO LOPEZ CONTRERAS</h2>
                        <h6>RFC: LOCM740327AAM7</h6>
                        <h5>Régimen fiscal emisor</h5>
                        <h4>612 - Personsa Físicas con Actividades Empresariales y Profesionles</h4>
                        <h6>CERRADA CAPULIN No. 15 INFONAVIT AMALUCAN</h6>
                        <h6>PUEBLA, PUE C.P. 72310</h6>
                        <h4><i className='fa fa-phone'></i> 222 123 05 06 <i className='ms-5 fa fa-envelope'></i> apizacocresta@yahoo.com</h4>

                        <table className='table table-bordered mt-4 text-start'>
                            <tr>
                                <th>Nombre Cliente</th>
                                <td>{client?.company_name}</td>
                                <th  rowSpan={2}>Remisión</th>
                                <td rowSpan={2}>{row.rem ?? ''}</td>
                            </tr>
                            <tr>
                                <th>Código</th>
                                <td>{client?.accounting?.key_name}</td>
                            </tr>
                            <tr>
                                <th>Dirección</th>
                                <td>
                                    {client?.invoice_address?.street}
                                    {client?.invoice_address?.ext_num == 'S/N' ? ' s/n' : client?.invoice_address?.ext_num}
                                    {client?.invoice_address?.int_num == 'S/N' ? '' : client?.invoice_address?.int_num}
                                </td>
                                <th>Fecha de emisión: </th>
                                <td>{ row.mov_date }</td>
                            </tr>
                            <tr>
                                <th>Ciudad</th>
                                <td>{client?.invoice_address?.city}</td>
                                <th>Lugar de expedición:</th>
                                <td>72310 Puebla Pue.</td>
                            </tr>
                            <tr>
                                <th>C.P. del cliente</th>
                                <td>{client?.invoice_address?.cp}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Teléfono</th>
                                <td>{client?.phone ?? ''}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>

                        <table className='table table-bordered table-sm'>
                            <thead className='table-light'>
                                <tr>
                                    <th>Cantidad</th>
                                    <th>U.M.</th>
                                    <th>Clave prod.</th>
                                    <th>Descripción</th>
                                    <th>Valor Unitario</th>
                                    <th>Importe</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{row.kgs}</td>
                                    <td>KGs</td>
                                    <td></td>
                                    <td>{row.product}</td>
                                    <td>{row.pv}</td>
                                    <td className='text-end'>{row.total_vta}</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                            
                            <tfoot>
                                <tr>
                                    <th colSpan={4}>Importe con letra</th>
                                    <th>Subtotal</th>
                                    <th className='text-end'>{row.total_vta}</th>
                                </tr>
                                <tr>
                                    <th colSpan={4} rowSpan={2}>
                                        {NumeroALetras(row.total_vta)}
                                    </th>
                                    <th>IVA</th>
                                    <th className='text-end'>{row.iva ?? '0.00'}</th>
                                </tr>
                                <tr>
                                    <th>Total</th>
                                    <th className='text-end'>{Number(row.total_vta) + Number(row.iva)}</th>
                                </tr>
                            </tfoot>
                        </table>
                        <p className='text-start'>
                            Por el presente pagaré reconozco deber y tengo la obligación a pagar en cualquier parte,
                            que se me requiera de el pago a <b>MARCO ANTONIO LOPEZ CONTRERAS</b> a 
                            su orden el día ___________ la cantidad de $ {row.total_vta} valor recibido en mercancía.
                        </p>
                        <p className='mt-5'>
                            <div className='d-inline-block f-bold' style={{borderTop: '1px solid #000', minWidth: '200px'}}>Acepto</div>
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-dismiss="modal"
                            onClick={onClickOpenEmailModal}
                        >
                            <i className="mdi mdi-email" /> Enviar
                        </button>
                        <button
                            type="button"
                            onClick={downloadSaleTicket}
                            className="btn btn-primary"
                            disabled={isDownloading}
                        >
                            <i className={`${!isDownloading && 'd-none' } bx bx-loader bx-spin font-size-16 align-middle me-2`}></i>
                            {isDownloading ? 'Descargando...' : 'Descargar'}
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Cerrar
                        </button>
                    </div>
                </form>
            </Modal>
            <ModalEmail
                isOpen={openEmailModal}
                setOpenModal={setOpenEmailModal}
                onSendEmail={onSendEmail}
                isSending={isSending}
            />
        </div>
    )
}

ModalRemsion.propTypes = {
    isOpen: PropTypes.bool,
    setOpenModal: PropTypes.func,
    row: PropTypes.object
};

export default ModalRemsion;