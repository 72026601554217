import React from 'react';

export default function TableTotal() {
    return (
        <>
            <tr className="no-border-b">
                <td></td>
                <td></td>                        
                <td></td>
                <td></td>
                <td className="text-right f-bold">Total</td>
                <td className="text-right f-blue bg-gray">6,070.0000</td>
                <td className="bg-gray"></td>
                <td colSpan={2} className="text-center f-blue bg-gray">179,377.61</td>
                <td className="text-right bg-gray"></td>
            </tr>
        </>
    );
}
