import React from 'react';
import PropTypes from 'prop-types';
import CostContainerForm from './CostContainerForm';

export const TypeForm = ({ activeTab, setDataRow, dataRow, toggleRightCanvas, isNew, accounts,setData}) => {

    return  <CostContainerForm 
        dataRow={ dataRow }
        isNew={ isNew }
        toggleRightCanvas={ toggleRightCanvas } 
        accounts={accounts}
        setDataRow={setDataRow}
        setData={setData}
    />;    
}

TypeForm.propTypes = {
    activeTab: PropTypes.string,
    dataRow: PropTypes.object,
    toggleRightCanvas: PropTypes.func,
    isNew: PropTypes.bool,
    accounts:PropTypes.array,
    setDataRow: PropTypes.func,
    setData:PropTypes.func
}
