import React from "react"
import PropTypes from "prop-types"

import CurrencyFormat from "common/nibble/CurrencyFormat"

const ProductsTotals = ({ validation }) => {
  const iva = validation.values.Items.reduce((acc, current) => {
    const iva = current.Taxes.find(item => item.Name === "IVA")
    if (!iva) return acc

    return acc + iva.Total
  }, 0)

  const ivaRet = validation.values.Items.reduce((acc, current) => {
    const tax = current.Taxes.find(item => item.Name === "IVA RET")
    if (!tax) return acc

    return acc + tax.Total
  }, 0)

  const ieps = validation.values.Items.reduce((acc, current) => {
    const tax = current.Taxes.find(item => item.Name === "IEPS")
    if (!tax) return acc

    return acc + tax.Total
  }, 0)

  const isr = validation.values.Items.reduce((acc, current) => {
    const tax = current.Taxes.find(item => item.Name === "ISR")
    if (!tax) return acc

    return acc + tax.Total
  }, 0)

  const Subtotal = validation.values.Items.reduce(
    (acc, current) => acc + current.Subtotal,
    0
  )

  const Total = validation.values.Items.reduce(
    (acc, current) => acc + current.Total,
    0
  )

  return (
    <div className="d-flex justify-content-end gap-4">
      <p className="text-end">
        <strong>Subtotal</strong> <br />
        <CurrencyFormat value={Subtotal} />
      </p>
      {iva ? (
        <p className="text-end">
          <strong>IVA</strong> <br />
          <CurrencyFormat value={iva} />
        </p>
      ) : null}
      {ivaRet ? (
        <p className="text-end">
          <strong>IVA RET</strong> <br />
          <CurrencyFormat value={ivaRet} />
        </p>
      ) : null}
      {ieps ? (
        <p className="text-end">
          <strong>IEPS</strong> <br />
          <CurrencyFormat value={ieps} />
        </p>
      ) : null}
      {isr ? (
        <p className="text-end">
          <strong>ISR</strong> <br />
          <CurrencyFormat value={isr} />
        </p>
      ) : null}
      <p className="text-end">
        <strong>Total</strong> <br />
        <CurrencyFormat value={Total} />
      </p>
    </div>
  )
}

ProductsTotals.propTypes = {
  formValues: PropTypes.object,
  validation: PropTypes.object,
}

export default ProductsTotals
