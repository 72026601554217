import React from 'react';
import PropTypes from 'prop-types';

const nf = new Intl.NumberFormat('es-MX');

export default function TableTotal({ totals }) {

    const {
        pieces,
        kgs,
        cost_mn,
        total_vta,
        nota_cargo,
        nota_credito,
        ventas_netas,
        utilidad,
        porcentaje,
        decrease,
        mortalidad_descuento,
        mortalidad,
        mortalidad_percentaje
    } = totals;

    return (
        <>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-danger f-bold text-right">Totales</td>
                <td className="text-danger f-bold  text-right">
                    { pieces }
                </td>
                <td className="text-danger f-bold  text-right">
                    { kgs }
                </td>
                <td className="f-bold  text-right"></td>
                <td className="text-danger f-bold  text-right"></td>
                <td className="f-bold  text-right"></td>
                <td className="text-danger f-bold  text-right">
                    { cost_mn }
                </td>
                <td className="text-danger f-bold  text-right">
                    { total_vta }
                </td>
                <td className="text-danger f-bold  text-right">
                    { decrease }
                </td>
                <td className="text-danger f-bold  text-right">
                    { mortalidad_descuento }
                </td>
                <td className="text-danger f-bold  text-right">
                    { nota_credito }
                </td>
                <td className="text-danger f-bold  text-right">
                    { nota_cargo }
                </td>
                <td className="text-danger f-bold  text-right">
                    { ventas_netas }
                </td>
                <td className="text-danger f-bold  text-right">
                    { utilidad }
                </td>
                <td className="text-danger f-bold  text-right">
                    { porcentaje }
                </td>
                <td className="text-danger f-bold  text-right">
                    { mortalidad }
                </td>
                <td className="text-danger f-bold  text-right">
                    { mortalidad_percentaje }
                </td>
            </tr>
        </>
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object
};