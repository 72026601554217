import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

import { setActiveTabAdminiData, setAdministrativeFilters } from 'store/catalogs/administrative-data/actions';



const Tabs = ({ children }) => {

    const dispatch = useDispatch();

    const { 
        activeTab,
        typeReport,
        dataReports
    } = useSelector(state => state.AdministrativeData);

    const toggleCustom = tab => {
        dispatch( setActiveTabAdminiData(tab) );
    }

    useEffect(() => {
        dispatch( setAdministrativeFilters({ page: 1 }) );
    }, [ activeTab ]);

    return (
        <Row className='mt-2'>
            <Col>
                <Nav tabs className="nav-tabs-custom nav-justified">
                    {
                        Object.keys(dataReports).map( key => 
                            dataReports[key].type === typeReport ? (
                                <TabNavItem 
                                    key={ dataReports[key].id }
                                    title={ dataReports[key].title }
                                    slug={ key }
                                    activeTab={ activeTab }
                                    toggleCustom={ toggleCustom }
                                />
                            ) : ''
                        )
                    }
                </Nav>                        
                
                { children }
            </Col>
        </Row>
    )
}

Tabs.propTypes = {
    children: PropTypes.any
};

export default Tabs;

const TabNavItem = ({ activeTab, toggleCustom, title, slug }) => {

    const dispatch = useDispatch();

    const resetMostrar = () => {
        dispatch( 
            setAdministrativeFilters({
                mostrar: { id: 1, name: 'Todo' }
            })
        );
    }

    return (
        <NavItem>
            <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                    active: activeTab === slug,
                })}
                onClick={() => {
                    toggleCustom(slug)
                    resetMostrar()
                    console.log('click')
                }}
            >
                <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">{ title }</span>
            </NavLink>
        </NavItem>
    )
}

TabNavItem.propTypes = {
    activeTab: PropTypes.string,
    toggleCustom: PropTypes.func,
    slug: PropTypes.string,
    title: PropTypes.string
};