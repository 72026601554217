import React from 'react';

import { 
    Card,
    CardBody
} from 'reactstrap';

import FullSpinner from 'components/Common/FullSpinner';
import PageBase from 'components/Common/PageBase';
import Filters from './Filters';
import HeaderReport from './HeaderReport';
import TableReport from './TableReport';

import '../../../../assets/scss/reports.scss';

const isLoading = false;

const breadcrumbList = [
    { id: '1', title: 'Administrativo', active: false },
    { id: '2', title: 'Reportes', active: false },
    { id: '3', title: 'Ventas', active: true }
];

export default function CashContainer() {

    return (
        <>  
            { isLoading ? <FullSpinner /> : null }
            <PageBase
                titleMeta="Clientes y Proveedores"
                titleBread="Clientes y Proveedores"
                itemsBread={ breadcrumbList }
            >
                <Card>
                    <CardBody>
                        <Filters />
                    </CardBody>
                </Card>                
                <Card>
                    <CardBody>
                        <HeaderReport />
                        <TableReport />
                    </CardBody>
                </Card>
            </PageBase>
        </>
    );
}