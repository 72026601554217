import { getDistribuitorDashboard } from "helpers/backend_helper";
import React, { Component, useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Container, Media, Row,
} from "reactstrap"
import Earning from "./earning";
import Expenses from './expenses';
import BreadcrumbNav from '../../../components/Common/BreadcrumbNav';

const DashboardDistribuitor = props => {
  
  const getData = (obj) => {
    return {
      reports: [
        {
          title: 'Pedidos del Día',
          description: obj ? obj.orders : 0,
          iconClass: "bx-copy-alt"
        },
        {
          title: 'Proveedores (inactivos/activos)',
          description: obj ? obj.providers.active + '/' + obj.providers.inactive : 0,
          iconClass: "bx-user"
        },
        {
          title: 'Clientes (inactivos/activos)',
          description: obj ? obj.clients.active + '/' + obj.clients.inactive : 0,
          iconClass: "bx-group"
        }
      ]
    }
  };

  const [dashboard, setDashboard] = useState(getData())
  const [providers, setProviders] = useState([])
  const [loading, setLoading] = useState (false);

  useEffect(() => {

    async function getDataServer()  {

      const config = {
        params: {
          week: 1
        }
      }
      setLoading(true);
      const dataDashboard = await getDistribuitorDashboard(config);
      setLoading(false);
      console.log(dataDashboard);
      setDashboard(getData(dataDashboard.counters))
      setProviders(dataDashboard.providers)
    }

    getDataServer();
  }, [1]);

  const breadcrumbItems = [
    { id:'1', title: 'Dashboard', link: '', active: true },
  ];
  

  return (
    <React.Fragment>
      <BreadcrumbNav title="Resumen"  items={breadcrumbItems}/>
      <Row className="mt-2">
          {/* Reports Render */}
          {dashboard.reports.map((report, key) => (
            <Col md="4" key={"_col_" + key}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Media>
                    <Media body>
                      <p className="text-muted fw-medium">
                        {report.title}
                      </p>
                      <h4 className="mb-0">{report.description}</h4>
                    </Media>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-danger">
                        <i
                          className={
                            "bx " + report.iconClass + " font-size-24"
                          }
                        ></i>
                      </span>
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Earning />
        </Row>
        <Row>
          <Expenses providers={ providers } />
        </Row>
    </React.Fragment>
  );
}

export default DashboardDistribuitor;
