import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { Button, Col, Form, Modal, Row } from "reactstrap"

import Taxes from "common/sat/Taxes"
import useSatUnits from "hooks/useSatUnits"
import useSatProducts from "hooks/useSatProducts"
import { getTaxesCalculated } from "helpers/sat"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import { productValidation as validationSchema } from "./static"

import ProductTotals from "./ProductTotals"
import ProductFinder from "./ProductFinder"
import { NumberFixed } from "helpers/numbers"

const ProductModal = ({ isOpen, productRow, toggleModal, validation }) => {
  const [showFinder, setShowFinder] = useState(false)

  const { satUnits, fetchSatUnits } = useSatUnits(productRow.UnitCode)
  const { satProducts, fetchSatProducts } = useSatProducts(
    productRow.ProductCode
  )

  const finderToggle = () => {
    setShowFinder(!showFinder)
  }

  const onSubmit = formValues => {
    const newItem = {
      ...formValues,
      IsEditable: true,
    }

    /* if (["01", "03", "04"].includes(newItem.TaxObject)) {
      delete newItem.Taxes
    } */

    if (productRow.Id) {
      const Items = validation.values.Items.map(item =>
        item.Id === newItem.Id ? newItem : item
      )

      validation.setFieldValue("Items", Items)
    } else {
      newItem.Id = new Date().getTime()

      const Items = [...validation.values.Items, newItem]

      validation.setFieldValue("Items", Items)
    }

    toggleModal()
  }

  const productValidation = useFormik({
    enableReinitialize: true,
    initialValues: productRow,
    validationSchema,
    onSubmit,
  })

  const { values: formValues, errors: formErrors } = productValidation

  const calculateSubtotal = () => {
    let Subtotal = 0

    if (!formErrors?.Quantity && !formErrors?.UnitPrice) {
      Subtotal = Number(formValues.Quantity) * formValues.UnitPrice
      Subtotal = NumberFixed(Subtotal)
    }

    productValidation.setFieldValue("Subtotal", Subtotal)
  }

  const calculateTaxes = () => {
    if (formErrors.Discount || formErrors.Subtotal) return

    const { Total, Taxes } = getTaxesCalculated(
      formValues.Subtotal,
      formValues.Discount,
      formValues.tax_configuration
    )

    productValidation.setFieldValue("Total", Total)
    productValidation.setFieldValue("Taxes", Taxes)
  }

  useEffect(calculateSubtotal, [
    formValues.Quantity,
    formValues.UnitPrice,
    formErrors,
  ])

  useEffect(calculateTaxes, [
    formValues.Id, // ensure calculation
    formValues.Discount,
    formValues.Subtotal,
    formValues.tax_configuration,
    formErrors.formErrors,
  ])

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop="static">
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {!productRow?.id ? "Agregar Producto" : "Editar Producto"}
        </h5>
        <button
          type="button"
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            productValidation.handleSubmit()
            return false
          }}
          id="create-form-product"
        >
          <Row>
            <Col md={12} className="d-flex justify-content-end">
              <Button
                type="button"
                color="primary"
                size="sm"
                title="Buscar producto en el catálogo"
                onClick={finderToggle}
              >
                <i className="bx bx-search-alt-2 font-size-16 align-middle"></i>
              </Button>
            </Col>
          </Row>
          {showFinder ? (
            <ProductFinder
              fetchSatUnits={fetchSatUnits}
              fetchSatProducts={fetchSatProducts}
              productValidation={productValidation}
            />
          ) : null}
          <Row>
            <NibbleBaseInput
              fieldType="text"
              fieldName="Description"
              label="Producto"
              validation={productValidation}
              md={12}
            />
            <NibbleBaseInput
              fieldType="select"
              fieldName="UnitCode"
              label="Unidad SAT"
              placeHolder="Seleccionar"
              selectItems={satUnits.map(item => ({
                ...item,
                id: item.code,
              }))}
              validation={productValidation}
              md={6}
              selectOptionLabel={option =>
                `${option.code} - ${option.name} ${
                  option.symbol ? `(${option.symbol})` : ""
                }`
              }
              onInputChange={search => {
                if (!search) return
                fetchSatUnits(search)
              }}
            />
            <NibbleBaseInput
              fieldType="select"
              fieldName="ProductCode"
              label="Código SAT"
              placeHolder="Seleccionar"
              selectItems={satProducts.map(item => ({
                ...item,
                id: item.code,
              }))}
              validation={productValidation}
              md={6}
              selectOptionValue={option => option.code}
              selectOptionLabel={option =>
                `${option.code} - ${option.description}`
              }
              onInputChange={search => {
                if (!search) return
                fetchSatProducts(search)
              }}
            />
            <NibbleBaseInput
              fieldType="number"
              fieldName="Quantity"
              label="Cantidad"
              validation={productValidation}
              md={4}
            />
            <NibbleBaseInput
              fieldType="number"
              fieldName="UnitPrice"
              label="Precio Unitario"
              validation={productValidation}
              md={4}
            />
            <NibbleBaseInput
              fieldType="number"
              fieldName="Discount"
              label="Descuento"
              validation={productValidation}
              md={4}
            />
          </Row>
          <Taxes validation={productValidation} />
          <ProductTotals productValidation={productValidation} />
        </Form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={toggleModal}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          <i className="fas fa-times"></i> Cerrar
        </button>
        <button
          type="submit"
          className="btn btn-danger"
          form="create-form-product"
        >
          <i className="fas fa-check"></i> Guardar
        </button>
      </div>
    </Modal>
  )
}

ProductModal.propTypes = {
  isOpen: PropTypes.bool,
  request: PropTypes.object,
  productRow: PropTypes.object,
  validation: PropTypes.object,
  stampedInvoice: PropTypes.object,
  toggleModal: PropTypes.func,
  handleEditProductModal: PropTypes.func,
}

export default ProductModal
