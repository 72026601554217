import React,{useState} from 'react';
import PropTypes from 'prop-types';

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableTotal({ totals,configs }) {
    
    return (
        <>
            <tr className='table-head'>
                <td></td>
                <td></td>
                { configs.map(col => (
                <td className="text-danger f-bold text-right" key={col.value+'totals'}>
                    { totals[col.value] ? nf2.format(totals[col.value]) : '-' }
                </td>
                ))}
                <td className="text-danger f-bold text-right">
                    { totals.otros ? nf2.format(totals.otros) : '-' }
                </td>
                <td className="text-danger f-bold text-right">
                    { totals.total ? nf2.format(totals.total) : '-' }
                </td>
            </tr>
        </>
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object,
    configs: PropTypes.array
};