import React from "react"
import PropTypes from "prop-types"
import { Spinner } from "reactstrap"

import "./styles.css"

function FullSpinner({ message = "Cargando..." }) {
  return (
    <div className="full-spinner">
      <div className="text-center">
        <Spinner className="ms-2" color="primary" />
        <p>{message}</p>
      </div>
    </div>
  )
}

FullSpinner.propTypes = {
  message: PropTypes.any,
}

export default FullSpinner
