import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Alert, Form } from "reactstrap"

import GeneralData from "common/invoice/components/GeneralData"
import Issuer from "common/invoice/components/Issuer"
import useInvoice from "common/invoice/hooks/useInvoice"

import useInvoiceMovClient from "./useInvoiceMovClient"

import Client from "common/invoice/components/Client"
import Products from "common/invoice/components/Products"
import ProductsTotals from "common/invoice/components/ProductsTotal"
import InvoiceDownloadButtons from "common/invoice/components/InvoiceDownloadButtons"
import FormActions from "common/invoice/components/FormActions"
import ProductModal from "common/invoice/product-modal/ProductModal"
import EnterpriseModal from "common/invoice/components/EnterpriseModal"
import { getAdministrativeData } from "store/catalogs/administrative-data/actions"

import "flatpickr/dist/themes/material_blue.css"
import ComplementData from "common/invoice/components/complements/ComplementData"

const InvoiceForm = ({ dataRow: movClient, toggleRightCanvas }) => {
  const dispatch = useDispatch()

  const { InvoiceType, CfdiType } = movClient

  const invoiceValues = {
    InvoiceType,
    ...(CfdiType ? { CfdiType } : {}),
  }

  const { validation, state, methods } = useInvoice(invoiceValues)
  const { isOpenReceiverModal, toggleReceiverModal } = useInvoiceMovClient({
    movClient,
    validation,
  })

  const { productRow, cfdiRequest, isOpenProductModal } = state

  const {
    toggleProductModal,
    handleDeleteProduct,
    handleNewProductModal,
    handleEditProductModal,
  } = methods

  const isComplementPayment = validation.values.CfdiType === "P"

  useEffect(() => {
    if (state.cfdiRequest.isSubmitted) {
      dispatch(getAdministrativeData())
    }
  }, [state.cfdiRequest.isSubmitted])

  return (
    <>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        {cfdiRequest.isError ? (
          <Alert color="danger">{cfdiRequest.msgError}</Alert>
        ) : null}

        <GeneralData validation={validation} />

        {isComplementPayment ? (
          <>
            <Issuer validation={validation} />
            <Client
              disableRfc
              disableName
              /* disableUseCfdi */
              disableTaxZipCode
              disableFiscalRegime
              validation={validation}
              title={<h5>Cliente</h5>}
            />
            <ComplementData validation={validation} />
          </>
        ) : (
          <>
            <Issuer validation={validation} />
            <Client
              validation={validation}
              title={
                <h5>
                  Cliente
                  <span onClick={toggleReceiverModal} role="button">
                    <i
                      className="fa fa-edit cursor-pointer ms-2"
                      style={{ fontSize: 12 }}
                    />
                  </span>
                </h5>
              }
            />
            <Products
              validation={validation}
              handleDeleteProduct={handleDeleteProduct}
              handleNewProductModal={handleNewProductModal}
              handleEditProductModal={handleEditProductModal}
            />
            <ProductsTotals validation={validation} />
          </>
        )}

        {cfdiRequest.data ? (
          <InvoiceDownloadButtons
            stampedInvoice={cfdiRequest.data}
            toggleRightCanvas={toggleRightCanvas}
          />
        ) : (
          <FormActions
            validation={validation}
            isStampingCfdi={cfdiRequest.isLoading}
            toggleClose={toggleRightCanvas}
          />
        )}
      </Form>
      {isOpenProductModal && (
        <ProductModal
          validation={validation}
          productRow={productRow}
          isOpen={isOpenProductModal}
          toggleModal={toggleProductModal}
        />
      )}
      {isOpenReceiverModal && (
        <EnterpriseModal
          validation={validation}
          isOpen={isOpenReceiverModal}
          toggleModal={toggleReceiverModal}
          enterpriseId={movClient?.client_account?.related?.id}
          isIssuer={false}
        />
      )}
    </>
  )
}

InvoiceForm.propTypes = {
  isNew: PropTypes.bool,
  dataRow: PropTypes.object,
  toggleRightCanvas: PropTypes.func,
}

export default InvoiceForm
