
export const GET_ADMINISTRATIVE_DATA = 'GET_ADMINISTRATIVE_DATA';
export const GET_ADMINISTRATIVE_DATA_SUCESS = 'GET_ADMINISTRATIVE_DATA_SUCESS';
export const GET_ADMINISTRATIVE_DATA_FAIL = 'GET_ADMINISTRATIVE_DATA_FAIL';

export const SET_ACTIVE_ADMINISTRATIVE_DATA = 'SET_ACTIVE_ADMINISTRATIVE_DATA';
export const SET_TYPE_ADMINISTRATIVE_DATA = 'SET_TYPE_ADMINISTRATIVE_DATA';

export const SET_FILTERS_ADMINISTRATIVE_DATA = 'SET_FILTERS_ADMINISTRATIVE_DATA';

export const ADD_ADMINISTRATIVE_DATA = 'ADD_ADMINISTRATIVE_DATA';
export const UPDATE_ADMINISTRATIVE_DATA = 'UPDATE_ADMINISTRATIVE_DATA';

export const REPORT_TYPE = {
    CLIENT_PROVIDERS: 'ClientesProveedores',
    BANKS: 'Bancos',
    CHARGES: 'Cobros',
    COSTS: 'costos'
};

export const REPORTS = {
    CLIENTS: 'clientes',
    PROVIDERS: 'proveedores',
    WAREHOUSE_CHECK: 'comprobacion_almacen',
    CHARGES: 'cargos',
    BANKS: 'bancos',
    BANKS_CHARGES: 'bancos_cargos',
    COSTS: 'costos',
    COLUMNS_VIEW: 'columnas_vista',
};

export const SET_FILTER_CLIENT = "SET_FILTER_CLIENT";
export const SET_FILTER_PROVIDER = "SET_FILTER_PROVIDER";
export const SET_FILTER_DATES = "SET_FILTER_DATES";
export const SET_FILTER_SEARCH = "SET_FILTER_SEARCH";
export const SET_ADMINISTRATIVE_FILTERS = "SET_ADMINISTRATIVE_FILTERS";
export const SET_COLUMNS_VIEW = "SET_COLUMNS_VIEW";