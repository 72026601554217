import React from "react"
import { Row, Col, Card, CardBody, Button, Modal } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from "react-router-dom"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ClipLoader from "react-spinners/ClipLoader";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../datatables.scss"
import { useState } from "react";
import { useEffect } from "react";
import { deleteRegion, getRegions } from "helpers/backend_helper";
import { deleteProduct } from 'helpers/backend_helper';
import { Alert } from "reactstrap";
import ModalForm from "./modal-form";
import usePage from "hooks/usePage";
import BreadcrumbNav from "components/Common/BreadcrumbNav";

const initRegion = {
  id: '',
  name: ''
}

const breadcrumbItems = [
  { id:'1', title: 'Catálogos', link: '', active: false },
  { id:'2', title: 'Regiones', link: '', active: true }
];

const CatalogRegionList = (props) => {
  const { page, setPage, sizePerPage } = usePage();

  const [region, setRegion] = useState(initRegion);
  const [error, setError] = useState (null)
  const [row, setRow] = useState(null)
  const [modal_standard, setmodal_standard] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  
  async function handleDelete(evt, row) {
    setLoading(true);
    try {
      const response = await deleteRegion(row.id);
      setData(data.filter( item => item.id !== row.id))
    } catch(error) {
      setError(error.toString())
    }
    setLoading(false);
    tog_standard()
  }  

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        {/* <Link onClick={() => setRegion(row)} className="btn btn-sm btn-danger" to={{ pathname: "/catalogs/regions/form-edit", state: row }} title="Edit">
          <i className="fas fa-edit"></i>
        </Link> */}
        <button onClick={() => {
          setRegion(row)
          setIsOpenModal(true)
        }} className="btn btn-sm btn-danger" title="Edit">
          <i className="fas fa-edit"></i>
        </button>
        <Button 
          type="button"
          className="btn btn-sm btn-danger ms-1"
          onClick={(evt) => {tog_standard(); setRow(row); } } 
          title="Borrar">
          <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  buttonActions.displayName = 'buttonEdit'

  const columns = [
    {
      dataField: 'name',
      text: 'Nombre',
      sort: true
    }, {
      dataField: 'id',
      text: 'Acciones',
      formatter: buttonActions
    }
  ]; 
  
  // Table Data
  let [color, setColor] = useState("#f46a6a");
  const [loading, setLoading] = useState (false);
  const [data, setData] = useState ([]);
  const [totalSize, setTotalSize] = useState(0);

  async function getData()  {
    setLoading(true);
    try {
      const response = await getRegions();
      setData(response);
      setTotalSize(response.length);
      setError(null)
    } catch(error) {
      setError(error.toString())
    }
    setLoading(false);
  }

  useEffect(() => {    

    getData();
  }, []);


  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage,
    totalSize,
    custom: true,
    page,
    onPageChange: setPage,
    hideSizePerPage: true
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (data).length }];

  const { SearchBar } = Search;

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const afterSearch = (results) => {
    setTotalSize(results.length);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo Regiones</title>
        </MetaTags>
        <div className="container-fluid">
          <BreadcrumbNav title="Regiones"  items={breadcrumbItems}/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {error ? <Alert color="danger">{error}</Alert> : null}
                      
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={data}
                        search={ { afterSearch } }
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder='Buscar'
                                      onSearch={(data) => {
                                        setPage(1);
                                        toolkitProps.searchProps.onSearch(data);
                                      }}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col md="8" className="text-end">
                              {/* <Link to="/catalogs/regions/form-create" className="btn btn-danger btn-sm">
                                  <i className="fas fa-plus"></i> Crear Región
                              </Link> */}
                              <button onClick={() => {
                                setRegion(initRegion)
                                setIsOpenModal(true)
                              }} className="btn btn-danger btn-sm">
                                <i className="fas fa-plus"></i> Crear Región
                              </button>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    noDataIndication={ loading ? <ClipLoader color={color} loading={loading} size={30} /> : 'Sin datos' }
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ModalForm 
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          region={region}
          getData={getData}
        />
      </div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Borrar Region
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            ¿Está seguro de borrar la region?
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            <i className="fas fa-times"></i> Cerrar
          </button>
          <button
            onClick={(evt) => handleDelete(evt, row)}
            type="button"
            className="btn btn-danger"
          >
            <i className="fas fa-check"></i> Borrar
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CatalogRegionList
