import React from 'react';
import PropTypes from 'prop-types';

import {
    Table
} from 'reactstrap';

import TableMovementItem from './TableMovementItem';
import Header from './TableHeader';
import TableTotal from './TableTotal';

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableReport({ data, totals, setColumnOrder, columnOrder, balanceInitial,multiple}) {
    return (
        <div className="table-responsive" style={{position: 'relative', maxHeight: '500px'}}>
            <Table className="table mb-0 report-table table-bordered table-sm">
                <thead className='table-light'>
                    <TableTotal totals={totals} multiple={multiple} />
                    <Header setColumnOrder={ setColumnOrder } columnOrder={columnOrder} multiple={multiple}/>
                </thead>
                <tbody>
                    {!multiple &&
                    <tr>
                        <td colSpan={6}></td>
                        <td className="text-end"><b>{ nf2.format(balanceInitial) }</b></td>
                    </tr>
                    }
                    { data.map((item,index) => (
                        <TableMovementItem key={ index } item={ item } multiple={multiple}/>
                    )) }
                </tbody>
            </Table>
        </div>
    );
}

TableReport.propTypes = {
    data: PropTypes.any,
    balanceInitial: PropTypes.number,
    totals: PropTypes.object,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object,
    multiple: PropTypes.bool
};