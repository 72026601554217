import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import * as _ from "lodash"
import {
  getInvoiceType,
  getPaymentForm,
  getPaymentMethod,
  getTaxRegime,
  getUseCfdi,
} from "store/sat/actions"
import {
  getEnterprise,
  postCfdi,
  postNextInvoiceFolio,
} from "helpers/backend_helper"
import getStorageUser from "helpers/api_helper"
import useRequest from "hooks/useRequest"
import {
  initialValues as initialInvoiceValues,
  invoiceValidation as validationSchema,
} from "../static"
import { fillCfdiIssuer } from "../helpers"
import { initProduct } from "pages/Administrative/ClientProvider/RightForms/Invoice/components/product-modal/static"
import { buildComplementInvoice, buildInvoice } from "../invoice-builder"

const useInvoice = (invoiceValues = {}) => {
  const user = getStorageUser()

  const dispatch = useDispatch()
  const cfdiRequest = useRequest()

  const [productRow, setProductRow] = useState(initProduct)
  const [isOpenProductModal, setIsOpenProductModal] = useState(false)

  const toggleProductModal = () => {
    setIsOpenProductModal(!isOpenProductModal)
  }

  const onSubmit = async formValues => {
    let formData = {}

    if (formValues.CfdiType === "P") {
      formData = buildComplementInvoice(formValues)
    } else {
      formData = buildInvoice(formValues)
    }

    cfdiRequest.req(postCfdi(formData))
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialInvoiceValues,
      ...invoiceValues,
    },
    validationSchema,
    onSubmit,
  })

  const handleNewProductModal = () => {
    setProductRow(initProduct)
    toggleProductModal()
  }

  const handleEditProductModal = row => {
    setProductRow(row)
    toggleProductModal()
  }

  const handleDeleteProduct = Id => {
    const producsFiltered = validation.values.Items.filter(
      item => item.Id !== Id
    )

    validation.setFieldValue("Items", producsFiltered)
  }

  const onClickGlobalReceiver = () => {
    validation.setFieldValue("Receiver.Name", "PUBLICO EN GENERAL")
    validation.setFieldValue("Receiver.Rfc", "XAXX010101000")
    validation.setFieldValue("Receiver.FiscalRegime", "616")
    validation.setFieldValue("Receiver.TaxZipCode", "78240")
    validation.setFieldValue("Receiver.CfdiUse", "S01")
    validation.setFieldValue(
      "GlobalInformation.Year",
      new Date().getFullYear().toString()
    )
  }

  const fetchIssuer = () => {
    if (!user?.enterprise_id) return

    getEnterprise(user.enterprise_id).then(enterprise => {
      fillCfdiIssuer(enterprise, validation)
    })
  }

  const getSatCatalogs = () => {
    dispatch(getUseCfdi())
    dispatch(getTaxRegime())
    dispatch(getInvoiceType())
    dispatch(getPaymentForm())
    dispatch(getPaymentMethod())
  }

  const fetchNextInvoiceFolio = () => {
    const data = {
      InvoiceType: validation.values.InvoiceType,
      CfdiType: validation.values.CfdiType,
    }

    postNextInvoiceFolio(data).then(resp => {
      validation.setFieldValue("Folio", resp?.folio)
    })
  }

  useEffect(getSatCatalogs, [])
  useEffect(fetchNextInvoiceFolio, [])
  useEffect(fetchIssuer, [user?.enterprise_id])

  return {
    validation,
    state: {
      productRow,
      cfdiRequest,
      isOpenProductModal,
    },
    methods: {
      toggleProductModal,
      handleDeleteProduct,
      handleNewProductModal,
      handleEditProductModal,
      onClickGlobalReceiver,
      fetchNextInvoiceFolio,
    },
  }
}

export default useInvoice
