import {
  tax_configuration,
  tax_configuration_validation,
} from "common/sat/Taxes"
import * as Yup from "yup"
import { es } from "yup-locales"

Yup.setLocale(es)

export const initProduct = {
  Id: "", //
  ProductCode: "",
  Description: "",
  UnitCode: "",
  Quantity: 1,
  UnitPrice: 0,
  Subtotal: 0,
  Discount: 0,
  Total: 0,
  TaxObject: "",
  Taxes: [],
  tax_configuration,
}

export const productValidation = Yup.object({
  ProductCode: Yup.string().nullable().required().label("Código SAT"),
  Description: Yup.string().required().label("Producto"),
  UnitCode: Yup.string().nullable().required().label("Unidad SAT"),
  Quantity: Yup.number().moreThan(0).required().label("Cantidad"),
  UnitPrice: Yup.number().moreThan(0).required().label("Precio Unitario"),
  Subtotal: Yup.number().moreThan(0).required().label("Subtotal"),
  Discount: Yup.number()
    .min(0)
    .test(
      "is-less-than-subtotal",
      "El descuento no puede ser mayor que el subtotal",
      function (value) {
        const subtotal = this.parent.Subtotal
        return value <= subtotal
      }
    )
    .label("Descuento"),
  Total: Yup.number().label("Total"),
  TaxObject: Yup.string().nullable().required().label("Objeto Impuesto"),
  Taxes: Yup.array().of(
    Yup.object({
      Base: Yup.number(),
      IsRetention: Yup.bool(),
      IsQuota: Yup.bool(),
      Name: Yup.string(),
      Rate: Yup.number(),
      Total: Yup.number(),
      IsFederalTax: Yup.bool(),
    })
  ),
  tax_configuration: tax_configuration_validation,
})
