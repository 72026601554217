import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import BtnDownloadExcel from "../common/BtnDownloadExcel"

import BtnDownloadPdf from "../common/BtnDownloadPdf"
import { useForm } from "hooks/useForm"
import { getClientsBalanceReport } from "../../../../helpers/backend_helper"
import NibblePeriodFilter from "common/nibble/NibblePeriodFilter"
import NibbleFilter from "common/nibble/NibbleFilter"
import { header_excel, header_pdf, initialPeriod } from "static"

const typeOptions = [
  {
    id: "con-operaciones-en-el-periodo",
    name: "Con operaciones en el periodo",
  },
]

const initFilter = {
  period: initialPeriod,
  clients: [],
  type: "",
}
const getParsedParams = (form, column, order) => ({
  ...form,
  clients: form.clients.join(","),
  column,
  order,
})

export default function Filters({
  data,
  handleFilters,
  setPeriod,
  columnOrder,
}) {
  const { column, order } = columnOrder

  const { form, handleSpecificInput, handleDatePeriod } = useForm(initFilter)

  const params = getParsedParams(form, column, order)

  const downloadFile = (type = "excel") =>
    getClientsBalanceReport({
      params,
      headers: type === "excel" ? header_excel : header_pdf,
    })

  useEffect(() => {
    handleFilters(params)
  }, [form.period, form.clients, form.type, column, order])

  return (
    <>
      <Row>
        <NibblePeriodFilter
          form={form}
          handleDatePeriod={handleDatePeriod}
          handleSpecificInput={handleSpecificInput}
          setPeriod={setPeriod}
        />
        <Col className="text-end">
          {data?.report?.length ? (
            <div>
              <BtnDownloadExcel downloadExcel={downloadFile} />
              <BtnDownloadPdf downloadPdf={downloadFile} />
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <NibbleFilter
          isMulti
          name="clients"
          form={form}
          handleInput={handleSpecificInput}
        />
        <NibbleFilter
          form={form}
          name="select"
          label="Mostrar"
          fieldName="type"
          options={typeOptions}
          handleInput={handleSpecificInput}
        />
      </Row>
    </>
  )
}

Filters.propTypes = {
  data: PropTypes.any,
  handleFilters: PropTypes.func,
  setPeriod: PropTypes.func,
  columnOrder: PropTypes.object,
}
