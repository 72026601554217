import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment"

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 


export default function TableMovementItem({ item,index }) {

    const {
        mov_date,
        folio,
        dias_vencidos,
        pieces,
        kgs,
        total,
        net,
        balance,
        discount,
        other_charges,
        fecha,
        payments,
        decrease
    } = item;

    return (
        <>
            <tr>
                <td className="text-end">
                    {index+1}
                </td>
                <td className="text-end">
                    {fecha}
                </td>
                <td className="text-right">
                    { 'Nota de compra' }
                </td>
                <td className="text-right">
                    { folio }
                </td>
                <td className="text-right">
                    { new Intl.NumberFormat('es-MX').format(dias_vencidos) }
                </td>
                <td className="text-right">
                    { new Intl.NumberFormat('es-MX').format(pieces) }
                </td>
                <td  className={"text-right "}>
                    { nf2.format(kgs) }
                </td>
                <td className={"text-right "}>
                    { nf2.format(total) }
                </td>
                <td className={"text-right "}>
                    { nf2.format(decrease) }
                </td>
                <td className={"text-right "}>
                    { nf2.format(discount) }
                </td>
                <td className={"text-right "}>
                    { nf2.format(other_charges) }
                </td>
                <td className={"text-right "}>
                    { nf2.format(net) }
                </td>
                
                <td className={"text-right "}>
                    { nf2.format(payments) }
                </td>
                <td className={"text-right "}>
                    { nf2.format(balance) }
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number
};