import React, { useEffect, useState } from "react"
import { tabName } from "./components/Tabs"
import useRequest from "hooks/useRequest"
import { useSelector } from "react-redux"
import { getEnterprise } from "helpers/backend_helper"

const useBillingConfiguration = () => {
  const [tabActive, setTabActive] = useState(tabName.defaultConcepts)

  const enterpriseReq = useRequest()
  const user = useSelector(state => state.Login.user)

  const fetchEnterprise = () => {
    if (!user.enterprise_id) return

    enterpriseReq.req(getEnterprise(user.enterprise_id))
  }

  useEffect(fetchEnterprise, [user?.enterprise_id])

  return {
    state: {
      tabActive,
      isLoading: enterpriseReq.isLoading,
      enterprise: enterpriseReq.data,
    },
    events: {
      setTabActive,
    },
  }
}

export default useBillingConfiguration
