import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Modal,
    Alert,
    Spinner
} from 'reactstrap';

import useRequest from 'hooks/useRequest';
import { getAdministrativeData } from 'store/catalogs/administrative-data/actions';
import { deleteMov } from 'helpers/backend_helper';

function ModalDelete({ isOpen, setOpenModal, row }) {

    const dispatch = useDispatch();

    const {
        activeTab,
        dataReports
    } = useSelector(state => state.AdministrativeData);

    const {
        reset,
        msgError,
        isLoading,
        isSubmitted,
        req
    } = useRequest();

    const {
        title,
        url_delete
    } = dataReports[activeTab];


    const handleSubmit = async (e) => {
        e.preventDefault();        

        req(deleteMov(`${url_delete}/${row.id}`)).then(() => {
            dispatch(getAdministrativeData());
        });
    }

    useEffect(() => {
        reset();
    }, [ isOpen ]);

    return (
        <div>
            <Modal
                isOpen={ isOpen }
                toggle={() => {
                    setOpenModal(false);
                }}
            >
                <form onSubmit={ handleSubmit }>                    
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            { title }
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        <h5>¿Desea eliminar el registro?</h5>
                        { isSubmitted ? (
                            <Alert>
                                Registro eliminado
                            </Alert>
                        ) : '' }
                        { msgError ? (
                            <Alert className="alert alert-danger">
                                { msgError }
                            </Alert>
                        ) : '' }
                        { isLoading ? <Spinner /> : '' }
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                            disabled={ isLoading }
                        >
                            { isSubmitted ? 'Cerrar' : 'Cancelar' }
                        </button>
                        { !isSubmitted ? (
                            <button
                                type="submit"
                                className="btn btn-danger"
                                disabled={ isLoading }
                            >       
                                <i 
                                    className={`${isLoading ? '' : 'd-none' } bx bx-loader bx-spin font-size-16 align-middle me-2`}
                                ></i>
                                { isLoading ? 'Eliminando' : 'Eliminar' }
                            </button>
                        ) : '' }
                    </div>
                </form>
            </Modal>
        </div>
    )
}

ModalDelete.propTypes = {
    isOpen: PropTypes.bool,
    setOpenModal: PropTypes.func,
    row: PropTypes.object
};

export default ModalDelete;