import React from 'react';

export default function TableTotal() {
    return (
        <>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="f-bold text-right">Total en PESOS</td>
                <td colSpan={2} className="f-blue bg-gray text-right">39,960,048.90</td>
                <td colSpan={4} className="bg-gray"></td>
            </tr>
            <tr>
                <td colSpan={2} className="f-bold">Total de documentos</td>
                <td className="f-blue bg-gray text-right">380</td>
                <td className="f-bold text-right">Saldo total en pesos</td>
                <td colSpan={2} className="f-blue bg-gray text-right">39,960,048.90</td>
                <td colSpan={4} className="bg-gray"></td>
            </tr>
        </>
    );
}
