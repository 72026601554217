import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { 
    Modal,
    Button,
    Alert
} from 'reactstrap';

import useRequest from 'hooks/useRequest';
import { uploadExcel } from 'helpers/backend_helper';

function ModalFile({ isOpen, setOpenModal }) {
    
    const refInput = useRef();
    
    const [ file, setFile ] = useState();
    const [ fileError, setFileError ] = useState('');

    const { activeTab, dataReports } = useSelector(state => state.AdministrativeData);
    const { title, url_file, url_format } = dataReports[activeTab];

    const {
        reset,
        msgError,
        isLoading,
        isSubmitted,
        req
    } = useRequest();

    const selectFile = () => {
        if(!refInput.current) return;
        refInput.current.click();
    }

    const handleInputChange = e => {
        const fileUpload = e.target.files[0];
        if(!fileUpload) return;

        setFile(fileUpload);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!file){
            setFileError('Selecciona un archivo');
            return;
        }else{
            setFileError('');
        }
        
        req(uploadExcel(url_file, file));
    }

    useEffect(() => {
        reset();
    }, [ isOpen ]);

    return (
        <div>
            <Modal
                isOpen={ isOpen }
                toggle={() => {
                    setOpenModal(false);
                }}
                backdrop={'static'}
            >
                <form onSubmit={ handleSubmit }>
                    <input onChange={ handleInputChange } ref={ refInput } type="file" hidden/>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            { title }
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        <h5>Cargar archivo</h5>
                        { isSubmitted ? (
                            <Alert>
                                Datos cargados
                            </Alert>
                        ) : '' }
                        { fileError ? (
                                <Alert className="alert alert-danger">
                                    { fileError }
                                </Alert>
                        ) : '' }
                        { msgError ? (
                                <Alert className="alert alert-danger">
                                    { msgError }
                                </Alert>
                        ) : '' }                        

                        <Button
                                onClick={ selectFile }
                                type="button"
                                className="btn btn-danger btn-sm float-right mb-3 mt-2"
                            >
                                <i className="bx bx-upload"></i> Seleccionar
                        </Button>                        

                        { file?.name ? 
                            <p>{file.name}</p> 
                        : '' }

                        <a 
                            href={`${process.env.PUBLIC_URL}/${url_format}`}
                            className="d-block"
                            download
                        >
                            Descargar formato
                        </a>                      

                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                            disabled={ isLoading }
                        >
                            { isSubmitted ? 'Cerrar' : 'Cancelar' }
                        </button>
                        { !isSubmitted ? (
                            <button
                                type="submit"
                                className="btn btn-danger"
                                disabled={ isLoading }
                            >       
                                <i 
                                    className={`${isLoading ? '' : 'd-none' } bx bx-loader bx-spin font-size-16 align-middle me-2`}
                                ></i>
                                { isLoading ? 'Cargando' : 'Cargar' }
                            </button>
                        ) : '' }
                    </div>
                </form>
            </Modal>
        </div>
    )
}

ModalFile.propTypes = {
    isOpen: PropTypes.bool,
    setOpenModal: PropTypes.func
};

export default ModalFile;