import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Alert, Card, CardBody, CardHeader, Row } from "reactstrap"

import useRequest from "hooks/useRequest"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import { getPPDInvoices } from "helpers/backend_helper"
import ComplementItem from "./components/ComplementItem"
import { DocumentoRelacionado } from "./static"
import {
  addFirstRelatedDocumentToMovClient,
  addFirstRelatedDocumentToPpdInvoice,
} from "./complement-builder"

const ComplementData = ({ validation }) => {
  const ppdInvoicesRequest = useRequest()

  const { Receiver, Complemento, mov_client_id, ppd_invoice_id } =
    validation.values
  const { Amount, RelatedDocuments } = Complemento

  const totalRelatedDocsAmountPaid = RelatedDocuments.reduce(
    (acc, relatedDoc) => acc + Number(relatedDoc.AmountPaid),
    0
  )

  const addRelatedDocument = () => {
    const newRelatedDocuments = [
      ...RelatedDocuments,
      { ...DocumentoRelacionado },
    ]

    validation.setFieldValue(
      "Complemento.RelatedDocuments",
      newRelatedDocuments
    )
  }

  const deleteRelatedDocument = index => {
    const newRelatedDocuments = RelatedDocuments.filter((item, i) => i != index)

    validation.setFieldValue(
      "Complemento.RelatedDocuments",
      newRelatedDocuments
    )
  }

  const fetchPPDInvoices = () => {
    if (!Receiver.Rfc) return

    const config = {
      params: { receiver: Receiver.Rfc, mov_client_id, ppd_invoice_id },
    }

    ppdInvoicesRequest.req(getPPDInvoices(config)).then(data => {
      if (!data?.data?.length) return

      // Asociar complemento de pago a la venta
      if (mov_client_id) {
        addFirstRelatedDocumentToMovClient({
          validation,
          mov_client_id,
          ppdInvoices: data.data,
        })
      }

      // Asociar complemento a la factura ppd
      if (ppd_invoice_id) {
        addFirstRelatedDocumentToPpdInvoice({
          validation,
          ppd_invoice_id,
          ppdInvoices: data.data,
        })
      }
    })
  }

  const validateRelatedDocsAmountPaid = () => {
    if (!Amount) return
    if (!totalRelatedDocsAmountPaid) return

    let message = false

    if (totalRelatedDocsAmountPaid !== Amount) {
      message = "La suma de los montos pagados debe ser igual al monto a pagar"
    }

    validation.setFieldValue(`Complemento.Error`, message)
  }

  useEffect(fetchPPDInvoices, [Receiver.Rfc])
  useEffect(validateRelatedDocsAmountPaid, [Amount, totalRelatedDocsAmountPaid])

  return (
    <div className="pb-4">
      <h5>Pago</h5>
      {validation.values?.Complemento?.Error ? (
        <Alert color="danger" className="mt-3">
          {validation.values.Complemento.Error}
        </Alert>
      ) : null}
      <Row>
        <NibbleBaseInput
          fieldType="number"
          fieldName="Complemento.Amount"
          label="Monto a pagar"
          validation={validation}
          md={12}
        />
      </Row>
      <div className="pb-2">
        <div className="d-flex justify-content-between align-items-center pb-2">
          <div>
            <h5 className="mb-0">Documentos relacionados con el pago</h5>
            {!Receiver?.Rfc ? (
              <p className="text-danger pt-2">
                Selecciona un cliente para poder asociar todas sus facturas PPD
              </p>
            ) : null}
          </div>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            title="Agregar nuevo documento"
            onClick={addRelatedDocument}
          >
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
      {RelatedDocuments?.map((doc, index) => {
        const number = index + 1

        return (
          <Card
            key={doc.Id}
            style={{
              border: "2px solid #dddddd",
            }}
          >
            <CardHeader>
              <div className="d-flex justify-content-between">
                Documento {number}
                {number > 1 ? (
                  <button
                    onClick={() => deleteRelatedDocument(index)}
                    type="button"
                    className="btn"
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                ) : null}
              </div>
            </CardHeader>
            <CardBody>
              <ComplementItem
                index={index}
                validation={validation}
                isLoading={ppdInvoicesRequest.isLoading}
                ppdInvoices={ppdInvoicesRequest.data?.data || []}
              />
            </CardBody>
          </Card>
        )
      })}
    </div>
  )
}

ComplementData.propTypes = {
  validation: PropTypes.object,
  disableCfdiType: PropTypes.bool,
}

export default ComplementData
