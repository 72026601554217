import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getOrder } from '../../store/orders_provider/actions';

import {
    Row,
    Col,
    Card,
    CardBody,
    Spinner
} from 'reactstrap';

import PageBase from 'components/Common/PageBase';
import OrderHeader from './OrderHeader';
import OrderSummary from './OrderSummary';
import Comments from './Comments';
import PageNotFound from 'pages/Utility/PageNotFound';

import 'flatpickr/dist/themes/material_red.css';
import OrderRating from './OrderRating';


const breadcrumbList = [
    { id: '1', title: 'Pedidos a Proveedor', link:'/distributor/orders-provider', active: false },
    { id: '2', title: 'Detalle de pedidos', link: '#', active: true }
];

export default function OrderProviderContainer() {   
    const dispatch    = useDispatch();
    const { orderId } = useParams(); 

    const { dailys, ui } = useSelector(state => state.OrdersProvider);

    const { isLoading, isError } = ui;
    
    useEffect(() => {
        dispatch(
            getOrder(orderId)
        );
    }, []);

    console.log('dailys',dailys)
    if(isError) return <PageNotFound />

    return (
        <>  
            <PageBase
                titleMeta="Detalle del pedido"
                titleBread="Detalle de Pedidos a Proveedor"
                itemsBread={ breadcrumbList }
            >
                {
                    isLoading ? <Spinner /> : null
                }          
                { !isLoading && dailys.length > 0 && (
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>                                    
                                    <OrderHeader 
                                        id={ dailys[0].id }
                                        provider={ dailys[0].provider }
                                        date={ dailys[0].date }
                                    />
                                    <OrderSummary 
                                        dailys={ dailys }
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </PageBase>
        </>
    )
}