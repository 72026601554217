import React, { useCallback, useEffect, useRef, useState } from "react"
import axios from "axios"

import useRequest from "./useRequest"
import { getSatProducts } from "helpers/backend_helper"

const useSatProducts = (search = "") => {
  const cancelToken = useRef(null)
  const satProductsRequest = useRequest()

  const [satProducts, setSatProducts] = useState([])

  const fetchSatProducts = useCallback(async search => {
    if (cancelToken.current) {
      cancelToken.current.cancel()
    }

    cancelToken.current = axios.CancelToken.source()

    const config = {
      params: {
        search,
      },
      cancelToken: cancelToken.current.token,
    }

    const response = await satProductsRequest.req(getSatProducts(config))
    if (!response) return

    setSatProducts(response)
  }, [])

  useEffect(() => {
    fetchSatProducts(search)
  }, [fetchSatProducts, search])

  return {
    isLoadingSatProducts: satProductsRequest.isLoading,
    satProducts,
    fetchSatProducts,
  }
}

export default useSatProducts
