import React from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"

const Filters = ({ validation }) => {
  return (
    <Row id="billing-filters">
      <NibbleBaseInput
        placeHolder="Nombre, Rfc"
        fieldName="issuer"
        label="Emisor"
        validation={validation}
        md={3}
      />
      <NibbleBaseInput
        placeHolder="Nombre, Rfc"
        fieldName="receiver"
        label="Receptor"
        validation={validation}
        md={3}
      />
      <NibbleBaseInput
        fieldType="date-range"
        fieldName="period"
        label="Periodo"
        validation={validation}
        md={3}
      />
      <NibbleBaseInput
        placeHolder="Seleccionar..."
        fieldType="select"
        fieldName="status"
        label="Estatus"
        validation={validation}
        md={3}
        selectItems={[
          {
            id: "active",
            name: "Activas",
          },
          {
            id: "canceled",
            name: "Canceladas",
          },
        ]}
      />
      <NibbleBaseInput
        placeHolder="Seleccionar..."
        fieldType="select"
        fieldName="payment_method"
        label="Método de pago"
        validation={validation}
        md={3}
        selectItems={[
          {
            id: "PUE",
            name: "PUE",
          },
          {
            id: "PPD",
            name: "PPD",
          },
          {
            id: "CP",
            name: "CP",
          },
        ]}
      />
      <NibbleBaseInput
        fieldName="total_amount_state"
        fieldType="number"
        label="Importe total"
        validation={validation}
        md={3}
        placeHolder="Colocar cantidad + [enter]"
        onKeyDown={e => {
          if (e.key === "Enter") {
            validation.setFieldValue("total_amount", e.target.value)
          }
        }}
      />
      <NibbleBaseInput
        fieldName="uuid"
        label="UUID"
        placeHolder="Buscar UUID"
        validation={validation}
        md={3}
      />
    </Row>
  )
}

Filters.propTypes = {
  validation: PropTypes.object,
}

export default Filters
