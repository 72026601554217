import React, { useEffect, useState } from "react"
import { Row } from "reactstrap"
import PropTypes from "prop-types"

import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import moment from "moment"

const lastYear = moment().subtract(1, "years").format("YYYY")
const currentYear = moment().format("YYYY")

const years = [
  {
    id: currentYear,
    name: currentYear,
  },
  {
    id: lastYear,
    name: lastYear,
  },
]

const monthCatalog = [
  { id: "01", name: "Enero" },
  { id: "02", name: "Febrero" },
  { id: "03", name: "Marzo" },
  { id: "04", name: "Abril" },
  { id: "05", name: "Mayo" },
  { id: "06", name: "Junio" },
  { id: "07", name: "Julio" },
  { id: "08", name: "Agosto" },
  { id: "09", name: "Septiembre" },
  { id: "10", name: "Octubre" },
  { id: "11", name: "Noviembre" },
  { id: "12", name: "Diciembre" },
  { id: "13", name: "Enero - Febrero" },
  { id: "14", name: "Marzo - Abril" },
  { id: "15", name: "Mayo - Junio" },
  { id: "16", name: "Julio - Agosto" },
  { id: "17", name: "Septiembre - Octubre" },
  { id: "18", name: "Noviembre - Diciembre" },
]

const bimestralMonthList = monthCatalog.filter(item =>
  ["13", "14", "15", "16", "17", "18"].includes(item.id)
)

const noBimestralMonthList = monthCatalog.filter(
  item => !["13", "14", "15", "16", "17", "18"].includes(item.id)
)

const GlobalInformation = ({ validation, disableCfdiType = true }) => {
  const { GlobalInformation } = validation.values

  const [monthsAllowed, setMonthsAllowed] = useState(monthCatalog)

  //https://apisandbox.facturama.mx/guias/cfdi40/publico-general
  useEffect(() => {
    if (GlobalInformation.Periodicity === "05") {
      setMonthsAllowed(bimestralMonthList)
    } else {
      setMonthsAllowed(noBimestralMonthList)
    }

    // clear field
    validation.setFieldValue("GlobalInformation.Months", "")
  }, [GlobalInformation.Periodicity])

  return (
    <div className="pb-4">
      <h5>Información global</h5>
      <Row>
        <NibbleBaseInput
          fieldType="select"
          fieldName="GlobalInformation.Periodicity"
          label="Periodicidad"
          selectItems={[
            { id: "01", name: "Diario" },
            { id: "02", name: "Semanal" },
            { id: "03", name: "Quincenal" },
            { id: "04", name: "Mensual" },
            { id: "05", name: "Bimestral" },
          ]}
          validation={validation}
          md={4}
          placeHolder="Seleccionar"
          selectIsClearable={false}
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName="GlobalInformation.Months"
          label="Meses"
          selectItems={monthsAllowed}
          validation={validation}
          md={4}
          placeHolder="Seleccionar"
          selectIsClearable={false}
        />
        <NibbleBaseInput
          fieldType="select"
          fieldName="GlobalInformation.Year"
          label="Año"
          selectItems={years}
          validation={validation}
          md={4}
          placeHolder="Seleccionar"
          selectIsClearable={false}
        />
      </Row>
    </div>
  )
}

GlobalInformation.propTypes = {
  validation: PropTypes.object,
  disableCfdiType: PropTypes.bool,
}

export default GlobalInformation
