import React, { Fragment, useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types'
import { Row, Col, Label } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import AddressForm from "./AddressForm"
import Select from 'react-select';
import * as _ from 'lodash';
import { MEXICO_STATES } from '../../../helpers/states';
import { getProducts } from "helpers/backend_helper"
import toastr from "toastr"

const FarmForm = props => {

  
  
  const refForm = useRef('form-farm-create')
  const initState = {
    name: null,
    address: {},
    local_delivery: false,
    region_id: null,
    region: null,
    regions: []
  }
  const [farm, setFarm] = useState({...initState, ...props.entityFarm})
  const [zonas, setZonas] = useState(props.entityFarm?.regions ? [...props.entityFarm.regions]:[])
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState(props.entityFarm?.products || [])
  const [isProductsValid, setIsProductsValid] = useState(true)

  console.log('farm', farm)
  console.log('zonas', zonas)

  const handleCheckHasLocalDelivery = (e, value) => {
    setFarm({...farm, local_delivery: value})
  }

  const handleCheckRegionsDelivery = (idxRegion, value) => {
    idxRegion = parseInt(idxRegion)
    let newregions = [...farm.regions];
    if (value) {  // add
      const element = _.find(newregions, {id: idxRegion});
      if (!element) {
        newregions.push( _.find(props.regions, {id: idxRegion}) )
      }
    } else {    // remove
      newregions = _.remove(newregions, (region) => { return region.id != idxRegion })
    }
    
    setFarm({...farm, regions: newregions})
  }

  const handleCheckLocalRegion = (option) => {
    setFarm({
      ...farm,
      region_id: option.value,
      region: _.find(props.regions, {id: option.value})
    })
  }

  useEffect(() => {
    getProducts().then(setProducts)
  }, [])
  

  const handleValidSubmit = async (event, values) => {
    if(!selectedProducts.length){
      toastr.error('El campo productos es obligatorio')
      return;
    };
    try {
      // const response = await postFarm({...farm, ...values})

      const addressFields = ['street', 'ext_num', 'int_num', 'location', 'city', 'country', 'cp', 'state', 'region_id']
      const address = _.pick(values, addressFields)
      props.handleComplete({...farm, ..._.omit(values, addressFields), address: address, regions: zonas, products: selectedProducts})

    } catch(error) {
      console.error(error)
    }
  }

  const handleZones = (e) => {
    setZonas(e)
    setFarm({
      ...farm,
      regions: e,
    })
  }

  return (
    <Fragment>

    <AvForm
      className="form-horizontal"
      onValidSubmit={(e, v) => {
        handleValidSubmit(e, v)
      }}
    >
    <Row>
      <Col md={12}>
        <div className="mb-3">
          <AvField
              name="name"
              label="Nombre"
              value={ farm.name }
              type="text"
              required
              errorMessage="Campo requerido"
              placeholder=""
            />
        </div>
      </Col>
    </Row>
    <AddressForm entityAddress={ farm.address } />
    <Row className="mb-3">
      <Col md={7}>
        <h5 className="font-size-14 mb-4">
          <i
            className="mdi mdi-arrow-right text-danger mr-1"></i> ¿Entrega en sucursal?
        </h5>
      </Col>
      <Col md={4}>
        <div className="form-check form-check-danger mb-3" style={{display:"inline-block", width:"120px"}}>
          <input
            className="form-check-input"
            type="checkbox"
            id={"local_delivery"}
            defaultChecked={farm.local_delivery}
            onChange={ (value) => handleCheckHasLocalDelivery(value.currentTarget.id, value.currentTarget.checked) }
          />
          <label
            className="form-check-label"
            htmlFor={"local_delivery"}
          >
            Sí
          </label>
        </div>
      </Col>
      <Col md={12}>
        <div className="mb-3">
          <Label htmlFor="formrow-firstname-Input">Zonas de cobertura</Label>
          <Select
            //isClearable
            id="formrow-InputState"
            isMulti={true}
            options={ MEXICO_STATES.map( ({ id, name }) => ({ label: name, value: name}) ) }
            onChange={ handleZones }
            //defaultValue={ {label: farm.region?.name, value: farm.region_id} }
            value = { zonas.map( (e) => { return {label: e.label, value:e.label}}) }
          />
          { farm.region_id == null ? <div className="invalid-feedback">Campo requerido</div>:''}
        </div>
      </Col>
      <Col md={12}>
        <div className={`mb-3`}>
          <Label htmlFor="formrow-firstname-Input">Productos</Label>
          <Select
            //isClearable
            id="formrow-InputState"
            isMulti={ true }
            options={ products }
            getOptionLabel={ option => option.name }
            getOptionValue={ option => option.id }
            onChange={ (values) => setSelectedProducts(values) }
            value={ selectedProducts }
          />
        </div>
      </Col>
    </Row>
      <div className="text-center">
        <button type="submit" className="btn btn-sm btn-danger w-md">
          <i className="fas fa-plus"></i> { props.editMode ? 'Modificar':'Agregar'}
        </button>
      </div>
    </AvForm>
    </Fragment>
  )
}

FarmForm.propTypes = {
  entityFarm: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
  handleComplete: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
}

export default FarmForm
