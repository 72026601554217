import React, { useEffect, useRef, useState } from 'react'
import { FormGroup, InputGroup, Label, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Alert } from "reactstrap";
import { postHolidays, updateHolidays } from 'helpers/backend_helper';
import { useForm } from 'hooks/useForm';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import * as moment from 'moment';

const ModalForm = ({ isOpenModal, setIsOpenModal, holiday, getData }) => {    

    const [isSubmit, setIsSubmit] = useState(false)
    const [error, setError] = useState (null)
    const [completed, setCompleted] = useState (null)
    const [btnSaveLoad, setBtnSaveLoad] = useState (false)
    const refForm = useRef('form-user-create');

    const { form, handleSpecificInput, resetForm} = useForm(holiday);

    const handleValidSubmit = async (event, values) => {
        setIsSubmit(true)

        console.log(values)

        const formData = {
            day: moment(form.day).format('YYYY-MM-DD'),
            id: form.id
        }

        setBtnSaveLoad(true)
        try {
          holiday.id ? await updateHolidays(formData) : await postHolidays(formData);
          setError(null)
          setCompleted('Se completo la operación')
          getData();
        } catch(error) {
          setError(error.toString())
          setCompleted(null)
        }
        setBtnSaveLoad(false)
    }

    useEffect(() => {
        setCompleted('')
        setIsSubmit(false)
        resetForm(holiday)
    }, [isOpenModal, holiday.id])
    

    return (
        <div>
            <Modal
                isOpen={isOpenModal}
                toggle={() => {
                  setIsOpenModal(false);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                      {holiday.id ? 'Editar' : 'Crear'} día festivo
                    </h5>
                    <button
                      type="button"
                      onClick={() => {
                        setIsOpenModal(false);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    <div className="modal-body">
                        <AvForm
                            ref={refForm}
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                            }}
                        >
                            {error ? <Alert color="danger">{error}</Alert> : null}
                            {completed ? <Alert color="success">{completed}</Alert> : null}
                            <FormGroup className="mb-3">
                                <Label>Fecha</Label>
                                <InputGroup>
                                <Flatpickr
                                    className="form-control d-block"
                                    placeholder=""
                                    onChange={ (dates, parseDate) => {
                                        handleSpecificInput({
                                            key: 'day',
                                            value: dates[0]
                                        })
                                    }}
                                    value={form.day}
                                    options={{
                                        dateFormat: "Y-m-d",
                                        locale: Spanish,
                                        //defaultDate: new Date()
                                    }}
                                />
                                </InputGroup>
                            </FormGroup>
                            <input name="unit_id" type="hidden" /> 
                            <div className="text-center">
                                <button type="submit" className="btn btn-danger w-md" disabled={ btnSaveLoad }>
                                    <i className="fas fa-save"></i> Guardar
                                </button>
                                <button 
                                    onClick={() => {
                                        setIsOpenModal(false);
                                    }} 
                                    className="btn w-md btn-danger ms-2"
                                >
                                    <i className="fas fa-times"></i> Cerrar
                                </button>
                            </div>
                        </AvForm>
                    </div>
                    {/* <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                            setIsOpenModal(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary "
                        >
                            Save changes
                        </button>
                    </div> */}
            </Modal>
        </div>
    )
}

ModalForm.propTypes = {
    isOpenModal: PropTypes.bool,
    setIsOpenModal: PropTypes.func,
    holiday: PropTypes.object,
    getData: PropTypes.func,
    products: PropTypes.array,
    enterprises: PropTypes.array
};

export default ModalForm