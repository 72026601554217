import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import Select from "react-select"
import Flatpickr from "react-flatpickr"
import { isEmpty } from "lodash"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import classnames from "classnames"
const _ = require("lodash")

import {
  Col,
  Row,
  Label,
  Button,
  Form,
  Input,
  Alert,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

import { useForm } from "hooks/useForm"
import { getAdministrativeData } from "store/catalogs/administrative-data/actions"
import useRequest from "hooks/useRequest"

import "flatpickr/dist/themes/material_red.css"
import moment from "moment"
import {
  postMovClient,
  putMovClient,
  getMovClientNextFolio,
  getProvidersDate,
  getClientMovementToPay,
  getMovBankFolios,
} from "helpers/backend_helper"
import { getProducts } from "../../../../helpers/backend_helper"
import Creatable from "react-select/creatable"

const nf = new Intl.NumberFormat("es-MX", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const todayDate = moment().format("YYYY-MM-DD")
const ivaOptions = [
  { name: "16%", value: "0.16" },
  { name: "8%", value: "0.08" },
  { name: "0%", value: "0" },
]
const retencionOptions = [
  { name: "No aplica", value: "0" },
  { name: "1.25%", value: "0.0125" },
]

const initForm = {
  banco: "",
  banco_folio: "",
  client_account: { id: "", name: "" },
  cobro: "0.00",
  decrease: "0.00",
  folio: "",
  folio2: "",
  kgs: "0.00",
  mov_date: todayDate,
  payment_account: { id: "", name: "" },
  pieces: "0.00",
  product: "",
  productSelected: { value: "", label: "" },
  product_unit: "",
  prom: "0.00",
  provider: "",
  pv: "0.00",
  reference: "",
  rem: "",
  total_vta: "0.00",
  nota_cargo: "0.00",
  nota_credito: "0.00",
  venta_neta: "0.00",
  venta_type: "remision",
  provider_account: { id: "", name: "" },
  provider_client_account: { id: "", name: "" },
  mov_provider: { id: "", name: "" },
  iva_opt: { name: "16%", value: "0.16" },
  retencion_opt: { name: "No aplica", value: "0" },
  ventaRelated: [],
  movsClientsSales: [],
  mov_banks: [],
  origin: "",
  mortalidad: "0.00",
  mortalidad_descuento: "0.00",
}

const selfPaymentOpt = {
  label: "A la misma venta",
  value: "0",
}

const getDefaultTab = (isNew, dataRow) => {
  if (isNew) return "registro-venta"
  if (dataRow?.cobro === "0.00") return "registro-venta"
  return "registro-cobro"
}

const isValidFormValues = (form, setFormErr, activeTab) => {
  let isValid = true

  if (!form?.client_account?.id) {
    setFormErr(prev => ({
      ...prev,
      client_account: "El campo cliente es obligatorio",
    }))
    isValid = false
  }

  if (activeTab === "registro-cobro") {
    if (!form?.payment_account?.id) {
      setFormErr(prev => ({
        ...prev,
        payment_account: "El campo banco es obligatorio",
      }))
      isValid = false
    }

    let porAsignar = Number(form.cobro) -
      (form.movsClientsSales || [])
        .map(e => e.import ?? 0.0)
        .reduce(
          (partialSum, a) => Number(partialSum) + Number(a),
          0
        );

    if (porAsignar < 0) {
      setFormErr(prev => ({ ...prev, cobro: 'El importe por asignar debe ser mayor o igual a 0' }));
      isValid = false;

      return isValid;
    }
  }

  if (activeTab === "registro-venta") {
    if (!form?.productSelected?.value) {
      setFormErr(prev => ({
        ...prev,
        productSelected: "El campo producto es obligatorio",
      }))
      isValid = false
    }
    if (form?.pieces === "" || form?.pieces === "0.00" || form?.pieces <= 0) {
      setFormErr(prev => ({
        ...prev,
        pieces: "El campo piezas debe ser mayor a 0",
      }))
      isValid = false
    }
    if (form?.pv === "" || form?.pv === "0.00" || form?.pv <= 0) {
      setFormErr(prev => ({
        ...prev,
        pv: "El campo precio unitario debe ser mayor a 0",
      }))
      isValid = false
    }
    if (form?.total <= 0) {
      setFormErr(prev => ({ ...prev, total: "El total debe ser mayor a 0" }))
      isValid = false
    }

    return isValid
  }

  if (form?.cobro === "" || form?.cobro === "0.00" || form?.cobro <= 0) {
    setFormErr(prev => ({
      ...prev,
      cobro: "El campo cobro debe ser mayor a 0",
    }))
    isValid = false
  }

  return isValid
}

const ClientForm = ({ dataRow, isNew, toggleRightCanvas }) => {
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState(getDefaultTab(isNew, dataRow))
  const [formErr, setFormErr] = useState({})
  const [movsProv, setMovsProv] = useState([])
  const [products, setProducts] = useState([])
  const [ventaRelatedOpts, setVentaRelatedOpts] = useState([])
  const [movBanks, setMovBanks] = useState([])

  console.log("dataRow", dataRow)

  let editData
  if (!isNew) {
    let ivaExist = ivaOptions.filter(e => e.value == dataRow.type_iva)
    let newIvaOpt
    if (ivaExist.length == 0) {
      newIvaOpt = { name: dataRow.type_iva, value: dataRow.type_iva }
      ivaOptions.push(newIvaOpt)
    }
    let retencionExist = retencionOptions.filter(
      e => e.value == dataRow.type_retencion
    )
    let newRetencionOpt
    if (retencionExist.length == 0) {
      newRetencionOpt = {
        name: dataRow.type_retencion,
        value: dataRow.type_retencion,
      }
      retencionOptions.push(newRetencionOpt)
    }
    editData = {
      ...dataRow,
      iva_opt:
        ivaExist.length == 0
          ? newIvaOpt
          : { name: ivaExist[0].name, value: dataRow.type_iva },
      retencion_opt:
        retencionExist.length == 0
          ? newRetencionOpt
          : { name: retencionExist[0].name, value: dataRow.type_retencion },
      productSelected: { label: dataRow.product, value: dataRow.product },
      movsClientsSales:
        dataRow.movs_clients_sales?.map(e => {
          return {
            value: e.id,
            label:
              e.mov_date + " - f-" + (e.folio ?? "") + " - $" + e.venta_neta,
            import: e.pivot?.import ?? null,
            obj: e,
          }
        }) ?? [],
    }
    console.log("editData", editData)
  }

  const {
    form,
    handleInputChange,
    handleDatePicker,
    handleSpecificInput,
    validateNumeric,
    resetForm,
    validateMatchImportsCobro,
  } = useForm(isNew ? initForm : editData)

  const { clientAccounts, paymentAccounts, providerAccounts } = useSelector(
    state => state.AccountingAccounts
  )

  const { isError, isLoading, isSubmitted, msgError, req } = useRequest()

  const { mov_date } = form

  const provider_account_id = form.provider_account?.id
  const client_account_id = form.client_account?.id
  const movBankIds = form.mov_banks?.map(mov => mov.id)

  useEffect(() => {
    async function getDataFolio() {
      const response = await getMovClientNextFolio()
      handleSpecificInput({ key: "folio", value: response.folio })
    }
    if (isNew) {
      getDataFolio()
    }
  }, [])

  useEffect(() => {
    async function getProductsCatalog() {
      const response = await getProducts()
      let products = response.map(e => {
        return {
          label: `${e.skey} ${e.name}`,
          value: e.name,
          unit: e?.unit?.name,
        }
      })
      setProducts(products)

      if (!isNew) {
        let exist = response.filter(e => e.name == dataRow.product)
        if (exist.length == 0) {
          setProducts([
            ...products,
            { label: dataRow.product, value: dataRow.product },
          ])
        }
      }
    }

    getProductsCatalog()
  }, [])

  useEffect(() => {
    if (!provider_account_id) return

    const params = {
      mov_date: form.mov_date,
      provider_id: provider_account_id,
    }

    getProvidersDate({ params }).then(setMovsProv)
  }, [mov_date, provider_account_id])

  useEffect(() => {
    if (!client_account_id) return

    const params = {
      date: form.mov_date,
    }

    getClientMovementToPay(client_account_id, params).then(res => {
      console.log("res", res.length)
      setVentaRelatedOpts([
        ...res.map(e => {
          return {
            value: e.id,
            label:
              e.mov_date +
              " - f-" +
              (e.folio ?? "") +
              " - $" +
              nf.format(e.total),
            obj: e,
          }
        }),
      ])
    })
  }, [mov_date, client_account_id])

  useEffect(() => {
    if (!client_account_id) return

    const params = {
      mov_date,
      client_id: client_account_id,
    }

    getMovBankFolios(params).then(folios => {
      setMovBanks(
        folios?.map(item => ({ ...item, value: item.id, label: item.name }))
      )
    })
  }, [mov_date, client_account_id])

  useEffect(() => {
    setFormErr({})
  }, [activeTab])

  useEffect(() => {
    if (form?.product_unit !== "Kg") {
      handleSpecificInput({ key: "prom", value: 0 })
      handleSpecificInput({ key: "kgs", value: 0 })
    }
  }, [form?.product_unit])

  const handleSubmit = e => {
    e.preventDefault()

    setFormErr(prev => ({ ...prev, total: "" }))

    const formSend = {
      ...form,
      product: form.productSelected?.value,
      kgs: totalKgs(),
      total_vta: totalVenta(),
      venta_neta: subtotal(),
      total: totalFinal(),
      iva: subtotalIva(),
      retencion: subtotalRetencion(),
      type_iva: form.iva_opt?.value,
      type_retencion: form.retencion_opt?.value,
    }

    if (!isValidFormValues(formSend, setFormErr, activeTab)) return

    if (isNew) {
      req(postMovClient(formSend)).then(async () => {
        dispatch(getAdministrativeData())
        resetForm()
        const response = await getMovClientNextFolio()
        handleSpecificInput({ key: "folio", value: response.folio })
        handleSpecificInput({
          key: "movsClientsSales",
          value:
            response.movs_clients_sales?.map(e => {
              return {
                value: e.id,
                label:
                  e.mov_date +
                  " - f-" +
                  (e.folio ?? "") +
                  " - $" +
                  e.venta_neta,
                import: e.pivot?.import ?? null,
                obj: e,
              }
            }) ?? [],
        })
      })
    } else {
      req(putMovClient(form.id, formSend)).then(response => {
        console.log("response", response)
        dispatch(getAdministrativeData())
        handleSpecificInput({
          key: "movsClientsSales",
          value:
            response.movs_clients_sales?.map(e => {
              return {
                value: e.id,
                label:
                  e.mov_date +
                  " - f-" +
                  (e.folio ?? "") +
                  " - $" +
                  e.venta_neta,
                import: e.pivot?.import ?? null,
                obj: e,
              }
            }) ?? [],
        })
      })
    }
  }

  const totalKgs = () => {
    let total = Number(form.pieces) * Number(form.prom)
    return total ? total : 0
  }

  const totalVenta = () => {
    let total = 0
    if (isNotUnitKg) {
      total = Number(form.pieces) * Number(form.pv)
    } else {
      total = Number(form.kgs) * Number(form.pv)
    }
    console.log({ total })
    total = total ? Math.round(total * 100) / 100 : 0
    return total ? total : 0
  }

  const subtotal = () => {
    let totalVta = totalVenta()
    let ncargo = Number(form.nota_cargo)
    let ncredito = Number(form.nota_credito)
    let merma = Number(form.decrease)
    let mortalidad = Number(form.mortalidad_descuento)
    let total =
      totalVta +
      (ncargo ? ncargo : 0) -
      (ncredito ? ncredito : 0) -
      (merma ?? 0) -
      (mortalidad ?? 0)
    total = total ? Math.round(total * 100) / 100 : 0
    return total ? total : 0
  }

  const subtotalIva = () => {
    let subtotalVta = subtotal()
    let iva = subtotalVta * Number(form.iva_opt?.value)
    return iva ?? 0
  }

  const subtotalRetencion = () => {
    let subtotalVta = subtotal()
    let retencion = subtotalVta * Number(form.retencion_opt?.value)
    return retencion ?? 0
  }

  const totalFinal = () => {
    let totalVta = subtotal() + subtotalIva() - subtotalRetencion()
    return totalVta ?? 0
  }

  const totalMerma = () => {
    let total = Number(form.merma) * Number(form.pv)
    total = total ? Math.round(total * 100) / 100 : 0
    return total ? total : 0
  }

  /*
    const totalMortalidad = () => {
    let total = Number(form.merma) * Number(form.prom) * Number(form.pv)
    total = total ? Math.round(total * 100) / 100 : 0
    return total ? total : 0
  }
  */

  const totalMortalidad = () => {
    let total = Number(form.mortalidad) * Number(form.prom) * Number(form.pv)
    total = total ? Math.round(total * 100) / 100 : 0
    return total ? total : 0
  }

  const isNotUnitKg = form?.product_unit !== "Kg"

  console.log("values", form.values)
  return (
    <>
      <Form onSubmit={handleSubmit}>
        {isError ? (
          <Alert className="alert alert-danger">{msgError}</Alert>
        ) : (
          ""
        )}
        {isSubmitted ? (
          <Alert className="alert alert-success">
            {isNew ? "Registro creado" : "Registro actualizado"}
          </Alert>
        ) : (
          ""
        )}
        <Row>
          <Col md={12} className="pb-3">
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: "registro-venta" === activeTab,
                  })}
                  onClick={() => setActiveTab("registro-venta")}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Registro de venta</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: "registro-cobro" === activeTab,
                  })}
                  onClick={() => setActiveTab("registro-cobro")}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Registro de cobro</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        {activeTab === "registro-venta" && (
          <>
            <Row className="d-none">
              <Col md={12}>
                <p className="bg-danger text-light p-2">Registro de venta</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="hstack gap-3 mt-3 mb-3">
                  <Label>Tipo de documento:</Label>
                  <div className="form-check form-radio-danger">
                    <Input
                      className="form-check-input"
                      checked={form.venta_type == "remision"}
                      type="radio"
                      value="remision"
                      name="venta_type"
                      id="typeRemision"
                      onClick={evt => {
                        if (evt.target.checked)
                          handleSpecificInput({
                            key: "venta_type",
                            value: evt.target.value,
                          })
                      }}
                    ></Input>
                    <Label className="form-check-label" for="typeRemision">
                      Remisión
                    </Label>
                  </div>
                  <div className="form-check form-radio-danger">
                    <Input
                      className="form-check-input"
                      checked={form.venta_type == "factura"}
                      type="radio"
                      value="factura"
                      name="venta_type"
                      id="typeFactura"
                      onClick={evt => {
                        if (evt.target.checked)
                          handleSpecificInput({
                            key: "venta_type",
                            value: evt.target.value,
                          })
                      }}
                    ></Input>
                    <Label className="form-check-label" for="typeFactura">
                      Factura
                    </Label>
                  </div>
                </div>
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="">Fecha *</Label>
                  <Flatpickr
                    value={form.mov_date ? form.mov_date : ""}
                    className="form-control d-block"
                    placeholder="YYYY-MM-DD"
                    options={{
                      altInput: true,
                      altFormat: "Y-m-d",
                      dateFormat: "Y-m-d",
                      locale: Spanish,
                    }}
                    onChange={dates => handleDatePicker(dates, "mov_date")}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Folio Vta</Label>
                  <Input
                    value={form.folio ? form.folio : ""}
                    onChange={handleInputChange}
                    name="folio"
                    type="text"
                    className="form-control"
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="">Cliente *</Label>
                  <Select
                    value={form.client_account}
                    onChange={event => {
                      handleSpecificInput({
                        key: "client_account",
                        value: event,
                      })
                      setFormErr(prev => ({ ...prev, client_account: "" }))
                    }}
                    options={clientAccounts}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    placeholder="Seleccionar"
                    classNamePrefix="select2-selection"
                    className={
                      formErr?.client_account ? "form-control is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.client_account ? formErr.client_account : ""}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="">Producto * </Label>
                  <Select
                    value={form.productSelected}
                    onChange={event => {
                      console.log({ event })
                      handleSpecificInput({
                        key: "productSelected",
                        value: event,
                      })
                      handleSpecificInput({
                        key: "product_unit",
                        value: event?.unit,
                      })
                      setFormErr(prev => ({ ...prev, productSelected: "" }))
                    }}
                    options={products}
                    placeholder="Seleccionar"
                    classNamePrefix="select2-selection"
                    className={
                      formErr?.productSelected ? "form-control is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.productSelected ? formErr.productSelected : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="d-none">
                <div className="mb-3 ">
                  <Label htmlFor="formrow-email-Input">Rem/Prov</Label>
                  <Input
                    value={form.rem ? form.rem : ""}
                    onChange={handleInputChange}
                    name="rem"
                    type="text"
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="">Proveedor</Label>
                  <Select
                    placeholder="Seleccionar"
                    value={form.provider_account}
                    onChange={event => {
                      handleSpecificInput({
                        key: "provider_account",
                        value: event ? event : initForm.provider_account,
                      })
                      handleSpecificInput({
                        key: "mov_provider",
                        value: initForm.mov_provider,
                      })
                    }}
                    options={providerAccounts}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    classNamePrefix="select2-selection"
                    isClearable={true}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="">Compra relacionada</Label>
                  <Select
                    placeholder="Seleccionar"
                    value={form.mov_provider}
                    onChange={event =>
                      handleSpecificInput({
                        key: "mov_provider",
                        value: event,
                      })
                    }
                    options={movsProv}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    classNamePrefix="select2-selection"
                    isClearable={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Piezas *</Label>
                  <Input
                    value={form.pieces ? form.pieces : ""}
                    onChange={e => {
                      setFormErr(validateNumeric(e, formErr))
                      handleInputChange(e)
                    }}
                    name="pieces"
                    type="text"
                    className={
                      formErr?.pieces
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.pieces ? formErr.pieces : ""}
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Promedio</Label>
                  <Input
                    value={form.prom ? form.prom : ""}
                    onChange={e => {
                      setFormErr(validateNumeric(e, formErr))
                    }}
                    name="prom"
                    type="text"
                    className={
                      formErr?.prom ? "form-control is-invalid" : "form-control"
                    }
                    disabled={isNotUnitKg}
                  />
                  <div className="invalid-feedback">
                    {formErr?.prom ? formErr.prom : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">KGs</Label>

                  <InputGroup style={{ zIndex: 0 }}>
                    <Input
                      value={form.kgs ? form.kgs : ""}
                      onChange={e => {
                        setFormErr(validateNumeric(e, formErr))
                      }}
                      name="kgs"
                      type="text"
                      className={
                        formErr?.kgs
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      disabled={isNotUnitKg}
                    />
                    <Button
                      onClick={() => {
                        handleSpecificInput({ key: "kgs", value: totalKgs() })
                      }}
                      className="btn btn-danger"
                      type="button"
                    >
                      calcular kgs
                    </Button>
                  </InputGroup>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Precio unitario</Label>
                  <Input
                    value={form.pv ? form.pv : ""}
                    onChange={e => {
                      setFormErr(validateNumeric(e, formErr))
                    }}
                    name="pv"
                    type="text"
                    className={
                      formErr?.pv ? "form-control is-invalid" : "form-control"
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.pv ? formErr.pv : ""}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="">Ruta</Label>
                  <Input
                    disabled
                    value={form?.mov_provider?.origin ?? ""}
                    onChange={handleInputChange}
                    name="origin"
                    type="text"
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3 text-end">
                  <Label className="f-bold">Importe venta total</Label>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <p className="text-end f-bold">$ {nf.format(totalVenta())}</p>
                  <Input
                    value={form.total_vta ? form.total_vta : ""}
                    onChange={e => {
                      setFormErr(validateNumeric(e, formErr))
                    }}
                    name="total_vta"
                    type="hidden"
                    className={
                      formErr?.total_vta
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    required
                  />
                  <div className="invalid-feedback">
                    {formErr?.total_vta ? formErr.total_vta : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Merma</Label>
                  <InputGroup style={{ zIndex: 0 }}>
                    <Input
                      value={form.merma ? form.merma : ""}
                      onChange={e => {
                        setFormErr(validateNumeric(e, formErr))
                      }}
                      name="merma"
                      type="text"
                      className={
                        formErr?.merma
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                    />
                    <Button
                      onClick={() => {
                        handleSpecificInput({
                          key: "decrease",
                          value: totalMerma(),
                        })
                        handleSpecificInput({
                          key: "payment_account",
                          value: paymentAccounts.find(item => {
                            return item.name == "MERMA"
                          }),
                        })
                      }}
                      className="btn btn-danger"
                      type="button"
                    >
                      calcular merma
                    </Button>
                    <Button
                      onClick={() => {
                        handleSpecificInput({
                          key: "decrease",
                          value: totalMortalidad(),
                        })
                        handleSpecificInput({
                          key: "payment_account",
                          value: paymentAccounts.find(item => {
                            return item.name == "MORTALIDAD"
                          }),
                        })
                      }}
                      className="btn btn-danger d-none"
                      type="button"
                    >
                      calcular mortalidad
                    </Button>
                  </InputGroup>
                  <div className="invalid-feedback">
                    {formErr?.merma ? formErr.merma : ""}
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Mortalidad</Label>
                  <InputGroup style={{ zIndex: 0 }}>
                    <Input
                      value={form.mortalidad ? form.mortalidad : ""}
                      onChange={e => {
                        setFormErr(validateNumeric(e, formErr))
                      }}
                      name="mortalidad"
                      type="text"
                      className={
                        formErr?.mortalidad
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                    />
                    {/* <Button onClick={ () => { handleSpecificInput({key: 'decrease', value: totalMerma() }); handleSpecificInput({key: 'payment_account', value: paymentAccounts.find((item) => { return item.name == 'MERMA'}) });  } } 
                                    className='btn btn-danger' 
                                    type='button'>
                                        calcular merma
                                </Button> */}
                    <Button
                      onClick={() => {
                        handleSpecificInput({
                          key: "mortalidad_descuento",
                          value: totalMortalidad(),
                        })
                        handleSpecificInput({
                          key: "payment_account",
                          value: paymentAccounts.find(item => {
                            return item.name == "MORTALIDAD"
                          }),
                        })
                      }}
                      className="btn btn-danger"
                      type="button"
                    >
                      calcular mortalidad
                    </Button>
                  </InputGroup>
                  <div className="invalid-feedback">
                    {formErr?.merma ? formErr.merma : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="pt-2 text-end">
                  <Label className="">(-) Descuento Merma</Label>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Input
                    value={form.decrease ? form.decrease : ""}
                    onChange={e => {
                      setFormErr(validateNumeric(e, formErr))
                    }}
                    name="decrease"
                    type="text"
                    className={
                      formErr?.decrease
                        ? "form-control text-end is-invalid"
                        : "form-control text-end"
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.decrease ? formErr.decrease : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="pt-2 text-end">
                  <Label className="">(-) Descuento Mortalidad</Label>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Input
                    value={
                      form.mortalidad_descuento ? form.mortalidad_descuento : ""
                    }
                    onChange={e => {
                      setFormErr(validateNumeric(e, formErr))
                    }}
                    name="mortalidad_descuento"
                    type="text"
                    className={
                      formErr?.mortalidad_descuento
                        ? "form-control text-end is-invalid"
                        : "form-control text-end"
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.mortalidad_descuento
                      ? formErr.mortalidad_descuento
                      : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="pt-2 text-end">
                  <Label className="">(-) Otros descuentos</Label>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Input
                    value={form.nota_credito ? form.nota_credito : ""}
                    onChange={e => {
                      setFormErr(validateNumeric(e, formErr))
                    }}
                    name="nota_credito"
                    type="text"
                    className={
                      formErr?.nota_credito
                        ? "form-control is-invalid text-end"
                        : "form-control text-end"
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.nota_credito ? formErr.nota_credito : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="pt-2 text-end">
                  <Label className="">(+) Otros cargos</Label>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Input
                    value={form.nota_cargo ? form.nota_cargo : ""}
                    onChange={e => {
                      setFormErr(validateNumeric(e, formErr))
                    }}
                    name="nota_cargo"
                    type="text"
                    className={
                      formErr?.nota_cargo
                        ? "form-control is-invalid text-end"
                        : "form-control text-end"
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.nota_cargo ? formErr.nota_cargo : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3 text-end">
                  <Label className="f-bold">Subtotal</Label>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <p className="text-end f-bold">$ {nf.format(subtotal())}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="pt-2 text-end">
                  <Label className="">(+) IVA</Label>
                </div>
              </Col>
              <Col md={3}>
                <Select
                  placeholder="Seleccionar"
                  value={form.iva_opt}
                  onChange={event =>
                    handleSpecificInput({
                      key: "iva_opt",
                      value: event,
                    })
                  }
                  options={ivaOptions}
                  getOptionLabel={option => option.name}
                  classNamePrefix="select2-selection"
                />
              </Col>
              <Col md={6}>
                <div className="pt-2">
                  <p className="text-end">$ {nf.format(subtotalIva())}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="pt-2 text-end">
                  <Label className="">(-) Retenciones</Label>
                </div>
              </Col>
              <Col md={3}>
                <Select
                  placeholder="Seleccionar"
                  value={form.retencion_opt}
                  onChange={event =>
                    handleSpecificInput({
                      key: "retencion_opt",
                      value: event,
                    })
                  }
                  options={retencionOptions}
                  getOptionLabel={option => option.name}
                  classNamePrefix="select2-selection"
                />
              </Col>
              <Col md={6}>
                <div className="pt-2">
                  <p className="text-end">$ {nf.format(subtotalRetencion())}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="pt-2 text-end">
                  <Label className="f-bold">Total</Label>
                </div>
              </Col>
              <Col md={6}>
                <div className="pt-2">
                  <p className="text-end f-bold">$ {nf.format(totalFinal())}</p>
                  {formErr?.total ? (
                    <div className="invalid-feedback d-block text-end">
                      {formErr.total}
                    </div>
                  ) : null}
                </div>
                <Input
                  value={form.venta_neta ? form.venta_neta : ""}
                  onChange={e => {
                    setFormErr(validateNumeric(e, formErr))
                  }}
                  name="venta_neta"
                  type="hidden"
                  className={
                    formErr?.venta_neta
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  required
                />
                <div className="invalid-feedback">
                  {formErr?.venta_neta ? formErr.venta_neta : ""}
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="">Observaciones</Label>
                  <Input
                    value={form.observations ? form.observations : ""}
                    onChange={handleInputChange}
                    name="observations"
                    type="text"
                    maxLength={255}
                    className="form-control"
                  />
                </div>
              </Col>

              <Col md={12} className="d-none">
                <p>
                  <strong>*</strong> Campos obligatorios
                </p>
              </Col>
            </Row>
          </>
        )}

        {activeTab === "registro-cobro" && (
          <>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <Label htmlFor="">Fecha *</Label>
                  <Flatpickr
                    value={form.mov_date ? form.mov_date : ""}
                    className="form-control d-block"
                    placeholder="YYYY-MM-DD"
                    options={{
                      altInput: true,
                      altFormat: "Y-m-d",
                      dateFormat: "Y-m-d",
                      locale: Spanish,
                    }}
                    onChange={dates => handleDatePicker(dates, "mov_date")}
                  />
                </div>
              </Col>
              <Col md={8}>
                <div className="mb-3">
                  <Label htmlFor="">Cliente *</Label>
                  <Select
                    value={form.client_account}
                    onChange={event => {
                      handleSpecificInput({
                        key: "client_account",
                        value: event,
                      })
                      setFormErr(prev => ({ ...prev, client_account: "" }))
                    }}
                    options={clientAccounts}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    placeholder="Seleccionar"
                    classNamePrefix="select2-selection"
                    className={
                      formErr?.client_account ? "form-control is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.client_account ? formErr.client_account : ""}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="d-none">
              <Col md={12}>
                <p className="bg-danger text-light p-2">Registro de cobro</p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Importe Cobrado *</Label>
                  <Input
                    value={form.cobro ? form.cobro : ""}
                    onChange={e => {
                      setFormErr(validateNumeric(e, formErr))
                      setFormErr(
                        validateMatchImportsCobro(
                          "movsClientsSales",
                          e.target.value,
                          form.movsClientsSales,
                          formErr
                        )
                      )
                    }}
                    name="cobro"
                    min={0}
                    step={0.01}
                    type="text"
                    className={
                      formErr?.cobro || formErr?.movsClientsSales
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    required
                  />
                  <div className="invalid-feedback">
                    {formErr?.cobro ? formErr.cobro : ""}
                  </div>
                  <div className="text-muted">
                    Por asignar $
                    {nf.format(
                      Number(form.cobro) -
                      (form.movsClientsSales || [])
                        .map(e => e.import ?? 0.0)
                        .reduce(
                          (partialSum, a) => Number(partialSum) + Number(a),
                          0
                        )
                    )}
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="mb-3">
                  <Label htmlFor="">Venta relacionada</Label>
                  <Select
                    isMulti={true}
                    value={form.movsClientsSales}
                    onChange={event => {
                      console.log("event", event)
                      for (let i = 0; i < event.length; i++) {
                        if (!("import" in event[i]))
                          event[i].import = event[i].obj.pendingToPayment
                      }
                      handleSpecificInput({
                        key: "movsClientsSales",
                        value: event,
                      })
                      setFormErr(
                        validateMatchImportsCobro(
                          "movsClientsSales",
                          form.cobro,
                          event,
                          formErr
                        )
                      )
                    }}
                    options={ventaRelatedOpts}
                    placeholder="Seleccionar"
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>

            {(form.movsClientsSales || []).length > 0 && (
              <Row>
                <Col md={12}>
                  <div className="table-responsive">
                    <table
                      className={
                        formErr?.movsClientsSales
                          ? "is-invalid table table-bordered table-sm"
                          : "table table-bordered table-sm"
                      }
                    >
                      <thead className="table-light">
                        <tr>
                          <th>Folio Venta</th>
                          <th>Pendiente de pago</th>
                          <th>Cobro</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(form.movsClientsSales || []).map((e, i) => {
                          return (
                            <tr key={i}>
                              <td>{e.obj.folio}</td>
                              <td className="text-right">
                                $ {nf.format(e.obj.pendingToPayment)}
                              </td>
                              <td>
                                <div className="mb">
                                  <Input
                                    value={
                                      form.movsClientsSales[i].import ?? ""
                                    }
                                    onChange={evt => {
                                      form.movsClientsSales[i].import =
                                        evt.target.value
                                      handleSpecificInput({
                                        key: "movsClientsSales",
                                        value: form.movsClientsSales,
                                      })
                                      setFormErr(
                                        validateMatchImportsCobro(
                                          "movsClientsSales",
                                          form.cobro,
                                          form.movsClientsSales,
                                          formErr
                                        )
                                      )
                                    }}
                                    name="movsClientsSales"
                                    type="text"
                                    min={0}
                                    max={e.obj.venta_neta}
                                    required
                                    className={
                                      formErr?.movsClientsSales
                                        ? "form-control is-invalid text-right"
                                        : "form-control text-right"
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className="invalid-feedback">
                      {formErr?.movsClientsSales
                        ? formErr.movsClientsSales
                        : ""}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="">Banco *</Label>
                  <Select
                    placeholder="Seleccionar"
                    value={form.payment_account}
                    onChange={event =>
                      handleSpecificInput({
                        key: "payment_account",
                        value: event ? event : initForm.payment_account,
                      })
                    }
                    options={paymentAccounts.concat(providerAccounts)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    classNamePrefix="select2-selection"
                    className={
                      formErr?.payment_account ? "form-control is-invalid" : ""
                    }
                  />
                  <div className="invalid-feedback">
                    {formErr?.payment_account ? formErr.payment_account : ""}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Forma de Pago</Label>
                  <Input
                    value={form.payment_type ? form.payment_type : ""}
                    onChange={handleInputChange}
                    name="payment_type"
                    type="text"
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="">Descripción bancaria</Label>
                  <Input
                    value={form.folio2 ? form.folio2 : ""}
                    onChange={handleInputChange}
                    name="folio2"
                    type="text"
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="">Banco folio</Label>
                  <Input
                    value={form.banco_folio ? form.banco_folio : ""}
                    onChange={handleInputChange}
                    name="banco_folio"
                    type="text"
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="">Folio de Banco</Label>
                  <Select
                    isMulti={true}
                    value={movBanks.filter(mov => movBankIds.includes(mov.id))}
                    onChange={value => {
                      handleSpecificInput({ key: "mov_banks", value })
                    }}
                    options={movBanks}
                    placeholder="Seleccionar"
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="">Observaciones</Label>
                  <Input
                    value={form.observations ? form.observations : ""}
                    onChange={handleInputChange}
                    name="observations"
                    type="text"
                    maxLength={255}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col md={12}>
            <p>
              <strong>*</strong> Campos obligatorios
            </p>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              onClick={toggleRightCanvas}
              color="secondary"
              className="btn btn-secondary btn"
              style={{ marginRight: "10px" }}
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              className="btn btn-danger btn"
              disabled={isLoading}
              type="submit"
            >
              <i
                className={`${isLoading ? "" : "d-none"
                  } bx bx-loader bx-spin font-size-16 align-middle me-2`}
              ></i>
              {isLoading ? "Guardando" : "Guardar"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

ClientForm.propTypes = {
  toggleRightCanvas: PropTypes.func,
  dataRow: PropTypes.object,
  isNew: PropTypes.bool,
}

export default ClientForm
