import React, { useEffect } from "react"
import PropTypes from "prop-types"
import toastr from "toastr"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import CurrencyFormat from "common/nibble/CurrencyFormat"
import useDownloadCfdi from "hooks/useDownloadCfdi"
import PreviewPDF from "common/nibble/PreviewPdf"
import { useState } from "react"
import { getCfdis, getDownloadMovClientCfdi } from "helpers/backend_helper"
import useRequest from "hooks/useRequest"
import CancelCfdiModal from "./CancelCfdiModal"

const initModal = {
  show: false,
  url: "",
  fileName: "",
}

const InvoiceList = ({ dataRow: movClient }) => {
  const cfdisRequest = useRequest([])

  const [invoice, setInvoice] = useState()
  const [modalPdf, setModalPdf] = useState(initModal)
  const [showCancelCfdiModal, setShowCancelCfdiModal] = useState(false)

  const toggleCancelCfdiModal = () => {
    setShowCancelCfdiModal(!showCancelCfdiModal)
  }

  const handleCancelCfdi = row => {
    setInvoice(row)
    toggleCancelCfdiModal()
  }

  const fetchCfdis = () => {
    const config = {
      params: {
        mov_client_id: movClient.id,
      },
    }

    cfdisRequest.req(getCfdis(config))
  }

  useEffect(fetchCfdis, [])

  return (
    <div>
      <Table bordered size="sm" responsive style={{ minHeight: "250px" }}>
        <Headers isLoadingInvoices={cfdisRequest.isLoading} />
        <Content
          invoices={cfdisRequest.data}
          setModalPdf={setModalPdf}
          handleCancelCfdi={handleCancelCfdi}
        />
        <PreviewPDF modalPdf={modalPdf} setModalPdf={setModalPdf} />
      </Table>
      {showCancelCfdiModal && (
        <CancelCfdiModal
          invoice={invoice}
          isOpen={showCancelCfdiModal}
          toggleModal={toggleCancelCfdiModal}
          fetchCfdis={fetchCfdis}
        />
      )}
    </div>
  )
}

InvoiceList.propTypes = {
  toggleRightCanvas: PropTypes.func,
  dataRow: PropTypes.object,
}

export default InvoiceList

const Headers = ({ isLoadingInvoices }) => (
  <thead>
    <tr>
      <th>UUID</th>
      <th style={{ minWidth: 145 }}>Fecha</th>
      <th style={{ minWidth: 160 }}>Método de pago</th>
      <th style={{ minWidth: 120 }}>Tipo</th>
      <th>Estatus</th>
      <th>Total</th>
      <th className="text-center">
        {isLoadingInvoices ? <Spinner size="sm" /> : "Acciones"}
      </th>
    </tr>
  </thead>
)

Headers.propTypes = {
  isLoadingInvoices: PropTypes.bool,
}

const Content = ({ invoices, setModalPdf, handleCancelCfdi }) => {
  const pdfRequest = useRequest()
  const { downloadInvoice, isDownloadingInvoice } = useDownloadCfdi()

  const isLoading = isDownloadingInvoice || pdfRequest.isLoading

  const handleShowPdf = invoiceId => {
    const config = {
      params: {
        format: "pdf",
      },
    }

    pdfRequest.req(getDownloadMovClientCfdi(invoiceId, config)).then(resp => {
      setModalPdf({
        show: true,
        url: resp.url,
        fileName: `factura-${invoiceId}.pdf`,
      })
    })
  }

  const copyTextToClipboard = async text => {
    toastr.success("UUID copiado en el portapales")

    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand("copy", true, text)
    }
  }

  const getPaymentMethod = invoice => {
    if (invoice?.type?.includes("P")) return "CP"

    const typeParts = invoice?.payment_method?.split(" - ")

    return typeParts?.[0]
  }

  const getInvoicesMapped = () => {
    let invoicesMapped = []

    invoices.forEach(invoice => {
      invoicesMapped.push(invoice)

      if (invoice?.complements?.length) {
        invoicesMapped.push(...invoice.complements)
      }
    })

    return invoicesMapped
  }

  return (
    <tbody className={`${isLoading && "opacity-50"}`}>
      {getInvoicesMapped().map(item => (
        <tr key={item.id}>
          <td className="text-center">
            <i
              className="mdi mdi-file-document-multiple-outline"
              onClick={() => copyTextToClipboard(item.uuid)}
              title="Copiar UUID al portapapeles"
              style={{ fontSize: 18 }}
            ></i>
          </td>
          <td>{item.date}</td>
          <td>{getPaymentMethod(item)}</td>
          <td>{item.invoice_type}</td>
          <td>{item.status_str}</td>
          <td>
            <CurrencyFormat prefix="$" value={item.total} />
          </td>
          <td className="text-center">
            {item.invoice_type !== "Cancelación CFDI" ? (
              <UncontrolledDropdown>
                <DropdownToggle
                  href="#"
                  className="card-drop action-points"
                  tag="i"
                >
                  <i className="mdi mdi-dots-horizontal font-size-14" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-enddd actions-menu">
                  <DropdownItem
                    href="#"
                    onClick={() => handleShowPdf(item.sat_provider_id)}
                  >
                    <i className="mdi mdi-monitor-eye font-size-16 text-warning me-1" />{" "}
                    Ver factura
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={() => downloadInvoice("pdf", item.sat_provider_id)}
                  >
                    <i className="mdi mdi-file-pdf-box-outline font-size-16 text-info me-1" />{" "}
                    Descargar PDF
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={() => downloadInvoice("xml", item.sat_provider_id)}
                  >
                    <i className="mdi mdi-xml font-size-16 text-info me-1" />{" "}
                    Descargar XML
                  </DropdownItem>
                  {item.invoice_type === "Factura" &&
                  item.status === "active" ? (
                    <DropdownItem
                      href="#"
                      onClick={() => handleCancelCfdi(item)}
                    >
                      <i className="mdi mdi-bell-cancel font-size-16 text-danger me-1" />{" "}
                      Cancelar CFDI
                    </DropdownItem>
                  ) : null}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null}
          </td>
        </tr>
      ))}
    </tbody>
  )
}

Content.propTypes = {
  invoices: PropTypes.array,
  setModalPdf: PropTypes.func,
  handleCancelCfdi: PropTypes.func,
}
