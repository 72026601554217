import React from "react"
import PropTypes from "prop-types"
import { Nav, NavItem, NavLink } from "reactstrap"

export const tabName = {
  generalData: "datos-generales",
  taxData: "datos-fiscales",
  csdsCatalogs: "catalogo-csds",
}

const Tabs = ({ tabActive, setTabActive }) => {
  return (
    <div className="mt-2">
      <Nav tabs>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={{ active: tabActive == tabName.generalData }}
            onClick={() => {
              setTabActive(tabName.generalData)
            }}
          >
            Datos generales de empresa
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={{ active: tabActive == tabName.taxData }}
            onClick={() => {
              setTabActive(tabName.taxData)
            }}
          >
            Datos fiscales en empresa
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={{ active: tabActive == tabName.csdsCatalogs }}
            onClick={() => {
              setTabActive(tabName.csdsCatalogs)
            }}
          >
            Catálogo de CSDs
          </NavLink>
        </NavItem> */}
      </Nav>
    </div>
  )
}

Tabs.propTypes = {
  tabActive: PropTypes.string,
  setTabActive: PropTypes.func,
}

export default Tabs
