import React, { useState } from "react"
import { Card, Col, FormFeedback, Input, Label, Row, Spinner } from "reactstrap"
import classNames from "classnames"
import Dropzone from "react-dropzone"
import PropTypes from "prop-types"

const NibbleInputImage = ({
  md = 6,
  label = "label",
  fieldName = "field_name",
  itemFile = null,
  fileUrl = "",
  validation,
  postFileFunction = () => {},
}) => {
  const [uploadingFile, setUploadingFile] = useState(false)
  const [showDropzone, setShowDropzone] = useState(fileUrl ? false : true)

  const handleAcceptedFiles = async files => {
    setUploadingFile(true)
    try {
      const response = await postFileFunction(files[0])
      setShowDropzone(false)
      validation.setFieldValue(fieldName, response.path, true)
    } catch (error) {}

    setUploadingFile(false)
  }

  return (
    <Col md={md}>
      {!showDropzone && (
        <div
          className={classNames({
            "pt-2": true,
            "mb-3": true,
          })}
        >
          <Label className="form-label">{label}</Label>
          <p>
            <img height="100" src={fileUrl}></img>
          </p>
          <button
            type="button"
            onClick={() => {
              setShowDropzone(true)
              validation.setFieldValue(fieldName, itemFile?.id ?? null)
            }}
            className="btn btn-primary btn-sm save-user mt-1"
          >
            <i className="fas fa-sync"></i> Cambiar
          </button>
        </div>
      )}
      {showDropzone && (
        <div
          className={classNames({
            "d-none": false,
            "is-invalid": !!(
              validation.touched[fieldName] && validation.errors[fieldName]
            ),
            "mt-3": true,
          })}
        >
          <Label className="form-label">{label}</Label>
          <br />
          <Dropzone
            accept="image/*"
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles)
            }}
            onFileDialogOpen={() => {
              validation.setFieldTouched(fieldName, true, true)
            }}
            disabled={uploadingFile}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" style={{ minHeight: "120px" }}>
                <div
                  className="text-center needsclick mt-2"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-1">
                    <i className="display-5 text-muted bx bx-cloud-upload" />
                  </div>
                  {uploadingFile ? (
                    <>
                      <h5>Subiendo archivo...</h5>
                      <Spinner className="my-4" />
                    </>
                  ) : (
                    <h5>Arrastra archivos aquí o da clic en cargar</h5>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      )}
      {validation.touched[fieldName] && validation.errors[fieldName] ? (
        <FormFeedback type="invalid" className="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      ) : null}
    </Col>
  )
}

NibbleInputImage.propTypes = {
  md: PropTypes.any,
  label: PropTypes.any,
  fieldName: PropTypes.any,
  itemFile: PropTypes.any,
  validation: PropTypes.any,
  postFileFunction: PropTypes.any,
  fileUrl: PropTypes.string,
}

export default NibbleInputImage
