import React, { useEffect, useState, useRef, } from "react"
import MetaTags from 'react-meta-tags';
import Select from 'react-select';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from 'react-router-dom';

import { AvField, AvForm } from "availity-reactstrap-validation";
import { getEnterprises, getProducts, postAccount } from "helpers/backend_helper";
import { PropTypes } from 'prop-types';
import _ from "lodash";
import BreadcrumbNav from "components/Common/BreadcrumbNav";

const breadcrumbItems = [
  { id:'1', title: 'Administrativo', link: '', active: false },
  { id:'2', title: 'Cuentas Contables', link: '/administrative/accountings', active: false },
  { id:'3', title: 'Crear', link: '', active: true }
];

export const ACCOUNT_TYPES = [
  { label: 'Acreedor', value: 'rounder' },
  { label: 'Banco', value: 'bank' },
  { label: 'Cliente', value: 'client' },
  { label: 'Gasto', value: 'cost' },
  { label: 'General', value: 'any' },
  { label: 'Producto', value: 'product' },
  { label: 'Proveedor', value: 'provider' },
]

const CatalogAccountingFormCreate = (props) => {
  const [selectedTipo, setSelectedTipo] = useState(null);
  const [selectedRelated, setSelectedRelated] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState('');

  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')

  const [products, setProducts] = useState([]);
  const [enterprises, setEnterprises] = useState ([]);

  useEffect (() => {
    async function getData()  {
      const response = await getEnterprises();
      setEnterprises(response.map( e => { return {type: e.type == '2' ? 'provider' : (e.type == '3' ? 'client':''),  label: (e.clave ? e.clave + '':'') + e.company_name, value: e.id} } ));
    }

    getData();
  }, []);

  const validateProvider = (option) => {
    if (selectedTipo) return true;
    throw new Error('Debe completar el campo de tipo');
  }

  const handleValidSubmit = async (event, values) => {
    try {
      validateProvider()
      setBtnSaveLoad(true)
      const response = await postAccount({
        ...values,
        type: selectedTipo,
        related_id: selectedRelated,
        product_id: selectedProduct
      })
      setError(null)
      setCompleted('Se completo la operación')
      refForm && refForm.current.reset();
    } catch(error) {
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const handleSelectChangeTipo = (option) => {
    setSelectedTipo(option.value)
  }

  const handleSelectRelated = (option) => {
    setSelectedRelated(option.value)
  }

  useEffect(() => {
    getProducts().then(setProducts)
  }, []);

  //console.log({products, selectedProduct});
  //console.log(enterprises, selectedTipo, _.filter(enterprises, {type: selectedTipo}))

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de Usuarios</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Cuentas Contables"  items={breadcrumbItems}/>
          <Row>
            <Col lg={12} style={{minHeight: '800px'}}>
              <Card>
                <CardBody>
                  <AvForm
                      ref={refForm}
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      {completed ? <Alert color="success">{completed}</Alert> : null}
                      
                    <div className="mb-3">
                      <AvField
                          name="name"
                          label="Nombre"
                          value=""
                          type="text"
                          required
                          errorMessage="Campo requerido"
                          placeholder=""
                        />
                    </div>

                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Tipo</Label>
                        <Select
                          id="formrow-InputState"
                          options={ACCOUNT_TYPES}
                          onChange={handleSelectChangeTipo}
                        />
                      </div>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            name="balance_initial"
                            label="Saldo Inicial"
                            value="0.0"
                            type="number"
                            required
                            errorMessage="Campo requerido"
                            placeholder=""
                          />
                        </div>
                      </Col>
                      {
                        selectedTipo == 'client' || selectedTipo == 'provider' ? 
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">Proveedor/Cliente Relacionado</Label>
                              <Select
                                id="formrow-InputRelated"
                                options={ _.filter(enterprises, {type: selectedTipo}) }
                                onChange={handleSelectRelated}
                              />
                            </div>
                          </Col> :
                          ''
                      }
                      { selectedTipo == 'product' ?
                      <Col md={6}> 
                        <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Producto</Label>
                        <Select
                          id="formrow-InputState"
                          value={products.find(item => item.id === selectedProduct)}
                          options={products}
                          onChange={event => setSelectedProduct(event?.id)}
                          getOptionLabel={ option => option.name }
                          getOptionValue={ option => option.id }
                        />
                      </div> </Col> : null
                      }
                    </Row>

                    <div className="text-center">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> Guardar
                      </button>
                      <Link to="/administrative/accountings" className="btn w-md btn-sm btn-danger ms-2">
                        <i className="fas fa-reply"></i> Atrás
                      </Link>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

CatalogAccountingFormCreate.propTypes = {
  user: PropTypes.object
};

export default CatalogAccountingFormCreate
