import React from 'react';
import { Col, Row } from 'reactstrap';

export default function HeaderReport() {
    return (
        <>
            <h4 className="text-center mb-4 f-blue f-bold">MARCO ANTONIO LOPEZ CONTRERAS</h4>
            <Row>
                <Col md={2}>
                    <span className="d-block f-bold">Fecha: 13/01/2022</span>
                    <span className="d-block f-bold">Hora: 19:55:04</span>
                </Col>
                <Col className="text-center">
                    <p className="card-title">Corte de caja por usuario</p>
                </Col>
                <Col md={2} className="d-flex justify-content-end">
                    <span className="f-bold">Pagína: 1</span>
                </Col>
            </Row>
            <p className="mt-3">Viernes 1 de Enero del 2021</p>
        </>
    );
}
