import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment"

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 


export default function TableMovementItem({ item }) {

    const {
        fecha,
        banco,
        folio2,
        cobro
    } = item;

    return (
        <>
            <tr>
                <td className="text-end">
                    {fecha}
                </td>
                <td className="text-end">
                    {banco}
                </td>
                <td className="text-end">
                    {folio2}
                </td>
                <td className={"text-right "}>
                    { nf2.format(cobro) }
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object
};