import React from "react"
import PropTypes from 'prop-types'
import { Col, Label, FormGroup, InputGroup, Button } from "reactstrap"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_red.css"
import { useState } from "react"
import { removePropertiesDeep } from "@babel/types"
import { values } from 'underscore';
import { AvField } from 'availity-reactstrap-validation';

const RangeTime = props => {
  const [state, setState] = useState({
    id: props.id,
    start: props.startTime,
    end: props.endTime,
    fee_per_unit: props.fee_per_unit,
    isClient: props.isClient
  })

  if(props.startTime != state.start || props.endTime != state.end) {
    setState({
      id: props.id,
      start: props.startTime,
      end: props.endTime,
      fee_per_unit: props.fee_per_unit,
      isClient: props.isClient
    })
  }

  const handleChange = (selectDates, dateStr, instance) => {
    let newState = {...state}
    newState[instance.input.id] = dateStr
    setState(newState)
    props.onChange(newState)
  }

  const handleFeeChange = (event) => {
    let newState = {...state}
    newState['fee_per_unit'] = event.target.valueAsNumber
    setState(newState)
    props.onChange(newState)
  }

  const isValidStart = () => {
    if (state.end != null)
      return state.start != null ? true:false
    return true;
  }

  const isValidEnd = () => {
    if (state.start != null)
      return state.end != null ? true:false
    return true;
  }

  return (
    <React.Fragment>
      { props.label &&
      <>
      <Col lg={2} className="text-end pt-3">
        <b>{props.label}</b>
      </Col>
      <Col lg={2} className="text-end">
        <Label style={{paddingTop: '13px'}}>Horario</Label>
      </Col>
      </>
      }
      <Col lg={2} className="text-center">
        <FormGroup>
          <InputGroup className={isValidStart() ? 'pt-2' : 'pt-2 is-invalid'}>
            <Flatpickr
              className={isValidStart() ? "form-control d-block" : 'form-control d-block is-invalid'}
              placeholder="Seleccionar Horario"
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                defaultDate: state.start,
                maxDate: state.end,
                onChange: handleChange
              }}
              value={state.start}
              id='start'
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="mdi mdi-clock-outline" />
              </span>
            </div>
          </InputGroup>
          <div className="invalid-feedback">Debes completar el campo</div>
        </FormGroup>
      </Col>
      <Col lg={2} className="text-center">
        <FormGroup>
          <InputGroup className={isValidEnd() ? 'pt-2' : 'pt-2 is-invalid'}>
            <Flatpickr
              className={isValidEnd() ? "form-control d-block" : 'form-control d-block is-invalid'}
              placeholder="Seleccionar Horario"
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                defaultDate: state.end,
                minTime: state.start,
                onChange: handleChange
              }}
              value={state.end}
              id='end'
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="mdi mdi-clock-outline" />
              </span>
            </div>
          </InputGroup>
          <div className="invalid-feedback">Debes completar el campo</div>
        </FormGroup>
      </Col>
      { state.isClient ? (
        <>
          <Col lg={2} className="text-end">
            <Label style={{paddingTop: '10px'}}>
              Comisión por<br /> unidad ($)
            </Label>
          </Col>
          <Col lg={2} className="text-center" style={{paddingTop: '10px'}}>
            <input
              className="text-end form-control"
              name="fee_per_unit"
              value={state.fee_per_unit}
              type="number"
              required
              min="0"
              step={0.1}
              placeholder="$"
              onChange= {handleFeeChange}
            />
          </Col>
        </>
      ) : ''
      }
    </React.Fragment>
  )
}

RangeTime.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  onChange: PropTypes.func,
  isClient: PropTypes.bool,
  fee_per_unit: PropTypes.number
}

export default RangeTime
