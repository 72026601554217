import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import Select from 'react-select'

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation";
import { useState } from "react";
import { useRef } from "react";
import { Alert } from "reactstrap";
import { getRegions, postLandingClient, validateZipCode } from "helpers/backend_helper";

import logoImg from "assets/images/logo-cresta.png";

import toastr from "toastr"
import AddressForm from 'pages/Catalogos/common/AddressForm';

const SITE_KEY    = process.env.REACT_APP_CAPTCHA_SITE_KEY
const CAPTCHA_URL = process.env.REACT_APP_CAPTCHA_URL

const CatalogClientFormCreate = () => {

  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [regions, setRegions] = useState ([])
  const [tokenCaptcha, setTokenCaptcha] = useState('');
  const [newRegion, setNewRegion] = useState('')

  useEffect(() => {
    async function getData()  {
      const response = await getRegions()
      response.push({name: 'Otro', id: 0});
      setRegions(response.map( e => { return {label: e.name, value: e.id} } ))
    }

    getData();
  }, []);

  const handleValidSubmit = async (event, values) => {
    try {
      const resp = await validateZipCode({ cp: values.cp })
      if(!resp.cp) {
        toastr.error('Código postal no válido');
        return;
      };
    } catch (error) {
      toastr.error(error);
    }
    setBtnSaveLoad(true)
    try {
      setError(null)
      setCompleted(null)
      await postLandingClient({...values, region_id: selectedRegion, tokenCaptcha})
      setError(null)
      setCompleted('Se completo la operación, te llegará un correo con tus credenciales, sólo espera por la activación de tu cuenta por parte del distribuidor.')
      refForm && refForm.current.reset();
    } catch(error) {
      setError(error.toString())
      setCompleted(null)
      window.grecaptcha.reset()
    }
    setBtnSaveLoad(false)
  }

  const handleSelectChangeRegion = async (option) => {
    setSelectedRegion(option.value)
  }

  const handleLoaded = () => {
    window.grecaptcha.ready(() => {
        window.grecaptcha.render('html_element', {
            'sitekey' : SITE_KEY,
            'callback': token => setTokenCaptcha(token)
        });
    })
  }

  useEffect(() => {
      if(!window.grecaptcha){
        const script = document.createElement('script')
        script.src = CAPTCHA_URL
        script.addEventListener('load', handleLoaded)
        document.body.appendChild(script)
      }
  }, []);

  return (
    <React.Fragment>
        <div className="page-content">
            <MetaTags>
                <title>Registrar Cliente | Cresta </title>
            </MetaTags>

        <Container>     
            <Row>
                <Col className="col-12">
                    <div className="text p-4 text-center bg-warning bg-soft">
                        <img
                            src={logoImg}
                            alt=""
                            height="55"
                        />
                        <p className="pt-4">Registrar nuevo cliente</p>
                    </div>
                </Col>
            </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? <Alert color="success">{completed}</Alert> : null}
                      
                    <Row>
                      <Col md={6}>
                      <div className="mb-3">
                        <AvField
                            name="company_name"
                            label="Nombre Comercial o Razón Social"
                            value=""
                            type="text"
                            required
                            errorMessage="Campo requerido"
                            placeholder=""
                          />
                      </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                              name="contact_name"
                              label="Nombre completo Persona Contacto"
                              value=""
                              type="text"
                              required
                              errorMessage="Campo requerido"
                              placeholder=""
                            />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <AvField
                              name="phone"
                              label="Teléfono"
                              value=""
                              type="text"
                              required
                              errorMessage="Campo requerido"
                              placeholder=""
                            />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <AvField
                              name="phone2"
                              label="Teléfono 2"
                              value=""
                              type="text"
                              errorMessage="Campo requerido"
                              placeholder=""
                            />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <AvField
                              name="email"
                              label="Correo electrónico"
                              value=""
                              type="email"
                              required
                              errorMessage="Campo requerido"
                              placeholder=""
                            />
                        </div>
                      </Col>
                    </Row>

                    <AddressForm entityAddress={{}} ></AddressForm>

                    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                        <div id="html_element"></div>
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> Registrar
                      </button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container */}
      </div>
    </React.Fragment>
  )
}

CatalogClientFormCreate.propTypes = {
  provider: PropTypes.object
};

export default CatalogClientFormCreate;