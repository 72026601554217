import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useRequest from 'hooks/useRequest';

import { Button } from 'reactstrap';

export default function BtnDownloadPdf({ downloadPdf }) {

    const eleRef = useRef();
    
    const {
        data,
        isLoading,
        isError,
        req
    } = useRequest();

    const handleClick = () => {
        req( downloadPdf('pdf') ).then(() => {
            if(eleRef.current)
                eleRef.current.click();
        });
    }

    return (
        <>
            <Button
                onClick={ handleClick }
                className="btn btn-danger btn-sm float-right mb-3"
                style={{ marginLeft: '5px' }}
            >
                <i className={`${!isLoading && 'd-none' } bx bx-loader bx-spin font-size-16 align-middle me-2`}></i>
                <i className="mdi mdi-file-pdf-box-outline"></i> PDF
            </Button>
            { isError && (
                <span className="alert alert-danger d-block text-center">Ocurrio un error al descargar el archivo</span>
            )}
            { data && (
                <a 
                    rel="noreferrer"
                    href={ data.file }
                    ref={ eleRef }
                    className="d-none my-4"
                    target="_blank"
                ></a>
            )}
        </>
    );
}

BtnDownloadPdf.propTypes = {
    downloadPdf: PropTypes.func
};