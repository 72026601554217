import React from 'react';
import PropTypes from 'prop-types';

const nf2 = new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}); 

export default function TableTotal({ totals }) {

    const {
        pieces,
        kgs,
        pieces_client,
        kgs_client,
        bal_pieces,
        bal_kgs,
        cost,
        valuation
    } = totals;

    return (
        <tr className='table-head'>
            <th colSpan={5} className="text-danger f-bold text-right">Totales</th>
            <th className="text-danger f-bold text-right">
                { nf2.format(pieces) }
            </th>
            <th className="text-danger f-bold text-right">
                { nf2.format(pieces_client) }
            </th>
            <th className="text-danger f-bold text-right">
                { nf2.format(bal_pieces) }
            </th>
            <th className="text-danger f-bold text-right">
                -
            </th>
            <th className="text-danger f-bold text-right">
                { nf2.format(kgs) }
            </th>
            <th className="text-danger f-bold text-right">
                { nf2.format(kgs_client) }
            </th>
            <th className="text-danger f-bold text-right">
                { nf2.format(bal_kgs) }
            </th>
            <th className="text-danger f-bold text-right">
                { nf2.format(cost) }
            </th>
            <th className="text-danger f-bold text-right">
                { nf2.format(valuation) }
            </th>
            <th></th>
        </tr>
        
    );
}

TableTotal.propTypes = {
    totals: PropTypes.object
};