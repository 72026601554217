import React, { useState } from "react"
import { Col, Input, Label } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { getFilters } from "selectors"
import { useSelector } from "react-redux"

const defaultOption = { id: "", name: "Todos" }

const accountsLabel = {
  costs: "Gastos",
  banks: "Bancos",
  clients: "Clientes",
  providers: "Proveedores",
}

const NibbleFilter = ({
  form,
  md = 3,
  name = "",
  label = "",
  handleInput,
  options = [],
  isMulti = false,
  fieldName = "",
}) => {
  const [searchQty, setSearchQty] = useState("")

  const { costAccounts, bankAccounts, clientAccounts, providerAccounts } =
    useSelector(getFilters)

  const accountsData = {
    banks: bankAccounts,
    costs: costAccounts,
    clients: clientAccounts,
    providers: providerAccounts,
  }

  if (["banks", "costs", "clients", "providers"].includes(name)) {
    const fieldNamePlural = name
    const fieldNameSingular = name.slice(0, -1) // delete last character

    const accounts = accountsData[name]

    const optionsList = isMulti ? accounts : [defaultOption, ...accounts]

    const value = isMulti
      ? optionsList.filter(({ id }) => form[fieldNamePlural]?.includes(id))
      : optionsList.find(({ id }) => form[fieldNameSingular] == id)

    return (
      <Col lg={md}>
        <div className="mb-3">
          <label className="control-label small fw-bold p-0 m-0">
            {label ? label : accountsLabel[name]}
          </label>
          <Select
            value={value}
            onChange={value => {
              if (isMulti) {
                handleInput({
                  key: fieldNamePlural,
                  value: value.map(item => item.id),
                })
              } else {
                handleInput({
                  key: fieldNameSingular,
                  value: value ? value.id : defaultOption.id,
                })
              }
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            placeholder="Seleccionar"
            options={optionsList}
            classNamePrefix="select2-selection"
            isMulti={isMulti}
            isClearable
          />
        </div>
      </Col>
    )
  }

  if (name === "accounts") {
    const optionsList = isMulti ? options : [defaultOption, ...options]

    const value = isMulti
      ? optionsList.filter(({ id }) => form.accounts?.includes(id))
      : optionsList.find(({ id }) => form.account == id)

    return (
      <Col lg={md}>
        <div className="mb-3">
          <label className="control-label small fw-bold p-0 m-0">
            {label ? label : "Nombre Cliente / Proveedor / Gasto"}
          </label>
          <Select
            value={value}
            onChange={value => {
              if (isMulti) {
                handleInput({
                  key: "accounts",
                  value: value.map(item => item.id),
                })
              } else {
                handleInput({
                  key: "account",
                  value: value ? value.id : defaultOption.id,
                })
              }
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            placeholder="Seleccionar"
            options={optionsList}
            classNamePrefix="select2-selection"
            isClearable
            isMulti={isMulti}
          />
        </div>
      </Col>
    )
  }

  if (name === "select") {
    const optionsList = isMulti ? options : [defaultOption, ...options]

    const value = isMulti
      ? optionsList.filter(({ id }) => form[fieldName]?.includes(id))
      : optionsList.find(({ id }) => form[fieldName] == id)

    return (
      <Col lg={3}>
        <div className="mb-3">
          <label className="control-label small fw-bold p-0 m-0">{label}</label>
          <Select
            value={value}
            onChange={value => {
              if (isMulti) {
                handleInput({
                  key: fieldName,
                  value: value.map(item => item.id),
                })
              } else {
                handleInput({
                  key: fieldName,
                  value: value ? value.id : defaultOption.id,
                })
              }
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            placeholder="Seleccionar"
            options={optionsList}
            classNamePrefix="select2-selection"
            isClearable
            isMulti={isMulti}
          />
        </div>
      </Col>
    )
  }

  if (name === "searchQty")
    return (
      <Col md={md}>
        <div className="w-100">
          <Label
            className="small fw-bold p-0 m-0"
            htmlFor="formrow-email-Input"
          >
            {label ? label : "Buscar cantidad:"}
          </Label>
          <Input
            value={searchQty}
            onChange={e => {
              setSearchQty(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleInput({
                  key: "search_qty",
                  value: searchQty,
                })
              }
            }}
            name="search_qty"
            type="number"
            placeholder="Colocar cantidad + [enter]"
          />
        </div>
      </Col>
    )

  return null
}

NibbleFilter.propTypes = {
  md: PropTypes.number,
  label: PropTypes.string,
  fieldName: PropTypes.string,
  validation: PropTypes.object,
  fieldType: PropTypes.string,
  selectItems: PropTypes.array,
  selectIsClearable: PropTypes.bool,
  selectOptionValue: PropTypes.func,
  selectOptionLabel: PropTypes.func,
  selectIsSearchable: PropTypes.bool,
  selectStringify: PropTypes.func,
  postFileFunction: PropTypes.func,
  itemFile: PropTypes.any,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  getOptionValue: PropTypes.func,
  placeHolder: PropTypes.string,
  onChange: PropTypes.any,
  value: PropTypes.any,

  name: PropTypes.string,
  handleInput: PropTypes.func,
  form: PropTypes.object,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
}

export default NibbleFilter
