import { ADD_ADMINISTRATIVE_DATA, GET_ADMINISTRATIVE_DATA, GET_ADMINISTRATIVE_DATA_FAIL, GET_ADMINISTRATIVE_DATA_SUCESS, SET_ACTIVE_ADMINISTRATIVE_DATA, SET_ADMINISTRATIVE_FILTERS, SET_COLUMNS_VIEW, SET_FILTERS_ADMINISTRATIVE_DATA, SET_TYPE_ADMINISTRATIVE_DATA, UPDATE_ADMINISTRATIVE_DATA } from "./actionsTypes";

export const getAdministrativeData = () => ({
    type: GET_ADMINISTRATIVE_DATA
});

export const getAdministrativeDataSuccess = data => ({
    type: GET_ADMINISTRATIVE_DATA_SUCESS,
    payload: data
});

export const getAdministrativeDataFail = error => ({
    type: GET_ADMINISTRATIVE_DATA_FAIL,
    payload: error
});

export const setActiveTabAdminiData = active => ({
    type: SET_ACTIVE_ADMINISTRATIVE_DATA,
    payload: active
});

export const setTypeAdminiData = type => ({
    type: SET_TYPE_ADMINISTRATIVE_DATA,
    payload: type
});

export const addAdminiData = item => ({
    type: ADD_ADMINISTRATIVE_DATA,
    payload: item
});

export const updateAdminiData = item => ({
    type: UPDATE_ADMINISTRATIVE_DATA,
    payload: item
});

export const setFiltersAdminiData = filters => ({
    type: SET_FILTERS_ADMINISTRATIVE_DATA,
    payload: filters
});

export const setAdministrativeFilters = filter => ({
    type: SET_ADMINISTRATIVE_FILTERS,
    payload: filter
});

export const setColumnsView = cols => ({
    type: SET_COLUMNS_VIEW,
    payload: cols
});
