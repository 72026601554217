import React from 'react';
import PropTypes from 'prop-types';

export default function TableHeader({ setColumnOrder, columnOrder }) {
    return (
        <>
            <tr className='table-head'>
                <th className="text-center" style={{minWidth: '100px'}}>
                    No de Dcto.
                </th>
                <th className="text-center" style={{minWidth: '140px'}}>
                    Fecha
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="mov_date"
                    />
                </th>
                <th className="text-center" style={{minWidth: '130px'}}>
                    Tipo
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Folio de  venta
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="folio"
                    />
                </th>
                <th className="text-center" style={{minWidth: '100px'}}>
                    Dias Vencidos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="dias_vencidos"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Pzs
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="pieces"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    KG
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="kgs"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Precio
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="pv"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Venta Total
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="total_vta"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Merma
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="merma"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Mortalidad
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="mortalidad"
                    />
                </th>
                <th className="text-center" style={{minWidth: '115px'}}>
                    Otros descuentos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="nota_credito"
                    />
                </th>
                <th className="text-center" style={{minWidth: '100px'}}>
                    Otros cargos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="nota_cargo"
                    />
                </th>
                <th className="text-center" style={{minWidth: '100px'}}>
                    Venta Neta
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="venta_neta"
                    />
                </th>
                <th className="text-center" style={{minWidth: '100px'}}>
                    Pagos
                </th>
                <th className="text-center" style={{minWidth: '115px'}}>
                    Saldo por documento
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="balance"
                    />
                </th>
                
            </tr>
        </>
    );
}

TableHeader.propTypes = {
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};

const ArrowsOrder = ({ setColumnOrder, column, columnOrder }) => {
    
    const isArrowDownActive = (columnOrder.column === column && columnOrder.order === 'desc');
    const isArrowUpActive = (columnOrder.column === column && columnOrder.order === 'asc');

    return (
        <span className="order arrows-order">
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'desc'
                })}
                className={`dropdown arrows-order__asc ${ isArrowDownActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'asc'
                })}
                className={`dropup arrows-order__desc ${isArrowUpActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
        </span>
    )
}

ArrowsOrder.propTypes = {
    column: PropTypes.string,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};