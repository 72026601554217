import React from "react"
import MetaTags from "react-meta-tags"

import { Card, Col, Container, Row, CardBody, Label, Form } from "reactstrap"

//Import Breadcrumb
import { PropTypes } from "prop-types"
import BreadcrumbNav from "components/Common/BreadcrumbNav"

import EnterpriseForm from "./enterprise-form"

const breadcrumbItems = [
  { id: "1", title: "Catálogos", link: "", active: false },
  { id: "2", title: "Empresas", link: "/catalogs/enterprises", active: false },
  { id: "3", title: "Crear", link: "", active: true },
]


const CatalogEnterprisesFormCreate = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Catalogo de Empresas</title>
        </MetaTags>
        <Container fluid={true}>
          <BreadcrumbNav title="Empresas" items={breadcrumbItems} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <EnterpriseForm 
                    isNew={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

CatalogEnterprisesFormCreate.propTypes = {
  provider: PropTypes.object,
}

export default CatalogEnterprisesFormCreate
