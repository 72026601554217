import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import {
  Row,
  Col,
  Label,
  FormGroup,
  InputGroup,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap"

import Select from "react-select"
import * as moment from "moment"
import Flatpickr from "react-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"

import {
  getAdministrativeData,
  setAdministrativeFilters,
} from "store/catalogs/administrative-data/actions"

import "flatpickr/dist/themes/material_red.css"
import ModalFile from "../common/ModalFile"
import { monthsFilterValues } from "common/data/months"
import MonthButton from "../common/MonthButton"
import { getFilters } from "selectors"

import DownloadExcel from "../common/DownloadExcel"

const initSelect = { id: "", value: "" }

/* const initialPeriod =
  moment().startOf("year").format("YYYY-MM-DD") +
  "/" +
  moment().endOf("year").format("YYYY-MM-DD") */

const initialPeriod =
  moment().startOf("month").format("YYYY-MM-DD") +
  "/" +
  moment().endOf("month").format("YYYY-MM-DD")

const curYear = moment().year()

const optionsYears = Array(8)
  .fill(0)
  .map((_, i) => {
    return { id: curYear - i, name: curYear - i }
  })

const Filters = ({ toggleRightCanvas, setIsNew }) => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const [userType, setUserType] = useState(user.user?.type || user.type)
  const [userTypeRol, setUserTypeRol] = useState(
    user.user?.type_rol || user.type_rol
  )
  const [searchQty, setSearchQty] = useState("")
  const [isDownloadExcel, setIsDownloadExcel] = useState(false)

  const dispatch = useDispatch()

  const {
    data,
    activeTab,
    filters,
    bankAccounts,
    clientAccounts,
    providerAccounts,
    costAccounts,
    paymentType,
  } = useSelector(getFilters)

  const [openModal, setOpenModal] = useState(false)

  const handleDatePicker = dates => {
    if (dates.length !== 2) return

    const parseDates = dates
      .map(date => moment(date).format("YYYY-MM-DD"))
      .join("/")
    dispatch(setAdministrativeFilters({ dates: parseDates, page: 1 }))
  }

  const handleFilters = ({ key, val }) => {
    dispatch(
      setAdministrativeFilters({
        [key]: val,
        page: 1,
      })
    )
    //dispatch(getAdministrativeData())
  }

  const handleSelectYear = year => {
    const dates = filters.dates.split("/")
    const start = moment(dates[0])
    const end = moment(dates[1])
    start.year(year)
    end.year(year)
    const parseDates =
      start.format("YYYY-MM-DD") + "/" + end.format("YYYY-MM-DD")
    handleFilters({
      key: "dates",
      val: parseDates,
    })
  }

  useEffect(() => {
    dispatch(
      setAdministrativeFilters({ dates: initialPeriod, client: initSelect })
    )
  }, [])

  useEffect(() => {
    if (!filters.dates) return

    dispatch(getAdministrativeData())
  }, [
    filters.page,
    filters.dates,
    filters.bank.id,
    filters.client.id,
    filters.provider.id,
    filters.account_id.id,
    filters.mostrar.id,
    filters.search_qty,
    filters.payment_type.id,
  ])

  return (
    <>
      <Row>
        <Col md={"auto"}>
          <div className="w-100 mt-1">
            <Label className="small fw-bold p-0 m-0 d-block">Año - Mes</Label>
            <UncontrolledDropdown
              style={{ cursor: "pointer" }}
              className="d-inline-block me-1 pt-0 mt-0"
            >
              <DropdownToggle
                href="#"
                className="card-drop action-points"
                tag="i"
              >
                <button className="btn btn-sm btn-danger">
                  {filters.year.name} <i className="mdi mdi-chevron-down"></i>
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-enddd actions-menu">
                {optionsYears.map((val, key) => {
                  return (
                    <DropdownItem
                      key={key}
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        handleFilters({
                          key: "year",
                          val: val,
                        })
                        handleSelectYear(val.id)
                      }}
                    >
                      {val.name}
                    </DropdownItem>
                  )
                })}
              </DropdownMenu>
            </UncontrolledDropdown>

            {monthsFilterValues.map((val, key) => {
              return (
                <MonthButton
                  key={key}
                  month={key}
                  monthString={val}
                  year={filters.year.name}
                  handler={period => {
                    handleFilters({
                      key: "dates",
                      val: period,
                    })
                  }}
                />
              )
            })}
          </div>
        </Col>
        <Col lg={3} className="">
          <div className="w-100">
            <Label className="small fw-bold p-0 m-0">Periodo</Label>
            <InputGroup className="">
              <Flatpickr
                className="form-control d-block w-100"
                placeholder=""
                onChange={dates => {
                  if (dates.length == 0) return
                  handleFilters({
                    key: "dates",
                    val: rangeDatePicker(
                      dates.length == 1 ? [dates[0], dates[0]] : dates
                    ),
                  })
                }}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                  locale: Spanish,
                  defaultDate: initialPeriod.split("/"),
                  allowInput: true,
                }}
              />
            </InputGroup>
          </div>
        </Col>
        <Col className="text-end">
          <UncontrolledDropdown style={{ cursor: "pointer" }}>
            <DropdownToggle
              href="#"
              className="card-drop action-points"
              tag="i"
              onClick={() => setIsDownloadExcel(false)}
            >
              <button className="btn btn-sm btn-danger">
                Registros <i className="mdi mdi-chevron-down"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-enddd actions-menu">
              {userTypeRol != 3 && activeTab !== "bancos_cargos" && (
                <>
                  <DropdownItem
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      toggleRightCanvas()
                      setIsNew(true)
                    }}
                  >
                    <i className="bx bx-plus font-size-16 text-danger" /> Nuevo
                    registro
                  </DropdownItem>
                  <DropdownItem
                    className="d-none"
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setOpenModal(true)
                    }}
                  >
                    <i className="bx bx-upload font-size-16 text-danger"></i>{" "}
                    Importar
                  </DropdownItem>
                </>
              )}
              {data.length > 0 && (
                <DropdownItem
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    setIsDownloadExcel(true)
                  }}
                >
                  <i className="mdi mdi-microsoft-excel font-size-16 text-danger"></i>{" "}
                  Exportar
                </DropdownItem>
              )}
            </DropdownMenu>
            
          </UncontrolledDropdown>
          {isDownloadExcel && (
            <DownloadExcel setIsDownloadExcel={setIsDownloadExcel} />
          )}
        </Col>
      </Row>
      <Row className="mt-1">
        <Col lg={3}>
          <div className="w-100">
            <Label className="small fw-bold p-0 m-0">Cuenta Contable</Label>
            <Select
              value={filters.account_id}
              onChange={event => {
                const account_id = event ? event : { id: "", name: "Todas" }
                dispatch(setAdministrativeFilters({ account_id, page: 1 }))
              }}
              options={[{ id: "", name: "Todas" }, ...costAccounts]}
              getOptionLabel={option => option?.name}
              getOptionValue={option => option?.id}
              placeholder="Seleccionar"
              classNamePrefix="select2-selection"
              isClearable={true}
            />
          </div>
        </Col>
        <Col lg={3}>
          <div className="w-100">
            <Label className="small fw-bold p-0 m-0">Proveedor</Label>
            <Select
              value={filters.provider}
              onChange={event => {
                const defaultSel = { id: "", name: "Todos" }

                handleFilters({
                  key: "provider",
                  val: event ? event : defaultSel,
                })
              }}
              options={[{ id: "", name: "Todos" }, ...providerAccounts]}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder=""
              classNamePrefix="select2-selection"
              isClearable={true}
            />
          </div>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <Label className="small fw-bold p-0 m-0">Banco</Label>
            <Select
              value={filters.bank}
              onChange={event => {
                const defaultSel = { id: "", name: "Todos" }

                handleFilters({
                  key: "bank",
                  val: event ? event : defaultSel,
                })
              }}
              options={[{ id: "", name: "Todos" }, ...bankAccounts]}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder="Seleccionar"
              classNamePrefix="select2-selection"
              isClearable={true}
            />
          </div>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <Label className="small fw-bold p-0 m-0">Forma de Pago </Label>
            <Select
              value={filters.payment_type}
              onChange={event => {
                const defaultSel = { id: "", name: "Todos" }

                handleFilters({
                  key: "payment_type",
                  val: event ? event : defaultSel,
                })
              }}
              options={[{ id: "", name: "Todos" }, ...paymentType]}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder="Seleccionar"
              classNamePrefix="select2-selection"
              isClearable={true}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={3}>
          <div className="mb-3">
            <Label className="small fw-bold p-0 m-0">Mostrar</Label>
            <Select
              value={filters.mostrar}
              onChange={event => {
                const defaultSel = { id: "", name: "Todo" }

                handleFilters({
                  key: "mostrar",
                  val: event ? event : defaultSel,
                })
              }}
              options={[
                { id: "", name: "Todo" },
                { id: "Solo importes", name: "Solo importes" },
                { id: "Solo iva", name: "Solo iva" },
                { id: "Solo totales", name: "Solo totales" },
                { id: "Solo pagos", name: "Solo pagos" },
              ]}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder="Seleccionar"
              classNamePrefix="select2-selection"
              isClearable={true}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="w-100">
            <Label
              className="small fw-bold p-0 m-0"
              htmlFor="formrow-email-Input"
            >
              Buscar cantidad:
            </Label>
            <Input
              value={searchQty}
              onChange={e => {
                setSearchQty(e.target.value)
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  handleFilters({
                    key: "search_qty",
                    val: searchQty,
                  })
                }
              }}
              name="search_qty"
              type="number"
              placeholder="Colocar cantidad + [enter]"
            />
          </div>
        </Col>
      </Row>

      <ModalFile isOpen={openModal} setOpenModal={setOpenModal} />
    </>
  )
}

Filters.propTypes = {
  toggleRightCanvas: PropTypes.func,
  setIsNew: PropTypes.func,
}

export default Filters
