import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import classnames from "classnames"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Button,
  Form,
  Alert,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

import useRequest from "hooks/useRequest"
import { getAdministrativeData } from "store/catalogs/administrative-data/actions"
import { putCost, postCost, getMovCostNextFolio } from "helpers/backend_helper"

import CostForm from "./CostForm"
import ChargeForm from "./ChargeForm"
import {
  cobroValidation,
  gastoValidation,
  costFormInitialValues as initialValues,
} from "./static"

import "flatpickr/dist/themes/material_red.css"

const getDefaultTab = (isNew, dataRow) => {
  if (isNew) return "registro-gasto"
  if (dataRow?.amount !== "0.00") return "registro-gasto"
  return "registro-cobro"
}

const ClientForm = ({ dataRow, isNew, toggleRightCanvas }) => {
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState(getDefaultTab(isNew, dataRow))

  const { isError, isLoading, isSubmitted, msgError, req } = useRequest()

  const onSubmit = formValues => {
    if (isNew) {
      req(postCost(formValues)).then(async () => {
        dispatch(getAdministrativeData())
        validation.resetForm()
        getNextFolio()
      })
    } else {
      req(putCost(formValues.id, formValues)).then(() => {
        dispatch(getAdministrativeData())
      })
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: isNew ? initialValues : dataRow,
    validationSchema:
      activeTab === "registro-gasto" ? gastoValidation : cobroValidation,
    onSubmit,
  })

  const getNextFolio = () => {
    async function getDataFolio() {
      const response = await getMovCostNextFolio()
      validation.setFieldValue("folio", response.folio)
    }

    if (isNew) {
      getDataFolio()
    }
  }

  const resetFormOnChangeTab = () => {
    if (!isNew) return

    validation.resetForm({
      values: {
        ...initialValues,
        folio: validation.values.folio,
      },
    })
  }

  useEffect(getNextFolio, [])
  useEffect(resetFormOnChangeTab, [activeTab, isNew])

  return (
    <>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        {isError ? (
          <Alert className="alert alert-danger">{msgError}</Alert>
        ) : (
          ""
        )}
        {isSubmitted ? (
          <Alert className="alert alert-success">
            {isNew ? "Registro creado" : "Registro actualizado"}
          </Alert>
        ) : (
          ""
        )}
        <Row>
          <Col md={12} className="pb-3">
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "registro-gasto",
                  })}
                  onClick={() => setActiveTab("registro-gasto")}
                  disabled={!isNew && activeTab !== "registro-gasto"}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Registro de gasto</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "registro-cobro",
                  })}
                  onClick={() => setActiveTab("registro-cobro")}
                  disabled={!isNew && activeTab !== "registro-cobro"}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Registro de cobro</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        {activeTab === "registro-gasto" && <CostForm validation={validation} />}
        {activeTab === "registro-cobro" && (
          <ChargeForm validation={validation} />
        )}
        <Row>
          <Col md={12}>
            <p>
              <strong>*</strong> Campos obligatorios
            </p>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              onClick={toggleRightCanvas}
              color="secondary"
              className="btn btn-secondary btn"
              style={{ marginRight: "10px" }}
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              className="btn btn-danger btn"
              disabled={isLoading}
              type="submit"
            >
              <i
                className={`${
                  isLoading ? "" : "d-none"
                } bx bx-loader bx-spin font-size-16 align-middle me-2`}
              ></i>
              {isLoading ? "Guardando" : "Guardar"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

ClientForm.propTypes = {
  toggleRightCanvas: PropTypes.func,
  dataRow: PropTypes.object,
  isNew: PropTypes.bool,
}

export default ClientForm
