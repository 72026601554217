import moment from "moment"

export const header_excel = {
  Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export const header_pdf = {
  Accept: "application/pdf",
}

export const initialPeriod =
  moment().startOf("month").format("YYYY-MM-DD") +
  "/" +
  moment().endOf("month").format("YYYY-MM-DD")
