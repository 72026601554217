import React from 'react';
import PropTypes from 'prop-types';

import '../common/arrows-order.scss';

export default function TableHeader({ setColumnOrder, columnOrder }) {
    return (
        <>
            <tr className='table-head'>
                <th className="text-center" style={{minWidth: '100px'}}>
                    Fecha
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="mov_date"
                    />
                </th>
                <th className="text-center" style={{minWidth: '70px'}}>
                    Folio
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="folio"
                    />
                </th>
                <th className="text-center">
                    Código
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="client_code"
                    />
                </th>
                <th style={{minWidth: '100px'}}>
                    Cliente
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="client_account"
                    />
                </th>
                <th style={{minWidth: '100px'}}>
                    Proveedor
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="provider_account"
                    />
                </th>
                <th className="" style={{minWidth: '100px', wordBreak: 'break-all'}}>
                    Producto
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="product"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Promedio por pieza
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="prom"
                    />
                </th>
                <th className="text-right" style={{minWidth: '80px'}}>
                    Piezas
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="pieces"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Kg.
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="kgs"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Precio costo
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="price_cost"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Precio Venta
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="pv"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Utilidad x unidad
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="diferencia"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Costo M.N.
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="cost_mn"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Venta M.N.
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="total_vta"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Merma
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="decrease"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Mortalidad
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="mortalidad_descuento"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Otros descuentos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="nota_credito"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Otros cargos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="nota_cargo"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Ventas netas
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="venta_neta"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Utilidad
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="utilidad"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    %
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="porcentaje"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Unidades Mortalidad
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="mortalidad"
                    />
                </th>
                <th className="text-center" style={{minWidth: '90px'}}>
                    % de Mortalidad
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="mortalidad_percentaje"
                    />
                </th>
            </tr>
        </>
    );
}

TableHeader.propTypes = {
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};

const ArrowsOrder = ({ setColumnOrder, column, columnOrder }) => {
    
    const isArrowDownActive = (columnOrder.column === column && columnOrder.order === 'desc');
    const isArrowUpActive = (columnOrder.column === column && columnOrder.order === 'asc');

    return (
        <div className="order arrows-order text-center">
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'desc'
                })}
                className={`p-1 dropdown arrows-order__asc ${ isArrowDownActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'asc'
                })}
                className={`dropup p-1 arrows-order__desc ${isArrowUpActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
        </div>
    )
}

ArrowsOrder.propTypes = {
    column: PropTypes.string,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};