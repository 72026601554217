import React from 'react';
import PropTypes from 'prop-types';

export default function TableHeader({ setColumnOrder, columnOrder }) {
    return (
        <>
            <tr className='table-head'>
                <th className="text-center" style={{minWidth: '90px'}}>
                    Clave
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="skey"
                    />
                </th>
                <th className="text-center" style={{minWidth: '150px'}}>
                    Proveedor
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="provider"
                    />
                </th>
                <th className="text-center" style={{minWidth: '170px'}}>
                    Pzs
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="pieces"
                    />
                </th>
                <th className="text-center" style={{minWidth: '170px'}}>
                    Kgs
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="kgs"
                    />
                </th>
                <th className="text-center" style={{minWidth: '170px'}}>
                    Compra total
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="total"
                    />
                </th>
                <th className="text-center" style={{minWidth: '170px'}}>
                    Merma
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="decrease"
                    />
                </th>
                <th className="text-center" style={{minWidth: '170px'}}>
                    Otros descuentos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="discount"
                    />
                </th>
                <th className="text-center" style={{minWidth: '170px'}}>
                    Otros cargos
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="other_charges"
                    />
                </th>
                <th className="text-center" style={{minWidth: '170px'}}>
                    Compra neta
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="net"
                    />
                </th>
                <th className="text-center" style={{minWidth: '170px'}}>
                    % Compra
                </th>
                <th className="text-center" style={{minWidth: '170px'}}>
                    % Mortalidad
                </th>
            </tr>
        </>
    );
}

TableHeader.propTypes = {
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};

const ArrowsOrder = ({ setColumnOrder, column, columnOrder }) => {
    
    const isArrowDownActive = (columnOrder.column === column && columnOrder.order === 'desc');
    const isArrowUpActive = (columnOrder.column === column && columnOrder.order === 'asc');

    return (
        <span className="order arrows-order">
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'desc'
                })}
                className={`dropdown arrows-order__asc ${ isArrowDownActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'asc'
                })}
                className={`dropup arrows-order__desc ${isArrowUpActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
        </span>
    )
}

ArrowsOrder.propTypes = {
    column: PropTypes.string,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object
};