import { GET_ACCOUNTING_ACCOUNTS, GET_ACCOUNTING_ACCOUNTS_SUCCESS, GET_ACCOUNTING_ACCOUNTS_FAIL } from './actionTypes';

const initialState = {
    clientAccounts: [],
    providerAccounts: [],
    paymentAccounts: [],
    bankAccounts: [],
    costAccounts: [],
    ui:{
        isLoading: false,
        msgError: ''
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACCOUNTING_ACCOUNTS: return {
            ...state,
            ui: {
                ...state.ui,
                isLoading: true
            }
        }
        case GET_ACCOUNTING_ACCOUNTS_SUCCESS: return {
            ...state,
            ui: {
                msgError: '',
                isLoading: false
            },
            ...action.payload
        }
        case GET_ACCOUNTING_ACCOUNTS_FAIL: return {
            ...state,
            ui: {
                msgError: action.payload,
                isLoading: false
            }
        }
        default: return state;
    }
}

export default reducer;