import React from "react"
import PropTypes from 'prop-types'
import { Alert, Col, Row, Table } from "reactstrap"
import { useState } from "react"
import RatingCustom from "components/Common/RatingCustom"
import _ from "lodash"
const starStyle = {}
const numberFormat = new Intl.NumberFormat('es-MX', { minimumFractionDigits: 2 });


const ResumeTrack = props => {
  const [popover, setpopover] = useState({});
  //const [state, setState] = useState([...props.rows])
  const state = props.rows;
  const orders = props.orders;
  //const ordersParent = props.orders;
  //const [orders, setOrders] = useState(props.orders)
  const [values, setValues] = useState({
    provider_id: null,
    products: {},
    timeDelivery: '',
  })
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)

  console.log('state',state, 'orders', orders)
  const grouped = _.groupBy(orders, 'client_id');
  return (
    <React.Fragment>
    { state.length > 0 &&
      <div className="mt-3">

            {(state || []).map((formDaily, keyDaily) => (
              <>
              <Row>
                <Col lg={12} className="text-center">
                    {error ? <Alert color="danger" className="mt-3">{error}</Alert> : null}
                    <div className="mt-1">
                      <div className="bg-dark text-light pt-1 pb-1 mb-1 text-center"><b>{ formDaily.provider.company_name } - Sucursal { state[0].farm.name }</b></div>
                    </div>
                </Col>
              </Row>
              
              <Row key={keyDaily} className="">
                <Col lg={12}>
                  <div className="bg-dark bg-soft text-dark pt-1 pb-1 mb-1 text-center"><b>{formDaily.product}</b></div>
                </Col>
                <Col lg={4}>
                  <div>Cantidad del dia: <b>{formDaily.quantity} Pzas</b></div>
                  <div>Disponible: <b>{formDaily.available_product} Pzas</b></div>
                </Col>
                <Col lg={4}>
                  <div>Peso Promedio: <b>{formDaily.weight} - {formDaily.weight_max} Kg.</b></div>
                  <div>Observaciones: <b>{formDaily.observations}</b></div><br/>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col lg={6}>
                      Pigmento:
                    </Col>
                    <Col lg={6}>
                      <RatingCustom
                        max={3}
                        disabled={true}
                        defaultRating={formDaily.quality}
                        styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                        ActiveComponent={
                          <i
                            className="mdi mdi-star text-warning"
                            style={{}}
                          />
                        }
                        InActiveComponent={
                          <i
                            className="mdi mdi-star-outline text-muted"
                            style={{}}
                          />
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      Calidad:
                    </Col>
                    <Col lg={6}>
                      <RatingCustom
                        max={3}
                        disabled={true}
                        defaultRating={formDaily.quality_2}
                        styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                        ActiveComponent={
                          <i
                            className="mdi mdi-star text-warning"
                            style={{}}
                          />
                        }
                        InActiveComponent={
                          <i
                            className="mdi mdi-star-outline text-muted"
                            style={{}}
                          />
                        }
                      />{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      Salud del Ave:
                    </Col>
                    <Col lg={6}>
                      <RatingCustom
                        max={3}
                        disabled={true}
                        defaultRating={formDaily.quality_3}
                        styleConfig={{starContainer: { fontSize: '15px', justifyContent: 'center' }}}
                        ActiveComponent={
                          <i
                            className="mdi mdi-star text-warning"
                            style={{}}
                          />
                        }
                        InActiveComponent={
                          <i
                            className="mdi mdi-star-outline text-muted"
                            style={{}}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={ 12 } className="mt-2 mb-2 text-lg-center text-center">
                  <div className="table-responsive">
                  <Table className="table table-striped table-sm w-100">
                    <thead className="table-light">
                        <tr className="table-primary">
                          <th colSpan={8}>Camiones</th>
                        </tr>
                        <tr className="table-primary">
                          <th>Camión</th>
                          <th>Unidades / Camión</th>
                          <th className="">Pedido</th>
                          <th className="">Cliente</th>
                          <th className="">Destino</th>
                          <th>Precio</th>
                          <th>Total</th>
                          <th className="">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                      {(formDaily.quantities || []).map((truck, key) => (
                        <React.Fragment key={key}>
                          <tr>
                            <td style={{width: '80px'}}>
                              {truck.truck_name}
                            </td>
                            <td style={{width: '100px'}}>
                              <b>{truck.truck_units}</b>
                            </td>
                            <td>
                            {(truck.truck_name in formDaily.truckDeliveryList ? formDaily.truckDeliveryList[truck.truck_name]:[]).map((orderItem, key2) => (
                                <div key={'a'+key2} className="mb-2">
                                  <span className="badge bg-danger ps-1 pe-1 me-1">{key2 + 1}</span> {orderItem?.truckAsigned[truck.truck_name]}Pzas
                                </div>
                              ))}
                            </td>
                            <td className="">
                              {(truck.truck_name in formDaily.truckDeliveryList ? formDaily.truckDeliveryList[truck.truck_name]:[]).map((orderItem, key2) => (
                                <div key={'a'+key2} className="mb-2">
                                  <b>{orderItem.order.client.company_name}</b>
                                </div>
                              ))}
                            </td>
                            <td className="">
                              {(truck.truck_name in formDaily.truckDeliveryList ? formDaily.truckDeliveryList[truck.truck_name]:[]).map((orderItem, key2) => (
                                <div key={'a'+key2} className="mb-2">
                                  <b>Día {orderItem.delivery_date.substr(8)} | {orderItem.delivered ? orderItem?.delivered_region:''} {orderItem.delivered ? orderItem?.delivered_subregion:''}</b> <br />
                                  <i className="d-none">{orderItem.delivered ? orderItem?.order.address?.address_string:''}</i>
                                </div>
                              ))}
                            </td>
                            <td style={{width: '100px'}}>
                              {(truck.truck_name in formDaily.truckDeliveryList ? formDaily.truckDeliveryList[truck.truck_name]:[]).map((orderItem, key2) => (
                                <div key={'a'+key2} className="mb-2">
                                  ${numberFormat.format(orderItem?.unit_price_shipped)}
                                </div>
                              ))}
                            </td>
                            <td style={{width: '100px'}}>
                              {(truck.truck_name in formDaily.truckDeliveryList ? formDaily.truckDeliveryList[truck.truck_name]:[]).map((orderItem, key2) => (
                                <div key={'a'+key2} className="mb-2">
                                  $ {numberFormat.format(orderItem?.total)}
                                </div>
                              ))}
                            </td>
                            <td style={{width: '50px'}}>
                              <span className={truck.status=='pending' ? 'badge rounded-pill bg-warning':'badge rounded-pill bg-success'}>{truck.status_string}</span>
                            </td>
                          </tr> 
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                  </div>
                </Col>
              </Row>
              </>
            ))}
      </div>
    }
  </React.Fragment>
)}

ResumeTrack.propTypes = {
  rows: PropTypes.array,
  orders: PropTypes.array,
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  date: PropTypes.string.isRequired,
  toggleModalAuth: PropTypes.func.isRequired,
  handleAuthTruck: PropTypes.func.isRequired,
}

export default ResumeTrack
