import React from 'react';
import PropTypes from 'prop-types';

const nf = new Intl.NumberFormat('es-MX');

export default function TableMovementItem({ item }) {

    const {
        key,
        client,
        account,
        amount
    } = item;

    return (
        <>
            <tr>
                <td className="text-center">
                    { key }
                </td>
                <td className="text-center">
                    { client }
                </td>
                <td className="text-center">
                    { account }
                </td>
                <td className="text-right">
                    { nf.format(amount) }
                </td>
            </tr>
        </>
    );
}

TableMovementItem.propTypes = {
    item: PropTypes.object
};