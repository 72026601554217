import React, { useState } from "react"
import PropTypes from "prop-types"
import Flatpickr from "react-flatpickr"
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  InputGroup,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import moment from "moment"
import { monthsFilterValues } from "common/data/months"
import MonthButton from "pages/Administrative/common/MonthButton"

const curYear = moment().year()

const optionsYears = Array(8)
  .fill(0)
  .map((_, i) => {
    return { id: curYear - i, name: curYear - i }
  })

const NibblePeriodFilter = ({
  form,
  handleSpecificInput,
  handleDatePeriod,
  setPeriod,
}) => {
  const [year, setYear] = useState({ id: curYear, name: curYear })

  const handleSelectYear = year => {
    const dates = form.period.split("/")
    const start = moment(dates[0])
    const end = moment(dates[1])

    start.year(year)
    end.year(year)

    const parseDates =
      start.format("YYYY-MM-DD") + "/" + end.format("YYYY-MM-DD")

    handleSpecificInput({
      key: "period",
      value: parseDates,
    })

    setPeriod(parseDates)
  }

  return (
    <>
      <Col md={"auto"}>
        <div className="w-100 mt-1">
          <Label className="small fw-bold p-0 m-0 d-block">Año - Mes</Label>
          <UncontrolledDropdown
            style={{ cursor: "pointer" }}
            className="d-inline-block me-1 pt-0 mt-0"
          >
            <DropdownToggle
              href="#"
              className="card-drop action-points"
              tag="i"
            >
              <button className="btn btn-sm btn-danger">
                {year.name} <i className="mdi mdi-chevron-down"></i>
              </button>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-enddd actions-menu">
              {optionsYears.map((val, key) => {
                return (
                  <DropdownItem
                    key={key}
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setYear({
                        id: val.id,
                        name: val.name,
                      })
                      handleSelectYear(val.id)
                    }}
                  >
                    {val.name}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
          {monthsFilterValues.map((val, key) => {
            return (
              <MonthButton
                key={key}
                month={key}
                monthString={val}
                year={year.id}
                handler={periodo => {
                  handleSpecificInput({
                    key: "period",
                    value: periodo,
                  })

                  setPeriod(periodo)
                }}
              />
            )
          })}
        </div>
      </Col>
      <Col lg={3}>
        <FormGroup className="mb-4">
          <Label className="small fw-bold p-0 m-0">Periodo</Label>
          <InputGroup>
            <Flatpickr
              className="form-control d-block"
              placeholder=""
              onChange={dates =>
                handleDatePeriod({
                  key: "period",
                  dates,
                })
              }
              value={form.period ? form.period.split("/") : []}
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                locale: Spanish,
                defaultDate: form.period ? form.period.split("/") : [],
                allowInput: true,
              }}
            />
          </InputGroup>
        </FormGroup>
      </Col>
    </>
  )
}

NibblePeriodFilter.propTypes = {
  form: PropTypes.object,
  handleSpecificInput: PropTypes.func,
  handleDatePeriod: PropTypes.func,
  setPeriod: PropTypes.func,
}

export default NibblePeriodFilter
