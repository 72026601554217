export const DocumentoRelacionado = {
  Error: "",
  TaxObject: "01",
  Uuid: "",
  Serie: "",
  Folio: "",
  PaymentMethod: "PUE",
  PartialityNumber: "",
  PreviousBalanceAmount: "",
  AmountPaid: "",
  ImpSaldoInsoluto: "",
}
