import React from 'react';

export default function TableHeader() {
    return (
        <>
            <tr className="report-table__header">
                <th>Tipo</th>
                <th>Subtipo</th>
                <th className="text-right">Nota de venta</th>
                <th className="text-right">F. de contado</th>
                <th className="text-right">F. de Crédito</th>
                <th className="text-right">Anticipo</th>
                <th className="text-right">Importe M.N.</th>
            </tr>
        </>
    );
}