import React from "react";
import PropTypes from 'prop-types';

const numberFormat = new Intl.NumberFormat('es-MX', { minimumFractionDigits: 2 });

const OrderItem = ({ id, product, peso, quantity, unit_price, total }) => {
    return (
        <tr key={ id }>
            <td>{ id }</td>
            <td>{ product }</td>
            <td className="text-center">{ peso }</td>
            <td className="text-end">{ quantity }</td>
            <td className="text-end">{ unit_price }</td>
            <td className="text-end">${ numberFormat.format(total) }</td>
        </tr>
    )
}

OrderItem.propTypes = {
    id: PropTypes.number,
    product: PropTypes.string,
    quantity: PropTypes.number,
    unit_price: PropTypes.number,
    total: PropTypes.number,
    peso: PropTypes.number,
};

export {
    OrderItem
};