import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Timer = props => {
  const [time, setTime] = useState(props.timeLeft)
  const [curTime, setCurTime] = useState(0)

  useEffect(() => {
    if (time === 0) { props.callback(true); return; }
    const timer = setTimeout(() => {
      setCurTime(curTime + 1)
      if ((time - curTime) < 0)
        props.callback(true)
    }, 1000);
  });

  return (
    <div className="text-danger float-end">
      {String(Math.floor((time - curTime) / 60)).padStart(2,'0')} min {String((time - curTime) % 60).padStart(2,'0')} seg
    </div>
  )
}

Timer.propTypes = {
  timeLeft: PropTypes.number,
  callback: PropTypes.func,
}

export default Timer
