import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label
} from "reactstrap"
import PropTypes from 'prop-types';
import * as _ from 'lodash';

//Import Breadcrumb
import { Link } from 'react-router-dom';
import { useState } from "react";
import { useRef } from "react";
import { getRegions, postLandingProvider, validateZipCode } from "helpers/backend_helper";
import { Alert } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ProviderForm from '../Catalogos/common/ProviderForm';

import logoImg from "assets/images/logo-cresta.png";
import Select from 'react-select';
import useRequest from "hooks/useRequest";
import FormFarm from "./FormFarm";
import AdrressForm from "pages/Catalogos/common/AddressForm";
import { MEXICO_STATES } from '../../helpers/states';
import { formatNumber } from '../../helpers/formats';
import toastr from "toastr"

const SITE_KEY    = process.env.REACT_APP_CAPTCHA_SITE_KEY
const CAPTCHA_URL = process.env.REACT_APP_CAPTCHA_URL

const initState = {
  key: null,
  name: null,
  address: {},
  local_delivery: false,
  region_id: null,
  region: null,
  regions: []
}

const Provider = () => {

  const [error, setError] = useState (null)
  const [completed, setCompleted] = useState (null)
  const [btnSaveLoad, setBtnSaveLoad] = useState (false)
  const refForm = useRef('form-user-create')
  const [tokenCaptcha, setTokenCaptcha] = useState('');
  const [rfc, setRfc] = useState('')

  const [farm, setFarm] = useState({...initState})
  const [farms, setFarms] = useState([]);
  const [offCanvasIsOpen, setOffCanvasIsOpen] = useState(false)
  const [regions, setRegions] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [conSucursales, setConSucursales] = useState(false);

  const reqRegions = useRequest();

  const handleCheckLocalRegion = (option) => {
    setFarm({
      ...farm,
      region_id: option.value,
      region: _.find(regions, {id: option.value})
    })
  }

  const handleValidSubmit = async (event, values) => {
    try {
      const resp = await validateZipCode({ cp: values.cp })
      if(!resp.cp) {
        toastr.error('Código postal no válido');
        return;
      };
    } catch (error) {
      toastr.error(error);
    }

    try {
      const { company_name, contact_name, skey, phone, email, ...address } = values;
      values['farms'] = conSucursales ? farms : []
      values['rfc'] = rfc
      setBtnSaveLoad(true)
      setError(null)
      setCompleted(null)
      const response = await postLandingProvider({...values, address, zonas, tokenCaptcha })
      setError(null)
      setCompleted('Se completo la operación, te llegará un correo con tus credenciales, sólo espera por la activación de tu cuenta por parte del distribuidor.')
      setFarms([]);
      refForm && refForm.current.reset();
    } catch(error) {
      setError(error.toString())
      setCompleted(null)
      window.grecaptcha && window.grecaptcha.reset()
    }
    setBtnSaveLoad(false)
  }

  const handleFarms = farm => {
    if(farm.key === null)
      setFarms(prev => [...prev, farm])
    else
      setFarms(prev => prev.map((item, key) => key === farm.key ? farm : item))

    setOffCanvasIsOpen(false)
  }

  const handleLoaded = () => {
    window.grecaptcha.ready(() => {
        window.grecaptcha.render('html_element', {
            'sitekey' : SITE_KEY,
            'callback': token => setTokenCaptcha(token)
        });
    })
  }

  const delFarm = (indexFarm) => {
    const newFarms = [...farms]
    newFarms.splice(indexFarm, 1)
    setFarms(newFarms)
  }

  useEffect(() => {
      if(!window.grecaptcha){
        const script = document.createElement('script')
        script.src = CAPTCHA_URL
        script.addEventListener('load', handleLoaded)
        document.body.appendChild(script)
      }
  }, []);

  useEffect(() => {
    const { req } = reqRegions;
    
    req(getRegions())
      .then(setRegions)
  }, []);

  const handleZones = (e) => {
    setZonas(e);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
        <title>Registrar Proveedor | Cresta </title>
        </MetaTags>
        <Container>
            <Row>
                <Col className="col-12">
                    <div className="text p-4 text-center bg-warning bg-soft">
                        <img
                            src={logoImg}
                            alt=""
                            height="55"
                        />
                        <p className="pt-4">Registrar nuevo proveedor</p>
                    </div>
                </Col>
            </Row>  
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    ref={refForm}
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    {completed ? <Alert color="success">{completed}</Alert> : null}
                      
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                              name="company_name"
                              label="Nombre Comercial o Razón Social"
                              type="text"
                              required
                              errorMessage="Campo requerido"
                              placeholder=""
                            />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                              name="contact_name"
                              label="Nombre Completo Persona Contacto"
                              type="text"
                              required
                              errorMessage="Campo requerido"
                              placeholder=""
                            />
                        </div>
                      </Col>
                    </Row>
                  
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                              name="phone"
                              label="Teléfono"
                              type="text"
                              required
                              errorMessage="Campo requerido"
                              placeholder=""
                            />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                              name="email"
                              label="Correo electrónico"
                              type="email"
                              required
                              errorMessage="Campo requerido"
                              placeholder=""
                            />
                        </div>
                      </Col>
                    </Row>

                    <label className="py-2">Datos Fiscales Matriz</label>
                    <Row>
                      <Col md={12}>
                        <Label htmlFor="formrow-firstname-Input">RFC</Label>
                        <div className="mb-3">
                          <input
                              className="form-control"
                              name="rfc"
                              type="text"
                              placeholder=""
                              onChange={ (e) => { console.log(e); setRfc(e.target.value.toUpperCase()) } }
                              value={ rfc }
                              maxLength={13}
                            />
                        </div>
                      </Col>
                    </Row>
                    <AdrressForm entityAddress={{}}/>

                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Zonas de cobertura</Label>
                          <Select
                            //isClearable
                            id="formrow-InputState"
                            isMulti={true}
                            options={ MEXICO_STATES.map( ({ id, name }) => ({ label: name, value: name}) ) }
                            onChange={ handleZones }
                            //defaultValue={ {label: farm.region?.name, value: farm.region_id} }
                            value = { zonas }
                          />
                          { farm.region_id == null ? <div className="invalid-feedback">Campo requerido</div>:''}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Agregar Sucursales</Label>
                          <input
                            className="form-check-input"
                            value={conSucursales}
                            onChange={ (e) => { console.log(e.target.checked); setConSucursales(e.target.checked); } }
                            name="with_farms"
                            type="checkbox"
                            style={{marginLeft: '20px'}}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div style={{width:'100%', overflow:'scroll'}} className={conSucursales ? '':'d-none'}>
                      <table className="table table-responsive table-bordered table-sm">
                        <thead>
                        <tr>
                            <th colSpan={5} className="text-center">
                              Sucursales
                            </th>
                          </tr>
                          <tr>
                            <th className="text-center">Sucursal</th>
                            <th className="text-center">Entrega en sucursal</th>
                            <th className="text-center">Región de la sucursal</th>
                            <th className="text-center">Regiones de entrega</th>
                            <th className="text-center">Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                        {( farms ).map((farm, key) => (
                          <tr key={key}>
                            <td>{farm.name}</td>
                            <td>{farm.local_delivery ? 'Sí':'No'}</td>
                            <td>{farm.region ? farm.region.name:''}</td>
                            <td>{ farm.regions ? farm.regions.reduce( (prev, cur) => { return prev + cur.name + ' - ' }, '' ) : '' }</td>
                            <td>
                              <Button 
                                type="button"
                                className="btn btn-warning btn-sm"
                                onClick={ () => {
                                  setFarm({                                  
                                    ...farm,
                                    key
                                  })
                                  setOffCanvasIsOpen(true)
                                }}>
                                <i className="fas fa-edit"></i>
                              </Button> <Button type="button" className="btn btn-danger btn-sm" onClick={ () => delFarm(key) }>
                                <i className="fas fa-trash"></i>
                              </Button> 
                            </td>
                          </tr>
                        ))}
                        { farms.length == 0 && (
                          <tr>
                            <td colSpan={5} className="text-center">Sin sucursales adicionales</td>
                          </tr>
                        )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={5} className="text-center">
                              <Button 
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={ () => {
                                  setOffCanvasIsOpen(true )
                                  setFarm(initState)
                                }}>
                                <i className="fas fa-plus"></i> Agregar sucursal
                              </Button>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>                  

                    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                        <div id="html_element"></div>
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-sm btn-danger w-md" disabled={ btnSaveLoad }>
                        <i className="fas fa-save"></i> Registrar
                      </button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Offcanvas
              isOpen={ offCanvasIsOpen }
              direction="end"
              toggle={ () => setOffCanvasIsOpen(false) }
          >                
            <OffcanvasHeader toggle={ () => setOffCanvasIsOpen(false) }>
              { farm.key === null ? 'Nueva' : 'Editar' } Sucursal
            </OffcanvasHeader>
            <OffcanvasBody>
              <FormFarm farm={farm} regions={regions} handleFarms={handleFarms} setRegions={setRegions} setFarm={setFarm}/>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  )
}

Provider.propTypes = {
  provider: PropTypes.object
};

export default Provider
