import { Col, Row, Form, Alert } from "reactstrap"

//Import Breadcrumb
import { Link } from "react-router-dom"

import { PropTypes } from "prop-types"
import {
  postClient,
  postEnterprise,
  putEnterprise,
  validateZipCode,
} from "helpers/backend_helper"

import toastr from "toastr"
import NibbleBaseInput from "common/nibble/NibbleBaseInput"
import { useFormik } from "formik"
import { MEXICO_STATES } from "helpers/states"
import * as Yup from "yup"
import React, { useState } from "react"

const cpRegExp = /^[0-9]{5}/

const EnterpriseForm = ({ isNew = true, dataRow = {} }) => {
  const [btnSaveLoad, setBtnSaveLoad] = useState(false)
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)

  const initialValues = {
    user_name: "",
    user_email: "",
    user_password: "",
    company_name: "",
    contact_name: "",
    rfc: "",
    phone: "",
    email: "",
    street: "",
    ext_num: "",
    int_num: "",
    cp: "",
    location: "",
    city: "",
    state: "",
    country: "México",
  }

  const validationSchema = () => {
    Yup.object({
      user_name: Yup.string().nullable().required("Campo requerido"),
      user_email: Yup.string()
        .nullable()
        .required("Campo requerido")
        .email("Debe ser un correo electrónico válido"),
      user_password: isNew
        ? Yup.string().nullable().required("Campo requerido")
        : Yup.string().nullable(),
      company_name: Yup.string().nullable().required("Campo requerido"),
      contact_name: Yup.string().nullable().required("Campo requerido"),
      rfc: Yup.string().nullable().required("Campo requerido"),
      phone: Yup.string().nullable().required("Campo requerido"),
      email: Yup.string().nullable().required("Campo requerido"),
      street: Yup.string().nullable().required("Campo requerido"),
      ext_num: Yup.string().nullable().required("Campo requerido"),
      int_num: Yup.string().nullable(),
      cp: Yup.string()
        .nullable()
        .required("Campo requerido")
        .matches(cpRegExp, "Código postal no válido"),
      location: Yup.string().nullable().required("Campo requerido"),
      city: Yup.string().nullable().required("Campo requerido"),
      state: Yup.string().nullable().required("Campo requerido"),
      country: Yup.string().nullable().required("Campo requerido"),
    })
  }

  const handleValidSubmit = async values => {
    try {
      const resp = await validateZipCode({ cp: values.cp })
      if (!resp.cp) {
        toastr.error("Código postal no válido")
        return
      }
    } catch (error) {
      toastr.error(error)
    }

    setBtnSaveLoad(true)
    try {
      if (isNew) {
        const response = await postEnterprise({
          ...values,
          fee_per_unit: 0,
          start: "10:00",
          end: "11:00",
        })
        validation.resetForm()
      } else {
        const response = await putEnterprise({
          ...values,
          fee_per_unit: 0,
          start: "10:00",
          end: "11:00",
        })
      }
      setError(null)
      setCompleted("Se completo la operación")
    } catch (error) {
      setError(error.toString())
      setCompleted(null)
    }
    setBtnSaveLoad(false)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: isNew ? initialValues : dataRow,
    validationSchema: validationSchema(),
    onSubmit: handleValidSubmit,
  })

  console.log("values", validation.values)
  console.log("errors", validation.errors)

  return (
    <React.Fragment>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col md={12}>
            <h4 className="card-title mb-4">Datos de Usuario</h4>
            {error ? <Alert color="danger">{error}</Alert> : null}
            {completed ? <Alert color="success">{completed}</Alert> : null}
          </Col>
          <NibbleBaseInput
            md={4}
            label="Nombre"
            fieldName="user_name"
            fieldType="text"
            validation={validation}
          />
          <NibbleBaseInput
            md={4}
            label="Correo Electrónico"
            fieldName="user_email"
            fieldType="email"
            validation={validation}
          />
          {isNew && (
            <NibbleBaseInput
              md={4}
              label="Contraseña"
              fieldName="user_password"
              fieldType="password"
              validation={validation}
            />
          )}
        </Row>
        <Row>
          <Col md={12}>
            <h4 className="card-title mb-4">Datos de Empresa</h4>
          </Col>
          <NibbleBaseInput
            md={6}
            label="Nombre Comercial o Razón Social"
            fieldName="company_name"
            fieldType="text"
            validation={validation}
          />
          <NibbleBaseInput
            md={6}
            label="Nombre Completo Persona Contacto"
            fieldName="contact_name"
            fieldType="text"
            validation={validation}
          />
          <NibbleBaseInput
            md={4}
            label="Rfc"
            fieldName="rfc"
            fieldType="text"
            validation={validation}
          />
          <NibbleBaseInput
            md={4}
            label="Teléfono"
            fieldName="phone"
            fieldType="number"
            validation={validation}
          />
          <NibbleBaseInput
            md={4}
            label="Correo electrónico"
            fieldName="email"
            fieldType="email"
            validation={validation}
          />
          <NibbleBaseInput
            md={12}
            label="Calle"
            fieldName="street"
            fieldType="text"
            validation={validation}
          />
          <NibbleBaseInput
            md={4}
            label="Num Ext."
            fieldName="ext_num"
            fieldType="text"
            validation={validation}
          />
          <NibbleBaseInput
            md={4}
            label="Num Int."
            fieldName="int_num"
            fieldType="text"
            validation={validation}
          />
          <NibbleBaseInput
            md={4}
            label="C.P."
            fieldName="cp"
            fieldType="text"
            validation={validation}
          />
          <NibbleBaseInput
            md={6}
            label="Colonia"
            fieldName="location"
            fieldType="text"
            validation={validation}
          />
          <NibbleBaseInput
            md={6}
            label="Ciudad/Municipio"
            fieldName="city"
            fieldType="text"
            validation={validation}
          />

          <NibbleBaseInput
            md={6}
            label="Estado"
            fieldName="state"
            fieldType="select"
            validation={validation}
            selectItems={MEXICO_STATES.map(item => {
              return { id: item.name, name: item.name }
            })}
          />

          <NibbleBaseInput
            md={6}
            label="País"
            fieldName="country"
            fieldType="text"
            validation={validation}
          />

          <Col md={12} className="text-center">
            <button
              type="submit"
              className="btn btn-sm btn-danger w-md"
              disabled={btnSaveLoad}
            >
              <i className="fas fa-save"></i> Guardar
            </button>
            <Link
              to="/catalogs/enterprises"
              className="btn w-md btn-sm btn-danger ms-2"
            >
              <i className="fas fa-reply"></i> Atrás
            </Link>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

EnterpriseForm.propTypes = {
  isNew: PropTypes.bool,
  dataRow: PropTypes.object,
}

export default EnterpriseForm
