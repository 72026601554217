import { tax_configuration, tax_configuration_validation } from "common/sat/Taxes"
import * as Yup from "yup"
import { es } from "yup-locales"

Yup.setLocale(es)

export const initModel = {
  name: "",
  description: "",
  position: "",
  skey: "",
  unidad_sat: null,
  codigo_sat: null,
  objeto_imp_sat: null,
  unit_id: null,
  tax_configuration,
}

export const productValidation = Yup.object({
  name: Yup.string().required().label("Nombre"),
  description: Yup.string().required().label("Descripción"),
  unit_id: Yup.string().required().label("Unidad"),
  position: Yup.number().nullable("Orden"),
  skey: Yup.string().nullable().label("Clave"),
  unidad_sat: Yup.string().nullable().label("Unidad SAT"),
  codigo_sat: Yup.string().nullable().label("Código SAT"),
  objeto_imp_sat: Yup.string().nullable().label("Objeto Impuesto"),
  tax_configuration: tax_configuration_validation,
})
