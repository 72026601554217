import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFilters } from 'selectors';
import { getAdministrativeData, setAdministrativeFilters } from 'store/catalogs/administrative-data/actions';

const useAdminClientsProviders = (initialPeriod) => {

    const dispatch = useDispatch();

    const {
        activeTab 
    } = useSelector(getFilters);

    useEffect(() => {
        dispatch(setAdministrativeFilters({dates: initialPeriod}));
    }, []);

    useEffect(() => {
        dispatch(getAdministrativeData());
    }, [ activeTab ]);
    
}

export default useAdminClientsProviders;