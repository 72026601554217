import React from 'react';
import PropTypes from 'prop-types';

export default function TableHeader({ setColumnOrder, columnOrder, multiple}) {
    return (
        <>
            <tr className='table-head'>
                <th className="text-center" style={{minWidth: '120px'}}>
                    Fecha
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="row_period"
                    />
                </th>
                {multiple &&
                <th className="text-center" style={{minWidth: '120px'}}>
                    Cliente
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="account_name"
                    />
                </th>
                }
                <th className="text-center" style={{minWidth: '80px'}}>
                    Ventas
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="total_vta"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Descuentos<br />Merma<br />
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="decrease"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Otros<br />Descuentos<br />
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="nota_credito"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Otros<br />Cargos<br />
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="nota_cargo"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Cobros
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="cobros"
                    />
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Saldo
                </th>
                <th className="text-center" style={{minWidth: '80px'}}>
                    Efecto<br />Deuda<br />
                    <ArrowsOrder 
                        setColumnOrder={ setColumnOrder }
                        columnOrder={ columnOrder }
                        column="total_periodo"
                    />
                </th>
            </tr>
        </>
    );
}

TableHeader.propTypes = {
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object,
    multiple: PropTypes.bool
};

const ArrowsOrder = ({ setColumnOrder, column, columnOrder }) => {
    
    const isArrowDownActive = (columnOrder.column === column && columnOrder.order === 'desc');
    const isArrowUpActive = (columnOrder.column === column && columnOrder.order === 'asc');

    return (
        <span className="order arrows-order">
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'desc'
                })}
                className={`dropdown arrows-order__asc ${ isArrowDownActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
            <span 
                onClick={() => setColumnOrder({
                    column,
                    order: 'asc'
                })}
                className={`dropup arrows-order__desc ${isArrowUpActive && 'active'}`}
            >
                <span className="caret"></span>
            </span>
        </span>
    )
}

ArrowsOrder.propTypes = {
    column: PropTypes.string,
    setColumnOrder: PropTypes.func,
    columnOrder: PropTypes.object,
    
};