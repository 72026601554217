import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';

const usePage = () => {
    const location = useLocation();
    const history = useHistory();

    const pageParam = new URLSearchParams(location.search).get('page');
    const currentPage = parseInt(pageParam) || 1;

    const sizePerPageParam = new URLSearchParams(location.search).get('sizePerPage');
    const currentSizePerPage = parseInt(sizePerPageParam) || 10;

    const [page, setPage] = useState(currentPage);
    const [sizePerPage, setSizePerPage] = useState(currentSizePerPage)

    useEffect(() => {
        history.push(`?page=${page}`);
    }, [page]);

    return {
        page,
        sizePerPage,
        setPage,
        setSizePerPage
    }
}

export default usePage